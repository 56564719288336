import React from 'react';
import Colors from "../../../components/ui/Colors";
import { Card, Button, Typography, Autocomplete, TextField, 
    MenuItem, Dialog, Box, Grid } from "@mui/material";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import swal from "sweetalert";
import { useMediaQuery } from '@mui/material';
import {getActiveProducts, enrollUser} from "../../../utilityFunctions/EduMarketUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import ScheduleDialog from './ScheduleDialog';
import { ukLocations } from '../../../Api';
// import { makeStyles } from '@mui/styles';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../../css/slickOverrides.css";
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const serviceTypes = ["All", "Holyday Clubs", "After School Care", "Private Tuition", "Child Care"];

let allPrograms = null;
let uniqueGeoLocations = null;

export default function ProgramsMarketPlace(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [filteredPrograms, setFilteredPrograms] = React.useState(null);

    React.useEffect(() => {
        getActivePrograms();
    }, []);

    const getActivePrograms = async () => {
        setLoading(true);
        const response = await getActiveProducts(loginUser.jwtToken);
        setLoading(false);

        setFilteredPrograms(response);

        allPrograms=[{ county: 'All', city: '' }, ...response];

        uniqueGeoLocations = getUniquePrograms(allPrograms);

        console.log(allPrograms);

        if(!response){
            swal({
                title: "Info...",
                text: "Currently we do not have any active programs. Please try again later.",
                icon: "info",
                button: "Ok",
            });
            return;
        }

        return;
    };

    const getUniquePrograms = (programs) => {
        const seen = new Set();
        return programs.filter((program) => {
            const identifier = `${program.county} - ${program.city}`;
            if (seen.has(identifier)) {
                return false;
            }
            seen.add(identifier);
            return true;
        });
    };

    React.useEffect(() => {
        if(filteredPrograms){
            console.log(filteredPrograms);
            renderProducts();
        }
    }, [filteredPrograms]);

    const [products, setProducts] = React.useState("");

    const renderProducts = async () => {

        // const activePrograms = await getActivePrograms();

        const activePrograms = filteredPrograms;
        console.log(activePrograms);

        if(!activePrograms){
            return;
        }

        const gridDiv = Math.round(12 / activePrograms.length) < 4 ? 4 : Math.round(12 / activePrograms.length);

        setProducts(
            
            activePrograms.map((program) => {
                return(
                        <Grid key={generateRandomKey()} item xs={12} sm={gridDiv} md={gridDiv} lg={gridDiv} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Card key={generateRandomKey()} sx={{position: "relative", width: {xs: "95%", sm: "90%"}, cursor: "pointer", maxWidth: "400px", height:{xs: "490px", sm: "500px", md: "520px"}, borderRadius: "25px", marginBottom: {xs: "1rem", sm: "0"}}}>
                                <Grid key={generateRandomKey()} container direction = "column">
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Box 
                                            component="img"
                                            sx={{
                                            height: "90%",
                                            maxHeight: "230px",
                                            width: "100%",
                                            justifyContent: "center",
                                            }}
                                            alt="Foto"
                                            src={URL.createObjectURL(program.edProgramPic.picture)}

                                        >
                                        </Box>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "1rem"}}>
                                        <Grid container key={generateRandomKey()} sx={{width: "90%", display: "flex", justifyContent: "center"}}>
                                            <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "0.2rem"}}>
                                                <Typography key={generateRandomKey()}  variant="h6" sx={{fontWeight: "bold", width: "100%", color: Colors.primary}}>{program.title}</Typography>
                                            </Grid>

                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <LocationOnOutlinedIcon fontSize="small" sx={{color: Colors.primary}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Location:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.67rem", sm: "0.875rem"}}}>{`${program.territory}, ${program.county}, ${program.city}`}</Typography>
                                            </Grid>

                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <CurrencyPoundIcon fontSize="small" sx={{color: Colors.primary}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>price:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.79rem", sm: "0.875rem"}}}>{`£ ${new Intl.NumberFormat('en-GB').format(program.price)}`}</Typography>
                                            </Grid>

                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <CalendarMonthIcon fontSize="small" sx={{color: Colors.primary}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Schedule:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Button key={generateRandomKey()} onClick={() => handleOpenDialog(program)} component="label" sx={{marginTop: "0.1rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem", fontWeight: "bold"}}>
                                                    See Calendar
                                                </Button>
                                            </Grid>
                                            
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <EscalatorWarningIcon fontSize="small" sx={{color: Colors.primary}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Age Range:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.79rem", sm: "0.875rem"}}}>{`from ${program.fromAge} to ${program.toAge}`}</Typography>
                                            </Grid>

                                            {/* <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}> */}
                                                <Button onClick={() => productDetail(program)} variant="contained" sx={{position: "absolute", left: "50%", transform: 'translateX(-50%)', bottom: "1rem", backgroundColor: Colors.primary}}>
                                                    Detail
                                                </Button>
                                            {/* </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>   
                        </Grid>
                    
                    )
            })
        );
    };

    const [selectedSchedule, setSelectedSchedule] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleOpenDialog = (program) => {
        console.log(program.schedule);

        if(!program.schedule || program.schedule.length === 0){
            swal({
                title: "Info...",
                text: "This program has no schedule",
                icon: "info",
                button: "Ok",
            });
            return;
        }

        const modifiedSchedule = program.schedule.map((time, index) => {
            return ({
                id: time.id,
                title: time.title, 
                start: new Date(time.startTime),
                end: new Date(time.endTime)
            });
        });

        console.log(modifiedSchedule);
        
        setSelectedSchedule(modifiedSchedule);
        setDialogOpen(true);
       
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedSchedule(null);
    };

    const[openDesc, setOpenDesc] = React.useState(false);

    const handleCloseDesc = () => {
        setOpenDesc(false);
        setProgramDescription(null);
        setSelectedProduct(null);
    };

    const [programDescription, setProgramDescription] = React.useState(null);
    const [selectedProduct, setSelectedProduct] = React.useState(null);

    const productDetail = async (product) => {
        
        console.log(product);
        setSelectedProduct(product);

        if(!product.description){
            setProgramDescription("No description provided for this program.");
            return;
        }

        setProgramDescription(product.description);
    };

    React.useEffect(() => {
        if(programDescription && selectedProduct){
            setOpenDesc(true);
        }
    }, [programDescription, selectedProduct]);

    const submitRegister = async () => {

        setLoading(true);
        setOpenDesc(false);
        const enrollmentResp = await enrollUser({programId: selectedProduct.id}, loginUser.jwtToken);
        setLoading(false);

        if(!enrollmentResp){
            swal({
                title: "Error",
                text: "There was an error enrolling you in the program. Please try again later.",
                icon: "error",
                button: "Ok",
            });
            return;
        }

        swal({
            title: "Success",
            text: "You have successfully enrolled in the program.",
            icon: "success",
            button: "Ok",
        });
    };

    

    //Filtering functionallities

    const [serviceType, setServiceType] = React.useState("");
    
    const serviceTypeChange = (e) => {
        setServiceType(e.target.value);

        console.log(e.target.value)

        if(e.target.value === "All"){
            
            const filtered = allPrograms.filter((program) => {
                return program.county !== "All";
            });
            
            setFilteredPrograms(filtered);
            return;
        }

        const filtered = allPrograms.filter((program) => {
            return program.serviceType === e.target.value;
        });

        setFilteredPrograms(filtered);
    };

    const geoFilterChange = (e, value) => {     
        console.log(value);
        if(!value || value.county === "All"){
            const filtered = allPrograms.filter((program) => {
                return program.county !== "All";
            });
            
            setFilteredPrograms(filtered);
            return;
        }


        const filtered = allPrograms.filter((program) => {
            return program.city === value.city && program.county === value.county;
        });

        setFilteredPrograms(filtered);
    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#f6f6f6"}}>
            <Box
                sx={{
                    display: "flex",
                    // flexGrow: "1",
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "100%",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <>
                <Grid container spacing={2} sx={{ width: {xs: "95%", sm: "95%"}, height: "100%", alignItems: {xs: "start", sm: "center"}, justifyContent: "center", marginTop:{xs: "5%", sm: "0.5rem", md: 0}, marginBottom: {xs: "3rem", sm: "2rem", md: "1.6rem"}}}>
                    <>
                    {uniqueGeoLocations &&
                        <>
                        <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Autocomplete 
                                sx={{ width: "90%"}}
                                options={uniqueGeoLocations}
                                getOptionLabel={(option) => `${option.county} - ${option.city}`}
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"Program not found"}
                                fullWidth
                                onChange={geoFilterChange}
                                // onInputChange={onChange}
                                renderInput={(params) => (
                                    <TextField  {...params} label="Filter by city" variant="standard" />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <TextField
                                sx={{textAlign: "start", width: "90%"}}
                                variant="standard"
                                fullWidth={true}
                                id="serviceType"
                                name="serviceType"
                                select
                                label={<Typography variant="body2">Filter by service type</Typography>}
                                value={serviceType}
                                onChange={(e)=>serviceTypeChange(e)}
                                autoComplete="off"
                            >
                                {serviceTypes.map((el) => (
                                    <MenuItem key={el} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </TextField> 
                        </Grid> 
                        </>
                    }  
                    {products}
                    </>
                </Grid>
                {selectedSchedule && (
                    <>
                    {console.log(selectedSchedule)}
                    <ScheduleDialog
                        open={dialogOpen}
                        handleClose={handleCloseDialog}
                        events={selectedSchedule}
                        defaultStarting={selectedSchedule[0].start}
                    />
                    </>
                )}
                <Dialog open={openDesc} onClose={handleCloseDesc} 
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "60%", md: "40%"},
                                height: "40%"
                            },
                        },
                    }}
                >
                    <Grid container direction="row" spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                        <Grid item xs={12} sm={12} md={12} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem", marginTop: "2rem"}}>
                            <Typography variant="h6" sx={{}}>Service Description</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <Typography variant="body2" sx={{}}>{programDescription}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <Button variant="contained" onClick={handleCloseDesc}>
                                Back
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <Button variant="contained" onClick={submitRegister}>
                                Register
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                </>
            }
            </Box>
        </Box>
    )
}
