import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Grid } from '@mui/material';
import { Calendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment'; // Import Moment.js
import 'moment/locale/en-gb'; // Import the en-gb locale for Moment.js
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomCalendar.css'; // Import custom styles

// Set Moment.js to use the en-gb locale
moment.locale('en-gb');

const SetScheduleDialog = ({ open, handleClose, defaultStarting, addCalendarSchedule, title, events }) => {
    
    const localizer = momentLocalizer(moment); // Use Moment.js localizer provided by react-big-calendar

    const { views } = React.useMemo(
        () => ({
        views: [Views.WEEK],
        
        }),
        []
    );

    React.useEffect(() => {
        if(events && events.length > 0){
            setCurrentEvents(events);
        }
    }, [events]);


    const[currentEvents, setCurrentEvents] = React.useState([]);

    const handleSelectSlot = (date) => {
        console.log(date);

        const newSchedule = {
            id: currentEvents.length + 1,
            title: title, // Use el.name instead of time.name
            start: date.start,
            end: date.end
        };
        
        console.log(newSchedule);

        setCurrentEvents(prevCurrentEvent => [...prevCurrentEvent, newSchedule]);

    };

    const deleteDate = (date) => {
        const newEvents = currentEvents.filter((event) => event.start !== date.start && event.end !== date.end);
        setCurrentEvents(newEvents);
    }

    const handleSetSchedule = () => {
        console.log(currentEvents);
        addCalendarSchedule(currentEvents);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogContent>
                <Calendar
                    defaultDate={defaultStarting} // Specify defaultDate
                    // min={defaultStarting}
                    localizer={localizer}
                    events={currentEvents}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    views={views}
                    // toolbar={false}
                    // selectable={false}
                    showMultiDayTimes
                    defaultView={'week'}
                    onSelectSlot={handleSelectSlot} // Handler for slot selection
                    selectable={true} // Enable slot selection
                    onSelectEvent={deleteDate}
                />
            </DialogContent>
            <Grid container direction="row" spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                <Grid item xs={6} sm={6} md={6} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                    <Button variant="contained" onClick={handleClose}>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                    <Button variant="contained" onClick={handleSetSchedule}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default SetScheduleDialog;
