import React from 'react'

const DefaultSection = (props) => {
  const { backImgage, backColor, width, noFlex, defaultWidth, padding, margin, maxWidth, alignSelf} = props
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: backColor ? backColor : 'white',
        padding: padding ? padding : '2rem 0',
        width: defaultWidth || '100%',
        maxWidth: maxWidth ? maxWidth : '100%',
        flex: noFlex? "none" : 1,
        margin: margin ? margin : "0",
        alignSelf: alignSelf ? alignSelf : "none"
      }}
    >
      <img
        src={backImgage}
        style={{
          width: width || '80%',
        }}
        alt=""
      />
    </div>
  )
}

export default DefaultSection