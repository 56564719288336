import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import ContentForm from "../../layout/ContentForm";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";
import { creditAsset, creditTerm, b2bClient} from "../../Api";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {simulatorExplanation} from "../../Texts/Explanations";
import {disbursedText, rateText, warrantyText, platformText,
     platformDiscountText, vatText, totalPaymentText} from "../../Texts/SimulatorExplanations"
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';

let marksBalance = [
    {
      value: 100000,
      label: '$100.000',
    },
    {
      value: 500000,
      label: '$500.000',
    },
];

let marksTerm = [
    {
        value: 7,
        label: '7',
    },
    {
        value: 56,
        label: '56',
    },
];

let marksInstallments = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
];

const useStyles = makeStyles((theme) => ({
    input: {
        width: "50%",
    },

    formControl: {
        width: "50%",
    },

    dialogStyle:{
        whiteSpace: "pre-line",
        
    },

    dialogContainer:{
        width: "25em"
    },

    typography:{
        color: Colors.primary,
        fontWeight: "bold",
    },
}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const clientCreditAsset = creditAsset;

let lender = "WUPO"; //Currently a hardcoded WUPO, but needs to migrate to WUPO or a friend selected by the client.
const creditOptions = ["Cashback", "change"]

const creditInformation = {
    creditXrplAccount: "",
    creditClassicXrplAccount: "",
    creditXrplSecret: "",
    asset: clientCreditAsset,
    initialBalance: "",
    term: "",
    termUnit: creditTerm.day,
    dues: "",
    options: creditOptions,
    lender: "",
    account: "",
    paymentType: "",
};

//Credit Conditions:
let creditValueConditions = {
    asset: "WUP",
    minValue: 0,
    maxValue: 0,
    interval: 0,
};

let creditTermConditions = {
    minTerm: 0,
    maxTerm: 0,
    interval: 0,
    termUnit: creditTerm.day,
};

let creditInstallmentConditions = {
    amountInstallments: 1, //Can be equal to the term selected by the client / interval (traditional behavior).
    paymentType: "",
};

let creditInterestConditions = {
    interestRate: 0,
    interestRateDefaultMultiplier: 0,
    collectionsFee: 0
};

let creditCostsConditions = {
    costsWithVAT: 0, // Platform
    costsWithVATminimum: 0,
    platformTimeDiscount: 0,
    platformBalanceDiscount: 0,

    costsWithoutVAT: 0, //Aval
    costsWithoutVATminimum: 0,
    costsWithoutVATFee: 0,
    warrantyTimeDiscount: 0,
    warrantyBalanceDiscount: 0,
};

const calculationVariables = {
    loanAmount: 0,
    margenState: 0,
    interestState: 0,
    warrantyDiscount: 0,
    platfromDiscount: 0,
    disbursedBalance: 0,
    vatTax: 0,
    term: 0,
    totalPayment: 0,
};

//Credit front rendering
let uxEDR = 0;
let VAT = 0; // Local tax: IVA = 19%

let loanConditionsSettings;
let loanCostsSettings;
let totalPayment = 0;
let disbursedBalanceBeforeInterests = 0;

let isAdmin = false;

export default function LoanSimulator(props) {
    const{
        loadProductsAndSetting
    } = props

    const classes = useStyles();

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    //States to render the loan simulation based on changing values (modified by clients).
    const[loanAmount, setLoanAmount] = React.useState(0);
    const[platfromDiscount, setPlatfromDiscount] = React.useState(0);
    const[interestState, setInterestState] = React.useState(0);
    const[margenState, setMargenState] = React.useState(0);
    const[warrantyDiscount, setWarrantyDiscount] = React.useState(0);
    const[vatTax, setVatTax] = React.useState(0);
    const[disbursedBalanceState, setDisbursedBalanceState]= React.useState(0);

    //States to follow changes in the term and dues
    const [term, setTerm] = React.useState(0);
    const [dues, setDues] = React.useState(creditInstallmentConditions.amountInstallments);

    //Load initial, conduct initial calculations to properly render the simulator in the moment 0
    const loanSettingsAssignment = () =>{
        return new Promise((resolve) =>{

        
            creditCostsConditions = {
                ...creditCostsConditions, 
                costsWithVAT: loanCostsSettings.costsWithTaxes, // Platform
                costsWithVATminimum: loanCostsSettings.costsWithTaxesMinimum,
                platformTimeDiscount: loanCostsSettings.platformTimeDiscount,
                platformBalanceDiscount: loanCostsSettings.platformBalanceDiscount,

                costsWithoutVATminimum: loanCostsSettings.costsWithoutTaxesMinimum,
                costsWithoutVATFee: loanCostsSettings.costsWithoutTaxes,
                warrantyTimeDiscount: loanCostsSettings.warrantyTimeDiscount,
                warrantyBalanceDiscount: loanCostsSettings.warrantyBalanceDiscount

                // paymentType: "prepaid", //Pending include it in a table on the back 
            };

            creditInterestConditions = {
                ...creditInterestConditions,
                interestRate: loanCostsSettings.interestRate,
                interestRateDefaultMultiplier: loanCostsSettings.interestDefaultFactor,
                collectionsFee: loanCostsSettings.collectionsFee,
            };

            creditInstallmentConditions.amountInstallments = loanConditionsSettings.amountInstallments;
            creditInstallmentConditions.paymentType = loanConditionsSettings.paymentType;
            console.log(creditInstallmentConditions.paymentType);

            creditTermConditions = {
                ...creditTermConditions,
                minTerm: loanConditionsSettings.minTerm,
                maxTerm: loanConditionsSettings.maxTerm,
                interval: loanConditionsSettings.termInterval,
                termUnit: loanConditionsSettings.termUnit,
            };

            creditValueConditions = {
                ...creditValueConditions,
                // asset: "WUP", //Pending solve asset
                minValue: loanConditionsSettings.minValue,
                maxValue: loanConditionsSettings.maxValue,
                interval: loanConditionsSettings.balanceInterval,
            };

            VAT = loanCostsSettings.taxesRate;

            resolve("ok");
        })    
    };

    const dailyInterestCalc = () =>{
        
        uxEDR = (Math.pow(1+creditInterestConditions.interestRate, calculationVariables.term/360)) - 1;

        return new Promise((resolve)=>{
            resolve(uxEDR);
        });
    }

    const VATCalculation = (discount) =>{
        // if(discount){
        //     calculationVariables.vatTax = (creditCostsConditions.costsWithVAT - discount) * VAT;
        //     setVatTax((creditCostsConditions.costsWithVAT - discount) * VAT);
        //     // console.log(calculationVariables.vatTax)
        // }else{
        //     calculationVariables.vatTax = (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) * VAT;
        //     setVatTax((creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) * VAT);
        //     // console.log(calculationVariables.vatTax)
        // }

        // console.log((creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount))
        calculationVariables.vatTax = ((creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) ) * VAT;
        setVatTax(calculationVariables.vatTax);
        // console.log(calculationVariables.vatTax)

        return calculationVariables.vatTax;
    };

    const handleInterests = () =>{

        if(creditInstallmentConditions.paymentType === "postpaid"){
        //     calculationVariables.interestState = uxEDR * (calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        // + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax);

        calculationVariables.interestState = uxEDR * (calculationVariables.loanAmount);
        
        setInterestState(calculationVariables.interestState);
        }
        else{
            setInterestState(calculationVariables.loanAmount * uxEDR);
            calculationVariables.interestState = calculationVariables.loanAmount * uxEDR;
        }


        return new Promise((resolve) => {
            resolve(calculationVariables.loanAmount * uxEDR);
        });
    };

    const disbursedBalanceRendered = (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in disbursed: ${loanAmount}`)
        // calculationVariables.disbursedBalance = calculationVariables.loanAmount - 
        // ((creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount) - guarantorDiscount)
        // - (creditCostsConditions.costsWithVAT - discount) - tempVat - rate;

        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;

        // calculationVariables.totalPayment = calculationVariables.loanAmount + 
        // ((creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount) - guarantorDiscount)
        // + (creditCostsConditions.costsWithVAT - discount) + tempVat + rate;

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;

        console.log(calculationVariables.loanAmount)
        console.log(creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount)
        console.log(creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount)
        console.log(calculationVariables.vatTax)
        console.log(calculationVariables.interestState)

        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        return new Promise((resolve) => {
            resolve(calculationVariables.disbursedBalance );
        });
    };

    const defineMargins = async (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in margins ${loanAmount}`)
        const onMountBalance = await disbursedBalanceRendered(guarantorDiscount, discount, rate, tempVat);
        setMargenState((calculationVariables.loanAmount -  onMountBalance)/ calculationVariables.loanAmount);
    };

    const updateSettings = () =>{
        marksBalance = [
            {
              value: loanConditionsSettings.minValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.minValue)}`,
              
            },
            {
              value: loanConditionsSettings.maxValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.maxValue)}`,
            },
          ];
        
        marksTerm = [
            {
                value: loanConditionsSettings.minTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.minTerm)}`,
            },
            {
                value: loanConditionsSettings.maxTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.maxTerm)}`,
            },
        ];
        
        marksInstallments = [
            {
                value: 1,
                label: '1',
            },
            {
                value: loanConditionsSettings.amountInstallments,
                label: `${loanConditionsSettings.amountInstallments}`,
            },
        ];
    };

    const initialValuesCalculation = async () =>{

        //  Assign the setting fetched from the back
        await loanSettingsAssignment();
        setLoanAmount(creditValueConditions.minValue);
        calculationVariables.loanAmount = creditValueConditions.minValue;
        creditInformation.initialBalance = creditValueConditions.minValue;

        setTerm(creditValueConditions.minTerm);
        calculationVariables.term = creditTermConditions.minTerm;
        creditInformation.term = creditTermConditions.minTerm;

        //Set initial cost without vat
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;

        //Set with and without taxes initial costs discounts
        // const guarantorDiscount = await calculateNoVATTermDiscount(creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount);
        // const guarantorDiscount = await calculateNoVATTermDiscount(); //UNDER REVIEW
        const guarantorDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(guarantorDiscount);
        calculationVariables.warrantyDiscount = guarantorDiscount;

        // const discount = await calculateWithVATTermDiscount(); //UNDER REVIEW
        const discount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(discount);
        calculationVariables.platfromDiscount = discount;

        //Initial taxes calculation
        // const tempVat = VATCalculation(discount);
        const tempVat = VATCalculation();
        
        //Set dues
        setDues(creditInstallmentConditions.amountInstallments);
        creditInformation.dues=creditInstallmentConditions.amountInstallments;

         //Set termunit
        creditInformation.termUnit = creditTermConditions.termUnit;

        //Set interests calculations
        await dailyInterestCalc();
        const rate = await handleInterests();

        defineMargins(guarantorDiscount, discount, rate, tempVat);

        //Set marks accordingly to received info from back
        updateSettings();
    };

    const loadInitialProducts = () => {
        return (new Promise((resolve) => {
            loadProductsAndSetting("/simulator", "", "").then((result) => {
                loanConditionsSettings = result.loanConditions;
                loanCostsSettings = result.loanCosts;
                resolve(result);
            });
            
        }));
           
    };

    const explanationMessages = () => {
        swal({
            title: "Explicación",
            text: simulatorExplanation,
            icon: "success",
            button: "Entiendo",
        });
    };

    useEffect(() => {
        
        if(b2bClient && b2bClient !== "IMS"){
            explanationMessages();
        }

        setLoading(true);
        loadInitialProducts().then((result) => {
            initialValuesCalculation();
            
            setLoading(false);
        });

    }, []);


    const handleInputCreditInformation = () => {
        setLoading(true);
        //Include in the un mount when receiving from AML form
        creditInformation.account = "pending";
        creditInformation.paymentType = creditInstallmentConditions.paymentType;
        console.log(creditInformation);
        history.push({
            pathname: "/register",
            state: {
                creditInformation: creditInformation,
                from: "simulator"
            }
        });
        
    };

    //Handle the input from ux of the loan simulator
    const handleBalanceInput = (inputValues) => {
        
        setBalanceInputValue(inputValues.target.value);
        creditInformation.initialBalance = inputValues.target.value;

        setLoanAmount(inputValues.target.value)
        calculationVariables.loanAmount = inputValues.target.value;

        calculateBalanceModification();
    }

    const handleTermSelect = async (e) => {
        setTerm(e.target.value);
        calculationVariables.term = e.target.value;
        creditInformation.term = e.target.value;

        await dailyInterestCalc();

        calculateTermModification();

    }

    const handleDuesSelect = (e) => {
        setDues(e.target.value);
        creditInformation.dues = e.target.value;
    }

    //Calculations derived from changing balance, term and / or dues (installments)
    const calculateBalanceModification = async () =>{

        // creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * creditInformation.initialBalance;
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;

        calculationVariables.platfromDiscount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(calculationVariables.platfromDiscount);

        // await calculateWithoutVATBalanceDiscount();

        // calculationVariables.warrantyDiscount = await calculateNoVATTermDiscount();
        
        calculationVariables.warrantyDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;
        

        console.log(calculationVariables.loanAmount)
        console.log(creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount)
        console.log(creditCostsConditions.costsWithoutVAT)
        console.log(calculationVariables.warrantyDiscount)
        console.log(creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount)
        console.log(calculationVariables.vatTax)
        console.log(calculationVariables.interestState)

        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
        
    };

    const calculateTermModification = async () =>{
        
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;
        // console.log(`costsWithoutVAT: ${creditCostsConditions.costsWithoutVAT}`)
        
        // await calculateWithVATBalanceDiscount();
        // await calculateWithoutVATBalanceDiscount();

        // calculationVariables.platfromDiscount = await calculateWithVATTermDiscount();
        calculationVariables.platfromDiscount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(calculationVariables.platfromDiscount);

        // calculationVariables.warrantyDiscount = await calculateNoVATTermDiscount();
        calculationVariables.warrantyDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;

        console.log(calculationVariables.loanAmount)
        console.log(creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount)
        console.log(creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount)
        console.log(calculationVariables.vatTax)
        console.log(calculationVariables.interestState)

        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
    };
    
    const  calculateWithVATTermDiscount = () => {
        
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        const minimumCostsWithVATFee = creditCostsConditions.costsWithVATminimum * creditCostsConditions.costsWithVAT;
        const remainingCostsWithVAT = creditCostsConditions.costsWithVAT - minimumCostsWithVATFee;
        const discountValue = remainingCostsWithVAT - (remainingCostsWithVAT * termSteps * currentTermSteps);
        // console.log(`discount value with VAT ${discountValue}`); //DEBUG

        //TODO: new way of calculation
        const stepDiscount = currentTermSteps * creditCostsConditions.platformTimeDiscount;
        
        console.log(currentTermSteps);
        console.log(stepDiscount);

        // return new Promise((resolve) => {
        //     resolve(discountValue);
        // })

        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
        
        
    };

    const calculateWithVATBalanceDiscount = () => {
        const currentBalanceSteps = (creditValueConditions.maxValue - creditValueConditions.minValue)/creditValueConditions.interval 
        - (creditValueConditions.maxValue - calculationVariables.loanAmount)/creditValueConditions.interval;

        const stepDiscount = currentBalanceSteps * creditCostsConditions.platformBalanceDiscount;
        
        console.log(currentBalanceSteps);
        console.log(stepDiscount);
        
        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateWithVatTotalDiscount = async () => {

        const termDiscount = await calculateWithVATTermDiscount();
        const balanceDiscount = await calculateWithVATBalanceDiscount();

        let stepDiscountVat = 0;

        if(creditCostsConditions.costsWithVATminimum < 1){
            stepDiscountVat = (1 - creditCostsConditions.costsWithVATminimum) - termDiscount - balanceDiscount;
        }

        const finalDiscount = stepDiscountVat * creditCostsConditions.costsWithVAT;

        console.log(termDiscount)
        console.log(balanceDiscount)
        console.log(stepDiscountVat)
        console.log(finalDiscount)

        return new Promise((resolve) => {
            resolve(finalDiscount);
        });
    };
    

    const calculateNoVATTermDiscount = (noTaxesCost) => {
       
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        let minimumCostsWithoutVATFee;
        let remainingCostsWithoutVAT;

        if(noTaxesCost){
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * noTaxesCost; 
            remainingCostsWithoutVAT = noTaxesCost - minimumCostsWithoutVATFee;  
        }else{
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * creditCostsConditions.costsWithoutVAT;
            remainingCostsWithoutVAT = creditCostsConditions.costsWithoutVAT - minimumCostsWithoutVATFee;
        }
        const discountValue = remainingCostsWithoutVAT - (remainingCostsWithoutVAT * termSteps * currentTermSteps);

        //TODO: new way of calculation
        const stepDiscount = currentTermSteps * creditCostsConditions.warrantyTimeDiscount;
        
        // console.log(currentTermSteps);
        // console.log(stepDiscount);

        // console.log(`discount value without VAT ${discountValue}`); //DEBUG
        // return new Promise((resolve) => {
        //     resolve(discountValue);
        // });

        return new Promise((resolve) => {
            resolve(stepDiscount);
        });
    };

    const calculateWithoutVATBalanceDiscount = () => {
        const currentBalanceSteps = (creditValueConditions.maxValue - creditValueConditions.minValue)/creditValueConditions.interval 
        - (creditValueConditions.maxValue - calculationVariables.loanAmount)/creditValueConditions.interval;

        const stepDiscount = currentBalanceSteps * creditCostsConditions.warrantyBalanceDiscount;
        
        // console.log(currentBalanceSteps);
        // console.log(stepDiscount);
       
        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateNoVatTotalDiscount = async () => {

        const termDiscount = await calculateNoVATTermDiscount();
        const balanceDiscount = await calculateWithoutVATBalanceDiscount();

        let stepDiscountNoVat = 0;

        if(creditCostsConditions.costsWithoutVATminimum < 1){
            stepDiscountNoVat = (1 - creditCostsConditions.costsWithoutVATminimum) - termDiscount - balanceDiscount;
        }

        const finalDiscount = stepDiscountNoVat * creditCostsConditions.costsWithoutVAT;

        // console.log(termDiscount)
        // console.log(balanceDiscount)
        // console.log(stepDiscount)
        // console.log(finalDiscount)

        return new Promise((resolve) => {
            resolve(finalDiscount);
        });
    };

    const valuetext = (value) => {
        // console.log(`${value} pesos`)
        return `${value} pesos`;
    }

    const [balanceInputValue, setBalanceInputValue] = React.useState("");

    const goLogin = () => {
        history.push({
            pathname: "/login",
        });
    }

    return (
        <>
            <div
                style={{
                display: "flex",
                flexGrow: "1",
                justifyContent: "center",
                }}
            >
                <ContentForm>
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <>
                    <PageLabel>Adelanto Digital</PageLabel>

                        <Box sx={{ width: 300, textAlign: "center" }}>
                        <Typography gutterBottom>
                            {creditInstallmentConditions.paymentType === "postpaid" ? "¿Cuánto necesitas?":"Valor a pagar"}
                        </Typography>
                            <Slider
                                aria-label="Amount"
                                // defaultValue={150000}
                                value={loanAmount}
                                getAriaValueText={valuetext}
                                step={creditValueConditions.interval}
                                marks={marksBalance}
                                valueLabelDisplay="on"
                                min={creditValueConditions.minValue}
                                max={creditValueConditions.maxValue}
                                onChange={handleBalanceInput}
                            />
                        </Box>

                        <Box sx={{ width: 300, textAlign: "center" }}>
                        <Typography gutterBottom>Plazo (días)</Typography>
                            <Slider
                                aria-label="installments"
                                // defaultValue={1}
                                value={term}
                                getAriaValueText={valuetext}
                                step={creditTermConditions.interval}
                                marks={marksTerm}
                                valueLabelDisplay="on"
                                min={creditTermConditions.minTerm}
                                max={creditTermConditions.maxTerm}
                                onChange={handleTermSelect}
                            />
                        </Box>

                        {creditInstallmentConditions.amountInstallments > 1 ? 
                            <Box sx={{ width: 300 }}>
                            <Typography gutterBottom>Cuotas (cantidad de pagos)</Typography>
                                <Slider
                                    aria-label="installments"
                                    // defaultValue={1}
                                    value={dues}
                                    getAriaValueText={valuetext}
                                    step={1}
                                    marks={marksInstallments}
                                    valueLabelDisplay="on"
                                    min={1}
                                    max={creditInstallmentConditions.amountInstallments}
                                    onChange={handleDuesSelect}
                                />
                            </Box>
                        : <></>
                        }

                        {/* <Grid container style={{marginLeft:"12em"}}> */}
                        
                        <div style={{maxWidth: "300px", textAlign: "start"}}>
                            <Grid container>
                                {/* <Grid item xs={3} sm={3} md={3}></Grid> */}
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography  style={{fontSize:"0.95rem"}} >
                                    {creditInstallmentConditions.paymentType === "postpaid" ? "¿Cuánto necesitas?":"Valor a pagar"}
                                    </Typography>
                                </Grid>
                                
                                <Grid item xs={3} sm={3} md={3} >
                                    <Typography className={classes.dialogStyle} style ={{color:"red", fontWeight:"bold", fontSize:"0.9rem"}}>
                                        {/* {`$ ${new Intl.NumberFormat('es-CO').format(creditInformation.initialBalance)}`} */}
                                        {`$ ${new Intl.NumberFormat('es-CO').format(loanAmount)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} >
                                    <Tooltip title={ disbursedText} placement="left">
                                        <Typography>
                                            <BsInfoCircle size={13}/>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                {isAdmin ?
                                    <>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Typography  style={{fontWeight:"bold", fontSize:"0.95rem"}}>
                                                Margen:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} >
                                            <Typography className={classes.dialogStyle} style={{color:"black", fontWeight:"bold", fontSize:"0.95rem"}}>
                                                {`$ ${new Intl.NumberFormat('es-CO', {
                                                    maximumFractionDigits: 0,
                                                }).format(margenState * 100)}%`}
                                            </Typography>
                                        </Grid>
                                    </>
                                    : <></>
                                }            
                            
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography  display="inline" style={{fontSize:"0.95rem"}}>
                                        {/* {`Por solo ${Math.round(creditInterestConditions.interestRate*100)}% EA`} */}
                                        {`Intereses`}
                                    </Typography>
                                    <Typography  display="inline" style={{fontSize: "0.6em"}}>
                                        {` (${Math.round(creditInterestConditions.interestRate*100)}% EA)`}
                                        {/* {` (${Math.round((((Math.pow(1+creditInterestConditions.interestRate, 30/360)) - 1)*100)*10)/10}% EM) `} */}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} >
                                    <Typography className={classes.dialogStyle} style={{fontSize:"0.95rem"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(interestState)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} >
                                    <Tooltip title={rateText} placement="left">
                                        <Typography>
                                            <BsInfoCircle size={13}/>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                { creditCostsConditions.costsWithoutVATFee > 0 ?
                                    <>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography style={{fontSize:"0.95rem"}} >
                                        Servicio de Fianza (FG)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle} style={{fontSize:"0.95rem"}}>
                                            {/* {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * creditInformation.initialBalance))}`} */}
                                            {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * loanAmount))}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={warrantyText} placement="left">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    {   warrantyDiscount > 0 ?
                                        <>
                                        <Grid item xs={8} sm={8} md={8}>
                                            <Typography  >
                                            Descuento Aval:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} >
                                            <Typography className={classes.dialogStyle} style={{color: "blue", fontSize:"0.95rem"}}>
                                                {`$ ${new Intl.NumberFormat('es-CO',
                                                {
                                                    maximumFractionDigits: 0,
                                                }).format(warrantyDiscount * -1)}`}
                                            </Typography>
                                        </Grid>
                                        </>
                                        :<></>
                                    }
                                    </>
                                : <></>
                                }

                                { creditCostsConditions.costsWithVAT > 0 ?
                                    <>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography  style={{fontSize:"0.95rem"}}>
                                            Costo Plataforma
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle} stye={{fontSize:"0.95rem"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO').format(creditCostsConditions.costsWithVAT)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={platformText} placement="left">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography  style={{fontSize:"0.95rem"}}>
                                            Descuento Plataforma
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle} style={{color: "blue", fontSize:"0.95rem"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO',
                                            {
                                                maximumFractionDigits: 0,
                                            }).format(platfromDiscount * -1)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={platformDiscountText} placement="left">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography  display="inline" style={{fontSize:"0.95rem"}}>
                                            {`IVA `}
                                        </Typography>
                                        <Typography  display="inline" style={{fontSize:"0.6rem"}}>
                                            {`(${VAT * 100}%)`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle} style={{fontSize:"0.95rem"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO', {
                                                maximumFractionDigits: 0,
                                            }).format(vatTax)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        {/* <Tooltip title={vatText} placement="left">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip> */}
                                    </Grid>
                                    </>
                                : <></>
                                }
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography  style={{fontWeight:"bold", fontSize:"0.95rem"}}>
                                        {creditInstallmentConditions.paymentType === "postpaid" ? "Total":"¿Cuánto necesitas?"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} >
                                    <Typography className={classes.dialogStyle} style={{color:"black", fontWeight:"bold", fontSize:"0.9rem"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(disbursedBalanceState)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} >
                                    <Tooltip title={totalPaymentText} placement="left">
                                        <Typography>
                                            <BsInfoCircle size={13}/>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </div>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} >
                            <AppButton 
                                primary 
                                width={"85%"}
                                onClick={handleInputCreditInformation}
                                // disabled={disableButton}
                                >
                                    LISTO
                            </AppButton>
                            <AppButton
                                onClick={goLogin}
                                width={"85%"}
                                style={{ marginBottom: "1rem", background: "none", color: "blue" }}
                            >
                                ¿Ya estás registrado?
                            </AppButton>
                        </Grid>
                    </Grid>
                    </>
                }
                </ContentForm>
            </div>
        </>
    )
}
