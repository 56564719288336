
// const existAccount = ledgerAccounts.find(
//     (element) =>
//       element.account === jpaAccount.account
//   );
const determineAsset = (assetsTables, assetToSearch) =>{
    // console.log(assetsTables)
    // console.log(assetToSearch)
    const  assetDefinition = assetsTables.currenciesAssets.find(
        (element) => element.name === assetToSearch || element.xrplName === assetToSearch
    ) ||
    assetsTables.stocksAssets.find(
        (element) => element.name === assetToSearch || element.xrplName === assetToSearch
    ) ||
    assetsTables.tayloredTokens.find(
        (element) => element.name === assetToSearch || element.xrplName === assetToSearch
    );

    console.log(assetDefinition)

    return new Promise((resolve, reject)=>{
        resolve(assetDefinition)
    })
    
};

const determineTypeOfAsset = (assetsTables, assetToSearch) =>{
    return new Promise((resolve, reject)=>{
        if(assetsTables.currenciesAssets.some(
            (element) => element.name === assetToSearch || element.xrplName === assetToSearch)){
                console.log("currency")
                resolve ("currency");
        }

        if(assetsTables.stocksAssets.some(
            (element) => element.name === assetToSearch || element.xrplName === assetToSearch)){
                console.log("stock")
                resolve ("stock");
        }

        if(assetsTables.tayloredTokens.some(
            (element) => element.name === assetToSearch || element.xrplName === assetToSearch)){
                console.log("tayloredToken")
                resolve ("tayloredToken");
        }
    })
}

// var epayco = require('epayco-sdk-node')({
//     apiKey: 'b459d654998c6fea2f9c6b9e1cacb960',
//     privateKey: '2e11d3dcaaf185e0530a9fe30a0fcc50',
//     lang: 'ES',
//     test: true
// })

// const epaycoPSE = () => {

//     epayco.bank.getBanks()
//     .then(function(bank) {
//         console.log(bank);
//      })
//     .catch(function(err) {
//          console.log("err:" + err);
//      });

//     var pse_info = {
//         bank: "1022",
//         invoice: "1472050778",
//         description: "pay test",
//         value: "10000",
//         tax: "0",
//         tax_base: "0",
//         currency: "COP",
//         type_person: "0",
//         doc_type: "CC",
//         doc_number: "10358519",
//         name: "testing",
//         last_name: "PAYCO",
//         email: "no-responder@payco.co",
//         country: "CO",
//         cell_phone: "3010000001",
//         ip:"190.000.000.000", /*This is the client's IP, it is required */
//         url_response: "https://ejemplo.com/respuesta.html",
//         url_confirmation: "https://ejemplo.com/confirmacion",
//         metodoconfirmacion : "GET",
    
//         //Los parámetros extras deben ser enviados tipo string, si se envía tipo array generara error.
//             extra1: "",
//             extra2: "",
//             extra3: "",
//             extra4: "",
//             extra5: "",
//             extra6: ""
        
//     }

//     epayco.bank.create(pse_info)
//     .then(function(bank) {
//         console.log(bank);
//     })
//     .catch(function(err) {
//         console.log("err: " + err);
//     });
// }

export {determineAsset, determineTypeOfAsset}