import React from 'react';
import { getTrustClients } from "../../utilityFunctions/DealsUtil";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { Grid, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Colors from "../../components/ui/Colors";
import { useHistory } from "react-router-dom";

let allClients;

let clientSearch = {
    id: "",
    name: "",
    sector: "",
};

export default function ClientsSearch(props) {

    const {loginUser} = props 

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {

        clientSearch = {
            id: "",
            name: "",
            sector: "",
        };

        loadClients();
    }, []);

    const [clientNameArray, setClientNameArray] = React.useState("");
    const [clientIdArray, setClientIdArray] = React.useState("");
    const [clientSectorArray, setClientSectorArray] = React.useState("");

    const loadClients = async () => {
        allClients = await getTrustClients(loginUser.jwtToken);
 
        setClientNameArray(
             {
                 options: allClients,
                 getOptionLabel: (option) => option.name,
             }
        );

        setClientIdArray(
            {
                options: allClients,
                getOptionLabel: (option) => option.idNumber,
            }
        );

        const sectorsNoDuplicates = [...new Set(allClients.map((el) => {return el.economicSector}))];
        console.log(sectorsNoDuplicates);

        const sectorsAutocomplete = sectorsNoDuplicates.map((sector) => {return {economicSector: sector}})
        console.log(sectorsAutocomplete);

        setClientSectorArray(
            {
                options: sectorsAutocomplete,
                getOptionLabel: (option) => option.economicSector,
            }
        );
 
     };

    const autocompleteName = (event, value) => {
        console.log(value);
        clientSearch.name = value.name;
    };

    const autocompleteId = (event, value) => {
        console.log(value);
        clientSearch.id = value.id;
        
    };

    const autocompleteSector = (event, value) => {
        console.log(value);
        clientSearch.sector = value.economicSector;
    };

    const onChange = () => {

    };

    const submitSearch = () => {
        let infoSearched;
        console.log(clientSearch)
        if(clientSearch.id){
            infoSearched = allClients.find((client) => client.id === clientSearch.id);
            console.log(infoSearched);
            history.push({ 
                pathname: "/commercial/clientDetail",
                state:{
                    client: infoSearched
                }
            });
            return;
        }

        if(clientSearch.name && clientSearch.sector){
            infoSearched = allClients.filter((client) => client.name === clientSearch.name && client.sector === clientSearch.sector );
            console.log(infoSearched);
            //history.push
            return;
        }

        if(clientSearch.name){
            infoSearched = allClients.find((client) => client.name === clientSearch.name);
            console.log(infoSearched);
            history.push({ 
                pathname: "/commercial/clientDetail",
                state:{
                    client: infoSearched
                }
            });
            return;
        }

        if(clientSearch.sector){
            infoSearched = allClients.filter((client) => client.economicSector === clientSearch.sector);
            console.log(infoSearched);
            history.push({ 
                pathname: "/commercial/sectorslist",
                state:{
                    sectors: infoSearched
                }
            });
            return;
        }
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Autocomplete
                            {...clientNameArray}
                            id="clientsNames"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Cliente no existe"}
                            // sx={{ width: 600 }}
                            onChange={autocompleteName}
                            onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Nombre cliente" variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Autocomplete
                            {...clientIdArray}
                            id="clientsIds"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Cliente no existe"}
                            // sx={{ width: 600 }}
                            onChange={autocompleteId}
                            onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Identificación cliente" variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Autocomplete
                            {...clientSectorArray}
                            id="sectors"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Sector no existe"}
                            // sx={{ width: 600 }}
                            onChange={autocompleteSector}
                            onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Sector económico" variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "3.5rem"}}>
                    <Button onClick={submitSearch} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                        BUSCAR
                    </Button>
                </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
