import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import swal from "sweetalert";

const restrictedCharacters = /[ \-+()]/;

export default function ContactLocation(props) {
    const{
        dataForParent
    }=props

    const handleChange = (event) => {
        dataForParent(event);
    }; 

    const handleChangePhone = (event) => {
      if(restrictedCharacters.test(event.target.value) ){
        // || event.target.value.length < 10
        swal({
          title: "Formato incorrecto",
          text: `\n Incluye solo los 10 números del celular sin espacios o guiones (-) 
          `,
          icon: "error",
          button: "Entiendo"
        }).then(()=>{
          setValue("");
        });
        return;
      }else{
        setValue(event.target.value);
        dataForParent(event);
      }
      
  }; 
    const[value, setValue] = React.useState("");
    return (
        <React.Fragment>
          <div
            style={{
              // display: "flex",
              // flexGrow: "1",
              width: "100%",
              // height: "80%",
              justifyContent: "center",
              // alignContent:"center",
              
            }}
          >
            <Typography variant="h6" gutterBottom>
              Información Ubicación y Contacto
            </Typography>
            
             <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                <TextField
                  
                  id="nationality"
                  name="nationality"
                  label="Nacionalidad"
                  fullWidth
                  autoComplete="given-nationality"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  
                  id="birthCountry"
                  name="birthCountry"
                  label="País de nacimiento"
                  fullWidth
                  autoComplete="given-nationality"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  
                  id="homeCountry"
                  name="homeCountry"
                  label="País de residencia"
                  fullWidth
                  autoComplete="given-nationality"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="Address"
                  name="Address"
                  label="Dirección"
                  fullWidth
                  autoComplete="given-address"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="phone"
                  type="tel"
                  name="phone"
                  label="Numero de teléfono"
                  fullWidth
                  value={value}
                  autoComplete="given-phone"
                  onChange={handleChangePhone}
                  inputProps={{ maxLength: 10, minLength: 10}}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="email"
                  name="email"
                  label="Correo electrónico"
                  fullWidth
                  autoComplete="given-email"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
        </React.Fragment>  
    );
}
