const simulatorExplanation = `Simula tu crédito para que conozcas las condiciones.

A medida que deslices los botones vas a poder ver el resultado simulado del crédito.

Posteriormente y solo si aceptas, te pediremos crear un usuario en nuestra plataforma y algunos datos`;

const registerExplanation = `Ingresa un usuario, contraseña y correo electrónico para continuar al siguiente paso.

Sigue las instrucciones en las condiciones de seguridad de la contraseña`;

const piiFormExplanation = `Llena la información solicitada para continuar con la creación. 

Recuerda leer la autorización de privacidad para que puedas aceptar el uso que le daremos a tu información personal`;

const authenticationExplanation = `Te llegará un mesnsaje o llamada al celular que registraste con un código. 
Por favor ingresa el código en el espacio correspondiente`;

const loginExplanation = `Selecciona tu usario para ingresar. Por algunas condiciones de tu dispositivo te podemos pedir que ingreses nuevamente el usuario y contraseña, esto es por tu seguridad`;

const creditExplanation = `A continuación encontrarás las condiciones finales del crédito que simulaste y los documentos que formalizan la solicitud. Solo acepta si estás de acuerdo. Te invitamos a leer con atención todos los documentos.`;

const loggedSimulatorExplanation = `Simula tu crédito para que conozcas las condiciones.

A medida que deslices los botones vas a poder ver el resultado simulado del crédito.

Posteriormente, encontratas las condiciones finales del crédito que simulaste y solicitaste y los documentos que formalizan la solicitud. Solo acepta si estás de acuerdo. Te invitamos a leer con atención todos los documentos.`;

const handleCreditExplanation = `Administra tu crédito de forma sencilla. Puedes revisar las condiciones de tu crédito, la fecha del próximo pago y hacer los pagos`;

const createAccountExplanation = ``;

const handleAccountsExplanation = ``;

const paymentExplanation = ``;

const F2Explanation = `Activar el segundo factor de autenticación implica pasos adicionales que debes realizar al ingresar al sistema.

Si estás seguro de cambiar el segundo factor de autenticación y sus implicaciones, por favor ingresa la palabra Acepto`;

const awardsExplanation = `Ingresa la información solicitada para premiar con puntos`;

export {simulatorExplanation, registerExplanation, piiFormExplanation, authenticationExplanation, 
    loginExplanation, creditExplanation, loggedSimulatorExplanation, handleCreditExplanation, F2Explanation,
    awardsExplanation }