import React, { useState } from "react";
import swal from "sweetalert";
import Content from "../../layout/Content";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import AppInput from "../../components/AppInput";

const keyValues = ["XRP", "ETH", "BTC", "USD"];

export default function ProfitViewer(props) {
  const { handleProfitChange, handleClickOK, wuposProfit, spreadDist } = props;

  const [edit, setEdit] = useState(false);
  const [updateData, setUpdateData] = useState(spreadDist);

  const handleConfirm = () => {
    if (edit) {
      setEdit(false);
    } else {
      handleClickOK();
    }
  };

  const validateSpreadData = () => {
    return (
      keyValues
        .map((key) => updateData[`spreadShare${key}`])
        .reduce((a, b) => a + b, 0) === 1
    );
  };

  const handleSpreadChange = () => {
    if (edit) {
      if (validateSpreadData()) {
        const update = {
          shareXRP: updateData.spreadShareXRP,
          shareETH: updateData.spreadShareETH,
          shareBTC: updateData.spreadShareBTC,
          shareUSD: updateData.spreadShareUSD,
        };
        handleProfitChange(update);
        setEdit(false);
      } else {
        swal({
          title: "SUMA DIFERENTE A 100%",
          text: `\n La distribución del spread debe sumar 100%`,
          icon: "error",
          buttons: {
            cancel: "Entiendo",
          },
        });
      }
    } else {
      setEdit(true);
    }
  };

  const onChangeInfo = (value, key) => {
    if (key === "total") {
      setUpdateData({
        ...updateData,
        spreadValue: value,
      });
    } else {
      setUpdateData({
        ...updateData,
        [`spreadShare${key}`]: value,
      });
    }
  };

  return (
    <>
      <PageLabel>{edit ? "CAMBIOS - %" : "WUPO'S PROFIT"}</PageLabel>
      <Content>
        <Content style={{ padding: "20px 0" }}>
          <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "70%", textAlign: "center" }}>
              <h3>Spread Total</h3>
            </div>
            <div
              style={{ width: "30%", display: "flex", alignItems: "center" }}
            >
              {edit ? (
                <AppInput
                  onChangeInfo={onChangeInfo}
                  defaultValue={updateData.spreadValue * 100}
                  index={"total"}
                  bold={true}
                />
              ) : (
                <h3>{updateData.spreadValue * 100}%</h3>
              )}
            </div>
          </div>
          {keyValues.map((el) => (
            <div
              style={{ width: "100%", display: "flex", marginBottom: "10px" }}
              key={el}
            >
              <div
                style={{
                  width: "40%",
                  textAlign: "right",
                  paddingRight: "15px",
                }}
              >
                {
                  wuposProfit && wuposProfit.find((e) => e.assetCode === el)?.accountBalance
                    ?.netBalance
                }
              </div>
              <div style={{ width: "30%" }}>{el}</div>
              <div
                style={{ width: "30%", display: "flex", alignItems: "center" }}
              >
                {edit ? (
                  <AppInput
                    onChangeInfo={onChangeInfo}
                    defaultValue={updateData[`spreadShare${el}`] * 100}
                    index={el}
                  />
                ) : (
                  `${updateData[`spreadShare${el}`] * 100}%`
                )}
              </div>
            </div>
          ))}
        </Content>
        <AppButton
          primary
          onClick={handleConfirm}
          style={{ marginBottom: "1rem" }}
        >
          {edit ? "ATRÁS" : "OK"}
        </AppButton>
        <AppButton onClick={handleSpreadChange}>CAMBIAR</AppButton>
      </Content>
    </>
  );
}
