import React from 'react';
import { PuffLoader } from "react-spinners";
import { Box } from '@mui/system';
import { getDelivery } from "../../utilityFunctions/DeliveryUtil";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import {
    useLocation
  } from "react-router-dom"
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let deliveryReq={
    id: "",
    newStatus: "",
};

export default function PublicDetail() {

    let history = useHistory();
    const location = useLocation();

    React.useEffect(() => {

        getParams();

    }, []);

    const getParams = () => {
        
        const queryParameters = new URLSearchParams(location.search);
        console.log(queryParameters.get("id"));

        deliveryReq.id=queryParameters.get("id");

        loadDeliveries();
    };

    const loadDeliveries = async () => {
        
        const ordersData = await getDelivery(deliveryReq);

        history.push({ 
            pathname: "/lastmile/publicDetail",
            state:{
              delivery: ordersData
            }
        });
        
    };

    return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
        <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
    </Box>
    )
}
