import React, {useEffect} from 'react'
import makeStyles from '@mui/styles/makeStyles';
import Colors from "../../components/ui/Colors";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { ClientRol } from '../admin';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';


const useStyles = makeStyles((theme) => ({
  
    dialogStyle:{
      whiteSpace: "pre-line",
      
    },
  
    dialog:{
      backgroundColor: "#DCDCDC",
    },
    
    linkStyle:{
      cursor: "pointer",
      color: "inherit",
      textAlign: "center"
    },
  
    actionArea: {
      borderRadius: 16,
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        backgroundColor: Colors.primary,
      },
    },
  
    cardImage:{
      width: "20%",
      justifySelf: "center"
    }
  
  }));

  const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
  
let openAdmin=false;
let openQrys=false;
let openSettings=false;
let openDeals=false;
let openDeliveries=false;
let openMeketPlace=false;
  
export default function HanldeProfile(props) {
    const {
        loginUser, loadProductsAndSetting, updateDealsDefinitions, updateDealsList
    } = props

    let history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        
        handleAdmin();
    }, []);

    

    const toNewProgram = () => {
        history.push({pathname: "/edu_programs/new_program"});
    };

    const toModifyProgram = () => {
        history.push({pathname: "/edu_programs/modify_programs"});
    };
    const toUsersReport = () => {
        history.push({pathname: "/edu_programs/users_report"});
    };
    const toProgramsReport = () => {
        history.push({pathname: "/edu_programs/services_report"});
    };
    
    const [profileOptions, setProfileOptions] = React.useState("");

    const profileOptionsDrawer = (userProfile) => {
        console.log(userProfile);
        setProfileOptions(
            <List sx={{width: "100%"}}>
                {userProfile === "ADMIN" &&
                    <>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleExpand} sx={{justifyContent: "end"}}>   
                            <ListItemText primary={"Administrator"}/>
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}   
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openAdmin} timeout="auto">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleExpandMarketPlace} sx={{justifyContent: "center"}}>
                                    <Grid container direction="column">
                                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                            <ListItemText primary={"Administrator"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                            {openMeketPlace ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Collapse in={openMeketPlace} timeout="auto">
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => toNewProgram()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"New Program"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => toModifyProgram()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Modify Programs"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => toUsersReport()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Users Report"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => toProgramsReport()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Programs Report"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    </>
                }
                {userProfile === "USER" &&
                    <>
                    <ListItem disablePadding>
                        <ListItemButton onClick={handleExpand} sx={{justifyContent: "end"}}>   
                            <ListItemText primary={"Client"}/>
                            {openAdmin ? <ExpandLess /> : <ExpandMore />}   
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={openAdmin} timeout="auto">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton onClick={handleExpandMarketPlace} sx={{justifyContent: "center"}}>
                                    <Grid container direction="column">
                                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", flexDirection: "row"}}>
                                            <ListItemText primary={"User"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                            {openMeketPlace ? <ExpandLess /> : <ExpandMore />}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Collapse in={openMeketPlace} timeout="auto">
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => toNewProgram()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"New Program"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => toModifyProgram()} sx={{justifyContent: "center"}}>
                                                        <ListItemText primary={"Modify Programs"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                                                    </ListItemButton>
                                                </ListItem>
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </Collapse>
                    </>
                }
            </List>
        );
    };

    

    const handleExpand = () => {
        console.log("handleExpand...");
        openAdmin=!openAdmin;
        console.log(openAdmin);
        handleAdmin(); 
    };
    
    const handleExpandMarketPlace = () => {
        openMeketPlace=!openMeketPlace;
        handleAdmin(); 
    };
    

    const[userRole, setUserRole] = React.useState(null);

    const handleAdmin = () => {

        console.log("handleAdmin...")

        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
                console.log("ADMIN");
                profileOptionsDrawer("ADMIN");
                
            }

            else if(loginUser.roles.some((rol) => rol.authority === "USER")){
                console.log("USER");
                profileOptionsDrawer("USER");

            }

            else {
                profileOptionsDrawer("USER");
            }
        }

    };

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

  return (
      <React.Fragment>
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container>
                <Grid item xs={2} sm={4} md={3} lg={3} sx={{paddingLeft: "1rem"}}>
                    <Box display={{ xs: "flex", sm: "flex", md: "none", lg: "none"}} style={{justifyContent: {xs: "end", sm: "center"}, width: "100%"}}>
                        <IconButton
                            color="inherit"
                            //   edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'flex',  md: "none"} }}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                    </Box>
                    <Drawer
                        // container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'block', md: "none" },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box'},
                        }}
                        PaperProps={{
                            sx:{
                                marginTop: "5rem",
                                width: {sx: "50%", sm: "35%"},
                            }
                        }}
                        >
                        {profileOptions}
                    </Drawer>
                    <Box style={{marginTop: "5rem"}}>
                        <Drawer
                            variant="permanent"
                            sx={{
                                display: { xs: 'none', sm: 'none', md: 'flex'},
                                '& .MuiDrawer-paper': { boxSizing: 'border-box'}
                            
                            }}
                            PaperProps={{
                                sx:{
                                    marginTop: "5rem",
                                    width: "20%",
                                    // paddingLeft: "0.6rem"
                                }
                            }}
                            open
                            
                            >
                            {profileOptions}
                        </Drawer>
                    </Box>
                </Grid>
                <Grid item xs={10} sm={8} md={9} lg={9}>
                    <ClientRol {...props}/>
                </Grid>
            </Grid>
        }

       </React.Fragment>
  );
}
