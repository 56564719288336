import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import Colors from "../../components/ui/Colors";
import { useHistory } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme) => ({

    gridProducts:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        // marginTop:"0.2em",
        // marginBottom: "0.2em"
    },

    div:{
        color: Colors.primary,
        cursor: "pointer",
    },

}));

export default function AdminSettings() {

    let history = useHistory();

    const classes = useStyles();

    const[settingsInputs, setSettingsInputs] = React.useState();

    const handleRequest = (typeOfRequest)=>{
        if(typeOfRequest === "loans"){
            history.push({pathname: "/loans-settings"});
        }

        else if(typeOfRequest === "users"){
            history.push({pathname: "/admin-batch-tokens"});
        }
        else if(typeOfRequest === "demo"){
            history.push({pathname: "/commercial/demoupload"});
        }
    };


    return (
    <div
    style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "85%",
        textAlign: "center",
        alignItems: "center",
    }}
    >
        <List>
            <ListItem disablePadding>
                <ListItemButton onClick={() => handleRequest("loans")} sx={{justifyContent: "center"}}>
                    <ListItemText primary={"Avances"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={() => handleRequest("users")} sx={{justifyContent: "center"}}>
                    <ListItemText primary={"Funcionarios"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton sx={{justifyContent: "center"}}>
                    <ListItemText primary={"Cuentas"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
                <ListItemButton onClick={() => handleRequest("demo")} sx={{justifyContent: "center"}}>
                    <ListItemText primary={"Info Demo"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
                </ListItemButton>
            </ListItem>
        </List>

        {/* <Grid container spacing = {8} className={classes.gridProducts}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography style={{color: Colors.labelColor,
                    fontSize: "1.5rem",
                    textAlign: "center",
                    fontWeight: 700,
                    margin: 0,}}>
                    ESCOGE LA CONFIGURACIÓN
                </Typography>
                <div style={{height: "0.3rem", width: "13rem", backgroundColor: Colors.secondary, marginLeft: "auto", marginRight: "auto", marginTop: "0.4rem"}}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container direction="row" className={classes.gridProducts}>       
                    <Grid item xs={6} sm={6} md={6} className={classes.gridProducts}>
                        <Typography style={{fontWeight:"bolder"}}>Avances</Typography>
                        <div className={classes.div} onClick={() => handleRequest("loans")}> 
                            <GiTakeMyMoney size={70} />
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} className={classes.gridProducts}>
                        <Typography style={{fontWeight:"bolder"}}>Funcionarios</Typography>
                        <div className={classes.div} onClick={() => handleRequest("users")}>    
                            <BsFillPersonFill size={70} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid> */}
    </div>
    )
}
