import React from 'react';
import {fetchSingleClientsInformation} from "../../utilityFunctions/FetchUtil.js";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors"; 
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { BsFillPersonFill } from "react-icons/bs";
import { AiOutlineIdcard } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import AppButton from "../../components/ui/AppButton";
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
    gridData:{
        maxWidth: "600px",
        // justifyContent: "left",
        // alignItems: "right",
        marginTop:"0.2em",
        marginBottom: "0.2em",
        paddingLeft: "8em"
    },
}));

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
};

let clientInfo;

export default function OperatorInfo(props) {
    const {loginUser} = props

    const classes = useStyles();
    const history = useHistory();

    const[loading, setLoading] = React.useState(false);
    const[profile, setProfile] = React.useState('');

    React.useEffect(() => {
        setLoading(true);
            clientInfo = props.location.state.clientInfo;
            setProfile(
                <Card >
                    <div style={{backgroundColor: Colors.fifth, width: "100%"}}>
                        <Grid container direction="row" className={classes.gridInputs}>
                            <Grid item xs={12} sm={12} md={12}> 
                                <BsFillPersonFill size={60} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}> 
                                <Typography gutterBottom variant="body2" component="div">
                                {`Último ingreso: ${new Intl.DateTimeFormat("es-CO", 
                                    dateOptions).format(new Date(clientInfo.lastLoginRender))}`}     
                                </Typography>
                                
                            </Grid>
                        </Grid>
                    </div>
                    <CardContent>  
                        <Grid container direction="row" spacing={4} className={classes.gridInputs}> 
                            <Grid item xs={12} sm={12} md={12}> 
                                <Typography gutterBottom variant="h5" component="div">
                                    {`${clientInfo.name} ${clientInfo.lastName}`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={4} className={classes.gridData}> 
                            <Grid item xs={2} sm={2} md={2}>
                                <AiOutlineIdcard size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.idNumber}`}
                                </Typography>   
                            </Grid>

                            <Grid item xs={2} sm={2} md={2}>
                                <FaMobileAlt size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.phone}`}
                                </Typography>   
                            </Grid>

                            <Grid item xs={2} sm={2} md={2}>
                                <HiOutlineMail size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={10} style={{textAlign: "start"}}>
                                <Typography variant="body2" color="text.secondary">
                                    {`${clientInfo.email}`}
                                </Typography>   
                            </Grid>
                        </Grid>
                    </CardContent>
                    <AppButton 
                        primary
                        width={"45%"}
                        onClick={handleClick}
                    >
                        LISTO
                    </AppButton>
                    
                </Card>
            );
            setLoading(false);

    }, []);

    const handleClick = () =>{
        history.push({pathname: "/operator-role"});
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "75%",
                textAlign: "center"
            }}
        >
            {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
               profile
            }
        </div>
    )   
}
