import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {getInvestmentAgreement} from "./../../../utilityFunctions/MarketPlaceUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
  // weekday: "short",
  day: "numeric",
  month: "numeric",
  year: "numeric",
};

let clientPurchases;

export default function ClientPurchases(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        clientPurchases="";
        renderPurchases();
    }, []);

    const initialValues = () => {
        if(props.location.state && props.location.state.user){
          console.log(props.location.state.user);
          return props.location.state.user;
        }
    };

    const [purchases, setPurchases] = React.useState();

    const renderPurchases = async () => {

        clientPurchases = await initialValues();
        console.log(clientPurchases);

        if(!clientPurchases){
          return;
        }

        setPurchases(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
              <Grid container spacing={2}>
                {clientPurchases.map((purchase) => {
                  return(
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                      <ListItemButton onClick={() => getDoc(purchase)}>
                        <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                                {`${purchase.code}`}
                            </Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                            <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                                {`${purchase.username}`}
                            </Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                                {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(purchase.investmentDate))}`}
                            </Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={3} sm={2} md={2} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                            <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                                {`$ ${new Intl.NumberFormat('es-CO').format(purchase.initialBalance)}`}
                            </Typography>
                        </Grid>
                        <Grid key={generateRandomKey()} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                              {`${new Intl.NumberFormat('es-CO').format(purchase.investmentShare * 100)} %`}
                            </Typography>
                        </Grid>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </Grid>
            </List>
          );
    
      };

      const getDoc = async (purchase) => {
        const docRequest = {
          id: purchase.id,
        };
        setLoading(true);
        await getInvestmentAgreement(docRequest, loginUser.jwtToken);
        setLoading(false);

      };

      const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
      };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container sx={{marginTop: "1.2rem"}}>
                    <Grid key={generateRandomKey()} item xs={4} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Lote</Typography>
                    </Grid>
                    <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Usuario</Typography>
                    </Grid>
                    <Grid key={generateRandomKey()} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha</Typography>
                    </Grid>
                    <Grid key={generateRandomKey()} item xs={3} sm={2} md={2} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Valor</Typography>
                    </Grid>
                    <Grid key={generateRandomKey()} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Participación</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {purchases}
                </Grid>
              </Grid>
            }
            </Box>
        </Box>
    )
}
