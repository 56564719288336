import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardImg from "../img/creditcards/card2.png";
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import {getProductInfo, purchaseAuth} from "../utilityFunctions/OpenCardUtil";
import swal from "sweetalert";
import { useHistory} from "react-router-dom";

const productRequest = {
    username: "",
};

const purchaseObject = {
    username: "",
    internalCardCode: "",
    expDate: "",
    transactionAmount: "",
};

export default function PurchaseSimulator(props) {

    const{loginUser} = props;
    
    const history = useHistory();

    React.useEffect(()=> {
        fetchProducts();
    }, []);

    const[cards, setCards] = React.useState("");

    const fetchProducts = async () => {
        productRequest.username = loginUser.accountId;
        const productsResponse = await getProductInfo(productRequest, loginUser.jwtToken);
        console.log(productsResponse);

        setCards(
            {
                options: productsResponse.listGeneralProduct,
                getOptionLabel: (option) => option.cardNumber,
            }
        );
    };

    
    const[purchaseValue, setPurchaseValue] = React.useState("");

    const valueChange = (e) => {
        setPurchaseValue(e.floatValue);
        const inputValue = e.floatValue * 100;
        purchaseObject.transactionAmount = inputValue.toString().padStart(16, "0");
        console.log(purchaseObject.transactionAmount);
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        purchaseObject.internalCardCode = value.internalCode;
        purchaseObject.username = loginUser.accountId;
        purchaseObject.expDate = value.importantDate.expirationDate.substring(2, 6);
    };

    const buy = async () => {
        const purchaseResponse = await purchaseAuth(purchaseObject, loginUser.jwtToken);
        console.log(purchaseResponse);

        if(!purchaseResponse){
            swal({
                title: "Error en la Comprao",
                text: "El pago no fue exitoso. Vuelve a intentarlo más tarde",
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: "Pago Exitoso",
            icon: "success",
            button: "Súper",
        }).then(()=> {
            history.push("/creditCard/mount");
        });

    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
            sx={{
                display: "flex",
                flexGrow: "1",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                // backgroundColor: "#ededed",
            }}
            >
                <Grid container spacing={2} sx={{justifyContent: {xs: "center", sm: "center"}, alignItems: "center", width: "90%"}}>
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "100%", sm: "90%", md: "300px", borderRadius: "15px"}}}>
                        <Grid container>
                            <CardMedia
                                component="img"
                                image={CardImg}
                                alt="Card"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem", marginBottom: "1rem"}}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "80%", height: "90%"}}
                                name="purchaseValue"
                                customInput={TextField}
                                prefix={'$ '}
                                type="purchaseValue"
                                label={<Typography variant="body2">Valor Compra</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={purchaseValue}
                                onValueChange={(values) => {
                                    valueChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.05rem", marginBottom: "0.2rem"}}>
                            <Autocomplete
                                {...cards}
                                sx={{width: "80%"}}
                                id="cards"
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"Tarjeta no existe"}
                                // sx={{ width: 600 }}
                                onChange={onChangeAutocomplete}
                                // onInputChange={onChange}
                                renderInput={(params) => (
                                    <TextField {...params} label={<Typography variant="body2">Selecciona tu tarjeta</Typography>} variant="standard" />
                                )}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "3rem", marginBottom: "1rem"}}>
                            <Button onClick={buy} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                Comprar
                            </Button>
                        </Grid>
                    </Card>
                </Grid> 
            </Box>
        </Box>
    )
}
