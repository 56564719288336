import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fetchXrpl } from "../../utilityFunctions/FetchUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Content from "../../layout/Content";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function XrpAdminHandler(props) {
  const {loginUser, wupoServerUri } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [balances, setBalances] = useState(null);
  const [xrpTestnetWupoCredentials, setXrpTestnetWupoCredentials] = useState({
    xrplAddress: "",
    xrplAddressSecret: ""
  });
  const FUNCTIONTYPE = "BalanceSheet";
  let WUPOHOTXRPWALLET = "";

  const loadXrpAddressCreds = () => {
    fetch(`${wupoServerUri.devnet}/api/secure/xrpwupocreds`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${loginUser.jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json(); //Fix me: Change text for body with a json response from springboot
      })
      .then((data) => {
        setXrpTestnetWupoCredentials({xrplAddress: data.xrpAddress, xrplAddressSecret: data.xrpAddressSecret});
        WUPOHOTXRPWALLET = xrpTestnetWupoCredentials.xrplAddress;
      })
      .catch((error) => {
        console.log("Error msg JPA: " + error);
      });
  };
  useEffect(() => loadXrpAddressCreds());

  const handleConsult = () => {
    setLoading(true);
    fetchXrpl(FUNCTIONTYPE, WUPOHOTXRPWALLET)
      .then((balance) => {
        console.log("Wupo's operational balance: " + JSON.stringify(balance));
        setLoading(false);
        setBalances(balance.obligations);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Content>
      <PageLabel>XRPL BALANCE</PageLabel>
      {loading ? (
        <PuffLoader size={200} color={Colors.secondary} css={cssLoader} />
      ) : balances ? (
        <Content style={{ padding: 0 }}>
          {balances.map((balance, index) => (
            <div
              style={{ display: "flex", width: "100%", marginBottom: "18px" }}
              key={index}
            >
              <div style={{ width: "60%", textAlign: "center" }}>
                {parseFloat(balance.value).toFixed(4)}
              </div>
              <div style={{ width: "40%", textAlign: "center" }}>
                {balance.currency}
              </div>
            </div>
          ))}
          <AppButton primary onClick={() => history.push("/admin")}>
            OK
          </AppButton>
        </Content>
      ) : (
        <>
          <AppButton primary onClick={handleConsult}>
            CONSULTAR
          </AppButton>
        </>
      )}
    </Content>
  );
}
