
import {cryptoQuote} from './FetchUtil'
import {wupoServerUri} from '../Api';

function backendMexicoWithdraw(bitsoWithdrawObject, jwtToken){
    // console.log("Info for bitso withdraw: " + JSON.stringify(bitsoWithdrawObject)) // DEBUG PRINTING
    fetch(`${wupoServerUri.devnet}/api/secure/mexicoWithdraw`, {

        method: 'POST',
        body: JSON.stringify(bitsoWithdrawObject),
        headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json"                    
        }
        
    }).then(function (response){ 
        return response.json();

    }).then(function(body){
        // console.log(JSON.stringify(body)) // DEBUG PRINTING
        
    }).catch(function(error){
        console.log("Error msg: "+ error);
    });
}

async function mexicoWithdraw(mexicoWithdrawObject, jwtToken){
    // console.log("************************ENTERING THE EXCHANGE WITHDRAW****************************");
  
    // let cryptoRate = cryptoQuote('MXN', 'XRP')
        cryptoQuote('MXN', 'XRP')
        .then(cryptoRate=>{
            const symbol = 'XRP/MXN'
            const amountPrecision = mexicoWithdrawObject.amount*cryptoRate// XRP
            const amount=Math.round((amountPrecision+Number.EPSILON)*100000)/100000;
            const price = Math.round(mexicoWithdrawObject.amount) // MXN
        
            const withdrawObject={
                accountId:mexicoWithdrawObject.accountId,
                clabe:mexicoWithdrawObject.clabe,
                amount:amount,
                price: price
            }
            // console.log("json for the middle to move exchanges: " + JSON.stringify(withdrawObject)) // DEBUG PRINTING
            backendMexicoWithdraw(withdrawObject, jwtToken);
        })
    


}

export {mexicoWithdraw}