import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import Card from '@mui/material/Card';
import { useHistory } from "react-router-dom";

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

export default function ClientDetail(props) {

    const [loading, setLoading] = React.useState(false);

    let history = useHistory();

    React.useEffect(() => {
        renderClient();
    }, []);

    const[clientRender, setClientRender] = React.useState("");
    const[relatedRender, setRelatedRender] = React.useState("");

    const renderClient = () => {

        if(props.location.state && props.location.state.client){

            const clientInfo = props.location.state.client;
            console.log(clientInfo)
            
            setClientRender(
                <Grid container direction="column" sx={{justifyContent: "space-evenly", marginTop: "1.5rem"}}>
                    <Grid>
                        <Card>
                            <Grid container sx={{paddingLeft: "5.5rem"}}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>Cliente</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="h6" sx={{fontWeight: "bolder"}}>
                                        {clientInfo.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2">Tipo cliente:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{}}> {clientInfo.clientType === "fund" ? "Fondo de inversión" : "Fidecomiso"}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2">Sector económico:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{}}>{clientInfo.economicSector}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2" sx={{}}>Número celular:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{}}>{clientInfo.phone}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2" sx={{marginBottom: "1.5rem"}}>Correo electrónico:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{marginBottom: "1.5rem"}}>{clientInfo.email}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card sx={{marginTop: "2rem"}}>
                            <Grid container sx={{paddingLeft: "5.5rem"}}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>Producto</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="h6" sx={{fontWeight: "bolder"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO').format(clientInfo.trustProducts[0].value)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2">Tipo producto:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{}}> {clientInfo.clientType === "fund" ? "Fondo de inversión" : "Fidecomiso"}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2" sx={{marginBottom: "1.5rem"}}>Fecha apertura:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{marginBottom: "1.5rem"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(clientInfo.trustProducts[0].date))}`}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card sx={{marginTop: "2rem"}}>
                            <Grid container sx={{paddingLeft: "5.5rem"}}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>Financiero</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="h6" sx={{fontWeight: "bolder"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO').format(clientInfo.income)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2">Situación legal:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{}}> {clientInfo.legalSitutation}</Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography gutterBottom variant="body2" sx={{marginBottom: "1.5rem"}}>Moras reportadas:</Typography>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography gutterBottom variant="body2" sx={{marginBottom: "1.5rem"}}>{clientInfo.pastDues ? "Sí" : "No"}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            );

            setRelatedRender(

                <Card sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <Grid container sx={{paddingLeft: "7.5rem"}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>Relacionados</Typography>
                        </Grid>
                        {
                            clientInfo.trustRelateds.map((related) => {
                                return(
                                <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="body1" sx={{fontWeight: "bold"}}>
                                        {related.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5}>
                                    <Typography gutterBottom variant="body2">Relación:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body2" sx={{}}> {related.relatedType}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5}>
                                    <Typography gutterBottom variant="body2">Sector económico:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body2" sx={{}}>{related.economicSector}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5}>
                                    <Typography gutterBottom variant="body2">Número celular:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body2" sx={{}}>{related.phone}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} sx={{marginBottom: "1.5rem"}}>
                                    <Typography gutterBottom variant="body2">Número celular:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7} sx={{marginBottom: "1.5rem"}}>
                                    <Typography gutterBottom variant="body2" sx={{}}>{related.email}</Typography>
                                </Grid>
                                </>
                                )
                            })
                        }
                    </Grid>
                </Card>

            );
        }

    };

    const toClientSearch = () => {
        history.push({pathname: "/commercial/clientSearch"})
    };

    return (
        <Box
            sx={{
            display: "flex",
            flexGrow: "1",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            backgroundColor: "#ededed",
            }}
        >
            <Grid container sx={{justifyContent: "center"}}>
                <Grid item xs={12} sm={12} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <Box sx={{width: "85%", height: "100%", display: "flex"}}>
                        {clientRender}
                    </Box> 
                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{marginTop: "1.8rem",}}>
                    <Box sx={{width: "85%", display: "flex"}}>
                        {relatedRender}
                    </Box>
                    {/* <Box sx={{display: "flex", justifyContent: "center", marginTop: "4rem"}}>
                        <Button onClick={toClientSearch} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                            Atras
                        </Button>
                    </Box> */}
                </Grid>
            </Grid>
        </Box>
    )
}
