import React, { useRef, useEffect, useState } from "react"
import "../css/accountStyle.css"
import { FaUser } from "react-icons/fa";
import { RiMoneyDollarCircleFill } from "react-icons/ri"
import { AiFillLock } from "react-icons/ai";
import { FiShoppingCart } from "react-icons/fi";

export default function Input(props) {
  const {
    onChangeInfo,
    eraseInput,
    inputIcon,
    value,
    type,
    length,
    label
  } = props

  const inputRef = useRef();
  const valueRef = useRef();
  const [rendering, setRendering] = useState();
  const [inputLabel, setInputLabel] = useState("información");

  useEffect(() => {
    label ? setInputLabel(label) : setInputLabel("información");

  }, []);

  function addcl() {
    const newClass = inputRef.current
    newClass.className = "accountInput focus";
  }

  function remcl() {
    const newClass = inputRef.current
    if (!value)
      newClass.className = "accountInput";
  }

  useEffect(() => {
    if (eraseInput) {
      const erase = valueRef.current;
      erase.value = "";
    }
    if (value)
      inputRef.current.className = "accountInput focus";
  })

  useEffect(() => {
    if (inputIcon === "assetAmount") {
      setRendering(
        <RiMoneyDollarCircleFill className="i" />
      )
    } else if (inputIcon === "user") {
      setRendering(
        <FaUser className="i" />
      )

    } else if (inputIcon === "token") {
      setRendering(
        <AiFillLock className="i" />
      )

    } else if (inputIcon === "retailCode") {
      setRendering(
        <FiShoppingCart className="i" />
      )
    } 

    else {
      setRendering(
        <FaUser className="i" />
      )
    }
  }, [inputIcon])

  return (
    <div ref={inputRef} className="accountInput">
      {/* <FaUser className="i"/> */}
      {rendering}
      <div className="creationDiv">
        <h5 className="accountLabel" id="label">{inputLabel}</h5>
        <input onFocus={addcl}
          onBlur={remcl}
          onChange={onChangeInfo}
          // type="text"
          className="input"
          autoComplete="off"
          required
          ref={valueRef}
          value={length ? value.substring(0, length) : value}
          type={type ? type : "text"}
        />
      </div>
    </div>
  )
}
