import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const occupations = [
    
    {
      value: 'invalid',
      label: 'Ocupación',
    },
    {
      value: 'lowRisk1',
      label: 'Empleado',
    },
    {
      value: 'risk1',
      label: 'Casinos y apuestas',
    },
    {
      value: 'risk2',
      label: 'Servicios monetarios',
    },
    {
      value: 'risk3',
      label: 'instituciones financieras no bancarias',
    },
    {
      value: 'risk4',
      label: 'Cajeros privados',
    },
    {
      value: 'risk5',
      label: 'Organizaciones benéficas o sin ánimo de lucro',
    },
    {
      value: 'risk6',
      label: 'Metales y piedras preciosas',
    },
    {
        value: 'risk8',
        label: 'Agencia de viajes',
      },
      {
        value: 'risk9',
        label: 'Transporte comercial transnacional no terrestre',
      },
      {
        value: 'risk10',
        label: 'Campañas y partidos políticos',
      },
      {
        value: 'risk11',
        label: 'Ganadería',
      },
      {
        value: 'risk12',
        label: 'Equipos de fútbol',
      },
      {
        value: 'risk13',
        label: 'Compra y venta de vehículos lujosos y yates',
      },
      {
        value: 'risk14',
        label: 'Comercializadores de obras de arte y antiguedades',
      },
      {
        value: 'risk15',
        label: 'Administración de recursos públicos',
      },
      {
        value: 'lowRisk2',
        label: 'Otros negocios comerciales o de servicios',
      },
];

export default function Activity(props) {
    const{
        dataForParent,
    }=props

    const [occupation, setOccupation] = React.useState("invalid");
    const handleChange = (event) => {
        setOccupation(event.target.value);
        dataForParent(event);
      };
    
    const [value, setValue] = React.useState('0-10 millones de pesos colombianos');
    const handleIncome = (event) => {
        setValue(event.target.value);
        dataForParent(event);
    };  
    const [tax, setTax] = React.useState('default');
    const handleTax = (event) => {
        setTax(event.target.value);
        dataForParent(event);
    }; 

    const handleCashbackCode = (event) => {
      dataForParent(event);
    }

    return (
        <React.Fragment>
          <div
            style={{
              // display: "flex",
              // flexGrow: "1",
              // width: "100%",
              // height: "80%",
              justifyContent: "center",
              // alignContent:"center",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Ocupación e Información Financiera
            </Typography>
            
             <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        
                        id="occupation"
                        name="occupation"
                        select
                        label="Ocupación"
                        value={occupation}
                        onChange={handleChange}
                        helperText="Selecciona tu ocupación"
                    >
                        {occupations.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                      <FormLabel component="legend">Ingresos Mensuales (Millones COP)</FormLabel>
                      <RadioGroup aria-label="income" name="income" value={value} onChange={handleIncome}>
                          <FormControlLabel value="0-5" control={<Radio />} label="0 a 5" />
                          <FormControlLabel value="5-10" control={<Radio />} label="5 a 10" />
                          <FormControlLabel value="10-20" control={<Radio />} label="10 a 20"/>
                          <FormControlLabel value="20-more" control={<Radio />} label="Más de 20"/>
                      </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl component="fieldset">
                      <FormLabel component="legend">Declaras impuestos en otro país</FormLabel>
                          <RadioGroup aria-label="tax" name="tax" value={tax} onChange={handleTax}>
                              <FormControlLabel value="yes" control={<Radio />} label="Sí" />
                              <FormControlLabel value="No" control={<Radio />} label="No" />
                          </RadioGroup>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <TextField
                    
                    id="merchantCode"
                    name="merchantCode"
                    label="Código cashback"
                    fullWidth
                    autoComplete="given-nationality"
                    onChange={handleCashbackCode}
                  />
                  </Grid>
            </Grid>
          </div>
        </React.Fragment>  
    );
}
