import React from "react";
import { FaPencilAlt } from "react-icons/fa";
import Colors from "./ui/Colors";

export default function AppInput(props) {
  const { onChangeInfo, defaultValue, index, bold } = props;

  return (
    <div style={{ position: "relative" }}>
      <FaPencilAlt
        style={{
          position: "absolute",
          bottom: "5px",
          left: 0,
          fontSize: "12px",
          color: Colors.secondary,
        }}
      />
      <input
        style={{
          outline: "none",
          borderTop: "none",
          borderLeft: "none",
          borderRight: "none",
          borderBottom: "1px solid #333",
          padding: "0 18px",
          width: "100%",
          fontSize: "18px",
          textAlign: "right",
          fontWeight: bold ? "bold" : "initial",
        }}
        onChange={(e) => onChangeInfo(parseFloat(e.target.value) / 100, index)}
        type="text"
        autoComplete="off"
        required
        defaultValue={defaultValue || ""}
        maxLength={3}
        key={index}
      />
      <span
        style={{
          position: "absolute",
          bottom: "2px",
          right: 0,
          fontSize: "15px",
        }}
      >
        %
      </span>
    </div>
  );
}
