import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import ContentForm from "../../layout/ContentForm";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Input from "../../components/Input";
import AssetSelect from "../../components/AssetSelect";
import Colors from "../../components/ui/Colors";
import { xrpPayment, createTrustline } from "../../utilityFunctions/Paymentutil";
import { xrplSecretFetch } from "../../utilityFunctions/FetchUtil";
import api from "../../Api";
import operationUnsuccesful from "../../Texts/OperationResult.js";
import {trustlineLimitValue} from "../../Api";
import { determineAsset } from "../../utilityFunctions/Util";
import Grid from '@mui/material/Grid';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const steps = [
  {
    key: "active",
    name: "ACTIVO",
    value: "",
  },
  {
    key: "id",
    name: "USUARIO",
    value: "",
  },
  {
    key: "token",
    name: "TOKEN",
    value: "",
  },
];

let stockCreation = false;

let WUPOADDRESS = ""; 
let WUPOSECRET = ""; 

const QUALITYIN = 1;
const QUALITYOUT = 1;

let trustline = {
  currency: "",
  counterparty: "",
  limit: "",
  qualityIn: QUALITYIN,
  qualityOut: QUALITYOUT,
};

 //Retry counter
 let retryCount=0;

export default function CreateAccount(props) {
  const {
    ledgerAccounts,
    loginUser,
    retailerTokens,
    wupoServerUri,
    updateLedgerAccounts,
    stockOrderSymbol,
    enableAccountAsset,
    enablePaymentValue,
    wupoAvailableAssets,
    loadProductsAndSetting,
  } = props;


  let history = useHistory();

  const [stepNumber, setStepNumber] = useState(0);
  const [inputText, setInputText] = useState("");
  const [inputCode, setInputCode] = useState("");
  const [fetchConcluded, setFetchConcluded] = useState(false);
  const [cardRender, setCardRender] = useState("");
  const [xrpTestnetWupoCredentials, setXrpTestnetWupoCredentials] = useState({xrplAddress: "", xrplAddressSecret: ""});

  const accountInput = {
    accountId: loginUser.accountId,
    accountRelationship: "CHILD",
    linkType: "ILP_OVER_HTTP",
    assetCode: "",
    assetScale: String(6), 
    customSettings: {
      "ilpOverHttp.incoming.auth_type": "SIMPLE",
      "ilpOverHttp.incoming.simple.auth_token": loginUser.ilpBearerToken,
    },
    sendRoutes: "true",
    receiveRoutes: "true",
  };

  let jpaAccount = {
    accountId: loginUser.accountId,
    xrplAddress: "",
    accountIdToken: loginUser.ilpBearerToken,
    xrplAddressSecret: "",
    account: loginUser.accountId,
    asset: "",
    stockAsset: "",
    retailCashBackCode: ""
  };

  const payment = {
    source: {
      address: "senderAddress",
      maxAmount: {
        value: "senderValue",
        currency: "senderAsset",
        //"counterparty": sendIssuer
      },
    },
    destination: {
      address: "destinationAddress",
      amount: {
        value: "destinationValue",
        currency: "destinationAsset",
        //"counterparty": destIssuer
      },
    },
  };
  
  const loadXrpAddressCreds = () => {
    fetch(`${wupoServerUri.devnet}/api/secure/xrpwupocreds`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${loginUser.jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json(); 
      })
      .then((data) => {
        setXrpTestnetWupoCredentials({xrplAddress: data.xrpAddress, xrplAddressSecret: data.xrpAddressSecret});
        // console.log(data)
        // WUPOADDRESS = xrpTestnetWupoCredentials.xrplAddress;
        // WUPOSECRET = xrpTestnetWupoCredentials.xrplAddressSecret;
        WUPOADDRESS = data.xrpAddress;
        WUPOSECRET = data.xrpAddressSecret;
        
      })
      .catch((error) => {
        console.log("Error msg JPA: " + error);
      });
  };

  const loadInitialProducts = () => {
    return (new Promise((resolve) => {
      if(props.location.state && props.location.state.fromHistory === "/login"){
        resolve(200);
      }

      loadProductsAndSetting((result) => {
        resolve(result);
      });
    }));
  };

  useEffect(() => {
    // loadInitialProducts(); // Loading done in the component that calls this one

    loadXrpAddressCreds();
    // console.log(ledgerAccounts) //DEBUG PRINTING
    swal({
      title: "¿QUÉ HAGO?",
      text: `\n Ingresa la información solicitada para crear un producto en WUPO`,
      icon: "success",
      button: "Entiendo",
    }).then(() => {
          // swal({
          //   title: "PARA ADMINS E INVERSIONISTAS",
          //   text: `\n Al crearse una cuenta ocurre los siguiente:
          //   \n \n 1. Creación de una cuenta en uno de los conectores ilp
          //   2. Creación de una nueva cuenta en el xrpl.
          //   3. ejecución de pago mínimo de habilitación de cuentas en el xrpl (20XRP). Este pago lo asume WUPO en ambiente de desarrollo.
          //   4. Consolidación de la información en ilp y xrpl para que los otros servicios afecten las dos cuentas cuando sea pertinente.
          //   \n\n Nota: En ilp se crea una cuenta por cada activo seleccionado por el mismo usuarios. En xrpl por el contrario se crea una sola cuenta con truslines por cada activo para que el pago de habilitación sea único
          //   \n\n Te invitamos a revisar la consola (En Chrome: click derecho -> inspeccionar -> console) para ver lo que pasa por detrás en tiempo real!`,
          //   icon: "success",
          //   button: "Súper",
          // });
          swal({
            title: "NUESTROS TOKENS",
            text: `\n TOKENS WUPO: QUÉ SON Y CÓMO LOS USO

            Los tokens WUPO son productos digitales que adquieres instantáneamente en el momento en el que recargas y los compras en nuestro sistema; esto quiere decir que los tokens son tuyos y los puedes usar de múltiples formas:
            
            •	Descuentos en WUPO comercios
            •	Devolución de una parte de tus compras
            •	Transferencia de tokens a otros usuarios desde y hacia otros países
            •	Compra de tokens que internamente alineamos con los precios de activos emocionantes como las acciones de las empresas más innovadoras y potentes del mundo, movimientos de monedas y precios de criptomonedas. 
            
            Aclaración: Es muy importante que te quede claro que los tokens no son un depósito con WUPO sino que representan la compra de productos digitales que puedes usar instantáneamente y sin barreras.
            
            Los tokens que adquieres pueden estar alineados a los siguientes valores:
            `,
            icon: "success",
            button: "Siguiente",
          }).then(() => {
            swal({
              title: "NUESTROS TOKENS",
              text: `\n TOKENS WUPO: QUÉ SON Y CÓMO LOS USO

              Movimiento de las acciones de empresas líderes en el mundo
              •	APL (Apple): Empresa de tecnología, líder en el mercado de computadores y celulares
              •	GOG (Google): Empresa de tecnología líder en buscadores de internet y data analytics.
              •	FCB (Facebook): La primera y más famosa red social.
              •	AMZ (Amazon): La empresa de venta de productos más grande del mundo. 
              •	TSL (Tesla): Pioneros y líderes en carros eléctricos con tecnología de punta.
              
              Monedas internacionales 
              •	USD (Dólar de Estados Unidos): La moneda más fuerte del mundo.
              •	EUR (Euro): La moneda de la Unión Europea que consolido la alianza económica más grande de la historia.
              •	MXN (Peso mexicano): La moneda del gigante del norte de Suramérica.
              •	CNY (Yuan chino): La moneda del gigante asiático.
              
              Criptomoneadas
              •	BTC (Bitcoint): La primera moneda digital que revolucionó el mundo financiero.
              •	ETH (Ethereum): La moneda digital de la plataforma financiera descentralizada más dinámica y funcional del mercado.
              `,
              icon: "success",
              button: "Súper",
            })
          });
    });
  }, []);

  useEffect(() => {
    if (!fetchConcluded) {
      if (["USUARIO", "TOKEN"].includes(steps[stepNumber].name)) {
        setCardRender(
          <Input
            value={inputText}
            onChangeInfo={(e) => setInputText(e.target.value)}
          />
        );
      } else if (steps[stepNumber].name === "ACTIVO") {
        setCardRender(
          <AssetSelect onChangeInfo={(e) => setInputText(e.target.value)} />
        );
      }
    }
  }, [fetchConcluded]);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const postJPA = async () => {
    await delay(3000);
   console.log(`retry contun: ${retryCount}`)
    //trusline here
    trustline.counterparty = WUPOADDRESS;
    trustline.currency = jpaAccount.asset;
    trustline.limit = trustlineLimitValue;
    // console.log(trustline); //LOANS DEBUG PRINTING
    // console.log(jpaAccount.xrplAddress)
    // console.log(jpaAccount.xrplAddressSecret)
    createTrustline( jpaAccount.xrplAddress, trustline, jpaAccount.xrplAddressSecret).then((result) => {
      console.log(result);
      // if(result.resultCode === "tesSUCCESS"){
        fetch(`${wupoServerUri.devnet}/api/secure/ledger/accounts`, {
          method: "POST",
          body: JSON.stringify(jpaAccount),
          headers: {
            "Authorization": `Bearer ${loginUser.jwtToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          // console.log(response) //LOANS DEBUG PRINTING
          return response.json(); 
        })
        .then((data) => {
          // console.log(data)
            // console.log("Fetch text JPA: " + data.message);
            setCardRender(<PageLabel>PRODUCTO CREADO</PageLabel>);
            swal({
              title: "CREACIÓN EXITOSA",
              icon: "success",
              button: "Súper",
            }).then(() => {
              loadProductsAndSetting("/account/handle_account", loginUser.accountId, loginUser.jwtToken).then(() => {
                history.push({pathname: "/account/create_account"});
              });
            })
            .catch((error) =>{
            console.log("Error msg JPA: " + error);
            retryCount++;
            if(retryCount<=10){
              postJPA();
            }
            else{
              retryCount=0;
              swal({
                title: "NO PUDIMOS  CREAR TU PRODUCTO",
                // text: `\n Por favor vuelve a intentarlo. 
                //     Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                //     Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                //     Jorge: XXXXXXXXXX
                //     Federico: XXXXXXXXXX`,
                text: operationUnsuccesful,
                icon: "error",
                button: "Entiendo",
              }).then(() => {
                history.push({pathname: "/account/handle_account"});
              });
            }
        });
      
        })
        .catch((error) => {
          console.log(error);
          retryCount++;
          if(retryCount<=100){
            postJPA();
          }
          else{
            retryCount=0;
            swal({
              title: "NO PUDIMOS CREAR TU PRODUCTO",
              // text: `\n Por favor vuelve a intentarlo. 
              //     Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
              //     Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
              //     Jorge: XXXXXXXXXX
              //     Federico: XXXXXXXXXX`,
              text: operationUnsuccesful,
              icon: "error",
              button: "Entiendo",
            }).then(() => {
              history.push({pathname: "/account/handle_account"});
            });
          } 
        });
      // }
    })
    .catch((error) => {
      console.log(error);
      retryCount++;
      if(retryCount<=10){
        postJPA();
      }
      else{
        retryCount=0;
        swal({
          title: "NO PUDIMOS CREAR TU PRODUCTO",
          // text: `\n Por favor vuelve a intentarlo. 
          //     Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
          //     Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
          //     Jorge: XXXXXXXXXX
          //     Federico: XXXXXXXXXX`,
          text: operationUnsuccesful,
          icon: "error",
          button: "Entiendo",
        }).then(() => {
          history.push({pathname: "/account/handle_account"});
        });
      }
    }); 
  };

  const createXRPLAccount = () => {
    api.on("error", (errorCode, errorMessage) => {
      console.log(errorCode + ": " + errorMessage);
    });
    // api.on("connected", () => {
    // console.log("connected to XRPL");
    // });
  
    api.connect().then(() => {
      /* ************Creates New Account in the XRPL************** */

      //Options Object to create the XRPL Adress
      const adressOptions = {
        test: true,
        includeClassicAddress: true,
      };
      // Generate Address and Secret
      const xrplObject = api.generateXAddress(adressOptions);

      const XRPLAddress = xrplObject.xAddress;
      //const XRPLSecret = xrplObject.secret; Currently not used variable
      //TODO: currently not using the classic address, but required for some fetching method in the Ripple API  

      //Information persisted in the integration JPA
      jpaAccount.xrplAddress = xrplObject.xAddress; //JPA POST VALUE
      jpaAccount.xrplAddressSecret = xrplObject.secret; // JPA POST VALUE
      

      /* ***************************************************************************************
      To enable the recently created XRPL address, it should receive a payment of minimum XRP 20
      The following variables declaration are hardcoded to conduct the required payment
      *************************************************************************************** */

      //Payment variables to enable the XRP Account
      const senderAsset = enableAccountAsset; //XRP to enable account
      const destinationAsset = enableAccountAsset; //XRP to enable account
      const senderValue = enablePaymentValue; //constant value. Currently = 25 (minimum 20)
      const destinationValue = enablePaymentValue; //constant value. Currently = 25 (minimum 20)


      payment.source.address = WUPOADDRESS;
      payment.source.maxAmount.currency = senderAsset;
      payment.destination.amount.value = destinationValue;
      payment.destination.amount.currency = destinationAsset;
      payment.source.maxAmount.value = senderValue;
      payment.destination.address = XRPLAddress;

      xrpPayment(payment, senderValue, XRPLAddress, WUPOSECRET)
        .then((result) => {
          if(result === "payment ok"){
            postJPA();
          }

          else{
            swal({
              title: "NO PUDIMOS CREAR TU PRODUCTO",
              // text: `\n Por favor vuelve a intentarlo. 
              //     Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
              //     Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
              //     Jorge: XXXXXXXXXX
              //     Federico: XXXXXXXXXX`,
              text: operationUnsuccesful,
              icon: "error",
              button: "Entiendo",
            }).then(() => {
              history.push({pathname: "/account/handle_account"});
            });
          }
               
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: "NO PUDIMOS CREAR TU PRODUCTO",
            // text: `\n Por favor vuelve a intentarlo. 
            //     Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
            //     Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
            //     Jorge: XXXXXXXXXX
            //     Federico: XXXXXXXXXX`,
            text: operationUnsuccesful,
            icon: "error",
            button: "Entiendo",
          }).then(() => {
            history.push({pathname: "/account/handle_account"});
          });
        });

      /* ************finish enabling payment ************ */
    }).catch((err) => {
      console.log(err);
    })
  };

  const determineInJPA = () => {
    // console.log("jpa account: " + jpaAccount.account)
    // console.log(ledgerAccounts) //DEBUG PRINTING
    const existAccount = ledgerAccounts.find(
      (element) =>
        element.account === jpaAccount.account
    );
    if (existAccount) {
      if (existAccount.asset === jpaAccount.asset) {
        //Account already exist with the same Asset
        console.log("Account already exists with the same Asset");
        swal({
          title: "NO PUDIMOS CREAR TU PRODUCTO",
          text: `\n Ya tienes este token`,
          icon: "error",
          button: "Entiendo",
        }).then(() => {
          history.push({pathname: "/account/handle_account"});
        });
      } else {
        //Account in integrator but with different asset
        console.log(
          "User already exists, but creating account with different asset"
        );
        jpaAccount = {
          ...jpaAccount,
          accountId: existAccount.account + accountInput.assetCode,
          xrplAddress: existAccount.xrplAddress,
          // xrplAddressSecret: existAccount.xrplAddressSecret,
        };
        xrplSecretFetch(existAccount.xrplAddressSecret, loginUser.jwtToken)
        .then((secret) => {
          jpaAccount.xrplAddressSecret = secret;
          postJPA();
        });
        // postJPA();
        return;
      }
    } else {
      //New Account: the account is not in the integrator
      console.log("New account");
      createXRPLAccount();
    }
  };

  const handleClick = () => {
    stockCreation = false;
    if (["USUARIO", "TOKEN"].includes(steps[stepNumber].name) && !inputText)
      return;

    if (stepNumber < steps.length - 1) {
      if (stepNumber === 0) {
        if (inputText === "Selecciona" || inputText === "") return;

        setFetchConcluded(true);

        setCardRender(
          <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
        );

        jpaAccount.retailCashBackCode=inputCode;

        let isRetailerToken=false;
        let retailerAsset="";
        
        //TODO: building assets definition from tables in the backend

        determineAsset(wupoAvailableAssets, inputText).then((assetFound) => {
          
          console.log(assetFound.xrplName);

          accountInput.assetCode=assetFound.xrplName;
          accountInput.accountId+=assetFound.xrplName;
          jpaAccount.accountId=accountInput.accountId; //value of the persisted integration object of the account
          jpaAccount.asset=assetFound.xrplName;
          jpaAccount.stockAsset=assetFound.quote;

          determineInJPA();
        })

        // retailerTokens.forEach(element=>{
        //   if(element.label===inputText){
        //       isRetailerToken=true;
        //       retailerAsset=element.asset;
        //   }
        // });

        // if(isRetailerToken){
        //   accountInput.assetCode=retailerAsset;
        //   accountInput.accountId+=retailerAsset;
        //   jpaAccount.accountId=accountInput.accountId; //value of the persisted integration object of the account
        //   jpaAccount.asset=retailerAsset;
        //   jpaAccount.stockAsset=retailerAsset;
        // }else{
        //   accountInput.assetCode = inputText;
        //   accountInput.accountId += inputText;
        //   jpaAccount.accountId = accountInput.accountId;
        //   jpaAccount.asset = inputText;

        //   stockOrderSymbol.forEach(token => {
        //     if(token.currency===inputText){
        //       jpaAccount.stockAsset = token.stockSymbol;
        //       stockCreation = true;
        //     }
        //   }); 

        //   if(stockCreation !== true){
        //     jpaAccount.stockAsset = inputText;
        //   }
  
        // }
        
        // determineInJPA();
      }
      fetchConcluded && setStepNumber(stepNumber + 1);
    }
  };

  const toHandleAccounts = () => {
    history.push('/account/handle_account');
  };

  return (
    <div
    style={{
      display: "flex",
      flexGrow: "1",
      justifyContent: "center",
    }}
    >
      <ContentForm>
        <PageLabel>{steps[stepNumber].name}</PageLabel>
        {cardRender}
        {steps[stepNumber].name === "ACTIVO"}
        <Input
            value={inputCode}
            label={"Código Empresa"}
            inputIcon={"retailCode"}
            onChangeInfo={(e) => setInputCode(e.target.value)}
          />
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <AppButton 
            primary 
            width={"80%"}
            onClick={handleClick}
            >
                Crear
            </AppButton>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <AppButton 
            style={{backgroundColor: "#87C1FF", color: "white"}}
            width={"80%"}
            onClick={toHandleAccounts}
            >
                Atrás
            </AppButton>
          </Grid>
        </Grid>
        
      </ContentForm>
    </div>
  );
}
