import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from "react-number-format";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import {modifyEduProgram, getProviderPrograms} from "../../../utilityFunctions/EduMarketUtil";
import { ukLocations } from '../../../Api';
import Autocomplete from '@mui/material/Autocomplete';
import swal from "sweetalert";
import SetScheduleDialog from '../edu_clients/SetScheduleDialog';
import {useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

const newProgramrequest ={}; // this is the object that will be sent to the backend

const ukterritories = ["England", "Scotland", "Wales", "Northern Ireland"];

const serviceTypes = ["Holyday Clubs", "After School Care", "Private Tuition", "Child Care"];

export default function ModifyPrograms(props) {

    const {loginUser} = props

    const history = useHistory();
    
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        initialValues();
    }, []);

    const [providerPrograms, setProviderPrograms] = React.useState(null);

    const initialValues = async () => {

        setLoading(true);   
        const programsResponse = await getProviderPrograms(loginUser.jwtToken);
        setLoading(false);

        if(!programsResponse){
            swal({
                title: "Error",
                text: "Please try again later",
                icon: "error",
                button: "Ok",
            });
            return;
        }

        setProviderPrograms(programsResponse);
    };

    const[title, setTitle] = React.useState("");
    const[fromAge, setFromAge] = React.useState("");
    const[toAge, setToAge] = React.useState("");
    const[price, setPrice] = React.useState("");
    const[territory, setTerritory] = React.useState("");
    const[county, setCounty] = React.useState("");
    const[city, setCity] = React.useState("");
    const[address, setAddress] = React.useState("");
    const[description, setDescription] = React.useState("");
    const[serviceType, setServiceType] = React.useState("");
    const [selectedImage, setSelectedImage] = React.useState(null);

    const [selectedProgram, setSelectedProgram] = React.useState(null);

    const selectedProgramChange = (event, value) => {
        if(!value){
            return;
        }

        newProgramrequest.id = value.id;

        setTitle(value.title);
        newProgramrequest.title = value.title;
        setFromAge(value.fromAge);
        newProgramrequest.fromAge = value.fromAge;
        setToAge(value.toAge);
        newProgramrequest.toAge = value.toAge;
        setPrice(value.price);
        newProgramrequest.price = value.price;
        setTerritory(value.territory);
        newProgramrequest.territory = value.territory;
        setCounty(value.county);
        newProgramrequest.county = value.county;
        setCity(value.city);
        newProgramrequest.city = value.city;
        setAddress(value.address);
        newProgramrequest.address = value.address;
        setDescription(value.description);
        newProgramrequest.description = value.description;
        setServiceType(value.serviceType);
        newProgramrequest.serviceType = value.serviceType;

        const byteImage = Buffer.from(value.edProgramPic.picture, 'base64');
        setSelectedImage(new Blob([byteImage], {type: "application/octet-stream"}));

        handleCurrentSchedule(value);

        setSelectedProgram(value);

        
    };

    const [currentSchedule, setCurrentSchedule] = React.useState(null);

    const handleCurrentSchedule = (program) => {

        const modifiedSchedule = program.schedule.map((time, index) => {
            return ({
                id: time.id,
                title: time.title, 
                start: new Date(time.startTime),
                end: new Date(time.endTime)
            });
        });

        console.log(modifiedSchedule);

        setCurrentSchedule(modifiedSchedule);
       
    };

    const adaptCurrentSchedule = (schedule) => {
        if (schedule && schedule.length > 0) {
            const newSchedule = {
                
                dates: schedule.map((dateEl) => ({ 
                    fromTiming: dateEl.start.toISOString(),
                    toTiming: dateEl.end.toISOString()
                })),
            };
            return newSchedule.dates;
        } 
        
        else {
            swal({
                title: "Error",
                text: "Please fill all fields",
                icon: "error",
                button: "Ok",
            });
        }
    };

    const titleChange = (e) => {
        setTitle(e.target.value);
        newProgramrequest.title = e.target.value;
    };

    const fromAgeChange = (e) => {
        setFromAge(e.floatValue);
        newProgramrequest.fromAge = e.floatValue;
    };

    const toAgeChange = (e) => {
        setToAge(e.floatValue);
        newProgramrequest.toAge = e.floatValue;
    };

    const priceChange = (e) => {
        setPrice(e.floatValue);
        newProgramrequest.price = e.floatValue;
    };

    const territoryChange = (event, value) => {
        if(!value){
            return;
        }

        setTerritory(value);
        setCounty("");
        setCity("");
        newProgramrequest.territory = value;
        newProgramrequest.county = "";
        newProgramrequest.city = "";
    };
    
    const countyChange = (event, value) => {
        setCounty(value);
        setCity("");
        newProgramrequest.county = value;
        newProgramrequest.city = "";
    };

    const cityChange = (event, value) => {
        setCity(value);
        newProgramrequest.city = value;
    };

    const addressChange = (e) => {
        setAddress(e.target.value);
        newProgramrequest.address = e.target.value;
    };

    const descriptionChange = (e) => {
        setDescription(e.target.value);
        newProgramrequest.description = e.target.value;
    };

    const serviceTypeChange = (e) => {
        setServiceType(e.target.value);
        newProgramrequest.serviceType = e.target.value;
    };

    const selectedImageChange = async (e) => {

        setSelectedImage(e.target.files[0])
    };

    const deleteSelectedImg = () => {
        setSelectedImage(null);
    };

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [calendarSchedule, setCalendarSchedule] = React.useState();

    const handleOpenDialog = () => {

        setDialogOpen(true);
       
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const addCalendarSchedule = (selectedSchedule) => {
        if (selectedSchedule && selectedSchedule.length > 0) {
            const newSchedule = {
                // timeList: selectedSchedule.map((timeEl) => ({
                //     fromTiming: moment(timeEl.start).format('HH:mm'),
                //     toTiming: moment(timeEl.end).format('HH:mm')
                // })),
                dates: selectedSchedule.map((dateEl) => ({ 
                    fromTiming: dateEl.start.toISOString(),
                    toTiming: dateEl.end.toISOString()
                })),
            };
            setCalendarSchedule(newSchedule);
        } 
        
        else {
            swal({
                title: "Error",
                text: "Please fill all fields",
                icon: "error",
                button: "Ok",
            });
        }
    };
    

    const uploadProgram = async () => {

        // newProgramrequest.schedule = schedule; //Under review
        newProgramrequest.dates = adaptCurrentSchedule(currentSchedule);

        console.log(selectedImage);
        console.log(newProgramrequest);

        setLoading(true);

        const formData  = new FormData();

        const json = JSON.stringify(newProgramrequest);
        const newProduct = new Blob([json], {
            type: 'application/json'
        });

        formData.append("selectedImage", selectedImage, "Program-pic");
        formData.append("newProduct", newProduct);


        const createProductResponse = await modifyEduProgram(formData, loginUser.jwtToken);

        setLoading(false);

        if(!createProductResponse){
            swal({
                title: "Error",
                text: "Please try again later",
                icon: "error",
                button: "Ok",
            });
            return; 
        }

        swal({
            title: "Success",
            text: "Your program has been created successfully",
            icon: "success",
            button: "Ok",
        });
        history.push("/handle_profile");
        clearAllStates();
        return;
    };

    const clearAllStates = () => {
        setTitle("");
        setFromAge("");
        setToAge("");
        setPrice("");
        setTerritory("");
        setCounty("");
        setCity("");
        setAddress("");
        setDescription("");
        setSelectedImage(null);
        setCalendarSchedule(null);
        setServiceType("");

        newProgramrequest.title = "";
        newProgramrequest.fromAge = "";
        newProgramrequest.toAge = "";
        newProgramrequest.price = "";
        newProgramrequest.territory = "";
        newProgramrequest.county = "";
        newProgramrequest.city = "";
        newProgramrequest.address = "";
        newProgramrequest.description = "";
        newProgramrequest.schedule = [];
        newProgramrequest.dates = [];
        newProgramrequest.serviceType = "";

    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "1.5rem"},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2} sx={{display: "flex", marginTop: "1rem"}}>
                        {providerPrograms &&
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Autocomplete sx={{ width: {xs: "90%", sm: "50%", md: "30%"}}}
                                    options={providerPrograms}
                                    getOptionLabel={(option) => `${option.title} - ${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(option.creationDate))}`}
                                    
                                    id="selectedProgram"
                                    name="selectedProgram"
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"Program not found"}
                                    fullWidth
                                    onChange={selectedProgramChange}
                                    // onInputChange={onChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Select one program" variant="standard" />
                                    )}
                                />
                            </Grid>
                        }
                        {selectedProgram && (
                            <>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="title"
                                    name="title"
                                    label={<Typography variant="body2">Title</Typography>}
                                    value={title}
                                    onChange={(e)=> titleChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth={true}
                                    id="description"
                                    name="description"
                                    label={<Typography variant="body2">Description</Typography>}
                                    value={description}
                                    onChange={(e)=> descriptionChange(e)}
                                    autoComplete="off"
                                    multiline
                                    rows={4}
                                    inputProps={{ maxLength: 500 }}  // Limit the number of characters to 200
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    sx={{textAlign: "start"}}
                                    variant="standard"
                                    fullWidth={true}
                                    id="serviceType"
                                    name="serviceType"
                                    select
                                    label={<Typography variant="body2">Service Type</Typography>}
                                    value={serviceType}
                                    onChange={(e)=>serviceTypeChange(e)}
                                    autoComplete="off"
                                >
                                    {serviceTypes.map((el) => (
                                        <MenuItem key={el} value={el}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "100%", height: "50%"}}
                                    name="fromAge"
                                    customInput={TextField}
                                    // prefix={'$ '}
                                    suffix={' years'}
                                    type="text"
                                    label={<Typography variant="body2">From Age</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={fromAge}
                                    onValueChange={(values) => {
                                        // const {formattedValue, value, floatValue} = values;
                                        fromAgeChange(values);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "100%", height: "50%"}}
                                    name="toAge"
                                    customInput={TextField}
                                    // prefix={'$ '}
                                    suffix={' years'}
                                    type="text"
                                    label={<Typography variant="body2">To Age</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={toAge}
                                    onValueChange={(values) => {
                                        // const {formattedValue, value, floatValue} = values;
                                        toAgeChange(values);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "100%", height: "50%"}}
                                    name="toAge"
                                    customInput={TextField}
                                    prefix={'£ '}
                                    // suffix={' years'}
                                    type="text"
                                    label={<Typography variant="body2">Price</Typography>}
                                    thousandSeparator={','}
                                    decimalSeparator={'.'}
                                    autoComplete="off"
                                    value={price}
                                    onValueChange={(values) => {
                                        // const {formattedValue, value, floatValue} = values;
                                        priceChange(values);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Autocomplete sx={{ width: "100%"}}
                                    options={ukterritories}
                                    getOptionLabel={(option) => option}
                                    value={territory}
                                    id="territory"
                                    name="territory"
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"Territory not found"}
                                    fullWidth
                                    onChange={territoryChange}
                                    // onInputChange={onChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Territory" variant="standard" />
                                    )}
                                />
                            </Grid>
                            {territory && 
                                <>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Autocomplete sx={{ width: "100%"}}
                                        options={
                                            [...new Set(
                                                ukLocations
                                                    .filter(location => location.Territory === territory)
                                                    .map(location => location.County)
                                            )]
                                        }
                                        getOptionLabel={(option) => option}
                                        value={county}
                                        id="county"
                                        name="county"
                                        clearOnEscape
                                        autoComplete = {true}
                                        noOptionsText = {"County not found"}
                                        fullWidth
                                        onChange={countyChange}
                                        // onInputChange={onChange}
                                        renderInput={(params) => (
                                            <TextField  {...params} label="County" variant="standard" />
                                        )}
                                    />
                                </Grid>
                                {county &&
                                    <>
                                    {console.log(`Teeritory: ${territory}, County: ${county}`)}
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete sx={{ width: "100%"}}
                                            options={[
                                                ...new Set(
                                                    ukLocations
                                                        .filter(location => location.Territory === territory && location.County === county)
                                                        .map(location => location.Name)
                                                )
                                            ]}
                                            getOptionLabel={(option) => option}
                                            value={city}
                                            id="city"
                                            name="city"
                                            clearOnEscape
                                            autoComplete = {true}
                                            noOptionsText = {"City or town not found"}
                                            fullWidth
                                            onChange={cityChange}
                                            // onInputChange={onChange}
                                            renderInput={(params) => (
                                                <TextField  {...params} label="City or Town" variant="standard" />
                                            )}
                                        />
                                    </Grid>
                                    </>
                                }
                                </>
                            }
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="address"
                                    name="address"
                                    label={<Typography variant="body2">Address</Typography>}
                                    value={address}
                                    onChange={(e)=> addressChange(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            {!selectedImage &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Button
                                        sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                        // variant="contained"
                                        component="label"
                                        >
                                        Upload Image
                                        <input
                                            type="file"
                                            hidden
                                            onChange={(event) => {
                                                console.log(event.target.files[0]);
                                                selectedImageChange(event);
                                            }}
                                        />
                                    </Button>
                                </Grid>
                            }
                            {selectedImage &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Grid container spacing={0} sx={{justifyContent: "center", marginTop: "2rem"}}>
                                        <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                            <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{selectedImage.name}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                            <Button onClick={deleteSelectedImg}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                                Delete
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {currentSchedule &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button onClick={handleOpenDialog} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Change Schedule
                                </Button>
                            </Grid>
                            }
                            {title && fromAge && toAge && price && territory && county && city && address && description && selectedImage && serviceType && currentSchedule &&
                                <Grid item xs={12} sm={6} md={6}>
                                    <Button onClick={uploadProgram} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                        Modify
                                    </Button>
                                </Grid>
                            }
                            </>
                        )}
                    </Grid>
                }
                {currentSchedule && (
                    <>
                    <SetScheduleDialog
                        open={dialogOpen}
                        handleClose={handleCloseDialog}
                        addCalendarSchedule={addCalendarSchedule}
                        title={newProgramrequest.title}
                        events={currentSchedule}
                        defaultStarting={currentSchedule[0].start}
                    />
                    </>
                )}
            </Box>
        </Box>
    )

}
