import styled from "@emotion/styled"


const Content = styled.div(props=>({
  width: "100%",
  maxWidth: props.maxWidth ? props.maxWidth : "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // padding: "20px 25px",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  justifyContent: "space-evenly",
}))

export default Content