import styled from "@emotion/styled"
import Colors from "./Colors"

const PageLabel = styled.p(({
  color: Colors.labelColor,
  fontSize: "2rem",
  textAlign: "center",
  fontWeight: 700,
  margin: 0,
}))

export default PageLabel