import React from 'react';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import Grid from '@mui/material/Grid';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function PaymentLoading() {

  return (
    <>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: "center"}}>
            <h1>Cargando método de pago...</h1>
          </Grid> 
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: "center"}}>
            <PuffLoader size={150} color={Colors.primary} css={cssLoader}/>
          </Grid>
        </Grid>  
    </>
  )
}
