import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Content from "../../layout/Content";
import Colors from "../../components/ui/Colors";
import {GoogleMap, useLoadScript, Marker, InfoWindow} from "@react-google-maps/api";
import api from "../../Api";
import Button from '@mui/material/Button';


const mapContainerStyle = {
  height: "100vh",
  width: "100vw",
};

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 4.852789,
  lng: -74.069936,
};

const libraries = ["places"];

const retailLocations = {

  comercioPrueba3: {
    lat: 4.847363994277114,
    long: -74.0786393755233,
    name: "Papelería Internet y Tienda CP",
    address: "Al frente de Centro Comercial Terranova"
  },

  laCanastaFamiliar: {
    lat: 4.856039,
    long: -74.063978,
    name: "Carnes Primavera",
    address: "Dentro de La Canasta Familiar que está al lado de D1"
  },

  drogueria: {
    lat: 4.853194,
    long: -74.074056,
    name: "Droguería Cerca de Jade",
    address: ""
  },

  peluqueriaDownTown: {
    lat: 4.860589,
    long: -74.062675,
    name: "Barbería DownTown",
    address: "Media cuadra de la plaza de mercado"
  },

  catHouseChia: {
    lat: 4.849569,
    long: -74.075004,
    name: "Cat House Chía: Veterinaria y peluquería",
    address: "Al lado norte de la vía"
  },

  angelBlues: {
    lat: 4.860382,
    long: -74.062835,
    name: "Angel Blues: Café y Cerveza",
    address: "Media cuadra de la plaza de mercado"
  },

  citiPharmaChia: {
    lat: 4.856798, 
    long: -74.073099,
    name: "Droguería City Pharma",
    address: "Chía"
  },

  citiPharmaCota: {
    lat: 4.817509,
    long: -74.095623,
    name: "Droguería City Pharma",
    address: "Cota"
  },

  homeVet: {
    lat: 4.8573550955666,
    long: -74.0569804532736,
    name: "Veterinaria HomeVet",
    address: "Chía"
  },

  nuestrasRaíces: {
    lat: 4.85751770606966,
    long: -74.05680920237764,
    name: "Frutería Nuestras Raíces",
    address: "Chía"
  },

  restauranteElDespacho: {
    lat: 4.856842,
    long: -74.063595,
    name: "Restaurante El Despacho: Comida a la parrilla",
    address: "Chía"
  },

  heladeriaNatuCrem: {
    lat: 4.8590007,
    long: -74.0607026,
    name: "Heladería Natu Crem",
    address: "carrera 10 8 76 CC. El Curubito"
  },

  distribuidorBimbo: {
    lat: 4.8595461,
    long: -74.061501,
    name: "Distribuidor Bimbo",
    address: "Carrera 11 No 9 - 15"
  },

  elFarolitoChia: {
    lat: 4.8619919,
    long: -74.0609885,
    name: "El Farolito Chía",
    address: "Calle 12 carrera 11 - peatonal"
  },

  sweetNana: {
    lat: 4.8571629,
    long: -74.0632296,
    name: "Sweet Nana",
    address: "Calle 6 carrera 12"
  },

  everest: {
    lat: 4.8568373,
    long: -74.0625284,
    name: "Everest",
    address: "Calle 6 carrera 11"
  },

  cafeParis: {
    lat: 4.8562662,
    long: -74.0613804,
    name: "Café París",
    address: "Carrera 10 calle 6"
  },

  panYCafe: {
    lat: 4.8562662,
    long: -74.0613804,
    name: "Pan y Café",
    address: "Carrera 6 calle 9"
  },

  parkAndRoses: {
    lat: 4.861862,
    long: -74.0605324,
    name: "parkAndRoses: Beer & Coffee",
    address: "Sobre peatonal parque principal"
  },

  laOctava: {
    lat: 4.8592391,
    long: -74.0625196,
    name: "La Octava: Cafetería y Frutería",
    address: "Carrera 12 con Calle 8"
  },

  pacandeapc: {
    lat: 4.8592391,
    long: -74.0625196,
    name: "Pacande: Heladería, cafetería y frutería",
    address: "Calle 11 No. 12 - 51"
  },

  cafeCelestialyBar: {
    lat: 4.862572781365858,
    long: -74.05638033216015,
    name: "Café Celestial y Bar",
    address: "Av Pradilla # 7 - 51"
  },

  panColiceo: {
    lat: 4.862568649794399,
    long: -74.0560550186669,
    name: "Panadería y Cafetería El Coliseo",
    address: "Av Pradilla # 7 - 07"
  },

  cafeBardelaSabana: {
    lat: 4.862583473699321,
    long: -74.05640818155818,
    name: "Café Bar de la Sabana: Empanadas",
    address: "Av Pradilla # 7 - 51"
  },

  peruSaborySazon: {
    lat: 4.862156001048328,
    long: -74.0606434321601,
    name: "Perú: Sabor y Sazón",
    address: "Calle 12 # 11 - 26"
  },

  palosDeNogal: {
    lat: 4.8603866,
    long: -74.0614410,
    name: "Restaurante Palos de Nogal",
    address: "Calle 10 # 11 - 33"
  },

  mesonBoyacense: {
    lat: 4.8670037,
    long: -74.0551992,
    name: "Restaurante El Mesón Boyacense",
    address: "Cra 9 # 19 - 90"
  },

  laVacaLoca: {
    lat: 4.8604456,
    long: -74.0617112,
    name: "Restaurante La Vaca Loca",
    address: "Calle 10 # 11 - 57"
  },

  fruteriayBroster: {
    lat: 4.860505433381379,
    long: -74.06116976098423,
    name: "Frutería y Broaster",
    address: "Cra 11 # 9 - 79"
  },

  rockyPizza: {
    lat: 4.863387041931477,
    long: -74.05998566098424,
    name: "Rock y Pizza",
    address: "Cra 11 # 13 - 47"
  },

  caliAjiRestaurante: {
    lat: 4.863128851821223,
    long: -74.06007320331273,
    name: "Cali Ají Restaurante",
    address: "Cra 11 # 13 - 39"
  },

  dejavus: {
    lat: 4.859066143717269,
    long: -74.0609377033128,
    name: "Café Bar Dejavus",
    address: "Cra 10 # 8 - 53"
  },

  coffeeComics: {
    lat: 4.85932247277969,
    long: -74.06031851865566,
    name: "Coffee Comics: Café, cocteles, desayunos, helados y jugos",
    address: "Cll 9 # 9 - 45 Local 5"
  },

  churrosyCafe: {
    lat: 4.86185979137886,
    long: -74.05865480331275,
    name: "Churros y Café",
    address: "Cra 9 # 12 - 13"
  },

  jalisco: {
    lat: 4.861810162107666,
    long: -74.0596535033128,
    name: "Gastrobar Jalisco",
    address: "Cra 9 # 12 - 13"
  },

  kajomaRestaurante: {
    lat: 4.661317923752599,
    long: -74.06402804749239,
    name: "Gastrobar Jalisco",
    address: "Cll 72 # 20B - 08"
  },

  angelsStyles: {
    lat: 4.85783957332168,
    long: -74.06138793214859,
    name: "Peluquería Angels Styles",
    address: "Calle 7 # 10 - 35"
  },

  cafeBarcelona: {
    lat: 4.860685191771126,
    long: -74.05927068796987,
    name: "Café Barcelona",
    address: "Calle 11 # 9 - 28"
  },

  panaderiaVenezolana: {
    lat: 4.8635462789359085,
    long: -74.06361846098419,
    name: "Panadería Venezolana",
    address: "Cra 15 # 12 -7"
  },

  laCumbreBogota: {
    lat: 4.8635462789359085,
    long: -74.06361846098419,
    name: "Restaurante y cocteles: La Cumbre Bogotá",
    address: "Pendiente"
  },

  exageradamenteBueno: {
    lat: 4.862504800937362,
    long: -74.05967667632709,
    name: "Restaurante: Exageradamente Bueno",
    address: "Calle 13 # 10 - 27"
  },

  rebanoBurger: {
    lat: 4.698118316317004,
    long: -74.09040537632796,
    name: "Rebaño Burger",
    address: "Carrera 73A # 81 - 47"
  },

  ambrosia: {
    lat: 4.701193929646093,
    long: -74.08961044564212,
    name: "Ambrosia: Bebidas de Dioses",
    address: "Diagonal 81h # 72c - 70"
  },
   
  bubbleWaffles: {
    lat: 4.8612827110808805,
    long: -74.05975378981995,
    name: "Bubble Waffles",
    address: "Carrera 10 # 11 - 38"
  },

  goliat: {
    lat: 4.8612827110808805,
    long: -74.05975378981995,
    name: "Veterinaria Goliat",
    address: "Tv 74b # 81f - 93"
  },

  avicolaSanMateo: {
    lat: 4.703704650640997, 
    long: -74.09027113214931,
    name: "Avícola San Mateo",
    address: "diagonal 82g # 73a - 25"
  },

  acdSorpresas: {
    lat: 4.697662252499801, 
    long: -74.09038960331357,
    name: "ACD Sorpresas",
    address: "Cra 73a # 81 - 22"
  },

  casaGadi: {
    lat: 4.863058581195869, 
    long: -74.06084653214846,
    name: "Casa Gadi: Café y Pastelería",
    address: "Calle 13 # 12 - 07"
  },

  cavaRestauranteBar: {
    lat: 4.69875006361152,  
    long: -74.08984663100776,
    name: "Cava: Restaurante Bar",
    address: "Cll 81 # 72b - 54"
  },

  polaoMiedo: {
    lat: 4.697051117406112,  
    long: -74.08984563581816 ,
    name: "Pola o Miedo",
    address: "Cll 81 # 72b - 54"
  },

  minutoGourmet: {
    lat: 4.698508701739206,  
    long: -74.09042927447794,
    name: "Minuto Gourmet: Fast Food",
    address: "Calle 81 # 73 - 22"
  },

  laEsquinaPaisa: {
    lat: 4.697787364223143,   
    long: -74.09128507647121,
    name: "La Tienda de Jero",
    address: "Calle 81 # 74 - 25"
  },

  veterinariaHuellas: {
    lat: 4.6977038251705,   
    long: -74.09393744796836,
    name: "Veterinaria Huellas",
    address: "calle 80 # 75 - 04"
  },

  bowlEnCincoMinutos: {
    lat: 4.6974732021687355,  
    long: -74.09029117447783,
    name: "Bowl en 5 Minutos",
    address: "Calle 81 # 73 - 50"
  },

  analuSalon: {
    lat: 4.698001181225407,  
    long: -74.09014761680635, 
    name: "Spa Analu",
    address: "Carrera 73a # 81 - 88"
  },

  infinity: {
    lat: 4.862498422753356,
    long: -74.06202923214846, 
    name: "Infinity Ice Cream: Malteadas y Helados",
    address: "Cra 13 # 11 - 43"
  },

  restauranteLaEsperanza: {
    lat: 4.700228873435172, 
    long: -74.08974475913487 , 
    name: "Restaurante La Esperanza",
    address: "Transversal 73A # 81F - 31 "
  },

  theDiamond: {
    lat: 4.862347342361302, 
    long: -74.0626994456413, 
    name: "The Diamond: Salón de belleza y barbería",
    address: "Cr 14 con 11 al frente del salón comunal Las Acacias "
  },

  losAsesDelSabor: {
    lat: 4.698036580316242, 
    long: -74.09089640331355, 
    name: "Los Ases del Sabor",
    address: "calle 81 # 73b - 08"
  },

  farmaMinuto: {
    lat: 4.697635016502677, 
    long: -74.09066488797068, 
    name: "Farma Minuto",
    address: "Calle 81 # 73A - 15 local 3"
  },

  peluqueriaZulma: {
    lat: 4.8624319520982775, 
    long: -74.05830118796987, 
    name: "Peluquería Zulma",
    address: "Avenida 9 # 7 - 33 Parque Principal"
  },

  papeleriaGenesis: {
    lat: 4.702328136517964, 
    long: -74.09233606098496, 
    name: "Papelería Génesis",
    address: "diagonal 82b # 76 - 33"
  },

  elBoyaco: {
    lat: 4.708742134345777,
    long: -74.10301473214926, 
    name: "Mini Mercado El Boyaco",
    address: "carrera 92 # 82 a 64"
  },

  tiendaQuirinales: {
    lat: 4.708177641900174, 
    long: -74.10230543214922, 
    name: "Tienda Quirinales",
    address: "transversal 90b # 82a - 04"
  },

  panYPAsion: {
    lat: 4.708707585959735,  
    long: -74.10296580331351, 
    name: "Pan y Pasión",
    address: "Carrera 92 # 82a 70"
  },

  espigaDorada: {
    lat: 4.706662220371328,  
    long: -74.10768806098498 , 
    name: "Panadería y Cafetería Espiga Dorada",
    address: "Transversal 94 # 80 - 20"
  },

  papeleriaMundoJoven: {
    lat: 4.71064230374205,   
    long: -74.10117214137955, 
    name: "Papelería Mundo Joven",
    address: "Carrera 92 # 85a - 09 "
  },

  KYNBroaster: {
    lat: 4.710324119017853,  
    long: -74.10375893214922 , 
    name: "KYN Broaster",
    address: "Carrera 94h # 83 - 27"
  },

  papeleriaClaqueta: {
    lat: 4.704065643229773,  
    long: -74.1094987186564, 
    name: "Papelería Claqueta",
    address: "cra 94 # 75a - 28"
  },

  HuellitasWkiPets: {
    lat: 4.860093343292854,  
    long: -74.06312614564135 , 
    name: "Huellitas de Wikipets",
    address: "Carrera 13 # 9a - 96"
  },

  tiendaNelcySuarez: {
    lat: 4.710359941194087,  
    long: -74.10671030331346, 
    name: "Tienda Nancy Suarez",
    address: "calle 82 # 94L - 61"
  },

  zabadRestaurante: {
    lat: 4.712229703463389,  
    long: -74.10251244749206 , 
    name: "Zabad Restaurante",
    address: "transversal 94L # 86 - 56"
  },

  papeleriaDiegoMahecha: {
    lat: 4.665200735623041,   
    long: -74.05059743214946, 
    name: "Papelería Diego Mahecha",
    address: "Calle 82 # 85b - 49"
  },

  supermercadoMyM: {
    lat: 4.702271821992714,   
    long: -74.11106987447774 , 
    name: "Super Mercado MyM",
    address: "Cra 94 # 73 - 33"
  },

  studioBerlin: {
    lat: 4.703690021469137,    
    long: -74.1049878591349, 
    name: "Studio Berlin Peluquería",
    address: "Carrera 90 # 76a - 14"
  },

  variedadesElCentro: {
    lat: 4.8626579715599805,    
    long: -74.06172787447711, 
    name: "Variedades El Centro",
    address: "Cll 12 # 12 - 64"
  },

  variedadesNellys: {
    lat: 4.70395380677643,     
    long: -74.10522629167076, 
    name: "Variedades Nelly",
    address: "Carrera 90 # 76a - 35"
  },

  papeleriaElBaraton: {
    lat: 4.862374210750933,     
    long: -74.06146338981993 , 
    name: "Papelería El Baratón de Chía",
    address: "Calle 12 # 35"
  },
   
};

export default function StoreMap(props) {

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  let history = useHistory();
//   const [loading, setLoading] = useState(false);

  
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  const handleOkClick = () => {
    history.push("/account/handle_account");
  };

  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
    }}
    >
      <Content maxWidth={"576px"}>
        
        <PageLabel>PUNTOS DE RECARGA WUPO</PageLabel>

        <p 
          style = {{
            color: Colors.labelColor,
            fontSize: "1rem",
            textAlign: "center",
            fontWeight: 400,
            margin: 0,
            whiteSpace: "pre-line",
            marginTop:"1rem",
            marginBottom:"0.6rem",
          }}
        >
          {`Encuentra los comercios aliados de WUPO en el mapa`}
        </p>
        <Button variant="contained" onClick={handleOkClick} sx={{marginBottom: "0.8rem", backgroundColor: Colors.primary}}>
            Atrás
        </Button>
        <GoogleMap
          id="map"
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={center}
          options={options}
          // onClick={onMapClick}
          // onLoad={onMapLoad}
        >

          <Marker
            position={{ lat: retailLocations.comercioPrueba3.lat, lng: retailLocations.comercioPrueba3.long}}
            onClick={() => {
              setSelected(retailLocations.comercioPrueba3);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.laCanastaFamiliar.lat, lng: retailLocations.laCanastaFamiliar.long}}
            onClick={() => {
              setSelected(retailLocations.laCanastaFamiliar);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
           <Marker
            position={{ lat: retailLocations.drogueria.lat, lng: retailLocations.drogueria.long}}
            onClick={() => {
              setSelected(retailLocations.drogueria);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.peluqueriaDownTown.lat, lng: retailLocations.peluqueriaDownTown.long}}
            onClick={() => {
              setSelected(retailLocations.peluqueriaDownTown);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
           <Marker
            position={{ lat: retailLocations.catHouseChia.lat, lng: retailLocations.catHouseChia.long}}
            onClick={() => {
              setSelected(retailLocations.catHouseChia);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.angelBlues.lat, lng: retailLocations.angelBlues.long}}
            onClick={() => {
              setSelected(retailLocations.angelBlues);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.citiPharmaChia.lat, lng: retailLocations.citiPharmaChia.long}}
            onClick={() => {
              setSelected(retailLocations.citiPharmaChia);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.citiPharmaCota.lat, lng: retailLocations.citiPharmaCota.long}}
            onClick={() => {
              setSelected(retailLocations.citiPharmaCota);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.homeVet.lat, lng: retailLocations.homeVet.long}}
            onClick={() => {
              setSelected(retailLocations.homeVet);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.nuestrasRaíces.lat, lng: retailLocations.nuestrasRaíces.long}}
            onClick={() => {
              setSelected(retailLocations.nuestrasRaíces);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.restauranteElDespacho.lat, lng: retailLocations.restauranteElDespacho.long}}
            onClick={() => {
              setSelected(retailLocations.restauranteElDespacho);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.heladeriaNatuCrem.lat, lng: retailLocations.heladeriaNatuCrem.long}}
            onClick={() => {
              setSelected(retailLocations.heladeriaNatuCrem);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.distribuidorBimbo.lat, lng: retailLocations.distribuidorBimbo.long}}
            onClick={() => {
              setSelected(retailLocations.distribuidorBimbo);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

           <Marker
            position={{ lat: retailLocations.elFarolitoChia.lat, lng: retailLocations.elFarolitoChia.long}}
            onClick={() => {
              setSelected(retailLocations.elFarolitoChia);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

           <Marker
            position={{ lat: retailLocations.sweetNana.lat, lng: retailLocations.sweetNana.long}}
            onClick={() => {
              setSelected(retailLocations.sweetNana);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

           <Marker
            position={{ lat: retailLocations.everest.lat, lng: retailLocations.everest.long}}
            onClick={() => {
              setSelected(retailLocations.everest);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.panYCafe.lat, lng: retailLocations.panYCafe.long}}
            onClick={() => {
              setSelected(retailLocations.panYCafe);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.cafeParis.lat, lng: retailLocations.cafeParis.long}}
            onClick={() => {
              setSelected(retailLocations.cafeParis);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.parkAndRoses.lat, lng: retailLocations.parkAndRoses.long}}
            onClick={() => {
              setSelected(retailLocations.parkAndRoses);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.laOctava.lat, lng: retailLocations.laOctava.long}}
            onClick={() => {
              setSelected(retailLocations.laOctava);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.pacandeapc.lat, lng: retailLocations.pacandeapc.long}}
            onClick={() => {
              setSelected(retailLocations.pacandeapc);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.cafeCelestialyBar.lat, lng: retailLocations.cafeCelestialyBar.long}}
            onClick={() => {
              setSelected(retailLocations.cafeCelestialyBar);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.panColiceo.lat, lng: retailLocations.panColiceo.long}}
            onClick={() => {
              setSelected(retailLocations.panColiceo);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.cafeBardelaSabana.lat, lng: retailLocations.cafeBardelaSabana.long}}
            onClick={() => {
              setSelected(retailLocations.cafeBardelaSabana);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.peruSaborySazon.lat, lng: retailLocations.peruSaborySazon.long}}
            onClick={() => {
              setSelected(retailLocations.peruSaborySazon);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.palosDeNogal.lat, lng: retailLocations.palosDeNogal.long}}
            onClick={() => {
              setSelected(retailLocations.palosDeNogal);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.mesonBoyacense.lat, lng: retailLocations.mesonBoyacense.long}}
            onClick={() => {
              setSelected(retailLocations.mesonBoyacense);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.laVacaLoca.lat, lng: retailLocations.laVacaLoca.long}}
            onClick={() => {
              setSelected(retailLocations.laVacaLoca);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.fruteriayBroster.lat, lng: retailLocations.fruteriayBroster.long}}
            onClick={() => {
              setSelected(retailLocations.fruteriayBroster);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.rockyPizza.lat, lng: retailLocations.rockyPizza.long}}
            onClick={() => {
              setSelected(retailLocations.rockyPizza);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.caliAjiRestaurante.lat, lng: retailLocations.caliAjiRestaurante.long}}
            onClick={() => {
              setSelected(retailLocations.caliAjiRestaurante);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.dejavus.lat, lng: retailLocations.dejavus.long}}
            onClick={() => {
              setSelected(retailLocations.dejavus);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.coffeeComics.lat, lng: retailLocations.coffeeComics.long}}
            onClick={() => {
              setSelected(retailLocations.coffeeComics);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.churrosyCafe.lat, lng: retailLocations.churrosyCafe.long}}
            onClick={() => {
              setSelected(retailLocations.churrosyCafe);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.jalisco.lat, lng: retailLocations.jalisco.long}}
            onClick={() => {
              setSelected(retailLocations.jalisco);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.kajomaRestaurante.lat, lng: retailLocations.kajomaRestaurante.long}}
            onClick={() => {
              setSelected(retailLocations.kajomaRestaurante);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.angelsStyles.lat, lng: retailLocations.angelsStyles.long}}
            onClick={() => {
              setSelected(retailLocations.angelsStyles);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.cafeBarcelona.lat, lng: retailLocations.cafeBarcelona.long}}
            onClick={() => {
              setSelected(retailLocations.cafeBarcelona);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.panaderiaVenezolana.lat, lng: retailLocations.panaderiaVenezolana.long}}
            onClick={() => {
              setSelected(retailLocations.panaderiaVenezolana);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.laCumbreBogota.lat, lng: retailLocations.laCumbreBogota.long}}
            onClick={() => {
              setSelected(retailLocations.laCumbreBogota);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.exageradamenteBueno.lat, lng: retailLocations.exageradamenteBueno.long}}
            onClick={() => {
              setSelected(retailLocations.exageradamenteBueno);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.rebanoBurger.lat, lng: retailLocations.rebanoBurger.long}}
            onClick={() => {
              setSelected(retailLocations.rebanoBurger);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.ambrosia.lat, lng: retailLocations.ambrosia.long}}
            onClick={() => {
              setSelected(retailLocations.ambrosia);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.bubbleWaffles.lat, lng: retailLocations.bubbleWaffles.long}}
            onClick={() => {
              setSelected(retailLocations.bubbleWaffles);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.goliat.lat, lng: retailLocations.goliat.long}}
            onClick={() => {
              setSelected(retailLocations.goliat);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.avicolaSanMateo.lat, lng: retailLocations.avicolaSanMateo.long}}
            onClick={() => {
              setSelected(retailLocations.avicolaSanMateo);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.acdSorpresas.lat, lng: retailLocations.acdSorpresas.long}}
            onClick={() => {
              setSelected(retailLocations.acdSorpresas);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.casaGadi.lat, lng: retailLocations.casaGadi.long}}
            onClick={() => {
              setSelected(retailLocations.casaGadi);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.cavaRestauranteBar.lat, lng: retailLocations.cavaRestauranteBar.long}}
            onClick={() => {
              setSelected(retailLocations.cavaRestauranteBar);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.polaoMiedo.lat, lng: retailLocations.polaoMiedo.long}}
            onClick={() => {
              setSelected(retailLocations.polaoMiedo);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.minutoGourmet.lat, lng: retailLocations.minutoGourmet.long}}
            onClick={() => {
              setSelected(retailLocations.minutoGourmet);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.laEsquinaPaisa.lat, lng: retailLocations.laEsquinaPaisa.long}}
            onClick={() => {
              setSelected(retailLocations.laEsquinaPaisa);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.veterinariaHuellas.lat, lng: retailLocations.veterinariaHuellas.long}}
            onClick={() => {
              setSelected(retailLocations.veterinariaHuellas);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.bowlEnCincoMinutos.lat, lng: retailLocations.bowlEnCincoMinutos.long}}
            onClick={() => {
              setSelected(retailLocations.bowlEnCincoMinutos);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.analuSalon.lat, lng: retailLocations.analuSalon.long}}
            onClick={() => {
              setSelected(retailLocations.analuSalon);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.infinity.lat, lng: retailLocations.infinity.long}}
            onClick={() => {
              setSelected(retailLocations.infinity);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.restauranteLaEsperanza.lat, lng: retailLocations.restauranteLaEsperanza.long}}
            onClick={() => {
              setSelected(retailLocations.restauranteLaEsperanza);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.theDiamond.lat, lng: retailLocations.theDiamond.long}}
            onClick={() => {
              setSelected(retailLocations.theDiamond);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.losAsesDelSabor.lat, lng: retailLocations.losAsesDelSabor.long}}
            onClick={() => {
              setSelected(retailLocations.losAsesDelSabor);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.farmaMinuto.lat, lng: retailLocations.farmaMinuto.long}}
            onClick={() => {
              setSelected(retailLocations.farmaMinuto);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.peluqueriaZulma.lat, lng: retailLocations.peluqueriaZulma.long}}
            onClick={() => {
              setSelected(retailLocations.peluqueriaZulma);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.papeleriaGenesis.lat, lng: retailLocations.papeleriaGenesis.long}}
            onClick={() => {
              setSelected(retailLocations.papeleriaGenesis);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.elBoyaco.lat, lng: retailLocations.elBoyaco.long}}
            onClick={() => {
              setSelected(retailLocations.elBoyaco);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />   

          <Marker
            position={{ lat: retailLocations.tiendaQuirinales.lat, lng: retailLocations.tiendaQuirinales.long}}
            onClick={() => {
              setSelected(retailLocations.tiendaQuirinales);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.panYPAsion.lat, lng: retailLocations.panYPAsion.long}}
            onClick={() => {
              setSelected(retailLocations.panYPAsion);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.espigaDorada.lat, lng: retailLocations.espigaDorada.long}}
            onClick={() => {
              setSelected(retailLocations.espigaDorada);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.papeleriaMundoJoven.lat, lng: retailLocations.papeleriaMundoJoven.long}}
            onClick={() => {
              setSelected(retailLocations.papeleriaMundoJoven);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.KYNBroaster.lat, lng: retailLocations.KYNBroaster.long}}
            onClick={() => {
              setSelected(retailLocations.KYNBroaster);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.papeleriaClaqueta.lat, lng: retailLocations.papeleriaClaqueta.long}}
            onClick={() => {
              setSelected(retailLocations.papeleriaClaqueta);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.HuellitasWkiPets.lat, lng: retailLocations.HuellitasWkiPets.long}}
            onClick={() => {
              setSelected(retailLocations.HuellitasWkiPets);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.tiendaNelcySuarez.lat, lng: retailLocations.tiendaNelcySuarez.long}}
            onClick={() => {
              setSelected(retailLocations.tiendaNelcySuarez);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.zabadRestaurante.lat, lng: retailLocations.zabadRestaurante.long}}
            onClick={() => {
              setSelected(retailLocations.zabadRestaurante);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.papeleriaDiegoMahecha.lat, lng: retailLocations.papeleriaDiegoMahecha.long}}
            onClick={() => {
              setSelected(retailLocations.papeleriaDiegoMahecha);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.supermercadoMyM.lat, lng: retailLocations.supermercadoMyM.long}}
            onClick={() => {
              setSelected(retailLocations.supermercadoMyM);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.studioBerlin.lat, lng: retailLocations.studioBerlin.long}}
            onClick={() => {
              setSelected(retailLocations.studioBerlin);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.variedadesElCentro.lat, lng: retailLocations.variedadesElCentro.long}}
            onClick={() => {
              setSelected(retailLocations.variedadesElCentro);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.variedadesNellys.lat, lng: retailLocations.variedadesNellys.long}}
            onClick={() => {
              setSelected(retailLocations.variedadesNellys);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.papeleriaElBaraton.lat, lng: retailLocations.papeleriaElBaraton.long}}
            onClick={() => {
              setSelected(retailLocations.papeleriaElBaraton);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.long }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div style={{textAlign: "center"}}>
              <h2
                style={{
                  color: "#A020F0",
                }}
              >
                COMERCIO WUPO
              </h2>
              <p
                style={{
                  fontSize: "1rem",
                  margin: "0",
                  fontWeight: "bold",
                  
                }}
              >
                {selected.name}
              </p>
              <p>{selected.address}</p>
            </div>
          </InfoWindow>
        ) : null}
        </GoogleMap>
       
      </Content>
    </div>
  );
}

