import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ilpAccountFetcherUtil } from "../../utilityFunctions/FetchUtil";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import Content from "../../layout/Content";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function OperationalHandler(props) {
  const {loginUser } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  // const accountFetcher = {
  //   accountId: "wupohotwallet",
  //   token: "YWRtaW46cGFzc3dvcmQ=",
  // };

  const handleConsult = () => {
    setLoading(true);
    //ilpAccountFetcherUtil(accountFetcher, loginUser.jwtToken)
    ilpAccountFetcherUtil(loginUser.jwtToken)
      .then((result) => {
        try {
          setBalance(JSON.parse(result));
        } catch (err) {
          setBalance(result);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error fetching: " + err);
        setLoading(false);
        swal({
          title: "NO PUDIMOS HACER TU PAGO",
          text: `\n Puede que hayas seleccionado una cuenta o activo inválido.
                    Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n
                    Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                    Jorge: XXXXXXXXXX
                    Federico: XXXXXXXXXX`,
          icon: "error",
          button: "Entiendo",
        });
      });
  };

  return (
    <Content>
      <PageLabel>{balance ? "WUPO'S BALANCE" : "OP. BALANCE"}</PageLabel>
      {loading ? (
        <PuffLoader size={200} color={Colors.secondary} css={cssLoader} />
      ) : balance ? (
        <>
          <h1 style={{ color: Colors.primary, textAlign: "center" }}>
            {balance.text}
          </h1>
          <AppButton primary onClick={() => history.push("/admin")}>
            OK
          </AppButton>
        </>
      ) : (
        <>
          <AppButton primary onClick={handleConsult}>
            CONSULTAR
          </AppButton>
        </>
      )}
    </Content>
  );
}
