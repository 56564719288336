import React from 'react';
import {getCardStatement} from "../utilityFunctions/OpenCardUtil";
import swal from "sweetalert";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography, DialogContent } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Divider from '@mui/material/Divider';
import Colors from "../components/ui/Colors";
import CardImg from "../img/creditcards/card2.png";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const statementRequest = {
    internalCardCode: "",
};


export default function CreditCardStatement(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        getStatement();
    }, []);

    const getStatement = async () => {
        setLoading(true);
        if(!props.location.state || !props.location.state.product){
            return;
        };
        console.log(props.location.state.product);
        statementRequest.internalCardCode = props.location.state.product.internalCode;

        const statementResponse = await getCardStatement(statementRequest, loginUser.jwtToken);
        
        setLoading(false);

        if(!statementResponse){
            swal({
                title: "Sin Movimientos",
                icon: "info",
                button: "ok",
            }).then(() => {
                history.push("/creditCard/mount");
            });

            return;
        };

        if(statementResponse[0] && statementResponse[0].errorMessage === "00001 Tarjeta sin movimientos"){
            swal({
                title: "Sin Movimientos",
                icon: "info",
                button: "ok",
            }).then(() => {
                history.push("/creditCard/mount");
            });
            return;
        }

        renderStatement(statementResponse);

    };

    const[statementComponent, setStatementComponent] = React.useState();

    const renderStatement = (statementResponse) => {

        console.log(statementResponse);

        if(!statementResponse){return}

        statementResponse.sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));
        console.log(statementResponse)

        setStatementComponent(
            <Grid container spacing={2} sx={{justifyContent: {xs: "center", sm: "center"}, alignItems: "center", width: "90%"}}>
                    <Card sx={{maxWidth: "400px", minWidth: {xs: "100%", sm: "90%", md: "300px", borderRadius: "15px"}}}>
                            <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", width: "90%"}}>
                                <CardMedia
                                    component="img"
                                    image={CardImg}
                                    alt="Card"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", maxHeight: "200px", overflowY: "auto" }}>
                                    <Grid container sx={{width: "90%"}}>
                                    {statementResponse.map((operation) => {
                                        return(
                                            <> 
                                            {(operation.transactionDate && operation.transactionAmount) ?
                                            <>
                                            <Grid key={generateRandomKey()}  item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "start", textAlign: "start", marginTop: "0.8rem", marginBottom: "0.2rem"}}>
                                                <Typography key={generateRandomKey()}  variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(operation.transactionDate))}`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()}  item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(operation.transactionAmount)}`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()}  item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                                {operation.merchantName ? 
                                                    <Typography variant="body2">Compra en línea</Typography>
                                                : operation.transactionConcept === "Pago"?
                                                <Typography variant="body2">Pago en línea</Typography>
                                                :
                                                <Typography variant="body2">Compra en línea</Typography>
                                                }
                                            </Grid>
                                            {operation.transactionTerm ? 
                                            <>
                                                <Grid key={generateRandomKey()}  item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                </Grid>
                                                <Grid key={generateRandomKey()}  item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                                    <Typography variant="body2" sx={{color: "gray", fontSize: "0.7rem"}}>{`(${operation.transactionTerm} cuotas)`}</Typography>
                                                </Grid>
                                            </>
                                            :<></>
                                            }
                                            <Grid key={generateRandomKey()}  item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                                                <Divider/>
                                            </Grid>
                                            </>
                                            :<></>}
                                            </>
                                        )
                                    })}
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1.5rem", marginBottom: "1rem"}}>
                                <Button onClick={toMount} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                                    Atrás
                                </Button>
                            </Grid>
                            </Grid>
                    </Card>
                </Grid>
        );

    };

    const toMount = () => {
        history.push("/creditCard/mount");
    };

    const generateRandomKey = () => {
        return Math.random().toString(36).substr(2, 9);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Box
                    sx={{
                        display: "flex",
                        // flex: 1,
                        justifyContent: "center",
                        alignItems: {xs: "center", sm: "center", md: "center"},
                        width: "100%",
                        height: "100%",
                        textAlign: "center",
                        overflowY: "scroll",
                        
                    }}
                >
                    {statementComponent}
                </Box>
            }
        </Box>
    )

    
}
