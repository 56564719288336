import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import swal from "sweetalert"
import AppLabel from "../../components/ui/AppLabel"
import DefaultSection from "../../components/ui/DefaultSection"
import AppButton from "../../components/ui/AppButton"
import ButtonContent from "../../layout/ButtonContent"
import Coins from "../../img/design-june/img-light/astronauta.png"
import Astronaut from "../../img/design-june/highDefinition/astronaut.png"
import Lend from "../../img/design-june/highDefinition/lend.png"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Colors from "../../components/ui/Colors";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
// import { epaycoPSE } from "../../utilityFunctions/Util";
// import ScriptTag from 'react-script-tag';


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({
  
  dialogStyle:{
    whiteSpace: "pre-line",
    
  },

  dialog:{
    backgroundColor: "#DCDCDC",
  },
  
  linkStyle:{
    cursor: "pointer",
    color: "inherit",
    textAlign: "center"
  },

  actionArea: {
    borderRadius: 16,
    transition: '0.2s',
    '&:hover': {
      transform: 'scale(1.1)',
      backgroundColor: Colors.primary,
    },
  },

  cardImage:{
    width: "20%",
    justifySelf: "center"
  }

}));

let existingProduct = false;

let isRootOrOperator = false;


export default function WelcomeCardAccount(props) {
  const { updateLedgerAccounts, loginUser, updateVisitorAccounts, updateVisitorLoans, 
    visitorLoans, creditRiskStatus, updateWupoAssets, updateLoanSettings,
    updateRefinancingSettings } = props
  
  const history = useHistory()
  const classes = useStyles();

  const[loading, setLoading] = React.useState(true);

  useEffect(() => {
    console.log(loginUser);
    isRootOrOperator = false;
    existingProduct = false;
    
    determineProfile();
    
    // updateVisitorLoans(loginUser.accountId, loginUser.jwtToken).then((response) => {
    //   if(response === 200){
    //     setLoading(false);
    //   }
    // });
    // updateVisitorAccounts(loginUser.accountId, loginUser.jwtToken).then((response) => {
    //   if(response === 200){
    //     setLoading(false);
    //   }
    // });

    // updateWupoAssets(loginUser.jwtToken).then((response) => {
    //   if(response === 200){
    //     setLoading(false);
    //   }
    // });

    // updateLoanSettings().then((response) => {
    //   if(response === 200){
    //     setLoading(false);
    //   }
    // });

    // updateLedgerAccounts(loginUser.jwtToken)
    // swal({
    //   title: "¿QUÉ HAGO?",
    //   text: `\n Si no tienes un producto con nosotros, crea uno y empieza a disfrutar la magia de ser libre. \n \n
    //           Si ya tienes productos WUPO, selecciona la opción Tus Cuentas e ingresa tu información para Wupear!`,
    //   icon: "success",
    //   buttons: ["Entiendo", "Más Info"]
    // }).then(info => {
    //   if (info) {
    //     swal({
    //       title: "¿QUÉ ES WUPEAR?",
    //       text: `
    //       Wupear = Transferir. 

    //       En realidad, mucho más que eso, Wupear es libertad, simplicidad, tecnología de punta, es el beneficio de tener opciones reales para obtener lo que quieres.
          
    //       Creemos en un futuro donde todos tengamos acceso a tecnología de punta para potencializar nuestra capacidad de compra y calidad de vida. 
          
    //       Sabemos que somos más fuertes actuando en comunidad, cuando las relaciones son justas y donde todos ganamos. 
          
    //       Wupear a la final es, transferir, cambiar, libertad, futuro, compartir, crecer y mucho más
          
    //       Asi que Wupealo!
    //       `,
    //       icon: "success",
    //       button: "Súper"
    //     })
    //   }
    // })

  }, [])

  const[profile, setProfile] = useState('');

  useEffect(() =>{
    console.log(isRootOrOperator)
    if(!isRootOrOperator){
        
      updateVisitorLoans(loginUser.accountId, loginUser.jwtToken).then((response) => {
        if(response === 200){
          setLoading(false);
        }
      });
      updateVisitorAccounts(loginUser.accountId, loginUser.jwtToken).then((response) => {
        if(response === 200){
          setLoading(false);
        }
      });

      updateWupoAssets(loginUser.jwtToken).then((response) => {
        if(response === 200){
          setLoading(false);
        }
      });

      updateLoanSettings(loginUser.jwtToken).then((response) => {
        if(response === 200){
          setLoading(false);
        }
      });

      updateRefinancingSettings(loginUser.jwtToken).then((response) => {
        if(response === 200){
          setLoading(false);
        }
      });

      updateLedgerAccounts(loginUser.jwtToken)
      swal({
        title: "¿QUÉ HAGO?",
        text: `\n Si no tienes un producto con nosotros, crea uno y empieza a disfrutar la magia de ser libre. \n \n
                Si ya tienes productos WUPO, selecciona la opción Tus Cuentas e ingresa tu información para Wupear!`,
        icon: "success",
        buttons: ["Entiendo", "Más Info"]
      }).then(info => {
        if (info) {
          swal({
            title: "¿QUÉ ES WUPEAR?",
            text: `
            Wupear = Transferir. 

            En realidad, mucho más que eso, Wupear es libertad, simplicidad, tecnología de punta, es el beneficio de tener opciones reales para obtener lo que quieres.
            
            Creemos en un futuro donde todos tengamos acceso a tecnología de punta para potencializar nuestra capacidad de compra y calidad de vida. 
            
            Sabemos que somos más fuertes actuando en comunidad, cuando las relaciones son justas y donde todos ganamos. 
            
            Wupear a la final es, transferir, cambiar, libertad, futuro, compartir, crecer y mucho más
            
            Asi que Wupealo!
            `,
            icon: "success",
            button: "Súper"
          })
        }
      })
    }
  }, [profile]);

  const determineProfile = () =>{

    if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
      
      

      if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
        setProfile("ADMIN");
        // history.push({pathname: "/account/handle_account"});
      }

      else if(loginUser.roles.some((rol) => rol.authority === "ROOT")){
        isRootOrOperator=true;
        setProfile("ROOT");
        history.push({pathname: "/root-profile"});

      }

      else if(loginUser.roles.some((rol) => rol.authority === "OPERATOR")){
        isRootOrOperator=true;
        setProfile("OPERATOR");
        history.push({pathname: "/operator-role"});
      }

    }


  };

  function handleOnclick(){
    // updateLedgerAccounts();
    existingProduct = true;
    handleChooseProduct();
    // history.push({pathname: "/account/handle_account"})
  }

  function handleCreateAccountClick(){
    // updateLedgerAccounts();
    if(existingProduct){
      history.push({pathname: "/account/handle_account"})
    }else{
      history.push({pathname: "/account/create_account"})
    }
  }

  const handleCreateCreditClick = () =>{
    handleChooseProductClose();

    if(existingProduct){

      existingProduct = false;
      console.log(visitorLoans)
      if(!visitorLoans){
        
        swal({
          title: "TODAVÍA NO TIENES UN WUPO CRÉDITO",
          text: `Te va a sorprender lo fácil que es obtener uno.

          Elige Nuevo Producto y empieza a Wupear con tu crédito`,
          icon: "info",
          button: "Entiendo",
        }).then(() => {
            history.push({pathname: "/account"});
        });
        
      }
      else{
        history.push({pathname: "/credit/handle_credit"});
      }

      
    }else{
      if(visitorLoans && visitorLoans.currentBalance > 0){
        swal({
          title: "YA TIENES UN WUPO CRÉDITO",
          text: "Ya tienes un WUPO crédito. Cuando termines de pagarlo, puedes pedir uno nuevo!",
          icon: "info",
          button: "Entiendo",
        }).then(() => {
            history.push({pathname: "/account"});
        });
      }

      else if(!creditRiskStatus.creditApproved){
        swal({
          title: "ESTÁS A POCAS TRANSACCIONES",
          text: `Ya casi puedes obtener tu wupo crédito. Debes haber hecho
          mínimo ${creditRiskStatus.minumumTxCondition} transacciones en nuestros comercios aliados y has hecho ${creditRiskStatus.transactionsCount}. Sigue Wupeando para obtener tu Wupo crédito!`,
          icon: "info",
          button: "Entiendo",
        }).then(() => {
            history.push({pathname: "/account"});
        });
      }
      else{
        history.push({pathname: "/account/create_credit"});
      }
      
    }
  }

  const [chooseProduct, setChooseProduct] = React.useState(false);

  const handleChooseProduct = async () => {
    
    //TODO: epayco
    // epaycoLoading = false;
    // const script = document.createElement('script');
    // script.src = "https://checkout.epayco.co/checkout.js";
    // script.async = true;
    // document.body.appendChild(script);

    // const handler = await window.ePayco.checkout.configure({
    //   key: '502a7cf6776c4080941e24e28b515028',
    //   test: true,
    // });

    // handler.open(data); // Open epayco dialog box

    setChooseProduct(true);

    // return () => {
    //   document.body.removeChild(script);
    // }
  }

  const handleChooseProductClose = () => {
    setChooseProduct(false);
  }

  /**
   * Functions for products explanation and agreement between parties 
   */
  
   const [creditAgreement, setCreditAgreement] = React.useState(false);
   const [tokenAgreement, setTokenAgreement] = React.useState(false);

   const handleExplanation = (productType) => {
    setChooseProduct(false);
    productType === "credit" ?
      setCreditAgreement(true)
    :
      setTokenAgreement(true)
  }

  const handleExplanationClose = () => {
    setCreditAgreement(false);
    setTokenAgreement(false);
  }
  

  return (
    // <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          // width: "100vw",
          maxWidth: "620px",
          // maxWidth: "60%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
      {/* <ScriptTag isHydrating={true} type="text/javascript" 
      src="https://checkout.epayco.co/checkout.js" /> */}

      {loading ?
        <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
      :
      <>
        <AppLabel
          logoVisible
          // bgColor={Colors.secondary}
          color="black"
          title="HOLA"
          subTitle="Crea nuevos productos o Wupea con los que ya tienes"
        />
        <p style={{fontWeight: "bold", marginTop: "-1em", marginBottom: "0", color: "#A020F0", alignSelf: "center" }}>{loginUser.accountId}</p>
        <DefaultSection
          backImgage={Coins}
          padding={"0"}
          width={"56%"}
        />
        <ButtonContent style={{ flex: 1 }}>
          <AppButton 
            primary 
            width={"85%"}
            onClick={handleOnclick} 
            style={{ marginBottom: "1rem" }}
          >
            Tus Productos
          </AppButton>
          <AppButton 
            primary 
            width={"85%"}
            onClick={handleChooseProduct}
          >
           Producto Nuevo
          </AppButton>
        </ButtonContent> 
      </>
      }
      </div>
      <React.Fragment>
          <Dialog open={chooseProduct} onClose={handleChooseProductClose} aria-labelledby="form-dialog-products">
            <DialogTitle id="form-dialog-title1">TIPO DE PRODUCTO</DialogTitle>
            <DialogContent className={classes.actionArea}>
            <Card sx={{ minWidth: 150 }}>
              <CardActionArea onClick={handleCreateAccountClick}>
              
                <CardMedia
                  className={classes.cardImage}
                  component="img"
                  // height="200"
                  image={Astronaut}
                  alt="astronaut"
                />
                
                <CardContent>
                  <Typography variant="h5" component="div">
                    WUPO TOKENS
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color = "primary">Detalle y condiciones</Button>
                </CardActions>
              </CardActionArea>
            </Card>
            </DialogContent>
            <DialogActions>
            </DialogActions>

            <DialogContent className={classes.actionArea}>
              <Card sx={{ minWidth: 150 }}>
                <CardActionArea onClick={handleCreateCreditClick}>
                  <CardMedia
                    className={classes.cardImage}
                    component="img"
                    // height="200"
                    image={Lend}
                    alt="astronaut"
                  />
                  <CardContent>
                    <Typography variant="h5" component="div">
                      WUPO CRÉDITO
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color = "primary">Detalle y condiciones</Button>
                  </CardActions>
                </CardActionArea>
              </Card>
            </DialogContent>
            <DialogActions>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      <React.Fragment>
        <Dialog open={creditAgreement} onClose={handleExplanationClose} aria-labelledby="form-dialog-credit">
          <DialogTitle id="form-dialog-title2">CONTRATO CRÉDITO</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogStyle}>
             Contrato Crédito 
            {/* {disclaimerText} */}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCreateAccountClick} color="primary">
                De acuerdo
            </Button>
            <Button onClick={handleChooseProduct} color="primary">
                Atrás
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <React.Fragment>
        <Dialog open={tokenAgreement} onClose={handleExplanationClose} aria-labelledby="form-dialog-tokens">
          <DialogTitle id="form-dialog-title3">CONDICIONES TOKENS</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogStyle}>
          
            Contrato Débito

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCreateAccountClick} color="primary">
                De acuerdo
            </Button>
            <Button onClick={handleChooseProduct} color="primary">
                Atrás
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  )
}