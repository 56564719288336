import React, { useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Typography } from '@mui/material'
import Content from "../../layout/Content";
import PageLabel from "../../components/ui/PageLabel";
import Grid from '@mui/material/Grid';
import AppButton from "../../components/ui/AppButton";
import { installmentPayment, refinancingPayment, prepareLoanStatement, 
    fetchStatement, fetchAccountStatement, combopayInstallment} from "../../utilityFunctions/CreditUtil";
import { fetchXrpl, loadXrpAddressCreds, fetchAssetRates, xrplSecretFetch } from "../../utilityFunctions/FetchUtil";
import { xrpPayment } from "../../utilityFunctions/Paymentutil";
import { updateExplanations } from "../../utilityFunctions/AdminUtil";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import NumberFormat from 'react-number-format';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Colors from "../../components/ui/Colors";
import Divider from '@mui/material/Divider';
// import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {handleCreditExplanation} from "../../Texts/Explanations";
import {loanPaymentProvider, b2bClient} from '../../Api';
import Box from '@mui/material/Box';

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><rect fill='#ffffff' width='1600' height='900'/><defs><linearGradient id='a' x1='0' x2='0' y1='1' y2='0'><stop  offset='0' stop-color='#0FF'/><stop  offset='1' stop-color='#CF6'/></linearGradient><linearGradient id='b' x1='0' x2='0' y1='0' y2='1'><stop  offset='0' stop-color='#BD0BFF'/><stop  offset='1' stop-color='#FC0'/></linearGradient></defs><g fill='#FFF' fill-opacity='0' stroke-miterlimit='10'><g  stroke='url(#a)' stroke-width='2'><path  transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/><circle  stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/><path  transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/></g><g stroke='url(#b)' stroke-width='4'><path  transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/><rect  stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/><path  transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/></g></g></svg>`;

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({
    listItem: {
        width: "50%",
    },
    listItemText: {
        // color: Colors.fourth,
    },
    divider:{
        background: Colors.primary
    },
    statementDate:{
        color: "#A0A0A0",
    },
    grid: {
        justifyContent: "center",
        alignItems: "center"
    },
    gridStatement: {
        marginTop:"1em",
    },
    gridProducts:{
        marginTop:"0.6em",
        marginBottom: "0.6em"
    },
    menuList: {
        overflowY: "scroll"
    },
    menuItemProducts: {
        width: "100%",
    },
    listItemPayment: {
        width: "100%",
        marginTop: "0.6em",
    },
    dialog:{
        width: "100%",
    },
    input: {
        width: "100%",
    },
    dialogHideButton: {
        visibility: false,
    },
    creditTypographyH5: {
        // marginBottom: "-1.2em",
        fontSize: "1.5rem"
    },
    creditTypographyH6: {
        marginTop: "-1.4em",
        // marginBottom: "0.4em",
        fontSize: "0.9rem",
        color: "#5e5e5e",
    },
    paymentTypography: {
        marginTop: "-1.4em",
        marginBottom: "0.4em",
        fontSize: "0.9rem",
        color: "blue",
    },
    svgBackground: {
        background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
    },
}));


let loanPaymentValue = 0;

const paymentTitles = {
    paymentOptions: "SELECCIONA LA OPCIÓN DE PAGO",
    otherValue: "VALOR QUE VAS PAGAR",
    accountSelect: "SELECCIONA EL PRODUCTO",
}

const paymentTypes = {
    minimumPayment: 1,
    totalPayment: 2,
    otherValuePayment: 3,
}

const FETCHTYPE = "BalanceSheet";

let visitorsAccountBalances;

const payment = {
    source: {
      address: "",
      maxAmount: {
        value: "",
        currency: "",
        counterparty: "",
      },
    },
    destination: {
      address: "",
      amount: {
        value: "",
        currency: "",
        counterparty: "",
      },
    },
};

let WUPOXRPLACCOUNT = "";
let WUPOXRPLSECRET = "";

const assetsToFetch = {
    fromAsset: "",
    toAsset: "",
};

let clientXrpSecret;

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
}

const onlyDateOption = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
}

const dateOptionsNextInstallment = {
    // weekday: "short",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
}

const epaycoData =
    {
      //Parametros compra (obligatorio)
      name: "prueba",
      description: "prueba",
      invoice: "1010",
      currency: "COP",
      amount: "35000",
      tax_base: "1000",
      tax: "0",
      country: "co",
      lang: "en",
    
      //Onpage="false" - Standard="true"
      external: "false",
    
    
      //Atributos opcionales
      extra1: "extra1",
      extra2: "extra2",
      extra3: "extra3",
      confirmation: "https://wupotestmiddle.ngrok.io/epaycoconfirmation",
      response: "https://wupotestmiddle.ngrok.io/epaycoresponse",
    
      //Atributos cliente
      name_billing: "Jorge Hernandez",
      address_billing: "Carrera 19 numero 14 91",
      type_doc_billing: "cc",
      mobilephone_billing: "3138252848",
      number_doc_billing: "1020723447",
      // email_billing: "isabella.coronel@gmail.com",
    
     //atributo deshabilitación metodo de pago
      // methodsDisable: ["CASH","DP"]
    
    }

let handler;

let isRefinancing = false;

let refinancingBalance;

export default function HandleCredit(props) {
    const {
        visitorLoans,
        loginUser,
        visitorAccounts,
        refinancingConditionsSettings,
        updateVisitorLoans,
        updateVisitorAccounts,
        updateLedgerAccounts,
        updateWupoAssets,
        updateLoanSettings,
        updateRefinancingSettings,
        loadProductsAndSetting,
        displayExplanations
    } = props

    const history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    const [dialogTitle, setDialogTitle] = React.useState(paymentTitles.paymentOptions);

    const [renderStatement, setRenderStatement] = React.useState();
    const [statementList, setStatementList] = React.useState();

    const loadInitialInfo = async () => {
        return (new Promise((resolve) => {
            if(props.location.state && props.location.state.fromHistory === "/login"){
                resolve(200);
            }

            loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((result) => {
                resolve(result);
            });
        }));
    };

    const explanationMessages = () => {
        if(displayExplanations && displayExplanations.newLoanMessage){
            swal({
                title: "Explicación",
                text: handleCreditExplanation,
                icon: "success",
                button: "Entiendo",
            }).then(() => {
                updateExplanations(loginUser.accountId, "newLoanMessage");
            });
        }
    };

    useEffect(() => {
        //Handle the explanation messages
        explanationMessages();

        setLoading(true);
        loadInitialInfo().then((result) => {
            console.log(result)
                // visitorsAccountBalances = [...visitorAccounts]; //Commented for IMS
                setRefinancingBalance();
                if(b2bClient && b2bClient !== "IMS"){
                    fetchXrplAccountBalances();
                }
                setLoading(false);
        })

        isRefinancing = false;
        loanPaymentValue = 0;
        
        fetchWupoCredentials();
        setClientXrpSecret();

        const script = document.createElement('script');
        script.src = "https://checkout.epayco.co/checkout.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
        document.body.removeChild(script);
        }

    }, [])

    const setRefinancingBalance = () => {
        refinancingBalance = visitorLoans.totalPayment * refinancingConditionsSettings.enablePayment;
        console.log(refinancingBalance); //DEBUG
    };

    const fetchWupoCredentials = () => {
        loadXrpAddressCreds(loginUser.jwtToken).then((credentials) => {
            WUPOXRPLACCOUNT = credentials.xrpAddress;
            WUPOXRPLSECRET = credentials.xrpAddressSecret;

            payment.destination.address = WUPOXRPLACCOUNT;
            payment.source.maxAmount.counterparty = WUPOXRPLACCOUNT;
            payment.destination.amount.counterparty = WUPOXRPLACCOUNT;
        })
    };

    const setClientXrpSecret = () => {
        //Commented for IMS
        // xrplSecretFetch(visitorAccounts[0].xrplAddressSecret, loginUser.jwtToken).then((secret) => {
        //     clientXrpSecret = secret;
        // })
    };

    const fetchXrplAccountBalances = () =>{
        console.log(visitorsAccountBalances);
        fetchXrpl(FETCHTYPE, visitorAccounts[0].xrplAddress).then ((balances) => {
            visitorsAccountBalances.forEach((account) => {
                balances.assets.forEach((balance) => {
                    if(account.asset === balance.currency){
                        account.balance = balance.value;
                    }

                })
            })
            setLoading(false);
        });
    };

    const comboPayment = (isRefinancing) => {
        setLoading(true);
        setPaymentDialog(false);

        // const paymentWindow = window.open('https://ims-customization.d8dvucg91qcfk.amplifyapp.com/withdrawmethod');
        const paymentWindow = window.open('https://wupotest.ngrok.io/withdrawmethod');
        let installmentObject
        if(isRefinancing){
            installmentObject = {
                account: visitorLoans.account,
                asset: visitorLoans.asset,
                installmentValue: loanPaymentValue,
                loanId: visitorLoans.id,
                isRefinancing: true
            };    
        }else{
            installmentObject = {
                account: visitorLoans.account,
                asset: visitorLoans.asset,
                installmentValue: loanPaymentValue,
                loanId: visitorLoans.id,
            }; 
        }

        combopayInstallment(installmentObject, loginUser.jwtToken).then((paymentResult) => {
            

            if(paymentResult !== "Not found"){
                paymentWindow.document.location.href = paymentResult.payment_link;

                const prepareStatement = {
                    account: visitorLoans.account,
                    asset: visitorLoans.asset,
                    payment: loanPaymentValue,
                    originAccount: "combopay",
                    loanId: visitorLoans.id,
                }

                // prepareLoanStatement(prepareStatement, loginUser.jwtToken).then(() =>{
                //     setLoading(false);
                //     swal({
                //         title: "PAGO INICIADO",
                //         icon: "success",
                //         text: "Cuando realices el pago se verá reflejado en tu estado del crédito",
                //         button: "Súper",
                //     }).then(() => {
                //         loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                //             if(loadedInfo.visitorLoans){
                //                 console.log("entering the loan not undefined")
                //                 console.log(loadedInfo.visitorLoans)
                //                 setLoading(false);
                //                 history.push(
                //                     {pathname: "/credit/handle_credit"}
                //                 );
                //                 return;
                //             }else{
                //                 console.log("entering the else")
                //                 loadProductsAndSetting("/account/create_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                //                     setLoading(false);
                //                     history.push(
                //                         {pathname: "/account/create_credit"}
                //                     );
                //                 });
                //                 return;
                //             }
                //         });
                //     });

                //     // setLoading(false);
                // })
                
                setLoading(false);
                swal({
                    title: "PAGO INICIADO",
                    icon: "success",
                    text: "Cuando realices el pago se verá reflejado en tu estado del crédito",
                    button: "Súper",
                }).then(() => {
                    loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                        if(loadedInfo.visitorLoans){
                            console.log("entering the loan not undefined")
                            console.log(loadedInfo.visitorLoans)
                            setLoading(false);
                            history.push(
                                {pathname: "/credit/handle_credit"}
                            );
                            return;
                        }else{
                            console.log("entering the else")
                            loadProductsAndSetting("/account/create_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                                setLoading(false);
                                history.push(
                                    {pathname: "/account/create_credit"}
                                );
                            });
                            return;
                        }
                    });
                });

            } else{
                paymentWindow.close();
                setLoading(false);
                swal({
                    title: "Ocurrió un error",
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    setLoading(false);
                    history.push(
                        {pathname: "/credit/handle_credit"}
                    );
                });
            }
        });
    };

    function handlePaymentSelected(paymentTypeId){
        
        if(paymentTypeId === 1){
            loanPaymentValue = Math.ceil(visitorLoans.minimumPayment);
            console.log(loanPaymentValue);
            selectProduct();

        } else if(paymentTypeId === 2){
            
            // loanPaymentValue = paymentsFetched.totalPayment + paymentsFetched.loanCost;
            loanPaymentValue = Math.ceil(visitorLoans.totalPayment); // interests not included
            selectProduct();
            console.log(loanPaymentValue);
        }  

        
          
    };

    const handleOtherPayment = (values) => {
        loanPaymentValue = values.floatValue;
        // console.log(loanPaymentValue);
    };

    const selectProduct = () => {
 
        setPaymentInput(true);

        console.log(loanPaymentProvider);
        if(loanPaymentProvider === "combopay"){
            comboPayment();
            return;
        }

        setDialogTitle(paymentTitles.accountSelect);
        setPaymentOptions(
            <MenuList className={classes.menuList}>
            {visitorsAccountBalances.map((account) => {
                return(
                    <div>
                    <Divider className={classes.divider}/>  
                    <MenuItem key ={account.asset} className={classes.menuItemProducts}>
                        <Grid container className={classes.gridProducts} key={account.asset}>
                            <Grid item xs={12} sm={12} md={12} key={account.asset}>
                                    <ListItemText 
                                        key={account.asset}
                                        onClick={() => handleProductSelected(account)}
                                    >
                                    {`Token: ${account.asset}`}
                                    </ListItemText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} key={account.asset+1}>
                                    <ListItemText 
                                        key={account.asset+1}
                                        onClick={() => handleProductSelected(account)}
                                    >
                                    {`${new Intl.NumberFormat('es-CO').format(account.balance)}`}
                                    </ListItemText>
                            </Grid>
                        </Grid>  
                    </MenuItem>
                    </div>
                )
            })}
                <Divider className={classes.divider}/> 
                <MenuItem className={classes.menuItemProducts}>
                    <ListItemText 
                        onClick={handleExternalPayment}
                        style={{color: "blue", fontWeight: "bold"}}
                    >
                        Pago Digital
                    </ListItemText>
                </MenuItem>
            </MenuList>
        )
    }

    const handleProductSelected = (account) => {
        
        handlePaymentsClose("payment");

        console.log(account);
        payment.source.address = account.xrplAddress;

        // The xrp payment must be done in the source asset to simplify the payment. 
        payment.source.maxAmount.currency = account.asset;
        payment.destination.amount.currency = account.asset;

        assetsToFetch.fromAsset = visitorLoans.asset;
        assetsToFetch.toAsset = account.asset;

        fetchAssetRates(assetsToFetch, loginUser.jwtToken).then((rate) => {
            
            // Math.round(((value)+Number.EPSILON)*100000000)/100000000;

            payment.source.maxAmount.value = String(Math.round(((loanPaymentValue * rate.rate)+Number.EPSILON)*1000)/1000);
            payment.destination.amount.value = payment.source.maxAmount.value;
            
            xrpPayment(payment, "", "", clientXrpSecret).then((paymentResult) => {
                if(paymentResult === "payment ok"){
                    
                    const installmentObject = {
                        account: visitorLoans.account,
                        asset: visitorLoans.asset,
                        installmentValue: loanPaymentValue,
                        loanId: visitorLoans.id,
                    }; 

                    installmentPayment(installmentObject, loginUser.jwtToken).then((paymentResult) => {

                        if(paymentResult !== "Not found"){

                            const prepareStatement = {
                                account: visitorLoans.account,
                                asset: visitorLoans.asset,
                                payment: loanPaymentValue,
                                originAccount: account.xrplAddress,
                                loanId: visitorLoans.id,
                            }

                            prepareLoanStatement(prepareStatement, loginUser.jwtToken).then(() =>{
                                swal({
                                    title: "PAGO EXITOSO",
                                    icon: "success",
                                    button: "Súper",
                                }).then(() => {
                                    loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                                        if(loadedInfo.visitorLoans){
                                            console.log("entering the loan not undefined")
                                            console.log(loadedInfo.visitorLoans)
                                            setLoading(false);
                                            history.push(
                                                {pathname: "/credit/handle_credit"}
                                            );
                                            return;
                                        }else{
                                            console.log("entering the else")
                                            loadProductsAndSetting("/account/create_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                                                setLoading(false);
                                                history.push(
                                                    {pathname: "/account/create_credit"}
                                                );
                                            });
                                            return;
                                        }
                                    });
                                });

                                // setLoading(false);
                            })

                        } else{
                            swal({
                                title: "Ocurrió un error",
                                icon: "error",
                                button: "Entiendo",
                            }).then(() => {
                                setLoading(false);
                                history.push(
                                    {pathname: "/credit/handle_credit"}
                                );
                            });
                        }
                    });
                }
                else if(paymentResult === "tecPATH_PARTIAL"){
                    swal({
                        title: "Pago no exitoso",
                        text: "Fondos insuficientes",
                        icon: "error",
                        button: "Entiendo",
                    }).then(() => {
                        setLoading(false);
                        history.push(
                            {pathname: "/credit/handle_credit"}
                        );
                    });
                }

                else{
                    swal({
                        title: "Pago no exitoso",
                        text: "Por favor vuleve a intentarlo",
                        icon: "error",
                        button: "Entiendo",
                    }).then(() => {
                        setLoading(false);
                        history.push(
                            {pathname: "/credit/handle_credit"}
                        );
                    });
                }
            });

        });
        
    };

    const handleExternalPayment = async () => {

        handlePaymentsClose("payment");

        handler = await window.ePayco.checkout.configure({
            key: '502a7cf6776c4080941e24e28b515028',
            test: true,
          });
      
          handler.open(epaycoData); // Open epayco dialog box
    };

    function handleOtherValue(){

        setPaymentInput(false);
        setPaymentOptions(
            <NumberFormat
                className={classes.input}
                name={"formatNumber"}
                customInput={TextField}
                // prefix={'WUPO '}
                prefix={'$ '}
                type="text"
                label="Valor que vas a pagar"
                thousandSeparator={'.'}
                decimalSeparator={','}
                autoComplete={"off"}
                onValueChange={(values) => {
                    // const {formattedValue, value, floatValue} = values;
                    handleOtherPayment(values);
                    
                }}
            />   
        );
        

        setDialogTitle(paymentTitles.otherValue);
    }

    const [paymentOptions, setPaymentOptions] = React.useState();

    const handlePayment = () =>{

        setLoading(true);

        console.log(visitorsAccountBalances);

            setPaymentOptions(
                <ListItem>
                    
                     <Grid container spacing={2} direction={"column"} className={classes.grid}>
                        {visitorLoans.minimumPayment !== visitorLoans.totalPayment ?
                            <Grid item xs={12} sm={12} md={12}>
                                <ListItemButton className={classes.listItemPayment}>
                                    <ListItemText 
                                        primary={`Pago Mínimo: ${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0}).format(visitorLoans.minimumPayment)} ${visitorLoans.asset}`} 
                                        onClick={() => handlePaymentSelected(paymentTypes.minimumPayment)} 
                                        className={classes.listItemText}  
                                    />
                                </ListItemButton>
                            </Grid>
                            : <></>
                        }
                        <Grid item xs={12} sm={12} md={12}>
                            <Divider className={classes.divider}/>
                            <ListItemButton className={classes.listItemPayment}>
                                <ListItemText 
                                    primaryTypographyProps={{ style: { whiteSpace: "pre-line" } }}
                                    primary={`Pago Total: ${new Intl.NumberFormat('es-CO',  {maximumFractionDigits: 0}).format(visitorLoans.totalPayment)} ${visitorLoans.asset}`} 
                                    onClick={() => handlePaymentSelected(paymentTypes.totalPayment)} 
                                    className={classes.listItemText}  
                                />
                            </ListItemButton>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Divider className={classes.divider}/>
                            <ListItemButton className={classes.listItemPayment}>
                                <ListItemText 
                                    primary={`Otro Monto`} 
                                    onClick={handleOtherValue}
                                    className={classes.listItemText} 
                                />
                            </ListItemButton>
                        </Grid>
                    </Grid>
                </ListItem>
            )
            setPaymentDialog(true);

    }

    const [paymentDialog, setPaymentDialog] = React.useState(false);
    const [paymentInput, setPaymentInput] = React.useState(true);

    const handlePaymentsClose = (renderTime) =>{
        
        setDialogTitle(paymentTitles.paymentOptions);
        setPaymentInput(true);

        if(renderTime!=="payment"){
            setLoading(false);
        }

        setPaymentDialog(false);
    }

    const handleStatementRender = () =>{
        setLoading(true);
        
        const getStatementObject = {
            account: visitorLoans.account,
            asset: visitorLoans.asset,
            loanId: visitorLoans.id,
        }
        
        fetchStatement(getStatementObject, loginUser.jwtToken).then((statements) => {
            setLoading(false);
            if(statements !== "Not found"){
                setRenderStatement(true);
                setStatementList(
                    <MenuList className={classes.menuList}>
                            {statements.map((statement) => {
                                return(  
                                    <Grid container className={classes.gridStatement} key={statement.date}>
                                        <Grid item xs={12} sm={12} md={12} key={statement.date}>
                                            <Divider className={classes.divider}/>
                                            <MenuItem key={statement.date}>
                                                <ListItemText key={statement.date} className={classes.statementDate}>                                                
                                                {`${new Intl.DateTimeFormat("es-CO", dateOptions).format(new Date(statement.date))}`}
                                                </ListItemText>
                                            </MenuItem>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} key={statement.date+1}> 
                                            <MenuItem key={statement.date+1}>
                                                <ListItemText key={statement.date+1}>
                                                {`Pago: ${new Intl.NumberFormat('es-CO').format(statement.payment)}`}
                                                </ListItemText>
                                            </MenuItem>
                                        </Grid>
                                    </Grid>
                                    
                                    )
                            })}
                        
                    </MenuList>
                )
            }else{
                //swal
            }

        });

    };

    const handleAccountStatementRender = () =>{
        setLoading(true);
        
        const getStatementObject = {
            account: visitorLoans.account,
            asset: visitorLoans.asset,
            loanId: visitorLoans.id,
        }
        
        fetchAccountStatement(getStatementObject, loginUser.jwtToken).then((statements) => {
            setLoading(false);
            if(statements !== "Not found"){
                setRenderStatement(true);
                setStatementList(
                    <MenuList className={classes.menuList}>
                            {statements.map((statement) => {
                                return(  
                                    <Grid container className={classes.gridStatement} key={statement.date}>
                                        <Grid item xs={12} sm={12} md={12} key={statement.date}>
                                            <Divider className={classes.divider}/>
                                            <MenuItem key={statement.date}>
                                                <ListItemText key={statement.date} style={{color:"blue"}}>                                                
                                                {`Crédito No. ${statement.loanId}`}
                                                </ListItemText>
                                            </MenuItem>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} key={statement.date}>
                                            <Divider className={classes.divider}/>
                                            <MenuItem key={statement.date}>
                                                <ListItemText key={statement.date} className={classes.statementDate}>                                                
                                                {`${new Intl.DateTimeFormat("es-CO", dateOptions).format(new Date(statement.date))}`}
                                                </ListItemText>
                                            </MenuItem>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} key={statement.date+1}> 
                                            <MenuItem key={statement.date+1}>
                                                <ListItemText key={statement.date+1}>
                                                {`Pago: ${new Intl.NumberFormat('es-CO').format(statement.payment)}`}
                                                </ListItemText>
                                            </MenuItem>
                                        </Grid>
                                    </Grid>
                                    
                                    )
                            })}
                        
                    </MenuList>
                )
            }else{
                //swal
            }

        });

    };

    //Redeferred functions and variables

    const handleNewTerms = () => {
        if(visitorLoans.isRedeferredEnabled && visitorLoans.isRedeferredEnabled === true){
            setLoading(true);
            updateVisitorLoans(loginUser.accountId, loginUser.jwtToken).then((response) => {
                if(response){
                    updateVisitorAccounts(loginUser.accountId, loginUser.jwtToken).then((response) => {
                        if(response){
                            history.push(
                                {pathname: "/credit_newterms",
                                state: {
                                    refinancingValue: visitorLoans.totalPayment 
                                },}
                            );
                            setLoading(false);
                        }
                    });
                }
            }); 
        }
        else{
            swal({
                title: "PAGO REQUERIDO",
                icon: "info",
                text: `Para continuar con el refinanciamiento debes hacer un pago del ${refinancingConditionsSettings.enablePayment * 100}% del total de tu 
                deuda que corresponde a $${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0}).format(refinancingBalance)}`,
                button: "Acepto",
            }).then(() => {
                handleRedeferredPayment();
            });
        }
            
    };
    
    const[redeferredPayment, setRedeferredPayment] = React.useState('');
    const[redeferredDialog, setRedeferredDialog] = React.useState(false);

    const closeRedeferredDialog = (renderTime) => {
       
        
        setDialogTitle(paymentTitles.paymentOptions);
        setPaymentInput(true);

        if(renderTime!=="payment"){
            setLoading(false);
        }

        setRedeferredDialog(false);
    };

    const handleRedeferredPayment = () => {
        setRedeferredPayment(
            <ListItem>
                    <Grid container spacing={2} direction={"column"} className={classes.grid}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Divider className={classes.divider}/>
                        <ListItemButton className={classes.listItemPayment}>
                            <ListItemText 
                                primaryTypographyProps={{ style: { whiteSpace: "pre-line" } }}
                                primary={`Pago refinanciamiento: $${new Intl.NumberFormat('es-CO',{maximumFractionDigits: 0}).format(refinancingBalance)}`} 
                                onClick={() => handleMinRedeferred()} 
                                className={classes.listItemText}  
                            />
                        </ListItemButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Divider className={classes.divider}/>
                        <ListItemButton className={classes.listItemPayment}>
                            <ListItemText 
                                primary={`Otro Monto Mayor`} 
                                onClick={handleOtherValueForRedeferred}
                                className={classes.listItemText} 
                            />
                        </ListItemButton>
                    </Grid>
                </Grid>
            </ListItem>
        );
        setRedeferredDialog(true);
    };

    const handleMinRedeferred = () => {
        loanPaymentValue = Math.ceil(refinancingBalance);
        selectProductForRefinancing();
    };

    const selectProductForRefinancing = () => {

        if(loanPaymentValue < refinancingBalance){
            swal({
                title: "PAGO MÍNIMO",
                icon: "info",
                text: `El valor mínimo a pagar es de $${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0}).format(refinancingBalance)}`,
                button: "Entendido",
            }).then(() => {
                handlePaymentsClose("payment");
            });
            return;
        }

        console.log(loanPaymentProvider);
        if(loanPaymentProvider === "combopay"){
            
            comboPayment(true);
            return;
        }
        
        setPaymentInput(true);
        setDialogTitle(paymentTitles.accountSelect);
        setRedeferredPayment(
            <MenuList className={classes.menuList}>
            {visitorsAccountBalances.map((account) => {
                return(
                    <div>
                    <Divider className={classes.divider}/>  
                    <MenuItem key ={account.asset} className={classes.menuItemProducts}>
                        <Grid container className={classes.gridProducts} key={account.asset}>
                            <Grid item xs={12} sm={12} md={12} key={account.asset}>
                                    <ListItemText 
                                        key={account.asset}
                                        onClick={() => handleProductSelectedForRefinancing(account)}
                                    >
                                    {`Token: ${account.asset}`}
                                    </ListItemText>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} key={account.asset+1}>
                                    <ListItemText 
                                        key={account.asset+1}
                                        onClick={() => handleProductSelectedForRefinancing(account)}
                                    >
                                    {`${new Intl.NumberFormat('es-CO',
                                            {maximumFractionDigits: 0},)
                                            .format(account.balance)}`}
                                    </ListItemText>
                            </Grid>
                        </Grid>  
                    </MenuItem>
                    </div>
                )
            })}
                <Divider className={classes.divider}/> 
                <MenuItem className={classes.menuItemProducts}>
                    <ListItemText 
                        onClick={handleExternalPayment}
                        style={{color: "blue", fontWeight: "bold"}}
                    >
                        Pago Digital
                    </ListItemText>
                </MenuItem>
            </MenuList>
        )

    };

    const handleProductSelectedForRefinancing = (account) => {
        setLoading(true);
        closeRedeferredDialog("payment");

        console.log(loanPaymentValue);
        payment.source.address = account.xrplAddress;

        // The xrp payment must be done in the source asset to simplify the payment. 
        payment.source.maxAmount.currency = account.asset;
        payment.destination.amount.currency = account.asset;

        assetsToFetch.fromAsset = visitorLoans.asset;
        assetsToFetch.toAsset = account.asset;

        fetchAssetRates(assetsToFetch, loginUser.jwtToken).then((rate) => {
            
            // Math.round(((value)+Number.EPSILON)*100000000)/100000000;

            payment.source.maxAmount.value = String(Math.round(((loanPaymentValue * rate.rate)+Number.EPSILON)*1000)/1000);
            payment.destination.amount.value = payment.source.maxAmount.value;
            
            xrpPayment(payment, "", "", clientXrpSecret).then((paymentResult) => {
                if(paymentResult === "payment ok"){
                    
                    const installmentObject = {
                        account: visitorLoans.account,
                        asset: visitorLoans.asset,
                        installmentValue: loanPaymentValue,
                        loanId: visitorLoans.id,
                    }; 

                    refinancingPayment(installmentObject, loginUser.jwtToken).then((paymentResult) => {

                        if(paymentResult !== "Not found"){

                            const prepareStatement = {
                                account: visitorLoans.account,
                                asset: visitorLoans.asset,
                                payment: loanPaymentValue,
                                originAccount: account.xrplAddress,
                                loanId: visitorLoans.id,
                            }

                            prepareLoanStatement(prepareStatement, loginUser.jwtToken).then(() =>{
                                swal({
                                    title: "PAGO EXITOSO",
                                    icon: "success",
                                    button: "Súper",
                                }).then(() => {
                                    //Update the account and loan information
                                    loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                                        if(loadedInfo.visitorLoans && loadedInfo.visitorAccounts){
                                            history.push(
                                                {pathname: "/credit_newterms",
                                                state: {
                                                    refinancingValue: loadedInfo.visitorLoans.totalPayment 
                                                },}
                                            );
                                            setLoading(false);
                                        }else{
                                            setLoading(false);
                                            return;
                                        }
                                    });
                                });

                                
                            })

                        } else{
                            swal({
                                title: "Ocurrió un error",
                                icon: "error",
                                button: "Entiendo",
                            }).then(() => {
                                history.push(
                                    {pathname: "/credit/handle_credit"}
                                );
                            });
                        }
                    });
                }
                else if(paymentResult === "tecPATH_PARTIAL"){
                    swal({
                        title: "Pago no exitoso",
                        text: "Fondos insuficientes",
                        icon: "error",
                        button: "Entiendo",
                    }).then(() => {
                        history.push(
                            {pathname: "/credit/handle_credit"}
                        );
                    });
                }

                else{
                    swal({
                        title: "Pago no exitoso",
                        text: "Por favor culeve a intentarlo",
                        icon: "error",
                        button: "Entiendo",
                    }).then(() => {
                        history.push(
                            {pathname: "/credit/handle_credit"}
                        );
                    });
                }
            });

        });
    };

    //Other value for redeferring
        
    function handleOtherValueForRedeferred(){

        setPaymentInput(false);
        setRedeferredPayment(
            <NumberFormat
                className={classes.input}
                name={"formatNumber"}
                customInput={TextField}
                // prefix={'WUPO '}
                prefix={'$ '}
                type="text"
                label="Valor que vas a pagar"
                thousandSeparator={'.'}
                decimalSeparator={','}
                autoComplete={"off"}
                onValueChange={(values) => {
                    // const {formattedValue, value, floatValue} = values;
                    handleOtherPaymentForRedeferring(values);
                    
                }}
            />   
        );
        

        setDialogTitle(paymentTitles.otherValue);
    }

    const handleOtherPaymentForRedeferring = (values) => {
        loanPaymentValue = values.floatValue;
        // console.log(loanPaymentValue);
    };

    return (
        <>
        <Box className={classes.svgBackground} sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%",}}>
            <div
            style={{
            display: "flex",
            // flexGrow: "1",
            justifyContent: "center",
            width: "100%",
            height: "85%",
            }}
            >
            
                {visitorLoans ?
                    <Content maxWidth={"500px"}>
                    {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <>
                        {renderStatement ?
                            <>
                                <PageLabel>TUS PAGOS</PageLabel>
                                {statementList}
                                <AppButton
                                    primary
                                    onClick={() => setRenderStatement(false)}
                                    width={"85%"}
                                    style={{ marginBottom: "1rem" }}
                                >
                                    ATRÁS
                                </AppButton>     
                            </>
                            :
                            <>
                                <PageLabel>TU CRÉDITO</PageLabel>         
                                {/* <Typography className={classes.creditTypographyH5} noWrap>
                                    {`${new Intl.NumberFormat('es-CO', {
                                        maximumFractionDigits: 0,
                                    }).format(visitorLoans.currentBalance + visitorLoans.loanFee)} ${visitorLoans.asset}`}
                                </Typography> */}
                                <Typography style={{fontWeight: "bolder", color: Colors.primary, fontSize: "1rem"}}>
                                    {`Número: ${visitorLoans.id}`}
                                </Typography>
                                <Typography className={classes.creditTypographyH5} noWrap>
                                    {`$ ${new Intl.NumberFormat('es-CO', {
                                        maximumFractionDigits: 0,
                                    }).format(visitorLoans.totalPayment)}`}
                                </Typography>
                                {/* <Typography className={classes.creditTypographyH6} noWrap>
                                    {`(Incluye Costo: ${new Intl.NumberFormat('es-CO', {
                                        maximumFractionDigits: 0,
                                    }).format(visitorLoans.loanFee)} ${visitorLoans.asset})`}
                                </Typography> */}
                                <Typography className={classes.creditTypographyH6} noWrap>
                                    {/* (Incluye costos, aval e intereses causados) */}
                                    (Incluye costos, aval e intereses a hoy)
                                </Typography>
                                <Typography className={classes.creditTypographyH6} noWrap>
                                    {`Costo plataforma $ ${new Intl.NumberFormat('es-CO', {
                                        maximumFractionDigits: 0,
                                    }).format(visitorLoans.costWithTaxes)}`}
                                </Typography>
                                <Typography className={classes.creditTypographyH6} noWrap>
                                    {`Aval $ ${new Intl.NumberFormat('es-CO', {
                                        maximumFractionDigits: 0,
                                    }).format(visitorLoans.costWithoutTaxes)}`}
                                </Typography>
                                {/* <Typography className={classes.creditTypographyH6} noWrap>
                                    {`Intereses ${new Intl.NumberFormat('es-CO', {
                                        maximumFractionDigits: 0,
                                    }).format(visitorLoans.loanFee)}`}
                                </Typography> */}
                                

                                <Typography className={classes.paymentTypography} noWrap>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: "center"}}>
                                            {`Fecha próximo pago: ${new Intl.DateTimeFormat("es-CO", onlyDateOption)
                                            .format(new Date(visitorLoans.nextInstallmentDate))}`}
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: "center", marginTop: "0.5rem"}}>
                                            <span style={{color: "red"}}>{visitorLoans.defaultDays > 0 ? `Mora de ${visitorLoans.defaultDays} días`: ""}</span>
                                        </Grid>
                                    </Grid>
                                </Typography>
                                <Grid container>
                                    {visitorLoans.defaultDays > 0 ?
                                        <>
                                        <Grid item xs={4} sm={4} md={4}></Grid>
                                        <Grid item xs={8} sm={8} md={8}>
                                        <div style={{maxWidth: "120px"}}>
                                            <AppButton
                                                width={"90%"}
                                                height={"95%"}
                                                onClick={handleNewTerms}
                                                style={{ marginBottom: "2rem", backgroundColor: "#87C1FF", color: "white", fontSize: "1em", borderRadius: "3px" }}
                                            >
                                                Refinancia
                                            </AppButton>
                                            </div>
                                        </Grid>
                                        </>
                                        : <></>
                                    
                                    }
                                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "end"}}>
                                        <AppButton
                                            primary
                                            width={"45%"}
                                            onClick={handlePayment}
                                            style={{ marginBottom: "1rem", marginRight: "0.8rem" }}
                                        >
                                            PAGA
                                        </AppButton>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start"}}>
                                        <AppButton
                                        secondary
                                            onClick={handleAccountStatementRender}
                                            width={"45%"}
                                            style={{ marginBottom: "1rem", marginLeft: "0.8rem" }}
                                            // style={{ marginBottom: "1rem", background: "none", color: "blue" }}
                                        >
                                            PAGOS
                                        </AppButton>
                                    </Grid>
                                </Grid>
                            </>    
                        }
                    </>
                    }
                    </Content>
                :<></>}
                <>
                <Dialog open={paymentDialog} onClose={handlePaymentsClose} aria-labelledby="form-dialog-tokens" 
                    fullWidth={true} maxWidth={"xs"}
                >
                    <DialogTitle id="form-dialog-title3">{dialogTitle}</DialogTitle>
                    <DialogContent>
                        {paymentOptions}
                    </DialogContent>  
                    <DialogActions>
                        <Button onClick={handlePaymentsClose} color="primary">
                            ATRÁS
                        </Button>
                        <Button 
                            onClick={selectProduct} color="primary" disabled={paymentInput}>
                            PAGAR
                        </Button>
                    </DialogActions>
                </Dialog>
                </>
                <>
                <Dialog open={redeferredDialog} onClose={closeRedeferredDialog} fullWidth={true} maxWidth={"xs"}
                >
                    <DialogTitle>{dialogTitle}</DialogTitle>
                    <DialogContent>
                        {redeferredPayment}
                    </DialogContent>  
                    <DialogActions>
                        <Button onClick={closeRedeferredDialog} color="primary">
                            ATRÁS
                        </Button>
                        <Button 
                            onClick={selectProductForRefinancing} color="primary" disabled={paymentInput}>
                            PAGAR
                        </Button>
                    </DialogActions>
                </Dialog>
                </>
            
            </div>
        </Box>
        </>
    )
}
