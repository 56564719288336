import React from 'react';
import { useCSVReader } from 'react-papaparse';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Colors from "../../components/ui/Colors";
import { Typography } from '@mui/material';
import { batchXrpPayment, batchXrpPaymentPrep } from "../../utilityFunctions/Paymentutil";
import swal from 'sweetalert';

const rewardsKeys = ["idNumber", "asset", "value", "description", "counterpartyName", "counterpartyAccount"];

let uploadFile;

let rewardsArray = [];

const batchPayment = {
    source: {
      address: "",
      maxAmount: {
        value: "",
        currency: "",
        counterparty: "",
      },
    },
    destination: {
      address: "",
      amount: {
        value: "",
        currency: "",
        counterparty: "",
      },
    },
  };

export default function LoyaltyUpload(props) {

    const {loginUser} = props

    const { CSVReader } = useCSVReader();

    React.useEffect(() => {
        rewardsArray = [];
    },[])

    const payWithFile = async () =>{
        rewardsArray = [];
        console.log("Entering pay with file");
        console.log(uploadFile);

        uploadFile.data.forEach((employee) => {
            const rewardObj = employee.reduce((acc, value, index) => {
                return {...acc, [rewardsKeys[index]]: value}
            }, {});

            rewardsArray.push(rewardObj);

        });

        console.log(rewardsArray);

        const paymentArray = await batchXrpPaymentPrep(rewardsArray, loginUser.jwtToken);

        // paymentArray.xrplPayment.forEach((payment) => {
        //     payment.source.maxAmount.value = String(payment.source.maxAmount.value);
        //     payment.destination.amount.value = String(payment.destination.amount.value);
        //     batchXrpPayment(payment, paymentArray.secret).then((result) => {
        //         console.log(result);
        //     });
        // });

        for(var i = 0; i < paymentArray.xrplPayment.length; i++){
            paymentArray.xrplPayment[i].source.maxAmount.value = String(paymentArray.xrplPayment[i].source.maxAmount.value);
            paymentArray.xrplPayment[i].destination.amount.value = String(paymentArray.xrplPayment[i].destination.amount.value);
            const result = await batchXrpPayment(paymentArray.xrplPayment[i], paymentArray.secret);
            console.log(result);
        };

        swal({
            title: "LISTO",
            icon: "success",
            buttons: "OK"
        });

    };


    return(
        
    <div
        style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "85%",
        minWidth: "20rem"
        }}
        >
        <Grid container spacing = {10} style={{maxWidth: "600px", justifyContent: "center", alignItems: "center", marginTop:"0.2em", marginBottom: "0.2em"}}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography style={{color: Colors.labelColor,
                    fontSize: "1.5rem",
                    textAlign: "center",
                    fontWeight: 700,
                    margin: 0,}}>
                    ESCOGE EL ARCHIVO
                </Typography>
                <div style={{height: "0.3rem", width: "13rem", backgroundColor: Colors.secondary, marginLeft: "auto", marginRight: "auto", marginTop: "0.4rem"}}></div>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <CSVReader
                        onUploadAccepted={(results) => {
                            console.log(results);
                            uploadFile = results;
                        }}
                        >

                        {({
                                getRootProps,
                                acceptedFile,
                                ProgressBar,
                                getRemoveFileProps,
                            }) => (
                            <>
                            <Grid container style={{marginTop: "0.3em", justifyContent: "center"}}>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Box style={{display: 'flex', textAlign: "center", justifyContent: "center"}}>
                                        <Button type='button' {...getRootProps()} style={{width: '100%', backgroundColor: Colors.primary, color: "white"}}>
                                            Explorar
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5}>
                                    <Box style={{display: 'flex', textAlign: "center", justifyContent: "center"}}>
                                        <Typography style={{border: '0.3px solid #ccc', height: 40, lineHeight: 2, width: '80%',}}>
                                            {acceptedFile && acceptedFile.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3}>
                                    <Box style={{display: 'flex', textAlign: "center", justifyContent: "center"}}>
                                        <Button {...getRemoveFileProps()} style={{width: '100%',backgroundColor: Colors.primary, color: "white"}}>
                                            Borrar
                                        </Button>
                                    </Box>
                                </Grid>     
                            <ProgressBar style={{backgroundColor: Colors.third, marginTop: "0.8rem", height: "0.3rem", maxWidth: "30rem"}} />
                            </Grid>
                            </>
                        )}
                        </CSVReader>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            height="100%"
                        >
                            <Button
                                onClick={payWithFile}
                                style={{backgroundColor: Colors.secondary, color: "white"}}
                            >
                                Listo  
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
        
    )
}
