import {wupoServerUri} from '../Api';

const getAllDealsDefinitions = (jwtToken) => {
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/dealsDefinitions`, {
          method: "POST",
        //   body: JSON.stringify(definitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((definitionsResponse) => {
          console.log(definitionsResponse); // DEBUG PRINTING
          
          return definitionsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const changeDealsDefinitions = (dealsDefinitions, jwtToken) => {
  
    console.log(dealsDefinitions);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/modifyDealsDefinition`, {
          method: "POST",
          body: JSON.stringify(dealsDefinitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((definitionsResponse) => {
          console.log(definitionsResponse); // DEBUG PRINTING
          
          return definitionsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const createDealsDefinitions = (dealsDefinitions, jwtToken) => {
  
    console.log(dealsDefinitions);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/createDealsDefinition`, {
          method: "POST",
          body: JSON.stringify(dealsDefinitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((definitionsResponse) => {
          console.log(definitionsResponse); // DEBUG PRINTING
          
          return definitionsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const getAllDeals = (jwtToken) => {
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/deals`, {
          method: "POST",
        //   body: JSON.stringify(definitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((dealsResponse) => {
          console.log(dealsResponse); // DEBUG PRINTING
          
          return dealsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const createClients = (clientsList, jwtToken) => {
  
    console.log(clientsList);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/trustClients`, {
          method: "POST",
          body: JSON.stringify(clientsList),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((clientResponse) => {
          console.log(clientResponse); // DEBUG PRINTING
          
          return clientResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const createProducts = (productsList, jwtToken) => {
  
    console.log(productsList);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/trustProducts`, {
          method: "POST",
          body: JSON.stringify(productsList),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((productsResponse) => {
          console.log(productsResponse); // DEBUG PRINTING
          
          return productsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const createRelated = (relatedList, jwtToken) => {
  
    console.log(relatedList);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/trustRelated`, {
          method: "POST",
          body: JSON.stringify(relatedList),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((productsResponse) => {
          console.log(productsResponse); // DEBUG PRINTING
          
          return productsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const dealsAnalytics = (jwtToken) => {
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/dealsanalytics`, {
          method: "POST",
        //   body: JSON.stringify(definitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return "ok";
          }
          else{
              return "Not found";
          }
      })
    //   .then((dealsResponse) => {
    //       console.log(dealsResponse); // DEBUG PRINTING
          
    //       return dealsResponse;
    //   })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const changeDealValue = (potentialValue) => {
  
    console.log(potentialValue);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/auth/dealValue`, {
          method: "POST",
          body: JSON.stringify(potentialValue),
          credentials: 'include',
          headers: {
            // "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((productsResponse) => {
          console.log(productsResponse); // DEBUG PRINTING
          
          return productsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const sendEmails = (jwtToken) => {
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/dealsEmails`, {
          method: "POST",
        //   body: JSON.stringify(definitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return "ok";
          }
          else{
              return "Not found";
          }
      })
    //   .then((dealsResponse) => {
    //       console.log(dealsResponse); // DEBUG PRINTING
          
    //       return dealsResponse;
    //   })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const assignDeal = (dealRequest, jwtToken) => {
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/dealstauts`, {
          method: "POST",
          body: JSON.stringify(dealRequest),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return "ok";
          }
          else{
              return "Not found";
          }
      })
    //   .then((dealsResponse) => {
    //       console.log(dealsResponse); // DEBUG PRINTING
          
    //       return dealsResponse;
    //   })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const closeDeal = (dealRequest, jwtToken) => {

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/dealclosing`, {
          method: "POST",
          body: JSON.stringify(dealRequest),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return "ok";
          }
          else{
              return "Not found";
          }
      })
    //   .then((dealsResponse) => {
    //       console.log(dealsResponse); // DEBUG PRINTING
          
    //       return dealsResponse;
    //   })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const createDeal = (newDeal, jwtToken) => {
    console.log(newDeal);

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/newDeal`, {
          method: "POST",
          body: JSON.stringify(newDeal),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
      })
      .then((dealsResponse) => {
          console.log(dealsResponse); // DEBUG PRINTING
          
          return dealsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const getTrustClients = (jwtToken) => {

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/listtrustclients`, {
          method: "POST",
        //   body: JSON.stringify(newDeal),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
      })
      .then((clientsResponse) => {
          console.log(clientsResponse); // DEBUG PRINTING
          
          return clientsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const getTrustCommercials = (jwtToken) => {

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/listtrustsales`, {
          method: "POST",
        //   body: JSON.stringify(newDeal),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
      })
      .then((salesResponse) => {
          console.log(salesResponse); // DEBUG PRINTING
          
          return salesResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

export {getAllDealsDefinitions, changeDealsDefinitions, createDealsDefinitions, getAllDeals, createClients, createProducts,
     dealsAnalytics, changeDealValue, sendEmails, assignDeal, closeDeal, createDeal, getTrustClients, getTrustCommercials,
     createRelated}