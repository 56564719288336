function ilpProfitAccountsFetch(){
    return new Promise((resolve, reject)=>{
        fetch('http://wupoapi.ngrok.io/profit', {

          method: 'GET',
          
        }).then(response=>{ 
        return response.json();

        }).then(body=>{
            // console.log("Wupo's profit accounts JSON "+JSON.stringify(body))
            resolve(
                body
            );
        }).catch(error=>{
            console.log("Error msg: "+ error);
            reject(
                error
            );
        });

    })
    
}

function ilpProfitDistributionFetch(){
    return new Promise((resolve, reject)=>{
        fetch('http://wupoapi.ngrok.io/spread', {

            method: 'GET',
            
        }).then(response=>{ 
        return response.json();

        }).then(body=>{
            // console.log("Wupo's profit asset distribution JSON "+JSON.stringify(body))
            resolve(
                body
            );
        }).catch(error=>{
            console.log("Error msg: "+ error);
            reject(
                error
            );
        });

    });    
}

function ilpSetProfitDistribution(spreadDistribution){
    return new Promise((resolve, reject)=>{
        // console.log("Spread distribution received from inputs: " + JSON.stringify(spreadDistribution))
        fetch('http://wupoapi.ngrok.io/profit', {

            method: 'POST',
            body: JSON.stringify(spreadDistribution),
            headers: {
                // 'Authorization': 'Basic YWRtaW46QTAxMTYwMjgwdGVj',
                'Content-Type': 'application/json'                     
            },
            
        }).then(response=>{ 
        return response.text();

        }).then(body=>{
            // console.log("Wupo's profit distribution set status "+body)
            resolve(
                body
            );
        }).catch(error=>{
            console.log("Error msg: "+ error);
            reject(
                error
            );
        });    

    });
    
}

export{ilpProfitAccountsFetch, ilpProfitDistributionFetch, ilpSetProfitDistribution};