import React from "react"
import logo from "../../logo/Nombre-Final.png"

const AppLabel = (props) => {
  const { bgColor, logoVisible, title, subTitle, color } = props
  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: color ? color : "white",
        width: "100%",
        padding: "10px 25px",
        textAlign: "center"
      }}
    >
      { logoVisible &&
        <img
          src={logo}
          style={{
            height: "30px",
            marginTop: "10px",
          }}
          alt="logo"
        />
      }
      <div
        style={{
          textAlign: "center"
        }}
      >
        <h1
          style={{
            margin: 0,
            fontSize: "56px"
          }}
        >
          {title}
        </h1>
        <h5
          style={{
            margin: 0,
            marginBottom: "10px",
            fontSize: "20px"
          }}
        >
          {subTitle}
        </h5>
      </div>
    </div>
  )
}

export default AppLabel