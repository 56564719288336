import React from 'react';
import {getBankAccount, changeClientPii, getClientPii} from "../../utilityFunctions/MarketPlaceUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors"; 
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { AiOutlineIdcard } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { FaPiggyBank } from "react-icons/fa";
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import AvatarJorge from "../../img/avatars/avatar_jorge.png";
import Box from '@mui/material/Box';
import swal from "sweetalert";
import PersonIcon from '@mui/icons-material/Person';
import { useHistory } from "react-router-dom";


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let role;

const changeDataObject = {
    accountId: "",
    name: "",
    lastName: "",
    idNumber: "",
    phone: "",
    representativeName: "",
    representativeLastName: "",
    representativeIdNumber: "",
};

export default function ClientRol(props) {

    const {loginUser, loadProductsAndSetting} = props

    const history = useHistory();

    const[loading, setLoading] = React.useState(false);
    const[profile, setProfile] = React.useState('');

    React.useEffect(() => {

        changeDataObject.accountId=loginUser.accountId;
        setLoading(true);
        handleRole();
        getPii();

    }, []);

    const getActiveBankAccount = async () => {
        // const bankAccountRequest = {
        //     username: loginUser.accountId,
        // };
        
        // const bankAccount = await getBankAccount(bankAccountRequest, loginUser.jwtToken);

        // return bankAccount;

        return null;
    }

    const [lastName, setLastName] = React.useState("");
    const [idNumber, setIdNumber] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [name, setName] = React.useState("");
    const [repName, setRepName] = React.useState("");
    const [repLastName, setRepLastName] = React.useState("");
    const [repIdNumber, setRepIdNumber] = React.useState("");
    const [isCompany, setIsCompany] = React.useState(false);

    const changeIdNumber = (e) => {
        setIdNumber(e.target.value);
        changeDataObject.idNumber=e.target.value;
    };

    const changePhone = (e) => {
        setPhone(e.target.value);
        changeDataObject.phone=e.target.value;
    };

    const changeName = (e) => {
        setName(e.target.value);
        changeDataObject.name=e.target.value;
    };

    const changeLastName = (e) => {
        setLastName(e.target.value);
        changeDataObject.lastName=e.target.value;
    };

    const changeRepIdNumber = (e) => {
        setRepIdNumber(e.target.value);
        changeDataObject.representativeIdNumber=e.target.value;
    };

    const changeRepName = (e) => {
        setRepName(e.target.value);
        changeDataObject.representativeName=e.target.value;
    };

    const changeRepLastName = (e) => {
        setRepLastName(e.target.value);
        changeDataObject.representativeLastName=e.target.value;
    };

    const[changeBox, setChangeBox] = React.useState(false);
    
    const closeChangeBox = () => {
        setChangeBox(false);
    }

    const changeClientData = () => {
        setChangeBox(true);
    };

    const toChangeData = async () => {

        closeChangeBox();

        setLoading(true);
        const changeResponse = await changeClientPii(changeDataObject, loginUser.jwtToken);
        setLoading(false);

        if(!changeResponse){
            swal({
                title: "Error",
                text: "No pudimos hacer los cambios. Vuleve a intentarlo más tarde.",
                icon: "error",
                button: "ok",
            });
            return;
        };

        getPii();
    };

    const getPii = async () => {

        const bankAccount = await getActiveBankAccount();
        console.log(bankAccount);

        // const clientInfo = await fetchSingleClientsInformation(loginUser.accountId, loginUser.jwtToken);
        const clientInfo = await getClientPii(loginUser.accountId, loginUser.jwtToken);
        const conditional = clientInfo.changesLocked ? true : false;
        console.log(conditional);
        console.log(clientInfo.changesLocked);
        if(clientInfo.changesLocked !== null && clientInfo.changesLocked === false){
            console.log("entering changes unlocked")
            setIdNumber(clientInfo.idNumber);
            changeDataObject.idNumber=clientInfo.idNumber;
            setPhone(clientInfo.phone);
            changeDataObject.phone=clientInfo.phone;
            setName(clientInfo.name);
            changeDataObject.name=clientInfo.name;
            setLastName(clientInfo.lastName);
            changeDataObject.lastName=clientInfo.lastName;

            if(clientInfo.clientType && clientInfo.clientType==="company"){
                setIsCompany(true);
                setRepIdNumber(clientInfo.representativeIdNumber);
                changeDataObject.representativeIdNumber=clientInfo.representativeIdNumber;
                setRepName(clientInfo.representativeName);
                changeDataObject.representativeName=clientInfo.representativeName;
                setRepLastName(clientInfo.representativeLastName);
                changeDataObject.representativeLastName=clientInfo.representativeLastName;
            }
        };

        setProfile(
            <>
            
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={3} md={3} sx={{display: "flex", justifyContent: {xs: "center", sm: "start"}, alignItems: "center"}}> 
                        <Grid container direction="column" spacing={4}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", width: "100%", justifyContent: {xs: "center", sm: "center"}}}>
                                <Avatar alt="Perfil" src={Avatar} style={{ height: '130px', width: '130px' }}>
                                    <PersonIcon style={{fontSize: "3rem"}} />
                                </Avatar>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center"}}>
                                <Button variant="contained" style={{backgroundColor: Colors.primary, color:"white", marginTop: "1.5rem"}} onClick={clickBack}>
                                    NUEVA
                                </Button>
                            </Grid> */}
                        </Grid>                       
                    </Grid>
                    <Grid item xs={12} sm={9} md={9} sx={{textAlign: {xs: "center", sm: "start"}}}> 
                        <Typography sx={{typography:{xs: "h5", sm: "h4"}}}>
                            {`${clientInfo.name} ${clientInfo.lastName}`}
                        </Typography>
                        <Grid container direction="row" spacing={4} style={{justifyContent: "start", marginTop: "1.1rem"}}> 
                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <AiOutlineIdcard size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.idNumber}`}
                                </Typography>   
                            </Grid>
                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <FaMobileAlt size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.phone}`}
                                </Typography>   
                            </Grid>

                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <HiOutlineMail size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body2" color="text.secondary">
                                    {`${clientInfo.email}`}
                                </Typography>   
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} style={{textAlign: "start"}}>
                                <Button onClick={() => changeClientData(clientInfo)} variant="contained" sx={{backgroundColor: "#e3e4e5", color:"black", marginTop: "0.8rem"}}>
                                    Change Data
                                </Button> 
                            </Grid>
                            {!bankAccount ? <></>:
                            
                            bankAccount === "pending" ?
                            <>
                                <Grid item xs={12} sm={12} md={12} style={{textAlign: "start"}}>
                                    <Button onClick={pendingApproval} sx={{backgroundColor: "none", color: "GrayText", fontSize: "0.8rem", fontWeight: "bold"}}>
                                        Cuenta en aprobación
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{textAlign: "start"}}>
                                    <Button onClick={pendingRegister} variant="contained" sx={{backgroundColor: Colors.primary, color:"white", marginTop: "0.2rem"}}>
                                        Nueva cuenta
                                    </Button> 
                                </Grid>
                            </>
                            : bankAccount === "not found" ?
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Button onClick={pendingRegister} variant="contained" sx={{backgroundColor: Colors.primary, color:"white", marginTop: "1rem"}}>
                                        Inscribe tu cuenta
                                    </Button>
                                </Grid>
                            :
                            bankAccount === "rejected" ?
                                <>
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Button onClick={rejectedMsg} sx={{backgroundColor: "none", color: "GrayText", fontSize: "0.8rem", fontWeight: "bold"}}>
                                        Cuenta rechazada
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{textAlign: "start"}}>
                                    <Button onClick={pendingRegister} variant="contained" sx={{backgroundColor: Colors.primary, color:"white", marginTop: "0.2rem"}}>
                                        Nueva cuenta
                                    </Button> 
                                </Grid>
                                </>
                            :
                            <>
                                <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                    <FaPiggyBank size={20} />
                                </Grid>
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Typography variant="body2" color="text.secondary">
                                        {`${bankAccount.bankAccountTypeString} - ${bankAccount.bankAccount}`}
                                    </Typography>   
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{textAlign: "start"}}>
                                    <Button onClick={pendingRegister} variant="contained" sx={{backgroundColor: Colors.primary, color:"white", marginTop: "0.2rem"}}>
                                        Nueva cuenta
                                    </Button> 
                                </Grid>
                            </>
                            }
                                
                                
                            
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Box sx={{display: {xs: "flex", sm: "none"}, width: "100%", justifyContent: {xs: "center", sm: "start"}}}>
                    <Button variant="contained" sx={{display: {xs: "none", sm: "none", md: "flex"}, backgroundColor: Colors.primary, color:"white", marginTop: "1.5rem"}} onClick={clickBack}>
                        NUEVA
                    </Button>
                </Box> */}
            </Box>
            </>
        );
        setLoading(false);
        

    };

    const pendingApproval = () => {
        swal({
            title: "Pendiente Aprobación",
            text: "Tu cuenta todavía no está activa. Estamos revisando la documentación para activarla",
            icon: "info",
            button: "Entiendo",
        });
    }

    const pendingRegister = () => {
        history.push({pathname: "/info/bank-account"});
    }

    const rejectedMsg = () => {
        swal({
            title: "Cuenta rechazada",
            text: "Los documentos que nos suministraste no coinciden con los datos registrados. Te invitamos a que revises que tu información coincida con los documentos que subiste para soportar la propiedad de la cuenta bancaria registrada",
            icon: "info",
            button: "Entiendo",
        });
    }

    const handleRole = () => {
        return(new Promise((resolve) => {
            if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
                role = loginUser.roles[0].authority;
                console.log(role);
                resolve(role);
            }
        }));
    };

    const handleClick = () =>{
        setLoading(true);

        if(role && role === "ROOT"){
            history.push({pathname: "/root-profile"});
            setLoading(false);
            return;
        }

        else if(role && role === "OPERATOR"){
            history.push({pathname: "/operator-role"});
            setLoading(false);
            return;
        }

        else{
            loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
                if(loadedInfo.visitorLoans){
                    history.push({pathname: "/credit/handle_credit"});
                    setLoading(false);
                }

                else{
                    history.push({pathname: "/account/create_credit"});
                    setLoading(false);
                }  
            });
        }
    };

    const clickBack = () =>{
        // history.push({pathname: "/account/handle_account"})
        history.push({pathname: "/info/bank-account"})
    }

    return (
        <Box
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          height: "100%",
          textAlign: "center"
        }}
        >
            {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
               profile
            }
            <Dialog open={changeBox} onClose={closeChangeBox} 
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "100%", sm: "80%", md: "50%"},
                            },
                        },
                    }}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            {isCompany===true &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="name"
                                    name="name"
                                    label={<Typography variant="body2">Razón social</Typography>}
                                    value={name}
                                    onChange={(e)=> changeName(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            }
                            {isCompany === false && 
                            <>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="name"
                                    name="name"
                                    label={<Typography variant="body2">Nombres</Typography>}
                                    value={name}
                                    onChange={(e)=> changeName(e)}
                                    autoComplete="off"
                                />
                            </Grid> 
                            <Grid item xs={6} sm={6} md={6}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="lastName"
                                    name="lastName"
                                    label={<Typography variant="body2">Apellidos</Typography>}
                                    value={lastName}
                                    onChange={(e)=> changeLastName(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            </>
                            }
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="idNumber"
                                    name="idNumber"
                                    label={<Typography variant="body2">Identificación</Typography>}
                                    value={idNumber}
                                    onChange={(e)=> changeIdNumber(e)}
                                    autoComplete="off"
                                />
                            </Grid> 
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="phone"
                                    name="phone"
                                    label={<Typography variant="body2">Celular</Typography>}
                                    value={phone}
                                    onChange={(e)=> changePhone(e)}
                                    autoComplete="off"
                                />
                            </Grid> 
                            {isCompany === true &&
                            <>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="repName"
                                    name="repName"
                                    label={<Typography variant="body2">Nombre representante</Typography>}
                                    value={repName}
                                    onChange={(e)=> changeRepName(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="repLastName"
                                    name="repLastName"
                                    label={<Typography variant="body2">Apellidos representante</Typography>}
                                    value={repLastName}
                                    onChange={(e)=> changeRepLastName(e)}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="standard"
                                    fullWidth={true}
                                    id="repIdNumber"
                                    name="repIdNumber"
                                    label={<Typography variant="body2">Identificacíon representante</Typography>}
                                    value={repIdNumber}
                                    onChange={(e)=> changeRepIdNumber(e)}
                                    autoComplete="off"
                                />
                            </Grid>  
                            </>
                            } 
                        </Grid>            
                    </DialogContent>
                    <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                        <Button onClick={closeChangeBox} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Back
                        </Button>
                        <Button onClick={toChangeData} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            Change
                        </Button>
                    </DialogActions>
            </Dialog>
            
        </Box>
    )   
}
