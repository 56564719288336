import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Colors from "../../components/ui/Colors";
import { FaFilter } from "react-icons/fa";
import { IconContext } from "react-icons";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'><rect fill='#330055' width='100' height='1000'/><g fill-opacity='1'><circle  fill='#330055' cx='50' cy='0' r='50'/><g fill='#3a015d' ><circle cx='0' cy='50' r='50'/><circle cx='100' cy='50' r='50'/></g><circle  fill='#410165' cx='50' cy='100' r='50'/><g fill='#48026e' ><circle cx='0' cy='150' r='50'/><circle cx='100' cy='150' r='50'/></g><circle  fill='#500376' cx='50' cy='200' r='50'/><g fill='#57047e' ><circle cx='0' cy='250' r='50'/><circle cx='100' cy='250' r='50'/></g><circle  fill='#5f0587' cx='50' cy='300' r='50'/><g fill='#67068f' ><circle cx='0' cy='350' r='50'/><circle cx='100' cy='350' r='50'/></g><circle  fill='#6f0798' cx='50' cy='400' r='50'/><g fill='#7707a0' ><circle cx='0' cy='450' r='50'/><circle cx='100' cy='450' r='50'/></g><circle  fill='#8008a9' cx='50' cy='500' r='50'/><g fill='#8909b1' ><circle cx='0' cy='550' r='50'/><circle cx='100' cy='550' r='50'/></g><circle  fill='#9109ba' cx='50' cy='600' r='50'/><g fill='#9a09c3' ><circle cx='0' cy='650' r='50'/><circle cx='100' cy='650' r='50'/></g><circle  fill='#a309cb' cx='50' cy='700' r='50'/><g fill='#ad09d4' ><circle cx='0' cy='750' r='50'/><circle cx='100' cy='750' r='50'/></g><circle  fill='#b608dc' cx='50' cy='800' r='50'/><g fill='#c007e5' ><circle cx='0' cy='850' r='50'/><circle cx='100' cy='850' r='50'/></g><circle  fill='#c905ee' cx='50' cy='900' r='50'/><g fill='#d303f6' ><circle cx='0' cy='950' r='50'/><circle cx='100' cy='950' r='50'/></g><circle  fill='#D0F' cx='50' cy='1000' r='50'/></g></svg>`;

const useStyles = makeStyles({
  svgBackground: {
    background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
  },
});

const dateOptions = {
  // weekday: "short",
  day: "numeric",
  month: "short",
  year: "numeric",
};

const dealsTypes = [
  {
      label: "Todas las oportunidades",
      type: "noFilter"
  },
  {
      label: "Cliente fiducia a fondo",
      type: "PRF"
  },
  {
      label: "Adquirente a fondo",
      type: "ADF"
  },
  {
      label: "Cliente VIP a fondo",
      type: "VFO"
  },
  {
      label: "Cliente interesante para fiducia",
      type: "IPR"
  },
  {
      label: "Cliente VIP a Promotor",
      type: "VPR"
  },
  {
      label: "Promotor a promotor",
      type: "PRP"
  },
  {
      label: "Cliente fondo a fondo",
      type: "FOF"
  },
];

let notStartedDeals;
let automaticDeals;
let clientResponseDeals;
let assignedDeals;
let ongoingDeals;
let updatedDealsList;

export default function Oportunities(props) {

    const {loginUser, updateDealsList} = props

    let history = useHistory();

    const classes = useStyles();

    React.useEffect(() => {
      dealsUpdate();
    }, []);

    const dealsUpdate = async () => {
      const updateResult = await updateDealsList(loginUser.jwtToken);

      if(updateResult !== "Not found"){
        updatedDealsList = updateResult;
        dealsPerStatus();
      }
    };

    const dealsPerStatus = () => {
      if(updatedDealsList){

        notStartedDeals = updatedDealsList.filter((dealEl) => dealEl.state === "notStarted");
        if(notStartedDeals){
          renderDealsNotStarted();
        }
        
        automaticDeals = updatedDealsList.filter((dealEl) => dealEl.state === "automatic");
        if(automaticDeals){
          renderDealsAutomatic();
        }
        
        clientResponseDeals = updatedDealsList.filter((dealEl) => dealEl.state === "clientResponse");
        if(clientResponseDeals){
          renderDealsClientResponse();
        }

        assignedDeals = updatedDealsList.filter((dealEl) => dealEl.state === "assigned");
        if(assignedDeals){
          renderDealsAssignedDeals();
        }

        ongoingDeals = updatedDealsList.filter((dealEl) => dealEl.state === "ongoing");

        
      }
    };

    const isDealPastdue = (maxTime, creationDate) => {

      const maxDate = new Date(creationDate);
      maxDate.setDate(maxDate.getDate() + maxTime);

      const daysDifference = Math.round(Math.abs((maxDate - new Date()) / (1000 * 60 * 60 * 24)));
      console.log(daysDifference);

      if(maxDate < new Date()){
        return <span style={{color: "red"}}>{`Vencida: ${daysDifference} días`}</span>
      }

      return <span style={{color: Colors.primary}}>{`Vence en ${daysDifference} días`}</span>

    };

    const handleFilterClick = (event) => {
      console.log(event)
      setAnchorEl(event.currentTarget);
    }

    const filterDeals = (byType) => {

      handleClose();
      console.log(byType);

      if(byType==="noFilter"){
        dealsPerStatus();
        return;
      };

      dealsPerStatus();

      if(notStartedDeals){
        notStartedDeals = notStartedDeals.filter((el) => el.type===byType);
        console.log(notStartedDeals);
        renderDealsNotStarted();
      }
      
      if(automaticDeals){
        automaticDeals = automaticDeals.filter((el) => el.type===byType);
        console.log(automaticDeals);
        renderDealsAutomatic();
      }
      
      if(clientResponseDeals){
        clientResponseDeals = clientResponseDeals.filter((el) => el.type===byType);
        console.log(clientResponseDeals);
        renderDealsClientResponse();
      }

      if(assignedDeals){
        assignedDeals = assignedDeals.filter((el) => el.type===byType);
        console.log(assignedDeals);
        renderDealsAssignedDeals();
      }

    };

    const [dealsNotStarted, setDealsNotStarted] = React.useState("");
    const [dealsAutomatic, setDealsAutomatic] = React.useState("");
    const [dealsClientResponse, setDealsClientResponse] = React.useState("");
    const [dealsAssigned, setDealsAssigned] = React.useState("");

    const renderDealsNotStarted = () => {
          setDealsNotStarted(
            <Grid container direction="column" sx={{justifyContent: {xs: "center", sm: "start"}}}> 
              {notStartedDeals.map((deal) => {
                  return(
                      <Grid key={deal.name} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", maxWidth:{xs: "300px"}, marginBottom: "0.5rem"}}>
                        <Card key={deal.name}>
                          <Grid container direction="column" key={deal.name}>
                              <Grid item xs={12} sm={12} md={12}>
                                <CardActions onClick={() => dealsDetail(deal)} sx={{cursor: "pointer"}}>
                                    <Grid container>
                                      <Grid item xs={3} sm={3} md={3}>
                                        <Box
                                           sx={{
                                                ...((deal.type === "PRF" || deal.type === "ADF" || deal.type === "VFO" || deal.type === "FOF") && {
                                                    backgroundColor: Colors.primary,
                                                    color: "white",
                                                }),
                                                ...((deal.type === "IPR" || deal.type === "VPR" || deal.type === "PRP") && {
                                                    backgroundColor: Colors.secondary,
                                                    color: "white",
                                                }),
                                                borderRadius: "25%",
                                                width: "3rem",
                                                height: "3rem",
                                                textAlign: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: "0.5rem",
                                            
                                            }} 
                                        >
                                            {deal.type  }
                                        </Box>
                                      </Grid>
                                      <Grid item xs={9} sm={9} md={9} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                        <Grid container>
                                          <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body1" sx={{color: "black", fontSize: "0.8rem"}}>
                                              {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(deal.date))}`}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "end"}}>
                                            <Typography sx={{fontSize: "0.8rem"}}>
                                              {isDealPastdue(deal.maxTime, deal.date)}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12}>  
                                        <Grid container direction="row" sx={{}} >
                                          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                            <Typography variant="body1" sx={{fontWeight: "bold"}} >
                                              {`${deal.clientName}`}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                </CardActions>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0.5rem"}}>
                                  <Typography variant="body2" sx={{}}>
                                      {`$ ${new Intl.NumberFormat('es-CO').format(deal.value)}`}
                                  </Typography>
                              </Grid> 
                          </Grid>
                        </Card> 
                      </Grid>
                  )
    
              })}
            </Grid>
          )
    };

    const renderDealsAutomatic = () => {
      setDealsAutomatic(
        <Grid container direction="column" sx={{justifyContent: {xs: "center", sm: "start"}}}> 
          {automaticDeals.map((deal) => {
              return(
                  <Grid key={deal.name} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", maxWidth:{xs: "300px"}, marginBottom: "0.5rem"}}>
                    <Card key={deal.name}>
                      <Grid container direction="column" key={deal.name}>
                          <Grid item xs={12} sm={12} md={12}>
                            <CardActions onClick={() => dealsDetail(deal)} sx={{cursor: "pointer"}}>
                                <Grid container>
                                  <Grid item xs={3} sm={3} md={3}>
                                    <Box
                                        sx={{
                                            ...((deal.type === "PRF" || deal.type === "ADF" || deal.type === "VFO" || deal.type === "FOF") && {
                                                backgroundColor: Colors.primary,
                                                color: "white",
                                            }),
                                            ...((deal.type === "IPR" || deal.type === "VPR" || deal.type === "PRP") && {
                                                backgroundColor: Colors.secondary,
                                                color: "white",
                                            }),
                                            borderRadius: "25%",
                                            width: "3rem",
                                            height: "3rem",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "0.5rem",
                                        
                                        }} 
                                    >
                                        {deal.type  }
                                    </Box>
                                  </Grid>
                                  <Grid item xs={9} sm={9} md={9} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                    <Grid container>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="body1" sx={{color: "black", fontSize: "0.8rem"}}>
                                          {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(deal.date))}`}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "end"}}>
                                        <Typography sx={{fontSize: "0.8rem"}}>
                                          {isDealPastdue(deal.maxTime, deal.date)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12}>  
                                    <Grid container direction="row" sx={{}} >
                                      <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body1" sx={{fontWeight: "bold"}} >
                                          {`${deal.clientName}`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                            </CardActions>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0.5rem"}}>
                              <Typography variant="body2" sx={{}}>
                                  {`$ ${new Intl.NumberFormat('es-CO').format(deal.value)}`}
                              </Typography>
                          </Grid> 
                      </Grid>
                    </Card> 
                  </Grid>
              )

          })}
        </Grid>
      )
    };

    const renderDealsClientResponse = () => {
      setDealsClientResponse(
        <Grid container direction="column" sx={{justifyContent: {xs: "center", sm: "start"}}}> 
          {clientResponseDeals.map((deal) => {
              return(
                  <Grid key={deal.name} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", maxWidth:{xs: "300px"}, marginBottom: "0.5rem"}}>
                    <Card key={deal.name}>
                      <Grid container direction="column" key={deal.name}>
                          <Grid item xs={12} sm={12} md={12}>
                            <CardActions onClick={() => dealsDetail(deal)} sx={{cursor: "pointer"}}>
                                <Grid container>
                                  <Grid item xs={3} sm={3} md={3}>
                                    <Box
                                        sx={{
                                            ...((deal.type === "PRF" || deal.type === "ADF" || deal.type === "VFO" || deal.type === "FOF") && {
                                                backgroundColor: Colors.primary,
                                                color: "white",
                                            }),
                                            ...((deal.type === "IPR" || deal.type === "VPR" || deal.type === "PRP") && {
                                                backgroundColor: Colors.secondary,
                                                color: "white",
                                            }),
                                            borderRadius: "25%",
                                            width: "3rem",
                                            height: "3rem",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "0.5rem",
                                        
                                        }} 
                                    >
                                        {deal.type  }
                                    </Box>
                                  </Grid>
                                  <Grid item xs={9} sm={9} md={9} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                    <Grid container>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="body1" sx={{color: "black", fontSize: "0.8rem"}}>
                                          {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(deal.date))}`}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "end"}}>
                                        <Typography sx={{fontSize: "0.8rem"}}>
                                          {isDealPastdue(deal.maxTime, deal.date)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12}>  
                                    <Grid container direction="row" sx={{}} >
                                      <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body1" sx={{fontWeight: "bold"}} >
                                          {`${deal.clientName}`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                            </CardActions>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0.5rem"}}>
                              <Typography variant="body2" sx={{}}>
                                  {`$ ${new Intl.NumberFormat('es-CO').format(deal.value)}`}
                              </Typography>
                          </Grid> 
                      </Grid>
                    </Card> 
                  </Grid>
              )

          })}
        </Grid>
      )
    };

    const renderDealsAssignedDeals = () => {
      setDealsAssigned(
        <Grid container direction="column" sx={{justifyContent: {xs: "center", sm: "start"}}}> 
          {assignedDeals.map((deal) => {
              return(
                <Grid key={deal.name} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", maxWidth:{xs: "300px"}, marginBottom: "0.5rem"}}>
                    <Card key={deal.name}>
                      <Grid container direction="column" key={deal.name}>
                          <Grid item xs={12} sm={12} md={12}>
                            <CardActions onClick={() => dealsDetail(deal)} sx={{cursor: "pointer"}}>
                                <Grid container>
                                  <Grid item xs={3} sm={3} md={3}>
                                    <Box
                                        sx={{
                                            ...((deal.type === "PRF" || deal.type === "ADF" || deal.type === "VFO" || deal.type === "FOF") && {
                                                backgroundColor: Colors.primary,
                                                color: "white",
                                            }),
                                            ...((deal.type === "IPR" || deal.type === "VPR" || deal.type === "PRP") && {
                                                backgroundColor: Colors.secondary,
                                                color: "white",
                                            }),
                                            borderRadius: "25%",
                                            width: "3rem",
                                            height: "3rem",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "0.5rem",
                                        
                                        }} 
                                    >
                                        {deal.type  }
                                    </Box>
                                  </Grid>
                                  <Grid item xs={9} sm={9} md={9} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                    <Grid container>
                                      <Grid item xs={12} sm={12} md={12}>
                                        <Typography variant="body1" sx={{color: "black", fontSize: "0.8rem"}}>
                                          {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(deal.date))}`}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "end"}}>
                                        <Typography sx={{fontSize: "0.8rem"}}>
                                          {isDealPastdue(deal.maxTime, deal.date)}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12}>  
                                    <Grid container direction="row" sx={{}} >
                                      <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="body1" sx={{fontWeight: "bold"}} >
                                          {`${deal.clientName}`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                            </CardActions>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "0.5rem"}}>
                              <Typography variant="body2" sx={{}}>
                                  {`$ ${new Intl.NumberFormat('es-CO').format(deal.value)}`}
                              </Typography>
                          </Grid> 
                      </Grid>
                    </Card> 
                  </Grid>
              )

          })}
        </Grid>
      )
    };


    const dealsDetail = (dealClicked) => {
      console.log(dealClicked);

      history.push({
        pathname: "/commercial/dealdetail",
        state: {
          deal: dealClicked,
        }
      });
    };

    const goToNewDeal = () => {
      history.push({
        pathname: "/commercial/createDeals"
      });
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box className={classes.svgBackground} sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%",}}>
        <Box
            sx={{
            display: "flex",
            flexGrow: "1",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            // backgroundColor: "#ededed",
            }}
        >
        <Grid container>
          <Grid item xs={11} sm={11} md={11} sx={{paddingLeft: "3.5rem"}}>
            <Grid container sx={{width: "95%", height: "95%"}}>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Grid container direction="column" sx={{justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                      <Grid container direction="row">
                          <Grid item xs={10} sm={10} md={10} sx={{display: "flex", justifyContent: "center"}}>
                            <Typography variant="h6" style={{ marginBottom: "1.5rem", marginTop: "1.5rem", paddingRight: "1rem", color: "white"}}> Creadas</Typography>
                          </Grid>
                          <Grid item xs={2} sm={2} md={2} sx={{display: "flex", alignItems: "center", marginTop: "0.5rem"}}>
                            <Box onClick={goToNewDeal} sx={{
                              backgroundColor: Colors.primary, 
                              color: "white",
                              borderRadius: "50%",
                              width: "1rem",
                              height: "1rem",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "0.5rem",
                              cursor: "pointer",
                              }}
                            >
                              +
                            </Box>
                          </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{width: "80%"}}>
                      {dealsNotStarted}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Grid container direction="column" sx={{justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                      <Typography variant="h6" style={{ marginBottom: "1.5rem", marginTop: "1.5rem", color: "white"}}> Automáticas</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{width: "80%"}}>
                      {dealsAutomatic}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Grid container direction="column" sx={{justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                      <Typography variant="h6" style={{marginBottom: "1.5rem", marginTop: "1.5rem", color: "white"}}> Respondidas </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{width: "80%"}}>
                      {dealsClientResponse}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <Grid container direction="column" sx={{justifyContent: "center", alignItems: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                      <Typography variant="h6" style={{ marginBottom: "1.5rem", marginTop: "1.5rem", color: "white"}}> Asignadas </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{width: "80%"}}>
                      {dealsAssigned}
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} sx={{}}>
            <IconContext.Provider
              value={{ color: "white", size: '1.6rem', }}
            >
              <Box onClick={handleFilterClick} sx={{marginTop: "2rem", marginRight: "3.8rem", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <FaFilter />
              </Box>
            </IconContext.Provider>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                   {dealsTypes.map((typeEl) => (
                        <MenuItem onClick={() => filterDeals(typeEl.type)} key={typeEl.type} value={typeEl.type}>
                            {typeEl.label}
                        </MenuItem>
                    ))}
              </Menu>
          </Grid>
        </Grid>    
        </Box>
      </Box>
    )
}
