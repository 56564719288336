import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AssetSelect from "../../components/AssetSelect";
import swal from "sweetalert";
// import { RingLoader } from "react-spinners";
import { PuffLoader } from "react-spinners"
import { css } from "@emotion/react";
import { xrpPayment, xrplOrder, currenciesOrder, placeOrder, prepareStatement } from "../../utilityFunctions/Paymentutil";
import { fetchAssetRates, xrplSecretFetch, loadXrpAddressCreds, fetchXrpl } from "../../utilityFunctions/FetchUtil";
import Content from "../../layout/Content";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import StepProgress from "../../components/ui/StepProgress";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import {mexicoWithdraw} from '../../utilityFunctions/Exchanges';
import Colors from "../../components/ui/Colors"
import operationUnsuccesful from "../../Texts/OperationResult.js";
import Autocomplete from '@mui/material/Autocomplete';
import NumberFormat from 'react-number-format';
import { determineTypeOfAsset, determineAsset } from "../../utilityFunctions/Util";
import {awardsExplanation} from "../../Texts/Explanations.js";
import Box from '@mui/material/Box';

const steps = [
  {
    key: "value",
    name: "CANTIDAD",
    value: "",
  },
  {
    key: "destination",
    name: "¿A QUIÉN?",
    value: "",
  },
  {
    key: "destionationAsset",
    name: "ESCOGE EL TOKEN",
    value: "",
  }
]

const useStyles = makeStyles((theme) => ({
  root: {
      maxWidth: 400,
      background: 'white',
      alignSelf: 'center'
    },
  button: {
      marginTop: "1.5rem",
      marginLeft: "0.5rem",
  },
  autocomplete: {
      width: "15em",
  },
  input: {
    width: "50%",
  },

}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const mexicoWithdrawObject={
  accountId:"",
  clabe:"",
  amount:""
}

// const exchangeOrder={
//   currencie: "",
//   amount: "",
//   instruction: ""
// }

const payment = {
  source: {
    address: "",
    maxAmount: {
      value: "",
      currency: "",
      counterparty: "",
    },
  },
  destination: {
    address: "",
    amount: {
      value: "",
      currency: "",
      counterparty: "",
    },
  },
  //TODO: include an automatic path or an input path
  //"paths": '[[{"currency":"COP","issuer":"rJAf4zqDKXGchRw3yG7f8rNh4GMEEvSMQf"},{"account":"rJAf4zqDKXGchRw3yG7f8rNh4GMEEvSMQf"}]]'
};

const cashBackPayment = {
  source: {
    address: "",
    maxAmount: {
      value: "",
      currency: "",
      counterparty: "",
    },
  },
  destination: {
    address: "",
    amount: {
      value: "",
      currency: "",
      counterparty: "",
    },
  },
};




const txInput = {
  senderAccount: "",
  paymentValue: "",
  receiverAccount: "",
  senderKey: "",
};

let WUPOILPACCOUNT = ""; 
let WUPOXRPLACCOUNT = "";
let WUPOXRPLSECRETFINAL = "";
let wupoXrpSecret = "";

let clientXrplToken = "";

let clientXrplAddress = "";

//Wupo's spread
const wupoSpread = ""; //TODO: must be an input from the profit input and must be aligned to Transfer Rate

const orderObject={
  amountOrdered: 0,
  instruction: "buy", 
  stockSymbolOrdered:"",
  stockXrplBalance:0
}

// Global variables to determine type of asset and derived processes

let stockPaymentSender = false; //review if this variable is not changing between iterations
let stockPaymentReceiver = false; //review if this variable is not changing between iterations

let currencyPaymentSender = false;
let currencyPaymentReceiver = false;

let tayloredTokenSender = false;
let tayloredTokenReceiver = false;


const FETCHTYPE="BalanceSheet";

const senderStatement = {
  accountId: "",
  account: "",
  asset: "",
  value: "",
  transaction: "", 
  description: "",
  counterpartyAccount: "",
}

const receiverStatement = {
  accountId: "",
  account: "",
  asset: "",
  value: "",
  transaction: "", 
  description: "",
  counterpartyAccount: "",
}

let cashBackAmount = 0;

  // const senderStock=useRef();
  const assetsToFetch = {
    fromAsset: "",
    toAsset: "",
  };

export default function LoggedTransfer(props) {
  const history = useHistory();
  const { ledgerAccounts, user, loginUser, retailerTokens, 
    exchangeCurrencies, wupoServerUri, stockOrderSymbol, 
    bridgeAsset, wupoAvailableAssets } = props;
  const classes = useStyles();
  const assetFromHandler = props.match?.params?.asset;
  const stockAssetFromHandleAccount = ledgerAccounts
    .filter((account) => account.account === user.account)
    ?.find((account) => account.asset === assetFromHandler)?.stockAsset;

  const [stepNumber, setStepNumber] = useState(0);
  const [inputType, setInputType] = useState("number");
  // const [inputText, setInputText] = useState("");

  const [inputFromChild, setInputFromChild] = useState();
  const [eraseLabel, setEraseLabel] = useState(false);

  const [loading, setLoading] = useState(false)

  // Input and label variables
  // const labelsArray = useRef(["VALOR", "DESTINO", "ACTIVO DESTINO"]);
  // let labelIndex = useRef(0);

  let fetchConcluded = false;
  const [cardRender, setCardRender] = useState();
  const [costRender, setCostRender] = useState();

  useEffect(() => {
    // console.log(user)

    assetsToFetch.fromAsset = stockAssetFromHandleAccount;

    stockPaymentSender = false; //review if this variable is not changing between iperation
    stockPaymentReceiver = false;

    currencyPaymentSender = false;
    currencyPaymentReceiver = false;

    tayloredTokenSender = false;
    tayloredTokenReceiver = false;

 
    // Variables assignments in mount:
    txInput.senderAccount = user.account + assetFromHandler;
    // xrplSecretFetch(user.accountIdToken, loginUser.jwtToken)
    // .then((secret) => {
    //   txInput.senderKey = secret;
    //   console.log("client xrpl secret: " + clientXrplToken);
    // });
    // txInput.senderKey = user.accountIdToken;
    loadXrpAddressCreds(loginUser.jwtToken)
    .then((data) => {
      WUPOILPACCOUNT = data.wupoAccount;
      WUPOXRPLACCOUNT = data.xrpAddress;
      WUPOXRPLSECRETFINAL = data.xrpAddressSecret;
      wupoXrpSecret = data.xrpAddressSecret;
      // console.log("wupo xrpl secret: " + wupoXrpSecret);
    });
    // console.log(user.xrplAddressSecret)
    xrplSecretFetch(user.xrplAddressSecret, loginUser.jwtToken)
    .then((secret) => {
      clientXrplToken = secret;
      // console.log("client xrpl secret: " + clientXrplToken);
    });
    payment.source.maxAmount.currency=assetFromHandler;
    // console.log("source currency: " + payment.source.maxAmount.currency); // DEBUG PRINTING

    determineTypeOfAssetSender().then(() =>{
      console.log(stockPaymentSender)
      console.log(currencyPaymentSender)
      console.log(tayloredTokenSender)
    });

    

    swal({
      title: "¿QUÉ HAGO?",
      text: awardsExplanation,
      icon: "success",
      button: "Entiendo",
    })
    
  }, []);

  useEffect(() => {
    setEraseLabel(false);
  }, [eraseLabel]);

  useEffect(() =>{
    if(steps[stepNumber].key === "value") {
      setInputType("number")
    } else {
      setInputType("text")
    } 
  }, [stepNumber]);

  const [usersList, setUsersList] = useState(
    {
    options: ledgerAccounts,
    getOptionLabel: (option) => option.account,
    }
  );
  
  useEffect(() => {
    
    if (fetchConcluded === false) {
      if(stepNumber === 1){
        setCardRender(
          <Autocomplete className={classes.autocomplete}
            {...usersList}
            id="username"
            clearOnEscape
            autoComplete = {true}
            noOptionsText = {"Usuario no existe"}
            // sx={{ width: 600 }}
            onChange={(event, value) => setInputFromChild(value.account)}
            onInputChange={(e) => setInputFromChild(e.target.value.account)}
            renderInput={(params) => (
              <TextField {...params} label="¿A quién?" variant="standard" />
            )}
          />
        )
      }
      else if (stepNumber < steps.length - 1) {
        setCardRender(
         
          <NumberFormat
            variant="standard"
            style={{width: "40%"}}
            className={classes.input}
            name={"formatNumber"}
            customInput={TextField}
            prefix={`${assetFromHandler} `}
            type="text"
            label="Cantidad"
            thousandSeparator={'.'}
            decimalSeparator={','}
            autoComplete={"off"}
            // value={balanceInputValue}
            onValueChange={(values) => {
                // const {formattedValue, value, floatValue} = values;
                setInputFromChild(values.floatValue);
            }}
          />  
        );
      } else {
        setCardRender(
          <AssetSelect
            onChangeInfo={(e) => setInputFromChild(e.target.value)}
          />
        );
      }
    }
  }, [fetchConcluded, stepNumber, eraseLabel, inputType]);

  const handleInput = async () => {
    let reviewStock = false;
    let isRetailerToken=false;

    if (!inputFromChild) return;
    if (stepNumber < steps.length) {
      if(stepNumber === 0){
        txInput.paymentValue = inputFromChild;
        payment.source.maxAmount.value = String(inputFromChild); //DEBUGING 01012022
        setStepNumber((prevStepNumber) => prevStepNumber + 1);
        setEraseLabel(true);
        setInputFromChild("");
      } 
      else if(stepNumber === 1){
        txInput.receiverAccount = inputFromChild;
        mexicoWithdrawObject.accountId=inputFromChild;
        receiverStatement.account = inputFromChild;
        senderStatement.description = inputFromChild;
        setStepNumber((prevStepNumber) => prevStepNumber + 1);
        setEraseLabel(true);
        setInputFromChild("");
      }   
      else if(stepNumber === 2){
        
        setStepNumber(0);
        setEraseLabel(true);
        
        // console.log(txInput); // DEBUG PRINTING
        //Call functions for ilp payment and xrpl payment (the xrpl payment is a balance adjustment of a trusline and it's limit)
        fetchConcluded = true;
        setLoading(true);

        //set payment values for the xrpPayment

        ledgerAccounts &&
          ledgerAccounts.forEach((element) => {
            if (element.accountId === txInput.receiverAccount) {
              payment.destination.address = element.xrplAddress;
              cashBackAmount = element.retailCashBack;
            }
          });

          //TODO simplifying the iterations to ledger table
          payment.source.maxAmount.counterparty = WUPOXRPLACCOUNT;
          payment.destination.amount.counterparty = WUPOXRPLACCOUNT;
          
          clientXrplAddress = user.xrplAddress;
          payment.source.address = user.xrplAddress;

          //DEFINITION OF GLOBAL VARIABLES: sender asset

          await determineTypeOfAssetReceiver(inputFromChild).then(() => {});
          

        // renderCost();

        // console.log(`stockPaymentReceiver: ${stockPaymentReceiver} stockPaymentSender: ${stockPaymentSender}`) // DEBUG PRINTING
        // console.log(isRetailerToken)
        console.log(stockPaymentReceiver)
        console.log(stockPaymentSender)
        console.log(tayloredTokenSender)
        console.log(tayloredTokenReceiver)
        console.log(currencyPaymentSender)
        console.log(currencyPaymentReceiver)

        if(tayloredTokenSender || tayloredTokenReceiver){
          gratitudMessage();
          return;
        }

        else if (stockPaymentReceiver || stockPaymentSender || tayloredTokenSender || tayloredTokenReceiver) {
          try{
            stockAssetPayment();
          }catch(error){
            console.log(error);
            swal({
              title: "NO PUDIMOS GENERAR TU PREMIO",
              text: `\n Por favor vuelve a intentarlo`,
              icon: "error",
              button: "Entiendo",
            }).then(() => {
              history.push(
                `/account/handle_account`
              );
            });
          }
        } else {
          try{
            postPayment();
          } catch(error){
            console.log(error);
            swal({
              title: "NO PUDIMOS GENERAR TU PREMIO",
              text: `\n Por favor vuelve a intentarlo`,
              icon: "error",
              button: "Entiendo",
            }).then(() => {
              history.push(
                `/account/handle_account`
              );
            });
          }
        }
      }
    } 
  }

  const gratitudMessage = () => {
    console.log("Gratitud message");
    setGratitudeBox(true);
    setLoading(false);
  };

  //TODO: Under construction
  const determineTypeOfAssetSender = async () => {

    //Sender asset validation
    determineTypeOfAsset(wupoAvailableAssets, assetFromHandler).then((assetType) => {
      if(assetType === "currency") currencyPaymentSender = true;
      else if(assetType === "stock") stockPaymentSender = true;
      else if(assetType === "tayloredToken") tayloredTokenSender = true;

      return new Promise((resolve)=>{
        resolve (assetType)
      })

    });

  }

  const determineTypeOfAssetReceiver = async (receiverAssetToSearch) => {

    //Receiver asset validation
    determineTypeOfAsset(wupoAvailableAssets, receiverAssetToSearch).then((assetType) => {
      if(assetType === "currency") currencyPaymentReceiver = true;
      else if(assetType === "stock") stockPaymentReceiver = true;
      else if(assetType === "tayloredToken") tayloredTokenReceiver = true;

      return new Promise((resolve)=>{
        resolve (assetType)
      })

    });

  }

  useEffect(() => {
    
    if (stepNumber === 2) {
      
      if (inputFromChild==="") return;

        determineAsset(wupoAvailableAssets, inputFromChild).then((assetFound) => {
          
          if(!ledgerAccounts.some((account) => account.accountId === (txInput.receiverAccount + assetFound.xrplName))){
            swal({
              title: "LA CUENTA NO EXISTE",
              text: `\n La cuenta de destino que ingresaste no existe o no tiene asociado el token que seleccionaste
              
              Por favor vuelve a ingresar la cuenta de destino`,
              icon: "error",
              button: "Entiendo",
            }).then(() => {
              setStepNumber(1);
            });
          }

          else{
            setLoading(true);

            //DEFINITION OF GLOBAL VARIABLES: Receiver Asset
            assetsToFetch.fromAsset = user.stockAsset; 
            console.log(assetsToFetch.fromAsset)
            assetsToFetch.toAsset = assetFound.quote; 
            console.log(assetsToFetch)
            payment.destination.amount.currency = assetFound.xrplName; 
            console.log(payment.destination.amount.currency)
            txInput.receiverAccount += assetFound.xrplName; //Replacing is retail from handleInput 
            console.log(txInput.receiverAccount)

            fetchAssetRates(assetsToFetch, loginUser.jwtToken).then((rate) => {
              
              setCostRender(
                <div style={{textAlign: "center"}}>
                  <p style={{color: "black"}}>
                    {`Relación entre tokens: ${rate.rate + wupoSpread}`}
                  </p>
                  <p style={{color: "black"}}>
                    Reciben:{" "}
                    {(
                      new Intl.NumberFormat('es-CO').format(Math.round(payment.source.maxAmount.value *
                        (rate.rate + wupoSpread) * 10000) / 10000
                      ))}
                  </p>
                </div>
              );

              setLoading(false);
            });

          }

        })
    }

  }, [stepNumber, inputFromChild]);

  //Post the payemnt
  async function postPayment() {
    // payment.source.maxAmount.currency=assetFromHandler

    // console.log("Tx JSON: " + JSON.stringify(txInput)); // DEBUG PRINTING
    fetch(`${wupoServerUri.devnet}/api/secure/payment`, {
      method: "POST",
      body: JSON.stringify(txInput),
      headers: {
        "Authorization": `Bearer ${loginUser.jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (body) {
        // console.log("Payment response: " + JSON.stringify(body)); // DEBUG PRINTING

        fetchConcluded = true;

        if (
          body.receiverAmount !== undefined &&
          body.wupoAmount !== undefined
        ) {
          // console.log("response not undefined");
          if (
            payment.source.maxAmount.currency !==
            payment.destination.amount.currency
          ) {
            // console.log(
            //   "asset to fetch payment" + JSON.stringify(assetsToFetch)
            // ); // DEBUG PRINTING
            fetchAssetRates(assetsToFetch, loginUser.jwtToken).then((rate) => {
              const sellValue=Math.round(((payment.source.maxAmount.value*1)+Number.EPSILON)*10000)/10000;
              const buyValue=Math.round(((sellValue*rate.rate)+Number.EPSILON)*10000)/10000;
              // console.log(rate.rate)
              payment.destination.amount.value=String(buyValue);
              mexicoWithdrawObject.amount=buyValue;

              xrplOrder(
                WUPOXRPLACCOUNT,
                wupoXrpSecret,
                payment.destination.amount.currency,
                assetFromHandler,
                String(buyValue),
                String(sellValue)
              )
                .then(() => {
                  xrpPayment(
                    payment,
                    String(payment.source.maxAmount.value),
                    payment.destination.address,
                    clientXrplToken
                  )
                    .then(() => {
                      // xrpPayment(payment.current, String(body.wupoAmount), WUPOXRPLACCOUNT, clientXrplToken)
                      // .then(paymentResult=>{
                      // console.log("payment result: "+ paymentResult)
                      if(bankTransferConfirm===true){
                        // console.log("the objecto for International withdraw: " + JSON.stringify(mexicoWithdrawObject)) // DEBUG PRINTING
                        mexicoWithdraw(mexicoWithdrawObject, loginUser.jwtToken)
                      }

                      // exchangeCurrencies.forEach(element=>{

                        // if(element.currency === payment.destination.amount.currency){
                        if(currencyPaymentReceiver){

                          const exchangeOrder={
                            currencie: "",
                            amount: "",
                            instruction: ""
                          };

                          exchangeOrder.instruction = "sell"; // sell given that it sells the bridge asset to receive the requested asset  
                          // exchangeOrder.currencie=element.exchangeCurrency;   
                          exchangeOrder.currencie=assetsToFetch.toAsset; //Under construction                                         
                                                              
                          const currenciesToFetch={
                              fromAsset: payment.destination.amount.currency,
                              toAsset: bridgeAsset
                          }  
                          fetchAssetRates(currenciesToFetch, loginUser.jwtToken)
                           .then(result=>{
                              
                              exchangeOrder.amount=String(Math.round(((payment.destination.amount.value*result.rate)+Number.EPSILON)*10)/10);
                              // String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000);
                              currenciesOrder(exchangeOrder, loginUser.jwtToken);
                           })
                        } 
                        
                        // if(element.currency === payment.source.maxAmount.currency){
                        if(currencyPaymentSender){    
                            const exchangeOrder={
                              currencie: "",
                              amount: "",
                              instruction: ""
                            };

                            exchangeOrder.instruction = "buy"; // buy given that it buys the requested currency for the the bridge asset
                            // exchangeOrder.currencie=element.exchangeCurrency;
                            exchangeOrder.currencie=assetsToFetch.fromAsset;
                                                      
                            const currenciesToFetch={
                                fromAsset: payment.source.maxAmount.currency,
                                toAsset: bridgeAsset
                            }  
                            fetchAssetRates(currenciesToFetch, loginUser.jwtToken)
                            .then(result=>{
                                
                                exchangeOrder.amount=String(Math.round(((payment.destination.amount.value*result.rate)+Number.EPSILON)*10)/10);
                                // String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000);
                                currenciesOrder(exchangeOrder, loginUser.jwtToken);
                            })
                        }
      
                      // });

                       //Sender statement
                    senderStatement.accountId = user.accountId;
                    senderStatement.account = user.account;
                    senderStatement.asset = user.asset;
                    senderStatement.value = payment.source.maxAmount.value;
                    senderStatement.transaction = "debit";
                    // senderStatement.description = txInput.receiverAccount;
          
                    prepareStatement(senderStatement, loginUser.jwtToken).then(statementResult =>{
                      // console.log(statementResult); // DEBUG PRINTING

                      //Receiver statement
                      receiverStatement.accountId = txInput.receiverAccount;
                      // receiverStatement.account = user.account;
                      receiverStatement.asset = user.asset;
                      receiverStatement.value = payment.destination.amount.value;
                      receiverStatement.transaction = "credit";
                      receiverStatement.description = txInput.senderAccount;
            
                      prepareStatement(receiverStatement, loginUser.jwtToken).then(statementResult =>{
                        // console.log(statementResult); // DEBUG PRINTING
                      });

                    });

                    //TODO: including the cashBack payment
                    // console.log("Start the printing to test the cashback functionality")

                    cashBackPayment.source.address = WUPOXRPLACCOUNT;
                    cashBackPayment.source.maxAmount.value = String(cashBackAmount * payment.source.maxAmount.value);
                    cashBackPayment.source.maxAmount.currency =  payment.source.maxAmount.currency;
                    cashBackPayment.source.maxAmount.counterparty = WUPOXRPLACCOUNT;

                    cashBackPayment.destination.address = payment.source.address;
                    cashBackPayment.destination.amount.value = cashBackPayment.source.maxAmount.value;
                    cashBackPayment.destination.amount.currency = cashBackPayment.source.maxAmount.currency;
                    cashBackPayment.destination.amount.counterparty = WUPOXRPLACCOUNT;

                    // console.log(cashBackPayment); // DEBUG PRINTING

                    if(cashBackPayment.source.maxAmount.value > 0){
                      xrpPayment(
                        cashBackPayment,
                        String(payment.source.maxAmount.value),
                        payment.destination.address,
                        wupoXrpSecret
                      ).then(() => {

                        //sender cashback statement
                        const senderCashback = senderStatement;
                        senderCashback.transaction = "credit";
                        senderCashback.description = "WUPO";
                        senderCashback.value = cashBackPayment.source.maxAmount.value; 

                        prepareStatement(senderCashback, loginUser.jwtToken).then(statementResult =>{
                          // console.log(statementResult); // DEBUG PRINTING
                        });

                        //retailer cashback
                        const retailerCashbackPayment = cashBackPayment;
                        
                        retailerCashbackPayment.source.maxAmount.value = String(cashBackAmount * payment.destination.amount.value);
                        retailerCashbackPayment.source.maxAmount.currency = payment.destination.amount.currency;

                        retailerCashbackPayment.destination.address = payment.destination.address;
                        retailerCashbackPayment.destination.amount.value = retailerCashbackPayment.source.maxAmount.value;
                        retailerCashbackPayment.destination.amount.currency = retailerCashbackPayment.source.maxAmount.currency;
                        
                        
                        xrpPayment(
                          retailerCashbackPayment,
                          String(payment.source.maxAmount.value),
                          payment.destination.address,
                          wupoXrpSecret
                        ).then(() => {
                          //Receiver statement
                          const receiverCashback = receiverStatement;
                          receiverCashback.transaction = "credit";
                          receiverCashback.description = "WUPO";
                          receiverCashback.value = cashBackPayment.destination.amount.value;
                
                          prepareStatement(receiverCashback, loginUser.jwtToken).then(statementResult =>{
                            // console.log(statementResult); // DEBUG PRINTING
                          });
                        });
                      });
                    }

                      setCardRender(<h3>PAGO EXITOSO</h3>);
                      swal({
                        title: "PAGO EXITOSO",
                        icon: "success",
                        button: "Súper",
                      }).then(() => {
                        history.push(
                          // `/account/handle_account/choosetx/${assetFromHandler}`
                          `/account/handle_account`
                        );
                      });
                    })
                    .catch(() => {
                      swal({
                        title: "NO PUDIMOS HACER TU PAGO",
                        // text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 
                        //         Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                        //         Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                        //         Jorge: XXXXXXXXXX
                        //         Federico: XXXXXXXXXX`,
                        text: operationUnsuccesful,
                        icon: "error",
                        button: "Entiendo",
                      }).then(() => {
                        history.push(
                          `/account/handle_account`
                        );
                      });
                    });
                })
                .catch((err) => {
                  console.log(err);
                  swal({
                    title: "NO PUDIMOS HACER TU PAGO",
                    // text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 
                    //       Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                    //       Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                    //       Jorge: XXXXXXXXXX
                    //       Federico: XXXXXXXXXX`,
                    text: operationUnsuccesful,
                    icon: "error",
                    button: "Entiendo",
                  }).then(() => {
                    history.push(
                      `/account/handle_account`
                    );
                  });
                });
              // });
            });
          } else {
            payment.destination.amount.value = payment.source.maxAmount.value
            console.log(payment)
            xrpPayment(
              payment,
              String(body.receiverAmount),
              payment.destination.address,
              clientXrplToken
            )
              .then((paymentResult) => {
                    // console.log("payment result: " + paymentResult);

                    //Mexico Withdraw
                    mexicoWithdrawObject.amount=payment.destination.address;
                    if(bankTransferConfirm===true){
                        // console.log("the objecto for International withdraw: " + JSON.stringify(mexicoWithdrawObject)) // DEBUG PRINTING
                        mexicoWithdraw(mexicoWithdrawObject, loginUser.jwtToken)
                    }

                    //TODO: post statement

                    //Sender statement
                    senderStatement.accountId = user.accountId;
                    senderStatement.account = user.account;
                    senderStatement.asset = user.asset;
                    senderStatement.value = payment.source.maxAmount.value;
                    senderStatement.transaction = "debit";
                    // senderStatement.description = txInput.receiverAccount;
          
                    prepareStatement(senderStatement, loginUser.jwtToken).then(statementResult =>{
                      // console.log(statementResult); // DEBUG PRINTING

                      //Receiver statement
                      receiverStatement.accountId = txInput.receiverAccount;
                      // receiverStatement.account = user.account;
                      receiverStatement.asset = user.asset;
                      receiverStatement.value = payment.destination.amount.value;
                      receiverStatement.transaction = "credit";
                      receiverStatement.description = txInput.senderAccount;
            
                      prepareStatement(receiverStatement, loginUser.jwtToken).then(statementResult =>{
                        // console.log(statementResult); // DEBUG PRINTING
                      });

                    });

                    //TODO: including the cashBack payment
                    // console.log("Start the printing to test the cashback functionality")

                    cashBackPayment.source.address = WUPOXRPLACCOUNT;
                    cashBackPayment.source.maxAmount.value = String(cashBackAmount * payment.source.maxAmount.value);
                    cashBackPayment.source.maxAmount.currency =  payment.source.maxAmount.currency;
                    cashBackPayment.source.maxAmount.counterparty = WUPOXRPLACCOUNT;

                    cashBackPayment.destination.address = payment.source.address;
                    cashBackPayment.destination.amount.value = cashBackPayment.source.maxAmount.value;
                    cashBackPayment.destination.amount.currency = cashBackPayment.source.maxAmount.currency;
                    cashBackPayment.destination.amount.counterparty = WUPOXRPLACCOUNT;

                    // console.log(cashBackPayment); // DEBUG PRINTING

                    if(cashBackPayment.source.maxAmount.value > 0){
                      xrpPayment(
                        cashBackPayment,
                        String(payment.source.maxAmount.value),
                        payment.destination.address,
                        wupoXrpSecret
                      ).then(() => {

                        //sender cashback statement
                        const senderCashback = senderStatement;
                        senderCashback.transaction = "credit";
                        senderCashback.description = "WUPO";
                        senderCashback.value = cashBackPayment.source.maxAmount.value; 

                        prepareStatement(senderCashback, loginUser.jwtToken).then(statementResult =>{
                          // console.log(statementResult); // DEBUG PRINTING
                        });

                        //retailer cashback
                        const retailerCashbackPayment = cashBackPayment;
                        
                        retailerCashbackPayment.source.maxAmount.value = String(cashBackAmount * payment.destination.amount.value);
                        retailerCashbackPayment.source.maxAmount.currency = payment.destination.amount.currency;

                        retailerCashbackPayment.destination.address = payment.destination.address;
                        retailerCashbackPayment.destination.amount.value = retailerCashbackPayment.source.maxAmount.value;
                        retailerCashbackPayment.destination.amount.currency = retailerCashbackPayment.source.maxAmount.currency;
                        
                        
                        xrpPayment(
                          retailerCashbackPayment,
                          String(payment.source.maxAmount.value),
                          payment.destination.address,
                          wupoXrpSecret
                        ).then(() => {
                          //Receiver statement
                          const receiverCashback = receiverStatement;
                          receiverCashback.transaction = "credit";
                          receiverCashback.description = "WUPO";
                          receiverCashback.value = cashBackPayment.destination.amount.value;
                
                          prepareStatement(receiverCashback, loginUser.jwtToken).then(statementResult =>{
                            // console.log(statementResult); // DEBUG PRINTING
                          });
                        });
                      });
                    }
                   
                    

                    setCardRender(<h3>PAGO EXITOSO</h3>);
                    swal({
                      title: "PAGO EXITOSO",
                      icon: "success",
                      button: "Súper",
                    }).then(() => {
                      history.push(
                        `/account/handle_account`
                      );
                    });
                  })
                  .catch(() => {
                    swal({
                      title: "NO PUDIMOS HACER TU PAGO",
                      // text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 
                      //       Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                      //       Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                      //       Jorge: XXXXXXXXXX
                      //       Federico: XXXXXXXXXX`,
                      text: operationUnsuccesful,
                      icon: "error",
                      button: "Entiendo",
                    }).then(() => {
                      history.push(
                        `/account/handle_account`
                      );
                    });
                  }) 
              .catch((err) => {
                console.log(err);
                swal({
                  title: "NO PUDIMOS HACER TU PAGO",
                  // text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 
                  //       Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                  //       Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                  //       Jorge: XXXXXXXXXX
                  //       Federico: XXXXXXXXXX`,
                  text: operationUnsuccesful,
                  icon: "error",
                  button: "Entiendo",
                }).then(() => {
                  history.push(
                    `/account/handle_account`
                  );
                });
              });
          }
        } else {
          swal({
            title: "NO PUDIMOS HACER TU PAGO",
            // text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 
            //       Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
            //       Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
            //       Jorge: XXXXXXXXXX
            //       Federico: XXXXXXXXXX`,
            text: operationUnsuccesful,
            icon: "error",
            button: "Entiendo",
          }).then(() => {
            history.push(
              `/account/handle_account`
            );
          });
        }
      })
      .catch(function (error) {
        console.log("Error msg: " + error);
        fetchConcluded = true;
        setCardRender(<h3>NO PUDIMOS HACER TU PAGO :(</h3>);
        swal({
          title: "NO PUDIMOS HACER TU PAGO",
          // text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 
          //       Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
          //       Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
          //       Jorge: XXXXXXXXXX
          //       Federico: XXXXXXXXXX`,
          text: operationUnsuccesful,
          icon: "error",
          button: "Entiendo",
        }).then(() => {
          history.push(`/account/handle_account`);
        });
      });
  }

  function stockAssetPayment() {
    // payment.source.maxAmount.currency=assetFromHandler
    if (payment.source.maxAmount.currency !== payment.destination.amount.currency) {
      console.log(payment)
    
      fetchAssetRates(assetsToFetch, loginUser.jwtToken).then((rate) => {
        const sellValue=Math.round(((payment.source.maxAmount.value*1)+Number.EPSILON)* 10000)/ 10000;
        const buyValue=Math.round(((sellValue*rate.rate) + Number.EPSILON) * 10000) / 10000
        console.log(rate.rate)
        // const buyValue=(sellValue*rate.rate);
        payment.destination.amount.value=String(buyValue);
        mexicoWithdrawObject.amount=buyValue;

        xrplOrder(
          WUPOXRPLACCOUNT,
          wupoXrpSecret,
          payment.destination.amount.currency,
          assetFromHandler,
          String(buyValue),
          String(sellValue)
        )
          .then(() => {
            xrpPayment(
              payment,
              String(payment.source.maxAmount.value),
              payment.destination.address,
              clientXrplToken
            )
              .then(() => {

                if(bankTransferConfirm===true){
                  // console.log("the object for International withdraw: " + JSON.stringify(mexicoWithdrawObject)) // DEBUG PRINTING
                  mexicoWithdraw(mexicoWithdrawObject, loginUser.jwtToken)
                }
                
                if(stockPaymentSender===true){
            
                  fetchXrpl(FETCHTYPE, WUPOXRPLACCOUNT) // under construction
                  .then(balance=>{
                      // console.log("balance of wupos xrpl account: " + JSON.stringify(balance)) // DEBUG PRINTING
                      const balanceObject=balance.obligations.find(
                        (balanceElement)=>balanceElement.currency === assetFromHandler
                      )
                      // xrpl balance of the stock been used
                      orderObject.stockXrplBalance=balanceObject.value;
                      orderObject.stockSymbolOrdered = assetsToFetch.fromAsset;
                      orderObject.instruction = "sell"
                      orderObject.amountOrdered = payment.source.maxAmount.value;
                      // console.log("balanceObjectInStock: " + JSON.stringify(orderObject)); // DEBUG PRINTING
        
                      //place an order in ameritrade /* COMMENTED WHILE LAUNCHING */
                       placeOrder(orderObject, loginUser.jwtToken);
                  });
                   
                }

                if(stockPaymentReceiver === true){
                  
                  fetchXrpl(FETCHTYPE, WUPOXRPLACCOUNT) // under construction
                  .then(balance=>{
                      // console.log("balance of wupos xrpl account: " + JSON.stringify(balance)) // DEBUG PRINTING
                      const balanceObject=balance.obligations.find(
                        balanceElement=>balanceElement.currency===payment.destination.amount.currency   
                      )
                      // xrpl balance of the stock been used
                      orderObject.stockXrplBalance=balanceObject.value;
                      orderObject.stockSymbolOrdered = assetsToFetch.toAsset; //changing here
                      orderObject.instruction = "buy";
                      orderObject.amountOrdered = payment.destination.amount.value;
                      // console.log("balanceObjectInStock: " + JSON.stringify(orderObject)); // DEBUG PRINTING
        
                      //place an order in ameritrade /* COMMENTED WHILE LAUNCHING */
                       placeOrder(orderObject, loginUser.jwtToken);
                  });
                  // else: if tiggered, purchase an article in the retailers affiliates
                  // else: buy currencies in eth ledger alignes to financial strategy   
                }

                // exchangeCurrencies.forEach(element=>{

                  // if(element.currency === payment.destination.amount.currency){
                  if(currencyPaymentReceiver){

                    const exchangeOrder={
                      currencie: "",
                      amount: "",
                      instruction: ""
                    };

                    exchangeOrder.instruction = "sell"; // sell given that it sells the bridge asset to receive the requested asset  
                    // exchangeOrder.currencie=element.exchangeCurrency;  
                    exchangeOrder.currencie=assetsToFetch.toAsset;  // ERROR                                         
                    console.log(exchangeOrder.currencie)                                 
                    const currenciesToFetch={
                        fromAsset: payment.destination.amount.currency,
                        toAsset: bridgeAsset
                    }  
                    fetchAssetRates(currenciesToFetch, loginUser.jwtToken)
                     .then(result=>{
                        
                        exchangeOrder.amount=String(Math.round(((payment.destination.amount.value*result.rate)+Number.EPSILON)*10)/10);
                        // String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000);
                        currenciesOrder(exchangeOrder, loginUser.jwtToken);
                     })
                  } 
                  
                  // if(element.currency === payment.source.maxAmount.currency){
                  if(currencyPaymentSender){

                      const exchangeOrder={
                        currencie: "",
                        amount: "",
                        instruction: ""
                      };

                      exchangeOrder.instruction = "buy"; // buy given that it buys the requested currency for the the bridge asset
                      // exchangeOrder.currencie=element.exchangeCurrency;
                      exchangeOrder.currencie=assetsToFetch.fromAsset;
                      console.log(exchangeOrder.currencie)                          
                      const currenciesToFetch={
                          fromAsset: payment.destination.amount.currency,
                          toAsset: bridgeAsset
                      }  
                      fetchAssetRates(currenciesToFetch, loginUser.jwtToken)
                      .then(result=>{
                          
                          exchangeOrder.amount=String(Math.round(((payment.destination.amount.value*result.rate)+Number.EPSILON)*10)/10);
                          // String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000);
                          currenciesOrder(exchangeOrder, loginUser.jwtToken);
                      })
                  }

                // });

                    //Sender statement
                    senderStatement.accountId = user.accountId;
                    senderStatement.account = user.account;
                    senderStatement.asset = user.asset;
                    senderStatement.value = payment.source.maxAmount.value;
                    senderStatement.transaction = "debit";
                    senderStatement.description = gratitudeMsg;
                    senderStatement.counterpartyAccount = receiverStatement.account;
          
                    prepareStatement(senderStatement, loginUser.jwtToken).then(statementResult =>{
                      // console.log(statementResult); // DEBUG PRINTING

                      //Receiver statement
                      receiverStatement.accountId = txInput.receiverAccount;
                      // receiverStatement.account = user.account;
                      receiverStatement.asset = user.asset;
                      receiverStatement.value = payment.destination.amount.value;
                      receiverStatement.transaction = "credit";
                      receiverStatement.description = gratitudeMsg;
                      receiverStatement.counterpartyAccount = user.account;
            
                      prepareStatement(receiverStatement, loginUser.jwtToken).then(statementResult =>{
                        // console.log(statementResult); // DEBUG PRINTING
                      });

                    });

                    //TODO: including the cashBack payment
                    // console.log("Start the printing to test the cashback functionality") // DEBUG PRINTING

                    cashBackPayment.source.address = WUPOXRPLACCOUNT;
                    cashBackPayment.source.maxAmount.value = String(cashBackAmount * payment.source.maxAmount.value);
                    cashBackPayment.source.maxAmount.currency =  payment.source.maxAmount.currency;
                    cashBackPayment.source.maxAmount.counterparty = WUPOXRPLACCOUNT;

                    cashBackPayment.destination.address = payment.source.address;
                    cashBackPayment.destination.amount.value = cashBackPayment.source.maxAmount.value;
                    cashBackPayment.destination.amount.currency = cashBackPayment.source.maxAmount.currency;
                    cashBackPayment.destination.amount.counterparty = WUPOXRPLACCOUNT;

                    // console.log(user)
                    // console.log(cashBackPayment); // DEBUG PRINTING

                    if(cashBackPayment.source.maxAmount.value > 0){
                      xrpPayment(
                        cashBackPayment,
                        String(payment.source.maxAmount.value),
                        payment.destination.address,
                        wupoXrpSecret
                      ).then(() => {

                         //sender cashback statement
                         const senderCashback = senderStatement;
                         senderCashback.transaction = "credit";
                         senderCashback.description = "WUPO";
                         senderCashback.value = cashBackPayment.source.maxAmount.value;
 
                         prepareStatement(senderCashback, loginUser.jwtToken).then(statementResult =>{
                          //  console.log(statementResult); // DEBUG PRINTING
                         });

                        const retailerCashbackPayment = cashBackPayment;
                        
                        retailerCashbackPayment.source.maxAmount.value = String(cashBackAmount * payment.destination.amount.value);
                        retailerCashbackPayment.source.maxAmount.currency = payment.destination.amount.currency;

                        retailerCashbackPayment.destination.address = payment.destination.address;
                        retailerCashbackPayment.destination.amount.value = retailerCashbackPayment.source.maxAmount.value;
                        retailerCashbackPayment.destination.amount.currency = retailerCashbackPayment.source.maxAmount.currency;
            
                        xrpPayment(
                          retailerCashbackPayment,
                          String(payment.source.maxAmount.value),
                          payment.destination.address,
                          wupoXrpSecret
                        ).then(() => {
                          //Receiver statement
                          const receiverCashback = receiverStatement;
                          receiverCashback.transaction = "credit";
                          receiverCashback.description = "WUPO";
                          receiverCashback.value = cashBackPayment.destination.amount.value;
                
                          prepareStatement(receiverCashback, loginUser.jwtToken).then(statementResult =>{
                            // console.log(statementResult); // DEBUG PRINTING
                          });
                        });
                      });
                    }
                    

                setCardRender(<h3>PAGO EXITOSO</h3>);
                swal({
                  title: "SÚPER",
                  icon: "success",
                  button: "Súper",
                }).then(() => {
                  history.push(
                    `/account/handle_account`
                  );
                });
              })
              .catch(() => {
                swal({
                  title: "NO PUDIMOS GESTINAR EL PREMIO",
                  icon: "error",
                  button: "Entiendo",
                }).then(() => {
                  history.push(
                    `/account/handle_account`
                  );
                });
              });
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: "NO PUDIMOS GESTINAR EL PREMIO",
              // text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 
              //       Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
              //       Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
              //       Jorge: XXXXXXXXXX
              //       Federico: XXXXXXXXXX`,
              text: operationUnsuccesful,
              icon: "error",
              button: "Entiendo",
            }).then(() => {
              history.push(
                `/account/handle_account`
              );
            });
          });
        // });
      });
    } else {
      payment.destination.amount.value = payment.source.maxAmount.value
      console.log(payment)

      xrpPayment(
        payment,
        String(payment.source.maxAmount.value),
        payment.destination.address,
        clientXrplToken
      )
        .then(() => {

          mexicoWithdrawObject.amount=payment.destination.amount.value; 
          if(bankTransferConfirm===true){
              // console.log("the object for International withdraw: " + JSON.stringify(mexicoWithdrawObject)) // DEBUG PRINTING
              mexicoWithdraw(mexicoWithdrawObject, loginUser.jwtToken)
          }

          //Sender statement
          senderStatement.accountId = user.accountId;
          senderStatement.account = user.account;
          senderStatement.asset = user.asset;
          senderStatement.value = payment.source.maxAmount.value;
          senderStatement.transaction = "debit";
          senderStatement.description = gratitudeMsg;
          senderStatement.counterpartyAccount = receiverStatement.account;

          prepareStatement(senderStatement, loginUser.jwtToken).then(statementResult =>{
            // console.log(statementResult); // DEBUG PRINTING

            //Receiver statement
            receiverStatement.accountId = txInput.receiverAccount;
            // receiverStatement.account = user.account;
            receiverStatement.asset = user.asset;
            receiverStatement.value = payment.destination.amount.value;
            receiverStatement.transaction = "credit";
            receiverStatement.description = gratitudeMsg;
            receiverStatement.counterpartyAccount = user.account;
  
            prepareStatement(receiverStatement, loginUser.jwtToken).then(statementResult =>{
              // console.log(statementResult); // DEBUG PRINTING
            });

          });

          //TODO: including the cashBack payment
          // console.log("Start the printing to test the cashback functionality") // DEBUG PRINTING

          cashBackPayment.source.address = WUPOXRPLACCOUNT;
          cashBackPayment.source.maxAmount.value = String(cashBackAmount * payment.source.maxAmount.value);
          cashBackPayment.source.maxAmount.currency =  payment.source.maxAmount.currency;
          cashBackPayment.source.maxAmount.counterparty = WUPOXRPLACCOUNT;

          cashBackPayment.destination.address = payment.source.address;
          cashBackPayment.destination.amount.value = cashBackPayment.source.maxAmount.value;
          cashBackPayment.destination.amount.currency = cashBackPayment.source.maxAmount.currency;
          cashBackPayment.destination.amount.counterparty = WUPOXRPLACCOUNT;

          // console.log(user)
          // console.log(cashBackPayment); // DEBUG PRINTING

          if(cashBackPayment.source.maxAmount.value > 0){
            try{
              xrpPayment(
                cashBackPayment,
                String(payment.source.maxAmount.value),
                payment.destination.address,
                wupoXrpSecret
              ).then(() => {

                  //sender cashback statement
                  const senderCashback = senderStatement;
                  senderCashback.transaction = "credit";
                  senderCashback.description = "WUPO";
                  senderCashback.value = cashBackPayment.source.maxAmount.value;

                  prepareStatement(senderCashback, loginUser.jwtToken).then(statementResult =>{
                    // console.log(statementResult); // DEBUG PRINTING
                  });

                const retailerCashbackPayment = cashBackPayment;
              
                retailerCashbackPayment.source.maxAmount.value = String(cashBackAmount * payment.destination.amount.value);
                retailerCashbackPayment.source.maxAmount.currency = payment.destination.amount.currency;

                retailerCashbackPayment.destination.address = payment.destination.address;
                retailerCashbackPayment.destination.amount.value = retailerCashbackPayment.source.maxAmount.value;
                retailerCashbackPayment.destination.amount.currency = retailerCashbackPayment.source.maxAmount.currency;

                xrpPayment(
                  retailerCashbackPayment,
                  String(payment.source.maxAmount.value),
                  payment.destination.address,
                  wupoXrpSecret
                ).then(() => {
                  //Receiver statement
                  const receiverCashback = receiverStatement;
                  receiverCashback.transaction = "credit";
                  receiverCashback.description = "WUPO";
                  receiverCashback.value = cashBackPayment.destination.amount.value;
                  
                 

                  prepareStatement(receiverCashback, loginUser.jwtToken).then(statementResult =>{
                    // console.log(statementResult); // DEBUG PRINTING
                    setCardRender(<h3>PAGO EXITOSO</h3>);
                    swal({
                      title: "SÚPER",
                      icon: "success",
                      button: "Súper",
                    }).then(() => {
                      history.push(
                        `/account/handle_account`
                      );
                    });
                    
                  });
                  // setCardRender(<h3>PAGO EXITOSO</h3>);
                  // swal({
                  //   title: "PAGO EXITOSO",
                  //   icon: "success",
                  //   button: "Súper",
                  // }).then(() => {
                  //   history.push(
                  //     `/account/handle_account/choosetx/${assetFromHandler}`
                  //   );
                  // });
                }).catch((error) => {
                  setCardRender(<h3>PAGO EXITOSO</h3>);
                  swal({
                    title: "SÚPER",
                    icon: "success",
                    button: "Súper",
                  }).then(() => {
                    history.push(
                      `/account/handle_account`
                    );
                  });
                });
              }).catch((error) => {
                setCardRender(<h3>PAGO EXITOSO</h3>);
                swal({
                  title: "SÚPER",
                  icon: "success",
                  button: "Súper",
                }).then(() => {
                  history.push(
                    `/account/handle_account`
                  );
                });
              });
            
            }catch (error){
              setCardRender(<h3>PAGO EXITOSO</h3>);
              swal({
                title: "SÚPER",
                icon: "success",
                button: "Súper",
              }).then(() => {
                history.push(
                  `/account/handle_account`
                );
              });
            }
          }else{
            setCardRender(<h3>SÚPER</h3>);
            swal({
              title: "SÚPER",
              icon: "success",
              button: "Súper",
            }).then(() => {
              history.push(
                `/account/handle_account`
              );
            });
          }
        
          
        })
        .catch(() => {
          swal({
            title: "NO PUDIMOS GENERAR EL PREMIO",
            icon: "error",
            button: "Entiendo",
          }).then(() => {
            history.push(
              `/account/handle_account`
            );
          });
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: "NO PUDIMOS GENERAR EL PREMIO",
            icon: "error",
            button: "Entiendo",
          }).then(() => {
            history.push(
              `/account/handle_account`
            );
          });
        });
    }
  }

  const [bankTransfer, setBankTransfer]=React.useState(false);
    const [bankAccount, setBankAccount]=React.useState();
    const [bankTransferConfirm, setBankTransferConfirm]=React.useState(false);

    function handleBank(){
        setBankTransfer(true);
    }

    const handleClose = () => {
        setBankTransfer(false);
    };

    function handleConfirm(){
        mexicoWithdrawObject.clabe=bankAccount;
        //The destination accountId is defined from an input
        //The payment amount is defined in the payments functions (currencies -ilp- and stocks)
        setBankTransferConfirm(true);
        setBankTransfer(false);
    }

    const [gratitudeBox, setGratitudeBox] = React.useState(false);
    const [gratitudeMsg, setGratitudeMsg] = React.useState("");
    const [gratitudDisabled, setGratitudDisabled] = React.useState(true);

    const gratitudeBoxClose = () => {
      setGratitudeBox(false);
    }

    useEffect(() => {
      if(gratitudeMsg !== ""){
        setGratitudDisabled(false);
      }else if(!gratitudDisabled){
        setGratitudDisabled(true);
      }
    }, [gratitudeMsg])

    const tokensTransfer = () => {
      setLoading(true);
      stockAssetPayment();
    };


  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        minWidth: "20rem",
    }}
    >
      <Box 
          width="100%"
          // maxWidth = "100%"
          sx={{maxWidth: "615px",}}
          display = "flex"
          flexDirection = "column"
          alignItems = "center"
          padding = "20px 25px"
          justifyContent = "space-evenly"
      >
        {
          loading ?
          <PuffLoader
            size={200}
            color={Colors.secondary}
            css={cssLoader}
          />
          :
          <React.Fragment>
            <PageLabel>{steps[stepNumber].name}</PageLabel>
            <Box sx={{width: "60%", display: "flex", justifyContent: "center"}}>
              <StepProgress stepNumber={stepNumber} steps={steps} />
            </Box>
            {cardRender}
            {costRender}
            <Button
              variant="contained"
              sx={{backgroundColor: Colors.primary}}
              onClick={handleInput}
            >
              Listo
            </Button>
            <React.Fragment>
              {/* {stepNumber===steps.length-1 ? 
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={handleBank}
                      className={classes.button}
                  >
                      BANCO
                  </Button>
                : <></>  } */}
              <Dialog open={bankTransfer} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">INFORMACIÓN BANCARIA</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                      Ingresa el CLABE de destino 
                      (temporalmente solo México)
                  </DialogContentText>
                  <TextField
                      variant="standard"
                      autoFocus
                      margin="dense"
                      id="name"
                      label="CLABE"
                      type="number"
                      fullWidth
                      onChange={e=>setBankAccount(e.target.value)}

                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                      Atrás
                  </Button>
                  <Button 
                      onClick={handleConfirm} 
                      color="primary"
                      disabled={inputFromChild!=="MXN"}
                  >
                      Confirmar
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={gratitudeBox} onClose={gratitudeBoxClose}>
                <DialogTitle>DILE POR QUÉ Y SÁCALE UNA SONRISA</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="standard"
                        multiline
                        sx={{marginTop: "1rem", resize: "both"}}
                        id="gratitud"
                        name="gratitud"
                        label="Mensaje de gratitud"
                        fullWidth
                        onChange={(e) => setGratitudeMsg(e.target.value)}
                        value={gratitudeMsg}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={gratitudeBoxClose} sx={{color: "grey"}}>
                        Atrás
                    </Button>
                    <Button 
                        onClick={tokensTransfer} sx={{color: Colors.primary}} 
                        disabled={gratitudDisabled}
                      >
                        Listo
                    </Button>
                </DialogActions>
              </Dialog>

            </React.Fragment>
          </React.Fragment>
        }
      </Box>
      </div>
  );
}
