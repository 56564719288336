import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import ContentForm from "../../layout/ContentForm";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";
import { xrpPayment, createTrustline, createXrplAccount, prepareStatement } from "../../utilityFunctions/Paymentutil";
import { loadXrpAddressCreds } from "../../utilityFunctions/FetchUtil";
import { postCredit, fetchCreditConditions, postRevolvingCredit, verifyBankAccount } from "../../utilityFunctions/CreditUtil";
import { updateExplanations } from "../../utilityFunctions/AdminUtil";
import { trustlineLimitValue, creditAsset, creditTerm, loanMaxLimit, wupoServerUri, b2bClient, 
    bankCodes, bankAccountTypes } from "../../Api";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {creditExplanation, loggedSimulatorExplanation} from "../../Texts/Explanations";
import {disbursedText, rateText, warrantyText, platformText,
    platformDiscountText, vatText, totalPaymentText} from "../../Texts/SimulatorExplanations"
import { BsInfoCircle } from "react-icons/bs";
import Tooltip from '@mui/material/Tooltip';
import {bankAccountExp, operationUnsuccesful} from "../../Texts/OperationResult.js";
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 900'><rect fill='#ffffff' width='1600' height='900'/><defs><linearGradient id='a' x1='0' x2='0' y1='1' y2='0'><stop  offset='0' stop-color='#0FF'/><stop  offset='1' stop-color='#CF6'/></linearGradient><linearGradient id='b' x1='0' x2='0' y1='0' y2='1'><stop  offset='0' stop-color='#BD0BFF'/><stop  offset='1' stop-color='#FC0'/></linearGradient></defs><g fill='#FFF' fill-opacity='0' stroke-miterlimit='10'><g  stroke='url(#a)' stroke-width='2'><path  transform='translate(0 0)' d='M1409 581 1450.35 511 1490 581z'/><circle  stroke-width='4' transform='rotate(0 800 450)' cx='500' cy='100' r='40'/><path  transform='translate(0 0)' d='M400.86 735.5h-83.73c0-23.12 18.74-41.87 41.87-41.87S400.86 712.38 400.86 735.5z'/></g><g stroke='url(#b)' stroke-width='4'><path  transform='translate(0 0)' d='M149.8 345.2 118.4 389.8 149.8 434.4 181.2 389.8z'/><rect  stroke-width='8' transform='rotate(0 1089 759)' x='1039' y='709' width='100' height='100'/><path  transform='rotate(0 1400 132)' d='M1426.8 132.4 1405.7 168.8 1363.7 168.8 1342.7 132.4 1363.7 96 1405.7 96z'/></g></g></svg>`;

let marksBalance = [
    {
      value: 100000,
      label: '$100.000',
    },
    {
      value: 500000,
      label: '$500.000',
    },
];

let marksTerm = [
    {
        value: 7,
        label: '7',
    },
    {
        value: 56,
        label: '56',
    },
];

let marksInstallments = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
];


const useStyles = makeStyles((theme) => ({
    input: {
        width: "50%",
    },

    formControl: {
        width: "50%",
    },

    dialogStyle:{
        whiteSpace: "pre-line",
        
    },

    dialogContainer:{
        width: "25em"
    },

    typography:{
        color: Colors.primary,
        fontWeight: "bold",
    },
    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
          transform: 'scale(1.05)',
          backgroundColor: Colors.primary,
        },
    },
    cardImage:{
        width: "20%",
        justifySelf: "center"
    },
    formControl: {
        minWidth: 120,
        marginRight:0
    },
    divider:{
        background: Colors.primary
    },
    svgBackground: {
        background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
    },

}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let WUPOADDRESS = ""; 
let WUPOSECRET = ""; 

let clientXrplAddress = "";
let clientClassicXrplAddress = "";
let clientXrplSecret = "";

const QUALITYIN = 1;
const QUALITYOUT = 1;

const payment = {
    source: {
      address: "senderAddress",
      maxAmount: {
        value: "senderValue",
        currency: "senderAsset",
        //"counterparty": sendIssuer
      },
    },
    destination: {
      address: "destinationAddress",
      amount: {
        value: "destinationValue",
        currency: "destinationAsset",
        //"counterparty": destIssuer
      },
    },
};

const loanDisbursement = {
    source: {
      address: "",
      maxAmount: {
        value: "",
        currency: "",
        counterparty: ""
      },
    },
    destination: {
      address: "",
      amount: {
        value: "",
        currency: "",
        counterparty: ""
      },
    },
};

const clientCreditAsset = creditAsset;

const trustline = {
    currency: clientCreditAsset,
    counterparty: "",
    limit: "",
    qualityIn: QUALITYIN,
    qualityOut: QUALITYOUT,
};

let lender = "WUPO"; //Currently a hardcoded WUPO, but needs to migrate to WUPO or a friend selected by the client.
const creditOptions = ["Cashback", "change"]

const creditInformation = {
    creditXrplAccount: "",
    creditClassicXrplAccount: "",
    creditXrplSecret: "",
    asset: clientCreditAsset,
    initialBalance: "",
    term: "",
    termUnit: creditTerm.day,
    dues: "",
    options: creditOptions,
    lender: "",
    account: "",
    paymentType: "",
    transactionType: "cash",
    bankCode: "",
    bankAccount: "",
    bankAccountType: "",
};

let conditionsArray=[]; //Array fille with the credit conditions: dialog that shows conditions to clients
let creditCost=0; //payment included in the dialog that shows conditions to clients
let creditInterestRate=0; //interest rate included in the dialog that shows conditions to clients

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
}

let loanLimit = loanMaxLimit;

//Credit front rendering
let totalPayment = 0;
let uxEDR = 0;
let disbursedBalanceBeforeInterests = 0;
let VAT = 0; // Local tax: IVA = 19%

//Credit Conditions:
let creditValueConditions = {
    asset: "WUP",
    minValue: 0,
    maxValue: 0,
    interval: 0,
};

let creditTermConditions = {
    minTerm: 0,
    maxTerm: 0,
    interval: 0,
    termUnit: creditTerm.day,
};

let creditInstallmentConditions = {
    amountInstallments: 1, //Can be equal to the term selected by the client / interval (traditional behavior).
    paymentType: "",
};

let creditInterestConditions = {
    interestRate: 0,
    interestRateDefaultMultiplier: 0,
    collectionsFee: 0
};

let creditCostsConditions = {
    costsWithVAT: 0, // Platform
    costsWithVATminimum: 0,
    platformTimeDiscount: 0,
    platformBalanceDiscount: 0,

    costsWithoutVAT: 0, //Aval
    costsWithoutVATminimum: 0,
    costsWithoutVATFee: 0,
    warrantyTimeDiscount: 0,
    warrantyBalanceDiscount: 0,
};

const calculationVariables = {
    loanAmount: 0,
    margenState: 0,
    interestState: 0,
    warrantyDiscount: 0,
    platfromDiscount: 0,
    disbursedBalance: 0,
    vatTax: 0,
    term: 0,
    totalPayment: 0,
};

export default function Credit(props) {
    const {
        loginUser,
        visitorAccounts,
        enableAccountAsset,
        enablePaymentValue,
        visitorLoans, loanCostsSettings, loanConditionsSettings, loadProductsAndSetting, displayExplanations
      } = props;
    
    const classes = useStyles();

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[loanAmount, setLoanAmount] = React.useState(0);
    const[platfromDiscount, setPlatfromDiscount] = React.useState(0);
    const[interestState, setInterestState] = React.useState(0);
    const[margenState, setMargenState] = React.useState(0);
    const[warrantyDiscount, setWarrantyDiscount] = React.useState(0);
    const[vatTax, setVatTax] = React.useState(0);
    const[disbursedBalanceState, setDisbursedBalanceState]= React.useState(0);

    const [term, setTerm] = React.useState(0);
    const [dues, setDues] = React.useState(creditInstallmentConditions.amountInstallments);
    const [disableDues, setDisableDues] = React.useState(true);
    const [disableTerm, setDisableTerm] = React.useState(true);
    const [disableButton, setDisableButton] = React.useState(true);

    const loanSettingsAssignment = () =>{
        return new Promise((resolve) =>{

        
            creditCostsConditions = {
                ...creditCostsConditions, 
                costsWithVAT: loanCostsSettings.costsWithTaxes, // Platform
                costsWithVATminimum: loanCostsSettings.costsWithTaxesMinimum,
                platformTimeDiscount: loanCostsSettings.platformTimeDiscount,
                platformBalanceDiscount: loanCostsSettings.platformBalanceDiscount,

                costsWithoutVATminimum: loanCostsSettings.costsWithoutTaxesMinimum,
                costsWithoutVATFee: loanCostsSettings.costsWithoutTaxes,
                warrantyTimeDiscount: loanCostsSettings.warrantyTimeDiscount,
                warrantyBalanceDiscount: loanCostsSettings.warrantyBalanceDiscount

                // paymentType: "prepaid", //Pending include it in a table on the back 
            };

            creditInterestConditions = {
                ...creditInterestConditions,
                interestRate: loanCostsSettings.interestRate,
                interestRateDefaultMultiplier: loanCostsSettings.interestDefaultFactor,
                collectionsFee: loanCostsSettings.collectionsFee,
            };

            creditInstallmentConditions.amountInstallments = loanConditionsSettings.amountInstallments;
            creditInstallmentConditions.paymentType = loanConditionsSettings.paymentType;
            console.log(creditInstallmentConditions.paymentType);

            creditTermConditions = {
                ...creditTermConditions,
                minTerm: loanConditionsSettings.minTerm,
                maxTerm: loanConditionsSettings.maxTerm,
                interval: loanConditionsSettings.termInterval,
                termUnit: loanConditionsSettings.termUnit,
            };

            creditValueConditions = {
                ...creditValueConditions,
                // asset: "WUP", //Pending solve asset
                minValue: loanConditionsSettings.minValue,
                maxValue: loanConditionsSettings.maxValue,
                interval: loanConditionsSettings.balanceInterval,
            };

            VAT = loanCostsSettings.taxesRate;

            resolve("ok");
        })    
    };

    const dailyInterestCalc = () =>{
        
        uxEDR = (Math.pow(1+creditInterestConditions.interestRate, calculationVariables.term/360)) - 1;

        return new Promise((resolve)=>{
            resolve(uxEDR);
        });
    }

    const VATCalculation = (discount) =>{
        // if(discount){
        //     calculationVariables.vatTax = (creditCostsConditions.costsWithVAT - discount) * VAT;
        //     setVatTax((creditCostsConditions.costsWithVAT - discount) * VAT);
        //     // console.log(calculationVariables.vatTax)
        // }else{
        //     calculationVariables.vatTax = (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) * VAT;
        //     setVatTax((creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) * VAT);
        //     // console.log(calculationVariables.vatTax)
        // }

        calculationVariables.vatTax = ((creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) ) * VAT;
        setVatTax(calculationVariables.vatTax);

        return calculationVariables.vatTax;
    };

    const handleInterests = () =>{

        if(creditInstallmentConditions.paymentType === "postpaid"){
        //     calculationVariables.interestState = uxEDR * (calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        // + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax);

        calculationVariables.interestState = uxEDR * (calculationVariables.loanAmount);
        
        setInterestState(calculationVariables.interestState);
        }
        else{
            setInterestState(calculationVariables.loanAmount * uxEDR);
            calculationVariables.interestState = calculationVariables.loanAmount * uxEDR;
        }
     
        return new Promise((resolve) => {
            resolve(calculationVariables.loanAmount * uxEDR);
        });
    };

    const disbursedBalanceRendered = (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in disbursed: ${loanAmount}`)
        // calculationVariables.disbursedBalance = calculationVariables.loanAmount - 
        // ((creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount) - guarantorDiscount)
        // - (creditCostsConditions.costsWithVAT - discount) -tempVat - rate;

        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;
        
        // calculationVariables.totalPayment = calculationVariables.loanAmount + 
        // ((creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount) - guarantorDiscount)
        // + (creditCostsConditions.costsWithVAT - discount) + tempVat + rate;

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;

        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        return new Promise((resolve) => {
            resolve(calculationVariables.disbursedBalance );
        });
    };

    const defineMargins = async (guarantorDiscount, discount, rate, tempVat) =>{
        console.log(`loan amount in margins ${loanAmount}`)
        const onMountBalance = await disbursedBalanceRendered(guarantorDiscount, discount, rate, tempVat);
        setMargenState((calculationVariables.loanAmount -  onMountBalance)/ calculationVariables.loanAmount);
    };

    const[isAdmin, setIsAdmin] = React.useState(false);
    
    const handleProfile = () =>{
        if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
            
            if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
                setIsAdmin(true);
            }
        }
    }

    const updateSettings = () =>{
        marksBalance = [
            {
              value: loanConditionsSettings.minValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.minValue)}`,
              
            },
            {
              value: loanConditionsSettings.maxValue,
              label: `$${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.maxValue)}`,
            },
          ];
        
        marksTerm = [
            {
                value: loanConditionsSettings.minTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.minTerm)}`,
            },
            {
                value: loanConditionsSettings.maxTerm,
                label: `${new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(loanConditionsSettings.maxTerm)}`,
            },
        ];
        
        marksInstallments = [
            {
                value: 1,
                label: '1',
            },
            {
                value: loanConditionsSettings.amountInstallments,
                label: `${loanConditionsSettings.amountInstallments}`,
            },
        ];
    };

    const initialValuesCalculation = async () =>{

        //  Assign the setting fetched from the back
        await loanSettingsAssignment();
        setLoanAmount(creditValueConditions.minValue);
        calculationVariables.loanAmount = creditValueConditions.minValue;
        creditInformation.initialBalance = creditValueConditions.minValue;

        setTerm(creditValueConditions.minTerm);
        calculationVariables.term = creditTermConditions.minTerm;
        creditInformation.term = creditTermConditions.minTerm;

        //Set initial cost without vat
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;

        //Set with and without taxes initial costs discounts
        // const guarantorDiscount = await calculateNoVATTermDiscount(creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount);
        // const guarantorDiscount = await calculateNoVATTermDiscount(); //UNDER REVIEW
        const guarantorDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(guarantorDiscount);
        calculationVariables.warrantyDiscount = guarantorDiscount;

        // const discount = await calculateWithVATTermDiscount(); //UNDER REVIEW
        const discount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(discount);
        calculationVariables.platfromDiscount = discount;

        //Initial taxes calculation
        // const tempVat = VATCalculation(discount);
        const tempVat = VATCalculation();
        
        //Set dues
        setDues(creditInstallmentConditions.amountInstallments);
        creditInformation.dues=creditInstallmentConditions.amountInstallments;

         //Set termunit
        creditInformation.termUnit = creditTermConditions.termUnit;

        //Set interests calculations
        await dailyInterestCalc();
        const rate = await handleInterests();

        defineMargins(guarantorDiscount, discount, rate, tempVat);

        //Set marks accordingly to received info from back
        updateSettings();

        setLoading(false);
    };

    const loadInitialProducts = () => {
        return (new Promise((resolve) => {
                if(props.location.state && props.location.state.fromHistory === "/login"){
                    resolve("200");
                }
            loadProductsAndSetting("/account/create_credit", loginUser.accountId, loginUser.jwtToken).then((result) => {
                resolve(result);
            });
            
        }));
           
    };

    const handleLoanSimulator = () => {
        return(new Promise((resolve) => {
            if(props.location.state && props.location.state.creditInformation){
                console.log(props.location.state.creditInformation);
                
                //Assign proper values to the object that will serve to post the loan to the back
                creditInformation.account = loginUser.accountId;
                creditInformation.initialBalance = props.location.state.creditInformation.initialBalance;
                creditInformation.term = props.location.state.creditInformation.term;
                creditInformation.dues = props.location.state.creditInformation.dues;

                console.log(creditInformation);
                handleInputCreditInformation();
                resolve("skipSimulation");
            }
            else{
                resolve("doSimulation");
            }
        }));
    }

    const explanationMessages = () => {
        if(displayExplanations && displayExplanations.newLoanMessage){
            if(props.location.state && props.location.state.creditInformation){
                swal({
                    title: "Explicación",
                    text: creditExplanation,
                    icon: "success",
                    button: "Entiendo",
                }).then(() => {
                    updateExplanations(loginUser.accountId, "newLoanMessage");
                });
            }
            else{
                swal({
                    title: "Explicación",
                    text: loggedSimulatorExplanation,
                    icon: "success",
                    button: "Entiendo",
                }).then(() => {
                    updateExplanations(loginUser.accountId, "newLoanMessage");
                })
            }
            
        }
    };
 
    useEffect(() => {

        if(visitorLoans){
            history.push({pathname: "/credit/handle_credit"});
        }

        setLoading(true);
        //Handle explanation message
        explanationMessages();

        loadInitialProducts().then((result) => {
            initialValuesCalculation().then(() => {

                //When loan was already simulated: first time user and B2B client decided that the first view is a loan simulator
                handleLoanSimulator();

            });
        });

        //Profile verification
        handleProfile();

        //Credentials loading
        loadXrpAddressCreds(loginUser.jwtToken).then((credentials) => {
            
            WUPOADDRESS = credentials.xrpAddress;
            WUPOSECRET = credentials.xrpAddressSecret;
            // console.log(`address: ${WUPOADDRESS} secret: ${WUPOSECRET}`); //DEBUG
        });
        
        //Include in the un mount when receiving from AML form
        creditInformation.account = loginUser.accountId;

        loanLimit = loanMaxLimit;

    }, [])

    const delay = ms => new Promise(res => setTimeout(res, ms));
    
    function handleInputCreditInformation(){
        setLoading(true);
        
        creditInformation.paymentType = creditInstallmentConditions.paymentType;
        determineIfCreditProceed();
    }

    function determineIfCreditProceed(){

       const loanAssetAccount = visitorAccounts.find((account) => {
            return account.asset === clientCreditAsset;
        });

        // if(!loanAssetAccount){
        //     swal({
        //         title: "REQUISITO PREVIO",
        //         text: "Primero debes abrir tu cuenta en WUPOS",
        //         icon: "error",
        //         button: "Entiendo",
        //     }).then(() => {
        //         // history.push({pathname: "/account/create_account"}); //IMS specific
        //     });
        // }

        if(visitorLoans !== "Not found"){
            //TODO revolving actions
        }
        

        fetchCreditConditions(creditInformation, loginUser.jwtToken).then((loanConditions) =>{
           
            conditionsArray = [...loanConditions.installments];
            
            // console.log(conditionsArray); //DEBUG
            // console.log(loanConditions); //DEBUG
            creditCost = loanConditions.loanFee;
            creditInterestRate = loanConditions.loanInterestRate;
            handeConditions(); 
        }); 
        
    }

    function createCreditXrplAccount(){
        setLoading(true);

        createXrplAccount().then((xrplAddress) =>{
            console.log(xrplAddress);
            clientClassicXrplAddress = xrplAddress.address;
            clientXrplAddress = xrplAddress.xAddress;
            clientXrplSecret = xrplAddress.secret;


            payment.source.address = WUPOADDRESS;
            payment.source.maxAmount.value = enablePaymentValue;
            payment.source.maxAmount.currency = enableAccountAsset;

            payment.destination.address = clientXrplAddress;
            payment.destination.amount.value = enablePaymentValue;
            payment.destination.amount.currency = enableAccountAsset;

            xrpPayment(payment, "", "", WUPOSECRET).then((paymentResult) =>{
                if(paymentResult === "payment ok"){
                   
                    createCreditTrustline();                    

                }else{
                    swal({
                        title: "NO PUDIMOS CREAR TU PRODUCTO",
                        // text: operationUnsuccesful,
                        icon: "error",
                        button: "Entiendo",
                    }).then(() => {
                        history.push({pathname: "/account/create_credit"});
                    });
                }
            })
        })

    }

   async function createCreditTrustline(){

        trustline.currency = clientCreditAsset;
        trustline.counterparty = WUPOADDRESS;
        trustline.limit = trustlineLimitValue;
        await delay(50); //Sometimes required when client's internet is slow to ensure proper creation of trustline
        createTrustline( clientXrplAddress, trustline, clientXrplSecret).then((trustlineResult) => {
            if(trustlineResult.resultCode === "tesSUCCESS"){
                
                postCreditToBack();

            }else{
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    // text: operationUnsuccesful,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                });              
            }
        })
        .catch((error)=>{
            console.log(error);
            swal({
                title: "NO PUDIMOS CREAR TU PRODUCTO",
                // text: operationUnsuccesful,
                icon: "error",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/account/create_credit"});
            }); 
        })
    }

    function postCreditToBack(){

        // const paymentWindow = window.open('about:blank');

        // const paymentWindow = window.open('https://ims-customization.d8dvucg91qcfk.amplifyapp.com/withdrawmethod');
        // const paymentWindow = window.open('https://wupotest.ngrok.io/withdrawmethod');

        creditInformation.creditXrplAccount = clientXrplAddress;
        creditInformation.creditClassicXrplAccount = clientClassicXrplAddress;
        creditInformation.creditXrplSecret = clientXrplSecret;
        creditInformation.lender = lender;

        // console.log(creditInformation);

        postCredit(creditInformation, loginUser.jwtToken).then((loanResponse) => {
            
            loanDisbursement.source.address = WUPOADDRESS;
            loanDisbursement.source.maxAmount.counterparty = WUPOADDRESS;
            // loanDisbursement.source.maxAmount.currency = creditInformation.asset;
            loanDisbursement.source.maxAmount.currency = loanResponse.asset;
            // loanDisbursement.source.maxAmount.value = String(creditInformation.initialBalance);
            loanDisbursement.source.maxAmount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(0));

            // loanDisbursement.destination.address = visitorAccounts[0].xrplAddress;
            loanDisbursement.destination.address = loanResponse.walletAddress;
            loanDisbursement.destination.amount.counterparty = WUPOADDRESS;
            // loanDisbursement.destination.amount.currency = creditInformation.asset;
            loanDisbursement.destination.amount.currency = loanResponse.asset;
            // loanDisbursement.destination.amount.value = String(creditInformation.initialBalance);
            loanDisbursement.destination.amount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(0));

            if(loanResponse === "Error"){
                // paymentWindow.close();
                setLoading(false);
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    // text: operationUnsuccesful,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                }); 
            }

            else if(loanResponse === "invalid email"){
                
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    text: `No pudimos enviarte los documentos al correo ingresado. 
                    
                    Por favor revisa tu información y comunícate con nostros. `,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                });
            }

            else{
                
                //IMS disbursement
                console.log("*******Acá estoy")
                console.log(loanResponse.thirdPartyWithdrawLink);
                // paymentWindow.document.location.href = loanResponse.thirdPartyWithdrawLink;
                
                // window.open(loanResponse.thirdPartyWithdrawLink);

                //XRPL disbursement, only for clients different from IMS
                if(b2bClient && b2bClient !== "IMS"){
                    xrpPayment(loanDisbursement, "", "", WUPOSECRET).then((paymentResult) => {
                        setLoading(false);

                        if(paymentResult === "payment ok"){
                        
                            //Loan disbursment statement
                            const disbursedStatement = {
                                accountId: loginUser.accountId + creditInformation.asset,
                                account: loginUser.accountId,
                                asset: creditInformation.asset,
                                value: loanDisbursement.destination.amount.value,
                                transaction: "credit",
                                description: "Desembolso",
                            }
                        
                
                            prepareStatement(disbursedStatement, loginUser.jwtToken).then(statementResult =>{
                            // console.log(statementResult); // DEBUG PRINTING
                            });

                            swal({
                                title: "DESEMBOLSO EN PROCESO",
                                text: `Listo! Platica Yaap. 
                                
                                Así de fácil. Felicitaciones!

                                Por favor sigue las instricciones de nuestro socio ComboPay para retirar tu dinero.

                                Recibirás en tu correo electrónico registrado los documentos fimados electrónicamente (revisa correos no deseados)`,
                                icon: "success",
                                button: "Súper",
                            }).then(() => {
                                loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                                    history.push({pathname: "/credit/handle_credit"});
                                });
                            });
                        }
                        else{
                            swal({
                                title: "NO PUDIMOS CREAR TU PRODUCTO",
                                // text: operationUnsuccesful,
                                icon: "error",
                                button: "Entiendo",
                            }).then(() => {
                                history.push({pathname: "/account/create_credit"});
                            }); 
                        }
                    })
                }

                else{
                     //Loan disbursment statement
                     const disbursedStatement = {
                        accountId: loginUser.accountId + creditInformation.asset,
                        account: loginUser.accountId,
                        asset: creditInformation.asset,
                        value: loanDisbursement.destination.amount.value,
                        transaction: "credit",
                        description: "Desembolso",
                    }
        
                    prepareStatement(disbursedStatement, loginUser.jwtToken).then(statementResult =>{
                    // console.log(statementResult); // DEBUG PRINTING
                    });

                    swal({
                        title: "DESEMBOLSO EN PROCESO",
                        text: `Listo! Platica Yaap. 
                        
                        Así de fácil. Felicitaciones!

                        Por favor sigue las instrucciones de nuestro socio ComboPay para retirar tu dinero.

                        Recibirás en tu correo electrónico registrado los documentos fimados electrónicamente (revisa correos no deseados)`,
                        icon: "success",
                        button: "Súper",
                    }).then(() => {
                        loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                            history.push({pathname: "/credit/handle_credit"});
                        });
                    });
                }
            }
        });
    }

    function postRevolvingLoanToBack(){
        setLoading(true);

        creditInformation.lender = lender;

        // console.log(creditInformation);

        postRevolvingCredit(creditInformation, loginUser.jwtToken).then((loanResponse) => {
            
            

            if(loanResponse === "Error"){
                setLoading(false);
                swal({
                    title: "NO PUDIMOS CREAR TU PRODUCTO",
                    // text: operationUnsuccesful,
                    icon: "error",
                    button: "Entiendo",
                }).then(() => {
                    history.push({pathname: "/account/create_credit"});
                }); 
            }

            else{

                loanDisbursement.source.address = WUPOADDRESS;
                loanDisbursement.source.maxAmount.counterparty = WUPOADDRESS;
                // loanDisbursement.source.maxAmount.currency = creditInformation.asset;
                loanDisbursement.source.maxAmount.currency = loanResponse.asset;
                // loanDisbursement.source.maxAmount.value = String(creditInformation.initialBalance);
                loanDisbursement.source.maxAmount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(2));
    
                // loanDisbursement.destination.address = visitorAccounts[0].xrplAddress;
                loanDisbursement.destination.address = loanResponse.walletAddress;
                loanDisbursement.destination.amount.counterparty = WUPOADDRESS;
                // loanDisbursement.destination.amount.currency = creditInformation.asset;
                loanDisbursement.destination.amount.currency = loanResponse.asset;
                // loanDisbursement.destination.amount.value = String(creditInformation.initialBalance);
                loanDisbursement.destination.amount.value = String(Number(loanResponse.balanceToBeDisbursed).toFixed(2));

                xrpPayment(loanDisbursement, "", "", WUPOSECRET).then((paymentResult) => {
                    setLoading(false);
                    
                    if(paymentResult === "payment ok"){
                        swal({
                            title: "DESEMBOLSO EXITOSO",
                            text: `Listo! Ya tienes los tokens en tu cuenta. 
                            
                            Sí, así de fácil. Felicitaciones!
                            
                            Recibiras en tu correo electrónico registrado los documentos fimados electrónicamente (revisa correos no deseados)`,
                            icon: "success",
                            button: "Súper",
                        }).then(() => {
                            loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then(() => {
                                history.push({pathname: "/credit/handle_credit"});
                            });
                        });
                    }

                    else{
                        swal({
                            title: "NO PUDIMOS CREAR TU PRODUCTO",
                            // text: operationUnsuccesful,
                            icon: "error",
                            button: "Entiendo",
                        }).then(() => {
                            history.push({pathname: "/account/create_credit"});
                        }); 
                    }
                })

            }
        });
    }

    function handleBalanceInput(inputValues){
        
        setDisableTerm(false);

        setBalanceInputValue(inputValues.target.value);
        creditInformation.initialBalance = inputValues.target.value;

        setLoanAmount(inputValues.target.value)
        calculationVariables.loanAmount = inputValues.target.value;
        // creditValueConditions.minValue.loanAmount = inputValues.target.value;

        calculateBalanceModification();
    }

    async function handleTermSelect(e){
        setTerm(e.target.value);
        calculationVariables.term = e.target.value;
        setDisableDues(false);
        
        creditInformation.term = e.target.value;

        await dailyInterestCalc();

        setDisableButton(false);

        calculateTermModification();

    }

    function handleDuesSelect(e){
        setDues(e.target.value);
        // console.log(e.target.value)
        setDisableButton(false);
        creditInformation.dues = e.target.value;

    }

    const calculateBalanceModification = async () =>{

        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;

        
        calculationVariables.platfromDiscount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(calculationVariables.platfromDiscount);
 
        calculationVariables.warrantyDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;
        
        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
        
    };

    const calculateTermModification = async () =>{
        
        creditCostsConditions.costsWithoutVAT = creditCostsConditions.costsWithoutVATFee * calculationVariables.loanAmount;
        // console.log(`costsWithoutVAT: ${creditCostsConditions.costsWithoutVAT}`)
        

        calculationVariables.platfromDiscount = await calculateWithVatTotalDiscount();
        setPlatfromDiscount(calculationVariables.platfromDiscount);

        // calculationVariables.warrantyDiscount = await calculateNoVATTermDiscount();
        calculationVariables.warrantyDiscount = await calculateNoVatTotalDiscount();
        setWarrantyDiscount(calculationVariables.warrantyDiscount);

        VATCalculation();

        disbursedBalanceBeforeInterests = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        - (creditCostsConditions.costsWithVAT - platfromDiscount) -calculationVariables.vatTax;

        await handleInterests();

        calculationVariables.totalPayment = calculationVariables.loanAmount + (creditCostsConditions.costsWithoutVAT - calculationVariables.warrantyDiscount) 
        + (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) + calculationVariables.vatTax + calculationVariables.interestState;
        
        calculationVariables.disbursedBalance = calculationVariables.loanAmount - (creditCostsConditions.costsWithoutVAT - warrantyDiscount) 
                    - (creditCostsConditions.costsWithVAT - calculationVariables.platfromDiscount) -calculationVariables.vatTax - calculationVariables.interestState;

        if(creditInstallmentConditions.paymentType === "prepaid"){
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }else if(creditInstallmentConditions.paymentType === "postpaid"){
            setDisbursedBalanceState(calculationVariables.totalPayment );
        }else{
            setDisbursedBalanceState(calculationVariables.disbursedBalance );
        }

        setMargenState((calculationVariables.loanAmount -  calculationVariables.disbursedBalance)/ calculationVariables.loanAmount);
        // console.log(margen)
    };
    
    const  calculateWithVATTermDiscount = () => {
        
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        const minimumCostsWithVATFee = creditCostsConditions.costsWithVATminimum * creditCostsConditions.costsWithVAT;
        const remainingCostsWithVAT = creditCostsConditions.costsWithVAT - minimumCostsWithVATFee;
        const discountValue = remainingCostsWithVAT - (remainingCostsWithVAT * termSteps * currentTermSteps);
        // console.log(`discount value with VAT ${discountValue}`); //DEBUG

        //TODO: new way of calculation
        const stepDiscount = currentTermSteps * creditCostsConditions.platformTimeDiscount;
        
        console.log(currentTermSteps);
        console.log(stepDiscount);

        // return new Promise((resolve) => {
        //     resolve(discountValue);
        // })

        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateWithVATBalanceDiscount = () => {
        const currentBalanceSteps = (creditValueConditions.maxValue - creditValueConditions.minValue)/creditValueConditions.interval 
        - (creditValueConditions.maxValue - calculationVariables.loanAmount)/creditValueConditions.interval;

        const stepDiscount = currentBalanceSteps * creditCostsConditions.platformBalanceDiscount;
        
        console.log(currentBalanceSteps);
        console.log(stepDiscount);
        
        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateWithVatTotalDiscount = async () => {

        const termDiscount = await calculateWithVATTermDiscount();
        const balanceDiscount = await calculateWithVATBalanceDiscount();

        let stepDiscountVat = 0;

        if(creditCostsConditions.costsWithVATminimum < 1){
            stepDiscountVat = (1 - creditCostsConditions.costsWithVATminimum) - termDiscount - balanceDiscount;
        }

        const finalDiscount = stepDiscountVat * creditCostsConditions.costsWithVAT;

        console.log(termDiscount)
        console.log(balanceDiscount)
        console.log(stepDiscountVat)
        console.log(finalDiscount)

        return new Promise((resolve) => {
            resolve(finalDiscount);
        });
    };

    const calculateNoVATTermDiscount = (noTaxesCost) => {
       
        const termSteps = 1/(((creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval));
        const currentTermSteps = (creditTermConditions.maxTerm - creditTermConditions.minTerm)/creditTermConditions.interval 
        - (creditTermConditions.maxTerm - calculationVariables.term)/creditTermConditions.interval;
        let minimumCostsWithoutVATFee;
        let remainingCostsWithoutVAT;

        if(noTaxesCost){
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * noTaxesCost; 
            remainingCostsWithoutVAT = noTaxesCost - minimumCostsWithoutVATFee;  
        }else{
            minimumCostsWithoutVATFee = creditCostsConditions.costsWithoutVATminimum * creditCostsConditions.costsWithoutVAT;
            remainingCostsWithoutVAT = creditCostsConditions.costsWithoutVAT - minimumCostsWithoutVATFee;
        }
        const discountValue = remainingCostsWithoutVAT - (remainingCostsWithoutVAT * termSteps * currentTermSteps);

        //TODO: new way of calculation
        const stepDiscount = currentTermSteps * creditCostsConditions.warrantyTimeDiscount;
        
        // console.log(currentTermSteps);
        // console.log(stepDiscount);

        // console.log(`discount value without VAT ${discountValue}`); //DEBUG
        // return new Promise((resolve) => {
        //     resolve(discountValue);
        // });

        return new Promise((resolve) => {
            resolve(stepDiscount);
        });
    };

    const calculateWithoutVATBalanceDiscount = () => {
        const currentBalanceSteps = (creditValueConditions.maxValue - creditValueConditions.minValue)/creditValueConditions.interval 
        - (creditValueConditions.maxValue - calculationVariables.loanAmount)/creditValueConditions.interval;

        const stepDiscount = currentBalanceSteps * creditCostsConditions.warrantyBalanceDiscount;
        
        // console.log(currentBalanceSteps);
        // console.log(stepDiscount);
       
        return new Promise((resolve) => {
            resolve(stepDiscount);
        })
    };

    const calculateNoVatTotalDiscount = async () => {

        const termDiscount = await calculateNoVATTermDiscount();
        const balanceDiscount = await calculateWithoutVATBalanceDiscount();

        let stepDiscountNoVat = 0;

        if(creditCostsConditions.costsWithoutVATminimum < 1){
            stepDiscountNoVat = (1 - creditCostsConditions.costsWithoutVATminimum) - termDiscount - balanceDiscount;
        }

        const finalDiscount = stepDiscountNoVat * creditCostsConditions.costsWithoutVAT;

        // console.log(termDiscount)
        // console.log(balanceDiscount)
        // console.log(stepDiscount)
        // console.log(finalDiscount)

        return new Promise((resolve) => {
            resolve(finalDiscount);
        });
    };

    /**
     * Loan conditions dialog
     */

    const [conditions, setConditions] = React.useState(false);

    const handeConditions = ()=>{
        setConditions(true);
    };
    
    const handeConditionsClose = ()=>{
        setLoading(false);
        setConditions(false);
    };

    const handeAcceptConditions = ()=>{
        setConditions(false);

        handleAgreementsDialog(); //UNDER CONSTRUCTION

        // if(visitorLoans !== "Not found"){
        // if(visitorLoans){
        //     postRevolvingLoanToBack()        
        // }
        // else{
        //     createCreditXrplAccount();
        // }
    };

    const [balanceInputValue, setBalanceInputValue] = React.useState("");

    const [agreements, setAgreements] = React.useState(false);

    const handleAgreementsDialog = () =>{
        setAgreements(true);
    }

    const closeAgreementsDialog = () => {
        setAgreements(false);
        setLoading(false);
    }

    const handleAgreements = () => {
        setLoading(true)
        setAgreements(false);
        setDisbursementType(false);

        if(visitorLoans){
            console.log("Revolving loan")
            postRevolvingLoanToBack()        
        }
        else{
            // createCreditXrplAccount();
            console.log("Single loan")
            postCreditToBack();
        }

    }

    const valuetext = (value) => {
        // console.log(`${value} pesos`)
        return `${value} pesos`;
    }

    //Disbursement options dialogue
    const[disbursementType, setDisbursementType] = React.useState(false);

    const disbursementTypeOpen = () => {
        setDisbursementType(true);
    };
    
    const disbursementTypeClose = () => {
        setDisbursementType(false);
    };


    //transfer disbursement dialogue

    
    const[bankCode, setBankCode] = React.useState("");
    const[bankName, setBankName] = React.useState("");
    const[bankAccountType, setBankAccountType] = React.useState("");
    const[bankAccountNumber, setBankAccountNumber] = React.useState("");
    const[transferInfo, setTransferInfo] = React.useState(false);

    const[transferDisabled, setTransferDisabled] = React.useState(true);
    const[bankCodeDisabled, setBankCodeDisabled] = React.useState(true);
    const[bankAccountTypeDisabled, setBankAccountTypeDisabled] = React.useState(true);
    const[bankAccountNumberDisabled, setBankAccountNumberDisabled] = React.useState(true);

    const transferInfoOpen = () => {
        setLoading(true);
        setAgreements(false);
        verifyBankAccount(loginUser.accountId, loginUser.jwtToken).then((bankAccountPersisted) => {
            if(bankAccountPersisted !== "Not found"){
                const bank = bankCodes.find((account) => account.code === bankAccountPersisted.bankCode);
                setBankName(bank);
                setBankCode(bank.code);
                setBankAccountType(bankAccountPersisted.bankAccountType);
                setBankAccountNumber(bankAccountPersisted.bankAccount);
            }else{
                setBankCodeDisabled(false);
                setBankAccountTypeDisabled(false);
                setBankAccountNumberDisabled(false);
            }
            disbursementTypeClose();
            setTransferInfo(true);
            setLoading(false);
        });
    }; 

    const transferInfoClose = () => {
        setTransferInfo(false);
    };

    React.useEffect(() => {

        if(bankCode && bankAccountType && bankAccountNumber){
            setTransferDisabled(false);
        }else{
            setTransferDisabled(true);
        }

    }, [bankCode, bankAccountType, bankAccountNumber]);

    const handleBankAccount = () => {
        creditInformation.transactionType = "bankTransfer";
        creditInformation.bankCode = bankCode;
        creditInformation.bankAccountType = bankAccountType;
        creditInformation.bankAccount = bankAccountNumber;

        handleAgreements();
        setTransferInfo(false);
    };

    const changeBankCode = (e) => {
        console.log(e.target.value)
        setBankCode(e.target.value.code);
        setBankName(e.target.value);
    }

    return (
        <>
        <Box className={classes.svgBackground} sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%",}}>
            <div
                style={{
                display: "flex",
                flexGrow: "1",
                justifyContent: "center",
                }}
            >
                <ContentForm>
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <>
                    <PageLabel>Plata al Instante</PageLabel>

                        <Box sx={{ width: 300, textAlign: "center" }}>
                        <Typography gutterBottom>
                            {creditInstallmentConditions.paymentType === "postpaid" ? "Cuánto necesitas":"Valor a pagar"}
                        </Typography>
                            <Slider
                                aria-label="Amount"
                                // defaultValue={150000}
                                value={loanAmount}
                                getAriaValueText={valuetext}
                                step={creditValueConditions.interval}
                                marks={marksBalance}
                                valueLabelDisplay="on"
                                min={creditValueConditions.minValue}
                                max={creditValueConditions.maxValue}
                                onChange={handleBalanceInput}
                            />
                        </Box>

                        <Box sx={{ width: 300, textAlign: "center" }}>
                        <Typography gutterBottom>Plazo (días)</Typography>
                            <Slider
                                aria-label="installments"
                                // defaultValue={1}
                                value={term}
                                getAriaValueText={valuetext}
                                step={creditTermConditions.interval}
                                marks={marksTerm}
                                valueLabelDisplay="on"
                                min={creditTermConditions.minTerm}
                                max={creditTermConditions.maxTerm}
                                onChange={handleTermSelect}
                            />
                        </Box>

                        {creditInstallmentConditions.amountInstallments > 1 ? 
                            <Box sx={{ width: 300 }}>
                            <Typography gutterBottom>Cuotas (cantidad de pagos)</Typography>
                                <Slider
                                    aria-label="installments"
                                    // defaultValue={1}
                                    value={dues}
                                    getAriaValueText={valuetext}
                                    step={1}
                                    marks={marksInstallments}
                                    valueLabelDisplay="on"
                                    min={1}
                                    max={creditInstallmentConditions.amountInstallments}
                                    onChange={handleDuesSelect}
                                />
                            </Box>
                        : <></>
                        }
                        <div style={{maxWidth: "300px", textAlign: "start"}}>
                            <Grid container>
                            <Grid item xs={8} sm={8} md={8}>
                                    <Typography  style={{fontSize:"0.95rem"}}>
                                        {creditInstallmentConditions.paymentType === "postpaid" ? "Cuánto necesitas":"Valor a pagar"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} >
                                    <Typography className={classes.dialogStyle} style ={{color:"red", fontWeight:"bold", fontSize:"0.9rem"}}>
                                        {/* {`$ ${new Intl.NumberFormat('es-CO').format(creditInformation.initialBalance)}`} */}
                                        {`$ ${new Intl.NumberFormat('es-CO').format(loanAmount)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} >
                                    <Tooltip title={disbursedText} placement="top-end">
                                        <Typography>
                                            <BsInfoCircle size={13}/>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                {isAdmin ?
                                    <>
                                        <Grid item xs={8} sm={8} md={8}>
                                            <Typography  style={{fontWeight:"bold", fontSize:"0.95rem"}}>
                                                Margen:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} sm={3} md={3} >
                                            <Typography className={classes.dialogStyle} style={{color:"black", fontWeight:"bold", fontSize:"0.95rem"}}>
                                                {`$ ${new Intl.NumberFormat('es-CO', {
                                                    maximumFractionDigits: 0,
                                                }).format(margenState * 100)}%`}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1} sm={1} md={1} >
                                            {/* <Tooltip title={rateText} placement="top-end"> */}
                                                <Typography>
                                                    <BsInfoCircle size={13}/>
                                                </Typography>
                                            {/* </Tooltip> */}
                                        </Grid>
                                    </>
                                    : <></>
                                }            
                            
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography  display="inline" style={{fontSize:"0.95rem"}}>
                                        {`Intereses`}
                                    </Typography>
                                    <Typography  display="inline" style={{fontSize: "0.6em"}}>
                                        {` (${Math.round(creditInterestConditions.interestRate*100)}% EM) `}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} >
                                    <Typography className={classes.dialogStyle} style={{fontSize:"0.95rem"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(interestState)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} >
                                    <Tooltip title={rateText} placement="top-end">
                                        <Typography>
                                            <BsInfoCircle size={13}/>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                { creditCostsConditions.costsWithoutVATFee > 0 ?
                                    <>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography  style={{fontSize:"0.95rem"}}>
                                            Servicio de Fianza (FG)
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle}>
                                            {/* {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * creditInformation.initialBalance))}`} */}
                                            {`$ ${new Intl.NumberFormat('es-CO').format((creditCostsConditions.costsWithoutVATFee * loanAmount))}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={warrantyText} placement="top-end">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    {  warrantyDiscount > 0 ?
                                        <>
                                        <Grid item xs={8} sm={8} md={8}>
                                            <Typography  style={{fontSize:"0.95rem"}}>
                                                Descuento Fiador:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={4} >
                                            <Typography className={classes.dialogStyle} style={{color: "blue", fontSize:"0.95rem"}}>
                                                {`$ ${new Intl.NumberFormat('es-CO',
                                                {
                                                    maximumFractionDigits: 0,
                                                }).format(warrantyDiscount * -1)}`}
                                            </Typography>
                                        </Grid>
                                        </>
                                    :   <></>}
                                    </>
                                : <></>
                                }

                                { creditCostsConditions.costsWithVAT > 0 ?
                                    <>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography  style={{fontSize:"0.95rem"}}>
                                            Costo Plataforma:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle} style={{fontSize:"0.95rem"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO').format(creditCostsConditions.costsWithVAT)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={platformText} placement="top-end">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography  style={{fontSize:"0.95rem"}}>
                                        Descuento Plataforma:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle} style={{color: "blue", fontSize:"0.95rem"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO',
                                            {
                                                maximumFractionDigits: 0,
                                            }).format(platfromDiscount * -1)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        <Tooltip title={platformDiscountText} placement="top-end">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={8} sm={8} md={8}>
                                        <Typography  display="inline" style={{fontSize:"0.95rem"}}>
                                            {`IVA `}
                                        </Typography>
                                        <Typography  display="inline" style={{fontSize:"0.6rem"}}>
                                            {`(${VAT * 100}%)`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} >
                                        <Typography className={classes.dialogStyle}>
                                            {`$ ${new Intl.NumberFormat('es-CO', {
                                                maximumFractionDigits: 0,
                                            }).format(vatTax)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} >
                                        {/* <Tooltip title={vatText} placement="top-end">
                                            <Typography>
                                                <BsInfoCircle size={13}/>
                                            </Typography>
                                        </Tooltip> */}
                                    </Grid>
                                    </>
                                : <></>
                                }
                                <Grid item xs={8} sm={8} md={8}>
                                    <Typography  style={{fontWeight:"bold", fontSize:"0.95rem"}}>
                                        {creditInstallmentConditions.paymentType === "postpaid" ? "Total":"Cuánto necesitas"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} sm={3} md={3} >
                                    <Typography className={classes.dialogStyle} style={{color:"black", fontWeight:"bold", fontSize:"0.9rem"}}>
                                        {`$ ${new Intl.NumberFormat('es-CO', {
                                            maximumFractionDigits: 0,
                                        }).format(disbursedBalanceState)}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} >
                                    <Tooltip title={rateText} placement="top-end">
                                        <Typography>
                                            <BsInfoCircle size={13}/>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </div>
                    <AppButton 
                        primary 
                        width={"40%"}
                        onClick={handleInputCreditInformation}
                        // disabled={disableButton}
                        >
                            ACEPTO
                    </AppButton>
                    </>
                }
                </ContentForm>
            </div>
        </Box>
            <>
            <Dialog open={conditions} onClose={handeConditionsClose} aria-labelledby="form-dialog-tokens" >
                <DialogTitle id="form-dialog-title3">DETALLE PRÉSTAMO</DialogTitle>
                <DialogContent className={classes.dialogContainer}>
                <DialogContentText className={classes.typography}>
                    Cuando y cuanto pagas
                </DialogContentText>
                   {conditionsArray.map((condition)=>{
                       return(
                        <>
                        <Grid container spacing={1} key = {condition.installmentDate+1}>
                            <Grid item xs={6} sm={6} md={6} key = {condition.installmentDate+2}>
                                <Typography key = {condition.installmentDate+3} >
                                    Fecha: {new Intl.DateTimeFormat("es-CO", dateOptions).format(new Date(condition.installmentDate))}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} key = {condition.installmentDate+4}>
                                <Typography className={classes.dialogStyle} key = {condition.installmentDate+5}>
                                    Pago: {new Intl.NumberFormat('es-CO', {maximumFractionDigits: 0,}).format(condition.installmentValue)}
                                </Typography>
                            </Grid>
                        </Grid>
                        </>
                        )
                   })} 
                   <DialogContentText className={classes.dialogStyle}>
                        {" "}
                    </DialogContentText>
                   <DialogContentText className={classes.typography}>
                        Detalle
                    </DialogContentText>
                   <Grid container spacing={1}>
                        {creditInstallmentConditions.paymentType === "postpaid" ?
                            <>
                                <Grid item xs={6} sm={6} md={6}>
                                    <Typography>
                                        {/* Fecha: {conditionsArray[conditionsArray.length-1].installmentDate} */}
                                        Intereses
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4}>
                                    <Typography className={classes.dialogStyle}>
                                        Total: {new Intl.NumberFormat('es-CO').format(creditCost)}
                                    </Typography>
                                </Grid>
                            </>
                            :<></>
                        }
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography>
                                {/* Fecha: {conditionsArray[conditionsArray.length-1].installmentDate} */}
                                Tasa de interés EA:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography className={classes.dialogStyle}>
                                {new Intl.NumberFormat('es-CO').format(creditInterestRate*100)}%
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handeConditionsClose} color="primary">
                        ATRÁS
                    </Button>
                    <Button onClick={handeAcceptConditions}>
                        <Typography style = {{color: Colors.primary, fontSize: "0.9rem"}}>
                            ACEPTO
                        </Typography>
                    </Button>
                </DialogActions>


            </Dialog>
            <Dialog open={agreements} onClose={closeAgreementsDialog} aria-labelledby="form-dialog-tokens" >
                <DialogTitle id="form-dialog-title3">ACUERDOS</DialogTitle>
                <DialogContent className={classes.dialogContainer}>
                    <a 
                        // href={`${wupoServerUri.devnet}/contrato-credito.pdf`}
                        href={`${wupoServerUri.devnet}/contrato-platicapp.pdf`}
                        target='_blank' rel='noopener noreferrer'
                        style={{cursor: "pointer", color: Colors.fourth}}
                    >
                        Contrato de Préstamo
                    </a>
                    <DialogContentText className={classes.dialogStyle}>
                        {" "}
                    </DialogContentText>
                    <a 
                        // href={`${wupoServerUri.devnet}/Pagare.pdf`}
                        href={`${wupoServerUri.devnet}/pagare-instrucciones-platicapp.pdf`}
                        target='_blank' rel='noopener noreferrer'
                        style={{cursor: "pointer", color: Colors.fourth, marginTop: "2rem"}}
                    >
                        Pagaré y Carta de Instrucciones
                    </a>
                    <DialogContentText className={classes.dialogStyle}>
                        {" "}
                    </DialogContentText>
                    <a 
                        // href={`${wupoServerUri.devnet}/Pagare.pdf`}
                        href={`${wupoServerUri.devnet}/acuerdo-fg.pdf`}
                        target='_blank' rel='noopener noreferrer'
                        style={{cursor: "pointer", color: Colors.fourth, marginTop: "2rem"}}
                    >
                        Acuerdo Fondo de Garantía
                    </a>
                    <DialogContentText className={classes.dialogStyle}>
                        {" "}
                    </DialogContentText>
                     <DialogContentText style={{marginTop: "2rem"}}>
                        Si leíste y aceptas los acuerdos por favor oprime el botón acepto.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAgreementsDialog} color="primary">
                        ATRÁS
                    </Button>
                    <Button onClick={disbursementTypeOpen}>
                        <Typography style = {{color: Colors.primary, fontSize: "0.9rem"}}>
                            ACEPTO
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={disbursementType} onClose={disbursementTypeClose}>
            <DialogTitle style={{color: "blue"}}>¿CÓMO QUIERES RECIBIR LA PLATA? </DialogTitle>
            <DialogContent className={classes.actionArea}>
                <Card sx={{ minWidth: 150 }}>
                    <CardActionArea onClick={transferInfoOpen}>    
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Transferencia Bancaria
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </DialogContent>

            <DialogContent className={classes.actionArea}>
              <Card sx={{ minWidth: 150 }}>
                <CardActionArea onClick={handleAgreements}>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      Retiro en Efectivo
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </DialogContent>
          </Dialog>

          <Dialog open={transferInfo} onClose={transferInfoClose}>
            <DialogTitle style={{color: "blue"}}>CUENTA BANCARIA</DialogTitle>
            <DialogContent>
                <Typography variant="body2" style={{whiteSpace: 'pre-line', color: "GrayText"}} >
                    {bankAccountExp}
                 </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl disabled={bankCodeDisabled} className={classes.formControl} fullWidth>
                            <InputLabel id="bank">Escoge tu banco</InputLabel>
                            <Select
                                labelId="Escoge tu banco"
                                id="bank"
                                value={bankName}
                                name="bank"
                                onChange={changeBankCode}
                            >
                                {bankCodes.map((bankCode) => {
                                    return(
                                        <MenuItem key ={bankCode.code} value = {bankCode}>
                                            {`${bankCode.bank}`} 
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                            
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <FormControl disabled={bankAccountTypeDisabled} className={classes.formControl} fullWidth>
                            <InputLabel id="bankAccountType">Tipo de cuenta</InputLabel>
                            <Select
                                required
                                labelId="Tipo de cuenta"
                                id="bankAccountType"
                                value={bankAccountType}
                                name="bankAccountType"
                                onChange={(e) => setBankAccountType(e.target.value)}
                            >
                                <MenuItem value={7}>Cuenta de Ahorros</MenuItem>
                                <MenuItem value={1}>Cuenta Corriente</MenuItem>
                                <MenuItem value={9}>Depósito Electrónico</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            disabled={bankAccountNumberDisabled}
                            style={{marginTop: "1rem"}}
                            id="bankAccount"
                            name="bankAccount"
                            label="Ingresa tu cuenta bancaria"
                            fullWidth
                            // autoComplete="given-name"
                            onChange={(e) => setBankAccountNumber(e.target.value)}
                            value={bankAccountNumber}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={transferInfoClose} color="primary">
                    ATRÁS
                </Button>
                <Button 
                    onClick={handleBankAccount} color="grey" 
                    disabled={transferDisabled}>
                    CONTINUAR
                </Button>
            </DialogActions>
          </Dialog>
          
          </>
        </>
    )
}
