import {wupoServerUri} from '../Api';

const createOpenCardProduct = async(requestObject, jwtToken) => {
  

    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/clientOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const getProductInfo = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/cardInfoOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const product = await response.json();
      console.log(product);
  
      return product;
    }
  
    else {
      return null;
    }
}

const createCardPayment = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/paymentOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const payment = await response.json();
      console.log(payment);
  
      return payment;
    }
  
    else {
      return null;
    }
}

const getCardStatement = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/transactionsOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const transactions = await response.json();
      console.log(transactions);
  
      return transactions;
    }
  
    else {
      return null;
    }
}

const purchaseAuth = async(requestObject, jwtToken) => {
  
    console.log(requestObject);
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/authOpenCard`, {
        method: "POST",
        body: JSON.stringify(requestObject),
        credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });
  
    console.log(response.status);
    if(response.status === 200 ){
      const authResponse = await response.json();
      console.log(authResponse);
  
      return authResponse;
    }
  
    else {
      return null;
    }
}

  export{ createOpenCardProduct, getProductInfo, createCardPayment, getCardStatement, purchaseAuth }