import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../components/Input";
import Content from "../../layout/Content";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";

const RippleAPI = require("ripple-lib").RippleAPI;

const api = new RippleAPI({
  server: "wss://s.altnet.rippletest.net:51233", // Public rippled server hosted by Ripple, Inc.
});

export default function HandleEscrowExecution(props) {
  const history = useHistory();
  const { user, ledgerAccounts } = props;

  const userFromUserHandler = user.account;
  const tokenFromUserHandler = user.accountIdToken;
  const assetFromAccountHandler = props.match?.params?.asset;

  const [inputFromChild, setInputFromChild] = useState();
  const [eraseLabel, setEraseLabel] = useState(false);

  // Input and label variables
  const labelsArray = useRef([
    "CREADOR",
    "SECUENCIA",
    "CONDICIÓN",
    "LLAVE",
    "DESTINATARIO",
  ]);
  let labelIndex = useRef(0);

  //XRPL client's, receiver and Wupo's information
  const WUPOILPACCOUNT = "wupoHotWallet"; //operational ilp AccountId used by Wupo
  const clientXrplAddress = useRef();
  const clientXrplToken = useRef();
  const destinationXrplAccount = useRef();

  //ILP AccountId of the receiver.
  //Fix me: This is used to fetch the xrpl address from the receiver. It should be calculated from the ledger response,
  //but the returned address correesponds to the old ones and there is not an specific method to derivated. Cahnging the options from the account creation
  //should be enough
  const receiverAccountId = useRef();

  const escrowExecution = useRef({
    owner: "", //Input
    escrowSequence: "", //Input
    condition: "", //"2020-12-26T16:26:00.000-05:00", //Input. Optional. Should be presented as optional
    fulfillment: "", //"2020-10-17T19:22:00.000-05:00", //Input Optional. Should be presented as optional
  });

  //State that determines the rendering
  const [cardRender, setCardRender] = useState();
  //Fix me: Utility condition to deterine if the fetch has concluded. Change for a better async handling
  const fetchConcluded = useRef(false);

  useEffect(() => {
    setEraseLabel(false);
  }, [eraseLabel]);

  useEffect(() => {
    if (fetchConcluded.current === false) {
      setCardRender(
        <Input
          onChangeInfo={(e) => setInputFromChild(e.target.value)}
          eraseInput={eraseLabel}
        />
      );
    }
  }, [eraseLabel]);

  function xrplExecuteEscrow() {
    console.log("YA POR ACÁ EN EXECUTE ESCROW!!!!!");
    ledgerAccounts.forEach((element) => {
      //Takes the values for the logged user and not from the owner value input in this component
      //to allow any client (i.e. the escrow receiver) to settle the escrow
      if (element.accountId === userFromUserHandler + assetFromAccountHandler) {
        clientXrplAddress.current = element.xrplAddress;
        clientXrplToken.current = element.xrplAddressSecret; //review
        console.log("Client's address: " + clientXrplAddress.current);
        console.log("Client's token: " + clientXrplToken.current);
      }
      if (
        element.accountId === escrowExecution.current.owner ||
        element.accountId.slice(0, element.accountId.length - 3) ===
          escrowExecution.current.owner
      ) {
        escrowExecution.current.owner = element.xrplAddress;
        console.log("owner's xrpl address: " + escrowExecution.current.owner);
      }
      if (
        element.accountId.slice(0, element.accountId.length - 3) ===
          receiverAccountId.current ||
        element.accountId === receiverAccountId.current
      ) {
        destinationXrplAccount.current = element.xrplAddress;
        console.log("destination address: " + destinationXrplAccount.current);
      }
    });

    console.log(
      "escrow execution Json: " + JSON.stringify(escrowExecution.current)
    );
    //Create the escrow in the XRPL

    api.on("error", (errorCode, errorMessage) => {
      console.log(errorCode + ": " + errorMessage);
    });

    api.connect().then(() => {
      api
        .prepareEscrowExecution(
          clientXrplAddress.current,
          escrowExecution.current
        )
        .then((prepare) => {
          console.log("prepare");
          console.log(prepare);
          const { signedTransaction, id } = api.sign(
            prepare.txJSON,
            clientXrplToken.current
          );

          api.submit(signedTransaction).then((result) => {
            console.log("result");
            console.log(result);
            ilpEscrowPayment();
            alert("CONTRATO LIQUIDADO");
          });
        })
        .catch((errorPrepare) => {
          console.log(errorPrepare);
        });
    });
  }

  function ilpEscrowPayment() {}

  function handleInput() {
    if (labelIndex.current < labelsArray.current.length) {
      if (labelIndex.current === 0) {
        escrowExecution.current.owner = inputFromChild;
        console.log("input from child: " + inputFromChild);
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 1) {
        escrowExecution.current.escrowSequence = parseInt(inputFromChild);
        console.log("input from child: " + inputFromChild);
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 2) {
        escrowExecution.current.condition = inputFromChild;
        console.log("input from child: " + inputFromChild);
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 3) {
        escrowExecution.current.fulfillment = inputFromChild;
        console.log("input from child: " + inputFromChild);
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 4) {
        receiverAccountId.current = inputFromChild;
        console.log("input from child: " + inputFromChild);
        labelIndex.current = 0;
        setEraseLabel(true);

        //Call functions for xrpl escrow creation. The ilp payment must be called in Handle Escrow Execution.
        xrplExecuteEscrow();
      }
    }
  }

  return (
    <Content>
      <PageLabel>{labelsArray.current[labelIndex.current]}</PageLabel>
      {cardRender}
      <AppButton primary onClick={handleInput}>
        Next
      </AppButton>
    </Content>
  );
}
