import React from 'react';
import {adminKpiDisbursement, adminKpiPortfolio, 
    adminAllDefaultLoans, adminDefaultLoansByAging} from "../../utilityFunctions/AdminUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors"; 
import { AreaChart, Area, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
    gridInputsDefault:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em",
        marginRight: "3em"
    },
    gridButton:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"1em",
        marginBottom: "0.2em"
    },
    divider:{
        background: Colors.primary,
        marginTop: "1.8em"
    },
    button:{
        background: "none",
        textTransform: "none",
    },
}));


export default function AdminKpi(props) {
    const{loginUser, loadProductsAndSetting} = props

    const history = useHistory();
    const classes = useStyles();

    const[loading, setLoading] = React.useState(false);
    const[disbursementChart, setDisbursementChart] = React.useState('');
    const[portfolioChart, setPortfolioChart] = React.useState('');

    const renderDisbursementChart = (reportData) =>{
        console.log(reportData);
        setDisbursementChart(
            <>
            <Card sx={{width: "90%", marginTop: "2rem", textAlign: "start", }}>
                <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em", paddingLeft: "1.5rem"}}>Balance Desembolsos</Typography>
                <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <ResponsiveContainer width="90%" height={200} >
                        <AreaChart data={reportData} margin={{ top: 15, right: 0, bottom: 5, left: 20 }}>
                            {/* <Line type="monotone" dataKey="disbursedBalance" stroke="#8884d8" /> */}
                            <Area type="monotone" dataKey="disbursedBalance" stroke="#8884d8" fill="#8884d8" />
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                            <XAxis dataKey="weekRange" tick={{fontSize: 12}}/>
                            <YAxis tick={{fontSize: 10}}/>
                            <Tooltip />
                        </AreaChart>
                    </ResponsiveContainer>
                </Box>
            </Card>
            <Card sx={{width: "90%", marginTop: "2rem", textAlign: "start", }}>
                <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em", paddingLeft: "1.5rem"}}>Conteo Desembolsos</Typography>
                <Box sx={{width: "100%", display: "flex", justifyContent: "center"}}>
                    <ResponsiveContainer width="90%" height={200} >
                        <LineChart data={reportData} margin={{ top: 15, right: 0, bottom: 5, left: 20 }}>
                            <Line type="monotone" dataKey="disbursementCount" stroke="#8884d8" />
                            <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                            <XAxis dataKey="weekRange" tick={{fontSize: 12}}/>
                            <YAxis tick={{fontSize: 10}}/>
                            <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Card>
            </>
        );
    };

    const getDefaultLoansByAging = (aging) =>{
        adminDefaultLoansByAging(aging, loginUser.jwtToken).then((defaultLoans) =>{
            console.log(defaultLoans); //DEBUG
            if(defaultLoans !== "Error"){
                history.push({
                    pathname: "/admin-kpi-loans",
                    state: {
                        defaultLoans: defaultLoans,
                    }
                });
            }
        });
    };

    const getAllDefaultLoans = () =>{
        adminAllDefaultLoans(loginUser.jwtToken).then((defaultLoans) => {
            console.log(defaultLoans); //DEBUG
            if(defaultLoans !== "Error"){
                history.push({
                    pathname: "/admin-kpi-loans",
                    state: {
                        defaultLoans: defaultLoans,
                    }
                });
            }
        });
    };

    const renderPortfolioChart = (reportData) =>{
        setPortfolioChart(
            <>
                <Card sx={{width: "80%", marginTop: "1rem"}}>   
                    <Grid container direction="row" sx={{justifyContent: "center", alignItems: "center", paddingLeft: "3.5rem"}}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>
                                Cartera
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="h4" sx={{fontWeight: "bolder"}}>
                                {`${new Intl.NumberFormat('es-CO').format(reportData.relationInitialCurrentBalance * 100)}%`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" component="div">
                                Balance cartera:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`$ ${new Intl.NumberFormat('es-CO').format(reportData.activePortfolio)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2">
                                Balance inicial cartera:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`$ ${new Intl.NumberFormat('es-CO').format(reportData.activePortfolioInitialBalance)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2">
                                Relación cartera / balance inicial:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start", fontWeight: "bold"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`${new Intl.NumberFormat('es-CO').format(reportData.relationInitialCurrentBalance * 100)}%`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>   
                <Card sx={{width: "80%", marginTop: "2rem"}}>
                    <Grid container direction="row" sx={{justifyContent: "center", alignItems: "center", paddingLeft: "3.5rem"}}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>
                                Cartera vencida
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="h4" sx={{fontWeight: "bolder"}}>
                                {`${new Intl.NumberFormat('es-CO').format(reportData.relationDefaultAndActive * 100)}%`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2">
                                Cartera vencida:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`$ ${new Intl.NumberFormat('es-CO').format(reportData.portfolioInDefault)}`}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2">
                            Cartera en mora / Portafolio activo
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`${new Intl.NumberFormat('es-CO').format(reportData.relationDefaultAndActive * 100)}%`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
                <Card sx={{width: "80%", marginTop: "2rem"}}>
                    <Grid container direction="row" sx={{justifyContent: "center", alignItems: "center", paddingLeft: "3.5rem"}}>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", textAlign: "start"}}>
                            <Typography sx={{color: "graytext"}}>
                                Créditos vencidos:
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", textAlign: "start"}}>
                            <Typography onClick={getAllDefaultLoans} gutterBottom variant="h4" sx={{fontWeight: "bolder"}}>
                                {`Total: ${reportData.loansInDefault}`}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={6} sm={6} md={6}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(7)}>
                                Créditos mora mayor a 7 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography gutterBottom variant="body2" component="div">
                                {`${reportData.defaultOver7}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(14)}>
                                Créditos mora mayor a 14 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography gutterBottom variant="body2" component="div">
                                {`${reportData.defaultOver14}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(21)}>
                                Créditos mora mayor a 21 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography gutterBottom variant="body2" component="div">
                                {`${reportData.defaultOver21}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(28)}>
                                Créditos mora mayor a 28 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6}>
                            <Typography gutterBottom variant="body2" component="div">
                                {`${reportData.defaultOver28}`}
                            </Typography>
                        </Grid> */}
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(30)}>
                                Créditos mora mayor a 30 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`${reportData.defaultOver30}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(60)}>
                                Créditos mora mayor a 60 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`${reportData.defaultOver60}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(90)}>
                                Créditos mora mayor a 90 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`${reportData.defaultOver90}`}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Button className={classes.button} onClick={() => getDefaultLoansByAging(180)}>
                                Créditos mora mayor a 180 días:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`${reportData.defaultOver180}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Button className={classes.button}>
                                Créditos Refinanciados:
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", textAlign: "start"}}>
                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>
                                {`${reportData.refinancedLoans}`}
                            </Typography>
                        </Grid> */}
                        
                    </Grid>
                </Card>
            </>
        );
    };

    React.useEffect(() =>{
        setLoading(true);
        adminKpiDisbursement(loginUser.jwtToken).then((reportTable)=>{
            renderDisbursementChart(reportTable);
            setLoading(false);
        });

        adminKpiPortfolio(loginUser.jwtToken).then((portfolioData)=>{
            renderPortfolioChart(portfolioData);   
        });

    }, []);

    const handleClick = () => {
        setLoading(true);
        loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
          if(loadedInfo.visitorLoans){
            history.push({pathname: "/credit/handle_credit"});
            setLoading(false);
          }
    
          else{
            history.push({pathname: "/account/create_credit"});
            setLoading(false);
          }  
        });
    };

    return (
    <div
        style={{
            display: "flex",
            flex: 1,
            // maxWidth: "600px",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            textAlign: "center",
            // marginTop: "1rem",
            backgroundColor: "#ededed",
            // overflow: "scroll"
        }}
    >
        {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
            <>
                <Grid container display="row" spacing={6}>
                    <Grid item xs={12} sm={12} md={6} sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        {disbursementChart}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        {portfolioChart}
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                        <Button
                            variant='contained'
                            onClick={handleClick}
                            sx={{backgroundColor: Colors.primary}}
                        >
                            LISTO
                        </Button>
                    </Grid> */}
                </Grid>
            </>}
    </div>
    )
}
