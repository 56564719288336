import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import NumberFormat from "react-number-format";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {createProduct} from "../../../utilityFunctions/MarketPlaceUtil";
import swal from "sweetalert";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let newCattle = {
    code: "",
    totalValue: "",
    expectedRevenue: "",
    totalWeight: "",
    animalsAmount: "",
    location: "",
    breed: "",
    duration: "",
};



export default function CreateProducts(props) {

    const {loginUser} = props

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        newCattle = {
            code: "",
            totalValue: "",
            expectedRevenue: "",
            totalWeight: "",
            animalsAmount: "",
            location: "",
            breed: "",
            duration: "",
        };

        console.log("Entering CreateProducts")
    }, []);
    
    const [code, setCode] = React.useState("");
    const [breed, setBreed] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [totalValue, setTotalValue] = React.useState("");
    const [expectedRevenue, setExpectedRevenue] = React.useState("");
    const [totalWeight, setTotalWeight] = React.useState("");
    const [animalsAmount, setAnimalsAmount] = React.useState("");
    const [duration, setDuration] = React.useState("");

    const [selectedImage, setSelectedImage] = React.useState(null);

    const codeChange = (e) => {
        setCode(e.target.value);
        newCattle.code = e.target.value;
    };

    const breedChange = (e) => {
        setBreed(e.target.value);
        // newCattle.breed = e.target.value;
        newCattle.breed = Buffer.from(e.target.value, 'utf-8').toString();
    };

    const locationChange = (e) => {
        setLocation(e.target.value);
        // newCattle.location = e.target.value;
        newCattle.location = Buffer.from(e.target.value, 'utf-8').toString();
    };

    const totalValueChange = (e) => {
        setTotalValue(e.floatValue);
        newCattle.totalValue = e.floatValue;
    };

    const expectedRevenueChange = (e) => {
        setExpectedRevenue(e.floatValue);
        newCattle.expectedRevenue = e.floatValue / 100;
    };

    const totalWeightChange = (e) => {
        setTotalWeight(e.floatValue);
        newCattle.totalWeight = e.floatValue;
    };

    const animalsAmountChange = (e) => {
        setAnimalsAmount(e.floatValue);
        newCattle.animalsAmount = e.floatValue;
    };

    const durationChange = (e) => {
        setDuration(e.floatValue);
        newCattle.duration = e.floatValue;
    };

    const selectedImageChange = async (e) => {

        setSelectedImage(e.target.files[0])
        openPicBox();
    };

    React.useEffect(()=>{
        console.log(selectedImage);
    }, [selectedImage]);

    const [picBox, setPicBox] = React.useState(false);

    const openPicBox = () => {
        
        setPicBox(true);
    };

    const closePicBox = () => {
        setPicBox(false);
    };

    const uploadProduct = async () => {
        console.log(selectedImage);
        console.log(newCattle);

        

        if(!newCattle.code || !newCattle.totalValue || !newCattle.expectedRevenue || !newCattle.totalWeight || !newCattle.animalsAmount || !newCattle.location || !newCattle.breed || !newCattle.duration || !selectedImage){
            swal({
                title: "Datos Incompletos",
                text: "Debes completar todos los datos y cargar una foto",
                icon: "info",
                button: "ok",
            });

            return; 
        }

        setLoading(true);

        const formData  = new FormData();

        const json = JSON.stringify(newCattle);
        const newProduct = new Blob([json], {
            type: 'application/json'
            // type: 'application/json'
        });

        formData.append("selectedImage", selectedImage, "Foto-lote.jpeg");
        formData.append("newProduct", newProduct);

        const createProductResponse = await createProduct(formData, loginUser.jwtToken);

        setLoading(false);

        if(!createProductResponse){
            swal({
                title: "Error",
                text: "Ocurrio un error al crear el lote",
                icon: "error",
                button: "Ok",
            });
            return; 
        }

        swal({
            title: "Creación Exitosa",
            icon: "success",
            button: "Súper",
        });
        return;
    }

    

    return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                height: "90%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
        >
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        fullWidth={true}
                        id="code"
                        name="code"
                        label={<Typography variant="body2">Número de lote</Typography>}
                        value={code}
                        onChange={(e)=> codeChange(e)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        fullWidth={true}
                        id="breed"
                        name="breed"
                        label={<Typography variant="body2">Raza</Typography>}
                        value={breed}
                        onChange={(e)=> breedChange(e)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        fullWidth={true}
                        id="location"
                        name="location"
                        label={<Typography variant="body2">Ubicación</Typography>}
                        value={location}
                        onChange={(e)=> locationChange(e)}
                        autoComplete="off"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="totalValue"
                        customInput={TextField}
                        prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2">Valor total</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={totalValue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            totalValueChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="expectedRevenue"
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={'%'}
                        type="expectedRevenue"
                        label={<Typography variant="body2">Utilidad esperada</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={expectedRevenue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            expectedRevenueChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="totalWeight"
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' kg'}
                        type="totalWeight"
                        label={<Typography variant="body2">Peso total</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={totalWeight}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            totalWeightChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="animalsAmount"
                        customInput={TextField}
                        // prefix={'$ '}
                        // suffix={'%'}
                        type="animalsAmount"
                        label={<Typography variant="body2">Cantidad de animales</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={animalsAmount}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            animalsAmountChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="duration"
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' meses'}
                        type="duration"
                        label={<Typography variant="body2">Duración</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={duration}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            durationChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Button
                        sx={{marginTop: "2rem", backgroundColor: Colors.primary}}
                        variant="contained"
                        component="label"
                        >
                        Cargar foto
                        <input
                            type="file"
                            hidden
                            onChange={(event) => {
                                console.log(event.target.files[0]);
                                selectedImageChange(event);
                            }}
                        />
                    </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Button onClick={uploadProduct} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                        Cargar lote
                    </Button>
                </Grid>
            </Grid>
        }  
        </Box>
        <Dialog open={picBox} onClose={closePicBox}>
            <DialogContent>
            {selectedImage ? 
            <Box 
                component="img"
                sx={{
                height: "90%",
                width: "100%",
                justifyContent: "center",
                }}
                alt="Foto"
                src={URL.createObjectURL(selectedImage)}
            >
            </Box>
            :
            <></>
            }
            </DialogContent>
            <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                <Button onClick={closePicBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    </Box>
    )
}
