import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography } from "@mui/material";
import Colors from "../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { assignDeal } from '../../utilityFunctions/DealsUtil';
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

let selectedDeal;

export default function ClosedDeals(props) {

    const {loginUser, dealsList} = props;

    let history = useHistory();

    React.useEffect(() => {
        renderDealsDefinitions();
        selectedDeal="";
    }, []);

    const filterDeals = () => {
        if(dealsList){
            console.log(dealsList);

            const closedDeals = dealsList.filter((deal) => deal.state==="closed");
            console.log(closedDeals);

            return closedDeals;
        }
        else{
            return;
        }
    };

    const [closedDealsList, setClosedDealsList] = React.useState("");

    const renderDealsDefinitions = async () => {

        const closedDeals = await filterDeals();

        setClosedDealsList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                <Grid container spacing={2}>
                    {closedDeals.map((deal) => {
                        return(
                        <>
                            <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <ListItemButton onClick={() => handleClick(deal)}>
                                    <Grid key={deal.id} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "1rem"}}>
                                        <Box key={deal.id}
                                            sx={{
                                                ...((deal.type === "PRF" || deal.type === "ADF" || deal.type === "VFO" || deal.type === "FOF") && {
                                                    backgroundColor: Colors.primary,
                                                    color: "white",
                                                }),
                                                ...((deal.type === "IPR" || deal.type === "VPR" || deal.type === "PRP") && {
                                                    backgroundColor: Colors.secondary,
                                                    color: "white",
                                                }),
                                                borderRadius: "25%",
                                                width: "3rem",
                                                height: "3rem",
                                                textAlign: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: "1.5rem",
                                            
                                            }} 
                                        >
                                            { deal.type }
                                        </Box>
                                    </Grid>
                                    <Grid key={deal.id-1} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                                        <Grid container direction="column" sx={{ }}>
                                            <Grid item>
                                                <Typography variant="body2" sx={{marginBottom: "0.5rem"}}>
                                                    {`Creación: ${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(deal.date))}`}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    {`Cierre: ${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(deal.closingDate))}`}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid key={deal.id + 1} item xs={5} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={deal.id} variant="body1" sx={{color: "black"}}>
                                            {`${deal.definitionsName}`}
                                        </Typography>
                                    </Grid>
                                    <Grid key={deal.id + 2} item xs={5} sm={5} md={2} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={deal.id + 1} variant="body2" sx={{}}>
                                            {`$ ${new Intl.NumberFormat('es-CO').format(deal.value)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid key={deal.id + 3} item xs={3} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={deal.id + 2} deal="body2" sx={{fontWeight: "bold"}}>
                                            {deal.priority === "high" ? "Alta" : deal.priority === "medium" ? "Media" : "Baja"}
                                        </Typography>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </>
                        )
                    })}
                </Grid>
            </List>
        );
    };

    const handleClick = (deal) => {
        selectedDeal=deal;
        setAssignBox(true);
    };

    const handleAssign = async () => {

        const dealRequest = {
            id: selectedDeal.id
        };

        const assignResult = await assignDeal(dealRequest, loginUser.jwtToken);
        
        if(assignResult !== "Not found"){
            swal({
                title: "Asignación exitosa",
                icon: "success",
                buttons: "Súper"
            }).then(()=>{
                history.push("/commercial/deals");
            });
        }else{
            swal({
                title: "Error",
                icon: "error",
                buttons: "Súper"
            });
        }
    };

    const[assignBox, setAssignBox] = React.useState(false);

    const closeAssignBox = () => {
        setAssignBox(false);
    };

    return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
        >
            {closedDealsList}
        </Box>
        <Dialog open={assignBox} onClose={closeAssignBox}>
          <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
            <Typography variant="h4">Volver a asignar</Typography> 
          </DialogTitle> 
          <DialogActions sx={{display: "flex", justifyContent: "center"}}>
              <Button onClick={closeAssignBox} variant="contained" sx={{backgroundColor: Colors.primary, marginRight: "1rem"}}>
                  ATRÁS
              </Button>
              <Button 
                  onClick={handleAssign} variant="contained" sx={{backgroundColor: Colors.primary, marginLeft: "1rem"}}>
                  ASIGNAR
              </Button>
          </DialogActions>
        </Dialog>
    </Box>
    )
}
