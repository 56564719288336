import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import {
  ilpProfitAccountsFetch,
  ilpProfitDistributionFetch,
  ilpSetProfitDistribution,
} from "../../utilityFunctions/ProfitUtil";
import ProfitViewer from "./ProfitViewer";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Content from "../../layout/Content";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function ProfitHandler() {
  // const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [wuposProfit, setWuposProfit] = useState(null);
  const [spreadDist, setSpreadDist] = useState(null);

  const handleConsult = () => {
    setLoading(true);
    ilpProfitAccountsFetch()
      .then((result) => {
        console.log("Profit account const: ", result);
        setWuposProfit(result);
        //Chained call to profit distribution DB to ensure everything is set before calling ProfitViewer component
        ilpProfitDistributionFetch()
          .then((res) => {
            console.log("Profit Distribution: ", res);
            setSpreadDist(res[0]);
            setLoading(false);
          })
          .catch((err) => {
            console.log("Error fetching profit accounts: " + err);
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log("Error fetching profit accounts: " + err);
        setLoading(false);
      });
  };

  const handleProfitChange = (data) => {
    console.log(data);
    ilpSetProfitDistribution(data)
      .then((result) => {
        console.log("set profit status: " + result);
      })
      .catch((err) => {
        console.log("Error fetching profit accounts: " + err);
      });
  };

  return (
    <Content>
      {loading ? (
        <PuffLoader size={200} color={Colors.secondary} css={cssLoader} />
      ) : spreadDist && wuposProfit ? (
        <ProfitViewer
          wuposProfit={wuposProfit}
          spreadDist={spreadDist}
          handleProfitChange={handleProfitChange}
          handleClickOK={() => {
            setSpreadDist(null);
            setWuposProfit(null);
          }}
        />
      ) : (
        <>
          <PageLabel>WUPO'S PROFIT</PageLabel>
          <AppButton primary onClick={handleConsult}>
            CONSULTAR
          </AppButton>
        </>
      )}
    </Content>
  );
}
