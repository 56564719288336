import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import QRpayment from '../../paymentsQR/QRPayment.png'
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import PageLabel from "../../components/ui/PageLabel";
import DefaultSection from "../../components/ui/DefaultSection";
import AppButton from "../../components/ui/AppButton";
import Input from "../../components/Input";
import Content from "../../layout/Content";
import ButtonContent from "../../layout/ButtonContent";
import CoinsPlanet from "../../img/design-june/highDefinition/coinsPlanet.png"
import Colors from "../../components/ui/Colors";
import { xrpPayment, placeOrder, currenciesOrder, prepareStatement } from "../../utilityFunctions/Paymentutil";
import { fetchAssetRates, fetchXrpl, xrplSecretFetch } from "../../utilityFunctions/FetchUtil";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import {makeStyles} from '@mui/material/styles';
import Button from '@mui/material/Button';
import CashNetworkAugust from "../../img/cash-network-maps/cashNetworkAugust.jpeg";
import operationUnsuccesful from "../../Texts/OperationResult.js";
import {GoogleMap, useLoadScript, Marker, InfoWindow} from "@react-google-maps/api";
import {wupoHotAddress} from "../../Api";
import api from "../../Api";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let WUPOILPACCOUNT = ""; //operational ilp AccountId used by Wupo
let WUPOILPSECRET = "";
let WUPOXRPLSECRETFINAL="";
const QUALITYIN = 1; //qualityin should be an admin input. Hard coded just for tests
const QUALITYOUT = 1; //qualityout should be an admin input. Hard coded just for tests

const FETCHTYPE="BalanceSheet";


const orderObject={
    amountOrdered: 0,
    instruction: "buy", //under construction: always buying in the funding functionality
    stockSymbolOrdered:"",
    stockXrplBalance:0
}

const exchangeOrder={
    currencie: "",
    amount: "",
    instruction: ""
}

const statement = {
  accountId: "",
  account: "",
  asset: "",
  value: "",
  transaction: "credit", //Funding is always credit
  description: ""
}

let stockPaymentSender = false;

//Conditional ? render google maps : render input for direct funding
let cashNetworkMap = true;

const mapContainerStyle = {
  height: "100vh",
  width: "100vw",
};

const options = {
  // styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};
const center = {
  lat: 4.852789,
  lng: -74.069936,
};

const libraries = ["places"];

const retailLocations = {

  comercioPrueba3: {
    lat: 4.847363994277114,
    long: -74.0786393755233,
    name: "Papelería Internet y Tienda CP",
    address: "Al frente de Centro Comercial Terranova"
  },

  laCanastaFamiliar: {
    lat: 4.856039,
    long: -74.063978,
    name: "Carnes Primavera",
    address: "Dentro de La Canasta Familiar que está al lado de D1"
  },

  drogueria: {
    lat: 4.853194,
    long: -74.074056,
    name: "Droguería Cerca de Jade",
    address: ""
  },

  peluqueriaDownTown: {
    lat: 4.860589,
    long: -74.062675,
    name: "Barbería DownTown",
    address: "Media cuadra de la plaza de mercado"
  },

  catHouseChia: {
    lat: 4.849569,
    long: -74.075004,
    name: "Cat House Chía: Veterinaria y peluquería",
    address: "Al lado norte de la vía"
  },

  angelBlues: {
    lat: 4.860382,
    long: -74.062835,
    name: "Angel Blues: Café y Cerveza",
    address: "Media cuadra de la plaza de mercado"
  },

  citiPharmaChia: {
    lat: 4.856798, 
    long: -74.073099,
    name: "Droguería City Pharma",
    address: "Chía"
  },

  citiPharmaCota: {
    lat: 4.817509,
    long: -74.095623,
    name: "Droguería City Pharma",
    address: "Cota"
  },

  homeVet: {
    lat: 4.8573550955666,
    long: -74.0569804532736,
    name: "Veterinaria HomeVet",
    address: "Chía"
  },

  nuestrasRaíces: {
    lat: 4.85751770606966,
    long: -74.05680920237764,
    name: "Frutería Nuestras Raíces",
    address: "Chía"
  },

  restauranteElDespacho: {
    lat: 4.856842,
    long: -74.063595,
    name: "Restaurante El Despacho: Comida a la parrilla",
    address: "Chía"
  },

  heladeriaNatuCrem: {
    lat: 4.8590007,
    long: -74.0607026,
    name: "Heladería Natu Crem",
    address: "carrera 10 8 76 CC. El Curubito"
  },

  distribuidorBimbo: {
    lat: 4.8595461,
    long: -74.061501,
    name: "Distribuidor Bimbo",
    address: "Carrera 11 No 9 - 15"
  },

  elFarolitoChia: {
    lat: 4.8619919,
    long: -74.0609885,
    name: "El Farolito Chía",
    address: "Calle 12 carrera 11 - peatonal"
  },

  sweetNana: {
    lat: 4.8571629,
    long: -74.0632296,
    name: "Sweet Nana",
    address: "Calle 6 carrera 12"
  },

  everest: {
    lat: 4.8568373,
    long: -74.0625284,
    name: "Everest",
    address: "Calle 6 carrera 11"
  },

  cafeParis: {
    lat: 4.8562662,
    long: -74.0613804,
    name: "Café París",
    address: "Carrera 10 calle 6"
  },

  panYCafe: {
    lat: 4.8562662,
    long: -74.0613804,
    name: "Pan y Café",
    address: "Carrera 6 calle 9"
  },

  parkAndRoses: {
    lat: 4.861862,
    long: -74.0605324,
    name: "parkAndRoses: Beer & Coffee",
    address: "Sobre peatonal parque principal"
  },

  laOctava: {
    lat: 4.8592391,
    long: -74.0625196,
    name: "La Octava: Cafetería y Frutería",
    address: "Carrera 12 con Calle 8"
  },

  pacandeapc: {
    lat: 4.8592391,
    long: -74.0625196,
    name: "Pacande: Heladería, cafetería y frutería",
    address: "Calle 11 No. 12 - 51"
  },

  cafeCelestialyBar: {
    lat: 4.862572781365858,
    long: -74.05638033216015,
    name: "Café Celestial y Bar",
    address: "Av Pradilla # 7 - 51"
  },

  panColiceo: {
    lat: 4.862568649794399,
    long: -74.0560550186669,
    name: "Panadería y Cafetería El Coliseo",
    address: "Av Pradilla # 7 - 07"
  },

  cafeBardelaSabana: {
    lat: 4.862583473699321,
    long: -74.05640818155818,
    name: "Café Bar de la Sabana: Empanadas",
    address: "Av Pradilla # 7 - 51"
  },

  peruSaborySazon: {
    lat: 4.862156001048328,
    long: -74.0606434321601,
    name: "Perú: Sabor y Sazón",
    address: "Calle 12 # 11 - 26"
  },

  palosDeNogal: {
    lat: 4.8603866,
    long: -74.0614410,
    name: "Restaurante Palos de Nogal",
    address: "Calle 10 # 11 - 33"
  },

  mesonBoyacense: {
    lat: 4.8670037,
    long: -74.0551992,
    name: "Restaurante El Mesón Boyacense",
    address: "Cra 9 # 19 - 90"
  },

  laVacaLoca: {
    lat: 4.8604456,
    long: -74.0617112,
    name: "Restaurante La Vaca Loca",
    address: "Calle 10 # 11 - 57"
  },

  fruteriayBroster: {
    lat: 4.860505433381379,
    long: -74.06116976098423,
    name: "Frutería y Broaster",
    address: "Cra 11 # 9 - 79"
  },

  rockyPizza: {
    lat: 4.863387041931477,
    long: -74.05998566098424,
    name: "Rock y Pizza",
    address: "Cra 11 # 13 - 47"
  },

  caliAjiRestaurante: {
    lat: 4.863128851821223,
    long: -74.06007320331273,
    name: "Cali Ají Restaurante",
    address: "Cra 11 # 13 - 39"
  },

  dejavus: {
    lat: 4.859066143717269,
    long: -74.0609377033128,
    name: "Café Bar Dejavus",
    address: "Cra 10 # 8 - 53"
  },

  coffeeComics: {
    lat: 4.85932247277969,
    long: -74.06031851865566,
    name: "Coffee Comics: Café, cocteles, desayunos, helados y jugos",
    address: "Cll 9 # 9 - 45 Local 5"
  },

  churrosyCafe: {
    lat: 4.86185979137886,
    long: -74.05865480331275,
    name: "Churros y Café",
    address: "Cra 9 # 12 - 13"
  },

  jalisco: {
    lat: 4.861810162107666,
    long: -74.0596535033128,
    name: "Gastrobar Jalisco",
    address: "Cra 9 # 12 - 13"
  },

  kajomaRestaurante: {
    lat: 4.661317923752599,
    long: -74.06402804749239,
    name: "Gastrobar Jalisco",
    address: "Cll 72 # 20B - 08"
  },

  angelsStyles: {
    lat: 4.85783957332168,
    long: -74.06138793214859,
    name: "Peluquería Angels Styles",
    address: "Calle 7 # 10 - 35"
  },

  cafeBarcelona: {
    lat: 4.860685191771126,
    long: -74.05927068796987,
    name: "Café Barcelona",
    address: "Calle 11 # 9 - 28"
  },

  panaderiaVenezolana: {
    lat: 4.8635462789359085,
    long: -74.06361846098419,
    name: "Panadería Venezolana",
    address: "Cra 15 # 12 -7"
  },

  laCumbreBogota: {
    lat: 4.8635462789359085,
    long: -74.06361846098419,
    name: "Restaurante y cocteles: La Cumbre Bogotá",
    address: "Pendiente"
  },

  exageradamenteBueno: {
    lat: 4.862504800937362,
    long: -74.05967667632709,
    name: "Restaurante: Exageradamente Bueno",
    address: "Calle 13 # 10 - 27"
  },

  rebanoBurger: {
    lat: 4.698118316317004,
    long: -74.09040537632796,
    name: "Rebaño Burger",
    address: "Carrera 73A # 81 - 47"
  },

  ambrosia: {
    lat: 4.701193929646093,
    long: -74.08961044564212,
    name: "Ambrosia: Bebidas de Dioses",
    address: "Diagonal 81h # 72c - 70"
  },
   
  bubbleWaffles: {
    lat: 4.8612827110808805,
    long: -74.05975378981995,
    name: "Bubble Waffles",
    address: "Carrera 10 # 11 - 38"
  },

  goliat: {
    lat: 4.8612827110808805,
    long: -74.05975378981995,
    name: "Veterinaria Goliat",
    address: "Tv 74b # 81f - 93"
  },

  avicolaSanMateo: {
    lat: 4.703704650640997, 
    long: -74.09027113214931,
    name: "Avícola San Mateo",
    address: "diagonal 82g # 73a - 25"
  },

  acdSorpresas: {
    lat: 4.697662252499801, 
    long: -74.09038960331357,
    name: "ACD Sorpresas",
    address: "Cra 73a # 81 - 22"
  },

  casaGadi: {
    lat: 4.863058581195869, 
    long: -74.06084653214846,
    name: "Casa Gadi: Café y Pastelería",
    address: "Calle 13 # 12 - 07"
  },

  cavaRestauranteBar: {
    lat: 4.69875006361152,  
    long: -74.08984663100776,
    name: "Cava: Restaurante Bar",
    address: "Cll 81 # 72b - 54"
  },

  polaoMiedo: {
    lat: 4.697051117406112,  
    long: -74.08984563581816 ,
    name: "Pola o Miedo",
    address: "Cll 81 # 72b - 54"
  },

  minutoGourmet: {
    lat: 4.698508701739206,  
    long: -74.09042927447794,
    name: "Minuto Gourmet: Fast Food",
    address: "Calle 81 # 73 - 22"
  },
   
};

export default function Funding(props) {
  const { 
    user, 
    ledgerAccounts, 
    loginUser,
    retailerTokens, 
    exchangeCurrencies,
    wupoServerUri, 
    stockOrderSymbol,
    bridgeAsset,
    environmentReferenceFundingAsset
  } = props;

  const assetFromHandler = props.match?.params?.asset;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  const REFERENCEFUNDINGASSET = environmentReferenceFundingAsset;

  let history = useHistory();
  const [inputText, setInputText] = useState("");
  const [loading, setLoading] = useState(false);
  const [xrpTestnetWupoCredentials, setXrpTestnetWupoCredentials] = useState({
    account: "",
    token: "",
    xrplAddress: "",
    xrplAddressSecret: ""
  });

  let wupoXrplSecret;
  let wupoXrplAddress;
  let clientXrplAddress;
  let clientXrplToken;

  let payment = {
    source: {
      address: "",
      maxAmount: {
        value: "",
        currency: assetFromHandler,
        counterparty: "",
      },
    },
    destination: {
      address: "",
      amount: {
        value: "",
        currency: assetFromHandler,
        counterparty: "",
      },
    },
    //TODO: include an automatic path or an input path
    //"paths": "[[{"currency":"COP","issuer":"rJAf4zqDKXGchRw3yG7f8rNh4GMEEvSMQf"},{"account":"rJAf4zqDKXGchRw3yG7f8rNh4GMEEvSMQf"}]]"
  };

  let trustline = {
    currency: assetFromHandler,
    counterparty: "",
    limit: "",
    qualityIn: QUALITYIN,
    qualityOut: QUALITYOUT,
    //"ripplingDisabled": false, //Not included TODO: review the default valur (think false)
    //TODO: Automatic enable rippling in settings
    //"frozen": false, //Not included
  };

  let ilpPaymentVariables = {
    senderAccount: WUPOILPACCOUNT,
    paymentValue: "",
    receiverAccount: user.account + assetFromHandler,
    senderKey: WUPOILPSECRET,
  };
  const loadXrpAddressCreds = () => {
    fetch(`${wupoServerUri.devnet}/api/secure/xrpwupocreds`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${loginUser.jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json(); //Fix me: Change text for body with a json response from springboot
      })
      .then((data) => {
        setXrpTestnetWupoCredentials({account: data.wupoAccount, token: data.wupoToken, xrplAddress: data.xrpAddress, xrplAddressSecret: data.xrpAddressSecret});
        WUPOILPACCOUNT = data.wupoAccount;
        WUPOILPSECRET = data.wupoToken;
        wupoXrplSecret = data.xrpAddressSecret; // setting to undefined when used in a function. Review
        WUPOXRPLSECRETFINAL=data.xrpAddressSecret;

      })
      .catch((error) => {
        console.log("Error msg JPA: " + error);
      });
  };
  useEffect(() => {
    //If wupohot present the funding functionality
    user.account === wupoHotAddress ? cashNetworkMap = false : cashNetworkMap = true;
    
    
    stockPaymentSender = false
    loadXrpAddressCreds();
    swal({
      title: "¿QUÉ HAGO?",
      text: `\n Recarga el valor deseado en nuestra red de comercios (WUPO comercios)`,
      icon: "success",
      buttons: {
        cancel: "Entiendo",
        info: "Más Info",
        // admin: "Admin",
      },
      //["Entiendo", "Más Info"]
    }).then((value) => {
      switch (value) {
        case "info":
          swal({
            title: "¿QUÉ ES ESTO?",
            text: `\n Recargamos tu cuenta en wupo.NET \n \n`,
            icon: "success",
            button: "Súper",
          });
          break;
        // case "admin":
        //   swal({
        //     title: "PARA ADMINS E INVERSIONISTAS",
        //     text: `\n Cuando se fondea la cuenta ocurren los siguientes pasos:
        //           \n 1. Pago en interledger (protocolo de interconexión de las cuentas de WUPO a la cuenta del cliente
        //         2. Creación de trustline en XRPL (blockchain de finanzas eficientes) con límite igual al doble del fondeo
        //         3. Pago en XRPL de la cuenta de WUPO a la cuenta del cliente con el activo seleccionado (representado por trustlines)
        //         \n \n
        //         Actualmente, el valor ingresado simula un pago leído por una API con la infraestructura de pagos usada\n\n
        //         Te invitamos a revisar la consola (Chrome: click derecho -> inspeccionar -> console) para ver lo que pasa por detrás en tiempo real!`,
        //     icon: "success",
        //     button: "Súper",
        //   });
          break;
        default:
      }
    });
  }, []);

  const handleOnClick = () => {
    if (!inputText) return;
    setLoading(true);
    let isRetailerToken=false;
    console.log(` stockPaymentSender: ${stockPaymentSender}`)

    retailerTokens.forEach(token=>{
      if(token.label===assetFromHandler || token.asset===assetFromHandler){
          isRetailerToken=true
      }
    });
    stockOrderSymbol.forEach(token => {
      if(token.currency === assetFromHandler){
        stockPaymentSender = true;
      }
    }); 
    if (stockPaymentSender === true || isRetailerToken === true) {
      stockPayment();
    } else {
      ilpPayment();
    }
  };

  const handleOkClick = () => {
    history.push("/account/handle_account");
  };

  const onChange = (e) => {
    setInputText(e.target.value);
  };

  const stockPayment = () => {
    payment.source.maxAmount.value = String(inputText);
    ledgerAccounts.forEach((account) => {
      if (
        account.account === user.account &&
        account.asset === assetFromHandler
      ) {
        const assetsToFetch = {
          fromAsset: REFERENCEFUNDINGASSET,
          toAsset: account.stockAsset,
        };
        // stockAsset=account.stockAsset;
        fetchAssetRates(assetsToFetch, loginUser.jwtToken).then((result) => {
          //payment.destination.amount.value = String(inputText * result.rate); //Fix me: for test purposes slightly less
          console.log("funding rate fetching result: " + JSON.stringify(result))
          const receiverValue=inputText * (result.rate);
          payment.source.maxAmount.value=String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000);
          payment.destination.amount.value=String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000);
          orderObject.amountOrdered=inputText * (result.rate);
         
          xrplTrustline();
        });
      }
    });
  };

  const ilpPayment = () => {
    ilpPaymentVariables.paymentValue = inputText;
    console.log("Json payment: " + JSON.stringify(ilpPaymentVariables));

    fetch(`${wupoServerUri.devnet}/api/secure/payment`, {
      method: "POST",
      body: JSON.stringify(ilpPaymentVariables),
      headers: {
        "Authorization": `Bearer ${loginUser.jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((body) => {
        console.log("Fetch text: " + JSON.stringify(body));
        // if (
        //   body.receiverAmount !== undefined &&
        //   body.wupoAmount !== undefined
        // ) {
          if (
            body.receiverAmount &&
            body.wupoAmount 
          ) {

          //The XRP payment value corresponds to the receiven value paid to the client"s ilp account
          console.log(user.stockAsset);
          const currenciesToFetch={
            fromAsset: REFERENCEFUNDINGASSET,
            toAsset: assetFromHandler
          }
          fetchAssetRates(currenciesToFetch, loginUser.jwtToken)
          .then(result=>{
              console.log("currency funding rate fetching result: " + JSON.stringify(result))
              payment.source.maxAmount.value=String(Math.round(((body.receiverAmount* result.rate) + Number.EPSILON)*100000000)/ 100000000);
              const receiverValue=body.receiverAmount * (result.rate);//Fix me: for test purposes slightly less;
              payment.destination.amount.value=String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000)
              xrplTrustline();
          })
        } else {
          swal({
            title: "NO PUDIMOS HACER TU PAGO",
            // text: `\n Por favor vuelve a intentarlo. 
    
            //     Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
            //     Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
            //     Jorge: XXXXXXXXXX
            //     Federico: XXXXXXXXXX`,
            text: operationUnsuccesful,
            icon: "error",
            button: "Entiendo",
          });
        }
      })
      .catch(function (error) {
        console.log("Error msg: " + error);
        swal({
          title: "NO PUDIMOS HACER TU PAGO",
          // text: `\n Por favor vuelve a intentarlo. 

          //     Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
          //     Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
          //     Jorge: XXXXXXXXXX
          //     Federico: XXXXXXXXXX`,
          text: operationUnsuccesful,
          icon: "error",
          button: "Entiendo",
        });
      });
  };

  const xrplTrustline = () => {
    ledgerAccounts.forEach((account) => {
      if (account.accountId === WUPOILPACCOUNT) {
        trustline.counterparty = account.xrplAddress;
        trustline.limit = String(inputText * 1000000); //The limit is X times the funding just for test purposes
      }
      if (account.accountId === user.account + assetFromHandler) {
        clientXrplAddress = account.xrplAddress;
        // clientXrplToken = account.xrplAddressSecret; //Currently reviewing
        xrplSecretFetch(account.xrplAddressSecret, loginUser.jwtToken)
        .then((secret) => {
          console.log(secret);
          clientXrplToken = secret;

           //XRPL ledger payment from Wupo to the client"s address
          console.log("Json trustline: " + JSON.stringify(trustline));
          console.log("client xrpl address: " + clientXrplAddress); 
          console.log("client xrpl Token: " + clientXrplToken); //Currently reviewing

          api.on("error", (errorCode, errorMessage) => {
            console.log(errorCode + ": " + errorMessage);
          });

          api.connect().then(() => {
            api
              .prepareTrustline(clientXrplAddress, trustline)
              .then((prepare) => {
                // console.log(prepare);
                // console.log("signedTx");
                const { signedTransaction, id } = api.sign(
                  prepare.txJSON,
                  clientXrplToken
                );

                // console.log("ID: " + id);
                // console.log("Signed Tx: " + signedTransaction);

                api.submit(signedTransaction).then((result) => {
                  console.log("Trustline creation result: ");
                  console.log(result);
                  if (result.resultCode === "tesSUCCESS") {
                    xrplTrustlinePayment();
                  } else {
                    console.log("Trustline failed, pending reversion");
                  }
                });
              })
              .catch((errorPrepare) => {
                console.log(errorPrepare);
              });
          });

        });
      }
    });
  };

  const xrplTrustlinePayment = () => {
    /*Use the integrator"s fetch to define the payments variables */
    ledgerAccounts.forEach((account) => {
      if (account.accountId === WUPOILPACCOUNT) {
        // wupoXrplSecret = account.xrplAddressSecret;
        wupoXrplAddress = account.xrplAddress;
        payment.source.address = account.xrplAddress;
        payment.source.maxAmount.counterparty = account.xrplAddress;
        payment.destination.amount.counterparty = account.xrplAddress;


        //payment.current.source.maxAmount.value=inputFromChild;
        // payment.current.destination.amount.value=String(inputFromChild - (inputFromChild * 0.03));//Fix me: for test purposes slightly less;
      }
      if (account.accountId === user.account + assetFromHandler) {
        payment.destination.address = account.xrplAddress;
      }
    });
    /* Payment from Wupo to the client using the defined asset */

    xrpPayment(
      payment,
      String(payment.source.maxAmount.value),
      payment.destination.address,
      WUPOXRPLSECRETFINAL
      // wupoXrplSecret
    )
      .then(() => {
        //If stock -> Place an order in Ameritrade
        // console.log("stock from handle account: " +stockAssetFromHandleAccount)
        // if(user.stockAsset==="GOOG" || user.stockAsset==="AAPL"){ //changing here
        if(stockPaymentSender === true){ // changing here
          // Change it to: fetch the xrpl account balance for the asset and send it as part of the json
          //Fetch Wupo's xrpl balance to buy assets only if the client' order exceed the asset basket
          // console.log("WUPOADDRESS: " + WUPOADDRESS)
          fetchXrpl(FETCHTYPE, xrpTestnetWupoCredentials.xrplAddress)
          .then(balance=>{
              console.log("balance of wupos xrpl account: " + JSON.stringify(balance))
              const balanceObject=balance.obligations.find(balanceElement=>{ 
                  if(balanceElement.currency===assetFromHandler){
                      // console.log("stock equals true: " + balanceElement.currency);
                      return true;
                  }else{
                      return false;
                  }
              })
              orderObject.stockXrplBalance=balanceObject.value;
              // orderObject.stockXrplBalance=1; //just for test purposes
              orderObject.stockSymbolOrdered=user.stockAsset;
              console.log("balanceObjectInStock: " + JSON.stringify(orderObject));

              //place an order in ameritrade /* COMMENTED WHILE LAUNCHING */
               placeOrder(orderObject, loginUser.jwtToken);
          });
          // else: if tiggered, purchase an article in the retailers affiliates
          // else: buy currencies in eth ledger alignes to financial strategy   
        } 

         
          exchangeCurrencies.forEach(element=>{  
              if(element.currency===user.stockAsset){
                  console.log("Asset before sending currency order: " + element.currency)
                  exchangeOrder.currencie=element.exchangeCurrency;

                  const currenciesToFetch={
                    fromAsset: user.stockAsset,
                    toAsset: bridgeAsset
                }
                fetchAssetRates(currenciesToFetch,loginUser.jwtToken)
                 .then(result=>{
                    
                    exchangeOrder.amount=String(Math.round(((payment.source.maxAmount.value*result.rate)+Number.EPSILON)*10)/10);
                    exchangeOrder.instruction = "sell"; // sell given that it sells the bridge asset to receive the funded asset
      
                    // String(Math.round((receiverValue+ Number.EPSILON)*100000000)/100000000);
                    currenciesOrder(exchangeOrder, loginUser.jwtToken);
                 });
              }
          });

          //TODO: post statement: the payment bill that is persisted in the backend database
          statement.accountId = user.accountId;
          statement.account = user.account;
          statement.asset = user.asset;
          statement.value = payment.destination.amount.value;
          statement.description = user.accountId;

          prepareStatement(statement, loginUser.jwtToken).then(statementResult =>{
            console.log(statementResult);
          });



        
        swal({
          title: "FONDEO EXITOSO",
          icon: "success",
          button: "Súper",
        }).then(() => {
          setLoading(false);
          history.push("/account/handle_account");
          // dataForParent("wupealo-fundingOk")
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "NO PUDIMOS HACER TU PAGO",
          // text: `\n Por favor vuelve a intentarlo. 

          //         Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
          //         Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
          //         Jorge: XXXXXXXXXX
          //         Federico: XXXXXXXXXX`,
          text: operationUnsuccesful,
          icon: "error",
          button: "Entiendo",
        });
        //PENDING REVERSION OF THE ACCOUNT CREATION IN THE ILP CONNECTOR
      });
  };

  const [funding, setFunding] = React.useState(false);
  function handleFunding(){
      setFunding(true);
  }

  const handleClose = () => {
      setFunding(false);
  };

  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
    }}
    >
      <Content maxWidth={"576px"}>
        {cashNetworkMap ? 

        <>
        <PageLabel>PUNTOS DE RECARGA WUPO</PageLabel>

        <p 
          style = {{
            color: Colors.labelColor,
            fontSize: "1rem",
            textAlign: "center",
            fontWeight: 400,
            margin: 0,
            whiteSpace: "pre-line",
            marginTop:"1rem",
            marginBottom:"0.6rem",
          }}
        >
          {`Encuentra los comercios aliados de WUPO en el mapa y corre a recargar o descargar`}
        </p>
        <AppButton primary onClick={handleOkClick} width={"30%"} marginBottom={"0.8em"} height={"small"} fontSize={"small"}>
            Atrás
        </AppButton>
        <GoogleMap
          id="map"
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={center}
          options={options}
          // onClick={onMapClick}
          // onLoad={onMapLoad}
        >
          {/* <Marker
            position={{ lat: retailLocations.comercioPrueba1.lat, lng: retailLocations.comercioPrueba1.long}}
            onClick={() => {
              setSelected(retailLocations.comercioPrueba1);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.comercioPrueba2.lat, lng: retailLocations.comercioPrueba2.long}}
            onClick={() => {
              setSelected(retailLocations.comercioPrueba2);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> */}

          <Marker
            position={{ lat: retailLocations.comercioPrueba3.lat, lng: retailLocations.comercioPrueba3.long}}
            onClick={() => {
              setSelected(retailLocations.comercioPrueba3);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.laCanastaFamiliar.lat, lng: retailLocations.laCanastaFamiliar.long}}
            onClick={() => {
              setSelected(retailLocations.laCanastaFamiliar);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
           <Marker
            position={{ lat: retailLocations.drogueria.lat, lng: retailLocations.drogueria.long}}
            onClick={() => {
              setSelected(retailLocations.drogueria);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.peluqueriaDownTown.lat, lng: retailLocations.peluqueriaDownTown.long}}
            onClick={() => {
              setSelected(retailLocations.peluqueriaDownTown);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
           <Marker
            position={{ lat: retailLocations.catHouseChia.lat, lng: retailLocations.catHouseChia.long}}
            onClick={() => {
              setSelected(retailLocations.catHouseChia);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.angelBlues.lat, lng: retailLocations.angelBlues.long}}
            onClick={() => {
              setSelected(retailLocations.angelBlues);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.citiPharmaChia.lat, lng: retailLocations.citiPharmaChia.long}}
            onClick={() => {
              setSelected(retailLocations.citiPharmaChia);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
          <Marker
            position={{ lat: retailLocations.citiPharmaCota.lat, lng: retailLocations.citiPharmaCota.long}}
            onClick={() => {
              setSelected(retailLocations.citiPharmaCota);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.homeVet.lat, lng: retailLocations.homeVet.long}}
            onClick={() => {
              setSelected(retailLocations.homeVet);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.nuestrasRaíces.lat, lng: retailLocations.nuestrasRaíces.long}}
            onClick={() => {
              setSelected(retailLocations.nuestrasRaíces);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.restauranteElDespacho.lat, lng: retailLocations.restauranteElDespacho.long}}
            onClick={() => {
              setSelected(retailLocations.restauranteElDespacho);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.heladeriaNatuCrem.lat, lng: retailLocations.heladeriaNatuCrem.long}}
            onClick={() => {
              setSelected(retailLocations.heladeriaNatuCrem);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.distribuidorBimbo.lat, lng: retailLocations.distribuidorBimbo.long}}
            onClick={() => {
              setSelected(retailLocations.distribuidorBimbo);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

           <Marker
            position={{ lat: retailLocations.elFarolitoChia.lat, lng: retailLocations.elFarolitoChia.long}}
            onClick={() => {
              setSelected(retailLocations.elFarolitoChia);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

           <Marker
            position={{ lat: retailLocations.sweetNana.lat, lng: retailLocations.sweetNana.long}}
            onClick={() => {
              setSelected(retailLocations.sweetNana);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

           <Marker
            position={{ lat: retailLocations.everest.lat, lng: retailLocations.everest.long}}
            onClick={() => {
              setSelected(retailLocations.everest);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.panYCafe.lat, lng: retailLocations.panYCafe.long}}
            onClick={() => {
              setSelected(retailLocations.panYCafe);
            }}
            icon={{
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.cafeParis.lat, lng: retailLocations.cafeParis.long}}
            onClick={() => {
              setSelected(retailLocations.cafeParis);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.parkAndRoses.lat, lng: retailLocations.parkAndRoses.long}}
            onClick={() => {
              setSelected(retailLocations.parkAndRoses);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.laOctava.lat, lng: retailLocations.laOctava.long}}
            onClick={() => {
              setSelected(retailLocations.laOctava);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.pacandeapc.lat, lng: retailLocations.pacandeapc.long}}
            onClick={() => {
              setSelected(retailLocations.pacandeapc);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.cafeCelestialyBar.lat, lng: retailLocations.cafeCelestialyBar.long}}
            onClick={() => {
              setSelected(retailLocations.cafeCelestialyBar);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.panColiceo.lat, lng: retailLocations.panColiceo.long}}
            onClick={() => {
              setSelected(retailLocations.panColiceo);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.cafeBardelaSabana.lat, lng: retailLocations.cafeBardelaSabana.long}}
            onClick={() => {
              setSelected(retailLocations.cafeBardelaSabana);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.peruSaborySazon.lat, lng: retailLocations.peruSaborySazon.long}}
            onClick={() => {
              setSelected(retailLocations.peruSaborySazon);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.palosDeNogal.lat, lng: retailLocations.palosDeNogal.long}}
            onClick={() => {
              setSelected(retailLocations.palosDeNogal);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.mesonBoyacense.lat, lng: retailLocations.mesonBoyacense.long}}
            onClick={() => {
              setSelected(retailLocations.mesonBoyacense);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.laVacaLoca.lat, lng: retailLocations.laVacaLoca.long}}
            onClick={() => {
              setSelected(retailLocations.laVacaLoca);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />  

          <Marker
            position={{ lat: retailLocations.fruteriayBroster.lat, lng: retailLocations.fruteriayBroster.long}}
            onClick={() => {
              setSelected(retailLocations.fruteriayBroster);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.rockyPizza.lat, lng: retailLocations.rockyPizza.long}}
            onClick={() => {
              setSelected(retailLocations.rockyPizza);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.caliAjiRestaurante.lat, lng: retailLocations.caliAjiRestaurante.long}}
            onClick={() => {
              setSelected(retailLocations.caliAjiRestaurante);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.dejavus.lat, lng: retailLocations.dejavus.long}}
            onClick={() => {
              setSelected(retailLocations.dejavus);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.coffeeComics.lat, lng: retailLocations.coffeeComics.long}}
            onClick={() => {
              setSelected(retailLocations.coffeeComics);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.churrosyCafe.lat, lng: retailLocations.churrosyCafe.long}}
            onClick={() => {
              setSelected(retailLocations.churrosyCafe);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.jalisco.lat, lng: retailLocations.jalisco.long}}
            onClick={() => {
              setSelected(retailLocations.jalisco);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.kajomaRestaurante.lat, lng: retailLocations.kajomaRestaurante.long}}
            onClick={() => {
              setSelected(retailLocations.kajomaRestaurante);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.angelsStyles.lat, lng: retailLocations.angelsStyles.long}}
            onClick={() => {
              setSelected(retailLocations.angelsStyles);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.cafeBarcelona.lat, lng: retailLocations.cafeBarcelona.long}}
            onClick={() => {
              setSelected(retailLocations.cafeBarcelona);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.panaderiaVenezolana.lat, lng: retailLocations.panaderiaVenezolana.long}}
            onClick={() => {
              setSelected(retailLocations.panaderiaVenezolana);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.laCumbreBogota.lat, lng: retailLocations.laCumbreBogota.long}}
            onClick={() => {
              setSelected(retailLocations.laCumbreBogota);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          /> 

          <Marker
            position={{ lat: retailLocations.exageradamenteBueno.lat, lng: retailLocations.exageradamenteBueno.long}}
            onClick={() => {
              setSelected(retailLocations.exageradamenteBueno);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.rebanoBurger.lat, lng: retailLocations.rebanoBurger.long}}
            onClick={() => {
              setSelected(retailLocations.rebanoBurger);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.ambrosia.lat, lng: retailLocations.ambrosia.long}}
            onClick={() => {
              setSelected(retailLocations.ambrosia);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.bubbleWaffles.lat, lng: retailLocations.bubbleWaffles.long}}
            onClick={() => {
              setSelected(retailLocations.bubbleWaffles);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.goliat.lat, lng: retailLocations.goliat.long}}
            onClick={() => {
              setSelected(retailLocations.goliat);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.avicolaSanMateo.lat, lng: retailLocations.avicolaSanMateo.long}}
            onClick={() => {
              setSelected(retailLocations.avicolaSanMateo);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.acdSorpresas.lat, lng: retailLocations.acdSorpresas.long}}
            onClick={() => {
              setSelected(retailLocations.acdSorpresas);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.casaGadi.lat, lng: retailLocations.casaGadi.long}}
            onClick={() => {
              setSelected(retailLocations.casaGadi);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.cavaRestauranteBar.lat, lng: retailLocations.cavaRestauranteBar.long}}
            onClick={() => {
              setSelected(retailLocations.cavaRestauranteBar);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.polaoMiedo.lat, lng: retailLocations.polaoMiedo.long}}
            onClick={() => {
              setSelected(retailLocations.polaoMiedo);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

          <Marker
            position={{ lat: retailLocations.minutoGourmet.lat, lng: retailLocations.minutoGourmet.long}}
            onClick={() => {
              setSelected(retailLocations.minutoGourmet);
            }}
            icon={{       
              url: "/images/MarkerLogo.png",
              // origin: new window.google.maps.Point(0, 0),
              // anchor: new window.google.maps.Point(15, 15),
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />

        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.long }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div style={{textAlign: "center"}}>
              <h2
                style={{
                  color: "#A020F0",
                }}
              >
                COMERCIO WUPO
              </h2>
              <p
                style={{
                  fontSize: "1rem",
                  margin: "0",
                  fontWeight: "bold",
                  
                }}
              >
                {selected.name}
              </p>
              <p>{selected.address}</p>
            </div>
          </InfoWindow>
        ) : null}
        </GoogleMap>
        </>
        :
        (<React.Fragment>
      
          {loading ? (
            <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
          ): 
          
          ( <React.Fragment>
              <DefaultSection backImgage={CoinsPlanet} width="50%" />
              <PageLabel>VALOR FONDEO</PageLabel>
              <p
                style={{
                  fontSize: "1.2rem",
                  margin: "0",
                  fontWeight: 600,
                  color: Colors.labelColor,
                }}
              >
                (Pesos Colombianos)
              </p>
                <Input
                  value={inputText}
                  type="number"
                  onChangeInfo={onChange}
                  inputIcon="assetAmount"
                />
              <ButtonContent>
                <AppButton
                  primary
                  onClick={handleFunding}
                  style={{ marginBottom: "1rem" }}
                  width={"90%"}
                >
                  Fondea
                </AppButton>
                <AppButton primary onClick={handleOkClick} width={"90%"}>
                  Atrás
                </AppButton>
              </ButtonContent>
              <React.Fragment>
                <Dialog open={funding} onClose={handleFunding} aria-labelledby="form-dialog-title">
                  <DialogTitle id="form-dialog-title">MECANISMO DE RECARGA</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      RECARGA EN LOS WUPO COMERCIOS. ENCUÉNTRALOS EN EL MAPA Y CORRE A RECARGAR.
                    </DialogContentText>
                    <img className="paymentQR" src={CashNetworkAugust} alt="QR not rendered"/>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Atrás
                    </Button>
                    <Button 
                        onClick={handleOnClick} 
                        color="primary"
                    >
                        Súper
                    </Button>
                  </DialogActions>
                </Dialog>
                </React.Fragment>
              </React.Fragment>
              )}
        </React.Fragment>
      )}
      </Content>
    </div>
  );
}
