import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import AppButton from "../../components/ui/AppButton";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import {changeRol} from "../../utilityFunctions/RootUtil"
import swal from "sweetalert";


const roles = [
    {
        value: "ADMIN",
        label: "Administrador"
    },
    {
        value: "OPERATOR",
        label: "Operador"
    },
];

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        // marginTop:"0.2em",
        // marginBottom: "0.2em"
    },
    input: {
        width: "100%",
        height: "50%",
    },

    longLabels: {
        
    }

}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function RootChange(props) {

    const{ loginUser } = props

    let history = useHistory();

    const classes = useStyles();

    const[loading, setLoading] = React.useState(false);

    const[role, setRoles] = React.useState('');
    const[username, setUsername] = React.useState('');

    const submitModifications = async () =>{
        setLoading(true);
        const result = await changeRol(username , role, loginUser.jwtToken);
        setLoading(false);
        if(result !== "Error"){
            swal({
                title: "Cambio Exitoso",
                icon: "success",
                button: "Entiendo",
            });
        }else{
            swal({
                title: "Falló el cambio",
                icon: "error",
                button: "Entiendo",
            });
        }

    };

    const rootBack = () => {
        history.push({pathname: "/root-profile"});
    };

    return (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "50%",
            textAlign: "center",
        }}
    >
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" className={classes.gridInputs}>
                
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        label={<Typography variant="body2" className={classes.longLabels}>Usuario</Typography>}
                        value={username}
                        onChange={e=>setUsername(e.target.value)}
                        helperText="Digita el usuario"
                    >
                        Usuario
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField
                        select
                        label={<Typography variant="body2" className={classes.longLabels}>Rol</Typography>}
                        value={role}
                        onChange={e=>setRoles(e.target.value)}
                        helperText="Selecciona el rol"
                    >
                        {roles.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <AppButton 
                        primary 
                        width={"80%"}
                        onClick={rootBack}
                        style={{ backgroundColor: "#87C1FF", color: "white" }}
                    >
                        ATRÁS
                    </AppButton>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <AppButton 
                        primary 
                        width={"80%"}
                        onClick={submitModifications}
                    >
                        LISTO
                    </AppButton>
                </Grid>
            </Grid>
        }
    </div>
    )
}
