// import React, {useEffect} from "react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AppLabel from "../components/ui/AppLabel";
import AppButton from "../components/ui/AppButton";
// import Content from "../layout/Content";
import ButtonContent from "../layout/ButtonContent";
import Colors from "../components/ui/Colors";
import WelcomeRocket from "../img/welcomeRocket.png"
import DefaultSection from "../components/ui/DefaultSection";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import{fetchDevicesUsers} from "./../utilityFunctions/FetchUtil";
import{ renderFirstView, b2bClient } from "./../Api";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const autocompleteInfo = {
  deviceFingerprint: "",
  autocompleteUsers: false,
  usersAutocompleteArray: "",
};

const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

export default function Home(props) {
  const{updateDisplayExplanations} = props

  let history = useHistory();

  const[loading, setLoading] = React.useState(false);

  useEffect(() => {
    deviceFingerPrint();
  },[])

  const deviceFingerPrint = async () =>{
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    autocompleteInfo.deviceFingerprint = result.visitorId;
    console.log(autocompleteInfo.deviceFingerprint); //DEBUG PRINTING

    const FPVerification = {
      visitorsFingerprint: result.visitorId
    };

    setLoading(true);
    const deviceUsers = await fetchDevicesUsers(FPVerification);
      
      if(deviceUsers !== "Not found"){
        autocompleteInfo.autocompleteUsers = true;
        autocompleteInfo.usersAutocompleteArray = deviceUsers;
        setLoading(false);
        history.push({ 
          pathname: "/login", 
          state:{
            autocompleteInfo: autocompleteInfo
          }
        });
        return;
      }
      else{
        if(renderFirstView && renderFirstView === "loanSimulator"){
          setLoading(false);
          history.push({ 
            pathname: "/simulator", 
          });
          return;
        }
        else if(renderFirstView && renderFirstView === "wallet"){
          setLoading(false);
          history.push({ 
            pathname: "/register", 
          });
          return;
        }
        else{
          setLoading(false);
          history.push({ 
            pathname: "/register", 
          });
          return;
        }
        
      }
  }

  return (
    <div
      style={{
        display: "flex",
        // flexGrow: "1",
        width: "100%",
        height: "80%",
        justifyContent: "center",
        // alignContent:"center",
      }}
    >
      {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
        <div
          style={{
            // width: "100vw",
            maxWidth: "670px",
            // maxWidth: "60%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
        {b2bClient !== "IMS" ?
        <>
          <AppLabel
            logoVisible
            bgColor={"transparent"}
            title="HOLA"
            subTitle="Cambiemos la forma en la que compras"
            color={Colors.labelColor}
          />
          :<></>
        
          
            <DefaultSection 
              backImgage={WelcomeRocket} 
              bgColor={"transparent"} 
              padding={"0"}
              defaultWidth={"70%"}
              alignSelf={"center"}
              // margin={"0 0 0 4.3rem"}
            />
          
          <ButtonContent gridGap={"0.8em"}>
          {b2bClient !== "IMS" ?
              <AppButton
                primary
                onClick={() => history.push("/register")}
                style={{ marginBottom: "1rem" }}
              >
                Regístrate
              </AppButton>
            :<></>
          }
            <AppButton 
              onClick={() => history.push("/login")}
            >
              Ingresa
            </AppButton>
          </ButtonContent>
          </>
          :<></>
        }
        </div>
      }
    </div>
  );
}
