import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "@emotion/styled"
import PageLabel from "../../components/ui/PageLabel"
import Content from "../../layout/Content"
import Container from "../../layout/Container"
import Retiros from "../../img/design-june/img-light/retiros.png"
import EscudoScrow from "../../img/design-june/img-light/escudo.png"
import Transferencia from "../../img/design-june/img-light/txWupo.png"
import Colors from "../../components/ui/Colors"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CashNetworkAugust from "../../img/cash-network-maps/cashNetworkAugust.jpeg";

const PaperStyle = styled.div({
  position: "relative",
  display: "flex",
  flex: 1,
  width: "100%",
  margin: "0.5rem 0",
  padding: "1rem",
  borderRadius: "4px",
  borderTop: `4px solid ${Colors.primary}`,
  boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  transition: "all 0.2s cubic-bezier(.25,.8,.25,1)",
  [`:active`]: {
    boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
  },
})

const ChooseItem = styled.div({
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "120px",
  height: "3rem",
  right: "20px",
  top: 0,
  textAlign: "center",
  fontSize: "0.8rem",
  fontWeight: 600,
  color: "white",
  backgroundColor: Colors.primary,
  borderBottomLeftRadius: "12px",
  borderBottomRightRadius: "12px",
  boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.22)",
  cursor: "pointer",
})

const Paper = (props) => {
  const { title, subTitle, children, onClick } = props
  return (
    <PaperStyle onClick={onClick}>
      <ChooseItem>{title}</ChooseItem>
      {children}
      <div
        style={{
          flex: 1,
          paddingLeft: "1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          textAlign: "center"
        }}
      >
        <p
          style={{
            margin: 0,
            fontStyle: "italic",
            fontSize: "0.8rem",
            marginTop: "1rem",
            cursor: "pointer",
            alignSelf: "center"
          }}
        >
          {subTitle}
        </p>
      </div>
    </PaperStyle>
  )
}

export default function ChooseTx(props) {
  const history = useHistory()
  // console.log(props)
  const assetFromHandler = props.match?.params?.asset
  const [withdraw, setWithdraw] = useState(false);

  function handleWithdraw(){
    setWithdraw(true);
  };

  function handleClose(){
    setWithdraw(false);
  }

  return (
    <Content>
      <PageLabel style={{ fontSize: "1.8rem" }}>¿QUÉ HACES HOY?</PageLabel>
      <Container
        style={{ paddingTop: "1rem", width: "100%", flexDirection: "column" }}
      >
        <Paper
          title="TRANSFIERE TOKENS"
          subTitle="A otros usuarios, comercios o tus productos."
          onClick={() =>
            history.push(`/account/handle_account/transer/${assetFromHandler}`)
          }
        >
          <img src={Transferencia} width="165" alt="" />
        </Paper>
        <Paper
          title="CONTRATOS DIGITALES"
          subTitle="Has pagos protegidos por el sistema."
          onClick={() =>
            history.push(`/account/handle_account/escrow/${assetFromHandler}`)
          }
        >
          <img src={EscudoScrow} width="165" alt="" />
        </Paper>
        <Paper
          title="RETIROS"
          subTitle="Retira tus tokens cuando quieras"
          onClick={() =>
            history.push(`/account/handle_account/funding/${assetFromHandler}`)
            // setWithdraw(true)
          }
        >
          <img src={Retiros} width="120" alt="" />
        </Paper>
      </Container>

      <React.Fragment>
        <Dialog open={withdraw} onClose={handleWithdraw} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">MECANISMO DE DESCARGA (RETIRO)</DialogTitle>
          <DialogContent>
            <DialogContentText>
              DESCARGA EN LOS WUPO COMERCIOS. ENCUÉNTRALOS EN EL MAPA Y CORRE A DESCARGAR.
            </DialogContentText>
            <img className="paymentQR" src={CashNetworkAugust} alt="QR not rendered"/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
                Súper
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
          
    </Content>
  )
}
