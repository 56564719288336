import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from "react-number-format";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import {createEduProgram} from "../../../utilityFunctions/EduMarketUtil";
import { ukLocations } from '../../../Api';
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import moment from 'moment';
import 'moment/locale/es';
import swal from "sweetalert";
import SetScheduleDialog from '../edu_clients/SetScheduleDialog';
import {useHistory} from "react-router-dom";


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const newProgramrequest ={}; // this is the object that will be sent to the backend

const serviceTypes = ["Holyday Clubs", "After School Care", "Private Tuition", "Child Care"];

export default function CreateProgram(props) {
    
    const {loginUser} = props

    const history = useHistory();

    const ukterritories = ["England", "Scotland", "Wales", "Northern Ireland"];

    
    const [loading, setLoading] = React.useState(false);

    const[title, setTitle] = React.useState("");
    const[fromAge, setFromAge] = React.useState("");
    const[toAge, setToAge] = React.useState("");
    const[price, setPrice] = React.useState("");
    const[territory, setTerritory] = React.useState("");
    const[county, setCounty] = React.useState("");
    const[city, setCity] = React.useState("");
    const[address, setAddress] = React.useState("");
    const[description, setDescription] = React.useState("");
    const[serviceType, setServiceType] = React.useState("");
    const [selectedImage, setSelectedImage] = React.useState(null);

    const titleChange = (e) => {
        setTitle(e.target.value);
        newProgramrequest.title = e.target.value;
    };

    const fromAgeChange = (e) => {
        setFromAge(e.floatValue);
        newProgramrequest.fromAge = e.floatValue;
    };

    const toAgeChange = (e) => {
        setToAge(e.floatValue);
        newProgramrequest.toAge = e.floatValue;
    };

    const priceChange = (e) => {
        setPrice(e.floatValue);
        newProgramrequest.price = e.floatValue;
    };

    const territoryChange = (event, value) => {
        if(!value){
            return;
        }

        setTerritory(value);
        setCounty("");
        setCity("");
        newProgramrequest.territory = value;
        newProgramrequest.county = "";
        newProgramrequest.city = "";
    };
    
    const countyChange = (event, value) => {
        setCounty(value);
        setCity("");
        newProgramrequest.county = value;
        newProgramrequest.city = "";
    };

    const cityChange = (event, value) => {
        setCity(value);
        newProgramrequest.city = value;
    };

    const addressChange = (e) => {
        setAddress(e.target.value);
        newProgramrequest.address = e.target.value;
    };

    const descriptionChange = (e) => {
        setDescription(e.target.value);
        newProgramrequest.description = e.target.value;
    };

    const serviceTypeChange = (e) => {
        setServiceType(e.target.value);
        newProgramrequest.serviceType = e.target.value;
    };

    const selectedImageChange = async (e) => {

        setSelectedImage(e.target.files[0])
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSizeInBytes = 1 * 1024 * 1024; // 1MB in bytes

        if (file.size > maxSizeInBytes) {
            swal({
                title: "Error",
                text: "File size must not exceed 1MB",
                icon: "error",
                button: "Ok",
            });
            // Clear the input value to prevent the same file from being selected again
            event.target.value = null;
            deleteSelectedImg(null);
            return;
        }

        // Your existing logic to handle the file change
        selectedImageChange(event);
    };

    const deleteSelectedImg = () => {
        setSelectedImage(null);
    };

    const [startingTime, setStartingTime] = React.useState(null);
    const [endingTime, setEndingTime] = React.useState(null);
    const [timeArray, setTimeArray] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState([]);
    const [schedule, setSchedule] = React.useState([]);
    const [highlightedDates, setHighlightedDates] = React.useState([]);
    const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 13]);

    const startingTimeChange = (newTime) => {
        console.log(moment(newTime.toISOString()).format('HH:mm'));
        setStartingTime(moment(newTime).toISOString());
        
    };

    const endingTimeChange = (newTime) => {
        console.log(moment(newTime.toISOString()).format('HH:mm'));
        setEndingTime(moment(newTime).toISOString());
    };

    const clearTimes = () => {
        setTimeArray(prevTimeArray => [...prevTimeArray, {starting: moment(startingTime).toISOString(), ending: moment(endingTime).toISOString()}]);
        setStartingTime(null);
        setEndingTime(null);
    };

    const handleDateChange = (date) => {
        setHighlightedDates([...highlightedDates, date]);
        setSelectedDate(prevDates => [...prevDates,{ date: moment(date).format('YYYYMMDD')}]);
    };


    const addSchedule = () => {
        if (timeArray && timeArray.length > 0 && selectedDate && selectedDate.length > 0) {
            const newSchedule = {
                timeList: timeArray.map((timeEl) => ({
                    fromTiming: moment(timeEl.starting).format('HH:mm'),
                    toTiming: moment(timeEl.ending).format('HH:mm')
                })),
                dates: selectedDate,
            };
            setSchedule([...schedule, newSchedule]);
            // newProgramrequest.schedule = [...newProgramrequest.schedule, newSchedule];

            // Reset states
            setStartingTime(null);
            setEndingTime(null);
            setSelectedDate([]);
        } 
        
        else {
            swal({
                title: "Error",
                text: "Please fill all fields",
                icon: "error",
                button: "Ok",
            });
        }
    };

    const [timingBox, setTimingBox] = React.useState(false);

    const handleNewTiming = () => {
        // setTimingBox(true);
        handleOpenDialog();
    };

    const timingBoxClose = () => {
        setTimingBox(false);

        // Reset states
        setStartingTime(null);
        setEndingTime(null);
        setSelectedDate([]);
    };

    const [selectedSchedule, setSelectedSchedule] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [calendarSchedule, setCalendarSchedule] = React.useState();

    const handleOpenDialog = () => {

        setSelectedSchedule(true);
        setDialogOpen(true);
       
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const addCalendarSchedule = (selectedSchedule) => {
        if (selectedSchedule && selectedSchedule.length > 0) {
            const newSchedule = {
                // timeList: selectedSchedule.map((timeEl) => ({
                //     fromTiming: moment(timeEl.start).format('HH:mm'),
                //     toTiming: moment(timeEl.end).format('HH:mm')
                // })),
                dates: selectedSchedule.map((dateEl) => ({ 
                    fromTiming: dateEl.start.toISOString(),
                    toTiming: dateEl.end.toISOString()
                })),
            };
            setCalendarSchedule(newSchedule);
        } 
        
        else {
            swal({
                title: "Error",
                text: "Please fill all fields",
                icon: "error",
                button: "Ok",
            });
        }
    };
    

    const uploadProgram = async () => {

        // newProgramrequest.schedule = schedule; //Under review
        newProgramrequest.dates = calendarSchedule.dates;

        console.log(selectedImage);
        console.log(newProgramrequest);

        setLoading(true);

        const formData  = new FormData();

        const json = JSON.stringify(newProgramrequest);
        const newProduct = new Blob([json], {
            type: 'application/json'
        });

        formData.append("selectedImage", selectedImage, "Program-pic");
        formData.append("newProduct", newProduct);

        const createProductResponse = await createEduProgram(formData, loginUser.jwtToken);

        setLoading(false);

        if(!createProductResponse){
            swal({
                title: "Error",
                text: "Please try again later",
                icon: "error",
                button: "Ok",
            });
            return; 
        }

        swal({
            title: "Success",
            text: "Your program has been created successfully",
            icon: "success",
            button: "Ok",
        });
        clearAllStates();
        return;
    };

    const clearAllStates = () => {
        setTitle("");
        setFromAge("");
        setToAge("");
        setPrice("");
        setTerritory("");
        setCounty("");
        setCity("");
        setAddress("");
        setDescription("");
        setSelectedImage(null);
        setStartingTime(null);
        setEndingTime(null);
        setTimeArray([]);
        setSelectedDate([]);
        setSchedule([]);
        setCalendarSchedule(null);
        setServiceType("");

        newProgramrequest.title = "";
        newProgramrequest.fromAge = "";
        newProgramrequest.toAge = "";
        newProgramrequest.price = "";
        newProgramrequest.territory = "";
        newProgramrequest.county = "";
        newProgramrequest.city = "";
        newProgramrequest.address = "";
        newProgramrequest.description = "";
        newProgramrequest.schedule = [];
        newProgramrequest.dates = [];
        newProgramrequest.serviceType = "";

    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "1.5rem"},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="title"
                                name="title"
                                label={<Typography variant="body2">Title</Typography>}
                                value={title}
                                onChange={(e)=> titleChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                id="description"
                                name="description"
                                label={<Typography variant="body2">Description</Typography>}
                                value={description}
                                onChange={(e)=> descriptionChange(e)}
                                autoComplete="off"
                                multiline
                                rows={4}
                                inputProps={{ maxLength: 500 }}  // Limit the number of characters to 200
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                sx={{textAlign: "start"}}
                                variant="standard"
                                fullWidth={true}
                                id="serviceType"
                                name="serviceType"
                                select
                                label={<Typography variant="body2">Service Type</Typography>}
                                value={serviceType}
                                onChange={(e)=>serviceTypeChange(e)}
                                autoComplete="off"
                            >
                                {serviceTypes.map((el) => (
                                    <MenuItem key={el} value={el}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </TextField> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "50%"}}
                                name="fromAge"
                                customInput={TextField}
                                // prefix={'$ '}
                                suffix={' years'}
                                type="text"
                                label={<Typography variant="body2">From Age</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={fromAge}
                                onValueChange={(values) => {
                                    // const {formattedValue, value, floatValue} = values;
                                    fromAgeChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "50%"}}
                                name="toAge"
                                customInput={TextField}
                                // prefix={'$ '}
                                suffix={' years'}
                                type="text"
                                label={<Typography variant="body2">To Age</Typography>}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                autoComplete="off"
                                value={toAge}
                                onValueChange={(values) => {
                                    // const {formattedValue, value, floatValue} = values;
                                    toAgeChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <NumberFormat
                                variant="standard"
                                style={{width: "100%", height: "50%"}}
                                name="toAge"
                                customInput={TextField}
                                prefix={'£ '}
                                // suffix={' years'}
                                type="text"
                                label={<Typography variant="body2">Price</Typography>}
                                thousandSeparator={','}
                                decimalSeparator={'.'}
                                autoComplete="off"
                                value={price}
                                onValueChange={(values) => {
                                    // const {formattedValue, value, floatValue} = values;
                                    priceChange(values);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Autocomplete sx={{ width: "100%"}}
                                options={ukterritories}
                                getOptionLabel={(option) => option}
                                
                                id="territory"
                                name="territory"
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"Territory not found"}
                                fullWidth
                                onChange={territoryChange}
                                // onInputChange={onChange}
                                renderInput={(params) => (
                                    <TextField  {...params} label="Territory" variant="standard" />
                                )}
                            />
                        </Grid>
                        {territory && 
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Autocomplete sx={{ width: "100%"}}
                                    options={
                                        [...new Set(
                                            ukLocations
                                                .filter(location => location.Territory === territory)
                                                .map(location => location.County)
                                        )]
                                    }
                                    getOptionLabel={(option) => option}
                                    
                                    id="county"
                                    name="county"
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"County not found"}
                                    fullWidth
                                    onChange={countyChange}
                                    // onInputChange={onChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="County" variant="standard" />
                                    )}
                                />
                            </Grid>
                            {county &&
                                <>
                                {console.log(`Teeritory: ${territory}, County: ${county}`)}
                                <Grid item xs={12} sm={6} md={6}>
                                    <Autocomplete sx={{ width: "100%"}}
                                        options={[
                                            ...new Set(
                                                ukLocations
                                                    .filter(location => location.Territory === territory && location.County === county)
                                                    .map(location => location.Name)
                                            )
                                        ]}
                                        getOptionLabel={(option) => option}
                                        
                                        id="city"
                                        name="city"
                                        clearOnEscape
                                        autoComplete = {true}
                                        noOptionsText = {"City or town not found"}
                                        fullWidth
                                        onChange={cityChange}
                                        // onInputChange={onChange}
                                        renderInput={(params) => (
                                            <TextField  {...params} label="City or Town" variant="standard" />
                                        )}
                                    />
                                </Grid>
                                </>
                            }
                            </>
                        }
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                id="address"
                                name="address"
                                label={<Typography variant="body2">Address</Typography>}
                                value={address}
                                onChange={(e)=> addressChange(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        {!selectedImage &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Upload Image
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            handleFileChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {selectedImage &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center", marginTop: "2rem"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{selectedImage.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteSelectedImg}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Delete
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} sm={6} md={6}>
                            <Button onClick={handleNewTiming} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Add Schedule
                            </Button>
                        </Grid>
                        {title && fromAge && toAge && price && territory && county && city && address && description && selectedImage && serviceType && calendarSchedule &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button onClick={uploadProgram} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                    Create
                                </Button>
                            </Grid>
                        }
                    </Grid>
                }
                <Dialog open={timingBox} onClose={timingBoxClose}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "90%", sm: "50%", md: "50%"},
                                height: "90%"
                            },
                        },
                    }}
                >
                    <Grid container direction="row" spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                        <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center", width: "100%", marginTop: "1.5rem"}}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label={<Typography variant="body2">Select Starting Time</Typography>}
                                    value={startingTime}
                                    onChange={startingTimeChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center", width: "100%", marginTop: "1.5rem"}}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <TimePicker
                                    label={<Typography variant="body2">Select Ending Time</Typography>}
                                    value={endingTime}
                                    onChange={endingTimeChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx= {{display: "flex", justifyContent: "center", marginBottom: "1rem"}}>
                            <Button variant="contained" onClick={clearTimes}>
                                Add Schedule
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{width: "100%", marginTop: "0.5rem"}}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <CalendarPicker
                                    timezone="Europe/London"
                                    inputFormat="DD/MM/YYYY"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    disablePast={true}
                                    renderInput={(params) => 
                                        <TextField {...params} 
                                            variant="standard"
                                            fullWidth
                                            sx={{width: {xs: "90%", sm: "40%", md: "40%"}}}
                                        />}
                                />
                                {/* <StaticDatePicker
                                    orientation="portrait"
                                    timezone="Europe/London"
                                    inputFormat="DD/MM/YYYY"
                                    value={selectedDate}
                                    // disablePast={true}
                                    onChange={handleDateChange}
                                    slots={{
                                        day: (props) => {
                                            const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.getDate()) >= 0;
                                        
                                            return (
                                            <Badge
                                                key={props.day.toString()}
                                                overlap="circular"
                                                badgeContent={isSelected ? <CheckIcon htmlColor="red" /> : undefined}
                                            >
                                                <PickersDay {...props} />
                                            </Badge>
                                            );
                                        },
                                    }}
                                /> */}
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <Button variant="contained" onClick={timingBoxClose}>
                                Close Schedule
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <Button variant="contained" onClick={addSchedule}>
                                Add Schedule
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                {selectedSchedule && (
                    <>
                    <SetScheduleDialog
                        open={dialogOpen}
                        handleClose={handleCloseDialog}
                        defaultStarting={new Date()}
                        addCalendarSchedule={addCalendarSchedule}
                        title={newProgramrequest.title}
                    />
                    </>
                )}
            </Box>
        </Box>
    )
}
