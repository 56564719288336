import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import AppButton from "../../components/ui/AppButton";
import MenuList from '@mui/material/MenuList';
import Colors from "../../components/ui/Colors"; 
import Divider from '@mui/material/Divider';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
    menuList: {
        overflowY: "scroll",
    },
    divider:{
        background: Colors.primary
    },
}));

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
};

let loansInfo;

export default function KpiDefaultLoans(props) {
    const classes = useStyles();
    const history = useHistory();

    const[loading, setLoading] = React.useState(false);

    React.useEffect(() =>{
        loansInfo = props.location.state.defaultLoans;

        renderLoans();
    }, []);

    const handleClick = () => {
        history.push({pathname: "/admin-kpi"});
    };


    const[loansList, setLoanList] = React.useState('');

    const renderLoans = () =>{
        setLoading(true);
        console.log(loansInfo.length > 0)
        if(loansInfo.length > 0){
            setLoanList(
                <>
                    <MenuList className={classes.menuList}>
                    {loansInfo.map((loan) => {
                        return(
                            <>
                                <Divider key={loan.id} className={classes.divider}/>
                                <Grid key={loan.id} container direction="row" className={classes.gridInputs}>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                            Código crédito:
                                        </Typography>
                                    </Grid>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                            {`${loan.id}`} 
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                            Fecha del crédito:
                                        </Typography>
                                    </Grid>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                            {`${new Intl.DateTimeFormat("es-CO", 
                                                            dateOptions).format(new Date(loan.loanDate))}`} 
                                        </Typography>
                                    </Grid>

                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            Saldo inicial:
                                        </Typography>
                                    </Grid>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            {`$ ${new Intl.NumberFormat('es-CO').format(loan.initialBalance)}`}
                                        </Typography>
                                    </Grid>

                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            Saldo actual:
                                        </Typography>
                                    </Grid>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            {`$ ${new Intl.NumberFormat('es-CO').format(loan.currentBalance)}`}
                                        </Typography>
                                    </Grid>

                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            Cuotas:
                                        </Typography>
                                    </Grid>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            {`${loan.dues}`}
                                        </Typography>
                                    </Grid>

                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            Periodo:
                                        </Typography>
                                    </Grid>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            {loan.termUnit === "day" ? `${loan.term} días`: loan.termUnit === "month" ? `${loan.term} meses`: 
                                            loan.termUnit === "week" ? `${loan.term} semanas` : `${loan.term} días`}
                                        </Typography>
                                    </Grid>

                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            Días en mora:
                                        </Typography>
                                    </Grid>
                                    <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                        <Typography gutterBottom variant="body2" component="div">
                                            {loan.defaultDays}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    })}
                    </MenuList>
                    <Grid container direction="row" className={classes.gridInputs}>
                        <Grid item xs={12} sm={12} md={12}>
                            <AppButton 
                                primary
                                width={"45%"}
                                onClick={handleClick}
                            >
                                LISTO
                            </AppButton>
                        </Grid>
                    </Grid>
                </>
                            
            )
        }else{
            setLoanList(
                <>
                    <Typography gutterBottom variant="body1" component="div" style={{marginTop: "2em", fontWeight: "bold"}}>
                        No hay créditos con esta altura de mora
                    </Typography>
                    <Grid container direction="row" className={classes.gridInputs}>
                        <Grid item xs={12} sm={12} md={12}>
                            <AppButton 
                                primary
                                width={"45%"}
                                style={{marginTop: "2em"}}
                                onClick={handleClick}
                            >
                                LISTO
                            </AppButton>
                        </Grid>
                    </Grid>
                </>
            )
        }
            setLoading(false);
    };

    return (
            <div
                style={{
                    // display: "flex",
                    maxWidth: "600px",
                    justifyContent: "center",
                    width: "100%",
                    height: "75%",
                    textAlign: "center",
                    // overflow: "scroll"
                }}
            >
            {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
                
                loansList
                }
            </div>
    )
}
