import React, { useState, useEffect } from "react";
import { useHistory, Link} from "react-router-dom";
import { PuffLoader } from "react-spinners";
import swal from "sweetalert";
import { css } from "@emotion/react";
import ContentForm from "../../layout/ContentForm";
import StepProgress from "../../components/ui/StepProgress";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Colors from "../../components/ui/Colors";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import discAuthSecondFactorTextlaimerText from '../../Texts/Security';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {getUxLanguage, createWebauthnCredentials, verifyWebauthnCredentials, getWebauthnCredentials} from "../../utilityFunctions/AuthUtil";
import { fetchDevicesUsers } from "../../../src/utilityFunctions/FetchUtil";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {updateExplanations} from "../../../src/utilityFunctions/AdminUtil";
import {loginExplanation} from "../../Texts/Explanations";
import { b2bClient, webauthnPKRpId } from "../../Api";
import {authCondition, operationUnsuccesful, authConditionEmail} from "../../Texts/OperationResult";
// import { useTranslation } from 'react-i18next';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const userNewDevice = {
      
  username: "",
  password: "",
  totpToken: "",
  deviceConfirmation: "CONFIRMATION_PENDING",
  deviceFingerprint: "",
  totpEnabled: false
};

let secondFactorCallTrigger = false;

const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

let profileCompletedCondition = false;

const useStyles = makeStyles((theme) => ({
  
  dialogStyle:{
    whiteSpace: "pre-line"
  },

  enableButton:{
    backgroundColor: "red",
  },
  
  autocomplete: {
    width: "16em",
  },

}));

 const loginData = {
        accountId: "",
        ilpBearerToken: "",
        totpToken: "",
        jwtToken: "",
        profileCompleted: "",
        roles: "",
 };

let autocompleteUsers = false; 

let usersAutocompleteArray;

let isRootOrOperator = false;

const openCard = true;

let userCancelledWebAuthn = false;

export default function Login(props) {
  const { userdata, wupoServerUri, updateNavBarEnabled, updateDisplayExplanations,
    loadProductsAndSetting, 
    navBarEnabled} = props;
  const history = useHistory();

  const classes = useStyles();

  // const { t, i18n } = useTranslation();

  const steps = [
    {
      key: "username",
      name: "Email",
      value: "",
    },
    {
      key: "password",
      name: "Password",
      value: "",
    },
    {
      key: "token",
      name: "TOKEN",
      value: "",
    },
  ];

  
  const [userWithourWebAuthn, setUserWithourWebAuthn] = useState(false);
  const getUserCredentialsAuto = async (fpCredentials) => {
    

    const requestObject = {};
    requestObject.username = fpCredentials.user;

    setLoading(true);
    const response = await getWebauthnCredentials(requestObject, loginData.jwtToken);
    setLoading(false);

    if(!response){
      // const usernameObject ={};
      // usernameObject.user = userInfo.username;
      // newWebAuthnCredentials(usernameObject);
      // handleNextClick();
      setUserWithourWebAuthn(true);
      // console.log("No webauthn credentials");
      
      return;
    }

    const getWebAuthnRequest = {};
    getWebAuthnRequest.webauthnCredentials = response;
    // console.log(getWebAuthnRequest);
    getWebAuthn(getWebAuthnRequest);

    return;
  };

  const getUserCredentials = async () => {

    // console.log("Getting webauthn credentials");    

    const requestObject = {};
    requestObject.username = userInfo.username;

    setLoading(true);
    const response = await getWebauthnCredentials(requestObject, loginData.jwtToken);
    console.log(response);
    setLoading(false);

    if(!response){
      console.log("No webauthn credentials");
      handleNextClick();

      // const usernameObject ={};
      // usernameObject.user = userInfo.username;
      // newWebAuthnCredentials(usernameObject);
      return;
    }

    const getWebAuthnRequest = {};
    getWebAuthnRequest.webauthnCredentials = response;
    // console.log(getWebAuthnRequest);
    getWebAuthn(getWebAuthnRequest);

    return;
  };

  const [haveWebAuthnCredentials, setHaveWebAuthnCredentials] = useState(false);

  const getWebAuthn = async(userfpCredentials) => {

    console.log(userfpCredentials);

    if(!userfpCredentials || !userfpCredentials.webauthnCredentials){
      // const usernameObject ={};
      // usernameObject.user = userInfo.username;
      
      // newWebAuthnCredentials(usernameObject);
      console.log("No webauthn credentials");
      handleNextClick();
      return;
    }

    const webAuthnCredentials = userfpCredentials.webauthnCredentials;

    const options = {
      publicKey: {
        // Challenge to prevent replay attacks
        challenge: new Uint8Array(32),
        // Allow all associated credential IDs for the user
        allowCredentials: webAuthnCredentials.map((credentials) => ({
          type: 'public-key',
          id: base64urlToUint8Array(credentials.credentialId),
          transports: ['internal'] // Transports the authenticator supports
        })),
      },
    };

    console.log(options);

    try{
      // console.log(options);
      setLoading(true);
      const assertionResponse = await navigator.credentials.get(options);
      console.log("User has an authenticator registered:", assertionResponse);
      setHaveWebAuthnCredentials(true);

      // Extract authenticator data
      const authenticatorDataArrayBuffer = assertionResponse.response.authenticatorData;
      // Convert the authenticator data to base64 string
      const authenticatorDataBase64 = arrayBufferToBase64Auth(authenticatorDataArrayBuffer);


      // Extract the signature
      const signatureArrayBuffer = assertionResponse.response.signature;
      // Convert the signature to base64 string
      const signatureBase64 = arrayBufferToBase64Auth(signatureArrayBuffer);


      //Extract clientDataJSON and SHA256 as the signature seems to be over authenticatorData + SHA256(clientDataJSON)

      const clientDataJSON = assertionResponse.response.clientDataJSON;
      // Convert the signature to base64 string
      const clientDataJSONBase64 = arrayBufferToBase64Auth(clientDataJSON);



      const webathnVerRequest = {
        username: userfpCredentials.user,
        credentialId: assertionResponse.id,
        authenticatorData: authenticatorDataBase64,
        clientDataJSON: clientDataJSONBase64,
        signature: signatureBase64
      };

      // console.log(webathnVerRequest);

      const response = await verifyWebauthnCredentials(webathnVerRequest, loginData.jwtToken);
      setLoading(false);

      if(!response){
        console.log("Error verifying webauthn credentials");
        return;
      };

      console.log(response);

      handleWebAuthnResponse(response);


    }catch(error){
      
      // userCancelledWebAuthn = true;
      setLoading(false);
      console.log(error);

      // const usernameObject ={};
      // usernameObject.user = userInfo.username;

      // newWebAuthnCredentials(usernameObject);

      handleNextClick();
    }

  };

  const handleWebAuthnResponse = async(data) => {
    if (data.accessToken && data.verified === true) {
      // console.log(navBarEnabled);
      loginData.accountId = data.username;
      loginData.ilpBearerToken = data.ilpBearerToken;
      loginData.totpToken = data.totpToken;
      loginData.jwtToken = data.accessToken;
      loginData.profileCompleted = data.profileCompleted;
      loginData.roles = data.roles;


      profileCompletedCondition = data.profileCompleted;
      
      userdata({
        accountId: data.username,
        ilpBearerToken: data.ilpBearerToken,
        totpToken: data.totpToken,
        jwtToken: data.accessToken,
        profileCompleted: data.profileCompleted,
        roles: data.roles
      });

      //Load an array that defines which, if any, explanation messages will be displayed
      // loadExplanations(data.username);
      const profileName = await determineProfile(data)

      if(profileName && profileName !== "ROOT" && profileName !== "OPERATOR"){
        
        if(data.profileCompleted){
          // console.log(navBarEnabled);
          
          await updateNavBarEnabled(true);

          history.push("/edu_programs/active_programs");

        }else{
          // await updateNavBarEnabled(false); //Disable NavBar


          const loginParams ={
            username: userNewDevice.username,
            password: userNewDevice.password
          };

          history.push({
            pathname: "/account/generalinfo",
            state: {
              loginParams: loginParams
            }
          });
        }

      
      }
      
      
    } 
    else {
      setLoading(false);
      swal({
        title: "Incorrect Credentials",
        // text: t("Login.incorrectCredentials"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 

  }

  const sha256 = async (message) => {
    // Encode the message as UTF-8
    const msgBuffer = new TextEncoder().encode(message);
    // Calculate the hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // Convert the hash buffer to a Uint8Array
    return new Uint8Array(hashBuffer);
  };

  const newWebAuthnCredentials = async (userCredentials) => {

    console.log(userCredentials);

    const hashedUsername = await sha256(userCredentials.user);

    const createWebAuthnOptions = {
      publicKey:{
        rp:{id: webauthnPKRpId , name: "Wupo"},
        user:{
          id: hashedUsername,
          name: userCredentials.user,
          displayName: userCredentials.user,
        },
        challenge: generateRandomChallenge(32),
        pubKeyCredParams: [
          {type: "public-key", alg: -7},
          { type: "public-key", alg: -257 } // -257 represents RS256
        ],
        authenticatorSelection: {
          authenticatorAttachment: "platform",
          userVerification: "required" // Requires user verification during credential creation
        }
      },
      allowCredentials: [
        {
          id: "credentialsIdentifierOne", // Identifier for the platform authenticator
          type: "public-key",
          transports: ["internal"] // Specify "internal" transport for platform authenticators
        },
        // Add other credentials as needed
      ]
    };

    console.log(createWebAuthnOptions);
    
    try{
      setLoading(true);
      const webAuthnResponse = await navigator.credentials.create(createWebAuthnOptions);

      if(!webAuthnResponse){
        setLoading(false);
        console.log("No response from webauthn");
        
        //If the user cancel the registration or the device + browser cannot handle the registration the user
        //will continue with the traditional password registration
        // handleNextClick();
        return;
      };

      // console.log(webAuthnResponse);

      // Extract public key
      const publicKeyArrayBuffer = webAuthnResponse.response.getPublicKey();
      // Convert the public key to base64 string
      const publicKeyBase64 = arrayBufferToBase64Auth(publicKeyArrayBuffer);


      // Extract the authenticator data
      const authenticatorDataArrayBuffer = webAuthnResponse.response.getAuthenticatorData();
      // Convert the authenticator data to base64 string
      const authenticatorDataBase64 = arrayBufferToBase64Auth(authenticatorDataArrayBuffer);

      

      //Challenge validation
      const clientDataJSON = new TextDecoder().decode(webAuthnResponse.response.clientDataJSON);
      const clientData = JSON.parse(clientDataJSON);
      const receivedChallengeBytes = base64urlToUint8Array(clientData.challenge);


      if(!isEqualUint8Arrays(receivedChallengeBytes, createWebAuthnOptions.publicKey.challenge)){
        // console.log("Challenge validation failed");
        return;
      }

      const credentialsRequest = {};
      credentialsRequest.username = userCredentials.user;
      credentialsRequest.credentialId = webAuthnResponse.id;
      credentialsRequest.publicKey = publicKeyBase64;
      credentialsRequest.authenticatorData = authenticatorDataBase64;

      setLoading(true);
      const response = await createWebauthnCredentials(credentialsRequest, loginData.jwtToken);
      setLoading(false);

      if(!response){
        swal({
          title: "Unsuccessful Biometric register",
          icon: "error",
          button: "Ok",
        }).then(() => {
          return;
        });
      }

      swal({
        title: "Successful Biometric register",
        icon: "success",
        button: "Ok",
      }).then(() => {
        return;
      });

      // console.log(response);
    }catch(error){
      setLoading(false);
      console.log(error);
      // handleNextClick();
      return;
    }

  };

  const isEqualUint8Arrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
  }

  const base64urlToUint8Array = (base64urlString) => {
    const base64String = base64urlString.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64String);
    const buffer = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
  };

  // Function to convert ArrayBuffer to base64
  const arrayBufferToBase64Auth = (arrayBuffer) => {
    const binary = new Uint8Array(arrayBuffer);
    const binaryString = String.fromCharCode.apply(null, binary);
    return btoa(binaryString);
  }

  const generateRandomChallenge = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return array;
}

  const [userInfo, setUserInfo] = useState({
    username: "",
    password: "",
    token: "",
  });


  const [stepNumber, setStepNumber] = useState(0);
  const [inputText, setInputText] = useState("");
  const [inputType, setInputType] = useState("text");
  const [length, setLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [authFactor, setAuthFactor] = useState(false);

  useEffect(() => {
    setInputText("");
    if(steps[stepNumber].key === "password") {
      setInputType("password")
    } else if(steps[stepNumber].key === "token") {
      setInputType("number")
      setLength(6)
    } else {
      setInputType("text")
    }
  }, [stepNumber]);

  const [usersAssignedToDevice, setUsersAssignedToDevice] = useState(
    {
    options: [{user: "Sin usuario", pwd: ""}],
    getOptionLabel: (option) => option.user,
    }
  );
  
  const deviceFingerPrint = async () =>{
    
    setLoading(true);
    if(props.location.state && props.location.state.autocompleteInfo){
      userNewDevice.deviceFingerprint = props.location.state.autocompleteInfo.deviceFingerprint;
      autocompleteUsers = props.location.state.autocompleteInfo.autocompleteUsers;
      usersAutocompleteArray = props.location.state.autocompleteInfo.usersAutocompleteArray;
      // console.log(userNewDevice.deviceFingerprint); // DEBUG PRINTING
      setLoading(false);
      setUsersAssignedToDevice(
        {
          options: usersAutocompleteArray,
          getOptionLabel: (option) => option.user,
        }
      )
      
    }
    else{
      const fp = await fpPromise
      const result = await fp.get()

      // This is the visitor identifier:
      const visitorId = result.visitorId
      userNewDevice.deviceFingerprint = visitorId;
      // console.log(userNewDevice.deviceFingerprint) // DEBUG PRINTING

      const FPVerification = {
        visitorsFingerprint: visitorId
      };
      const deviceUsers = await fetchDevicesUsers(FPVerification);
        
      if(deviceUsers !== "Not found"){
        autocompleteUsers = true;
        usersAutocompleteArray = deviceUsers;
        setLoading(false);
        setUsersAssignedToDevice(
          {
            options: deviceUsers,
            getOptionLabel: (option) => option.user,
          }
        )
        
        // console.log(usersAssignedToDevice);
      }
      else{
        setLoading(false);
      }

    }
  };

  const explanationMessages = () => {
    if(!props.location.state){
      swal({
          title: "Explicación",
          text: loginExplanation,
          icon: "success",
          button: "Entiendo",
      });
    }
  };

  const navBarEnabler = async () => {
    await updateNavBarEnabled(false);
  };

  useEffect(() => {
    userCancelledWebAuthn = false;
    // fetchUxLang();
    navBarEnabler();
    // console.log(navBarEnabled);

    if(b2bClient && b2bClient !== "IMS"){
      explanationMessages();
    }
    
    userNewDevice.totpEnabled = false;
    secondFactorCallTrigger = false;
    autocompleteUsers = false;
    userNewDevice.deviceConfirmation = "CONFIRMATION_PENDING";

    /**visitor device Fingerprint identifier */
    deviceFingerPrint();

    /**Initialize the checkbox with false to only show it in its view */
    setAuthFactor(false);
  }, []);

  const fetchUxLang = async() => {
    const langResponse = await getUxLanguage();

    if(!langResponse){
      return;
    }

    // i18n.changeLanguage(langResponse.language);
  };

  const onChange = (e) => {
    setInputText(e.target.value);
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: e.target.value,
    });
  };

  const onChangeAutocomplete = (event, value) => {
    // console.log(value);
    if(!value){
      return;
    }

    //turning off the autocomplete so that a password is requested to the user
    autocompleteUsers = false;

    setInputText(value.user);
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: value.user,
    });

    // getWebAuthn(value);
    // console.log("Autocomplete" + autocompleteUsers); //DEBUG
    getUserCredentialsAuto(value);
  };

  const login = async () => {
    // console.log(`Automcomplete ${autocompleteUsers}`)
    // console.log(navBarEnabled);
    const user = {
      username: userInfo.username,
      password: userInfo.password,
      totpToken: userInfo.token.substring(0, length),
    };
    userNewDevice.username = user.username; 
    userNewDevice.totpToken = user.totpToken;

    if(autocompleteUsers === false){
      userNewDevice.password = user.password;
    } else{
      // autocompleteUsers = false; //UNDER REVIEW
    }
    // console.log(userNewDevice); //LOANS DEBUG PRINTING

    const response = await fetch(`${wupoServerUri.devnet}/api/auth/login`, {
      method: "POST",
      // body: JSON.stringify(user),
      body: JSON.stringify(userNewDevice),
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch((error) => {
      setLoading(false);
      setStepNumber(0);
      setLength(0);
      swal({
        title: "Error in the request",
        // text: operationUnsuccesful,
        icon: "error",
        button: "Ok",
      });
      // console.log("Error msg: " + error);
      return;
    });
    // console.log(loading);
    // console.log(response.status);
    const data = await response.json();
  
    // console.log(data); //DEBBUG
    if(data.action){
      loginScenarios(data);
      return;
    }

    else if (data.accessToken && data.verified === true) {
      console.log(userCancelledWebAuthn);
      if(userCancelledWebAuthn === false){
        //Enabling the webauthn credentials and wait until finished due to an error or client close swal after success
        console.log("New webauthn credentials");
        const usernameObject ={};
        usernameObject.user = data.username;
        await newWebAuthnCredentials(usernameObject);
      }
      

      //Continue with the login

      // console.log(navBarEnabled);

      loginData.accountId = data.username;
      loginData.ilpBearerToken = data.ilpBearerToken;
      loginData.totpToken = user.totpToken;
      loginData.jwtToken = data.accessToken;
      loginData.profileCompleted = data.profileCompleted;
      loginData.roles = data.roles;


      profileCompletedCondition = data.profileCompleted;
      
      userdata({
        accountId: data.username,
        ilpBearerToken: data.ilpBearerToken,
        totpToken: user.totpToken,
        jwtToken: data.accessToken,
        profileCompleted: data.profileCompleted,
        roles: data.roles
      });

      // activate the webauthn after a successfull login


      const profileName = await determineProfile(data)
      if(profileName && profileName !== "ROOT" && profileName !== "OPERATOR"){
        //If the user is not root or operator the initial info is loaded

        if(data.profileCompleted){
          // console.log(navBarEnabled);
          
          await updateNavBarEnabled(true);

          history.push("/edu_programs/active_programs");

        }else{
          // await updateNavBarEnabled(false); //Disable NavBar


          const loginParams ={
            username: userNewDevice.username,
            password: userNewDevice.password
          };

          history.push({
            pathname: "/account/generalinfo",
            state: {
              loginParams: loginParams
            }
          });
        }

      
      }
      
      
    } 
    else if(data.status === 401){
      setLoading(false);
      swal({
        title: "Incorrect Credentials",
        // text: t("Login.incorrectCredentials"),
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 
    else if(data.status === 422){
      setLoading(false);
      swal({
        title: "Incorrect Token ",
        // text: `\n El token dinámico que ingresaste es incorrecto (los 6 dígitos que te salen en Google Authenticator). 
        
        // Por favor vuelve a intentarlo.
        
        // Si sigues teniendo problemas, por favor contáctanos`,
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    } 
    
    else if(data.message === "Your account has been locked due to 3 failed attempts. It will be unlocked after 24 hours."){
      setLoading(false);
      swal({
        title: "Account blocked",
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return;
    }
    else{
      setLoading(false);
      swal({
        title: "Error with the request",
        // text: operationUnsuccesful,
        icon: "error",
        button: "Ok",
      });
      setStepNumber(0);
      setLength(0);
      return
    }
  
  };

  const loginScenarios = (data) => {
    if(data.action === "confirmation"){
      setLoading(false);
      swal({
        title: "Confrimation required",
        text: `\n Confírmanos si este va a ser el dispositivo con el que ingresarás`,
        icon: "success",
        buttons: {
          confirmed: "Confirmo", 
          notConfirmed: "No Confirmo"}
      })
      .then((value)=>{
  
        switch(value){
          case "confirmed":
            // console.log("confirmed") // DEBUG
            userNewDevice.deviceConfirmation = "FIRST_LOGIN_DEVICE_CONFIRMED";
            // console.log(userNewDevice); //DEBBUG
            // loginNewDevice();
            login();
            break;
          case "notConfirmed":
            // console.log("not confirmed") //DEBUG
            userNewDevice.deviceConfirmation = "NOT_CONFIRMED";
            // loginNewDevice();
            login();
            break;
    
          default:
            break;
        }
        
      });

      return;
    }
    else if(data.action === "emailconfirmation"){
      setLoading(false);
      swal({
        title: "Confirmation required",
        text: "Please confirm your email and then push the confirmation button",
        icon: "success",
        button: "Confirmed"
      }).then(() =>{
          setLoading(true);
          login();
      });

      return;
    }

    else if(data.action === "email"){
      setLoading(false);
      swal({
        title: "Confirmation required",
        text: "Please confirm your email and then push the confirmation button",
        icon: "success",
        button: "Confirmado"
      })
      .then(()=>{
        userNewDevice.deviceConfirmation = "DEVICE_EMAIL_CONFIRMED";
        if(!data.totpEnabled){
          // setLoading(false);
          // console.log("Entering !totpEnabled") //DEBUG
          setLoading(true);
          login();
          // handleSecondFactorBox();
        }
        else{
          swal({
            title: "GRACIAS POR LA CONFIRMACIÓN",
            text: `\n Por favor vuelve a ingresar tu código dinámico. Ya se te venció\n\n`,
            icon: "success",
            button: "Listo"
          }).then(() => {

              setInputText("");
              setStepNumber(2);
              setLength(6);
            
          });
        }
      });

      return;
    }
    else if(data.action === "totp"){
      setLoading(false);
      //If totp is enabled the user must enter the totp
      userNewDevice.totpEnabled = true;
      setAuthFactor(true);
      setInputText("");
      setStepNumber(2);
      setLength(6);
    }
  };

  const loadExplanations = (username) => {
    setLoading(true)
    updateDisplayExplanations(username).then((explanations) => {
      if(explanations && explanations.loginMessage){
        updateExplanations(username, "loginMessage");
      }     
    });
  }

  const loadProductosInfo = async (data) => {

    const loadingResult = await loadProductsAndSetting("/", data.username, data.accessToken);

    return new Promise((solve) => {
      solve(loadingResult);
    });
  };

  const handleNextClick = () => {
    // console.log(inputText)
    if (!inputText) return;
    if (stepNumber < steps.length - 2) {
      /* Autocomplete checkpoint*/
      if(steps[stepNumber].key === "username" && autocompleteUsers === true){
        // console.log("Autocomplete checkpoint");
        const autoUserFiltered = usersAutocompleteArray.find((autoUser) => autoUser.user === inputText);
        if(!autoUserFiltered){
          autocompleteUsers = false;
          setStepNumber(stepNumber + 1);
          return;
        }
        // console.log(autoUserFiltered); //LOANS DEBUG PRINTING
        userNewDevice.password = autoUserFiltered.password;
        // console.log(userNewDevice.password); //LOANS DEBUG PRINTING
        setLoading(true);
        login();
      } else{
          setStepNumber(stepNumber + 1);
          return;
      }

    } else {
        setLoading(true);
        login();
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  }

  //Authentication second factor varibales to open a dialog box and set if enabled or not since the registration
  const [secondFactorBox, setSecondFactorBox] = React.useState(false);

  function handleSecondFactorBox(){
    setSecondFactorBox(true);
  }

  //TODO: Being tested
  const handleEnableSecondFactor= () => {
    // setSecondFactor(true);
    
    swal({
      title: "CONFRIMACIÓN HABILITACIÓN",
      text: `\n Por favor confírmanos que quieres activar el código dinámico`,
      icon: "success",
      buttons: {
        confirmed: "Confirmo", 
        notConfirmed: "Atrás"}
    }).then((value  )=>{
      switch(value){
        case "confirmed":
          userNewDevice.totpEnabled = true;
    
          handleSecondFactorClose();
          setLoading(true);
          enableSecondFactor();
          //Call the QRRegisteer component
          break;
        case "notConfirmed":
          handleSecondFactorBox();
          break;
        default:
          break;
      }
    });

    
  };

  const handleDisableSecondFactor= async () => {
    handleSecondFactorClose();

    userdata({
      ...loginData
    });

    if(profileCompletedCondition){
      updateNavBarEnabled(true);
      history.push("/edu_programs/active_programs");
    }else{
      await updateNavBarEnabled(false);
      history.push("/account/generalinfo");
    }
  };

  const handleSecondFactorClose = () => {
    setSecondFactorBox(false);
   
  };

  const enableSecondFactor = () => {
      // console.log(userNewDevice) // DEBUG
      fetch(`${wupoServerUri.devnet}/api/auth/enabletotp`, {
        method: "POST",
        body: JSON.stringify(userNewDevice),
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) =>{
        // console.log(response.status)
        if(response.status === 200){
          setLoading(false);
          return response.arrayBuffer();
        }else if(response.status === 202){
          setLoading(false);
          swal({
            title: "Factor adicional deshabilitado",
            text: `\n Desahabilitaste uno de los factores de autenticación`,
            icon: "success",
            button: "Entendido",
          }).then(() => {
            
            handleDisableSecondFactor(); 
            
          });
        }
      }).then((body) => {
        let imgBase64 = arrayBufferToBase64(body);
        let base64Flag = "data:image/jpeg;base64,"
        const img = base64Flag + imgBase64;

        history.push({
          pathname: "/qrregister",
          state: {
            qrImg: img
          }
        })
      })
      .catch((error) => {
        setLoading(false);
        setStepNumber(0);
        setLength(0);
        swal({
          title: "Error in the request",
          // text: `\n Por favor vuelve a intentarlo.`,
          icon: "error",
          button: "Ok",
        });
        // console.log("Error msg: " + error);
      });
  }

  const handleAuthFactor = () => {
     //Move from here
    secondFactorCallTrigger = true;
    // enableSecondFactor();
  }

  const[profile, setProfile] = React.useState('');

  const determineProfile = (loginUser) =>{
    return (new Promise((resolve) => {
      if(loginUser.roles && !(Object.keys(loginUser.roles).length === 0 && loginUser.roles.constructor === Object)){
        
        if(loginUser.roles.some((rol) => rol.authority === "ADMIN")){
          setProfile("ADMIN");
          resolve("ADMIN");
          // history.push({pathname: "/account/handle_account"});
        }

        else if(loginUser.roles.some((rol) => rol.authority === "ROOT")){
          isRootOrOperator=true;
          setProfile("ROOT");
          resolve("ROOT")
          history.push({pathname: "/root-profile"});

        }

        else if(loginUser.roles.some((rol) => rol.authority === "OPERATOR")){
          isRootOrOperator=true;
          setProfile("OPERATOR");
          resolve("OPERATOR")
          history.push({pathname: "/operator-role"});
        }

        else{
          setProfile("USER");
          resolve("USER");
        }

      }
    }));
      
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexGrow: "1",
          justifyContent: "center",
        }}
      >
        <Box sx={{
          width: "100%",
          maxWidth: "615px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "space-evenly",
        }}>
          {loading ? (
            <PuffLoader size={200} color={Colors.secondary} css={cssLoader} />
          ) : (
            <>
              <StepProgress stepNumber={stepNumber} steps={steps} />
              <PageLabel>{steps[stepNumber].name}</PageLabel>
              {steps[stepNumber].key === "username" && autocompleteUsers ? (
                <Autocomplete className={classes.autocomplete}
                  {...usersAssignedToDevice}
                  id="username"
                  clearOnEscape
                  autoComplete = {true}
                  noOptionsText = {"This user is not associated with this device"}
                  // sx={{ width: 600 }}
                  onChange={onChangeAutocomplete}
                  onInputChange={onChange}
                  renderInput={(params) => (
                    <TextField {...params} label={"Email"} variant="standard" />
                  )}
                />
              ) : steps[stepNumber].key === "password" ? (
                <Box sx={{maxWidth: "300px", width: "100%"}}>
                  <TextField
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    variant="standard"
                    value={inputText}
                    onChange={onChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>  
              )
              :
                <Box sx={{maxWidth: "300px", width: "100%"}}>
                  <TextField
                    fullWidth
                    variant="standard"
                    value={inputText}
                    onChange={onChange}
                    // inputProps={{ maxLength: 10, minLength: 10}}
                  />
                </Box> 
              }
              {steps[stepNumber].key === "username" && userWithourWebAuthn === false ?
              
              <AppButton primary onClick={getUserCredentials} width={"40%"}>
                {stepNumber === steps.length - 2 ? "OK" : "OK"}
              </AppButton>
              :
              <AppButton primary onClick={handleNextClick} width={"40%"}>
                {stepNumber === steps.length - 2 ? "OK" : "OK"}
              </AppButton>
              }

              <Link to="/forgot-password">{"Have you forgotten your password?"}</Link>
              {authFactor ?
                <>
              <FormControlLabel
                required
                control={<Checkbox color="secondary" name="disableSecondFactor" value="no" />}
                label="Deshabilitar el código dinámico"
                onChange={handleAuthFactor}
              />
              </>
              :<></>}
            </>
          )}
        </Box>
      </div>
    </>
  );
}