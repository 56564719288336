import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import DefaultSection from "../../components/ui/DefaultSection";
import Content from "../../layout/Content";
import { fetchXrpl, fetchStatement, loadXrpAddressCreds, 
  xrplSecretFetch, fetchAssetRates } from "../../utilityFunctions/FetchUtil";
import FlyingRocket from "../../img/design-june/img-light/cohete.png"
import Colors from "../../components/ui/Colors";
import swal from 'sweetalert';
import { Button, Typography } from "@mui/material";
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { purple } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import { determineTypeOfAsset, determineAsset } from "../../utilityFunctions/Util";
import { xrpPayment, prepareStatement } from "../../utilityFunctions/Paymentutil";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import WupoToken from "../../img/tokens/wupo-token.png";
import AppleToken from "../../img/tokens/apple-token.png";
import AmazonToken from "../../img/tokens/amazon-token.png";
import BitcoinToken from "../../img/tokens/bitcoin-token.png";
import EthereumToken from "../../img/tokens/ethereum-token.png";
import EuroToken from "../../img/tokens/euro-token.png";
import FacebookToken from "../../img/tokens/facebook-token.png";
import GoogleToken from "../../img/tokens/google-token.png";
import TeslaToken from "../../img/tokens/tesla-token.png";
import UsdtToken from "../../img/tokens/usdt-token.png";
import MexicoToken from "../../img/tokens/mexico-token.png";
import IMSToken from "../../img/tokens/ims-token.png";
import MiniToken from "../../img/tokens/minibares-token.png";
import CopToken from "../../img/tokens/cop-token.png";
import HoyToken from "../../img/tokens/hoy-token.jpg"
import SpaReward from "../../img/spa-reward.jpg";
import ConcertReward from "../../img/concert-reward.jpg";
import LoanReward from "../../img/loan-reward.jpg";
import GiftReward from "../../img/gift-reward.jpg";
import Box from '@mui/material/Box';

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 2 1'><rect fill='#7F09AA' width='2' height='1'/><defs><linearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'><stop offset='0'  stop-color='#7F09AA'/><stop offset='1'  stop-color='#4fd'/></linearGradient><linearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'><stop offset='0'  stop-color='#cf8' stop-opacity='0'/><stop offset='1'  stop-color='#cf8' stop-opacity='1'/></linearGradient><linearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'><stop  offset='0' stop-color='#cf8' stop-opacity='0'/><stop  offset='1' stop-color='#cf8' stop-opacity='1'/></linearGradient></defs><rect x='0' y='0' fill='url(#a)' width='2' height='1'/><g fill-opacity='0.5'><polygon fill='url(#b)' points='0 1 0 0 2 0'/><polygon fill='url(#c)' points='2 1 2 0 0 0'/></g></svg>`;

const tokenImages = [
  {
    file: WupoToken,
    StringFile: "WupoToken"
  },
  {
    file: AppleToken,
    StringFile: "AppleToken"
  },
  {
    file: AmazonToken,
    StringFile: "AmazonToken"
  },
  {
    file: BitcoinToken,
    StringFile: "BitcoinToken"
  },
  {
    file: EthereumToken,
    StringFile: "EthereumToken"
  },
  {
    file: EuroToken,
    StringFile: "EuroToken"
  },
  {
    file: FacebookToken,
    StringFile: "FacebookToken"
  },
  {
    file: GoogleToken,
    StringFile: "GoogleToken"
  },
  {
    file: TeslaToken,
    StringFile: "TeslaToken"
  },
  {
    file: UsdtToken,
    StringFile: "UsdtToken"
  },
  {
    file: MexicoToken,
    StringFile: "MexicoToken"
  },
  {
    file: IMSToken,
    StringFile: "IMSToken"
  },
  {
    file: MiniToken,
    StringFile: "MiniToken"
  },
  {
    file: CopToken,
    StringFile: "CopToken"
  },
  {
    file: HoyToken,
    StringFile: "HoyToken"
  },
]

const useStyles = makeStyles((theme) => ({
 
  gridProducts:{
      justifyContent: "center",
      alignItems: "center",
      marginTop:"0.2em",
      marginBottom: "0.2em"
  },
  menuItemProducts: {
      width: "100%",
  },
  menuList: {
    overflowY: "scroll",
    overflowX: "scroll",
  },  
  button: {
    width: "5%",
    color: "white",
    backgroundColor: "#03a9f4",
    fontSize: "0.55rem",
    '&:hover': {
      // backgroundColor: purple[700],
    // fontSize: "1em",
    },
  },
  ListItemText: {
    fontSize: "0.3rem",
  },
  gridStatement: {
    marginTop:"1em",
    justifyContent: "center",
  },
  divider:{
    // background: Colors.primary
  },
  statementDate:{
    color: "#A0A0A0",
  },
  menuListReward: {
    overflowY: "scroll"
},
  dividerReward:{
      background: Colors.primary
  },
  menuItemProductsReward: {
      width: "100%",
  },
  gridProductsReward:{
      marginTop:"0.2em",
      marginBottom: "0.2em"
  },
  card:{
    width: "90%",
    marginTop: "0.1em",
    // boxShadow: "3px 5px #F1F1F1",
    // outlineColor: "#F8F8F8",
    // outlineStyle: "solid",
  },
  cardStatement: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    height: "90%",
    marginTop: "0.1em",
    // boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
    border: "1.6px solid #F8F8F8",
    // outlineColor: "#F8F8F8",
    // outlineStyle: "solid",
    borderRadius: '1rem',
    boxShadow: '0 6px 20px 0 #dbdbe8',
    // backgroundColor: '#fff',
    transition: '0.4s',

   
  },
  cardActionArea:{
    '&:hover': {
      // backgroundColor: Colors.primary,
      backgroundColor: "#CBEDFF",
      // color: "white",
    },
    cursor: "pointer",
  },
  typography:{
    fontSize: "0.85rem",
    fontWeight: "bold"
  },
  settlement:{
    fontSize: "0.75rem",
    color: "#808080"
  },
  rewardLogo:{
    marginBottom: "0.1em"
  },
  tokens: {
    width: "30%",
    borderRadius: '0.9rem',
    textAlign: "center",
    // background: 'linear-gradient(to top, #638ef0, #82e7fe)',
    background: "#89EBCE",
    '& > *': {
      textTransform: 'none !important',
    marginBottom: "1.5rem",
    },
  },
  svgBackground: {
    background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
  },
}));


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const FETCHTYPE = "BalanceSheet";
const ACTIONS = {
  FUNDING: "wupealo-funding",
  WUPEA: "wupealo-paymentSelection",
  STATEMENT: "wupealo-statement"
};

let clientProducts = [];
let clientProductsNames = [];

let currentAsset;
let currentAssetDefinition;

const dateOptions = {
  // weekday: "short",
  day: "2-digit",
  month: "numeric",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
}

const payment = {
  source: {
    address: "",
    maxAmount: {
      value: "",
      currency: "",
      counterparty: "",
    },
  },
  destination: {
    address: "",
    amount: {
      value: "",
      currency: "",
      counterparty: "",
    },
  },
};

let finalClientArray = [];

let handler;

export default function HandleAccount(props) {
  const { ledgerAccounts, user,
    updateUser, loginUser, visitorAccounts, wupoAvailableAssets, loadProductsAndSetting } = props;

  let history = useHistory();

  const classes = useStyles();

  const [balanceRenderEls, setBalanceRenderEls] = useState(null);
  const [statementRenderEls, setStatementRenderEls] = useState(null);
  const [loading, setLoading] = useState(false);
  const [funding, setFunding] = useState(false);

  const [renderBalanceOrStatement, setRenderBalanceOrStatement] = useState(true);

  const[epaycoData, setEpaycoData] = React.useState(
    {
      //Parametros compra (obligatorio)
      name: "prueba",
      description: "prueba",
      invoice: "1010",
      currency: "COP",
      amount: "35000",
      tax_base: "1000",
      tax: "0",
      country: "co",
      lang: "en",
    
      //Onpage="false" - Standard="true"
      external: "false",
    
    
      //Atributos opcionales
      extra1: "extra1",
      extra2: "extra2",
      extra3: "extra3",
      confirmation: "https://wupotestmiddle.ngrok.io/epaycoconfirmation",
      response: "https://wupotestmiddle.ngrok.io/epaycoresponse",
    
      //Atributos cliente
      name_billing: "Jorge Hernandez",
      address_billing: "Carrera 19 numero 14 91",
      type_doc_billing: "cc",
      mobilephone_billing: "3138252848",
      number_doc_billing: "1020723447",
      // email_billing: "isabella.coronel@gmail.com",
    
     //atributo deshabilitación metodo de pago
      // methodsDisable: ["CASH","DP"]
    
    }
  );

  useEffect(() => {
    

    finalClientArray = [];
    clientProducts = [];
    clientProductsNames = [];

    setLoading(true);
    loadInitialInfo();
    updateLedgerBalances().then(() => {
      
      updateFX().then(()=>{
        console.log(finalClientArray)
        handleProducts();
      });
    });


    currentAssetDefinition = "";
    currentAsset = "";
    // console.log(user);

    // const script = document.createElement('script');
    // script.src = "https://checkout.epayco.co/checkout.js";
    // script.async = true;
    // document.body.appendChild(script);

    // // handler = window.ePayco.checkout.configure({
    // //   key: '502a7cf6776c4080941e24e28b515028',
    // //   test: true,
    // // });

    // // return () => {
    // //   document.body.removeChild(script);
    // // }

  }, [])

  const loadInitialInfo = () => {
    return (new Promise((resolve) => {
      if(props.location.state && props.location.state.fromHistory === "/login"){
        resolve(200);
      }
      loadProductsAndSetting("/account/handle_account", loginUser.accountId, loginUser.jwtToken).then((result) => {
        resolve(200);
      });
    }));
      
  };

  const updateLedgerBalances = () => {
  
    if(!visitorAccounts.length){
      
      swal({
        title: "¿QUÉ PASÓ?",
        text: `\n Todavía no tienes tokens. 
        
        Crea productos y empieza a Wupear!`,
        icon: "info",
        buttons: "Entiendo"
      }).then(()=>{
        history.push('/account/create_account');
      })
    }else{
      clientProducts = visitorAccounts;
      return(
        fetchXrpl(FETCHTYPE, clientProducts[0].xrplAddress).then((ledgerBalance) => {
          if (!(Object.keys(ledgerBalance).length === 0 && ledgerBalance.constructor === Object)){
            clientProducts.forEach((product) => {
              ledgerBalance.assets.forEach((balance) => {
                  if(product.asset === balance.currency){
                      product.balance = balance.value;
                  }

              })
            })
          }
        })
      );
    
    }
      
  }
  
  const updateFX = ()=>{

    const assetsToFetch = {
      fromAsset: "",
      toAsset: "",
    };

    let counter= 0;
    

    return new Promise((resolve, reject)=>{
            
      clientProducts.map((element) => {
        assetsToFetch.fromAsset=element.asset;
        assetsToFetch.toAsset= "COP" //CHANGE: Temporarily hardcoded
        // console.log(assetsToFetch)
        fetchAssetRates(assetsToFetch, loginUser.jwtToken).then((fxRate) => {
          // console.log(fxRate)
          const localAssetSettlement = {
            settlement: (parseInt(element.balance) * fxRate.rate)
          }
          console.log(element.asset)
          determineAsset(wupoAvailableAssets, element.asset).then((assetDetermined)=>{
            console.log(assetDetermined)

            const imagePath = tokenImages.find((tokenImg) => 
              tokenImg.StringFile === assetDetermined.picturePath
            )
            
            const assetsMapping = {
              assetName: assetDetermined.name,
              picturePath: imagePath.file,
            }

            clientProductsNames = {...clientProductsNames, ...element, ...localAssetSettlement, ...assetsMapping};
            finalClientArray.push(clientProductsNames);
            
            counter++;

            if(counter >= clientProducts.length){
             
              console.log(finalClientArray)
              resolve(clientProducts)
              
            }
            
          })
        })  
      })
    
    })
  }

  const reloadBalances = () => {
    setLoading(true);
    updateLedgerBalances().then(() => {
      handleProducts();
    });
  };

  const handleProducts = () => {
    // let noFunds = false;
    console.log("entering handle product")
    // setLoading(true);
    try{
      
      setBalanceRenderEls(
        <Grid container spacing={3} sx={{justifyContent: {xs: "center", sm: "start"}}}> 
          {finalClientArray.map((account) => {
            currentAsset=account.asset;
              return(
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{marginTop: "0.5rem", maxWidth:{xs: "350px"}, marginBottom: "0.5rem"}}>
                    <Card className={classes.card} key={account.asset}>
                      <Grid container direction="column" key={account.asset}>
                          <Grid item xs={12} sm={12} md={12}>
                            <CardActions className={classes.cardActionArea}
                              onClick={() => handleAction(ACTIONS.WUPEA, account.asset, (account.balance <= 0), account.balance, account.picturePath)}
                              disabled={account.balance <= 0}>
                                <Grid container>
                                  <Grid item xs={2} sm={2} md={2} key={account.asset+1} className={classes.gridProducts}>
                                    <CardMedia
                                        component="img"
                                        image={account.picturePath}
                                        alt="Token symbol"
                                      />
                                  </Grid>
                                  <Grid item xs={10} sm={10} md={10} key={account.asset} sx={{textAlign: "end", display: "flex", alignItems: "center", justifyContent: "end"}}>
                                    <Typography variant="body1" sx={{color: "GrayText"}}>
                                    {`${account.assetName}`}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12} key={account.asset}>  
                                    <Grid container direction="row"
                                      className={classes.gridProducts} key={account.asset}
                                      style={{textAlign:"left", marginRight: "0.6em"}} >
                                      <Grid item xs={12} sm={12} md={12} key={account.asset}>
                                        <Typography key={account.asset} variant="h6" sx={{fontWeight: "bold"}} >
                                          {`${new Intl.NumberFormat('es-CO').format(account.balance)}`}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={12} md={12} key={account.assetName}>
                                        <Typography key={account.assetName} className={classes.settlement}>
                                          {`${new Intl.NumberFormat('es-CO').format(account.settlement)} COP`}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                            </CardActions>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} key={account.asset+2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                              <Button onClick={() => handleAction(ACTIONS.STATEMENT, account.asset, false, account.balance, account.picturePath)} 
                              style={{fontSize: "0.65em", cursor: "pointer", color: "#03a9f4", fontWeight: "bold"}}>
                                  Detalle
                              </Button>
                          </Grid> 
                      </Grid>
                    </Card> 
                  </Grid>
              )

          })}
        </Grid>
      )
      
      setLoading(false);

    }catch(error){
      console.log(error);
      swal({
        title: "¿QUÉ PASÓ?",
        text: `\n Todavía no tienes tokens. Crea productos y empieza a Wupear!`,
        icon: "error",
        buttons: "Entiendo"
      }).then(()=>{
        history.push('/account/create_account');
      });
    }
  }

  const handleAction = async (action, asset, assetFunds, tokenBalance, tokenLogo) => {

    // await determineAsset(wupoAvailableAssets, asset).then((assetDetermined)=>{
    //   asset = assetDetermined.xrplName;
    // })
    console.log(user)
    console.log(asset)
    updateUser(asset); //test
    if (action === ACTIONS.FUNDING) {
      // history.push(`/account/handle_account/funding/${asset}`);
      history.push(`/storesmap`);
    } else if (action === ACTIONS.WUPEA) {
      if(assetFunds) return;

      setLoading(true);

      //TODO: building the taylored tokens rewards table
      determineTypeOfAsset(wupoAvailableAssets, asset).then((type) =>{
        if(type === "tayloredToken"){
          determineAsset(wupoAvailableAssets, asset).then((assetFound) =>{

            currentAssetDefinition = assetFound;

            if(!(Object.keys(assetFound.tokensProducts).length === 0)){
              console.log(assetFound.tokensProducts)
              currentAsset = asset;

              setRewardDialogLogo(
                <Box className={classes.rewardLogo}
                  component="img"
                  sx={{
                    height: 40,
                    width: 40,
                  }}
                  alt="Token image"
                  src={tokenLogo}
                />
              )

              setRewardsRendering(
                <MenuList className={classes.menuListReward}>
                  {currentAssetDefinition.tokensProducts.map((rewardInfo) => {
                      return(
                          <div>
                          <Divider className={classes.dividerReward}/>  
                          <MenuItem key ={rewardInfo} className={classes.menuItemProductsReward}>
                              <Grid container className={classes.gridProductsReward} key={rewardInfo}>
                                  <Grid  item xs={5} sm={5} md={5} key={rewardInfo+1} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                          <ListItemText 
                                              key={rewardInfo+1}
                                              onClick={() => paymentOfReward(rewardInfo, tokenBalance)}
                                          >
                                            <Typography style={{fontWeight: "bold"}}>{`${rewardInfo.productRewarded}`}</Typography>
                                          </ListItemText>
                                  </Grid>
                                  <Grid item xs={3} sm={3} md={3} key={rewardInfo} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                          <ListItemText 
                                              key={rewardInfo}
                                              onClick={() => paymentOfReward(rewardInfo, tokenBalance)}
                                          >
                                          <Typography>{`${new Intl.NumberFormat('es-CO').format(rewardInfo.tokenQuantity)}`}</Typography>
                                          </ListItemText>
                                  </Grid>
                                  <Grid item xs={3} sm={3} md={3} key={rewardInfo+1} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.5rem", marginBottom: "0.5rem"}}>
                                   {rewardInfo.productRewarded === "SPA" ?
                                        <Box 
                                          component="img"
                                          sx={{
                                            height: 50,
                                            width: 50,
                                          }}
                                          alt="Premio"
                                          src={SpaReward} 
                                          style={{justifyContent: "end"}}
                                        >

                                        </Box>
                                    : rewardInfo.productRewarded === "Concierto" ?
                                        <Box 
                                          component="img"
                                          sx={{
                                            height: 50,
                                            width: 50,
                                          }}
                                          alt="Premio"
                                          src={ConcertReward} 
                                          style={{justifyContent: "end"}}
                                        >

                                        </Box>
                                    
                                    : rewardInfo.productRewarded === "Adelanto" ?
                                        <Box 
                                          component="img"
                                          sx={{
                                            height: 50,
                                            width: 50,
                                          }}
                                          alt="Premio"
                                          src={LoanReward} 
                                          style={{justifyContent: "end"}}
                                        >

                                        </Box>

                                    :
                                        <Box 
                                          component="img"
                                          sx={{
                                            height: 50,
                                            width: 50,
                                          }}
                                          alt="Premio"
                                          src={GiftReward} 
                                          style={{justifyContent: "end"}}
                                        >

                                        </Box> 
                                          
                                   }
                                  </Grid>
                              </Grid>  
                          </MenuItem>
                          </div>
                      )
                  })}
                </MenuList>
              );
              rewarDialogOpen();
              setLoading(false);
            }
            else {
              console.log("array empty");
              setLoading(false);
              history.push(`/account/handle_account/transer/${asset}`);
            }
          })
          
        }
        else {

            setLoading(false);
            history.push(`/account/handle_account/transer/${asset}`);
          
        }
      })

      // history.push(`/account/handle_account/transer/${asset}`);
    } else if(action === ACTIONS.STATEMENT){
      
      const statementAccountId = {
        accountId: loginUser.accountId + asset
      };

      statementView(statementAccountId);
    }
  };

  const statementView = (account) => {
   try{ 
      fetchStatement(account, loginUser.jwtToken).then((statements) =>{
        // console.log(statements); //LOANS DEBUG PRINTING
        if(!statements){
          
          swal({
            title: "¿QUÉ PASÓ?",
            text: `\n Todavía no tienes movimientos que revisar`,
            icon: "info",
            buttons: "Entiendo"
          }).then(()=>{
            return;
          }); 
          
        }
        else{
          setRenderBalanceOrStatement(false);
          //TODO: sorting the statement entries
          statements.sort((a,b) => {
            return b.id - a.id
          });
          
          setStatementRenderEls(
            <>
                  <Grid container>       
                    {statements.map((statement) => {
                        return(
                            <Grid item xs={12} sm={6} md={6} lg={6} key={statement.timestamp + 100}>  
                              <Grid container={true} className={classes.gridStatement} key={statement.timestamp + 1}>
                                <Card className={classes.cardStatement} key={statement.timestamp}>
                                    <Grid item xs={6} sm={6} md={6} key={statement.timestamp + 4} sx={{display: "flex", minWidth: "100%"}}>  
                                        <Typography style={{fontWeight: "bold", marginLeft: "1rem", marginTop: "0.5rem"}}>{statement.counterpartyName === null ? "Empresa" : `${statement.counterpartyName}`}</Typography>                                          
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} key={statement.timestamp + 3} sx={{display: "flex"}}>
                                        <Typography style={{fontSize: "0.9rem", color: "#d3d3d3", marginLeft: "1rem",}}>{`${statement.timestamp}`}</Typography>                                               
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} key={statement.timestamp+9} sx={{display: "flex", justifyContent: "center"}}>
                                      <Box style={{display: "flex", width: "90%", textAlign: "center", alignContent: "center", marginTop: "0.6rem", marginBottom: "0.6rem", justifyContent: "center"}}> 
                                        <Typography key={statement.timestamp+10} style={{fontSize: "1rem",}}>
                                          {`${statement.description}`}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} key={statement.timestamp+5} sx={{display: "flex", textAlign: "center", width: "100%"}}> 
                                      <Box key={statement.timestamp+6} style={{display: "flex", width: "100%", justifyContent: "center", marginBottom: "1.5rem", marginTop: "0.5rem"}}>
                                        <Typography key={statement.timestamp+10} className={classes.tokens}>
                                              {statement.transaction === "credit" ? 
                                              <span style={{ color: "white"}} key={statement.timestamp+7}>
                                                {` ${new Intl.NumberFormat('es-CO').format(statement.value)}`}</span>
                                              :
                                              <span style={{ color: "white"}} key={statement.timestamp+8}>
                                                {` ${new Intl.NumberFormat('es-CO').format((statement.value*-1))}`}</span>
                                              }
                                            
                                        </Typography>
                                      </Box>
                                    </Grid>
                                </Card>  
                              </Grid>
                            </Grid>
                            )
                    })}
                    </Grid> 
                
            </>
          )
        }
      })
    }catch(error){
      console.log(error);
      swal({
        title: "¿QUÉ PASÓ?",
        text: `\n Todavía no tienes movimientos que revisar`,
        icon: "info",
        buttons: "Entiendo"
      }).then(()=>{
        history.push('/account/handle_account');
      });
    }
  };

  function handleBack(){
    // console.log(renderBalanceOrStatement)
    setRenderBalanceOrStatement(true)
    // console.log(renderBalanceOrStatement)
  }
  // console.log(loginUser)

  const [rewardDialog, setRewardDialog] = React.useState(false);
  const [rewardsRendering, setRewardsRendering] = React.useState();
  const [rewardDialogLogo, setRewardDialogLogo] = React.useState();

  const rewarDialogOpen = () =>{
    setRewardDialog(true);
  };

  const handleRewardClose = () => {
    setRewardDialog(false);
  };

  const continuToTransfer = () => {
    history.push(`/account/handle_account/transer/${currentAsset}`);
  }

  const paymentOfReward = async (reward, tokenBalance) => {
    
    if(tokenBalance < reward.tokenQuantity){
      swal({
        title: "SALDO INSUFICIENTE",
        text: `\n No tienes el saldo para este producto. Sigue comprando y ganando para obtenerlo`,
        icon: "info",
        buttons: "Entiendo"
      }).then(()=>{
        handleRewardClose();
      });
    }
    
    else{

      setLoading(true);
      handleRewardClose();

      payment.source.address = visitorAccounts[0].xrplAddress;
      payment.source.maxAmount.value = String(reward.tokenQuantity);
      payment.source.maxAmount.currency = currentAsset;
      
      const businessLedgerAccount = ledgerAccounts.find((element) => 
        element.account === currentAssetDefinition.account);
      
      payment.destination.address = businessLedgerAccount.xrplAddress;
      payment.destination.amount.value = payment.source.maxAmount.value;
      payment.destination.amount.currency = payment.source.maxAmount.currency;

      await loadXrpAddressCreds(loginUser.jwtToken)
      .then((data) => {
        payment.destination.amount.counterparty = data.xrpAddress;
        payment.source.maxAmount.counterparty = data.xrpAddress;
      });

      let clientXrplToken;

      await xrplSecretFetch(visitorAccounts[0].xrplAddressSecret, loginUser.jwtToken)
      .then((secret) => {
        clientXrplToken = secret;
        // console.log("client xrpl secret: " + clientXrplToken);
      });

      xrpPayment(
        payment,
        "",
        "",
        clientXrplToken
      )

      //Prepare statement
      const senderStatement = {
        accountId: "",
        account: "",
        asset: "",
        value: "",
        transaction: "", 
        description: "",
        type: "",
      }
      
      const receiverStatement = {
        accountId: "",
        account: "",
        asset: "",
        value: "",
        transaction: "", 
        description: "",
      }
      //Sender statement
      senderStatement.accountId = loginUser.accountId + currentAsset;
      senderStatement.account = loginUser.accountId;
      senderStatement.asset = payment.source.maxAmount.currency
      senderStatement.value = payment.source.maxAmount.value;
      senderStatement.transaction = "debit";
      senderStatement.description = `Premio con ${businessLedgerAccount.account}`;

      await prepareStatement(senderStatement, loginUser.jwtToken).then(statementResult =>{
        // console.log(statementResult); // DEBUG PRINTING
      });

      //Receiver statement
      receiverStatement.accountId = businessLedgerAccount.accountId;
      receiverStatement.account = businessLedgerAccount.account;
      receiverStatement.asset = payment.source.maxAmount.currency
      receiverStatement.value = payment.destination.amount.value;
      receiverStatement.transaction = "credit";
      receiverStatement.description = `Premio de ${senderStatement.account}`;

      await prepareStatement(receiverStatement, loginUser.jwtToken).then(statementResult =>{
        // console.log(statementResult); // DEBUG PRINTING
      });

      swal({
        title: "FELICITACIONES!",
        text: `\n Ya redimiste tu compra`,
        icon: "success",
        buttons: "Súper"
      }).then(()=>{
        handleRewardClose();
        reloadBalances();
      });

      setLoading(false);

    }
  };

  const epaycoLoad = async () => {

    handler = await window.ePayco.checkout.configure({
      key: '502a7cf6776c4080941e24e28b515028',
      test: true,
    });

    handler.open(epaycoData); // Open epayco dialog box
  };

  const newToken = () => {
    setLoading(true);
    loadProductsAndSetting("/account/create_account", loginUser.accountId, loginUser.jwtToken).then(() => {
      setLoading(false);
      history.push({pathname: "/account/create_account"});
    });
  };

  const toLoan = () => {
    setLoading(true);
    loadProductsAndSetting("/credit/handle_credit", loginUser.accountId, loginUser.jwtToken).then((loadedInfo) => {
      if(loadedInfo.visitorLoans){
        history.push({pathname: "/credit/handle_credit"});
        setLoading(false);
      }

      else{
        history.push({pathname: "/account/create_credit"});
        setLoading(false);
      }
        
    });
  };

  const toRewardsDetail = () => {
    history.push({pathname: "/available-rewards"});
  }

  return (
    // <Box className={classes.svgBackground} sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%",}}>
    <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%", backgroundColor: "#f6f5f3"}}>
      <Box
      sx={{
        display: "flex",
        // flexGrow: "1",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        // backgroundColor: "#ededed",
      }}
      >
      {loading ?
            <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
          
      :

      <>
        {renderBalanceOrStatement ? 
        <>
          {/* <Typography  variant="h6" sx={{color: Colors.labelColor, textAlign: "start"}}>
                TUS TOKENS
          </Typography> */}
          <Box sx={{display: "flex", flex: 1, flexDirection: "column", maxWidth: "900px", justifyContent: "center", alignItems: "center", marginTop:{xs: "1.5rem"}, marginLeft:{xs: "1.5rem", sm: "1.5rem"}}}>
            <div style={{ width: "100%", overflowY: "scroll"}}>{balanceRenderEls}</div>
          </Box>
        </>
          :
          <Content maxWidth={"900px"}>
            {/* <PageLabel>TUS INTERACCIONES</PageLabel> */}
            {loading && (
              <PuffLoader size={100} color={Colors.secondary} css={cssLoader} />
            )}
            {balanceRenderEls === null && !loading && (
              
              <DefaultSection
                backImgage={FlyingRocket} 
              />
              
            )}
            <div style={{ width: "100%", overflowY: "scroll", maxHeight: "40rem", marginBottom: "0.8rem" }}>{statementRenderEls}</div>
            <Button variant="contained" size="large" style={{color: "white", backgroundColor: Colors.primary}} onClick={handleBack}>
              ATRÁS
            </Button>
          </Content>
        }
      </>
      }
        <>
          <Dialog open={rewardDialog} onClose={handleRewardClose} aria-labelledby="form-dialog-tokens" 
              fullWidth={true} maxWidth={"xs"}
          >
            <DialogTitle id="form-dialog-title3">
              <Grid container>
                <Grid item xs={3} sm={3} md={3}>
                  {rewardDialogLogo}
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                  {"Escoge tu Producto"}
                </Grid>
              </Grid>
            </DialogTitle>
            <DialogContent>
                {rewardsRendering}
            </DialogContent>  
            <DialogActions>
                <Button onClick={handleRewardClose} color="primary">
                    ATRÁS
                </Button>
                <Button 
                    onClick={continuToTransfer} color="primary">
                    COMPARTE
                </Button>
                <Button 
                    onClick={toRewardsDetail} color="primary">
                    DETALLE
                </Button>
            </DialogActions>
          </Dialog>
        </>
      </Box>
    </Box>
  );
}
