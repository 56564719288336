import { Divider, Grid, Typography} from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import Card from '@mui/material/Card';
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

// let sortedActions;

const dateOptions = {
// weekday: "short",
day: "numeric",
month: "short",
year: "numeric",
};

const deliveryStatus = [
    {
        status: "created",
        label: "En almacén",
        initials: "EA"
      },
      {
        status: "recolected",
        label: "Recogida",
        initials: "RE"
      },
      {
        status: "transit",
        label: "En tránsito",
        initials: "ET",
      },
      {
        status: "delivered",
        label: "Entregadas",
        initials: "EN"
      },
      {
        status: "pqr",
        label: "PQR",
        initials: "PQ"
      },
      {
        status: "problem",
        label: "Problema",
        initials: "PR"
      },
];

export default function DeliveryDetail(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    let history = useHistory();

    React.useEffect(() => {
       
        renderActions();
        renderDeliveryDetail();
       
    }, []);

    const sortDealActions = () => {
        if(props.location.state && props.location.state.delivery){
            const sortedActions = props.location.state.delivery.deliveryHistory.sort((a, b) => (new Date(a.date) - new Date(b.date)));
            console.log(props.location.state.delivery)
            console.log(sortedActions);
            return sortedActions;
        }

        else{
            return;
        }
    };

    const [actionsList, setActionsList] = React.useState("");

    const renderActions = async () => {
        setLoading(true);
        const sortedActions = await sortDealActions();

        setActionsList(
            <Grid container direction="column" sx={{justifyContent: {xs: "center", sm: "start"}}}>
                {sortedActions.map((action) => {
                    const statusElement = deliveryStatus.find((statusEl) => statusEl.status === action.deliveryAction)
                    console.log(statusElement);
                    return (
                        <>
                        <Grid key={action.id} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem"}}>
                            <Grid key={action.id+5} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
                                <Typography variant="body2" sx={{color: "GrayText"}}>
                                    {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(action.date))}`}
                                </Typography>
                            </Grid>
                            <Grid  key={action.id+1} container>
                                <Grid key={action.id+2} item xs={3} sm={3} md={3} sx={{display: "flex", alignItems: "center"}}>
                                    <Box
                                        sx={{
                                            ...((statusElement.initials === "EA" || statusElement.initials === "RE") && {
                                                backgroundColor: Colors.primary,
                                                color: "white",
                                            }),
                                            ...((statusElement.initials === "ET" || statusElement.initials === "EN") && {
                                                backgroundColor: Colors.primary,
                                                color: "white",
                                            }),
                                            ...((statusElement.initials === "PQ" || statusElement.initials === "PR") && {
                                                backgroundColor: "#E2062C",
                                                color: "white",
                                            }),
                                            borderRadius: "25%",
                                            width: "3rem",
                                            height: "3rem",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "0.5rem",
                                        
                                        }} 
                                    >
                                        {statusElement.initials}
                                    </Box>
                                </Grid>
                                <Grid ey={action.id+3} item xs={9} sm={9} md={9} sx={{display: "flex", alignItems: "center"}}>
                                    <Typography variant="h6">{statusElement.label}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {action.deliveryAction === "pqr" ?
                            <>
                            <Grid key={action.id+6} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0rem"}}>
                                <Typography variant="body2">{`Código: ${action.pqrCode}`}</Typography>
                            </Grid>
                            </>
                        :     
                        action.deliveryAction === "problem" && action.problemType ?
                            <>
                            <Grid key={action.id+7} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0rem"}}>
                                <Typography variant="body2">{`Tipo: ${action.problemType}`}</Typography>
                            </Grid>
                            </>
                        :
                        <></>
                        }
                        <Grid key={action.id+9} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid>
                        </>
                    )
                })}
            </Grid>
        )

        setLoading(false);
    };

    const[deliveryRender, setDeliveryRender] = React.useState("");

    const renderDeliveryDetail = () => {
        setLoading(true);

        const deliveryDetail = props.location.state.delivery;
        const statusElement = deliveryStatus.find((statusEl) => statusEl.status === deliveryDetail.status);
        console.log(deliveryDetail)
        console.log(statusElement);

        if(deliveryDetail && statusElement){
            setDeliveryRender(
                <Grid container direction="column" sx={{justifyContent: "space-evenly"}}>
                    <Grid>
                        <Card>
                            <Grid container sx={{paddingLeft: "3.5rem"}}>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "2rem"}}>Orden</Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "end"}}>
                                    <Typography sx={{color: "GrayText", fontSize: "0.8rem", marginTop: "2rem", paddingRight: "1rem"}}>
                                        {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(deliveryDetail.orderDate))}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography gutterBottom variant="h4" sx={{fontWeight: "bolder"}}>
                                        {deliveryDetail.id}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5}>
                                    <Typography gutterBottom variant="body2">Estado:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>{statusElement.label}</Typography>
                                </Grid>
                                {deliveryDetail.orderValue ? 
                                    <>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography variant="body2">Valor:</Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography variant="body2" sx={{fontWeight: "bold"}}>{`$ ${new Intl.NumberFormat('es-CO').format(deliveryDetail.orderValue)}`}</Typography>
                                    </Grid>
                                    </>
                                    :<></>
                                }
                                <Grid item xs={5} sm={5} md={5} sx={{}}>
                                    <Typography gutterBottom variant="body2">Cliente:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>{deliveryDetail.clientName}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "1.5rem"}}>
                                    <Divider sx={{width: "90%"}}/>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} sx={{marginTop: "1.5rem"}}>
                                    <Typography gutterBottom variant="body2">Celular:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography variant="body2" sx={{fontWeight: "bold", marginTop: "1.5rem"}}>{deliveryDetail.clientPhone}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5}>
                                    <Typography gutterBottom variant="body2">Correo:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body2" sx={{fontWeight: "bold", marginRight: "2rem"}}>{deliveryDetail.clientEmail}</Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} sx={{marginBottom: "2rem"}}>
                                    <Typography gutterBottom variant="body2">Identificación:</Typography>
                                </Grid>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Typography gutterBottom variant="body2" sx={{fontWeight: "bold", marginBottom: "2rem"}}>{deliveryDetail.clientId}</Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            );
        }

        setLoading(false);
    };

    return (
        <Box
        sx={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        // backgroundColor: "#ededed",
        }}
    >
    {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
        <>
            <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={8} sx={{display: "flex", justifyContent: "center", backgroundColor: "#FAFAFA"}}>
                    <Box sx={{width: "55%", height: "90%", display: "flex"}}>
                        {deliveryRender}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "start", backgroundColor: "#ededed"}}>
                    <Box sx={{width: "90%", height: "90%", overflowY: "scroll",}}>
                        {actionsList} 
                    </Box>
                </Grid>
            </Grid>
        </>
    }
    </Box>
    )
}
