import React, { useEffect } from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import AppButton from "../../components/ui/AppButton";
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors"; 

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({
    divider:{
        background: Colors.primary
    },
    statementDate:{
        color: "#A0A0A0",
    },
    gridStatement: {
        marginTop:"1em",
    },
    gridInputs:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
}));

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

let statements;

export default function OperatorStatements(props) {

    const classes = useStyles();
    const history = useHistory();
  
    useEffect(() => {
        setLoading(true);
        statements = props.location.state.statementsInfo;
        console.log(statements);
        renderStatements();
    }, []);

    const[loading, setLoading] = React.useState(false);

    const[statementList, setStatementList] = React.useState();

    const renderStatements = () => {
        setStatementList(
            <>
            <MenuList className={classes.menuList}>
                    {statements.map((statement) => {
                        return(  
                            <Grid container className={classes.gridStatement} key={statement.date}>
                                <Grid item xs={12} sm={12} md={12} key={statement.date}>
                                    <Divider className={classes.divider}/>
                                    <MenuItem key={statement.date}>
                                        <ListItemText key={statement.date} style={{color:"blue"}}>                                                
                                        {`Crédito No. ${statement.id}`}
                                        </ListItemText>
                                    </MenuItem>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} key={statement.date}>
                                    <Divider className={classes.divider}/>
                                    <MenuItem key={statement.date}>
                                        <ListItemText key={statement.date} className={classes.statementDate}>                                                
                                        {`${new Intl.DateTimeFormat("es-CO", dateOptions).format(new Date(statement.date))}`}
                                        </ListItemText>
                                    </MenuItem>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} key={statement.date+1}> 
                                    <MenuItem key={statement.date+1}>
                                        <ListItemText key={statement.date+1}>
                                        {`Pago: ${new Intl.NumberFormat('es-CO').format(statement.payment)}`}
                                        </ListItemText>
                                    </MenuItem>
                                </Grid>
                            </Grid>
                            
                            )
                    })}
            </MenuList>
            <Grid container direction="row" className={classes.gridInputs}>
                <Grid item xs={12} sm={12} md={12}>
                    <AppButton 
                        primary
                        width={"45%"}
                        onClick={handleClick}
                    >
                        LISTO
                    </AppButton>
                </Grid>
            </Grid>
            </>
        )
        setLoading(false);
    }

    const handleClick = () => {
        history.push({pathname: "/operator-role"});
    };

    return (
        <div
            style={{
                // display: "flex",
                maxWidth: "600px",
                justifyContent: "center",
                width: "100%",
                height: "75%",
                textAlign: "center",
                // overflow: "scroll"
            }}
        >
        {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
                
        statementList
        }
        </div>
  )
}
