import React from "react"
import styled from "@emotion/styled"
import Colors from "../components/ui/Colors"

const AppSelect = styled.select(() => ({
  width: "80%",
  height: "40px",
  borderColor: Colors.primary,
  borderRadius: "4px",
  backgroundColor: "white",
  outline: "none"
}))

export default function ConnectorSelect(props) {
  const { onChangeInfo } = props

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <AppSelect onChange={onChangeInfo} defaultValue="">
        <option value="" disabled>Selecciona una cuenta...</option>
        <option value="Operacional">Cuenta Operacional</option>
        <option value="Ganancias">Cuenta Ganancias</option>
      </AppSelect>
    </div>
  )
}
