import styled from "@emotion/styled"


const ContentForm = styled.div(({
  width: "100%",
  maxWidth: "615px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px 25px",
  justifyContent: "space-evenly",
}))

export default ContentForm