
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { css } from "@emotion/react"
import swal from "sweetalert"
import { PuffLoader } from "react-spinners"
// import Content from "../../layout/Content"
import ContentForm from "../../layout/ContentForm"
import PageLabel from "../../components/ui/PageLabel"
import AppButton from "../../components/ui/AppButton"
import StepProgress from "../../components/ui/StepProgress"
import Colors from "../../components/ui/Colors"
import { wupoServerUri, b2bClient, webauthnPKRpId } from "../../Api";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import discAuthSecondFactorTextlaimerText from '../../Texts/Security';
import {authRegister, getUxLanguage, createWebauthnCredentials} from "../../utilityFunctions/AuthUtil";
import {loginAuth} from "../../utilityFunctions/SecurityUtil"
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import {registerExplanation} from "../../Texts/Explanations";
import privacyConsent from '../../Texts/PrivacyConsent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import { useTranslation } from 'react-i18next';

const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

let wupoSignUpData = {
  accountId: "",
  accountRelationship: "CHILD",
  linkType: "ILP_OVER_HTTP",
  assetCode: "WPO",
  assetScale: 6,
  customSettings: {
    "ilpOverHttp.incoming.auth_type": "SIMPLE",
    "ilpOverHttp.incoming.simple.auth_token": ""
  },
  sendRoutes: "true",
  receiveRoutes: "true"
}

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({
  
  dialogStyle:{
    whiteSpace: "pre-line"
  },
  
  linkStyle:{
    cursor: "pointer",
    textAlign: "center",
    color: "blue"
  },
}));

let secondFactor;

const restictedDomains = /romebook|pk2s|ponotaxi|pibwifi/i;
const restrictedCharacters = /[*ñ´|\":<>[\]{}`\\()';@& $áéíóúàèìòù]/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const restrictedPhoneCharacters = /[ \-+()a-zA-Z]/;

let deviceFingerprint;

let privacyConsentChecked = false;

const userParams ={
  username: "",
  email: "",
  password: "",
  account: "",
  totpEnabled: "",
  deviceFingerprint: "",
  privacyConsentChecked: false
}

export default function Register(props) {
  const { updateRegisterUser, updateNavBarEnabled, userdata } = props
  let history = useHistory()

  // const { t, i18n } = useTranslation();

  const classes = useStyles();

  const steps = [
    {
      key: "username",
      name: "Email",
      value: "",
    },
    // {
    //   key: "email",
    //   name: "CORREO ELECTRÓNICO",
    //   value: "",
    // },
    {
      key: "password",
      name: "Password",
      value: "",
    }
  ];

  const PASSWORDMESSAGE = "The password must have at least 8 characters. Including 1 number, 1 uppercase and a special character ($@$!%*#?&). Example: MyPassword25*";

  const [userInfo, setUserInfo] = useState({
    username: "", email: "", password: ""
  })
  const [stepNumber, setStepNumber] = useState(0) // cambiar para solo tomar email
  const [inputText, setInputText] = useState("")
  const [color, setColor] = useState("")
  const [strengthMessage, setStrengthMessage] = useState("")
  const [strengthLevel, setStrengthLevel] = useState(0);
  const [loading, setLoading] = useState(false)

  const deviceFingerPrint = async () =>{
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    deviceFingerprint = result.visitorId
    // console.log(deviceFingerprint) //DEBUG PRINTING

  }

  useEffect(() => {
    setInputText("");
  }, [stepNumber])

  const explanationMessages = () => {
    swal({
        title: "Explicación",
        text: registerExplanation,
        icon: "success",
        button: "Entiendo",
    });
  }; 

  useEffect(() => {
    // fetchUxLang();
    
    updateNavBarEnabled(false);
    if(b2bClient && b2bClient === "explanation"){
      explanationMessages();
    }
    
    privacyConsentChecked = false;
    deviceFingerPrint(); //Uncomment for complete register and login
  }, []);

  const fetchUxLang = async() => {
    const langResponse = await getUxLanguage();

    if(!langResponse){
      return;
    }

    // i18n.changeLanguage(langResponse.language);
  };


  const textChange = (e) => {
    
    setInputText(e.target.value)
    if(steps[stepNumber].key === 'password'){
      checkPassword(e.target.value);
    }
   
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: e.target.value
    })
  }

  const handleNextClick = () => {
    // console.log(stepNumber) //DEBBUG
    if (!inputText) return

    if(steps[stepNumber].key === 'username'){
      if(!privacyConsentChecked){
        swal({
          title: "Information required",
          text: "To continue you must accept the privacy consent",
          icon: "info",
          button: "Ok",
        });
        return;
      };
      // if(userInfo.username.length < 10){
      //   swal({
      //     title: "CELULAR INVÁLIDO",
      //     text: `\n Ingresa los 10 números de tu celular \n \n
      //           `,
      //     icon: "info",
      //     button: "Entiendo",
      //   });
      //   return;
      // }
    }
    if(steps[stepNumber].key === 'password'){
      if(strengthLevel < 5){
        setColor("red");
        setStrengthMessage("The password must have at least 8 characters. Including 1 number, 1 uppercase and a special character ($@$!%*#?&). Example: MyPassword25*");
        return;
      }
    }
    if(steps[stepNumber].key === 'username' && restictedDomains.test(inputText)){
      swal({
        title: "Restricted Email",
        text: "The email is restricted due to security issues",
        icon: "error",
        buttons: "OK"
      }).then(()=>{
        setInputText("");
      });
      return;
    }

    if(steps[stepNumber].key === 'username' && !emailRegex.test(inputText)){
      swal({
        title: "Invalid Email",
        text: "Please enter a valid email",
        icon: "error",
        buttons: "OK"
      }).then(()=>{
        setInputText("");
      });
      return;
    }

    if (stepNumber < steps.length - 1) {
      setStepNumber(stepNumber + 1);
    } else {
       //TODO: Include the TOTP conditional
       handleSecondFactorBox();

      // setLoading(true);
      // submit();
    }
  }
  const checkPassword = (password) => {
    //Regular Expressions.
    const regex = new Array();
    regex.push("[A-Z]"); //Uppercase Alphabet.
    regex.push("[a-z]"); //Lowercase Alphabet.
    regex.push("[0-9]"); //Digit.
    regex.push("[$@$!%*#?&]"); //Special Character.

    let level = 0;

    //Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          level++;
        }
    }

    //Validate for length of Password.
    if (level > 2 && password.length > 8) {
      level++;
    }

    if (password.length > 15) {
      level++;
    }
    
    switch (level) {
        
        case 1:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("red");
        break;
        case 2:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("darkgrey");
        break;
        case 3:
        case 4:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("darkorange");
        break;
        case 5:
          setStrengthMessage("Good");
          setColor("green");
        break;
        case 6:
          setStrengthMessage("Strong");
          setColor("darkgreen");
        break;
        case 0:
        default:
          setStrengthMessage("");
          setColor("");
          setStrengthLevel(0);
        
    }
    setStrengthLevel(level);
  };
  
  const submit = async () => {
    wupoSignUpData.accountId = userInfo.username;
    wupoSignUpData.customSettings["ilpOverHttp.incoming.simple.auth_token"] = userInfo.password;
    updateRegisterUser(userInfo);
    
    userParams.username = userInfo.username;
    userParams.email = userInfo.username;
    userParams.password = userInfo.password;
    userParams.account = wupoSignUpData;
    userParams.totpEnabled = secondFactor;
    userParams.deviceFingerprint = deviceFingerprint;
    userParams.privacyConsentChecked= privacyConsentChecked;

    //Disable the navbar to hide it while the personal information is completed
    updateNavBarEnabled(false);

    const response = await authRegister(userParams);
    console.log(response);
    
    if(response.status === 200){

      swal({
        title: "User created",
        icon: "success",
        button: "OK"
      })

      secondFactorRegister(response.response);
    }

    else if(response.status === 201){

      newWebAuthnCredentials(response.response);

      // succesfullRegister(response.response);
      
    }
    
    else if(response.status === 428){
      swal({
        title: "Confirmation Required",
        text: "Please confirm your email and then push the confirmation button",
        icon: "success",
        button: "Confirmed",
      })
      .then(()=>{
        submit(); 
      });
    }
     
    else if(response.status === 409){
      swal({
        title: "Duplicated User",
        // text: duplicatedUsername,
        icon: "error",
        button: "Ok"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }

    else if(response.status === 417){
      swal({
        title: "Duplicated Email",
        // text: duplicatedEmail,
        icon: "error",
        button: "Ok"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }

    else {
      swal({
        title: "An error occured while creating your user",
        // text: errTransaction,
        icon: "error",
        button: "Entiendo"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }
  };

  const newWebAuthnCredentials = async (userCredentials) => {

    console.log(userCredentials);

    const hashedUsername = await sha256(userCredentials.username);

    const createWebAuthnOptions = {
      publicKey:{
        rp:{id: webauthnPKRpId , name: "Wupo"},
        user:{
          id: hashedUsername,
          name: userCredentials.username,
          displayName: userCredentials.username,
        },
        challenge: generateRandomChallenge(32),
        pubKeyCredParams: [
          {type: "public-key", alg: -7},
          { type: "public-key", alg: -257 } // -257 represents RS256
        ],
        authenticatorSelection: {
          authenticatorAttachment: "platform",
          userVerification: "required" // Requires user verification during credential creation
        }
      },
      allowCredentials: [
        {
          id: "credentialsIdentifierOne", // Identifier for the platform authenticator
          type: "public-key",
          transports: ["internal"] // Specify "internal" transport for platform authenticators
        },
        // Add other credentials as needed
      ]
    };

    console.log(createWebAuthnOptions);
    
    try{
      setLoading(true);
      const webAuthnResponse = await navigator.credentials.create(createWebAuthnOptions);

      if(!webAuthnResponse){
        setLoading(false);
        console.log("No response from webauthn");
        
        succesfullRegister(userCredentials);
        return;
      };

      console.log(webAuthnResponse);

      // Extract public key
      const publicKeyArrayBuffer = webAuthnResponse.response.getPublicKey();
      // Convert the public key to base64 string
      const publicKeyBase64 = arrayBufferToBase64Auth(publicKeyArrayBuffer);


      // Extract the authenticator data
      const authenticatorDataArrayBuffer = webAuthnResponse.response.getAuthenticatorData();
      // Convert the authenticator data to base64 string
      const authenticatorDataBase64 = arrayBufferToBase64Auth(authenticatorDataArrayBuffer);

      

      //Challenge validation
      const clientDataJSON = new TextDecoder().decode(webAuthnResponse.response.clientDataJSON);
      const clientData = JSON.parse(clientDataJSON);
      const receivedChallengeBytes = base64urlToUint8Array(clientData.challenge);


      if(!isEqualUint8Arrays(receivedChallengeBytes, createWebAuthnOptions.publicKey.challenge)){
        console.log("Challenge validation failed");
        succesfullRegister(userCredentials);
        return;
      }

      const credentialsRequest = {};
      credentialsRequest.username = userCredentials.username;
      credentialsRequest.credentialId = webAuthnResponse.id;
      credentialsRequest.publicKey = publicKeyBase64;
      credentialsRequest.authenticatorData = authenticatorDataBase64;

      setLoading(true);
      const response = await createWebauthnCredentials(credentialsRequest, userCredentials.accessToken);
      

      if(!response){
        swal({
          title: "Unsuccessful Biometric register",
          icon: "error",
          button: "Ok",
        }).then(() => {
          succesfullRegister(userCredentials);
          return;
        });
      }

      swal({
        title: "Successful Biometric register",
        icon: "success",
        button: "Ok",
      }).then(() => {
        succesfullRegister(userCredentials);
        return;
      });

      console.log(response);
    }catch(error){
      setLoading(false);
      succesfullRegister(userCredentials);
      console.log(error);
      // handleNextClick();
    }

  };

  const sha256 = async (message) => {
    // Encode the message as UTF-8
    const msgBuffer = new TextEncoder().encode(message);
    // Calculate the hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // Convert the hash buffer to a Uint8Array
    return new Uint8Array(hashBuffer);
  };

  const isEqualUint8Arrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
  }

  const base64urlToUint8Array = (base64urlString) => {
    const base64String = base64urlString.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64String);
    const buffer = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
  };

  // Function to convert ArrayBuffer to base64
  const arrayBufferToBase64Auth = (arrayBuffer) => {
    const binary = new Uint8Array(arrayBuffer);
    const binaryString = String.fromCharCode.apply(null, binary);
    return btoa(binaryString);
  }

  const generateRandomChallenge = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return array;
}

  const succesfullRegister = (body) => {

      const loginInfo = {
        accountId: body.username,
        ilpBearerToken: body.ilpBearerToken,
        totpToken: body.totpToken,
        jwtToken: body.accessToken,
        profileCompleted: body.profileCompleted,
        roles: body.roles  
      }

      // console.log(loginInfo);
      userdata(loginInfo);

      updateNavBarEnabled(false); //Disable NavBar

      if(props.location.state && props.location.state.from && props.location.state.from === "simulator"){
        console.log("simulator props");
        history.push({pathname: "/account/generalinfo",
                    state:{
                      loginInfo: loginInfo,
                      creditInformation: props.location.state.creditInformation
                    }
        }); 
        return;
      }

      else{
        console.log(userParams)
        history.push({pathname: "/account/generalinfo",
                    state:{
                      loginInfo: loginInfo,
                      loginParams: userParams,
                    }
        });
        return;  
      }
  };

  const secondFactorRegister = (body) => {

    //Receive the TOTP QR and transform it
    let imgBase64 = arrayBufferToBase64(body);
    let base64Flag = "data:image/jpeg;base64,"
    const img = base64Flag + imgBase64;

    history.push({
      pathname: "/qrregister",
      state: {
        qrImg: img
      }
    })
    
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    let bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  }

  //Disclaimer and privacy consent handling
  const [disclaimer, setDisclaimer] = React.useState(false);
  function handleDisclaimer(){
      setDisclaimer(true);
  }

  const handleClose = () => {
      setDisclaimer(false);
  };

  /**
   * Handle the privacy consent checking and / or unchecking
   * @param {*} e 
   */
  const handleChange = (e) => {
    privacyConsentChecked = e.target.checked;
  };


  //Authentication second factor varibales to open a dialog box and set if enabled or not since the registration
  const [secondFactorBox, setSecondFactorBox] = React.useState(false);

  function handleSecondFactorBox(){
    // setSecondFactorBox(true);
    handleDisableSecondFactor();
  }

  const handleEnableSecondFactor= () => {
    // console.log("handle enable") //DEBBUG
    swal({
      title: "CONFRIMACIÓN HABILITACIÓN",
      text: `\n Por favor confírmanos que quieres activar el código dinámico al ingresar a WUPO`,
      icon: "success",
      buttons: {
        confirmed: "Confirmo", 
        notConfirmed: "Atrás"}
    }).then((value  )=>{
      switch(value){
        case "confirmed":
          secondFactor = true;
          handleSecondFactorClose();
          break;

        case "notConfirmed":
          handleSecondFactorBox();
          break;
        default:
          break;
      }
    });
  };

  const handleDisableSecondFactor= () => {
    // console.log("handle disable") //DEBBUG
    secondFactor = false;
    handleSecondFactorClose();
  };

  const handleSecondFactorClose = () => {
    setSecondFactorBox(false);
    setLoading(true);
    submit();
  };

  const goToLogin = () => {
    history.push("/login");
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
      }}
    >
      <ContentForm>
        {
          loading ?
            <PuffLoader size={200} color={Colors.secondary} css={cssLoader}/>
            :
            <>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                  <Button onClick={goToLogin}
                    sx={{marginTop: "0.5rem", backgroundColor: "none", color: Colors.primary, fontSize: "1rem", fontWeight: "bold"}}
                    component="label"
                  >
                    Already have an user?
                  </Button>
                </Grid>
              </Grid>
              <StepProgress stepNumber={stepNumber} steps={steps} />
              <PageLabel>{steps[stepNumber].name}</PageLabel>
              { stepNumber === 0 ?
                <div style={{maxWidth: "300px", width: "100%"}}>
                  <TextField
                    variant="standard"
                    id="email"
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                    value={inputText}
                    autoComplete="given-email"
                    onChange={textChange}
                    // inputProps={{ maxLength: 10, minLength: 10}}
                  />
                </div>
                : stepNumber === "x" ?
                <div style={{maxWidth: "300px", width: "100%"}}>
                <TextField
                  variant="standard"
                  id="email2"
                  type="email"
                  name="email2"
                  label="Email"
                  fullWidth
                  value={inputText}
                  autoComplete="given-email"
                  onChange={textChange}
                />
                </div>
                :
                <Box style={{maxWidth: "300px", width: "100%"}}>
                  <TextField
                    variant="standard"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    label="Password"
                    fullWidth
                    value={inputText}
                    onChange={textChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handlePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              }
              { color && strengthMessage ?
              <em style={{color: color, whiteSpace: "pre-line", fontSize:"0.8rem", maxWidth: "300px"}}>{strengthMessage}</em>
              :
              <></>
              }
              <AppButton 
                primary 
                width={"40%"}
                onClick={handleNextClick}
              >
                {stepNumber === steps.length - 1 ? "OK" : "OK"}
              </AppButton>
            </>
        }
        {stepNumber === 0 ?
          <Grid container>
            <Grid item xs={12} sm={12} md={12} style={{textAlign: "center"}}>
              <FormControlLabel
                  required
                  control={<Checkbox color="secondary" name="privacyConsent" value="yes" />}
                  label="I accept the privacy consent"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} style={{textAlign: "center"}}>
              <Link onClick={handleDisclaimer} className={classes.linkStyle}>
                Privacy Consent
              </Link>
            </Grid>
          </Grid>
          :
          <></>
        }
        <React.Fragment>
          <Dialog open={disclaimer} onClose={handleDisclaimer} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Privacy Consent</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogStyle}>
            
              {privacyConsent}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                  Ok
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
        <React.Fragment>
          <Dialog open={secondFactorBox} onClose={handleSecondFactorClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">MAYOR SEGURIDAD</DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.dialogStyle}>
            
              {discAuthSecondFactorTextlaimerText}

              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEnableSecondFactor} color="primary">
                  Habilitar
              </Button>
              <Button onClick={handleDisableSecondFactor} color="primary">
                  Deshabilitar
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </ContentForm>
    </div>
  )
}
