const operationUnsuccesful = 
`\n Ocurrió un error, puede que ya hayas creado una cuenta. 

Si es así, al oprimir el botón entiendo te redirigimos a la opción de ingreso. Si no te redirigimos automáticamente, por favor oprime la opción ¿Ya estás registrado?  

Si el error persiste, te invitamos a comunicarte con nosotros`;

const duplicatedUSer = 
`\n 
Ocurrió un error, puede que ya hayas creado una cuenta, si es así, al oprimir el botón entendido te redirigiremos a la pantalla de ingreso.
`;

const duplicatedUsername = 
`\n 
Ocurrió un error, ingresaste un celular que ya está registrado. 

Si ya tienes una cuenta, al oprimir el botón entendido te redirigiremos a la pantalla de ingreso.
`;

const duplicatedEmail = 
`\n 
Ocurrió un error, ingresaste un correo electrónico que ya está registrado. 

Si ya tienes una cuenta, al oprimir el botón entendido te redirigiremos a la pantalla de ingreso.
`;

const authCondition = 
`\n Este dispositivo no está asociado a tu usuario. Por tu seguridad te enviamos 
un correo para que nos confirmes que eres tú.\n\n

Cuando hayas confirmado el correo oprime Confirmado para continuar

Gracias por ayudarnos con tu seguridad`

const authConditionEmail = 
`\n Por tu seguridad te enviamos un correo electrónico para confirmarlo.

Cuando hayas confirmado el correo oprime Confirmado para continuar

Gracias por ayudarnos con tu seguridad`

const authConditionSimulator = 
`\n Por tu seguridad te enviamos 
un correo para que nos confirmes que eres tú.\n\n

Cuando hayas confirmado el correo oprime Confirmado para ingresar con tu celular y contraseña

Gracias por ayudarnos con tu seguridad`

const errTransaction = `\n Ocurrió un error, te invitamos a volver a intentarlo. 

Si el error persiste, te invitamos a comunicarte con nosotros`;

const bankAccountExp = `\n Ingresa la información de tu cuenta bancaria. 

Si ya tienes registrada una cuenta con nostros, te desembolsarémos el préstamo a esa cuenta. 

Por tu seguridad, si deseas cambiar tu cuenta bancaria registrda debes comunicarte con nuestro equipo de servicio al cliente`;

export {operationUnsuccesful, duplicatedUSer, authCondition, authConditionSimulator, authConditionEmail, errTransaction, 
    duplicatedUsername, duplicatedEmail, bankAccountExp }

export default operationUnsuccesful;