const RippleAPI = require("ripple-lib").RippleAPI;

const api = new RippleAPI({
  server: "wss://s.altnet.rippletest.net:51233", // Public rippled server hosted by Ripple, Inc.
});

// const wupoServerUri = {
//   mainnet: "http://wupoapi.ngrok.io",
//   testnet: "http://wupoback.ngrok.io",
//   // devnet: "https://wupoback.ngrok.io", //Changed for testing purposes
//   // devnet: "https://localhost:8080",
//   // devnet: "https://wupotestmiddle.ngrok.io",
//   // devnet: "http://54.166.234.69:5000"
//   devnet: "https://awseb-e-p-awsebloa-m3ljpwrvikxh.wupealo.com"

// }

const wupoServerUri = {
  devnet: process.env.REACT_APP_BACK_URI,
}


const stockOrderSymbol = [
  {
    currency: "APL",
    stockSymbol: "AAPL"
  },
  {
    currency: "GOG",
    stockSymbol: "GOOG"
  },
  {
    currency: "FCB",
    stockSymbol: "FB"
  },
  {
    currency: "AMZ",
    stockSymbol: "AMZN"
  },
  {
    currency: "TSL",
    stockSymbol: "TSLA"
  },
  {
    currency: "GLD",
    stockSymbol: "GOLD"
  },
]

const exchangeCurrencies = [
  {
    currency: "USD",
    exchangeCurrency: "USDT",
  },
  {
    currency: "EUR",
    exchangeCurrency: "EUR",
  },
  {
    currency: "BTC",
    exchangeCurrency: "BTC",
  },
  {
    currency: "ETH",
    exchangeCurrency: "ETH",
  }
];

const retailerTokens = [
  {
    label: "Rappi",
    asset: "RPI",
    value: "COP",
  },
  {
    label: "Merqueo",
    asset: "MQO",
    value: "COP",
  },
  {
    label: "Laika",
    asset: "LKA",
    value: "COP",
  },
  {
    label: "Uber",
    asset: "UBR",
    value: "COP",
  },
  {
    label: "Gastronomy Market",
    asset: "GMK",
    value: "COP",
  },
  {
    label: "Carulla",
    asset: "CAL",
    value: "COP",
  },
  {
    label: "WUPOS",
    asset: "WUP",
    value: "COP",
  }

];

const retailersTokensReferenceCurrency = "COP"

const retailOperation ={
  retailLoginEnable: process.env.REACT_APP_ENABLE_RETAIL_LOGIN,
  retailerJwtEndpoint: process.env.REACT_APP_RETAILER_JWT_ENDPOINT
};

// const creditAsset = "WUP" //currently WUPOS, but exploring if other assets loans make sense.
const creditAsset = "COP";

const creditTerm = {
  day: "day",
  week: "week",
  month: "month",
};

const bankAccountTypes = [
  {code: 7, type: "Cuenta de Ahorros"},
  {code: 1, type: "Cuenta Corriente"},
  {code: 9, type: "Depósito Electrónico"},
]

const bankCodes = [

  {bank: "ITAU", code: "1014"},
  {bank: "BANCOLDEX S.A.S", code: "1031"},
  {bank: "BANCO AGRARIO", code: "1040"},
  {bank: "BANCO MUNDO MUJER", code: "1047"},
  {bank: "BANCO W S.A.", code: "1053"},
  {bank: "BANCO DE LAS MICROFINANZAS - BANCAMIA S.A.", code: "1059"},
  {bank: "BANCO PICHINCHA", code: "1060"},
  {bank: "BANCOOMEVA", code: "1061"},
  {bank: "BANCO FALABELLA S.A.", code: "1062"},
  {bank: "BANCO FINANDINA S.A.", code: "1063"},
  {bank: "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A", code: "1065"},
  {bank: "BANCO COOPERATIVO COOPCENTRAL", code: "1066"},
  {bank: "MIBANCO S.A.", code: "1067"},
  {bank: "BANCO SERFINANZA S.A", code: "1069"},
  {bank: "BANCO J.P. MORGAN COLOMBIA S.A.", code: "1071"},
  {bank: "ASOPAGOS S.A.S", code: "1086"},
  {bank: "FINANCIERA JURISCOOP S.A. COMPANIA DE FINANCIAMIENTO", code: "1121"},
  {bank: "RAPPIPAY DAVIPLATA", code: "1151"},
  {bank: "COOPERATIVA FINANCIERA DE ANTIOQUIA", code: "1283"},
  {bank: "COOTRAFA COOPERATIVA FINANCIERA", code: "1289"},
  {bank: "COOFINEP COOPERATIVA FINANCIERA", code: "1291"},
  {bank: "CONFIAR COOPERATIVA FINANCIERA", code: "1292"},
  {bank: "BANCO UNION S.A", code: "1303"},
  {bank: "COLTEFINANCIERA S.A", code: "1370"},
  {bank: "NEQUI", code: "1507"},
  {bank: "DAVIPLATA", code: "1551"},
  {bank: "BANCO CREDIFINANCIERA SA.", code: "1558"},
  {bank: "IRIS", code: "1637"},
  {bank: "JFK COOPERATIVA FINANCIERA", code: "1286"},
  {bank: "MOVII", code: "1801"},
  {bank: "DING TECNIPAGOS SA", code: "1802"},
  {bank: "UALA", code: "1804"},
  {bank: "BANCO BTG PACTUAL", code: "1805"},
  {bank: "LULO BANK S.A.", code: "1070"},
  {bank: "RAPPIPAY", code: "1811"},
  {bank: "BANCO DE BOGOTA", code: "5600010"},
  {bank: "BANCO POPULAR", code: "5600023"},
  {bank: "ITAU ANTES CORPBANCA", code: "5600065"},
  {bank: "BANCOLOMBIA", code: "5600078"},
  {bank: "CITIBANK", code: "5600094"},
  {bank: "BANCO GNB SUDAMERIS", code: "5600120"},
  {bank: "BBVA COLOMBIA", code: "5600133"},
  {bank: "SCOTIABANK COLPATRIA S.A", code: "5600191"},
  {bank: "BANCO DE OCCIDENTE", code: "5600230"},
  {bank: "BANCO CAJA SOCIAL BCSC SA", code: "5600829"},
  {bank: "BANCO DAVIVIENDA SA", code: "5895142"},
  {bank: "BANCO AV VILLAS", code: "6013677"},
]

const municipalities = [

  {municipality: "EL ENCANTO", code: "91263"},
  {municipality: "LA CHORRERA", code: "91405"},
  {municipality: "LA PEDRERA", code: "91407"},
  {municipality: "LA VICTORIA", code: "91430"},
  {municipality: "LETICIA", code: "91001"},
  {municipality: "MIRITI - PARANA", code: "91460"},
  {municipality: "PUERTO ALEGRIA", code: "91530"},
  {municipality: "PUERTO ARICA", code: "91536"},
  {municipality: "PUERTO NARINO", code: "91540"},
  {municipality: "PUERTO SANTANDER", code: "91669"},
  {municipality: "TARAPACA", code: "91798"},
  {municipality: "CACERES", code: "05120"},
  {municipality: "CAUCASIA", code: "05154"},
  {municipality: "EL BAGRE", code: "05250"},
  {municipality: "NECHI", code: "05495"},
  {municipality: "TARAZA", code: "05790"},
  {municipality: "ZARAGOZA", code: "05895"},
  {municipality: "CARACOLI", code: "05142"},
  {municipality: "MACEO", code: "05425"},
  {municipality: "PUERTO BERRiO", code: "05579"},
  {municipality: "PUERTO NARE", code: "05585"},
  {municipality: "PUERTO TRIUNFO", code: "05591"},
  {municipality: "YONDO", code: "05893"},
  {municipality: "AMALFI", code: "05031"},
  {municipality: "ANORI", code: "05040"},
  {municipality: "CISNEROS", code: "05190"},
  {municipality: "REMEDIOS", code: "05604"},
  {municipality: "SAN ROQUE", code: "05670"},
  {municipality: "SANTO DOMINGO", code: "05690"},
  {municipality: "SEGOVIA", code: "05736"},
  {municipality: "VEGACHI", code: "05858"},
  {municipality: "YALI", code: "05885"},
  {municipality: "YOLOMBO", code: "05890"},
  {municipality: "ANGOSTURA", code: "05038"},
  {municipality: "BELMIRA", code: "05086"},
  {municipality: "BRICENO", code: "05107"},
  {municipality: "CAMPAMENTO", code: "05134"},
  {municipality: "CAROLINA", code: "05150"},
  {municipality: "DON MATiAS", code: "05237"},
  {municipality: "ENTRERRIOS", code: "05264"},
  {municipality: "GOMEZ PLATA", code: "05310"},
  {municipality: "GUADALUPE", code: "05315"},
  {municipality: "ITUANGO", code: "05361"},
  {municipality: "SAN ANDRES", code: "05647"},
  {municipality: "SAN JOSE DE LA MONTANA", code: "05658"},
  {municipality: "SAN PEDRO", code: "05664"},
  {municipality: "SANTA ROSA de osos", code: "05686"},
  {municipality: "TOLEDO", code: "05819"},
  {municipality: "VALDIVIA", code: "05854"},
  {municipality: "YARUMAL", code: "05887"},
  {municipality: "ABRIAQUI", code: "05004"},
  {municipality: "ANZA", code: "05044"},
  {municipality: "ARMENIA", code: "05059"},
  {municipality: "BURITICA", code: "05113"},
  {municipality: "CANASGORDAS", code: "05138"},
  {municipality: "DABEIBA", code: "05234"},
  {municipality: "EBEJICO", code: "05240"},
  {municipality: "FRONTINO", code: "05284"},
  {municipality: "GIRALDO", code: "05306"},
  {municipality: "HELICONIA", code: "05347"},
  {municipality: "LIBORINA", code: "05411"},
  {municipality: "OLAYA", code: "05501"},
  {municipality: "PEQUE", code: "05543"},
  {municipality: "SABANALARGA", code: "05628"},
  {municipality: "SAN JERONIMO", code: "05656"},
  {municipality: "SANTAFE DE ANTIOQUIA", code: "05042"},
  {municipality: "SOPETRaN", code: "05761"},
  {municipality: "URAMITA", code: "05842"},
  {municipality: "ABEJORRAL", code: "05002"},
  {municipality: "ALEJANDRIA", code: "05021"},
  {municipality: "ARGELIA", code: "05055"},
  {municipality: "CARMEN DE VIBORAL", code: "05148"},
  {municipality: "COCORNA", code: "05197"},
  {municipality: "CONCEPCION", code: "05206"},
  {municipality: "GRANADA", code: "05313"},
  {municipality: "GUARNE", code: "05318"},
  {municipality: "GUATAPE", code: "05321"},
  {municipality: "LA CEJA", code: "05376"},
  {municipality: "LA UNION", code: "05400"},
  {municipality: "MARINILLA", code: "05440"},
  {municipality: "NARINO", code: "05483"},
  {municipality: "PENOL", code: "05541"},
  {municipality: "RETIRO", code: "05607"},
  {municipality: "RIONEGRO", code: "05615"},
  {municipality: "SAN CARLOS", code: "05649"},
  {municipality: "SAN FRANCISCO", code: "05652"},
  {municipality: "SAN LUIS", code: "05660"},
  {municipality: "SAN RAFAEL", code: "05667"},
  {municipality: "SAN VICENTE", code: "05674"},
  {municipality: "SANTUARIO", code: "05697"},
  {municipality: "SONSON", code: "05756"},
  {municipality: "AMAGa", code: "05030"},
  {municipality: "ANDES", code: "05034"},
  {municipality: "ANGELOPOLIS", code: "05036"},
  {municipality: "BETANIA", code: "05091"},
  {municipality: "BETULIA", code: "05093"},
  {municipality: "CAICEDO", code: "05125"},
  {municipality: "CARAMANTA", code: "05145"},
  {municipality: "CIUDAD BOLIVAR", code: "05101"},
  {municipality: "CONCORDIA", code: "05209"},
  {municipality: "FREDONIA", code: "05282"},
  {municipality: "HISPANIA", code: "05353"},
  {municipality: "JARDIN", code: "05364"},
  {municipality: "JERICO", code: "05368"},
  {municipality: "LA PINTADA", code: "05390"},
  {municipality: "MONTEBELLO", code: "05467"},
  {municipality: "PUEBLORRICO", code: "05576"},
  {municipality: "SALGAR", code: "05642"},
  {municipality: "SANTA BaRBARA", code: "05679"},
  {municipality: "TAMESIS", code: "05789"},
  {municipality: "TARSO", code: "05792"},
  {municipality: "TITIRIBI", code: "05809"},
  {municipality: "URRAO", code: "05847"},
  {municipality: "VALPARAISO", code: "05856"},
  {municipality: "VENECIA", code: "05861"},
  {municipality: "APARTADO", code: "05045"},
  {municipality: "ARBOLETES", code: "05051"},
  {municipality: "CAREPA", code: "05147"},
  {municipality: "CHIGORODO", code: "05172"},
  {municipality: "MURINDO", code: "05475"},
  {municipality: "MUTATA", code: "05480"},
  {municipality: "NECOCLI", code: "05490"},
  {municipality: "SAN JUAN DE URABA", code: "05659"},
  {municipality: "SAN PEDRO DE URABA", code: "05665"},
  {municipality: "TURBO", code: "05837"},
  {municipality: "VIGIA DEL FUERTE", code: "05873"},
  {municipality: "BARBOSA", code: "05079"},
  {municipality: "BELLO", code: "05088"},
  {municipality: "CALDAS", code: "05129"},
  {municipality: "COPACABANA", code: "05212"},
  {municipality: "ENVIGADO", code: "05266"},
  {municipality: "GIRARDOTA", code: "05308"},
  {municipality: "ITAGUI", code: "05360"},
  {municipality: "LA ESTRELLA", code: "05380"},
  {municipality: "MEDELLIN", code: "05001"},
  {municipality: "SABANETA", code: "05631"},
  {municipality: "ARAUCA", code: "81001"},
  {municipality: "ARAUQUITA", code: "81065"},
  {municipality: "CRAVO NORTE", code: "81220"},
  {municipality: "FORTUL", code: "81300"},
  {municipality: "PUERTO RONDON", code: "81591"},
  {municipality: "SARAVENA", code: "81736"},
  {municipality: "TAME", code: "81794"},
  {municipality: "PROVIDENCIA Y SANTA CATALINA", code: "88564"},
  {municipality: "SAN ANDReS", code: "88001"},
  {municipality: "BARRANQUILLA", code: "08001"},
  {municipality: "GALAPA", code: "08296"},
  {municipality: "MALAMBO", code: "08433"},
  {municipality: "PUERTO COLOMBIA", code: "08573"},
  {municipality: "SOLEDAD", code: "08758"},
  {municipality: "CAMPO DE LA CRUZ", code: "08137"},
  {municipality: "CANDELARIA", code: "08141"},
  {municipality: "LURUACO", code: "08421"},
  {municipality: "MANATi", code: "08436"},
  {municipality: "REPELON", code: "08606"},
  {municipality: "SANTA LUCiA", code: "08675"},
  {municipality: "SUAN", code: "08770"},
  {municipality: "BARANOA", code: "08078"},
  {municipality: "PALMAR DE VARELA", code: "08520"},
  {municipality: "POLONUEVO", code: "08558"},
  {municipality: "PONEDERA", code: "08560"},
  {municipality: "Sabanagrande", code: "08634"},
  {municipality: "SABANALARGA", code: "08638"},
  {municipality: "Santo Tomas", code: "08685"},
  {municipality: "JUAN DE ACOSTA", code: "08372"},
  {municipality: "PIOJO", code: "08549"},
  {municipality: "TUBARA", code: "08832"},
  {municipality: "USIACURi", code: "08849"},
  {municipality: "BOGOTA D.C.", code: "11001"},
  {municipality: "CICUCO", code: "13188"},
  {municipality: "HATILLO DE LOBA", code: "13300"},
  {municipality: "MARGARITA", code: "13440"},
  {municipality: "MOMPOS", code: "13468"},
  {municipality: "SAN FERNANDO", code: "13650"},
  {municipality: "TALAIGUA NUEVO", code: "13780"},
  {municipality: "ARJONA", code: "13052"},
  {municipality: "ARROYOHONDO", code: "13062"},
  {municipality: "CALAMAR", code: "13140"},
  {municipality: "CARTAGENA", code: "13001"},
  {municipality: "CLEMENCIA", code: "13222"},
  {municipality: "MAHATES", code: "13433"},
  {municipality: "SAN CRISTOBAL", code: "13620"},
  {municipality: "SAN ESTANISLAO", code: "13647"},
  {municipality: "SANTA CATALINA", code: "13673"},
  {municipality: "SANTA ROSA DE LIMA", code: "13683"},
  {municipality: "SOPLAVIENTO", code: "13760"},
  {municipality: "TURBACO", code: "13836"},
  {municipality: "TURBANA", code: "13838"},
  {municipality: "VILLANUEVA", code: "13873"},
  {municipality: "ALTOS DEL ROSARIO", code: "13030"},
  {municipality: "BARRANCO DE LOBA", code: "13074"},
  {municipality: "EL PENON", code: "13268"},
  {municipality: "REGIDOR", code: "13580"},
  {municipality: "RIO VIEJO", code: "13600"},
  {municipality: "SAN MARTIN DE LOBA", code: "13667"},
  {municipality: "ARENAL", code: "13042"},
  {municipality: "CANTAGALLO", code: "13160"},
  {municipality: "MORALES", code: "13473"},
  {municipality: "SAN PABLO", code: "13670"},
  {municipality: "SANTA ROSA DEL SUR", code: "13688"},
  {municipality: "SIMITI", code: "13744"},
  {municipality: "ACHI", code: "13006"},
  {municipality: "MAGANGUE", code: "13430"},
  {municipality: "MONTECRISTO", code: "13458"},
  {municipality: "PINILLOS", code: "13549"},
  {municipality: "SAN JACINTO DEL CAUCA", code: "13655"},
  {municipality: "TIQUISIO", code: "13810"},
  {municipality: "CARMEN DE BOLIVAR", code: "13244"},
  {municipality: "CORDOBA", code: "13212"},
  {municipality: "EL GUAMO", code: "13248"},
  {municipality: "MARIA LA BAJA", code: "13442"},
  {municipality: "SAN JACINTO", code: "13654"},
  {municipality: "SAN JUAN NEPOMUCENO", code: "13657"},
  {municipality: "ZAMBRANO", code: "13894"},
  {municipality: "CHIQUIZA", code: "15232"},
  {municipality: "CHIVATA", code: "15187"},
  {municipality: "COMBITA", code: "15204"},
  {municipality: "CUCAITA", code: "15224"},
  {municipality: "MOTAVITA", code: "15476"},
  {municipality: "OICATA", code: "15500"},
  {municipality: "SAMACA", code: "15646"},
  {municipality: "SIACHOQUE", code: "15740"},
  {municipality: "SORA", code: "15762"},
  {municipality: "SORACA", code: "15764"},
  {municipality: "SOTAQUIRA", code: "15763"},
  {municipality: "TOCA", code: "15814"},
  {municipality: "TUNJA", code: "15001"},
  {municipality: "TUTA", code: "15837"},
  {municipality: "VENTAQUEMADA", code: "15861"},
  {municipality: "CHISCAS", code: "15180"},
  {municipality: "CUBARA", code: "15223"},
  {municipality: "EL COCUY", code: "15244"},
  {municipality: "EL ESPINO", code: "15248"},
  {municipality: "GUACAMAYAS", code: "15317"},
  {municipality: "GÜICAN", code: "15332"},
  {municipality: "PANQUEBA", code: "15522"},
  {municipality: "LABRANZAGRANDE", code: "15377"},
  {municipality: "PAJARITO", code: "15518"},
  {municipality: "PAYA", code: "15533"},
  {municipality: "PISBA", code: "15550"},
  {municipality: "BERBEO", code: "15090"},
  {municipality: "CAMPOHERMOSO", code: "15135"},
  {municipality: "MIRAFLORES", code: "15455"},
  {municipality: "PAEZ", code: "15514"},
  {municipality: "SAN EDUARDO", code: "15660"},
  {municipality: "ZETAQUIRA", code: "15897"},
  {municipality: "BOYACA", code: "15104"},
  {municipality: "CIENEGA", code: "15189"},
  {municipality: "JENESANO", code: "15367"},
  {municipality: "NUEVO COLON", code: "15494"},
  {municipality: "RAMIRIQUI", code: "15599"},
  {municipality: "RONDON", code: "15621"},
  {municipality: "TIBANA", code: "15804"},
  {municipality: "TURMEQUE", code: "15835"},
  {municipality: "UMBITA", code: "15842"},
  {municipality: "VIRACACHA", code: "15879"},
  {municipality: "CHINAVITA", code: "15172"},
  {municipality: "GARAGOA", code: "15299"},
  {municipality: "MACANAL", code: "15425"},
  {municipality: "PACHAVITA", code: "15511"},
  {municipality: "SAN LUIS DE GACENO", code: "15667"},
  {municipality: "SANTA MARIA", code: "15690"},
  {municipality: "BOAVITA", code: "15097"},
  {municipality: "COVARACHIA", code: "15218"},
  {municipality: "LA UVITA", code: "15403"},
  {municipality: "SAN MATEO", code: "15673"},
  {municipality: "SATIVANORTE", code: "15720"},
  {municipality: "SATIVASUR", code: "15723"},
  {municipality: "SOATA", code: "15753"},
  {municipality: "SUSACON", code: "15774"},
  {municipality: "TIPACOQUE", code: "15810"},
  {municipality: "BRICENO", code: "15106"},
  {municipality: "BUENAVISTA", code: "15109"},
  {municipality: "CALDAS", code: "15131"},
  {municipality: "CHIQUINQUIRA", code: "15176"},
  {municipality: "COPER", code: "15212"},
  {municipality: "LA VICTORIA", code: "15401"},
  {municipality: "MARIPI", code: "15442"},
  {municipality: "MUZO", code: "15480"},
  {municipality: "OTANCHE", code: "15507"},
  {municipality: "PAUNA", code: "15531"},
  {municipality: "PUERTO BOYACa", code: "15572"},
  {municipality: "QUIPAMA", code: "15580"},
  {municipality: "SABOYA", code: "15632"},
  {municipality: "SAN MIGUEL DE SEMA", code: "15676"},
  {municipality: "SAN PABLO BORBUR", code: "15681"},
  {municipality: "TUNUNGUA", code: "15832"},
  {municipality: "ALMEIDA", code: "15022"},
  {municipality: "CHIVOR", code: "15236"},
  {municipality: "GUATEQUE", code: "15322"},
  {municipality: "GUAYATA", code: "15325"},
  {municipality: "LA CAPILLA", code: "15380"},
  {municipality: "SOMONDOCO", code: "15761"},
  {municipality: "SUTATENZA", code: "15778"},
  {municipality: "TENZA", code: "15798"},
  {municipality: "ARCABUCO", code: "15051"},
  {municipality: "CHITARAQUE", code: "15185"},
  {municipality: "GACHANTIVA", code: "15293"},
  {municipality: "MONIQUIRA", code: "15469"},
  {municipality: "RAQUIRA", code: "15600"},
  {municipality: "SACHICA", code: "15638"},
  {municipality: "SAN JOSE DE PARE", code: "15664"},
  {municipality: "SANTA SOFIA", code: "15696"},
  {municipality: "SANTANA", code: "15686"},
  {municipality: "SUTAMARCHAN", code: "15776"},
  {municipality: "TINJACA", code: "15808"},
  {municipality: "TOGÜI", code: "15816"},
  {municipality: "VILLA DE LEYVA", code: "15407"},
  {municipality: "AQUITANIA", code: "15047"},
  {municipality: "CUITIVA", code: "15226"},
  {municipality: "FIRAVITOBA", code: "15272"},
  {municipality: "GAMEZA", code: "15296"},
  {municipality: "IZA", code: "15362"},
  {municipality: "MONGUA", code: "15464"},
  {municipality: "MONGUI", code: "15466"},
  {municipality: "NOBSA", code: "15491"},
  {municipality: "PESCA", code: "15542"},
  {municipality: "SOGAMOSO", code: "15759"},
  {municipality: "TIBASOSA", code: "15806"},
  {municipality: "TOPAGA", code: "15820"},
  {municipality: "TOTA", code: "15822"},
  {municipality: "BELEN", code: "15087"},
  {municipality: "BUSBANZA", code: "15114"},
  {municipality: "CERINZA", code: "15162"},
  {municipality: "CORRALES", code: "15215"},
  {municipality: "DUITAMA", code: "15238"},
  {municipality: "FLORESTA", code: "15276"},
  {municipality: "PAIPA", code: "15516"},
  {municipality: "SAN ROSA VITERBO", code: "15693"},
  {municipality: "TUTAZA", code: "15839"},
  {municipality: "BETEITIVA", code: "15092"},
  {municipality: "CHITA", code: "15183"},
  {municipality: "JERICO", code: "15368"},
  {municipality: "PAZ DE RIO", code: "15537"},
  {municipality: "SOCHA", code: "15757"},
  {municipality: "SOCOTA", code: "15755"},
  {municipality: "TASCO", code: "15790"},
  {municipality: "FILADELFIA", code: "17272"},
  {municipality: "LA MERCED", code: "17388"},
  {municipality: "MARMATO", code: "17442"},
  {municipality: "RIOSUCIO", code: "17614"},
  {municipality: "SUPIA", code: "17777"},
  {municipality: "MANZANARES", code: "17433"},
  {municipality: "MARQUETALIA", code: "17444"},
  {municipality: "MARULANDA", code: "17446"},
  {municipality: "PENSILVANIA", code: "17541"},
  {municipality: "ANSERMA", code: "17042"},
  {municipality: "BELALCAZAR", code: "17088"},
  {municipality: "RISARALDA", code: "17616"},
  {municipality: "SAN JOSE", code: "17665"},
  {municipality: "VITERBO", code: "17877"},
  {municipality: "CHINCHINa", code: "17174"},
  {municipality: "MANIZALES", code: "17001"},
  {municipality: "NEIRA", code: "17486"},
  {municipality: "PALESTINA", code: "17524"},
  {municipality: "VILLAMARiA", code: "17873"},
  {municipality: "AGUADAS", code: "17013"},
  {municipality: "ARANZAZU", code: "17050"},
  {municipality: "PACORA", code: "17513"},
  {municipality: "SALAMINA", code: "17653"},
  {municipality: "LA DORADA", code: "17380"},
  {municipality: "NORCASIA", code: "17495"},
  {municipality: "SAMANA", code: "17662"},
  {municipality: "VICTORIA", code: "17867"},
  {municipality: "ALBANIA", code: "18029"},
  {municipality: "BELEN DE LOS ANDAQUIES", code: "18094"},
  {municipality: "CARTAGENA DEL CHAIRA", code: "18150"},
  {municipality: "CURRILLO", code: "18205"},
  {municipality: "EL DONCELLO", code: "18247"},
  {municipality: "EL PAUJIL", code: "18256"},
  {municipality: "FLORENCIA", code: "18001"},
  {municipality: "LA MONTANITA", code: "18410"},
  {municipality: "MILaN", code: "18460"},
  {municipality: "MORELIA", code: "18479"},
  {municipality: "PUERTO RICO", code: "18592"},
  {municipality: "SAN JOSE DEL FRAGUA", code: "18610"},
  {municipality: "SAN VICENTE DEL CAGUAN", code: "18753"},
  {municipality: "SOLANO", code: "18756"},
  {municipality: "SOLITA", code: "18785"},
  {municipality: "VALPARAISO", code: "18860"},
  {municipality: "AGUAZUL", code: "85010"},
  {municipality: "CHAMEZA", code: "85015"},
  {municipality: "HATO COROZAL", code: "85125"},
  {municipality: "LA SALINA", code: "85136"},
  {municipality: "MANI", code: "85139"},
  {municipality: "MONTERREY", code: "85162"},
  {municipality: "NUNCHIA", code: "85225"},
  {municipality: "OROCUE", code: "85230"},
  {municipality: "PAZ DE ARIPORO", code: "85250"},
  {municipality: "PORE", code: "85263"},
  {municipality: "RECETOR", code: "85279"},
  {municipality: "SABANALARGA", code: "85300"},
  {municipality: "SACAMA", code: "85315"},
  {municipality: "SAN LUIS DE PALENQUE", code: "85325"},
  {municipality: "TAMARA", code: "85400"},
  {municipality: "TAURAMENA", code: "85410"},
  {municipality: "TRINIDAD", code: "85430"},
  {municipality: "VILLANUEVA", code: "85440"},
  {municipality: "YOPAL", code: "85001"},
  {municipality: "CAJIBIO", code: "19130"},
  {municipality: "EL TAMBO", code: "19256"},
  {municipality: "LA SIERRA", code: "19392"},
  {municipality: "MORALES", code: "19473"},
  {municipality: "PIENDAMO", code: "19548"},
  {municipality: "POPAYAN", code: "19001"},
  {municipality: "ROSAS", code: "19622"},
  {municipality: "SOTARA", code: "19760"},
  {municipality: "TIMBIO", code: "19807"},
  {municipality: "BUENOS AIRES", code: "19110"},
  {municipality: "CALOTO", code: "19142"},
  {municipality: "CORINTO", code: "19212"},
  {municipality: "MIRANDA", code: "19455"},
  {municipality: "PADILLA", code: "19513"},
  {municipality: "PUERTO TEJADA", code: "19573"},
  {municipality: "SANTANDER DE QUILICHAO", code: "19698"},
  {municipality: "SUAREZ", code: "19780"},
  {municipality: "VILLA RICA", code: "19845"},
  {municipality: "GUAPI", code: "19318"},
  {municipality: "LOPEZ", code: "19418"},
  {municipality: "TIMBIQUI", code: "19809"},
  {municipality: "CALDONO", code: "19137"},
  {municipality: "INZA", code: "19355"},
  {municipality: "JAMBALO", code: "19364"},
  {municipality: "PAEZ", code: "19517"},
  {municipality: "PURACE", code: "19585"},
  {municipality: "Silvia", code: "19743"},
  {municipality: "TORIBIO", code: "19821"},
  {municipality: "TOTORO", code: "19824"},
  {municipality: "ALMAGUER", code: "19022"},
  {municipality: "ARGELIA", code: "19050"},
  {municipality: "BALBOA", code: "19075"},
  {municipality: "BOLIVAR", code: "19100"},
  {municipality: "FLORENCIA", code: "19290"},
  {municipality: "LA VEGA", code: "19397"},
  {municipality: "MERCADERES", code: "19450"},
  {municipality: "PATIA", code: "19532"},
  {municipality: "PIAMONTE", code: "19533"},
  {municipality: "SAN SEBASTIAN", code: "19693"},
  {municipality: "SANTA ROSA", code: "19701"},
  {municipality: "SUCRE", code: "19785"},
  {municipality: "BECERRIL", code: "20045"},
  {municipality: "CHIMICHAGUA", code: "20175"},
  {municipality: "CHIRIGUANA", code: "20178"},
  {municipality: "CURUMANI", code: "20228"},
  {municipality: "LA JAGUA DE IBIRICO", code: "20400"},
  {municipality: "PAILITAS", code: "20517"},
  {municipality: "TAMALAMEQUE", code: "20787"},
  {municipality: "ASTREA", code: "20032"},
  {municipality: "BOSCONIA", code: "20060"},
  {municipality: "EL COPEY", code: "20238"},
  {municipality: "EL PASO", code: "20250"},
  {municipality: "AGUSTIN CODAZZI", code: "20013"},
  {municipality: "LA PAZ", code: "20621"},
  {municipality: "MANAURE", code: "20443"},
  {municipality: "PUEBLO BELLO", code: "20570"},
  {municipality: "SAN DIEGO", code: "20750"},
  {municipality: "VALLEDUPAR", code: "20001"},
  {municipality: "AGUACHICA", code: "20011"},
  {municipality: "GAMARRA", code: "20295"},
  {municipality: "GONZALEZ", code: "20310"},
  {municipality: "LA GLORIA", code: "20383"},
  {municipality: "PELAYA", code: "20550"},
  {municipality: "RIO DE ORO", code: "20614"},
  {municipality: "SAN ALBERTO", code: "20710"},
  {municipality: "SAN MARTIN", code: "20770"},
  {municipality: "ATRATO", code: "27050"},
  {municipality: "BAGADO", code: "27073"},
  {municipality: "BOJAYA", code: "27099"},
  {municipality: "EL CARMEN DE ATRATO", code: "27245"},
  {municipality: "LLORO", code: "27413"},
  {municipality: "MEDIO ATRATO", code: "27425"},
  {municipality: "QUIBDO", code: "27001"},
  {municipality: "RIO QUITO", code: "27600"},
  {municipality: "ACANDI", code: "27006"},
  {municipality: "BELEN DE BAJIRA", code: "27086"},
  {municipality: "CARMEN DEL DARIEN", code: "27150"},
  {municipality: "RIOSUCIO", code: "27615"},
  {municipality: "UNGUIA", code: "27800"},
  {municipality: "BAHIA SOLANO", code: "27075"},
  {municipality: "JURADO", code: "27372"},
  {municipality: "NUQUI", code: "27495"},
  {municipality: "ALTO BAUDO", code: "27025"},
  {municipality: "BAJO BAUDO", code: "27077"},
  {municipality: "El Litoral del San Juan", code: "27250"},
  {municipality: "MEDIO BAUDO", code: "27430"},
  {municipality: "CANTON DE SAN PABLO", code: "27135"},
  {municipality: "CERTEGUI", code: "27160"},
  {municipality: "CONDOTO", code: "27205"},
  {municipality: "ITSMINA", code: "27361"},
  {municipality: "MEDIO SAN JUAN", code: "27450"},
  {municipality: "NOVITA", code: "27491"},
  {municipality: "RIO FRIO", code: "27580"},
  {municipality: "SAN JOSE DEL PALMAR", code: "27660"},
  {municipality: "SIPI", code: "27745"},
  {municipality: "TADO", code: "27787"},
  {municipality: "UNION PANAMERICANA", code: "27810"},
  {municipality: "TIERRALTA", code: "23807"},
  {municipality: "VALENCIA", code: "23855"},
  {municipality: "CHIMA", code: "23168"},
  {municipality: "COTORRA", code: "23300"},
  {municipality: "LORICA", code: "23417"},
  {municipality: "MOMIL", code: "23464"},
  {municipality: "PURISIMA", code: "23586"},
  {municipality: "MONTERIA", code: "23001"},
  {municipality: "CANALETE", code: "23090"},
  {municipality: "LOS CORDOBAS", code: "23419"},
  {municipality: "MONITOS", code: "23500"},
  {municipality: "PUERTO ESCONDIDO", code: "23574"},
  {municipality: "SAN ANTERO", code: "23672"},
  {municipality: "SAN BERNARDO DEL VIENTO", code: "23675"},
  {municipality: "CHINU", code: "23182"},
  {municipality: "SAHAGUN", code: "23660"},
  {municipality: "SAN ANDRES SOTAVENTO", code: "23670"},
  {municipality: "AYAPEL", code: "23068"},
  {municipality: "BUENAVISTA", code: "23079"},
  {municipality: "LA APARTADA", code: "23350"},
  {municipality: "MONTELIBANO", code: "23466"},
  {municipality: "PLANETA RICA", code: "23555"},
  {municipality: "PUEBLO NUEVO", code: "23570"},
  {municipality: "PUERTO LIBERTADOR", code: "23580"},
  {municipality: "CERETE", code: "23162"},
  {municipality: "CIENAGA DE ORO", code: "23189"},
  {municipality: "SAN CARLOS", code: "23678"},
  {municipality: "SAN PELAYO", code: "23686"},
  {municipality: "CHOCONTA", code: "25183"},
  {municipality: "MACHETA", code: "25426"},
  {municipality: "MANTA", code: "25436"},
  {municipality: "SESQUILE", code: "25736"},
  {municipality: "SUESCA", code: "25772"},
  {municipality: "TIBIRITA", code: "25807"},
  {municipality: "VILLAPINZON", code: "25873"},
  {municipality: "AGUA DE DIOS", code: "25001"},
  {municipality: "GIRARDOT", code: "25307"},
  {municipality: "GUATAQUI", code: "25324"},
  {municipality: "JERUSALEN", code: "25368"},
  {municipality: "NARINO", code: "25483"},
  {municipality: "NILO", code: "25488"},
  {municipality: "RICAURTE", code: "25612"},
  {municipality: "TOCAIMA", code: "25815"},
  {municipality: "CAPARRAPI", code: "25148"},
  {municipality: "GUADUAS", code: "25320"},
  {municipality: "PUERTO SALGAR", code: "25572"},
  {municipality: "ALBAN", code: "25019"},
  {municipality: "LA PENA", code: "25398"},
  {municipality: "LA VEGA", code: "25402"},
  {municipality: "NIMAIMA", code: "25489"},
  {municipality: "NOCAIMA", code: "25491"},
  {municipality: "QUEBRADANEGRA", code: "25592"},
  {municipality: "SAN FRANCISCO", code: "25658"},
  {municipality: "SASAIMA", code: "25718"},
  {municipality: "SUPATA", code: "25777"},
  {municipality: "UTICA", code: "25851"},
  {municipality: "VERGARA", code: "25862"},
  {municipality: "VILLETA", code: "25875"},
  {municipality: "GACHALA", code: "25293"},
  {municipality: "GACHETA", code: "25297"},
  {municipality: "GAMA", code: "25299"},
  {municipality: "GUASCA", code: "25322"},
  {municipality: "GUATAVITA", code: "25326"},
  {municipality: "JUNIN", code: "25372"},
  {municipality: "LA CALERA", code: "25377"},
  {municipality: "UBALA", code: "25839"},
  {municipality: "BELTRAN", code: "25086"},
  {municipality: "BITUIMA", code: "25095"},
  {municipality: "CHAGUANI", code: "25168"},
  {municipality: "GUAYABAL DE SIQUIMA", code: "25328"},
  {municipality: "PULI", code: "25580"},
  {municipality: "SAN JUAN DE RIO SECO", code: "25662"},
  {municipality: "VIANI", code: "25867"},
  {municipality: "MEDINA", code: "25438"},
  {municipality: "PARATEBUENO", code: "25530"},
  {municipality: "CAQUEZA", code: "25151"},
  {municipality: "CHIPAQUE", code: "25178"},
  {municipality: "CHOACHI", code: "25181"},
  {municipality: "FOMEQUE", code: "25279"},
  {municipality: "FOSCA", code: "25281"},
  {municipality: "GUAYABETAL", code: "25335"},
  {municipality: "GUTIERREZ", code: "25339"},
  {municipality: "QUETAME", code: "25594"},
  {municipality: "UBAQUE", code: "25841"},
  {municipality: "UNE", code: "25845"},
  {municipality: "EL PENON", code: "25258"},
  {municipality: "LA PALMA", code: "25394"},
  {municipality: "PACHO", code: "25513"},
  {municipality: "PAIME", code: "25518"},
  {municipality: "SAN CAYETANO", code: "25653"},
  {municipality: "TOPAIPI", code: "25823"},
  {municipality: "VILLAGOMEZ", code: "25871"},
  {municipality: "YACOPI", code: "25885"},
  {municipality: "CAJICA", code: "25126"},
  {municipality: "CHIA", code: "25175"},
  {municipality: "COGUA", code: "25200"},
  {municipality: "GACHANCIPA", code: "25295"},
  {municipality: "NEMOCoN", code: "25486"},
  {municipality: "SOPO", code: "25758"},
  {municipality: "TABIO", code: "25785"},
  {municipality: "TOCANCIPA", code: "25817"},
  {municipality: "ZIPAQUIRA", code: "25899"},
  {municipality: "BOJACA", code: "25099"},
  {municipality: "COTA", code: "25214"},
  {municipality: "EL ROSAL", code: "25260"},
  {municipality: "FACATATIVA", code: "25269"},
  {municipality: "FUNZA", code: "25286"},
  {municipality: "MADRID", code: "25430"},
  {municipality: "MOSQUERA", code: "25473"},
  {municipality: "SUBACHOQUE", code: "25769"},
  {municipality: "TENJO", code: "25799"},
  {municipality: "ZIPACoN", code: "25898"},
  {municipality: "SIBATE", code: "25740"},
  {municipality: "SOACHA", code: "25754"},
  {municipality: "ARBELAEZ", code: "25053"},
  {municipality: "CABRERA", code: "25120"},
  {municipality: "FUSAGASUGA", code: "25290"},
  {municipality: "GRANADA", code: "25312"},
  {municipality: "PANDI", code: "25524"},
  {municipality: "PASCA", code: "25535"},
  {municipality: "SAN BERNARDO", code: "25649"},
  {municipality: "SILVANIA", code: "25743"},
  {municipality: "TIBACUY", code: "25805"},
  {municipality: "VENECIA", code: "25506"},
  {municipality: "ANAPOIMA", code: "25035"},
  {municipality: "ANOLAIMA", code: "25040"},
  {municipality: "APULO", code: "25599"},
  {municipality: "CACHIPAY", code: "25123"},
  {municipality: "EL COLEGIO", code: "25245"},
  {municipality: "LA MESA", code: "25386"},
  {municipality: "QUIPILE", code: "25596"},
  {municipality: "SAN ANTONIO DE TEQUENDAMA", code: "25645"},
  {municipality: "TENA", code: "25797"},
  {municipality: "VIOTA", code: "25878"},
  {municipality: "CARMEN DE CARUPA", code: "25154"},
  {municipality: "CUCUNUBA", code: "25224"},
  {municipality: "FUQUENE", code: "25288"},
  {municipality: "GUACHETA", code: "25317"},
  {municipality: "LENGUAZAQUE", code: "25407"},
  {municipality: "SIMIJACA", code: "25745"},
  {municipality: "SUSA", code: "25779"},
  {municipality: "SUTATAUSA", code: "25781"},
  {municipality: "TAUSA", code: "25793"},
  {municipality: "UBATE", code: "25843"},
  {municipality: "BARRANCO MINA", code: "94343"},
  {municipality: "CACAHUAL", code: "94886"},
  {municipality: "INIRIDA", code: "94001"},
  {municipality: "LA GUADALUPE", code: "94885"},
  {municipality: "MAPIRIPaN", code: "94663"},
  {municipality: "MORICHAL", code: "94888"},
  {municipality: "PANA PANA", code: "94887"},
  {municipality: "PUERTO COLOMBIA", code: "94884"},
  {municipality: "SAN FELIPE", code: "94883"},
  {municipality: "CALAMAR", code: "95015"},
  {municipality: "EL RETORNO", code: "95025"},
  {municipality: "MIRAFLORES", code: "95200"},
  {municipality: "SAN JOSE DEL GUAVIARE", code: "95001"},
  {municipality: "AGRADO", code: "41013"},
  {municipality: "ALTAMIRA", code: "41026"},
  {municipality: "GARZON", code: "41298"},
  {municipality: "GIGANTE", code: "41306"},
  {municipality: "GUADALUPE", code: "41319"},
  {municipality: "PITAL", code: "41548"},
  {municipality: "SUAZA", code: "41770"},
  {municipality: "TARQUI", code: "41791"},
  {municipality: "AIPE", code: "41016"},
  {municipality: "ALGECIRAS", code: "41020"},
  {municipality: "BARAYA", code: "41078"},
  {municipality: "CAMPOALEGRE", code: "41132"},
  {municipality: "COLOMBIA", code: "41206"},
  {municipality: "HOBO", code: "41349"},
  {municipality: "IQUIRA", code: "41357"},
  {municipality: "NEIVA", code: "41001"},
  {municipality: "PALERMO", code: "41524"},
  {municipality: "RIVERA", code: "41615"},
  {municipality: "SANTA MARIA", code: "41676"},
  {municipality: "TELLO", code: "41799"},
  {municipality: "TERUEL", code: "41801"},
  {municipality: "VILLAVIEJA", code: "41872"},
  {municipality: "YAGUARA", code: "41885"},
  {municipality: "LA ARGENTINA", code: "41378"},
  {municipality: "LA PLATA", code: "41396"},
  {municipality: "NATAGA", code: "41483"},
  {municipality: "PAICOL", code: "41518"},
  {municipality: "TESALIA", code: "41797"},
  {municipality: "ACEVEDO", code: "41006"},
  {municipality: "ELIAS", code: "41244"},
  {municipality: "ISNOS", code: "41359"},
  {municipality: "OPORAPA", code: "41503"},
  {municipality: "PALESTINA", code: "41530"},
  {municipality: "PITALITO", code: "41551"},
  {municipality: "SALADOBLANCO", code: "41660"},
  {municipality: "SAN AGUSTIN", code: "41668"},
  {municipality: "TIMANA", code: "41807"},
  {municipality: "ALBANIA", code: "44035"},
  {municipality: "DIBULLA", code: "44090"},
  {municipality: "MAICAO", code: "44430"},
  {municipality: "MANAURE", code: "44560"},
  {municipality: "RIOHACHA", code: "44001"},
  {municipality: "URIBIA", code: "44847"},
  {municipality: "BARRANCAS", code: "44078"},
  {municipality: "DISTRACCION", code: "44098"},
  {municipality: "EL MOLINO", code: "44110"},
  {municipality: "FONSECA", code: "44279"},
  {municipality: "HATONUEVO", code: "44378"},
  {municipality: "LA JAGUA DEL PILAR", code: "44420"},
  {municipality: "SAN JUAN DEL CESAR", code: "44650"},
  {municipality: "URUMITA", code: "44855"},
  {municipality: "VILLANUEVA", code: "44874"},
  {municipality: "ARIGUANI", code: "47058"},
  {municipality: "CHIBOLO", code: "47170"},
  {municipality: "NUEVA GRANADA", code: "47460"},
  {municipality: "PLATO", code: "47555"},
  {municipality: "SABANAS DE SAN ANGEL", code: "47660"},
  {municipality: "TENERIFE", code: "47798"},
  {municipality: "ALGARROBO", code: "47030"},
  {municipality: "ARACATACA", code: "47053"},
  {municipality: "CIENAGA", code: "47189"},
  {municipality: "EL RETEN", code: "47268"},
  {municipality: "FUNDACION", code: "47288"},
  {municipality: "PUEBLO VIEJO", code: "47570"},
  {municipality: "ZONA BANANERA", code: "47980"},
  {municipality: "CERRO SAN ANTONIO", code: "47161"},
  {municipality: "CONCORDIA", code: "47205"},
  {municipality: "EL PINON", code: "47258"},
  {municipality: "PEDRAZA", code: "47541"},
  {municipality: "PIVIJAY", code: "47551"},
  {municipality: "REMOLINO", code: "47605"},
  {municipality: "SALAMINA", code: "47675"},
  {municipality: "SITIONUEVO", code: "47745"},
  {municipality: "ZAPAYAN", code: "47960"},
  {municipality: "SANTA MARTA", code: "47001"},
  {municipality: "EL BANCO", code: "47245"},
  {municipality: "GUAMAL", code: "47318"},
  {municipality: "PIJINO DEL CARMEN", code: "47545"},
  {municipality: "SAN SEBASTIAN DE BUENAVISTA", code: "47692"},
  {municipality: "SAN ZENON", code: "47703"},
  {municipality: "SANTA ANA", code: "47707"},
  {municipality: "SANTA BARBARA DE PINTO", code: "47720"},
  {municipality: "EL CASTILLO", code: "50251"},
  {municipality: "EL DORADO", code: "50270"},
  {municipality: "FUENTE DE ORO", code: "50287"},
  {municipality: "GRANADA", code: "50313"},
  {municipality: "LA MACARENA", code: "50350"},
  {municipality: "LA URIBE", code: "50370"},
  {municipality: "LEJANIAS", code: "50400"},
  {municipality: "MAPIRIPaN", code: "50325"},
  {municipality: "MESETAS", code: "50330"},
  {municipality: "PUERTO CONCORDIA", code: "50450"},
  {municipality: "PUERTO LLERAS", code: "50577"},
  {municipality: "PUERTO RICO", code: "50590"},
  {municipality: "SAN JUAN DE ARAMA", code: "50683"},
  {municipality: "VISTA HERMOSA", code: "50711"},
  {municipality: "VILLAVICENCIO", code: "50001"},
  {municipality: "ACACiAS", code: "50006"},
  {municipality: "BARRANCA DE UPIA", code: "50110"},
  {municipality: "CASTILLA LA NUEVA", code: "50150"},
  {municipality: "CUMARAL", code: "50226"},
  {municipality: "EL CALVARIO", code: "50245"},
  {municipality: "GUAMAL", code: "50318"},
  {municipality: "RESTREPO", code: "50606"},
  {municipality: "SAN CARLOS GUAROA", code: "50680"},
  {municipality: "SAN JUANITO", code: "50686"},
  {municipality: "SAN LUIS DE CUBARRAL", code: "50223"},
  {municipality: "SAN MARTIN", code: "50689"},
  {municipality: "CABUYARO", code: "50124"},
  {municipality: "PUERTO GAITAN", code: "50568"},
  {municipality: "PUERTO LoPEZ", code: "50573"},
  {municipality: "CHACHAGUI", code: "52240"},
  {municipality: "CONSACA", code: "52207"},
  {municipality: "EL PENOL", code: "52254"},
  {municipality: "EL TAMBO", code: "52260"},
  {municipality: "LA FLORIDA", code: "52381"},
  {municipality: "NARINO", code: "52480"},
  {municipality: "PASTO", code: "52001"},
  {municipality: "SANDONA", code: "52683"},
  {municipality: "TANGUA", code: "52788"},
  {municipality: "YACUANQUER", code: "52885"},
  {municipality: "ANCUYA", code: "52036"},
  {municipality: "GUAITARILLA", code: "52320"},
  {municipality: "LA LLANADA", code: "52385"},
  {municipality: "LINARES", code: "52411"},
  {municipality: "LOS ANDES", code: "52418"},
  {municipality: "MALLAMA", code: "52435"},
  {municipality: "OSPINA", code: "52506"},
  {municipality: "PROVIDENCIA", code: "52565"},
  {municipality: "RICAURTE", code: "52612"},
  {municipality: "SAMANIEGO", code: "52678"},
  {municipality: "SANTA CRUZ", code: "52699"},
  {municipality: "SAPUYES", code: "52720"},
  {municipality: "TUQUERRES", code: "52838"},
  {municipality: "BARBACOAS", code: "52079"},
  {municipality: "EL CHARCO", code: "52250"},
  {municipality: "FRANCISCO PIZARRO", code: "52520"},
  {municipality: "LA TOLA", code: "52390"},
  {municipality: "Magui", code: "52427"},
  {municipality: "MOSQUERA", code: "52473"},
  {municipality: "OLAYA HERRERA", code: "52490"},
  {municipality: "ROBERTO PAYAN", code: "52621"},
  {municipality: "SANTA BaRBARA", code: "52696"},
  {municipality: "TUMACO", code: "52835"},
  {municipality: "ALBAN", code: "52019"},
  {municipality: "ARBOLEDA", code: "52051"},
  {municipality: "BELEN", code: "52083"},
  {municipality: "BUESACO", code: "52110"},
  {municipality: "COLON", code: "52203"},
  {municipality: "CUMBITARA", code: "52233"},
  {municipality: "EL ROSARIO", code: "52256"},
  {municipality: "El Tablon de Gomez", code: "52258"},
  {municipality: "LA CRUZ", code: "52378"},
  {municipality: "LA UNION", code: "52399"},
  {municipality: "LEIVA", code: "52405"},
  {municipality: "POLICARPA", code: "52540"},
  {municipality: "SAN BERNARDO", code: "52685"},
  {municipality: "SAN LORENZO", code: "52687"},
  {municipality: "SAN PABLO", code: "52693"},
  {municipality: "SAN PEDRO DE CARTAGO", code: "52694"},
  {municipality: "TAMINANGO", code: "52786"},
  {municipality: "ALDANA", code: "52022"},
  {municipality: "CONTADERO", code: "52210"},
  {municipality: "CORDOBA", code: "52215"},
  {municipality: "CUASPUD", code: "52224"},
  {municipality: "CUMBAL", code: "52227"},
  {municipality: "FUNES", code: "52287"},
  {municipality: "GUACHUCAL", code: "52317"},
  {municipality: "GUALMATAN", code: "52323"},
  {municipality: "ILES", code: "52352"},
  {municipality: "IMUES", code: "52354"},
  {municipality: "IPIALES", code: "52356"},
  {municipality: "POTOSI", code: "52560"},
  {municipality: "PUERRES", code: "52573"},
  {municipality: "PUPIALES", code: "52585"},
  {municipality: "ARBOLEDAS", code: "54051"},
  {municipality: "CUCUTILLA", code: "54223"},
  {municipality: "GRAMALOTE", code: "54313"},
  {municipality: "LOURDES", code: "54418"},
  {municipality: "SALAZAR", code: "54660"},
  {municipality: "SANTIAGO", code: "54680"},
  {municipality: "VILLA CARO", code: "54871"},
  {municipality: "BUCARASICA", code: "54109"},
  {municipality: "EL TARRA", code: "54250"},
  {municipality: "SARDINATA", code: "54720"},
  {municipality: "TIBU", code: "54810"},
  {municipality: "ABREGO", code: "54003"},
  {municipality: "CACHIRA", code: "54128"},
  {municipality: "CONVENCION", code: "54206"},
  {municipality: "EL CARMEN", code: "54245"},
  {municipality: "HACARI", code: "54344"},
  {municipality: "LA ESPERANZA", code: "54385"},
  {municipality: "LA PLAYA", code: "54398"},
  {municipality: "OCANA", code: "54498"},
  {municipality: "SAN CALIXTO", code: "54670"},
  {municipality: "TEORAMA", code: "54800"},
  {municipality: "CUCUTA", code: "54001"},
  {municipality: "EL ZULIA", code: "54261"},
  {municipality: "LOS PATIOS", code: "54405"},
  {municipality: "PUERTO SANTANDER", code: "54553"},
  {municipality: "SAN CAYETANO", code: "54673"},
  {municipality: "VILLA DEL ROSARIO", code: "54874"},
  {municipality: "CACOTA", code: "54125"},
  {municipality: "CHITAGA", code: "54174"},
  {municipality: "MUTISCUA", code: "54480"},
  {municipality: "PAMPLONA", code: "54518"},
  {municipality: "PAMPLONITA", code: "54520"},
  {municipality: "SILOS", code: "54743"},
  {municipality: "BOCHALEMA", code: "54099"},
  {municipality: "CHINACOTA", code: "54172"},
  {municipality: "DURANIA", code: "54239"},
  {municipality: "HERRAN", code: "54347"},
  {municipality: "LABATECA", code: "54377"},
  {municipality: "RAGONVALIA", code: "54599"},
  {municipality: "TOLEDO", code: "54820"},
  {municipality: "COLON", code: "86219"},
  {municipality: "MOCOA", code: "86001"},
  {municipality: "ORITO", code: "86320"},
  {municipality: "PUERTO ASIS", code: "86568"},
  {municipality: "PUERTO CAICEDO", code: "86569"},
  {municipality: "PUERTO GUZMAN", code: "86571"},
  {municipality: "PUERTO LEGUIZAMO", code: "86573"},
  {municipality: "SAN FRANCISCO", code: "86755"},
  {municipality: "SAN MIGUEL", code: "86757"},
  {municipality: "SANTIAGO", code: "86760"},
  {municipality: "SIBUNDOY", code: "86749"},
  {municipality: "VALLE DEL GUAMUEZ", code: "86865"},
  {municipality: "VILLA GARZON", code: "86885"},
  {municipality: "ARMENIA", code: "63001"},
  {municipality: "BUENAVISTA", code: "63111"},
  {municipality: "CALARCA", code: "63130"},
  {municipality: "CoRDOBA", code: "63212"},
  {municipality: "GeNOVA", code: "63302"},
  {municipality: "PIJAO", code: "63548"},
  {municipality: "FILANDIA", code: "63272"},
  {municipality: "SALENTO", code: "63690"},
  {municipality: "CIRCASIA", code: "63190"},
  {municipality: "LA TEBAIDA", code: "63401"},
  {municipality: "Montengro", code: "63470"},
  {municipality: "QUIMBAYA", code: "63594"},
  {municipality: "DOSQUEBRADAS", code: "66170"},
  {municipality: "LA VIRGINIA", code: "66400"},
  {municipality: "MARSELLA", code: "66440"},
  {municipality: "PEREIRA", code: "66001"},
  {municipality: "SANTA ROSA DE CABAL", code: "66682"},
  {municipality: "APIA", code: "66045"},
  {municipality: "BALBOA", code: "66075"},
  {municipality: "BELEN DE UMBRIA", code: "66088"},
  {municipality: "GUATICA", code: "66318"},
  {municipality: "LA CELIA", code: "66383"},
  {municipality: "QUINCHiA", code: "66594"},
  {municipality: "SANTUARIO", code: "66687"},
  {municipality: "MISTRATO", code: "66456"},
  {municipality: "PUEBLO RICO", code: "66572"},
  {municipality: "CHIMA", code: "68176"},
  {municipality: "CONFINES", code: "68209"},
  {municipality: "CONTRATACION", code: "68211"},
  {municipality: "EL GUACAMAYO", code: "68245"},
  {municipality: "GALAN", code: "68296"},
  {municipality: "GAMBITA", code: "68298"},
  {municipality: "GUADALUPE", code: "68320"},
  {municipality: "GUAPOTA", code: "68322"},
  {municipality: "HATO", code: "68344"},
  {municipality: "OIBA", code: "68500"},
  {municipality: "PALMAR", code: "68522"},
  {municipality: "PALMAS DEL SOCORRO", code: "68524"},
  {municipality: "SANTA HELENA DEL OPON", code: "68720"},
  {municipality: "SIMACOTA", code: "68745"},
  {municipality: "SOCORRO", code: "68755"},
  {municipality: "SUAITA", code: "68770"},
  {municipality: "CAPITANEJO", code: "68147"},
  {municipality: "CARCASI", code: "68152"},
  {municipality: "CEPITA", code: "68160"},
  {municipality: "CERRITO", code: "68162"},
  {municipality: "CONCEPCION", code: "68207"},
  {municipality: "ENCISO", code: "68266"},
  {municipality: "GUACA", code: "68318"},
  {municipality: "MACARAVITA", code: "68425"},
  {municipality: "MALAGA", code: "68432"},
  {municipality: "MOLAGAVITA", code: "68468"},
  {municipality: "SAN ANDRES", code: "68669"},
  {municipality: "SAN JOSE DE MIRANDA", code: "68684"},
  {municipality: "SAN MIGUEL", code: "68686"},
  {municipality: "ARATOCA", code: "68051"},
  {municipality: "BARICHARA", code: "68079"},
  {municipality: "CABRERA", code: "68121"},
  {municipality: "CHARALA", code: "68167"},
  {municipality: "COROMORO", code: "68217"},
  {municipality: "CURITI", code: "68229"},
  {municipality: "ENCINO", code: "68264"},
  {municipality: "JORDAN", code: "68370"},
  {municipality: "MOGOTES", code: "68464"},
  {municipality: "OCAMONTE", code: "68498"},
  {municipality: "ONZAGA", code: "68502"},
  {municipality: "PARAMO", code: "68533"},
  {municipality: "PINCHOTE", code: "68549"},
  {municipality: "SAN GIL", code: "68679"},
  {municipality: "SAN JOAQUIN", code: "68682"},
  {municipality: "VALLE DE SAN JOSE", code: "68855"},
  {municipality: "VILLANUEVA", code: "68872"},
  {municipality: "BARRANCABERMEJA", code: "68081"},
  {municipality: "BETULIA", code: "68092"},
  {municipality: "EL CARMEN DE CHUCURI", code: "68235"},
  {municipality: "PUERTO WILCHES", code: "68575"},
  {municipality: "SABANA DE TORRES", code: "68655"},
  {municipality: "SAN VICENTE DE CHUCURI", code: "68689"},
  {municipality: "ZAPATOCA", code: "68895"},
  {municipality: "BUCARAMANGA", code: "68001"},
  {municipality: "CALIFORNIA", code: "68132"},
  {municipality: "CHARTA", code: "68169"},
  {municipality: "EL PLAYON", code: "68255"},
  {municipality: "FLORIDABLANCA", code: "68276"},
  {municipality: "GIRON", code: "68307"},
  {municipality: "LEBRIJA", code: "68406"},
  {municipality: "LOS SANTOS", code: "68418"},
  {municipality: "MATANZA", code: "68444"},
  {municipality: "PIEDECUESTA", code: "68547"},
  {municipality: "RIONEGRO", code: "68615"},
  {municipality: "SANTA BARBARA", code: "68705"},
  {municipality: "SURATA", code: "68780"},
  {municipality: "TONA", code: "68820"},
  {municipality: "VETAS", code: "68867"},
  {municipality: "AGUADA", code: "68013"},
  {municipality: "ALBANIA", code: "68020"},
  {municipality: "BARBOSA", code: "68077"},
  {municipality: "BOLIVAR", code: "68101"},
  {municipality: "CHIPATA", code: "68179"},
  {municipality: "CIMITARRA", code: "68190"},
  {municipality: "EL PENON", code: "68250"},
  {municipality: "FLORIAN", code: "68271"},
  {municipality: "GUAVATA", code: "68324"},
  {municipality: "GuEPSA", code: "68327"},
  {municipality: "JESUS MARIA", code: "68368"},
  {municipality: "LA BELLEZA", code: "68377"},
  {municipality: "LA PAZ", code: "68397"},
  {municipality: "LANDAZURI", code: "68385"},
  {municipality: "PUENTE NACIONAL", code: "68572"},
  {municipality: "PUERTO PARRA", code: "68573"},
  {municipality: "SAN BENITO", code: "68673"},
  {municipality: "SUCRE", code: "68773"},
  {municipality: "VELEZ", code: "68861"},
  {municipality: "GUARANDA", code: "70265"},
  {municipality: "MAJAGUAL", code: "70429"},
  {municipality: "SUCRE", code: "70771"},
  {municipality: "CHALAN", code: "70230"},
  {municipality: "COLOSO", code: "70204"},
  {municipality: "MORROA", code: "70473"},
  {municipality: "OVEJAS", code: "70508"},
  {municipality: "SINCELEJO", code: "70001"},
  {municipality: "COVENAS", code: "70221"},
  {municipality: "PALMITO", code: "70523"},
  {municipality: "SAN ONOFRE", code: "70713"},
  {municipality: "SANTIAGO DE TOLU", code: "70820"},
  {municipality: "TOLU VIEJO", code: "70823"},
  {municipality: "BUENAVISTA", code: "70110"},
  {municipality: "COROZAL", code: "70215"},
  {municipality: "EL ROBLE", code: "70233"},
  {municipality: "GALERAS", code: "70235"},
  {municipality: "LOS PALMITOS", code: "70418"},
  {municipality: "SAMPUES", code: "70670"},
  {municipality: "SAN JUAN BETULIA", code: "70702"},
  {municipality: "SAN PEDRO", code: "70717"},
  {municipality: "SINCE", code: "70742"},
  {municipality: "CAIMITO", code: "70124"},
  {municipality: "LA UNION", code: "70400"},
  {municipality: "SAN BENITO ABAD", code: "70678"},
  {municipality: "SAN MARCOS", code: "70708"},
  {municipality: "AMBALEMA", code: "73030"},
  {municipality: "ARMERO", code: "73055"},
  {municipality: "FALAN", code: "73270"},
  {municipality: "FRESNO", code: "73283"},
  {municipality: "HONDA", code: "73349"},
  {municipality: "MARIQUITA", code: "73443"},
  {municipality: "PALOCABILDO", code: "73520"},
  {municipality: "CARMEN DE APICALA", code: "73148"},
  {municipality: "CUNDAY", code: "73226"},
  {municipality: "ICONONZO", code: "73352"},
  {municipality: "MELGAR", code: "73449"},
  {municipality: "VILLARRICA", code: "73873"},
  {municipality: "ATACO", code: "73067"},
  {municipality: "CHAPARRAL", code: "73168"},
  {municipality: "COYAIMA", code: "73217"},
  {municipality: "NATAGAIMA", code: "73483"},
  {municipality: "ORTEGA", code: "73504"},
  {municipality: "PLANADAS", code: "73555"},
  {municipality: "RIOBLANCO", code: "73616"},
  {municipality: "RONCESVALLES", code: "73622"},
  {municipality: "SAN ANTONIO", code: "73675"},
  {municipality: "ALVARADO", code: "73026"},
  {municipality: "ANZOATEGUI", code: "73043"},
  {municipality: "CAJAMARCA", code: "73124"},
  {municipality: "COELLO", code: "73200"},
  {municipality: "ESPINAL", code: "73268"},
  {municipality: "FLANDES", code: "73275"},
  {municipality: "IBAGUe", code: "73001"},
  {municipality: "PIEDRAS", code: "73547"},
  {municipality: "ROVIRA", code: "73624"},
  {municipality: "SAN LUIS", code: "73678"},
  {municipality: "VALLE DE SAN JUAN", code: "73854"},
  {municipality: "ALPUJARRA", code: "73024"},
  {municipality: "DOLORES", code: "73236"},
  {municipality: "GUAMO", code: "73319"},
  {municipality: "PRADO", code: "73563"},
  {municipality: "PURIFICACION", code: "73585"},
  {municipality: "SALDANA", code: "73671"},
  {municipality: "SUAREZ", code: "73770"},
  {municipality: "CASABIANCA", code: "73152"},
  {municipality: "HERVEO", code: "73347"},
  {municipality: "LeRIDA", code: "73408"},
  {municipality: "LiBANO", code: "73411"},
  {municipality: "MURILLO", code: "73461"},
  {municipality: "SANTA ISABEL", code: "73686"},
  {municipality: "VENADILLO", code: "73861"},
  {municipality: "VILLAHERMOSA", code: "73870"},
  {municipality: "ANDALUCIA", code: "76036"},
  {municipality: "BUGA", code: "76111"},
  {municipality: "BUGALAGRANDE", code: "76113"},
  {municipality: "CALIMA", code: "76126"},
  {municipality: "EL CERRITO", code: "76248"},
  {municipality: "GINEBRA", code: "76306"},
  {municipality: "GUACARI", code: "76318"},
  {municipality: "RESTREPO", code: "76606"},
  {municipality: "RIOFRIO", code: "76616"},
  {municipality: "SAN PEDRO", code: "76670"},
  {municipality: "TRUJILLO", code: "76828"},
  {municipality: "TULUA", code: "76834"},
  {municipality: "YOTOCO", code: "76890"},
  {municipality: "ALCALa", code: "76020"},
  {municipality: "ANSERMANUEVO", code: "76041"},
  {municipality: "ARGELIA", code: "76054"},
  {municipality: "BOLIVAR", code: "76100"},
  {municipality: "CARTAGO", code: "76147"},
  {municipality: "EL AGUILA", code: "76243"},
  {municipality: "EL CAIRO", code: "76246"},
  {municipality: "EL DOVIO", code: "76250"},
  {municipality: "LA UNION", code: "76400"},
  {municipality: "LA VICTORIA", code: "76403"},
  {municipality: "OBANDO", code: "76497"},
  {municipality: "ROLDANILLO", code: "76622"},
  {municipality: "TORO", code: "76823"},
  {municipality: "ULLOA", code: "76845"},
  {municipality: "VERSALLES", code: "76863"},
  {municipality: "ZARZAL", code: "76895"},
  {municipality: "BUENAVENTURA", code: "76109"},
  {municipality: "CAICEDONIA", code: "76122"},
  {municipality: "SEVILLA", code: "76736"},
  {municipality: "CALI", code: "76001"},
  {municipality: "CANDELARIA", code: "76130"},
  {municipality: "DAGUA", code: "76233"},
  {municipality: "FLORIDA", code: "76275"},
  {municipality: "JAMUNDI", code: "76364"},
  {municipality: "LA CUMBRE", code: "76377"},
  {municipality: "PALMIRA", code: "76520"},
  {municipality: "PRADERA", code: "76563"},
  {municipality: "VIJES", code: "76869"},
  {municipality: "YUMBO", code: "76892"},
  {municipality: "CARURU", code: "97161"},
  {municipality: "MITU", code: "97001"},
  {municipality: "PACOA", code: "97511"},
  {municipality: "PAPUNAHUA", code: "97777"},
  {municipality: "TARAIRA", code: "97666"},
  {municipality: "YAVARATE", code: "97889"},
  {municipality: "CUMARIBO", code: "99773"},
  {municipality: "LA PRIMAVERA", code: "99524"},
  {municipality: "PUERTO CARRENO", code: "99001"},
  {municipality: "SANTA ROSALIA", code: "99624"},

]

const ukLocations = [
{Territory: "England", County: " Bristol", Name: "Bristol", Type: "City"},
{Territory:"England", County:" Cambridgeshire", Name:"Cambridge", Type:"City"},
{Territory:"England", County:" Cambridgeshire", Name:"Ely", Type:"City"},
{Territory:"England", County:" Cambridgeshire", Name:"Peterborough", Type:"City"},
{Territory:"England", County:" Cheshire", Name:"Chester", Type:"City"},
{Territory:"England", County:" Cornwall", Name:"Truro", Type:"City"},
{Territory:"England", County:" County Durham", Name:"Durham", Type:"City"},
{Territory:"England", County:" Cumbria", Name:"Carlisle", Type:"City"},
{Territory:"England", County:" Derbyshire", Name:"Derby", Type:"City"},
{Territory:"England", County:" Devon", Name:"Exeter", Type:"City"},
{Territory:"England", County:" Devon", Name:"Plymouth", Type:"City"},
{Territory:"England", County:" East Riding of Yorkshire", Name:"Kingston-upon-Hull", Type:"City"},
{Territory:"England", County:" East Sussex", Name:"Brighton & Hove", Type:"City"},
{Territory:"England", County:" Essex", Name:"Chelmsford", Type:"City"},
{Territory:"England", County:" Essex", Name:"Colchester", Type:"City"},
{Territory:"England", County:" Essex", Name:"Southend-on-Sea", Type:"City"},
{Territory:"England", County:" Gloucestershire", Name:"Gloucester", Type:"City"},
{Territory:"England", County:" Greater London", Name:"London", Type:"City"},
{Territory:"England", County:" Greater London", Name:"Westminster", Type:"City"},
{Territory:"England", County:" Greater Manchester", Name:"Manchester", Type:"City"},
{Territory:"England", County:" Greater Manchester", Name:"Salford", Type:"City"},
{Territory:"England", County:" Hampshire", Name:"Portsmouth", Type:"City"},
{Territory:"England", County:" Hampshire", Name:"Southampton", Type:"City"},
{Territory:"England", County:" Hampshire", Name:"Winchester", Type:"City"},
{Territory:"England", County:" Herefordshire", Name:"Hereford", Type:"City"},
{Territory:"England", County:" Hertfordshire", Name:"St Albans", Type:"City"},
{Territory:"England", County:" Kent", Name:"Canterbury", Type:"City"},
{Territory:"England", County:" Lancashire", Name:"Lancaster", Type:"City"},
{Territory:"England", County:" Leicestershire", Name:"Leicester", Type:"City"},
{Territory:"England", County:" Lincolnshire", Name:"Lincoln", Type:"City"},
{Territory:"England", County:" Merseyside", Name:"Liverpool", Type:"City"},
{Territory:"England", County:" Norfolk", Name:"Norwich", Type:"City"},
{Territory:"England", County:" North Yorkshire", Name:"Ripon", Type:"City"},
{Territory:"England", County:" North Yorkshire", Name:"York", Type:"City"},
{Territory:"England", County:" Nottinghamshire", Name:"Nottingham", Type:"City"},
{Territory:"England", County:" Oxfordshire", Name:"Oxford", Type:"City"},
{Territory:"England", County:" Somerset", Name:"Wells", Type:"City"},
{Territory:"England", County:" South Yorkshire", Name:"Doncaster", Type:"City"},
{Territory:"England", County:" South Yorkshire", Name:"Sheffield", Type:"City"},
{Territory:"England", County:" Staffordshire", Name:"Lichfield", Type:"City"},
{Territory:"England", County:" Staffordshire", Name:"Stoke on Trent", Type:"City"},
{Territory:"England", County:" Tyne and Wear", Name:"Newcastle-upon-Tyne", Type:"City"},
{Territory:"England", County:" Tyne and Wear", Name:"Sunderland", Type:"City"},
{Territory:"England", County:" West Midlands", Name:"Birmingham", Type:"City"},
{Territory:"England", County:" West Midlands", Name:"Coventry", Type:"City"},
{Territory:"England", County:" West Midlands", Name:"Wolverhampton", Type:"City"},
{Territory:"England", County:" West Sussex", Name:"Chichester", Type:"City"},
{Territory:"England", County:" West Yorkshire", Name:"Bradford", Type:"City"},
{Territory:"England", County:" West Yorkshire", Name:"Leeds", Type:"City"},
{Territory:"England", County:" West Yorkshire", Name:"Wakefield", Type:"City"},
{Territory:"England", County:" Wiltshire", Name:"Salisbury", Type:"City"},
{Territory:"England", County:" Worcestershire", Name:"Worcester", Type:"City"},
{Territory:"England", County:"Buckinghamshire", Name:"Milton Keynes", Type:"City"},
{Territory:"England", County:"Lancashire", Name:"Preston", Type:"City"},
{Territory:"England", County:"Somerset", Name:"Bath", Type:"City"},
{Territory:"England", County:" Cumbria", Name:"Ulverston", Type:"Town"},
{Territory:"England", County:" East Sussex", Name:"Uckfield", Type:"Town"},
{Territory:"England", County:" Greater London", Name:"Uxbridge", Type:"Town"},
{Territory:"England", County:" Rutland", Name:"Uppingham", Type:"Town"},
{Territory:"England", County:" Staffordshire", Name:"Uttoxeter", Type:"Town"},
{Territory:"England", County:" Worcestershire", Name:"Upton-upon-Severn", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Ampthill", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Arlesey", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Bedford", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Biggleswade", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Dunstable", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Flitwick", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Houghton Regis", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Kempston", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Leighton-Linslade", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Luton", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Potton", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Sandy", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Shefford", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Stotfold", Type:"Town"},
{Territory:"England", County:"Bedfordshire", Name:"Woburn", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Bracknell", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Earley", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Eton", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Hungerford", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Maidenhead", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Newbury", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Reading", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Sandhurst", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Slough", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Thatcham", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Windsor", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Wokingham", Type:"Town"},
{Territory:"England", County:"Berkshire", Name:"Woodley", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Amersham", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Aylesbury", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Beaconsfield", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Bletchley and Fenny Stratford", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Buckingham", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Chesham", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"High Wycombe", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Marlow", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Newport Pagnell", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Olney", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Princes Risborough", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Stony Stratford", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Wendover", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Winslow", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Woburn Sands", Type:"Town"},
{Territory:"England", County:"Buckinghamshire", Name:"Wolverton and Greenleys", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"Chatteris", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"Godmanchester", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"Huntingdon", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"March", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"Ramsey", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"St Ives", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"St Neots", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"Soham", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"Whittlesey", Type:"Town"},
{Territory:"England", County:"Cambridgeshire", Name:"Wisbech", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Alsager", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Birchwood", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Bollington", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Congleton", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Crewe", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Ellesmere Port", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Frodsham", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Knutsford", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Macclesfield", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Middlewich", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Nantwich", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Neston", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Northwich", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Poynton-with-Worth", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Runcorn", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Sandbach", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Warrington", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Widnes", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Wilmslow", Type:"Town"},
{Territory:"England", County:"Cheshire", Name:"Winsford", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Bodmin", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Bude-Stratton", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Callington", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Camborne", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Camelford", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Falmouth", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Fowey", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Hayle", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Helston", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Launceston", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Liskeard", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Looe", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Lostwithiel", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Marazion", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Newlyn", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Newquay", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Padstow", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Penryn", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Penzance", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Porthleven", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Redruth", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"St Austell", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"St Blaise", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"St Columb Major", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"St Ives", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"St Just-in-Penwith", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"St Mawes", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Saltash", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Torpoint", Type:"Town"},
{Territory:"England", County:"Cornwall", Name:"Wadebridge", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Alston", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Ambleside", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Appleby-in-Westmorland", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Barrow-in-Furness", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Brampton", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Broughton-in-Furness", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Cleator Moor", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Cockermouth", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Dalton-in-Furness", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Egremont", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Grange-over-Sands", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Kendal", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Keswick", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Kirkby Lonsdale", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Kirkby Stephen", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Longtown", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Maryport", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Millom", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Penrith", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Sedbergh", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Silloth", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Whitehaven", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Wigton", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Windermere", Type:"Town"},
{Territory:"England", County:"Cumbria", Name:"Workington", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Alfreton", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Ashbourne", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Bakewell", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Belper", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Bolsover", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Buxton", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Chapel-en-le-Frith", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Chesterfield", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Clay Cross", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Darley Dale", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Dronfield", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Glossop", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Heanor and Loscoe", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Ilkeston", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Matlock", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"New Mills", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Ripley", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Sandiacre", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Shirebrook", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Staveley", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Whaley Bridge", Type:"Town"},
{Territory:"England", County:"Derbyshire", Name:"Wirksworth", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Ashburton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Axminster", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Bampton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Barnstaple", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Bideford", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Bovey Tracey", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Bradninch", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Brixham", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Buckfastleigh", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Budleigh Salterton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Chagford", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Chudleigh", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Chulmleigh", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Colyton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Crediton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Cullompton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Dartmouth", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Dawlish", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Exmouth", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Great Torrington", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Hartland", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Hatherleigh", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Holsworthy", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Honiton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Ilfracombe", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Ivybridge", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Kingsbridge", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Kingsteignton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Lynton & Lynmouth", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Modbury", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Moretonhampstead", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Newton Abbot", Type:"Town"},
{Territory:"England", County:"Devon", Name:"North Tawton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Northam", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Okehampton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Ottery St Mary", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Paignton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Plympton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Salcombe", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Seaton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Sidmouth", Type:"Town"},
{Territory:"England", County:"Devon", Name:"South Brent", Type:"Town"},
{Territory:"England", County:"Devon", Name:"South Molton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Tavistock", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Teignmouth", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Tiverton", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Topsham", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Torquay", Type:"Town"},
{Territory:"England", County:"Devon", Name:"Totnes", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Beaminster", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Blandford Forum", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Bournemouth", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Bridport", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Chickerell", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Christchurch", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Dorchester", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Ferndown", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Gillingham", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Lyme Regis", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Lytchett Minster & Upton", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Poole", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Portland", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Shaftesbury", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Sherborne", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Stalbridge", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Sturminster Newton", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Swanage", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Verwood", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Wareham", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Weymouth", Type:"Town"},
{Territory:"England", County:"Dorset", Name:"Wimborne Minster", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Barnard Castle", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Billingham", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Bishop Auckland", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Chester-le-Street", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Chilton", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Crook", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Darlington", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Ferryhill", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Greater Willington", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Hartlepool", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Newton Aycliffe", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Peterlee", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Seaham", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Sedgefield", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Shildon", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Spennymoor", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Stanley", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Stanhope", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Tow Law", Type:"Town"},
{Territory:"England", County:"Durham", Name:"Wolsingham", Type:"Town"},
{Territory:"England", County:"Durham/North Yorkshire", Name:"Stockton-on-Tees", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Beverley", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Bridlington", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Brough", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Driffield", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Goole", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Hedon", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Hessle", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Hornsea", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Howden", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Market Weighton", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Pocklington", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Snaith and Cowick", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"South Cave", Type:"Town"},
{Territory:"England", County:"East Riding of Yorkshire", Name:"Withernsea", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Battle", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Bexhill-on-Sea", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Crowborough", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Eastbourne", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Hailsham", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Hastings", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Heathfield", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Lewes", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Newhaven", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Peacehaven", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Polegate", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Rye", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Seaford", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Telscombe", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Wadhurst", Type:"Town"},
{Territory:"England", County:"East Sussex", Name:"Winchelsea", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Basildon", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Billericay", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Braintree", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Brentwood", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Brightlingsea", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Burnham-on-Crouch", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Canvey Island", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Coggeshall", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Corringham", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Dovercourt", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Epping", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Frinton and Walton", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Grays", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Great Dunmow", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Halstead", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Harlow", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Harwich", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Leigh-on-Sea", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Loughton", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Maldon", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Manningtree", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Ongar", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Rayleigh", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Rochford", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Saffron Walden", Type:"Town"},
{Territory:"England", County:"Essex", Name:"South Woodham Ferrers", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Southminster", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Thaxted", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Waltham Abbey", Type:"Town"},
{Territory:"England", County:"Essex", Name:"West Mersea", Type:"Town"},
{Territory:"England", County:"Essex", Name:"West Tilbury", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Witham", Type:"Town"},
{Territory:"England", County:"Essex", Name:"Wivenhoe", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Berkeley", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Bradley Stoke", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Cheltenham", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Chipping Campden", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Chipping Sodbury", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Cinderford", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Cirencester", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Coleford", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Dursley", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Fairford", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Filton", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Lechlade", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Lydney", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Minchinhampton", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Mitcheldean", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Moreton-in-Marsh", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Nailsworth", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Newent", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Northleach with Eastington", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Painswick", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Patchway", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Quedgeley", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Stonehouse", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Stow-on-the-Wold", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Stroud", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Tetbury", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Tewkesbury", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Thornbury", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Wickwar", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Winchcombe", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Wotton-under-Edge", Type:"Town"},
{Territory:"England", County:"Gloucestershire", Name:"Yate", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Acton", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Barking", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Barnes", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Barnet", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Beckenham", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Bexley", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Brentford", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Bromley", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Carshalton", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Chingford", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Chiswick", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Coulsdon", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Croydon", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Dagenham", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Ealing", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"East Ham", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Edgware", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Edmonton", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Enfield", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Erith", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Finchley", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Harrow", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Hendon", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Hornsey", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Hounslow", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Ilford", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Isleworth", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Kingston-upon-Thames", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Leyton", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Mitcham", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"New Malden", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Orpington", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Purley", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Richmond", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Romford", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"St Mary Cray", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Southall", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Southgate", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Surbiton", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Sutton", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Tottenham", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Twickenham", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Wallington", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Walthamstow", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Wembley", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"West Ham", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Willesden", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Wimbledon", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Wood Green", Type:"Town"},
{Territory:"England", County:"Greater London", Name:"Woodford", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Altrincham", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Ashton-under-Lyne", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Blackrod", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Bolton", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Bury", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Dukinfield", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Eccles", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Heywood", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Horwich", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Hyde", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Leigh", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Middleton", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Mossley", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Oldham", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Partington", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Rochdale", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Sale", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Stalybridge", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Stockport", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Stretford", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Westhoughton", Type:"Town"},
{Territory:"England", County:"Greater Manchester", Name:"Wigan", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Aldershot", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Alton", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Andover", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Basingstoke", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Bishop's Waltham", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Blackwater and Hawley", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Bordon", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Cosham", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Eastleigh", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Emsworth", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Fareham", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Farnborough", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Fleet", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Fordingbridge", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Gosport", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Havant", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Hedge End", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Lymington", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"New Alresford", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"New Milton", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Petersfield", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Ringwood", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Romsey", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Southsea", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Southwick", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Tadley", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Totton and Eling", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Whitchurch", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Whitehill", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Wickham", Type:"Town"},
{Territory:"England", County:"Hampshire", Name:"Yateley", Type:"Town"},
{Territory:"England", County:"Herefordshire", Name:"Bromyard", Type:"Town"},
{Territory:"England", County:"Herefordshire", Name:"Kington", Type:"Town"},
{Territory:"England", County:"Herefordshire", Name:"Ledbury", Type:"Town"},
{Territory:"England", County:"Herefordshire", Name:"Leominster", Type:"Town"},
{Territory:"England", County:"Herefordshire", Name:"Ross-on-Wye", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Berkhamsted", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Bishop's Stortford", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Buntingford", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Bushey", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Cheshunt", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Chorleywood", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Elstree and Borehamwood", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Harpenden", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Hatfield", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Hemel Hempstead", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Hertford", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Hitchin", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Hoddesdon", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Letchworth Garden City", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Royston", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Sawbridgeworth", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Stevenage", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Tring", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Waltham Cross", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Ware", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Watford", Type:"Town"},
{Territory:"England", County:"Hertfordshire", Name:"Welwyn Garden City", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Brading", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Cowes", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"East Cowes", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Newport", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Ryde", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Sandown", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Shanklin", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Ventnor", Type:"Town"},
{Territory:"England", County:"Isle of Wight", Name:"Yarmouth", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Ashford", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Broadstairs and St Peter's", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Chatham", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Cranbrook", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Dartford", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Deal", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Dover", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Edenbridge", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Faversham", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Folkestone", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Fordwich", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Gillingham", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Gravesend", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Hawkinge", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Hythe", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Lydd", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Maidstone", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Margate", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Minster", Type:"Town"},
{Territory:"England", County:"Kent", Name:"New Romney", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Northfleet", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Paddock Wood", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Queenborough-in-Sheppey", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Ramsgate", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Rochester", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Royal Tunbridge Wells", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Sandwich", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Sevenoaks", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Sittingbourne", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Snodland", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Southborough", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Strood", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Swanley", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Swanscombe and Greenhithe", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Tenterden", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Tonbridge", Type:"Town"},
{Territory:"England", County:"Kent", Name:"West Malling", Type:"Town"},
{Territory:"England", County:"Kent", Name:"Westerham", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Accrington", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Adlington", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Bacup", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Barnoldswick", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Blackburn", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Blackpool", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Brierfield", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Burnley", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Carnforth", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Chorley", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Clitheroe", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Colne", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Darwen", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Earby", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Fleetwood", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Garstang", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Haslingden", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Kirkham", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Leyland", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Longridge", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Lytham St Annes", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Medlar-with-Wesham", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Morecambe", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Nelson", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Ormskirk", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Padiham", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Penwortham", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Preesall", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Rawtenstall", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Skelmersdale", Type:"Town"},
{Territory:"England", County:"Lancashire", Name:"Whitworth", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Ashby-de-la-Zouch", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Braunstone Town", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Coalville", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Earl Shilton", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Hinckley", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Loughborough", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Lutterworth", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Market Bosworth", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Market Harborough", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Melton Mowbray", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Shepshed", Type:"Town"},
{Territory:"England", County:"Leicestershire", Name:"Syston", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Alford", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Barton-upon-Humber", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Boston", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Bottesford", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Bourne", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Brigg", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Broughton", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Burgh-le-Marsh", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Caistor", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Cleethorpes", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Crowland", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Crowle", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Epworth", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Gainsborough", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Grantham", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Grimsby", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Holbeach", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Horncastle", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Immingham", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Kirton-in-Lindsey", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Long Sutton", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Louth", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Mablethorpe and Sutton", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Market Deeping", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Market Rasen", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"North Hykeham", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Scunthorpe", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Skegness", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Sleaford", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Spalding", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Spilsby", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Stamford", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Wainfleet All Saints", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Winterton", Type:"Town"},
{Territory:"England", County:"Lincolnshire", Name:"Wragby", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Bebington", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Birkenhead", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Bootle", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Bromborough", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Crosby", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Halewood", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Maghull", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Newton-le-Willows", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Prescot", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"St Helens", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Southport", Type:"Town"},
{Territory:"England", County:"Merseyside", Name:"Wallasey", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Acle", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Attleborough", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Aylsham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Cromer", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Dereham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Diss", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Downham Market", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Fakenham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Gorleston-on-Sea", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Great Yarmouth", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Redenhall with Harleston", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Hingham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Holt", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Hunstanton", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"King's Lynn", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Loddon", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"North Walsham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Reepham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Sheringham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Sprowston", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Stalham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Swaffham", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Thetford", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Thorpe St Andrew", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Watton", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Wells-next-the-Sea", Type:"Town"},
{Territory:"England", County:"Norfolk", Name:"Wymondham", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Bedale", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Bentham", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Boroughbridge", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Colburn", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Easingwold", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Filey", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Grassington", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Guisborough", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Harrogate", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Haxby", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Hawes", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Helmsley", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Ingleby Barwick", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Kirkbymoorside", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Knaresborough", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Leyburn", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Loftus", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Malton", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Masham", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Middleham", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Middlesbrough", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Northallerton", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Norton-on-Derwent", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Pateley Bridge", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Pickering", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Redcar", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Richmond", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Saltburn-By-The-Sea", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Scarborough", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Selby", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Settle", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Skelton-in-Cleveland", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Skipton", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Tadcaster", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Thirsk", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Thornaby-on-Tees", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Whitby", Type:"Town"},
{Territory:"England", County:"North Yorkshire", Name:"Yarm", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Brackley", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Burton Latimer", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Corby", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Daventry", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Desborough", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Higham Ferrers", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Irthlingborough", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Kettering", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Northampton", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Oundle", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Raunds", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Rothwell", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Rushden", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Thrapston", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Towcester", Type:"Town"},
{Territory:"England", County:"Northamptonshire", Name:"Wellingborough", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Alnwick", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Amble", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Ashington", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Berwick-upon-Tweed", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Blyth", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Corbridge", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Cramlington", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Haltwhistle", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Hexham", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Morpeth", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Newbiggin-by-the-Sea", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Ponteland", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Prudhoe", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Rothbury", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"West Bedlington", Type:"Town"},
{Territory:"England", County:"Northumberland", Name:"Wooler", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Bingham", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Bulwell", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Cotgrave", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"East Retford", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Eastwood", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Harworth and Bircotes", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Kimberley", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Kirkby-in-Ashfield", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Mansfield", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Newark-on-Trent", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Ollerton and Boughton", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Southwell", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Stapleford", Type:"Town"},
{Territory:"England", County:"Nottinghamshire", Name:"Worksop", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Abingdon-on-Thames", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Banbury", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Bicester", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Burford", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Carterton", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Charlbury", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Chipping Norton", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Didcot", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Faringdon", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Henley-on-Thames", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Thame", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Wallingford", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Wantage", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Watlington", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Witney", Type:"Town"},
{Territory:"England", County:"Oxfordshire", Name:"Woodstock", Type:"Town"},
{Territory:"England", County:"Rutland", Name:"Oakham", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Bishop's Castle", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Bridgnorth", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Broseley", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Church Stretton", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Cleobury Mortimer", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Clun", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Craven Arms", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Dawley", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Ellesmere", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Ludlow", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Madeley", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Market Drayton", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Much Wenlock", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Newport", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Oakengates", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Oswestry", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Shifnal", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Shrewsbury", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Telford", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Wellington", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Wem", Type:"Town"},
{Territory:"England", County:"Shropshire", Name:"Whitchurch", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Axbridge", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Bridgwater", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Bruton", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Burnham-on-Sea", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Castle Cary", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Chard", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Clevedon", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Crewkerne", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Dulverton", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Frome", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Glastonbury", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Highbridge", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Ilminster", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Keynsham", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Langport", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Midsomer Norton", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Minehead", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Nailsea", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"North Petherton", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Portishead", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Radstock", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Shepton Mallet", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Somerton", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Taunton", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Watchet", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Wellington", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Weston-super-Mare", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Wincanton", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Wiveliscombe", Type:"Town"},
{Territory:"England", County:"Somerset", Name:"Yeovil", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Askern", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Barnsley", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Bawtry", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Brierley", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Conisbrough", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Dinnington St John's", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Hatfield", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Maltby", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Mexborough", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Penistone", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Rotherham", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Stainforth", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Stocksbridge", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Thorne", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Tickhill", Type:"Town"},
{Territory:"England", County:"South Yorkshire", Name:"Wath-upon-Dearne", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Biddulph", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Brewood", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Burntwood", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Burslem", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Burton upon Trent", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Cannock", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Cheadle", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Eccleshall", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Fazeley", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Fenton", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Hednesford", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Kidsgrove", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Leek", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Longton", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Newcastle-under-Lyme", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Penkridge", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Rugeley", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Stafford", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Stoke-upon-Trent", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Stone", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Tamworth", Type:"Town"},
{Territory:"England", County:"Staffordshire", Name:"Tunstall", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Aldeburgh", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Beccles", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Brandon", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Bungay", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Bury St Edmunds", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Carlton Colville", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Clare", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Dunwich", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Eye", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Felixstowe", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Framlingham", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Hadleigh", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Halesworth", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Haverhill", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Ipswich", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Kesgrave", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Leiston", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Lowestoft", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Mildenhall", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Needham Market", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Newmarket", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Orford", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Saxmundham", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Southwold", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Stowmarket", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Sudbury", Type:"Town"},
{Territory:"England", County:"Suffolk", Name:"Woodbridge", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Chertsey", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Dorking", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Epsom and Ewell", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Farnham", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Godalming", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Guildford", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Haslemere", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Horley", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Leatherhead", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Redhill", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Reigate", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Staines-upon-Thames", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Walton-on-Thames", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Weybridge", Type:"Town"},
{Territory:"England", County:"Surrey", Name:"Woking", Type:"Town"},
{Territory:"England", County:"Tyne and Wear", Name:"Gateshead", Type:"Town"},
{Territory:"England", County:"Tyne and Wear", Name:"Hetton", Type:"Town"},
{Territory:"England", County:"Tyne and Wear", Name:"Jarrow", Type:"Town"},
{Territory:"England", County:"Tyne and Wear", Name:"South Shields", Type:"Town"},
{Territory:"England", County:"Tyne and Wear", Name:"Tynemouth", Type:"Town"},
{Territory:"England", County:"Tyne and Wear", Name:"Wallsend", Type:"Town"},
{Territory:"England", County:"Tyne and Wear", Name:"Washington", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Alcester", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Atherstone", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Bedworth", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Coleshill", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Henley-in-Arden", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Kenilworth", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Nuneaton", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Royal Leamington Spa", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Rugby", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Shipston-on-Stour", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Southam", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Stratford-upon-Avon", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Warwick", Type:"Town"},
{Territory:"England", County:"Warwickshire", Name:"Whitnash", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Bilston", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Dudley", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Fordbridge", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Halesowen", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Oldbury", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Rowley Regis", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Smethwick", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Solihull", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Stourbridge", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Sutton Coldfield", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Tipton", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Walsall", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Wednesbury", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"West Bromwich", Type:"Town"},
{Territory:"England", County:"West Midlands", Name:"Willenhall", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Arundel", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Bognor Regis", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Burgess Hill", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Crawley", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"East Grinstead", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Haywards Heath", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Horsham", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Littlehampton", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Midhurst", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Petworth", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Selsey", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Shoreham-by-Sea", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Steyning", Type:"Town"},
{Territory:"England", County:"West Sussex", Name:"Worthing", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Batley", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Bingley", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Brighouse", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Castleford", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Cleckheaton", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Denholme", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Dewsbury", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Elland", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Featherstone", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Halifax", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Hebden Royd", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Hemsworth", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Horsforth", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Huddersfield", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Ilkley", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Keighley", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Meltham", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Mirfield", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Morley", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Normanton", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Ossett", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Otley", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Pontefract", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Pudsey", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Rothwell", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Silsden", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"South Elmsall", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"South Kirkby and Moorthorpe", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Todmorden", Type:"Town"},
{Territory:"England", County:"West Yorkshire", Name:"Wetherby", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Amesbury", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Bradford-on-Avon", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Calne", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Chippenham", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Corsham", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Cricklade", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Devizes", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Highworth", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Ludgershall", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Malmesbury", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Marlborough", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Melksham", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Mere", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Royal Wootton Bassett", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Swindon", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Tidworth", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Trowbridge", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Warminster", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Westbury", Type:"Town"},
{Territory:"England", County:"Wiltshire", Name:"Wilton", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Bewdley", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Bromsgrove", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Droitwich Spa", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Evesham", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Kidderminster", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Malvern", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Pershore", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Redditch", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Stourport-on-Severn", Type:"Town"},
{Territory:"England", County:"Worcestershire", Name:"Tenbury Wells", Type:"Town"},
{Territory:"Northern Ireland", County:" County Antrim", Name:"Belfast", Type:"City"},
{Territory:"Northern Ireland", County:" County Antrim", Name:"Lisburn", Type:"City"},
{Territory:"Northern Ireland", County:" County Armagh", Name:"Armagh", Type:"City"},
{Territory:"Northern Ireland", County:" County Armagh/County Down", Name:"Newry", Type:"City"},
{Territory:"Northern Ireland", County:" County Down", Name:"Bangor", Type:"City"},
{Territory:"Northern Ireland", County:" County Londonderry", Name:"Londonderry", Type:"City"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Aghagallon", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Aghalee", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ahoghill", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Aldergrove", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Antrim", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Armoy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Aughafatten", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballintoy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballybogy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballycarry", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballycastle", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballyclare", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballyeaston", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballygally", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballylinney", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballymena", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballymoney", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballynure", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballyrobert", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballyskeagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballystrudder", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Ballyvoy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Balnamore", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Belfast", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Broughshane", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Buckna", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Bushmills", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Capecastle", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Cargan", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Carnalbanagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Carncastle", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Carnlough", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Carrickfergus", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Clogh", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Clough", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Cloughmills", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Cogry-Kilbride", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Craigarogan", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Crumlin", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Cullybackey", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Cushendall", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Cushendun", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Dechomet", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Derrykeighan", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Dervock", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Doagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Donegore", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Drains Bay", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Dunadry", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Dundrod", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Dunloy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Dunmurry", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Dunseverick", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Finaghy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Finvoy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Gawley's Gate", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Gillygooly", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Glebe", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Glenarm", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Glenavy", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Glengormley", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Glenoe", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Glynn", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Gracehill", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Greenisland", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Groggan", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Halfpenny Gate", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Holywell", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Islandmagee", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Jordanstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Kells-Connor", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Kellswater", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Killay", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Killead", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Knocknacarry", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Larne", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Lisburn", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Loughguile", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Lower Ballinderry", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Lurganville", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Maghaberry", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Magheramorne", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Martinstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Mazetown", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Milltown (Co. Antrim)", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Moneyglass", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Monteith", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Moss-Side", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Mallusk", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Newtown Crommelin", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Newtownabbey", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Parkgate", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Portballintrae", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Portbraddon", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Portglenone", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Portrush", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Raloo", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Randalstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Rasharkin", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Roughfort", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Stoneyford", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Straid", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Stranocum", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Templepatrick", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Toome", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Tullynacross", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Upper Ballinderry", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Waterfoot", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Whitehead", Type:"Town"},
{Territory:"Northern Ireland", County:" Antrim", Name:"Whitehouse (Antrim)", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Acton", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Aghacommon", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Annaghmore", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Annahugh", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Armagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Ballymacnab", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Bannfoot", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Belleeks", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Bessbrook", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Blackwatertown", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Camlough", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Charlemont", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Cladymore", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Clonmore", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Collegeland", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Corrinshego", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Craigavon", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Creggan", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Crossmaglen", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Cullaville", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Cullyhanna", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Darkley", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Derryhale", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Derrymacash", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Derrymore", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Derrynoose", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Derrytrasna", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Dollingstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Donaghmore", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Dorsey", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Dromintee", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Edenaveys", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Eglish", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Forkill", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Gibson's Hill", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Glencull", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Hamiltonsbawn", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Jonesborough", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Keady", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Killeen", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Killylea", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Kilmore (Co. Armagh)", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Laurelvale", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Lislea", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Lisnadill", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Loughgall", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Loughgilly", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Lurgan", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Lurganare", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Lurganure", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Macken", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Madden", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Magheraconluce", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Maghery", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Markethill", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Meigh", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Middletown", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Milford", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Mountnorris", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Mullaghbawn", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Mullaghbrack", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Newtownhamilton", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Portadown", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Richhill", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Silverbridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Tamnamore", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Tandragee", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Tartaraghan", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"The Birches", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Tynan", Type:"Town"},
{Territory:"Northern Ireland", County:" Armagh", Name:"Whitecross", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Annaclone", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Annahilt", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Annalong", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Annsborough", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ardglass", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Atticall", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballela", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Balloo", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballygowan", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballyhalbert", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballyhornan", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballykinler", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballylesson", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballymacmaine", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballymartin", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballynahinch", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ballywalter", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Banbridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Bangor", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Blackskull", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Bleary", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Brockagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Bryansford", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Burren", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Carrowdore", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Carryduff", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Castlewellan", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Cloghy", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Comber", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Conlig", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Crawfordsburn", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Crossgar", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Donaghadee", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Donaghcloney", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Downpatrick", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Dromara", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Dromore (Co. Down)", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Drumaness", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Drumbeg", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Drumbo", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Drumlin (near Hillsborough)", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Drumlin (near Rathfriland)", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Drumnacanvy", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Dundonald", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Dundrum", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Dunnaval", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Edenderry (Co. Down)", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Gamblestown", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Gilford", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Greyabbey", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Groomsport", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Helen's Bay", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Hillhall", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Hillsborough", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Hilltown", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Jerrettspass", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Katesbridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Kilcoo", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Kilkeel", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Killinchy", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Killough", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Killowen", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Killyleagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Kilmore (Co. Down)", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Kinallen", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Kircubbin", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Lambeg", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Lawrencetown", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Leitrim", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Listooder", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Loughbrickland", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Loughinisland", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Magheralin", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Mayobridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Mill Bay", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Millisle", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Moira", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Moneyreagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Moneyslane", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Mullaghglass", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Newcastle", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Newry", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Newtownards", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Newtowncloghoge", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Portaferry", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Portavogie", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Poyntzpass", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Rathfriland", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Ravernet", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Rock", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Rostrevor", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Saintfield", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Scarva", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Seaforde", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Spa", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Springfield", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Strangford", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Tullylish", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Waringsford", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Waringstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Down", Name:"Warrenpoint", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Aghadrumsee", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Arney", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Ballinamallard", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Ballycassidy", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Belcoo", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Bellanaleck", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Belleek", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Blaney", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Boho", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Brookeborough", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Carrybridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Clabby", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Cranagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Derrygonnelly", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Derrylin", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Donagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Drumraighland", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Ederney", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Enniskillen", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Florencecourt", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Garrison", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Garvetagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Irvinestown", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Kesh", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Keshbridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Killadeas", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Kinawley", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Lack", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Letterbreen", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Lisbellaw", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Lisnarick", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Lisnaskea", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Magheraveely", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Maguiresbridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Monea", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Newtownbutler", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Rosslea", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Skea", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Spamount", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Tamlaght (Co. Fermanagh)", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Teemore", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Tempo", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Trory", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Tullyhommon", Type:"Town"},
{Territory:"Northern Ireland", County:" Fermanagh", Name:"Wattlebridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Aghadowey", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ardgarvan", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ardmore", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Articlave", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Artikelly", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ballerin", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ballykelly", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ballymaguigan", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ballyrashane", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ballyronan", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Ballyscullion", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Banagher", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Bellaghy", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Bellarena", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Bendooragh", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Broomhill", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Burnfoot", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Campsie", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Carrickaness", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Castledawson", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Castlerock", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Clady (Co. Londonderry)", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Claudy", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Coleraine", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Corbet", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Corkey", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Culmore", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Culnady", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Curran", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Derry", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Desertmartin", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Downhill", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Draperstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Drumsurn", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Dungiven", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Eglinton", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Feeny", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Foreglen", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Garvagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Glack", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Glenone", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Gortaclare", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Goshedan", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Greysteel", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Gulladuff", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Inishrush", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Killaloo", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Killywool", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Kilrea", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Knockcloghrim", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Largy", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Lettershendoney", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Limavady", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Loup", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Macosquin", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Maghera", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Magherafelt", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Magheramason", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Maydown", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Millbank", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Moneymore", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Moneyneany", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Mounthill", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Mullaghboy", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Newbuildings", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Park", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Portstewart", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Shanmaghery", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Shanvey", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Sheeptown", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Straidarran", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Strathfoyle", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Straw", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Swatragh", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Tamlaght (Co. Londonderry)", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Tobermore", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Upperlands", Type:"Town"},
{Territory:"Northern Ireland", County:" Londonderry", Name:"Whiterock", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Altamuskin", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Altishane", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Altmore", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Ardboe", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Ardstraw", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Artigarvan", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Augher", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Aughnacloy", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Ballygawley", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Ballymagorry", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Ballyrory", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Benburb", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Beragh", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Brackaville", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Bready", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Caledon", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Cappagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Carnteel", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Carrickmore", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Carrowclare", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Castlecaulfield", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Castlederg", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Clady (Co. Tyrone)", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Clanabogan", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Clogher", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Clonoe", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Coagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Coalisland", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Cookstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Creagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Derrycrin", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Derrynaflaw", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Derrytresk", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Derryvore", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Donaghey", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Dooish", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Douglas Bridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Dromore (Co. Tyrone)", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Drumlaghy", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Drummullan", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Drumnakilly", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Drumquin", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Dungannon", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Dunnamanagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Dunnamore", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Edenderry (Co. Tyrone)", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Erganagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Fintona", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Fivemiletown", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Galbally", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Garvaghey", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Glenmornan", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Gortin", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Gortnahey", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Grange Corner", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Granville", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Greencastle", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Kildress", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Killen", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Killeter", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Killyman", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Kilskeery", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Knockmoyle", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Landahaussy", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Loughmacrory", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Moortown", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Mountfield", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Mountjoy", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Moy", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Moygashel", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Newtownstewart", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Nixon's Corner", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Newmills", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Omagh", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Orritor", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Plumbridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Pomeroy", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Ringsend", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Rousky", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Sandholes", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Scotch Street", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Seskinore", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Shrigley", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Sion Mills", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Sixmilecross", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Stewartstown", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Strabane", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Trillick", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Tullyhogue", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Tullywiggan", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Victoria Bridge", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Washing Bay", Type:"Town"},
{Territory:"Northern Ireland", County:" Tyrone", Name:"Whitehouse (Tyrone)", Type:"Town"},
{Territory:"Scotland", County:"Aberdeen", Name:" Aberdeen City", Type:" City"},
{Territory:"Scotland", County:"Dundee", Name:" Dundee City", Type:" City"},
{Territory:"Scotland", County:"Dunfermline", Name:" Fife", Type:" City"},
{Territory:"Scotland", County:"Edinburgh", Name:" City of Edinburgh", Type:" City"},
{Territory:"Scotland", County:"Glasgow", Name:" Glasgow City", Type:" City"},
{Territory:"Scotland", County:"Inverness", Name:" Highland", Type:" City"},
{Territory:"Scotland", County:"Perth", Name:" Perth and Kinross", Type:" City"},
{Territory:"Scotland", County:"Stirling", Name:" Stirling", Type:" City"},
{Territory:"Scotland", County:"Airdrie", Name:" North Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Arbroath", Name:" Angus", Type:" Town"},
{Territory:"Scotland", County:"Ayr", Name:" South Ayrshire", Type:" Town"},
{Territory:"Scotland", County:"Barrhead", Name:" East Renfrewshire", Type:" Town"},
{Territory:"Scotland", County:"Bathgate", Name:" West Lothian", Type:" Town"},
{Territory:"Scotland", County:"Bearsden", Name:" East Dunbartonshire", Type:" Town"},
{Territory:"Scotland", County:"Bellshill", Name:" North Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Bishopbriggs", Name:" East Dunbartonshire", Type:" Town"},
{Territory:"Scotland", County:"Blantyre", Name:" South Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Bonnyrigg", Name:" Midlothian", Type:" Town"},
{Territory:"Scotland", County:"Broxburn", Name:" West Lothian", Type:" Town"},
{Territory:"Scotland", County:"Cambuslang", Name:" South Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Clydebank", Name:" West Dunbartonshire", Type:" Town"},
{Territory:"Scotland", County:"Coatbridge", Name:" North Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Cumbernauld", Name:" North Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Dumbarton", Name:" West Dunbartonshire", Type:" Town"},
{Territory:"Scotland", County:"Dumfries", Name:" Dumfries and Galloway", Type:" Town"},
{Territory:"Scotland", County:"East Kilbride", Name:" South Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Elgin", Name:" Moray", Type:" Town"},
{Territory:"Scotland", County:"Erskine", Name:" Renfrewshire", Type:" Town"},
{Territory:"Scotland", County:"Falkirk", Name:" Falkirk", Type:" Town"},
{Territory:"Scotland", County:"Glenrothes", Name:" Fife", Type:" Town"},
{Territory:"Scotland", County:"Grangemouth", Name:" Falkirk", Type:" Town"},
{Territory:"Scotland", County:"Greenock", Name:" Inverclyde", Type:" Town"},
{Territory:"Scotland", County:"Hamilton", Name:" South Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Irvine", Name:" North Ayrshire", Type:" Town"},
{Territory:"Scotland", County:"Johnstone", Name:" Renfrewshire", Type:" Town"},
{Territory:"Scotland", County:"Kilmarnock", Name:" East Ayrshire", Type:" Town"},
{Territory:"Scotland", County:"Kilwinning", Name:" North Ayrshire", Type:" Town"},
{Territory:"Scotland", County:"Kirkcaldy and Dysart", Name:" Fife", Type:" Town"},
{Territory:"Scotland", County:"Kirkintilloch", Name:" East Dunbartonshire", Type:" Town"},
{Territory:"Scotland", County:"Larkhall", Name:" South Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Livingston", Name:" West Lothian", Type:" Town"},
{Territory:"Scotland", County:"Motherwell", Name:" North Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Musselburgh", Name:" East Lothian", Type:" Town"},
{Territory:"Scotland", County:"Newton Mearns", Name:" East Renfrewshire", Type:" Town"},
{Territory:"Scotland", County:"Paisley", Name:" Renfrewshire", Type:" Town"},
{Territory:"Scotland", County:"Penicuik", Name:" Midlothian", Type:" Town"},
{Territory:"Scotland", County:"Peterhead", Name:" Aberdeenshire", Type:" Town"},
{Territory:"Scotland", County:"Renfrew", Name:" Renfrewshire", Type:" Town"},
{Territory:"Scotland", County:"Rutherglen", Name:" South Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"St Andrews", Name:" Fife", Type:" Town"},
{Territory:"Scotland", County:"Viewpark", Name:" North Lanarkshire", Type:" Town"},
{Territory:"Scotland", County:"Wishaw", Name:" North Lanarkshire", Type:" Town"},
{Territory:"Wales", County:" Cardiff", Name:"Cardiff", Type:"City"},
{Territory:"Wales", County:" Denbighshire", Name:"St Asaph", Type:"City"},
{Territory:"Wales", County:" Gwynedd", Name:"Bangor", Type:"City"},
{Territory:"Wales", County:" Newport", Name:"Newport", Type:"City"},
{Territory:"Wales", County:" Pembrokeshire", Name:"St Davids", Type:"City"},
{Territory:"Wales", County:" Swansea", Name:"Swansea", Type:"City"},
{Territory:"Wales", County:" Wrexham", Name:"Wrexham", Type:"City"},
{Territory:"Wales", County:" Anglesey", Name:"Amlwch", Type:"Town"},
{Territory:"Wales", County:" Anglesey", Name:"Beaumaris", Type:"Town"},
{Territory:"Wales", County:" Anglesey", Name:"Holyhead", Type:"Town"},
{Territory:"Wales", County:" Anglesey", Name:"Llangefni", Type:"Town"},
{Territory:"Wales", County:" Anglesey", Name:"Menai Bridge", Type:"Town"},
{Territory:"Wales", County:" Blaenau Gwent", Name:"Abertillery", Type:"Town"},
{Territory:"Wales", County:" Blaenau Gwent", Name:"Blaina", Type:"Town"},
{Territory:"Wales", County:" Blaenau Gwent", Name:"Brynmawr", Type:"Town"},
{Territory:"Wales", County:" Blaenau Gwent", Name:"Ebbw Vale", Type:"Town"},
{Territory:"Wales", County:" Blaenau Gwent", Name:"Tredegar", Type:"Town"},
{Territory:"Wales", County:" Bridgend", Name:"Bridgend", Type:"Town"},
{Territory:"Wales", County:" Bridgend", Name:"Maesteg", Type:"Town"},
{Territory:"Wales", County:" Bridgend", Name:"Pencoed", Type:"Town"},
{Territory:"Wales", County:" Bridgend", Name:"Porthcawl", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Aberbargoed", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Abercarn", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Bargoed", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Bedwas", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Blackwood", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Caerphilly", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Crumlin", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Gelligaer", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Newbridge", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Rhymney", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Risca", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Senghenydd", Type:"Town"},
{Territory:"Wales", County:" Caerphilly", Name:"Ystrad Mynach", Type:"Town"},
{Territory:"Wales", County:" Cardiff", Name:"Cardiff", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Ammanford", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Burry Port", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Carmarthen", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Kidwelly", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Laugharne", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Llandeilo", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Llandovery", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Llanelli", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Llanybydder", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Newcastle Emlyn", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"St Clears", Type:"Town"},
{Territory:"Wales", County:" Carmarthenshire", Name:"Whitland", Type:"Town"},
{Territory:"Wales", County:" Ceredigion", Name:"Aberystwyth", Type:"Town"},
{Territory:"Wales", County:" Ceredigion", Name:"Cardigan", Type:"Town"},
{Territory:"Wales", County:" Ceredigion", Name:"Lampeter", Type:"Town"},
{Territory:"Wales", County:" Ceredigion", Name:"Llandysul", Type:"Town"},
{Territory:"Wales", County:" Ceredigion", Name:"New Quay", Type:"Town"},
{Territory:"Wales", County:" Ceredigion", Name:"Tregaron", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Abergele", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Colwyn Bay", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Conwy", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Llandudno", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Llandudno Junction", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Llanfairfechan", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Llanrwst", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Penmaenmawr", Type:"Town"},
{Territory:"Wales", County:" Conwy", Name:"Penrhyn Bay", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"Corwen", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"Denbigh", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"Llangollen", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"Prestatyn", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"Rhuddlan", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"Rhyl", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"Ruthin", Type:"Town"},
{Territory:"Wales", County:" Denbighshire", Name:"St Asaph", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Bagillt", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Buckley", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Caerwys", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Connah's Quay", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Flint", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Holywell", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Mold", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Queensferry", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Saltney", Type:"Town"},
{Territory:"Wales", County:" Flintshire", Name:"Shotton", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Bala", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Bangor", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Barmouth", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Bethesda", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Blaenau Ffestiniog", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Caernarfon", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Criccieth", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Dolgellau", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Harlech", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Nefyn", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Penrhyndeudraeth", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Porthmadog", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Pwllheli", Type:"Town"},
{Territory:"Wales", County:" Gwynedd", Name:"Tywyn", Type:"Town"},
{Territory:"Wales", County:" Merthyr Tydfil", Name:"Merthyr Tydfil", Type:"Town"},
{Territory:"Wales", County:" Merthyr Tydfil", Name:"Treharris", Type:"Town"},
{Territory:"Wales", County:" Monmouthshire", Name:"Abergavenny", Type:"Town"},
{Territory:"Wales", County:" Monmouthshire", Name:"Caldicot", Type:"Town"},
{Territory:"Wales", County:" Monmouthshire", Name:"Chepstow", Type:"Town"},
{Territory:"Wales", County:" Monmouthshire", Name:"Monmouth", Type:"Town"},
{Territory:"Wales", County:" Monmouthshire", Name:"Usk", Type:"Town"},
{Territory:"Wales", County:" Neath Port Talbot", Name:"Aberavon", Type:"Town"},
{Territory:"Wales", County:" Neath Port Talbot", Name:"Briton Ferry", Type:"Town"},
{Territory:"Wales", County:" Neath Port Talbot", Name:"Glynneath", Type:"Town"},
{Territory:"Wales", County:" Neath Port Talbot", Name:"Neath", Type:"Town"},
{Territory:"Wales", County:" Neath Port Talbot", Name:"Pontardawe", Type:"Town"},
{Territory:"Wales", County:" Neath Port Talbot", Name:"Port Talbot", Type:"Town"},
{Territory:"Wales", County:" Newport", Name:"Caerleon", Type:"Town"},
{Territory:"Wales", County:" Newport", Name:"Newport", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Fishguard", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Goodwick", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Haverfordwest", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Milford Haven", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Narberth", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Neyland", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Pembroke", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Pembroke Dock", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"St David's", Type:"Town"},
{Territory:"Wales", County:" Pembrokeshire", Name:"Tenby", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Brecon", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Builth Wells", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Crickhowell", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Hay-on-Wye", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Knighton", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Llandrindod Wells", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Llanfair Caereinion", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Llanfyllin", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Llanidloes", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Llanwrtyd Wells", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Machynlleth", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Montgomery", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Newtown", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Presteigne", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Rhayader", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Talgarth", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Welshpool", Type:"Town"},
{Territory:"Wales", County:" Powys", Name:"Ystradgynlais", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Aberdare", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Ferndale", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Llantrisant", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Mountain Ash", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Pontypridd", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Porth", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Talbot Green", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Tonypandy", Type:"Town"},
{Territory:"Wales", County:" Rhondda Cynon Taf", Name:"Treorchy", Type:"Town"},
{Territory:"Wales", County:" Swansea", Name:"Gorseinon", Type:"Town"},
{Territory:"Wales", County:" Swansea", Name:"Loughor", Type:"Town"},
{Territory:"Wales", County:" Swansea", Name:"Pontarddulais", Type:"Town"},
{Territory:"Wales", County:" Swansea", Name:"Swansea", Type:"Town"},
{Territory:"Wales", County:" Torfaen", Name:"Blaenavon", Type:"Town"},
{Territory:"Wales", County:" Torfaen", Name:"Cwmbran", Type:"Town"},
{Territory:"Wales", County:" Torfaen", Name:"Pontypool", Type:"Town"},
{Territory:"Wales", County:" Vale of Glamorgan", Name:"Barry", Type:"Town"},
{Territory:"Wales", County:" Vale of Glamorgan", Name:"Cowbridge", Type:"Town"},
{Territory:"Wales", County:" Vale of Glamorgan", Name:"Llantwit Major", Type:"Town"},
{Territory:"Wales", County:" Vale of Glamorgan", Name:"Penarth", Type:"Town"},
{Territory:"Wales", County:" Wrexham", Name:"Chirk", Type:"Town"},
{Territory:"Wales", County:" Wrexham", Name:"Overton-on-Dee", Type:"Town"},
{Territory:"Wales", County:" Wrexham", Name:"Wrexham", Type:"Town"},
{Territory:"Wales", County:"Ceredigion", Name:"Aberaeron", Type:"Town"},
];

const loanMaxLimit = 100000; 

const bridgeAsset = process.env.REACT_APP_BRIDGE_ASSET;

const enableAccountAsset = process.env.REACT_APP_ENABLE_ACCOUNT_ASSET;

const enablePaymentValue = process.env.REACT_APP_ENABLE_ACCOUNT_PAYMENT_VALUE;

const environmentReferenceFundingAsset = process.env.REACT_APP_REFERENCE_FUNDING_ASSET;

const trustlineLimitValue = process.env.REACT_APP_TRUSTLINE_LIMIT_VALUE;

const wupoHotAddress = process.env.REACT_APP_WUPO_HOT;

const renderFirstView = process.env.REACT_APP_FIRST_VIEW_FOR_USERS;

const loanPaymentProvider = process.env.REACT_APP_PAYMENT_PROVIDER;

const xrplEnabled = process.env.REACT_APP_XRPL_ENABLED;

const b2bClient = process.env.REACT_APP_CLIENT

const isUsernamePhone = process.env.REACT_APP_IS_USERNAME_PHONE;

const isPhoneVerifyEnabled = process.env.REACT_APP_IS_VERIFY_PHONE;

const webauthnPKRpId = process.env.REACT_APP_PUBLICKEY_RPID;

export { exchangeCurrencies, retailerTokens, wupoServerUri, retailOperation, 
  stockOrderSymbol, bridgeAsset, enableAccountAsset, enablePaymentValue, environmentReferenceFundingAsset, 
  trustlineLimitValue, api, retailersTokensReferenceCurrency, wupoHotAddress, creditAsset, creditTerm, 
  loanMaxLimit, renderFirstView, loanPaymentProvider, xrplEnabled, b2bClient, isUsernamePhone, municipalities,
  bankCodes, bankAccountTypes, isPhoneVerifyEnabled, ukLocations, webauthnPKRpId };

export default api;
