import styled from "@emotion/styled"
import Colors from "./Colors"

const AppButton = styled.button(props => ({
  backgroundColor: props.primary ? Colors.primary : props.secondary ? Colors.secondary : props.third ? Colors.third: props.fourth ? Colors.fourth : "#F0ECFC",
  height: props.small ? "auto" : props.height || "52px",
  width: props.small ? "auto" : props.width || "100%",
  borderRadius: props.small ? "6px" : "12px",
  padding: props.small ? "6px 8px" : 0,
  outline: "none",
  border: "none",
  fontSize: props.small ? "1rem" : "1.2rem",
  color: props.primary ? "white" : props.secondary ? "white" : "white",
  fontFamily: "'Poppins', sans-serif",
  cursor: "pointer",
  transition: ".5s",
  textAlign: "center",
  marginBottom: props.marginBottom ? props.marginBottom : "0",
  marginTop: props.marginTop ? props.marginTop: "0"
}))

export default AppButton