import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";
import AppButton from "../../components/ui/AppButton";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import {createUser} from "../../utilityFunctions/RootUtil"
import swal from "sweetalert";

const roles = [
    {
        value: "ADMIN",
        label: "Administrador"
    },
    {
        value: "OPERATOR",
        label: "Operador"
    },
];

const idTypes = [
  {
      value: "nationalId",
      label: "Identificación Nacional"
  },
  {
      value: "passport",
      label: "Pasaporte"
  },
];

const useStyles = makeStyles((theme) => ({

  gridInputs:{
      maxWidth: "600px",
      justifyContent: "center",
      alignItems: "center",
      // marginTop:"0.2em",
      // marginBottom: "0.2em"
  },
  input: {
      width: "100%",
      height: "50%",
  },

  longLabels: {
      
  }

}));

const cssLoader = css`
display: block;
margin-left: auto;
margin-right: auto;
`;

const newUser = {
  username: "",
  roleName: "",
  email: "",
  password: "",
  idType: "",
  idNumber: "",
  name: "",
  lastName: "",
  phone: "",
};

export default function RootCreate(props) {
  const{ loginUser } = props

  let history = useHistory();

  const classes = useStyles();

  const[loading, setLoading] = React.useState(false);

  const[role, setRoles] = React.useState('');
  const[username, setUsername] = React.useState('');
  const[email, setEmail] = React.useState('');
  const[password, setPassword] = React.useState('');
  const[idType, setIdType] = React.useState('');
  const[idNumber, setIdNumber] = React.useState('');
  const[name, setName] = React.useState('');
  const[lastName, setLastName] = React.useState('');
  const[phone, setPhone] = React.useState('');

  const rootBack = () => {
    history.push({pathname: "/root-profile"});
  };

  const submitUser = async () => {
    newUser.username = username;
    newUser.roleName = role;
    newUser.email = email;
    newUser.password = password;
    newUser.idType = idType;
    newUser.idNumber = idNumber;
    newUser.name = name;
    newUser.lastName = lastName;
    newUser.phone = phone;

    setLoading(true);
    const result = await createUser(newUser , loginUser.jwtToken);
    setLoading(false);
    if(result !== "Error"){
        swal({
            title: "Creación Exitosa",
            icon: "success",
            button: "Entiendo",
        });
    }else{
        swal({
            title: "Falló la creación",
            icon: "error",
            button: "Entiendo",
        });
    }
  }

  return (
    <div
      style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          overflowY: "scroll",
          overflowX: "scroll",
      }}
    >
    {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
      <>
      <Grid container direction="column">
          <Grid container direction="row" className={classes.gridInputs}>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    label={<Typography variant="body2" className={classes.longLabels}>Usuario</Typography>}
                    value={username}
                    onChange={e=>setUsername(e.target.value)}
                    helperText="Digita el usuario"
                >
                    Usuario
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    select
                    label={<Typography variant="body2" className={classes.longLabels}>Rol</Typography>}
                    value={role}
                    onChange={e=>setRoles(e.target.value)}
                    helperText="Selecciona el rol"
                >
                    {roles.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    label={<Typography variant="body2" className={classes.longLabels}>Correo electrónico</Typography>}
                    value={email}
                    onChange={e=>setEmail(e.target.value)}
                    helperText="Digita el correo"
                >
                    Correo
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    label={<Typography variant="body2" className={classes.longLabels}>Contraseña Temporal</Typography>}
                    value={password}
                    onChange={e=>setPassword(e.target.value)}
                    helperText="Ingresa una contraseña temporal"
                >
                    Contraseña
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    select
                    label={<Typography variant="body2" className={classes.longLabels}>Tipo ID</Typography>}
                    value={idType}
                    onChange={e=>setIdType(e.target.value)}
                    helperText="Selecciona un tipo de ID"
                >
                    {idTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    label={<Typography variant="body2" className={classes.longLabels}>No. Identificación</Typography>}
                    value={idNumber}
                    onChange={e=>setIdNumber(e.target.value)}
                    helperText="Ingresa el número de ID"
                >
                    Identificación
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    label={<Typography variant="body2" className={classes.longLabels}>Nombre</Typography>}
                    value={name}
                    onChange={e=>setName(e.target.value)}
                    helperText="Ingresa el nombre"
                >
                    Nombre
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    label={<Typography variant="body2" className={classes.longLabels}>Apellido</Typography>}
                    value={lastName}
                    onChange={e=>setLastName(e.target.value)}
                    helperText="Ingresa el apellido"
                >
                    Apellido
                </TextField>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <TextField
                    label={<Typography variant="body2" className={classes.longLabels}>Celular</Typography>}
                    value={phone}
                    onChange={e=>setPhone(e.target.value)}
                    helperText="Ingresa el celular"
                >
                    Celular
                </TextField>
            </Grid>
          </Grid>  
          <Grid container direction="row" className={classes.gridInputs} style={{marginTop: "1em"}}>
            <Grid item xs={6} sm={6} md={6}>
                <AppButton 
                    primary 
                    width={"80%"}
                    onClick={rootBack}
                    style={{ backgroundColor: "#87C1FF", color: "white" }}
                >
                    ATRÁS
                </AppButton>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
                <AppButton 
                    primary 
                    width={"80%"}
                    onClick={submitUser}
                >
                    LISTO
                </AppButton>
            </Grid>
          </Grid>
        </Grid>
      </>
    }
    </div>
  )
}
