import {wupoServerUri} from '../Api';

const changeRol = (username, role, jwtToken) => {
   

    const roleChange = {
        username: username,
        roleName: role,
    }

    console.log(roleChange);

    return(
        fetch(`${wupoServerUri.devnet}/api/root/changerole`, {
            
            method: 'POST',
            body: JSON.stringify(roleChange),
            headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
            }
            
        }).then((response) => { 
            console.log(response.status) // DEBUG PRINTING

            if(response.status === 200){
                return response.json();
            }
            else{
                return "Error";
            }
        
        }).then((roleChange) => {  
            console.log(roleChange);   
            return(roleChange);
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    );

};

const getApiCredentials = (username, jwtToken) => {
   

    const request = {
        rootUsername: username,
    }

    console.log(request);

    return(
        fetch(`${wupoServerUri.devnet}/api/root/rootapicredentials`, {
            
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
            }
            
        }).then((response) => { 
            console.log(response.status) // DEBUG PRINTING

            if(response.status === 200){
                return response.json();
            }
            else{
                return "Error";
            }
        
        }).then((credentials) => {  
            console.log(credentials);   
            return(credentials);
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    );

};

const createApiCredentials = (username, jwtToken) => {
   

    const request = {
        rootUsername: username,
    }

    console.log(request);

    return(
        fetch(`${wupoServerUri.devnet}/api/root/apicredentials`, {
            
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
            }
            
        }).then((response) => { 
            console.log(response.status) // DEBUG PRINTING

            if(response.status === 200){
                return response.json();
            }
            else{
                return "Error";
            }
        
        }).then((credentials) => {  
            console.log(credentials);   
            return(credentials);
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    );

};

const disableApiCredentials = (username, apiKey, jwtToken) => {
   

    const request = {
        rootUsername: username,
        apiKey: apiKey,
    }

    console.log(request);

    return(
        fetch(`${wupoServerUri.devnet}/api/root/apicredentialsstatus`, {
            
            method: 'POST',
            body: JSON.stringify(request),
            headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
            }
            
        }).then((response) => { 
            console.log(response.status) // DEBUG PRINTING

            if(response.status === 200){
                return response.json();
            }
            else{
                return "Error";
            }
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    );

};

const createUser= (userRequest, jwtToken) => {

    console.log(userRequest);

    return(
        fetch(`${wupoServerUri.devnet}/api/root/createuser`, {
            
            method: 'POST',
            body: JSON.stringify(userRequest),
            headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
            }
            
        }).then((response) => { 
            console.log(response.status) // DEBUG PRINTING

            if(response.status === 200){
                return response.json();
            }
            else{
                return "Error";
            }
        
        }).then((roleChange) => {  
            console.log(roleChange);   
            return(roleChange);
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    );

};

export {changeRol, getApiCredentials, createApiCredentials, disableApiCredentials, createUser}

