import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography } from "@mui/material";
import Colors from "../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { useHistory} from "react-router-dom";


export default function DealsDefinitionsList(props) {

    const {dealsDefinitions} = props

    const history = useHistory();

    React.useEffect(()=> {
        renderDealsDefinitions();
    }, []);

    const [dealsList, setDealsList] = React.useState("");

    const renderDealsDefinitions = () => {

        setDealsList(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                <Grid container spacing={2}>
                    {dealsDefinitions.map((definition) => {
                        return(
                        <>
                            <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <ListItemButton onClick={() => definitionDetail(definition)}>
                                    <Grid key={definition.id} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Box key={definition.id}
                                            sx={{
                                                ...((definition.type === "PRF" || definition.type === "ADF" || definition.type === "VFO" || definition.type === "FOF") && {
                                                    backgroundColor: Colors.primary,
                                                    color: "white",
                                                }),
                                                ...((definition.type === "IPR" || definition.type === "VPR" || definition.type === "PRP") && {
                                                    backgroundColor: Colors.secondary,
                                                    color: "white",
                                                }),
                                                borderRadius: "25%",
                                                width: "3rem",
                                                height: "3rem",
                                                textAlign: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: "1.5rem",
                                            
                                            }} 
                                        >
                                            { definition.type }
                                        </Box>
                                    </Grid>
                                    <Grid key={definition.id + 1} item xs={5} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={definition.id} variant="body1" sx={{color: "black"}}>
                                            {`${definition.name}`}
                                        </Typography>
                                    </Grid>
                                    <Grid key={definition.id + 2} item xs={5} sm={5} md={5} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={definition.id + 1} variant="body2" sx={{}}>
                                            {`${definition.description}`}
                                        </Typography>
                                    </Grid>
                                    <Grid key={definition.id + 3} item xs={3} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={definition.id + 2} variant="body2" sx={{fontWeight: "bold"}}>
                                            {definition.level === "high" ? "Alto" : definition.level === "medium" ? "Medio" : "Bajo"}
                                        </Typography>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </>
                        )
                    })}
                </Grid>
            </List>
        );
    };

    const definitionDetail = (definitions) =>{
        console.log(definitions)

        history.push({ 
            pathname: "/commercial/changedealsdef",
            state:{
                dealsDefinitions: definitions
            }
        });
    };

    return (
    <Box sx={{display: "flex", justifyContent: "center"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
        >
            {dealsList}
        </Box>
    </Box>
    )
}
