import React from 'react'
import { useHistory } from "react-router-dom";
import PageLabel from "../../components/ui/PageLabel";
import AppButton from "../../components/ui/AppButton";
import Content from "../../layout/Content";

export default function Statement(props) {

    let history = useHistory();

    console.log("Entrando")
    console.log(props.location.state.statement)

    let renderStatements = [];
    renderStatements = JSON.parse(props.location.state.statement)
    console.log(renderStatements)

    return (
    <div
        style={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        }}
    >
      <Content maxWidth={"576px"}>
        <PageLabel>TUS MOVIMIENTOS</PageLabel>
        {/* <div style={{ width: "80%" }}>{renderStatements}</div> */}
        {/* <AppButton primary width={"80%"} onClick={history.push('/account/handle_account')}> */}
        <AppButton primary width={"80%"}>
          ATRÁS
        </AppButton>
      </Content>
    </div>
    )
}
