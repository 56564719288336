import {wupoServerUri} from '../Api';

const getAllDeliveries = (jwtToken) => {

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/deliveries`, {
          method: "POST",
        //   body: JSON.stringify(definitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((definitionsResponse) => {
          console.log(definitionsResponse); // DEBUG PRINTING
          
          return definitionsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
};

const getDelivery = (deliveryReq) => {
    console.log(deliveryReq);
    return(
      fetch(`${wupoServerUri.devnet}/api/auth/delivery`, {
          method: "POST",
          body: JSON.stringify(deliveryReq),
          credentials: 'include',
          headers: {
            // "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((definitionsResponse) => {
          console.log(definitionsResponse); // DEBUG PRINTING
          
          return definitionsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
};

const getAllProblems = (jwtToken) => {

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/deliveryProblems`, {
          method: "POST",
        //   body: JSON.stringify(definitions),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((definitionsResponse) => {
          console.log(definitionsResponse); // DEBUG PRINTING
          
          return definitionsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
};

const createDeliveries = (deliveriesList, jwtToken) => {
  
    console.log(deliveriesList);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/batchDeliveries`, {
          method: "POST",
          body: JSON.stringify(deliveriesList),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((productsResponse) => {
          console.log(productsResponse); // DEBUG PRINTING
          
          return productsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
};

const createProblems = (problemsList, jwtToken) => {
  
    console.log(problemsList);
  
    return(
      fetch(`${wupoServerUri.devnet}/api/secure/batchProblems`, {
          method: "POST",
          body: JSON.stringify(problemsList),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
          if(response.status === 200){
              return response.json();
          }
          else{
              return "Not found";
          }
      })
      .then((productsResponse) => {
          console.log(productsResponse); // DEBUG PRINTING
          
          return productsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
};

const createDelivery = (newDelivery, jwtToken) => {
    console.log(newDelivery);

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/newDelivery`, {
          method: "POST",
          body: JSON.stringify(newDelivery),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
      })
      .then((dealsResponse) => {
          console.log(dealsResponse); // DEBUG PRINTING
          
          return dealsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const changeDeliveryStatus = (deliveryReq, jwtToken) => {
    console.log(deliveryReq);

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/statusDelivery`, {
          method: "POST",
          body: JSON.stringify(deliveryReq),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
      })
      .then((dealsResponse) => {
          console.log(dealsResponse); // DEBUG PRINTING
          
          return dealsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}
const pqrDelivery = (deliveryReq, jwtToken) => {
    console.log(deliveryReq);

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/pqrDelivery`, {
          method: "POST",
          body: JSON.stringify(deliveryReq),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
      })
      .then((dealsResponse) => {
          console.log(dealsResponse); // DEBUG PRINTING
          
          return dealsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

const createNewProblem = (problemReq, jwtToken) => {
    console.log(problemReq);

    return(
      fetch(`${wupoServerUri.devnet}/api/secure/problemDelivery`, {
          method: "POST",
          body: JSON.stringify(problemReq),
          credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            "Content-Type": "application/json; charset=UTF-8",
          },
      })
      .then((response) => {
          console.log(response.status);
            if(response.status === 200){
                return response.json();
            }
            else{
                return "Not found";
            }
      })
      .then((dealsResponse) => {
          console.log(dealsResponse); // DEBUG PRINTING
          
          return dealsResponse;
      })
      .catch((error) => {
          console.log("Error: " + error);
      })
    )
  
}

export {getAllDeliveries, createDeliveries, createProblems, getAllProblems, createDelivery, changeDeliveryStatus, pqrDelivery, 
    createNewProblem, getDelivery}