import React from 'react';
import {getCodes, createSettlement} from "../../../utilityFunctions/MarketPlaceUtil";
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import NumberFormat from "react-number-format";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import Autocomplete from '@mui/material/Autocomplete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import swal from "sweetalert";
import moment from 'moment';
import 'moment/locale/es';


let settlementValues = {
    code: "",
    animalsSold: "",
    sellingValue: "",
    totalWeight: "",
    date: "",
};

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function BatchSettlement(props) {

    const {loginUser} = props

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        
        getProductsCodes();

        settlementValues = {
            code: "",
            animalsSold: "",
            sellingValue: "",
            totalWeight: "",
        };
    }, []);

    const [codes, setCodes] = React.useState("");
    const [animalsSold, setAnimalsSold] = React.useState("");
    const [sellingValue, setSellingValue] = React.useState("");
    const [totalWeight, setTotalWeight] = React.useState("");

    const getProductsCodes = async () => {
       const codes = await getCodes(loginUser.jwtToken); 

        codes.sort((a, b) => {

            const codeA = a.code.toUpperCase();
            const codeB = b.code.toUpperCase();

            if (codeA < codeB) {
                return -1;
            } else if (codeA > codeB) {
                return 1;
            } else {
                return 0;
            }
        });

       setCodes(
        {
            options: codes,
            getOptionLabel: (option) => option.code,
        }
       );
    };

    const onChangeAutocomplete = (event, value) => {
        if(!value){
            return;
        }
        settlementValues.code = value.code;
    };

    const animalsSoldChange = (e) => {
        setAnimalsSold(e.floatValue);
        settlementValues.animalsSold = e.floatValue;
    };

    const sellingValueChange = (e) => {
        setSellingValue(e.floatValue);
        settlementValues.sellingValue = e.floatValue;
    };

    const totalWeightChange = (e) => {
        setTotalWeight(e.floatValue);
        settlementValues.totalWeight = e.floatValue;
    };

    const handelSettlement = async () => {
        setLoading(true);
        const sellingResult = await createSettlement(settlementValues, loginUser.jwtToken);
        setLoading(false);

        if(!sellingResult){
            swal({
                title: "Error en el proceso",
                text: "Vuelve a intentarlo",
                icon: "error",
                button: "ok",
            });
            return;
        };

        swal({
            title: "Proceso exitoso",
            icon: "success",
            button: "ok",
        });
    };

    const [selectedDate, setSelectedDate] = React.useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format('YYYYMMDD'));
        settlementValues.date=moment(date).format('YYYYMMDD');
        console.log(settlementValues.date);
        
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                height: "100%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
            >
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        {...codes}
                        id="codes"
                        clearOnEscape
                        autoComplete = {true}
                        noOptionsText = {"Lote no existe"}
                        // sx={{ width: 600 }}
                        onChange={onChangeAutocomplete}
                        // onInputChange={onChange}
                        renderInput={(params) => (
                            <TextField {...params} label={<Typography variant="body2">Código del lote</Typography>} variant="standard" />
                        )}
                    /> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="animalsAmount"
                        customInput={TextField}
                        // prefix={'$ '}
                        // suffix={'%'}
                        type="animalsAmount"
                        label={<Typography variant="body2">Cantidad de animales</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={animalsSold}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            animalsSoldChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="sellingValue"
                        customInput={TextField}
                        prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2">Valor de venta</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={sellingValue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            sellingValueChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="totalWeight"
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' kg'}
                        type="totalWeight"
                        label={<Typography variant="body2">Peso total</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={totalWeight}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            totalWeightChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{width: "100%", marginTop: "1.5rem"}}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            timezone="America/Bogota"
                            locale="es" 
                            label="Fecha de venta"
                            inputFormat="DD/MM/YYYY"
                            value={selectedDate}
                            onChange={handleDateChange}
                            renderInput={(params) => 
                                <TextField {...params} 
                                    variant="standard"
                                    fullWidth
                                    sx={{width: {xs: "90%", sm: "60%", md: "30%"}}}
                                />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Button onClick={handelSettlement} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                        Liquidar
                    </Button>
                </Grid>
            </Grid>
        }
        </Box>
        </Box>
    )
}
