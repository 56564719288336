import { Divider, Grid, Typography, Button, DialogContent } from '@mui/material';
import React from 'react';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import Card from '@mui/material/Card';
import {assignDeal, closeDeal, getTrustCommercials, getTrustClients} from "../../utilityFunctions/DealsUtil";
import swal from 'sweetalert';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useHistory } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

// let sortedActions;

const dateOptions = {
// weekday: "short",
day: "numeric",
month: "short",
year: "numeric",
};

const dealStatus = [
    {
        status: "created",
        label: "Oportunidad creada",
        initials: "CR",
    },
    {
        status: "automaticEmail",
        label: "Correo automático",
        initials: "CA",
    },
    {
        status: "responded",
        label: "Respuesta cliente",
        initials: "RC",
    },
    {
        status: "assigned",
        label: "Asignado comercial",
        initials: "AC",
    },
];

const detailedDealStatus =[
    {
        status: "notStarted",
        label: "Oportunidad creada",
        initials: "CR",
    },
    {
        status: "automatic",
        label: "Correo automático",
        initials: "CA",
    },
    {
        status: "clientResponse",
        label: "Respuesta cliente",
        initials: "RC",
    },
    {
        status: "assigned",
        label: "Asignado comercial",
        initials: "AC",
    },
];

const dealRequest = {
    id: "",
    commercialName: "",
}

export default function DealsDetail(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    let history = useHistory();

    React.useEffect(() => {
        loadCommercials();
        renderActions();
        renderDealDetail();
    }, []);

    const sortDealActions = () => {
        if(props.location.state && props.location.state.deal){
            const sortedActions = props.location.state.deal.dealshistory.sort((a, b) => (new Date(a.date) - new Date(b.date)));
            console.log(props.location.state.deal)
            console.log(sortedActions);
            return sortedActions;
        }

        else{
            return;
        }
    };

    const [actionsList, setActionsList] = React.useState("");
    const [clientNameArray, setClientNameArray] = React.useState("");

    const loadCommercials = async () => {
        const allCommercials = await getTrustCommercials(loginUser.jwtToken);
        console.log(allCommercials);
        setClientNameArray(
             {
                 options: allCommercials,
                 getOptionLabel: (option) => option.name,
             }
        );
 
     };

    const renderActions = async () => {

        const sortedActions = await sortDealActions();

        setActionsList(
            <Grid container direction="column" sx={{justifyContent: {xs: "center", sm: "start"}}}>
                {sortedActions.map((action) => {
                    const statusElement = dealStatus.find((statusEl) => statusEl.status === action.dealAction)
                    console.log(statusElement);
                    return (
                        <>
                        <Grid key={action.id} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem"}}>
                            <Grid key={action.id+5} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
                                <Typography variant="body2" sx={{color: "GrayText"}}>
                                    {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(action.date))}`}
                                </Typography>
                            </Grid>
                            <Grid  key={action.id+1} container>
                                <Grid ey={action.id+2} item xs={3} sm={3} md={3} sx={{display: "flex", alignItems: "center"}}>
                                    <Box
                                        sx={{
                                            ...((statusElement.initials === "CR" || statusElement.initials === "RC") && {
                                                backgroundColor: Colors.primary,
                                                color: "white",
                                            }),
                                            ...((statusElement.initials === "CA" || statusElement.initials === "AC") && {
                                                backgroundColor: Colors.secondary,
                                                color: "white",
                                            }),
                                            borderRadius: "25%",
                                            width: "3rem",
                                            height: "3rem",
                                            textAlign: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginBottom: "0.5rem",
                                        
                                        }} 
                                    >
                                        {statusElement.initials}
                                    </Box>
                                </Grid>
                                <Grid ey={action.id+3} item xs={9} sm={9} md={9} sx={{display: "flex", alignItems: "center"}}>
                                    <Typography variant="h6">{statusElement.label}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {action.dealAction === "automaticEmail" ?
                            <>
                            <Grid key={action.id+6} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0rem"}}>
                                <Typography variant="body2">{`Asunto: ${action.subject}`}</Typography>
                            </Grid>
                            <Grid key={action.id+7} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem"}}>
                                <Typography variant="body2">{action.clientAction === "responded"? "Resultado: Respondido" : <span style={{color: "#E2062C"}}>{"Resultado: Pendiente respuesta"}</span>}</Typography>
                            </Grid>
                            </>
                        :
                        action.dealAction === "responded" ?
                            <Grid key={action.id+8} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem"}}>
                                <Typography variant="body2">{action.callToAction === "form"? "Formulario valor potencial" : "Interesado en promoción"}</Typography> 
                            </Grid>
                        :        
                        <></>
                        }
                        <Grid key={action.id+9} item xs={12} sm={12} md={12} lg={12} sx={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
                            <Divider/>
                        </Grid>
                        </>
                    )
                })}
            </Grid>
        )
    };

    const[dealRender, setDEalRender] = React.useState("");

    const renderDealDetail = () => {

            const dealDetail = props.location.state.deal;
            const statusElement = detailedDealStatus.find((statusEl) => statusEl.status === dealDetail.state);
            console.log(dealDetail)
            console.log(statusElement);

            if(dealDetail && statusElement){
                setDEalRender(
                    <Grid container direction="column" sx={{justifyContent: "space-evenly"}}>
                        <Grid>
                            <Card>
                                <Grid container sx={{paddingLeft: "3.5rem"}}>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>Oportunidad</Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "end"}}>
                                        <Typography sx={{color: "GrayText", fontSize: "0.8rem", marginTop: "0.5em", paddingRight: "1rem"}}>
                                            {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(dealDetail.date))}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography gutterBottom variant="h4" sx={{fontWeight: "bolder"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO').format(dealDetail.value)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography gutterBottom variant="body2">Cliente:</Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>{dealDetail.clientName}</Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography variant="body2">Producto cliente:</Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography variant="body2" sx={{fontWeight: "bold"}}>{dealDetail.clientType==="fund"? "Fondo de inversión" : "Fiducia inmobiliaria"}</Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography variant="body2" sx={{marginBottom: "1.5rem"}}>Tipo de oportunidad</Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography variant="body2" sx={{fontWeight: "bold", marginBottom: "1.5rem"}}>{dealDetail.definitionsName}</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid>
                            <Card>
                                <Grid container sx={{paddingLeft: "3.5rem"}}>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography gutterBottom variant="body1" sx={{color: "graytext", marginTop: "0.5em"}}>Gestión</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Typography gutterBottom variant="h4" sx={{fontWeight: "bolder"}}>{`${dealDetail.maxTime} días`}</Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography variant="body2">Prioridad:</Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography variant="body2" sx={{fontWeight: "bold"}}>{dealDetail.priority==="high"? "Alta" : dealDetail.priority==="medium"? "Media": "Baja"}</Typography>
                                    </Grid>
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography gutterBottom variant="body2">Estado oportunidad:</Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>{`${statusElement.label}`}</Typography>
                                    </Grid>
                                    {dealDetail.commercialName && dealDetail.state==="assigned"?
                                        <>
                                        <Grid item xs={5} sm={5} md={5}>
                                            <Typography gutterBottom variant="body2">Funcionario a cargo:</Typography>
                                        </Grid>
                                        <Grid item xs={7} sm={7} md={7}>
                                            <Typography gutterBottom variant="body2" sx={{fontWeight: "bold"}}>{dealDetail.commercialName}</Typography>
                                        </Grid>
                                        </>
                                     :<></>
                                    }
                                    <Grid item xs={5} sm={5} md={5}>
                                        <Typography variant="body2" sx={{marginBottom: "1.5rem"}}>Periodicidad correos:</Typography>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <Typography variant="body2" sx={{fontWeight: "bold", marginBottom: "1.5rem"}}>{`${dealDetail.emailTiming} días`}</Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid>
                            <Grid container >
                                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={() => openAssignBox(dealDetail)} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                                        Asignar
                                    </Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={() => handleClosing(dealDetail)} disableFocusRipple sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                                        Cerrar 
                                    </Button>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={() => toClientDetail(dealDetail)} disableFocusRipple sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                                        Detalle 
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            }
    };

    const handleAssign = async (deal) => {
        setLoading(true);
        const assignResult = await assignDeal(dealRequest, loginUser.jwtToken);
        setLoading(false);
        if(assignResult !== "Not found"){
            swal({
                title: "Asignación exitosa",
                icon: "success",
                buttons: "Súper"
            }).then(()=>{
                history.push("/commercial/deals");
            });
        }else{
            swal({
                title: "Error",
                icon: "error",
                buttons: "Súper"
            });
        }
    };
    

    const handleClosing = async (deal) => {
        setLoading(true);

        dealRequest.id = deal.id;

        const closingResult = await closeDeal(dealRequest, loginUser.jwtToken);
        setLoading(false);
        if(closingResult !== "Not found"){
            swal({
                title: "Cierre exitoso",
                icon: "success",
                buttons: "Súper"
            }).then(()=>{
                history.push("/commercial/deals");
            });
        }else{
            swal({
                title: "Error",
                icon: "error",
                buttons: "Súper"
            });
        }
    };

    const[assignBox, setAssignBox] = React.useState(false);

    const openAssignBox = (deal) => {
        dealRequest.id = deal.id;
        setAssignBox(true);
    };

    const closeAssignBox = () => {
        setAssignBox(false);
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        dealRequest.commercialName=value.name;
    };

    const onChange = () => {

    };

    const toClientDetail = async (deal) => {
        setLoading(true);
        const allClients = await getTrustClients(loginUser.jwtToken);
        console.log(allClients);
        setLoading(false);
        if(allClients && deal){
            const clientForDetail = allClients.find((client) => client.name === deal.clientName);
            console.log(clientForDetail);

            if(clientForDetail){
                history.push({ 
                    pathname: "/commercial/clientDetail",
                    state:{
                        client: clientForDetail
                    }
                });
            }
        }
        

    };

    return (
    <Box
        sx={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        // backgroundColor: "#ededed",
        }}
    >
    {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
        <>
            <Grid container sx={{}}>
                <Grid item xs={12} sm={12} md={8} sx={{display: "flex", justifyContent: "center", backgroundColor: "#FAFAFA"}}>
                    <Box sx={{width: "50%", height: "90%", display: "flex"}}>
                        {dealRender}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "start", backgroundColor: "#ededed"}}>
                    <Box sx={{width: "90%", height: "90%", overflowY: "scroll",}}>
                        {actionsList} 
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={assignBox} onClose={closeAssignBox}>
            <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
                <Typography variant="h5">Asignar Oportunidad</Typography> 
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Autocomplete
                            {...clientNameArray}
                            id="commercialsNames"
                            clearOnEscape
                            autoComplete = {true}
                            fullWidth = {true}
                            noOptionsText = {"Funcionario no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeAutocomplete}
                            onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Nombre Funcionario" variant="standard" />
                            )}
                        /> 
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                <Button 
                    onClick={handleAssign} variant="contained" sx={{backgroundColor: Colors.primary, marginLeft: "1rem"}}>
                    ASIGNAR
                </Button>
            </DialogActions>
            </Dialog>
        </>
        }
    </Box>
    )
}
