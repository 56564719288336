import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import AppButton from "../../components/ui/AppButton";
import MenuList from '@mui/material/MenuList';
import Colors from "../../components/ui/Colors"; 
import Divider from '@mui/material/Divider';
import {getLoanDocument} from "../../utilityFunctions/OperatorUtil";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
    menuList: {
        overflowY: "scroll",
    },
    divider:{
        background: Colors.primary
    },
    button:{
        background: "none",
        textTransform: "none",
        '&:hover': {
            transform: 'scale(1.1)',
            backgroundColor: Colors.primary,
        },
    },
    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
            transform: 'scale(1.1)',
            backgroundColor: Colors.primary,
        },
    },
}));

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
};

let loansInfo;

const documentRequest = {
    loanId:"",
    type:"",
};

export default function OperatorLoans(props) {

    const {loginUser} = props

    const classes = useStyles();
    const history = useHistory();

    const[loading, setLoading] = React.useState(false);

    React.useEffect(() =>{
        loansInfo = props.location.state.loansInfo;

        renderLoans();
    }, []);

    const handleClick = () => {
        history.push({pathname: "/operator-role"});
    };


    const[loansList, setLoanList] = React.useState('');

    const renderLoans = () =>{
        setLoading(true);
        setLoanList(
            <>
                <MenuList className={classes.menuList}>
                {loansInfo.map((loan) => {
                    return(
                        <>
                            <Divider key={loan.id} className={classes.divider}/>
                            <Grid key={loan.id} container direction="row" className={classes.gridInputs}>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    {/* <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                        Código crédito:
                                    </Typography> */}
                                    <Button key={loan.id} className={classes.button} onClick={()=>loanSelect(loan.id)}>
                                        Código crédito:
                                    </Button>
                                </Grid>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    {/* <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                        {`${loan.id}`} 
                                    </Typography> */}
                                    <Button key={loan.id} className={classes.button} onClick={()=>loanSelect(loan.id)}>
                                        {`${loan.id}`} 
                                    </Button>
                                </Grid>
                                
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                        Fecha del crédito:
                                    </Typography>
                                </Grid>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography key={loan.id} gutterBottom variant="body2" component="div">
                                        {`${new Intl.DateTimeFormat("es-CO", 
                                                        dateOptions).format(new Date(loan.loanDate))}`} 
                                    </Typography>
                                </Grid>

                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Saldo inicial:
                                    </Typography>
                                </Grid>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        {`$ ${new Intl.NumberFormat('es-CO').format(loan.initialBalance)}`}
                                    </Typography>
                                </Grid>

                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Saldo actual:
                                    </Typography>
                                </Grid>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        {`$ ${new Intl.NumberFormat('es-CO').format(loan.currentBalance)}`}
                                    </Typography>
                                </Grid>

                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Cuotas:
                                    </Typography>
                                </Grid>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        {`${loan.dues}`}
                                    </Typography>
                                </Grid>

                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Periodo:
                                    </Typography>
                                </Grid>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        {loan.termUnit === "day" ? `${loan.term} días`: loan.termUnit === "month" ? `${loan.term} meses`: 
                                        loan.termUnit === "week" ? `${loan.term} semanas` : `${loan.term} días`}
                                    </Typography>
                                </Grid>

                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        Días en mora:
                                    </Typography>
                                </Grid>
                                <Grid key={loan.id} item xs={6} sm={6} md={6}>
                                    <Typography gutterBottom variant="body2" component="div">
                                        {loan.defaultDays}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )
                })}
                </MenuList>
                <Grid container direction="row" className={classes.gridInputs}>

                    <Grid item xs={12} sm={12} md={12}>
                        <AppButton 
                            primary
                            width={"45%"}
                            onClick={handleClick}
                        >
                            LISTO
                        </AppButton>
                    </Grid>
               </Grid>
            </>
                        
        );
        setLoading(false);
    };

    //Get loan documents

    const loanSelect = (loanId) => {
        documentRequest.loanId = loanId;
        setDocs(true);
    };

    const[docs, setDocs] = React.useState(false);


    const closeDocs = () =>{
        setDocs(false);
    };

    const docRequest = (documentType) => {
        documentRequest.type = documentType;
        setLoading(true);
        closeDocs();
        getLoanDocument(documentRequest, loginUser.jwtToken).then(() => {
            setLoading(false);
        })
    };

    return (
            <div
                style={{
                    // display: "flex",
                    maxWidth: "600px",
                    justifyContent: "center",
                    width: "100%",
                    height: "75%",
                    textAlign: "center",
                    // overflow: "scroll"
                }}
            >
            {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
                
                loansList
                }

                <React.Fragment>
                    <Dialog open={docs} onClose={closeDocs}>
                        <DialogTitle style={{color: "blue"}}>DOCUMENTOS</DialogTitle>
                        <DialogContent className={classes.actionArea}>
                            <Card sx={{ minWidth: 150 }}>
                                <CardActionArea onClick={()=>docRequest("AGREEMENT")}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Contrato
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </DialogContent>

                        <DialogContent className={classes.actionArea}>
                            <Card sx={{ minWidth: 150 }}>
                                <CardActionArea onClick={()=>docRequest("PROMESSNOTE")}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Pagaré
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </DialogContent>

                        <DialogContent className={classes.actionArea}>
                            <Card sx={{ minWidth: 150 }}>
                                <CardActionArea onClick={()=>docRequest("INSTRUCTIONSLETTER")}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Resumen
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </DialogContent>

                        <DialogContent className={classes.actionArea}>
                            <Card sx={{ minWidth: 150 }}>
                                <CardActionArea onClick={()=>docRequest("GUARANTEE")}>
                                    <CardContent>
                                        <Typography variant="h5" component="div">
                                            Aval
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </DialogContent>

                        <DialogActions>
                            <Button onClick={closeDocs} color="primary" style={{minWidth: "2rem"}}>
                                ATRÁS
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </div>
    )
}
