import React, {useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FaWallet } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { BsCardChecklist } from "react-icons/bs";
import Colors from "../../components/ui/Colors"; 
import {adminAllStatements, adminClientStatements, 
    adminAllLoans, adminClientLoans,
    adminAllLoansStatements, adminClientLoanStatement,
    adminAllClientsInformation, adminSingleClientsInformation,
    updateBalances, adminAllAccounts, adminClientAccounts, adminAllLoansDate, adminAllLoansStatementsDate,
    adminAllClientsInformationDate, adminFGReportDate, adminFGReport } from "../../utilityFunctions/AdminUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {fetchXrpl, loadXrpAddressCreds} from "../../utilityFunctions/FetchUtil";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import {b2bClient} from "../../Api.js";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles((theme) => ({

    gridProducts:{
        maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },

    div:{
        color: Colors.primary,
        cursor: "pointer",
    },

    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        '&:hover': {
          transform: 'scale(1.1)',
          backgroundColor: Colors.primary,
        },
    },

    cardImage:{
        width: "20%",
        justifySelf: "center"
    },

}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const clientUsername = {
    account: "",
}

let WUPOADDRESS = "";
const FETCHTYPE = "Balance";

export default function AdminRol(props) {

    const{loginUser} = props
 
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        setLoading(true);
        if(b2bClient !== "IMS"){
            loadXrpAddressCreds(loginUser.jwtToken).then((credential) => {
                WUPOADDRESS = credential.xrpAddress;
                setLoading(false);
            })
        }else{
            setLoading(false);
        }
    }, []);

    const handleRequest = async (request)=>{
        setChosenTx(request);
        setChooseQry(true);

        if(request === "accounts"){
            setLoading(true);
            const wupoLedgerBalance = await fetchXrpl(FETCHTYPE, WUPOADDRESS);
            await updateBalances(wupoLedgerBalance, loginUser.jwtToken);
            setLoading(false);
        }

    };

    const handleTypeRequest = (type) =>{
        if(type === "total"){
            // HandleAdminRequest(chosenTx);
            handleCloseChooseQry();
            setTotalDate(true);
        }
        else if(type === "client"){
            setQryInput(true);
            handleCloseChooseQry();
        }
    };

    const HandleAdminRequest = async (request) =>{
        setLoading(true);

        if(request === "users"){
            if(fromDate && toDate){
                const clientRequest ={
                    fromDate: fromDate,
                    toDate: toDate,
                }
                await adminAllClientsInformationDate(clientRequest, loginUser.jwtToken);
                setLoading(false);
                return;
            }
            else{
                await adminAllClientsInformation(loginUser.jwtToken);
                setLoading(false);
                return;
            }
            
        }

        if(request === "accounts"){
            await adminAllAccounts(loginUser.jwtToken);
            setLoading(false);
            return;
        }

        if(request === "transactions"){
            await adminAllStatements(loginUser.jwtToken);
            setLoading(false);
            return;
        }

        if(request === "loans"){
            if(fromDate && toDate){
                const loanRequest ={
                    fromDate: fromDate,
                    toDate: toDate,
                }
                await adminAllLoansDate(loanRequest, loginUser.jwtToken);
                setLoading(false);
                return;
            }
            else{
                await adminAllLoans(loginUser.jwtToken);
                setLoading(false);
                return;
            }
        }

        if(request === "loansPayments"){
            if(fromDate && toDate){
                const loanRequest ={
                    fromDate: fromDate,
                    toDate: toDate,
                }
                await adminAllLoansStatementsDate(loanRequest, loginUser.jwtToken);
                setLoading(false);
                return;
            }
            else{
                await adminAllLoansStatements(loginUser.jwtToken);
                setLoading(false);
                return;
            }
        }
        
    };

    const handleClientQry = async () =>{
        setLoading(true);
        closeQryInput();
        clientUsername.account=clientAccount;

        if(chosenTx === "users"){
            await adminSingleClientsInformation(clientUsername, loginUser.jwtToken);
            setLoading(false);
            return;
        }

        if(chosenTx === "accounts"){
            await adminClientAccounts(clientUsername, loginUser.jwtToken);
            setLoading(false);
            return;
        }

        if(chosenTx === "transactions"){
            await adminClientStatements(clientUsername, loginUser.jwtToken);
            setLoading(false);
            return;
        }

        if(chosenTx === "loans"){
            await adminClientLoans(clientUsername, loginUser.jwtToken);
            setLoading(false);
            return;
        }

        if(chosenTx === "loansPayments"){
            await adminClientLoanStatement(clientUsername, loginUser.jwtToken);
            setLoading(false);
            return;
        }
    }
    
    const[chooseQry, setChooseQry] = React.useState(false);
    const[chosenTx, setChosenTx] = React.useState("");
    const[qryInput, setQryInput] = React.useState(false);
    const[clientAccount, setClientAccount] = React.useState("");

    const handleCloseChooseQry = () =>{
        setChooseQry(false);
    };

    const closeQryInput = ()=>{
        setQryInput(false);
    };

    //Date choosing 
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    const[totalDate, setTotalDate] = React.useState(false);

    

    const handleFromDate = (newValue) =>{
        setFromDate(moment(newValue).format('YYYY-MM-DD'));
    };

    const handleToDate = (newValue) =>{
        setToDate(moment(newValue).format('YYYY-MM-DD'));
    };

    const closeTotalDate = () => {
        setTotalDate(false);
    }

    const handleTotalDate = () => {
        HandleAdminRequest(chosenTx);
        closeTotalDate();
    };

    //Logic to obtain the report for the GG

    const[totalDateFG, setTotalDateFG] = React.useState(false);
    const[toDateFG, setToDateFG] = React.useState("");
    const[fromDateFG, setFromDateFG] = React.useState("");

    const handleFGRequest = () =>{
        setTotalDateFG(true);
    };

    const closeTotalDateFG = () => {
        setTotalDateFG(false);
    };

    const handleFromDateFG = (newValue) => {
        setFromDateFG(moment(newValue).format('YYYY-MM-DD'));
    };

    const handleToDateFG = (newValue) => {
        setToDateFG(moment(newValue).format('YYYY-MM-DD'));
    };

    const handleTotalDateFG = () => {
        requestFGReport();
        closeTotalDateFG();
    };

    const requestFGReport = async () => {
        if(fromDateFG && toDateFG){
            const fgReportRequest ={
                fromDate: fromDateFG,
                toDate: toDateFG,
            }
            
            await adminFGReportDate(fgReportRequest, loginUser.jwtToken);
            setLoading(false);
            return;
        }
        else{
            await adminFGReport(loginUser.jwtToken);
            setLoading(false);
            return;
        }
    };
    
    return (
    
    <div
        style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "85%",
        textAlign: "center"
        }}
    >

    {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
    <>
    <List>
        <ListItem disablePadding>
            <ListItemButton onClick={() => handleRequest("users")} sx={{justifyContent: "center"}}>
                <ListItemText primary={"Funcionarios"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => handleRequest("accounts")} sx={{justifyContent: "center"}}>
                <ListItemText primary={"Tokens"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => handleRequest("transactions")} sx={{justifyContent: "center"}}>
                <ListItemText primary={"Interacciones"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => handleRequest("loans")} sx={{justifyContent: "center"}}>
                <ListItemText primary={"Adelantos"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
            </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
            <ListItemButton onClick={() => handleRequest("loansPayments")} sx={{justifyContent: "center"}}>
                <ListItemText primary={"Movimientos"} primaryTypographyProps={{variant: "body2", color: "GrayText"}}/>
            </ListItemButton>
        </ListItem>
    </List>      
    <React.Fragment>
        <Dialog open={chooseQry} onClose={handleCloseChooseQry} aria-labelledby="form-dialog-products">
        <DialogTitle style={{color: "blue"}}>TIPO DE CONSULTA</DialogTitle>
        <DialogContent className={classes.actionArea}>
        <Card sx={{ minWidth: 150 }}>
            <CardActionArea onClick={()=>handleTypeRequest("total")}>
            <BsCardChecklist size={30} />
            <CardContent>
                <Typography variant="h5" component="div">
                Total Información
                </Typography>
            </CardContent>
            </CardActionArea>
        </Card>
        </DialogContent>

        <DialogContent className={classes.actionArea}>
            <Card sx={{ minWidth: 150 }}>
            <CardActionArea onClick={()=>handleTypeRequest("client")}>
                <BsFillPersonFill size={30} />
                <CardContent>
                <Typography variant="h5" component="div">
                    Información Cliente
                </Typography>
                </CardContent>
            </CardActionArea>
            </Card>
        </DialogContent>
        </Dialog>
    </React.Fragment>

    <React.Fragment>
        <Dialog open={qryInput} onClose={closeQryInput} aria-labelledby="form-dialog-products">
        <DialogTitle id="form-dialog-title1">CLIENTE</DialogTitle>
        <DialogContent className={classes.actionArea}>
            <Card sx={{ minWidth: 150 }}>
                <TextField
                    autoFocus
                    // margin="dense"
                    id="account"
                    label="Usuario"
                    type="text"
                    fullWidth
                    onChange={e=>setClientAccount(e.target.value)}

                />

            </Card>
        </DialogContent>
        <DialogActions>
            <Button 
                onClick={handleClientQry} 
                color="primary"
            >
                Confirmar
            </Button>
        </DialogActions>
        </Dialog>
    </React.Fragment>

    <React.Fragment>
        <Dialog open={totalDate} onClose={closeTotalDate} aria-labelledby="form-dialog-products">
        <DialogTitle style={{color: "blue"}}>PERIODO CONSULTA</DialogTitle>
        <DialogContent className={classes.actionArea}>
            <Card sx={{ minWidth: 150 }}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Fecha desde"
                            inputFormat="MM/DD/YYYY"
                            value={fromDate}
                            onChange={handleFromDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Fecha hasta"
                            inputFormat="MM/DD/YYYY"
                            value={toDate}
                            onChange={handleToDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Card>
        </DialogContent>
        <DialogActions>
            <Button 
                onClick={handleTotalDate} 
                color="primary"
            >
                Confirmar
            </Button>
        </DialogActions>
        </Dialog>
    </React.Fragment>

    <React.Fragment>
        <Dialog open={totalDateFG} onClose={closeTotalDateFG} aria-labelledby="form-dialog-products">
        <DialogTitle style={{color: "blue"}}>PERIODO CONSULTA</DialogTitle>
        <DialogContent className={classes.actionArea}>
            <Card sx={{ minWidth: 150 }}>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Fecha desde"
                            inputFormat="MM/DD/YYYY"
                            value={fromDateFG}
                            onChange={handleFromDateFG}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                            label="Fecha hasta"
                            inputFormat="MM/DD/YYYY"
                            value={toDateFG}
                            onChange={handleToDateFG}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Card>
        </DialogContent>
        <DialogActions>
            <Button 
                onClick={handleTotalDateFG} 
                color="primary"
            >
                Confirmar
            </Button>
        </DialogActions>
        </Dialog>
    </React.Fragment>
    </>
    }

    </div>
  )
}
