import React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography, Button } from '@mui/material';
import swal from "sweetalert";
import { AiOutlineIdcard } from "react-icons/ai";
import { FaMobileAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { FaPiggyBank } from "react-icons/fa";
import { css } from "@emotion/react";
import TextField from '@mui/material/TextField';
import Colors from "../../../components/ui/Colors";
import { fetchSingleClientsInformation } from "../../../utilityFunctions/FetchUtil";
import { getClientPii, changeClientPii } from "../../../utilityFunctions/MarketPlaceUtil";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const piiChangeObject = {
    accountId: "",
    idNumber: "",
    phone: "",
    email: "",
};

export default function MarketClientData(props) {

    const {loginUser} = props;

    const[loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        getPii();
        setPiiInfo();
    }, []);

    const initialValues = () => {
        if(props.location.state && props.location.state.user){
          console.log(props.location.state.user);
          return props.location.state.user;
        }
    };

    const [profile, setProfile] = React.useState();

    const getPii = async () => {

        const clientData = await initialValues();
        console.log(clientData);
        const clientInfo = await fetchSingleClientsInformation(clientData.username, loginUser.jwtToken);

        const clientPii = await getClientPii(clientData.username, loginUser.jwtToken);
        piiChangeObject.accountId = clientData.username;

        // const clientInfo = clientData.clientPersonalInformation;

        if(!clientInfo){
            swal({
                title: "No hay datos",
                text: "El cliente no tiene datos registrados",
                icon: "info",
                button: "ok",
            });
            return; 
        };

        const bankAccounts = clientData.bankAccount;

        let bankAccount;
        let account;

        if(!bankAccounts){
            bankAccount=null;
        }
        
        else{
            account  = bankAccounts.find((el) => el.active === true && el.isEnableAdmin);

            if(!account){
                bankAccount="pending";
                account = bankAccounts.find((el) => el.active);
            }
        }

        console.log(bankAccount);
        console.log(account);
        
        setProfile(
            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Grid container direction="row" spacing={4}>
                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: {xs: "center", sm: "start"}}}> 
                        <Typography sx={{typography:{xs: "h5", sm: "h4"}}}>
                            {`${clientInfo.name} ${clientInfo.lastName}`}
                        </Typography>
                        <Grid container direction="row" spacing={4} style={{justifyContent: "start", marginTop: "1.1rem"}}> 
                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <AiOutlineIdcard size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.idNumber}`}
                                </Typography>   
                            </Grid>

                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <FaMobileAlt size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body1" color="text.secondary">
                                    {`${clientInfo.phone}`}
                                </Typography>   
                            </Grid>

                            <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                <HiOutlineMail size={20} />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                <Typography variant="body2" color="text.secondary">
                                    {`${clientInfo.email}`}
                                </Typography>   
                            </Grid>

                            {!bankAccount && !account ? <></>:
                            
                            (bankAccount === "pending") ?
                            <>
                                <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                    <FaPiggyBank size={20} />
                                </Grid>
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Typography variant="body2" color="text.secondary">
                                        {`Pendiente: ${account.bankAccountTypeString} - ${account.bankAccount}`}
                                    </Typography>   
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={2} sm={2} md={1} style={{display: "flex", justifyContent: "start"}}>
                                    <FaPiggyBank size={20} />
                                </Grid>
                                <Grid item xs={10} sm={10} md={11} style={{textAlign: "start"}}>
                                    <Typography variant="body2" color="text.secondary">
                                        {`${bankAccount.bankAccountTypeString} - ${bankAccount.bankAccount}`}
                                    </Typography>   
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
        setLoading(false);
    };

    const [idNumber, setIdNumber] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [email, setEmail] = React.useState("");

    const [piiInput, setPiiInput] = React.useState("");

    const setPiiInfo = async () => {

        const clientData = await initialValues();
        console.log(clientData);

        const clientPii = await getClientPii(clientData.username, loginUser.jwtToken);
        piiChangeObject.accountId = clientData.username;

        if(!clientPii){
            swal({
                title: "No hay datos",
                text: "El cliente no tiene datos registrados",
                icon: "info",
                button: "ok",
            });
            return; 
        };

        if(clientPii.idNumber){
            setIdNumber(clientPii.idNumber);
            piiChangeObject.idNumber=clientPii.idNumber;
        };

        if(clientPii.phone){
            setPhone(clientPii.phone);
            piiChangeObject.phone=clientPii.phone;
        }
        
        if(clientPii.email){
            setEmail(clientPii.email);
            // piiChangeObject.phone=clientPii.email;
        }

        renderPii();
    };

    const renderPii = async () => {

        setPiiInput(
            
        );


    }

    const idChange = (e) => {
        setIdNumber(e.target.value);
        piiChangeObject.idNumber = e.target.value;
    };

    const phoneChange = (e) => {
        setPhone(e.target.value);
        piiChangeObject.phone = e.target.value;
    };

    const emailChange = (e) => {
        setEmail(e.target.value);
        // piiChangeObject.email = e.target.value;
    };

    const submitModifications = async () => {
        if(!piiChangeObject.idNumber || !piiChangeObject.phone){
            swal({
                title: "Datos Incompletos",
                text: "El número de identificación y teléfono no pueden estar vacíos",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        const piiChanged = await changeClientPii(piiChangeObject, loginUser.jwtToken);

        if(!piiChanged){
            swal({
                title: "Cambio no realizado",
                text: "El cambio no se realizó. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Cambio Exitoso",
            icon: "success",
            button: "ok",
        });

    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="idNumber"
                            name="idNumber"
                            label={<Typography variant="body2">Número identificación</Typography>}
                            value={idNumber}
                            onChange={(e)=>idChange(e, "idNumber")}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="phone"
                            name="phone"
                            label={<Typography variant="body2">Celular</Typography>}
                            value={phone}
                            onChange={(e)=>phoneChange(e, "phone")}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            
                            variant="standard"
                            fullWidth={true}
                            id="email"
                            name="email"
                            label={<Typography variant="body2">Correo electrónico</Typography>}
                            value={email}
                            onChange={(e)=>emailChange(e, "email")}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1.5rem", display: "flex", justifyContent: "start"}}>
                        <Button onClick={submitModifications} sx={{backgroundColor: Colors.primary, color: "white", marginLeft: "1.5rem", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                            Cambiar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
