import {wupoServerUri} from '../Api';

const opClientLoans = (loanRequest, jwtToken) => {
    console.log(loanRequest);
    
    return(
      fetch(`${wupoServerUri.devnet}/api/operator/fetchclientloans`, {
          
          method: 'POST',
          body: JSON.stringify(loanRequest),
          headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
          }
          
        }).then((response) => { 
          // console.log(response.status) // DEBUG PRINTING
  
          if(response.status === 200){
            console.log(response.status);
            return response.json();
          }
          else{
              return "Error";
          }
    
      }).then((clientLoans) => {   
        console.log(clientLoans);    
        return(clientLoans);
            
      }).catch(function(error){
          console.log("Error msg: "+ error);
      })
    );
  
};

const opClientLoansByDate = (loanRequest, jwtToken) => {
  console.log(loanRequest);
  
  
  return(
    fetch(`${wupoServerUri.devnet}/api/operator/clientloansbydate`, {
        
        method: 'POST',
        body: JSON.stringify(loanRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.json();
        }
        else{
            return "Error";
        }
  
    }).then((clientLoans) => {   
      console.log(clientLoans);    
      return(clientLoans);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const opClientLoansStatements = (loanRequest, jwtToken) => {
  console.log(loanRequest);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/operator/fetchclientloansstatements`, {
        
        method: 'POST',
        body: JSON.stringify(loanRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.json();
        }
        else{
            return "Error";
        }
  
    }).then((clientLoans) => {   
        console.log(clientLoans);    
        return(clientLoans);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const opClientLoansStatementsDate = (loanRequest, jwtToken) => {
  console.log(loanRequest);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/operator/fetchclientloansstatementsdate`, {
        
        method: 'POST',
        body: JSON.stringify(loanRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.json();
        }
        else{
            return "Error";
        }
  
    }).then((clientLoans) => {   
        console.log(clientLoans);    
        return(clientLoans);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const opClientInfo = (loanRequest, jwtToken) => {
  console.log(loanRequest);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/operator/fetchclientinfo`, {
        
        method: 'POST',
        body: JSON.stringify(loanRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.json();
        }
        else{
            return "Error";
        }
  
    }).then((clientInfo) => {   
        console.log(clientInfo);
        return(clientInfo);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};
  
const opClientLoanStatementSCV = (loanRequest, jwtToken) => {
  console.log(loanRequest);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/operator/getaccountloansstatements`, {
        
        method: 'POST',
        body: JSON.stringify(loanRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
      
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "movimientos-creditos-cliente.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const opClientLoanStatementDateSCV = (loanRequest, jwtToken) => {
  console.log(loanRequest);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/operator/getaccountloansstatementsdate`, {
        
        method: 'POST',
        body: JSON.stringify(loanRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
      
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "movimientos-creditos-cliente.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const getLoanDocument = (documentRequest, jwtToken) => {
  
  console.log(documentRequest);
  
  return(
    fetch(`${wupoServerUri.devnet}/api/operator/loandocs`, {
        
        method: 'POST',
        body: JSON.stringify(documentRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
      
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "documento-client.pdf";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

  export {opClientLoans, opClientLoansStatements, opClientInfo, opClientLoanStatementSCV, getLoanDocument,
    opClientLoansByDate, opClientLoansStatementsDate, opClientLoanStatementDateSCV }