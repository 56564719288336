import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Button, Grid } from '@mui/material';
import { Calendar, momentLocalizer, Views} from 'react-big-calendar';
import moment from 'moment'; // Import Moment.js
import 'moment/locale/en-gb'; // Import the en-gb locale for Moment.js
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CustomCalendar.css'; // Import custom styles

// Set Moment.js to use the en-gb locale
moment.locale('en-gb');

const ScheduleDialog = ({ open, handleClose, events, defaultStarting }) => {
    
    const localizer = momentLocalizer(moment); // Use Moment.js localizer provided by react-big-calendar

    const { views } = React.useMemo(
        () => ({
        views: [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA],
        
        }),
        []
    );

    const handleSelect = (event) => {
        console.log(event);
        // console.log(start);
        // console.log(end);
    };

    const handleSelectSlot = (e) => {
        console.log(e);
        
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogContent>
                <Calendar
                    defaultDate={defaultStarting} // Specify defaultDate
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    views={views}
                    // toolbar={false}
                    // selectable={false}
                    showMultiDayTimes
                    defaultView={'week'}
                    onSelectSlot={handleSelectSlot} // Handler for slot selection
                        selectable={true} // Enable slot selection
                    onSelectEvent={handleSelect}
                />
            </DialogContent>
            <Grid container direction="row" spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                <Grid item xs={12} sm={6} md={6} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                    <Button variant="contained" onClick={handleClose}>
                        Back
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default ScheduleDialog;
