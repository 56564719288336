import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import NumberFormat from 'react-number-format';
import { updateLoanSettings} from "../../../utilityFunctions/CreditUtil";
import AppButton from "../../../components/ui/AppButton";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
    input: {
        width: "100%",
        height: "50%",
    },

    longLabels: {
        
    }

}));

const termUnits = [
    {
        value: "day",
        label: "Days"
    },
    {
        value: "week",
        label: "Weeks"
    },
    {
        value: "month",
        label: "Months"
    },
];

const paymentTypes = [
    {
        value: "prepaid",
        label: "Prepaid"
    },
    {
        value: "postpaid",
        label: "Postpaid"
    },
];

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let updatedLoanCostsSettings;
let updatedLoanConditionsSettings;

export default function LoanSettings(props) {
    const{loanCostsSettings, loanConditionsSettings, loginUser} = props
    
    let history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=>{
        updatedLoanCostsSettings = {...loanCostsSettings};
        console.log(updatedLoanCostsSettings)
        updatedLoanConditionsSettings = {...loanConditionsSettings};
        console.log(updatedLoanConditionsSettings)
    }, []);

    const[numberOfInstallments, setNumberOfInstallments] = React.useState('');
    const[termUnit, setTermUnit] = React.useState('');
    const[paymentType, setPaymentType] = React.useState('');
    const[termConditions, setTermConditions] = React.useState('');
    const[minTerm, setMinTerm] = React.useState('');
    const[maxTerm, setMaxTerm] = React.useState('');
    const[termInterval, setTermInterval] = React.useState('');
    const[minValue, setMinValue] = React.useState('');
    const[maxValue, setMaxValue] = React.useState('');
    const[balanceInterval, setBalanceInterval] = React.useState('');
    const[installments, setInstallments] = React.useState('');
    const[interestRate, setInterestRate] = React.useState('');
    const[costsWithTaxes, setCostsWithTaxes] = React.useState('');
    const[costsWithTaxesMinimum, setCostsWithTaxesMinimum] = React.useState('');
    const[costsWithoutTaxes, setCostsWithoutTaxes] = React.useState('');
    const[costsWithoutTaxesMinimum, setCostsWithoutTaxesMinimum] = React.useState('');
    const[taxesRate, setTaxesRate] = React.useState('');
    const[interestDefaultFactor, setInterestDefaultFactor] = React.useState('');
    const[collectionsFee, setCollectionsFee] = React.useState('');
    const[platformTimeDiscount, setPlatformTimeDiscount] = React.useState('');
    const[platformBalanceDiscount, setPlatformBalanceDiscount] = React.useState('');
    const[warrantyTimeDiscount, setWarrantyTimeDiscount] = React.useState('');
    const[warrantyBalanceDiscount, setWarrantyBalanceDiscount] = React.useState('');

    const handleInstallments = ()=>{

    };

    const handleTermUnit = (event)=>{
        setTermUnit(event.target.value)
        updatedLoanConditionsSettings.termUnit = event.target.value;
    };

    const handlePaymentType = (event)=>{
        setPaymentType(event.target.value)
        updatedLoanConditionsSettings.paymentType = event.target.value;
    };

    const handleTermConditions = (event, condition) =>{
        switch(condition){
            case "minTerm":
                setMinTerm(event.floatValue);
                updatedLoanConditionsSettings.minTerm = event.floatValue;
                break;
            case "maxTerm":
                setMaxTerm(event.floatValue);
                updatedLoanConditionsSettings.maxTerm = event.floatValue;
                break;
            case "termInterval": 
                setTermInterval(event.floatValue);
                updatedLoanConditionsSettings.termInterval = event.floatValue;
                break;
            case "minValue":
                setMinValue(event.floatValue);
                updatedLoanConditionsSettings.minValue = event.floatValue;
                break;
            case "maxValue":
                setMaxValue(event.floatValue);
                updatedLoanConditionsSettings.maxValue = event.floatValue;
                break;
            case "balanceInterval": 
                setBalanceInterval(event.floatValue);
                updatedLoanConditionsSettings.balanceInterval = event.floatValue;
                break;
            case "installments":
                setInstallments(event.floatValue);
                updatedLoanConditionsSettings.amountInstallments = event.floatValue;
                break;
            case "interestRate":
                setInterestRate(event.floatValue);
                updatedLoanCostsSettings.interestRate = event.floatValue/100;
                break;
            case "costsWithTaxes":
                setCostsWithTaxes(event.floatValue);
                updatedLoanCostsSettings.costsWithTaxes = event.floatValue;
                break;
            case "costsWithTaxesMinimum":
                setCostsWithTaxesMinimum(event.floatValue);
                updatedLoanCostsSettings.costsWithTaxesMinimum = event.floatValue/100;
                break;
            case "costsWithoutTaxes":
                setCostsWithoutTaxes(event.floatValue);
                updatedLoanCostsSettings.costsWithoutTaxes = event.floatValue/100;
                break;
            case "costsWithoutTaxesMinimum":
                setCostsWithoutTaxesMinimum(event.floatValue);
                updatedLoanCostsSettings.costsWithoutTaxesMinimum = event.floatValue/100;
                break;
            case "taxesRate":
                setTaxesRate(event.floatValue);
                updatedLoanCostsSettings.setTaxesRate = event.floatValue/100;
                break;
            case "interestDefaultFactor":
                setInterestDefaultFactor(event.floatValue);
                updatedLoanCostsSettings.interestDefaultFactor = event.floatValue/100;
                break;
            case "collectionsFee":
                setCollectionsFee(event.floatValue);
                updatedLoanCostsSettings.collectionsFee = event.floatValue;
                break;
            case "platformTimeDiscount":
                setPlatformTimeDiscount(event.floatValue);
                updatedLoanCostsSettings.platformTimeDiscount = event.floatValue/100;
                break;
            case "platformBalanceDiscount":
                setPlatformBalanceDiscount(event.floatValue);
                updatedLoanCostsSettings.platformBalanceDiscount = event.floatValue/100;
                break;
            case "warrantyTimeDiscount":
                setWarrantyTimeDiscount(event.floatValue);
                updatedLoanCostsSettings.warrantyTimeDiscount = event.floatValue/100;
                break;
            case "warrantyBalanceDiscount":
                setWarrantyBalanceDiscount(event.floatValue);
                updatedLoanCostsSettings.warrantyBalanceDiscount = event.floatValue/100;
                break;
            default:
                break;
        }
    };

    const submitModifications = async () =>{
        setLoading(true);
        const result = await updateLoanSettings(updatedLoanCostsSettings, updatedLoanConditionsSettings, loginUser.jwtToken);
        if(result === "ok"){

            swal({
                title: "Cambio Exitoso",
                icon: "success",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/handle_profile"});
            });
        }else{
            swal({
                title: "Cambio No Exitoso",
                icon: "error",
                button: "Entiendo",
            });
        }
        setLoading(false);
    };

    return (
    <>
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                height: "85%",
                textAlign: "center",
            }}
        >

        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" spacing={2} className={classes.gridInputs}>
                
                 {/* <Typography variant="h6" gutterBottom>
                        Configuración Condiciones y Costos
                    </Typography> */}

                {/*Loan conditions definitions*/}
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        id="termUnit"
                        name="termUnit"
                        select
                        label={<Typography variant="body2" className={classes.longLabels}>Unidad del periodo</Typography>}
                        value={termUnit}
                        onChange={handleTermUnit}
                        helperText="Selecciona la unida del periodo"
                    >
                        {termUnits.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        id="paymentType"
                        name="paymentType"
                        select
                        label={<Typography variant="body2" className={classes.longLabels}>Tipo de pago</Typography>}
                        value={paymentType}
                        onChange={handlePaymentType}
                        helperText="Selecciona la unida del periodo"
                    >
                        {paymentTypes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Periodo mínimo</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={minTerm}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "minTerm");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Periodo máximo</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={maxTerm}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "maxTerm");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Intervalo del periodo</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={termInterval}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "termInterval");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Monto máximo</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={maxValue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "maxValue");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Monto mínimo</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={minValue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "minValue");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Intervalo del monto</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={balanceInterval}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "balanceInterval");
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Cantidad de pagos</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={installments}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "installments");
                        }}
                    />
                </Grid>

                {/*Loan costs definitions*/}
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Interés Efectivo Anual (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={interestRate}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "interestRate");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Costo plataforma</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={costsWithTaxes}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "costsWithTaxes");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Mínimo costo plataforma (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={costsWithTaxesMinimum}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "costsWithTaxesMinimum");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Descuento plataforma plazo (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={platformTimeDiscount}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "platformTimeDiscount");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Descuento plataforma monto (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={platformBalanceDiscount}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "platformBalanceDiscount");
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Costo Aval (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={costsWithoutTaxes}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "costsWithoutTaxes");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        // label="Mínimo costos sin impuestos (%)"
                        label={<Typography variant="body2" className={classes.longLabels}>Mínimo costo Aval (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={costsWithoutTaxesMinimum}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "costsWithoutTaxesMinimum");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        // label="Mínimo costos sin impuestos (%)"
                        label={<Typography variant="body2" className={classes.longLabels}>Descuento Aval plazo (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={warrantyTimeDiscount}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "warrantyTimeDiscount");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        // label="Mínimo costos sin impuestos (%)"
                        label={<Typography variant="body2" className={classes.longLabels}>Descuento Aval monto (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={warrantyBalanceDiscount}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "warrantyBalanceDiscount");
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Tasa impuestos (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={taxesRate}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "taxesRate");
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Tasa de mora (%)</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={interestDefaultFactor}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "interestDefaultFactor");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        className={classes.input}
                        name={"formatNumber"}
                        customInput={TextField}
                        // prefix={'$ '}
                        // suffix={' %'}
                        type="text"
                        label={<Typography variant="body2" className={classes.longLabels}>Gastos cobranza</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete={"off"}
                        value={collectionsFee}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleTermConditions(values, "collectionsFee");
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <AppButton 
                        primary 
                        width={"45%"}
                        onClick={submitModifications}
                    >
                        LISTO
                    </AppButton>
                </Grid>
            </Grid>
        }
        </div>
    </>
    )
}
