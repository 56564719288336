import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { GiCow } from "react-icons/gi";
import { GiWeight } from "react-icons/gi";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import swal from "sweetalert";
import { useMediaQuery } from '@mui/material';
import CowIcon from './CowIcon';
import {getActiveProducts} from "../../utilityFunctions/MarketPlaceUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { makeStyles } from '@mui/styles';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/slickOverrides.css";
import { useHistory } from "react-router-dom";



const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let activeCattles;


export default function ProductsMarket(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: activeCattles ? Math.min(activeCattles.length, 2.5): 2.5, // Number of cards shown on each slide
        slidesToScroll: 1,
        swipeToSlide: true,
        draggable: true,
        // centerMode: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: activeCattles ? Math.min(activeCattles.length, 2.5): 2.5,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: activeCattles ? Math.min(activeCattles.length, 1.5): 1.5,
              arrows: false,
              dots: false,
            },
          },
        ],
    };

    const isLargeScreen = useMediaQuery('(min-width: 1024px)');
    const isMediumScreen = useMediaQuery('(max-width: 1023px) and (min-width: 600px)');
    const isSmallScreen = useMediaQuery('(max-width: 599px)');
  
    React.useEffect(() => {

        if(!activeCattles){
            return;
        }
        
        if (isLargeScreen) {
            // setSlidesToShow(Math.min(activeCattles.length, 3.5));
            renderProducts();
        } else if (isMediumScreen) {
            // setSlidesToShow(Math.min(activeCattles.length, 2.5));
            // setMidSlides(Math.min(activeCattles.length, 2.5));
            renderProducts();
        } else if (isSmallScreen) {
            // setSlidesToShow(Math.min(activeCattles.length, 1.5));
            // setSmallSlides(Math.min(activeCattles.length, 1.5));
            renderProducts();
        }
    }, [isLargeScreen, isMediumScreen, isSmallScreen]);

    React.useEffect(() => {
        setLoading(true);
        callActiveCattles();
    }, []);

    const sliderRef = React.useRef(null);

    const slide = (x, y) => {
      if (Math.abs(x) > Math.abs(y)) {
        x > 0 ? sliderRef.current.slickNext() : sliderRef.current.slickPrev();
      } else {
        y > 0 ? sliderRef.current.slickNext() : sliderRef.current.slickPrev();
      }
    };
  
    React.useEffect(() => {
      const handleWheel = (e) => {
        slide(e.deltaX, e.deltaY);
      };
  
      window.addEventListener('wheel', handleWheel);
  
      return () => {
        window.removeEventListener('wheel', handleWheel);
      };
    }, []);

    const [products, setProducts] = React.useState();


    const callActiveCattles = async () => {

        activeCattles = await getActiveProducts(loginUser.jwtToken);

        if(!activeCattles){
            setLoading(false);
            swal({
                title: "Información",
                text: "En este momento no tenemos lotes en venta.",
                icon: "info",
                button: "Entiendo",
            });
            return;
        }

        activeCattles = await sortCattle(activeCattles);

        settings.slidesToShow = Math.min(activeCattles.length, settings.slidesToShow);
        settings.responsive[0].settings.slidesToShow = Math.min(activeCattles.length, 2.5);
        settings.responsive[1].settings.slidesToShow = Math.min(activeCattles.length, 1.5);

        setLoading(false);

        renderProducts();
    };

    const renderProducts = () => {

        console.log(settings);
        setProducts(
            <Slider {...settings} ref={sliderRef} style={{width: "100%"}}>
            {
            activeCattles.map((cattle) => {
                return(
                    
                        <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <Card key={generateRandomKey()} onClick={() => productDetail(cattle)} sx={{position: "relative", width: {xs: "95%", sm: "90%"}, cursor: "pointer", maxWidth: "550px", height:{xs: "495px", sm: "546px", md: "560px"}, borderRadius: "25px", marginBottom: {xs: "1rem", sm: "0"}}}>
                                <Grid key={generateRandomKey()} container direction = "column">
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Box 
                                            component="img"
                                            sx={{
                                            height: "90%",
                                            maxHeight: "230px",
                                            width: "100%",
                                            justifyContent: "center",
                                            }}
                                            alt="Foto"
                                            src={URL.createObjectURL(cattle.cattleLotPic.cattlePic)}

                                        >
                                        </Box>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "1rem"}}>
                                        <Grid container key={generateRandomKey()} sx={{width: "90%"}}>
                                            <Grid key={generateRandomKey()} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "0.2rem"}}>
                                                <Typography key={generateRandomKey()}  variant="h6" sx={{fontWeight: "bold", width: "100%", color: "#25d366"}}>{cattle.code}</Typography>
                                            </Grid>

                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <TrendingUpOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Rentabilidad Esperada:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.79rem", sm: "0.875rem"}}}>{`${cattle.expectedRevenue*100}% E.A.`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <CalendarMonthIcon fontSize="small" sx={{color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Duración:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.79rem", sm: "0.875rem"}}}>{`${cattle.duration} meses`}</Typography>
                                            </Grid>
                                            
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <CowIcon style={{fontSize: "18px"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Cabezas de Ganado:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.79rem", sm: "0.875rem"}}}>{`${new Intl.NumberFormat('es-CO').format(cattle.animalsAmount)}`}</Typography>
                                            </Grid>

                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <GiWeight style={{fontSize: "18px", color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={cattle.id+9} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Peso Total:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.79rem", sm: "0.875rem"}}}>{`${new Intl.NumberFormat('es-CO').format(cattle.totalWeight)} Kg`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <CowIcon style={{fontSize: "18px"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Raza:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.67rem", sm: "0.875rem"}}}>{`${cattle.breed}`}</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <LocationOnOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}, fontSize: {xs: "0.8rem", sm: "0.875rem"}}}>Ubicación:</Typography>
                                            </Grid>
                                            <Grid key={generateRandomKey()} item xs={5} sm={5} md={5} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                                <Typography key={generateRandomKey()} variant="body2" sx={{ width: "100%", fontSize: {xs: "0.67rem", sm: "0.875rem"}}}>{`${cattle.location}`}</Typography>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}> */}
                                                <Button onClick={() => productDetail(cattle)} variant="contained" sx={{position: "absolute", left: "50%", transform: 'translateX(-50%)', bottom: "1rem", backgroundColor: Colors.primary}}>
                                                    Comprar
                                                </Button>
                                            {/* </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>   
                        </Grid>
                    
                    )
            })
            }
            </Slider>
        );
    };

    const sortCattle = async (cattleArray) => {
        cattleArray.sort((a,b) => {
            
            // Sort based on "name" property
            const codeA = a.code.toUpperCase();
            const codeB = b.code.toUpperCase();

            if (codeA < codeB) {
                return -1;
            } else if (codeA > codeB) {
                return 1;
            } else {
                return 0;
            }
        });

        return cattleArray;
    }

    const productDetail = (cattle) => {
        console.log(cattle);

        history.push({ 
            pathname: "/selling/productDetail",
            state:{
                product: cattle,
            }
        });

    };

    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return(
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#f6f6f6"}}>
            <Box
                sx={{
                    display: "flex",
                    flexGrow: "1",
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "100%",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container spacing={3} sx={{ width: {sm: "95%"}, height: "100%", alignItems: {xs: "start", sm: "center"}, justifyContent: "center", marginTop:{xs: "15%", sm: "0.5rem", md: 0}, marginBottom: {xs: "3rem", sm: "2rem", md: "1.6rem"}, paddingLeft: {xs: "10%", sm: "0%"}}}>
                        {products}
                </Grid>
            }
            </Box>
        </Box>
    )
}
