import React from 'react';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { BsFillPersonFill } from "react-icons/bs";
import { GiTakeMyMoney } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import {opClientLoans, opClientLoansStatements, opClientInfo, 
    opClientLoanStatementSCV, opClientLoansByDate, opClientLoansStatementsDate, 
    opClientLoanStatementDateSCV} from "../../utilityFunctions/OperatorUtil";
import Colors from "../../components/ui/Colors"; 
import { useHistory } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const useStyles = makeStyles((theme) => ({

    gridProducts:{
        maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },

    div:{
        color: Colors.primary,
        cursor: "pointer",
    },

    actionArea: {
        borderRadius: 16,
        transition: '0.2s',
        // '&:hover': {
        //   transform: 'scale(1.1)',
        //   backgroundColor: Colors.primary,
        // },
    },

    cardImage:{
        width: "20%",
        justifySelf: "center"
    },

}));

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const idTypes = [
    {
        value: "nationalId",
        label: "Cédula"
    },
    {
        value: "passport",
        label: "Pasaporte"
    },
    {
        value: "nit",
        label: "NIT"
    },
];

let csvChecked;

export default function OperatorRole(props) {
    const{loginUser} = props

    const history = useHistory();

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    const[clientAccount, setClientAccount] = React.useState("");
    const[idType, setIdType] = React.useState();
    const[idNumber, setIdNumber] = React.useState();
    const [fromDate, setFromDate] = React.useState("");
    const [toDate, setToDate] = React.useState("");
    const[qryInput, setQryInput] = React.useState(false);
    const[chosenTx, setChosenTx] = React.useState("");

    const handleRequest = async (request)=>{
        setChosenTx(request);
        setQryInput(true);
    };

    const handleClientQry = async () =>{
        setLoading(true);
        closeQryInput();

        if(chosenTx === "users"){
            const loanRequest ={
                account: clientAccount,
                idType: idType,
                idNumber: idNumber,
            }
            const clientInfo = await opClientInfo(loanRequest, loginUser.jwtToken);
            history.push({
                pathname: "/operator-info",
                state: {
                    clientInfo: clientInfo,
                }
            });
            setLoading(false);
            return;
        }

        if(chosenTx === "loans"){
            console.log(fromDate)
            console.log(toDate)
            if(fromDate && toDate){
                const loanRequest ={
                    account: clientAccount,
                    idType: idType,
                    idNumber: idNumber,
                    fromDate: fromDate,
                    toDate: toDate,
                }
                const loansInfo = await opClientLoansByDate(loanRequest, loginUser.jwtToken);
                history.push({
                    pathname: "/operator-loans",
                    state: {
                        loansInfo: loansInfo,
                    }
                }); 
                setLoading(false);
                return;
            }
            else{
                const loanRequest ={
                    account: clientAccount,
                    idType: idType,
                    idNumber: idNumber,
                }
                const loansInfo = await opClientLoans(loanRequest, loginUser.jwtToken);
                history.push({
                    pathname: "/operator-loans",
                    state: {
                        loansInfo: loansInfo,
                    }
                });
                setLoading(false);
                return;
            }
        }

        if(csvChecked && chosenTx === "loansPayments"){
            if(fromDate && toDate){
                const loanRequest ={
                    account: clientAccount,
                    idType: idType,
                    idNumber: idNumber,
                    fromDate: fromDate,
                    toDate: toDate,
                }

                await opClientLoanStatementDateSCV(loanRequest, loginUser.jwtToken);
                setLoading(false);
                return;
            }

            else{
                const loanRequest ={
                    account: clientAccount,
                    idType: idType,
                    idNumber: idNumber,
                }
                await opClientLoanStatementSCV(loanRequest, loginUser.jwtToken);
                setLoading(false);
                return;
            }
        }

        if(!csvChecked && chosenTx === "loansPayments"){
            if(fromDate && toDate){
                const loanRequest ={
                    account: clientAccount,
                    idType: idType,
                    idNumber: idNumber,
                    fromDate: fromDate,
                    toDate: toDate,
                }
                const statementsInfo = await opClientLoansStatementsDate(loanRequest, loginUser.jwtToken);
                history.push({
                    pathname: "/operator-statements",
                    state: {
                        statementsInfo: statementsInfo,
                    }
                }); 
                setLoading(false);
                return;
            }
            else{
                const loanRequest ={
                    account: clientAccount,
                    idType: idType,
                    idNumber: idNumber,
                }
                const statementsInfo = await opClientLoansStatements(loanRequest, loginUser.jwtToken);
                history.push({
                    pathname: "/operator-statements",
                    state: {
                        statementsInfo: statementsInfo,
                    }
                });
                setLoading(false);
                return;
            }
        }
    }

    const closeQryInput = ()=>{
        setQryInput(false);
    };

    const handleFromDate = (newValue) =>{
        setFromDate(moment(newValue).format('YYYY-MM-DD'));
    };

    const handleToDate = (newValue) =>{
        setToDate(moment(newValue).format('YYYY-MM-DD'));
    };

    const handleCSV = (e) => {
        csvChecked = e.target.checked;
        console.log(csvChecked);
    } 

    return (
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "85%",
            textAlign: "center"
        }}
    >
        {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" spacing={4} className={classes.gridProducts}>
                <Grid item xs={4} sm={4} md={4} className={classes.gridProducts}>
                    <Typography style={{fontWeight:"bolder"}}>Clientes</Typography>
                    <div className={classes.div} onClick={() => handleRequest("users")}>    
                        <BsFillPersonFill size={70} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} className={classes.gridProducts}>
                    <Typography style={{fontWeight:"bolder"}}>Créditos</Typography>
                    <div className={classes.div} onClick={() => handleRequest("loans")}> 
                        <GiTakeMyMoney size={70} />
                    </div>
                </Grid>
                <Grid item xs={6} sm={6} md={6} className={classes.gridProducts}>
                    <Typography style={{fontWeight:"bolder"}}>Movimientos Créditos</Typography>
                    <div className={classes.div} onClick={() => handleRequest("loansPayments")}>
                        <BiTransfer size={70} />
                    </div>
                </Grid>
            </Grid>
        }
        <React.Fragment>
            <Dialog open={qryInput} onClose={closeQryInput} aria-labelledby="form-dialog-products">
            <DialogTitle id="form-dialog-title1">CLIENTE</DialogTitle>
            <DialogContent className={classes.actionArea}>
                <Card sx={{ minWidth: 150 }}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                // margin="dense"
                                value={clientAccount}
                                id="account"
                                label="Usuario"
                                type="text"
                                fullWidth
                                onChange={e=>setClientAccount(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth={true}
                                name="idType"
                                select
                                label={<Typography variant="body2" className={classes.longLabels}>Tipo ID</Typography>}
                                value={idType}
                                onChange={e=>setIdType(e.target.value)}
                            >
                                {idTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                autoFocus
                                // margin="dense"
                                value={idNumber}
                                label="Número ID"
                                type="text"
                                fullWidth
                                onChange={e=>setIdNumber(e.target.value)}
                            />
                        </Grid>
                        {chosenTx !== "users" ?
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="Fecha desde"
                                        inputFormat="MM/DD/YYYY"
                                        value={fromDate}
                                        onChange={handleFromDate}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DesktopDatePicker
                                        label="Fecha hasta"
                                        inputFormat="MM/DD/YYYY"
                                        value={toDate}
                                        onChange={handleToDate}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    </LocalizationProvider>
                                </Grid>
                            </>
                            : <></>
                        }
                        { chosenTx === "loansPayments" ?
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControlLabel
                                    control={<Checkbox color="secondary" name="csvCheck" value="yes" />}
                                    label="Archivo CSV"
                                    onChange={handleCSV}
                                />
                            </Grid>
                            : <></>
                        }
                    </Grid>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={handleClientQry} 
                    color="primary"
                >
                    Confirmar
                </Button>
            </DialogActions>
            </Dialog>
        </React.Fragment>
    </div>
    )
}
