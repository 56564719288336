import React from 'react';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import swal from "sweetalert";
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllDeliveries, pqrDelivery } from "../../utilityFunctions/DeliveryUtil";
import Autocomplete from '@mui/material/Autocomplete';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let deliveryReq={
    id: "",
    pqrCode: "",
};

export default function PqrDelivary(props) {

    const {loginUser} = props 

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        deliveryReq={
            id: "",
            pqrCode: "",
        };

        loadDeliveries();

    }, []);

    const [pqrCode, setPqrCode] = React.useState("");
    const [deliveriesCode, setDeliveriesCode] = React.useState("");

    const loadDeliveries = async () => {
        const ordersData = await getAllDeliveries(loginUser.jwtToken);

        setDeliveriesCode(
            {
                options: ordersData,
                getOptionLabel: (option) => option.id,
            }
        );
    };

    const handleChange = (event, definition) => {
        switch(definition){
            case "pqrCode":
                deliveryReq.pqrCode = event.target.value;
                setPqrCode(event.target.value);
                break;

            default:
                break;
        }
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        deliveryReq.id=value.id;
    };

    const submitModifications = async () =>{
        setLoading(true);
        const result = await pqrDelivery(deliveryReq, loginUser.jwtToken);
        if(result !== "Not found"){

            swal({
                title: "Creación Exitosa",
                icon: "success",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/lastmile/board"});
            });
        }else{
            swal({
                title: "Creación No Exitosa",
                icon: "error",
                button: "Entiendo",
            });
        }
        setLoading(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                            {...deliveriesCode}
                            id="ids"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Guía no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeAutocomplete}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Número guías" variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="pqrCode"
                            name="pqrCode"
                            label={<Typography variant="body2">Código PQR</Typography>}
                            value={pqrCode}
                            onChange={(e)=>handleChange(e, "pqrCode")}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "3.5rem"}}>
                        <Button onClick={submitModifications} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                            CREAR
                        </Button>
                    </Grid>
                </Grid>
                }
            </Box>
        </Box>
    )
}
