import React from 'react';
import { useCSVReader } from 'react-papaparse';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Colors from "../../components/ui/Colors";
import { Typography } from '@mui/material';
import swal from 'sweetalert';
import Card from '@mui/material/Card';
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import { createDeliveries, createProblems } from "../../utilityFunctions/DeliveryUtil";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let uploadFile;
let dataArray = [];

const deliveryKeys = ["status", "stringOrderDate", "address", "clientId", "clientPhone", "clientName", "clientEmail", "orderValue"];
const problemKeys = ["stringDate", "problemType", "code"];

export default function UploadDeliveries(props) {

    const {loginUser} = props

    const [loading, setLoading] = React.useState(false);

    const { CSVReader } = useCSVReader();

    const uploadDeliveries = async () => {

        setLoading(true);

        dataArray = [];

        uploadFile.data.forEach((related) => {
            const element = related.reduce((acc, value, index) => {
                return {...acc, [deliveryKeys[index]]: value}
            }, {});

            dataArray.push(element);

        });

        console.log(dataArray);

        const productsResult = await createDeliveries(dataArray, loginUser.jwtToken);

        setLoading(false);
        
        if(productsResult !== "Not found"){
            swal({
                title: "LISTO",
                icon: "success",
                buttons: "OK"
            });
        };
    };

    const uploadProblems = async () => {

        setLoading(true);

        dataArray = [];

        uploadFile.data.forEach((related) => {
            const element = related.reduce((acc, value, index) => {
                return {...acc, [problemKeys[index]]: value}
            }, {});

            dataArray.push(element);

        });

        console.log(dataArray);

        const productsResult = await createProblems(dataArray, loginUser.jwtToken);

        setLoading(false);
        
        if(productsResult !== "Not found"){
            swal({
                title: "LISTO",
                icon: "success",
                buttons: "OK"
            });
        };
    };

    React.useEffect(()=> {
        dataArray = [];
    }, []);
    return (
    <Box
        sx={{
        display: "flex",
        flexGrow: "1",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        minWidth: "20rem",
        backgroundColor: "#ededed",
        }}
        >
        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container sx={{height: "100%", width: "100%",}}>
                <Grid xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
                    <Card sx={{height: "60%", width: "40%", display: "flex", alignItems: "center",}}>
                        <Grid container direction="column" sx={{dispaly: "flex", alignItems: "center", marginTop:"0.2em", marginBottom: "0.2em"}}>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", marginBottom: "3rem"}}>
                                <Typography varian="h1" sx={{color: Colors.labelColor, textAlign: "center",}}>
                                    Selecciona un archivo
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{height: "100%", width: "80%", display: "flex", alignItems: "space-evenly", justifyContent: "space-evenly"}}>
                                    <CSVReader
                                    onUploadAccepted={(results) => {
                                        console.log(results);
                                        uploadFile = results;
                                    }}
                                    >
                                    {({
                                            getRootProps,
                                            acceptedFile,
                                            ProgressBar,
                                            getRemoveFileProps,
                                        }) => (
                                        <>
                                        <Grid container direction = "column" sx={{justifyContent: "space-evenly", width: "100%", height: "100%"}}>
                                            <Grid item xs={12} sm={12} md={12} sx={{marginBottom: "2rem"}}>
                                                <Box sx={{display: 'flex', justifyContent: "center"}}>
                                                    <Typography sx={{border: '0.3px solid #ccc', height: 40, lineHeight: 2, width: '80%',}}>
                                                        {acceptedFile && acceptedFile.name}
                                                    </Typography>
                                                </Box>
                                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>    
                                                <ProgressBar style={{backgroundColor: Colors.primary, marginTop: "0.4rem", height: "0.2rem", maxWidth: "20rem"}} />
                                            </Grid>
                                            </Grid>
                                            <Grid item  xs={12} sm={12} md={12} sx={{display: 'flex', alignItems: "center", justifyContent: "center",}}>
                                                <Box sx={{ width: "50%"}}>
                                                    <Button type='button' {...getRootProps()} sx={{width: '100%', backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                                                        Explorar
                                                    </Button>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} sx={{}}>
                                                <Box sx={{display: 'flex', textAlign: "center", justifyContent: "center", marginTop: "1.5rem"}}>
                                                    <Button variant="text" {...getRemoveFileProps()} sx={{width: '100%',backgroundColor: "none", color: "GrayText", fontSize: "12px", '&.MuiButton-root:hover':{bgcolor: "transparent"}}}>
                                                        Borrar
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        </>
                                    )}
                                    </CSVReader>
                                </Grid>
                                <Grid xs={12} sm={12} md={12} sx={{display:"flex", justifyContent: "start", alignItems: "center", marginTop: "1.5rem"}}>
                                    <Grid container direction="row">
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <Button variant="text"
                                                    onClick={uploadDeliveries}
                                                    sx={{backgroundColor: "none", color: "black", fontSize: "0.8rem", fontWeight: "bold"}}
                                                >
                                                    Órdenes  
                                                </Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6}>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                height="100%"
                                            >
                                                <Button variant="text"
                                                    onClick={uploadProblems}
                                                    sx={{backgroundColor: "none", color: "black", fontSize: "0.8rem", fontWeight: "bold"}}
                                                >
                                                    Retrasos  
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        }
    </Box>
    )
}
