import {wupoServerUri} from '../Api';

const adminAllStatements = (jwtToken) => {

    return(
      fetch(`${wupoServerUri.devnet}/api/admin/getalltransactions`, {
          
          method: 'GET',
          // body: JSON.stringify(creditObject),
          headers: {
            'Authorization': `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json'                     
          }
          
        }).then((response) => { 
          // console.log(response.status) // DEBUG PRINTING

          if(response.status === 200){
            console.log(response.status);
            return response.blob();
          }
          else{
              return "Error";
          }
      
        }).then((blob) => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "transacciones.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again      
            // return(loanCreated);
            
      }).catch(function(error){
          console.log("Error msg: "+ error);
      })
    );

};

const adminClientStatements = (clientAccount, jwtToken) => {
  console.log(clientAccount);

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getaccounttransactions`, {
        
        method: 'POST',
        body: JSON.stringify(clientAccount),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "transacciones-cliente.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminAllLoans = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getallloans`, {
        
        method: 'GET',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'Content-Disposition',           
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
            console.log(response.status);
            return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "creditos.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminAllLoansDate = (loansRequest, jwtToken) => {
  console.log(loansRequest);
  return(
    fetch(`${wupoServerUri.devnet}/api/admin/allloansdate`, {
        
        method: 'POST',
        body: JSON.stringify(loansRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json',           
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
            console.log(response.status);
            return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "creditos.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminClientLoans = (clientAccount, jwtToken) => {
  console.log(clientAccount);

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getaccountloans`, {
        
        method: 'POST',
        body: JSON.stringify(clientAccount),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
  
    }).then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "creditos-cliente.csv";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();    
        a.remove();  //afterwards we remove the element again      
        // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminAllLoansStatements = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getallloansstatements`, {
        
        method: 'GET',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "movimientos-creditos.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminAllLoansStatementsDate = (loanRequest, jwtToken) => {
  console.log(loanRequest);
  return(
    fetch(`${wupoServerUri.devnet}/api/admin/allloansstatementsdate`, {
        
        method: 'POST',
        body: JSON.stringify(loanRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "movimientos-creditos.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};


const adminClientLoanStatement = (clientAccount, jwtToken) => {
  console.log(clientAccount);

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getaccountloansstatements`, {
        
        method: 'POST',
        body: JSON.stringify(clientAccount),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
      
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "movimientos-creditos-cliente.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminAllClientsInformation = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getallclientsinformation`, {
        
        method: 'GET',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "informacion-clientes.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminAllClientsInformationDate = (clientRequest, jwtToken) => {
  console.log(clientRequest);
  return(
    fetch(`${wupoServerUri.devnet}/api/admin/allclientsinformationdate`, {
        
        method: 'POST',
        body: JSON.stringify(clientRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "informacion-clientes.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminSingleClientsInformation = (clientAccount, jwtToken) => {
  console.log(clientAccount);

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getsingleclientinformation`, {
        
        method: 'POST',
        body: JSON.stringify(clientAccount),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
      
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "informacion-cliente-individual.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const xrpl = require("xrpl");

const updateBalances = (wupoBalance, jwtToken) => {
    console.log(wupoBalance);
    wupoBalance.forEach((account, index) => {
        if(account.counterparty != null){
            wupoBalance[index].counterparty = xrpl.classicAddressToXAddress(account.counterparty, false, true);
        }
    });
    console.log(wupoBalance);
    return(
        fetch(`${wupoServerUri.devnet}/api/secure/ledger/accounts/balances`, {

            method: 'POST',
            body: JSON.stringify(wupoBalance),
            headers: {
                "Authorization": `Bearer ${jwtToken}`,
                'Content-Type': 'application/json'                   
            },

        }).then((response) => {
            
            console.log(response.status);
            return response.text();

        }).then(function(body){
            // console.log(body);
            return body;
            
        }).catch(function(error){
            console.log("Error msg: "+ error);
        })
    );
}

const adminAllAccounts = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getallaccounts`, {
        
        method: 'GET',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "total-cuentas.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminClientAccounts = (clientAccount, jwtToken) => {
  console.log(clientAccount);

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/getclientaccounts`, {
        
        method: 'POST',
        body: JSON.stringify(clientAccount),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.blob();
        }
        else{
            return "Error";
        }
      
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "cuentas-cliente.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminKpiDisbursement = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/disbursementreport`, {
        
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          return response.json();
        }
        else{
            return "Error";
        }
    
      }).then((allLoans) => {
          console.log(allLoans);
          return(allLoans);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminKpiPortfolio = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/portfolioreport`, {
        
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          return response.json();
        }
        else{
            return "Error";
        }
    
      }).then((allLoans) => {
          console.log(allLoans);
          return(allLoans);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminAllDefaultLoans = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/alldefaultloans`, {
        
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          return response.json();
        }
        else{
            return "Error";
        }
    
      }).then((allLoans) => {
          console.log(allLoans);
          return(allLoans);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminDefaultLoansByAging = (aging, jwtToken) => {
  
  const defaultLoansRequest = {
    defaultAging: aging
  };
  console.log(defaultLoansRequest);

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/defaultloansbyaging`, {
        
        method: 'POST',
        body: JSON.stringify(defaultLoansRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          console.log(response.status);
          return response.json();
        }
        else{
            return "Error";
        }
      
      }).then((defaultLoans) => {
        console.log(defaultLoans);
        return(defaultLoans);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const updateExplanations = (username, message) => {
  const explanationsRequest = {
    account: username,
    explanationName: message,
  }

  console.log(explanationsRequest);

  return(
    fetch(`${wupoServerUri.devnet}/api/auth/explanationsupdate`, {
        
        method: 'POST',
        body: JSON.stringify(explanationsRequest),
        headers: {
          // 'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          return response.json();
        }
        else{
            return "Error";
        }
      
      }).then((explanations) => {
        console.log(explanations);
        return(explanations);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const fetchExplanations = (username) => {
  const explanationsRequest = {
    account: username,
    explanationName: "",
  }

  console.log(explanationsRequest);

  return(
    fetch(`${wupoServerUri.devnet}/api/auth/explanations`, {
        
        method: 'POST',
        body: JSON.stringify(explanationsRequest),
        headers: {
          // 'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json'                     
        }
        
      }).then((response) => { 
        console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
          return response.json();
        }
        else{
            return "Error";
        }
      
      }).then((explanations) => {
        console.log(explanations);
        return(explanations);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminFGReport = (jwtToken) => {

  return(
    fetch(`${wupoServerUri.devnet}/api/admin/fgreport`, {
        
        method: 'GET',
        // body: JSON.stringify(creditObject),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'Content-Disposition',           
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
            console.log(response.status);
            return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "reporte-FG.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

const adminFGReportDate = (fgRequest, jwtToken) => {
  console.log(fgRequest);
  return(
    fetch(`${wupoServerUri.devnet}/api/admin/fgreportdate`, {
        
        method: 'POST',
        body: JSON.stringify(fgRequest),
        headers: {
          'Authorization': `Bearer ${jwtToken}`, 
          'Content-Type': 'application/json',           
        }
        
      }).then((response) => { 
        // console.log(response.status) // DEBUG PRINTING

        if(response.status === 200){
            console.log(response.status);
            return response.blob();
        }
        else{
            return "Error";
        }
    
      }).then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "reporte-FG.csv";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again      
          // return(loanCreated);
          
    }).catch(function(error){
        console.log("Error msg: "+ error);
    })
  );

};

export{adminAllStatements, adminClientStatements, adminAllLoans, adminClientLoans,
  adminAllLoansStatements, adminClientLoanStatement, adminAllClientsInformation,
  adminSingleClientsInformation, updateBalances, adminAllAccounts, adminClientAccounts,
  adminKpiDisbursement, adminKpiPortfolio, adminAllDefaultLoans, adminDefaultLoansByAging,
  fetchExplanations, updateExplanations, adminAllLoansDate, adminAllLoansStatementsDate,
  adminAllClientsInformationDate, adminFGReportDate, adminFGReport}