import React from 'react';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors"; 
import { getAllDeliveries, getAllProblems } from "../../utilityFunctions/DeliveryUtil";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell, LabelList, Label} from 'recharts';
import Box from '@mui/material/Box';
import { Typography, Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { FaFilter } from "react-icons/fa";
import { IconContext } from "react-icons";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import { useHistory} from "react-router-dom";


const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 2 1'><rect fill='#77aa77' width='2' height='1'/><defs><linearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'><stop offset='0'  stop-color='#77aa77'/><stop offset='1'  stop-color='#4fd'/></linearGradient><linearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'><stop offset='0'  stop-color='#cf8' stop-opacity='0'/><stop offset='1'  stop-color='#cf8' stop-opacity='1'/></linearGradient><linearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'><stop  offset='0' stop-color='#cf8' stop-opacity='0'/><stop  offset='1' stop-color='#cf8' stop-opacity='1'/></linearGradient></defs><rect x='0' y='0' fill='url(#a)' width='2' height='1'/><g fill-opacity='0.5'><polygon fill='url(#b)' points='0 1 0 0 2 0'/><polygon fill='url(#c)' points='2 1 2 0 0 0'/></g></svg>`;

const useStyles = makeStyles({
  svgBackground: {
    background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
  },
});

const dateOptions = {
  // weekday: "short",
  day: "numeric",
  month: "short",
  year: "numeric",
};

let ordersData;
let delaysData;

let filterOrderData;


const expectedTime = 5;

const statusLabel = [
  {
    status: "noFilter",
    label: "Todas",
  },
  {
    status: "created",
    label: "En almacén"
  },
  {
    status: "recolected",
    label: "Recogido"
  },
  {
    status: "transit",
    label: "En tránsito"
  },
  {
    status: "delivered",
    label: "Entregado"
  },
  {
    status: "problem",
    label: "Problema"
  },
];

const problemLabel =[
  {
    problemType: "Cliente ausente",
    label: "Cliente ausente"
  },
  {
    problemType: "Extravío",
    label: "Extravío"
  },
  {
    problemType: "Alta demanda",
    label: "Alta demanda"
  },
];

const COLORS = ['#0088FE', '#00C49F', '#a020f0', '#FF5F1F'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = 8 + cx + radius * Math.cos(-midAngle * RADIAN);
  const y = 3 + cy + radius * Math.sin(-midAngle * RADIAN);
  console.log(x)
  console.log(y)
  return (
    
    <text x={x} y={y} fill="white"  style={{fontSize: "0.7rem"}}>
      {`(${(percent * 100).toFixed(0)}%)`}
    </text>

  );
};

const renderLabels = (piece) => {
  return (
    piece.name
  );
};

export default function LogisticsBoard(props) {

  const {loginUser} = props 

  const history = useHistory();

  const classes = useStyles();

  React.useEffect(() => {

    deliveryData();

  }, []);

  const deliveryData = async () => {

    ordersData = await getAllDeliveries(loginUser.jwtToken);

    filterOrderData = ordersData;

    delaysData = await getAllProblems(loginUser.jwtToken);

    calculateStatus();

    calculateProblems();

    renderList();

    calculateTimes();
    
  };


  const calculateTimes = () => {
    const deliveredArray = ordersData.filter((el) => el.status === "delivered");

    console.log(deliveredArray);

    let onTime = 0;
    let withDelay = 0;
    let accTime = 0;

    deliveredArray.forEach((el) => {
      if(el.deliveryDate){
        const differenceDays = daysBetween(el.orderDate, el.deliveryDate);
        console.log(el.id);
        console.log(differenceDays);
        accTime += differenceDays;

        if(differenceDays > expectedTime){
          withDelay++;
        }else{
          onTime++;
        }
      }
    });

    const timeData = [
      {
        name: "A tiempo",
        value: onTime
      },
      {
        name: "Retrasado",
        value: withDelay
      },
    ];
    
    console.log(timeData);

    renderTimes(timeData);

  };

  const treatAsUTC = (date) => {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  }

  const daysBetween = (startDate, endDate) => {
      var millisecondsPerDay = 24 * 60 * 60 * 1000;
      return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
  }

  const [duration, setDuration] = React.useState("");

  const renderTimes = (graphData) => {
    setDuration(
        <PieChart width={600} height={200}>
          <Legend layout="vertical" verticalAlign="middle" 
            formatter={(value, entry, index) => <span style={{color: "black", fontSize: "0.8rem", marginLeft: "0.2rem"}}>{value}</span>}
          />
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={graphData}
            cx={140}
            cy={100}
            outerRadius={80}
            fill="#8884d8"
            labelLine={false}
            label={renderCustomizedLabel}
            // label={(el) =>  el.percent}
          >
                          
            <LabelList
              dataKey="value"
              position="outsideLeft"
              style={{ fontSize: "1rem" }}
            />
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
    );
  };

  const calculateAvg = (values) => {
    console.log(values);
    const {value, percent} = values;

    return(
      <p>
      {`${(percent * 100).toFixed(0)}%`}
    </p>
    
    )
  };

  const calculateStatus = () => {
    let finalData = [];
    statusLabel.forEach((el) => {
      const count = ordersData.reduce((acc, cur) => cur.status === el.status ? ++acc : acc, 0);
      if(count>0){
        const statusElement = { name: el.label, value: count};
        finalData.push(statusElement);
      }
    });
    console.log(finalData);

    renderStatus(finalData);
  };

  const [statusGraph, setStatusGraph] = React.useState("");
  
  const renderStatus = (graphData) => {
    setStatusGraph(
      // <ResponsiveContainer width="90%" height={200} >
        <PieChart width={600} height={200}>
          <Legend layout="vertical" verticalAlign="middle" 
            formatter={(value, entry, index) => <span style={{color: "black", fontSize: "0.8rem", marginLeft: "0.2rem"}}>{value}</span>}
          />
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={graphData}
            cx={140}
            cy={100}
            outerRadius={80}
            fill="#8884d8"
            labelLine={false}
            // label={renderCustomizedLabel}
            // label={renderLabels}
          >
            <LabelList
              dataKey="value"
              position="right"
              style={{ fontSize: "1rem" }}
            />
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      // </ResponsiveContainer>
    );
  };

  const calculateProblems = () => {
    let problemsData = [];
    let count;
    problemLabel.forEach((el) => {

        count = delaysData.reduce((acc, cur) => cur.problemType === el.problemType ? ++acc : acc, 0);
        if(count>0){
          const statusElement = { name: el.label, value: count};
          problemsData.push(statusElement);
        }
    });
    console.log(problemsData);

    renderProblems(problemsData);
  };

  const [problemGraph, setProblemGraph] = React.useState("");
  
  const renderProblems = (graphData) => {
    setProblemGraph(
      // <ResponsiveContainer width="90%" height={200} >
        <PieChart width={630} height={200}>
          <Legend layout="vertical" verticalAlign="middle"
            formatter={(value, entry, index) => <span style={{color: "black", fontSize: "0.8rem", marginLeft: "0.2rem"}}>{value}</span>}
          />
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={graphData}
            cx={140}
            cy={100}
            outerRadius={80}
            fill="#8884d8"
            labelLine={false}
            // label={renderCustomizedLabel}
            // label={renderLabels}
          >
            <LabelList
              dataKey="value"
              position="right"
              style={{ fontSize: "1rem" }}
            />
            {graphData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      // </ResponsiveContainer>
    );
  };

  const [ordersList, setOrdersList] = React.useState("");

  const renderList = () => {
    setOrdersList(
      <List sx={{width: "90%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
        <Grid container spacing={2}>
          {
            filterOrderData.map((order) => {
              const currentStatus = statusLabel.find((el) => el.status ===order.status);
              return(
                <>
                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                  <ListItemButton onClick={() => deliveryDetail(order)}>
                    <Grid key={order.id} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Box key={order.id}
                          sx={{
                              ...((order.status === "created") && {
                                  backgroundColor: "#0088FE",
                                  color: "white",
                              }),
                              ...((order.status === "transit") && {
                                  backgroundColor: "#a020f0",
                                  color: "white",
                              }),
                              ...((order.status === "recolected") && {
                                  backgroundColor: "#00C49F",
                                  color: "white",
                              }),
                              ...((order.status === "delivered") && {
                                  backgroundColor: "#FF5F1F",
                                  color: "white",
                              }),
                             
                              borderRadius: "50%",
                              width: "1rem",
                              height: "1rem",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              // marginBottom: "0.5rem",
                          
                          }} 
                      >
                          
                      </Box>
                    </Grid>
                    <Grid key={order.id + 1} item xs={5} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Typography key={order.id} variant="body1" sx={{color: "black"}}>
                            {`${order.id}`}
                        </Typography>
                    </Grid>
                    <Grid key={order.id + 2} item xs={5} sm={4} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Typography key={order.id+1} variant="body1" sx={{color: "black"}}>
                          {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(order.orderDate))}`}
                        </Typography>
                    </Grid>
                    <Grid key={order.id + 3} item xs={5} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Typography key={order.id+2} variant="body1" sx={{color: "black"}}>
                            {`${currentStatus.label}`}
                        </Typography>
                    </Grid>
                  </ListItemButton>
                </ListItem>
                </>
              )
            })
          }
        </Grid>
      </List>
    );
  };

  const deliveryDetail = (selectedOrder) => {
    console.log(selectedOrder)

    history.push({ 
        pathname: "/lastmile/orderDetail",
        state:{
          delivery: selectedOrder
        }
    });
  };

  const handleFilterClick = (event) => {
    console.log(event)
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterDeliveries = (byType) => {

    handleClose();
    console.log(byType);

    if(byType==="noFilter"){
      filterOrderData = ordersData;
      console.log(filterOrderData);
      renderList();
      return;
    }

    if(byType==="problem"){

      filterOrderData = ordersData.filter((el) => el.deliveryProblem.length > 0);
      console.log(filterOrderData);
      renderList();
      return;
    }
    
    filterOrderData = ordersData.filter((el) => el.status === byType);
    console.log(filterOrderData);
    renderList();
    return;
    
  };

  return (
    <Box className={classes.svgBackground} sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%",}}>
      <Box
          sx={{
          display: "flex",
          flexGrow: "1",
          justifyContent: "center",
          alignItems: "start",
          width: "100%",
          height: "100%",
          // backgroundColor: "#ededed",
          }}
      >
        <Grid container sx={{justifyContent: "center"}}>
          <Grid item xs={5} sm={5} md={4}>
            <Grid container direction="column">
                <Grid item xs={12} sm={12} md={12} sx={{height: "50%"}}>
                  {statusGraph}
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{height: "50%"}}>
                  {problemGraph} 
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{height: "50%"}}>
                  {duration} 
                </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={7} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            {ordersList}
          </Grid>
          <Grid item xs={1} sm={1} md={1} sx={{}}>
              <IconContext.Provider
                value={{ color: "#555555", size: '1.2rem', }}
              >
                <Box onClick={handleFilterClick} sx={{marginTop: "2rem", marginRight: "3.8rem", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <FaFilter />
                </Box>
              </IconContext.Provider>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                    {statusLabel.map((typeEl) => (
                          <MenuItem onClick={() => filterDeliveries(typeEl.status)} key={typeEl.status} value={typeEl.status}>
                              {typeEl.label}
                          </MenuItem>
                      ))}
                </Menu>
            </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
