import React from 'react';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import { getAllDeliveries } from "../../utilityFunctions/DeliveryUtil";
import Autocomplete from '@mui/material/Autocomplete';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let deliveryReq={
    id: "",
    newStatus: "",
};

let ordersData;

export default function DeliverySearch(props) {

    const {loginUser} = props 

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        deliveryReq={
            id: "",
            newStatus: "",
        };

        loadDeliveries();

    }, []);

    const [deliveriesCode, setDeliveriesCode] = React.useState("");

    const loadDeliveries = async () => {
        setLoading(true);
        ordersData = await getAllDeliveries(loginUser.jwtToken);

        setDeliveriesCode(
            {
                options: ordersData,
                getOptionLabel: (option) => option.id,
            }
        );

        setLoading(false);
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        deliveryReq.id=value.id;
    };

    const submitModifications = async () =>{

        const selectedDelivery = ordersData.find((el) => el.id ===deliveryReq.id);

        console.log(selectedDelivery);

        history.push({ 
            pathname: "/lastmile/orderDetail",
            state:{
              delivery: selectedDelivery
            }
        });
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "30%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Autocomplete
                            {...deliveriesCode}
                            id="ids"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Guía no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeAutocomplete}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label={<Typography variant="body2">Número guía</Typography>} variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "3.5rem"}}>
                        <Button onClick={submitModifications} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                            Detalle
                        </Button>
                    </Grid>
                </Grid>
                }
            </Box>
        </Box>
    )
}
