import React, { useState, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import Input from "../../components/Input"
import Content from "../../layout/Content"
import PageLabel from "../../components/ui/PageLabel"
import AppButton from "../../components/ui/AppButton"
import swal from "sweetalert"
import { PuffLoader } from "react-spinners"
import { css } from "@emotion/react"
import Colors from "../../components/ui/Colors"

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function HandleUser() {

  const history = useHistory()

  useEffect(() => {
    swal({
      title: "¿QUÉ HAGO?",
      text: `\n Ingresa tu usuario y contraseña para empezar a ser tu propio banco: Wupéalo! \n \n
                    No olvides el botón Consulta al finalizar`,
      icon: "success",
      button: "Entiendo"
    });
  }, [])

  const [inputFromChild, setInputFromChild] = useState();
  const [eraseLabel, setEraseLabel] = useState(false);

  // Input and label variables
  const labelsArray = useRef(["USUARIO", "CONTRASEÑA", "TOKEN"]);
  let labelIndex = useRef(0);
  const loginCredentials = useRef({
    accountId: "",
    ilpBearerToken: "",
    totpToken: ""
  });

  const buttonId = useRef()
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setEraseLabel(false);
  }, [eraseLabel])

  function handleInput() {
    if (labelIndex.current < labelsArray.current.length) {

      // setInstructionLabel(labelsArray[labelIndex.current]);

      if (labelIndex.current === 0) {
        loginCredentials.current.accountId = inputFromChild;
        labelIndex.current += 1;
        setEraseLabel(true)
      } else if (labelIndex.current === 1) {
        loginCredentials.current.ilpBearerToken = inputFromChild;
        labelIndex.current += 1;
        setEraseLabel(true)
      } else if (labelIndex.current === 2) {
        loginCredentials.current.totpToken = inputFromChild;
        labelIndex.current = 0;
        setEraseLabel(true)
        tokenVerification();
        setLoading(true);
      }
    }
  }

  function tokenVerification() {
    console.log("login credentials before verification: " + JSON.stringify(loginCredentials.current))
    fetch("http://wupoapi.ngrok.io/verification", {

      method: "POST",
      body: JSON.stringify(loginCredentials.current),
      headers: {
        // "Authorization": "Bearer Token ´${tokenFromUserHandler}´", 
        "Content-Type": "application/json"
      },

    }).then((response) => {
      return response.text();

    }).then((text) => {
      setLoading(false);
      console.log("response text: " + text);
      console.log("ilp accountId: " + loginCredentials.current.accountId)
      console.log("ilp accountId: " + loginCredentials.current.ilpBearerToken)

      if (text === "true") {
        console.log("Account ID en confirmaciòn exitosa: " + loginCredentials.current.accountId)
        console.log("Token en confirmaciòn exitosa: " + loginCredentials.current.ilpBearerToken)
        // dataCallFromParent("cuenta-a", loginCredentials.current.accountId, loginCredentials.current.ilpBearerToken);
        history.push("/account")
      } else {
        swal({
          title: "CREDENCIALES INCORRECTAS",
          text: `\n Por favor vuelve a intentarlo.`,
          icon: "error",
          button: "Entiendo"
        }).then(() => {
          // dataCallFromParent("login-a", "", "");
          history.push("")
        });
      }

    }).catch((error) => {
      setLoading(false);
      console.log("Error msg: " + error);
    });
  }



  return (
    <Content>
      <PageLabel>{labelsArray.current[labelIndex.current]}</PageLabel>
      {loading && <PuffLoader
        size={200}
        color={Colors.secondary}
        css={cssLoader}
      />}
      <Input
        onChangeInfo={(e) => setInputFromChild(e.target.value)}
        eraseInput={eraseLabel}
        inputIcon="user"
      />
      <AppButton primary onClick={handleInput} ref={buttonId}>Next</AppButton>
    </Content>
  )
}
