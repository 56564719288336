import styled from "@emotion/styled"


const ButtonContent = styled.div(props => ({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridGap: props.gridGap ? props.gridGap : "0",
    justifyItems: "center",
    padding: "20px 25px",
    justifyContent: "space-evenly",
    background: "transparent"

}))

export default ButtonContent