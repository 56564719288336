import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, DialogContent, Typography } from "@mui/material";
import Colors from "../../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {settlementPaymentUpdate, getSettlementMemo} from "../../../utilityFunctions/MarketPlaceUtil";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let currentSettlement;

let cattlePayments

export default function ManualPaymentDetail(props) {

  const {loginUser} = props;

  const history = useHistory();

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    currentSettlement = "";
    renderPayments();
  }, []);

  const [payments, setPayments] = React.useState();

  const initialValues = () => {
    if(props.location.state && props.location.state.cattleSold){
      console.log(props.location.state.cattleSold);
      return props.location.state.cattleSold;
    }
  };

  const renderPayments = async () => {

    cattlePayments = await initialValues().clientsPorfolioSettlement;

    cattlePayments = sortCattle(cattlePayments);

    console.log(cattlePayments);
    setPayments(
      <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
        <Grid container spacing={2}>
          {cattlePayments.map((payment) => {
            return(
              <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                <ListItemButton key={generateRandomKey()} onClick={() => handlePayment(payment)}>
                  <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                          {`${payment.completeName}`}
                      </Typography>
                  </Grid>
                  <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                          {`${payment.idNumber}`}
                      </Typography>
                  </Grid>
                  <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()}variant="body1" sx={{color: "black"}}>
                          {`$ ${new Intl.NumberFormat('es-CO').format(payment.totalPayment)}`}
                      </Typography>
                  </Grid>
                  <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                          {payment.isSigned ? "Firmado" : "No Firmado"}
                      </Typography>
                  </Grid>
                  <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                          {payment.isPaid ? "Pago" : "No Pago"}
                      </Typography>
                  </Grid>
                </ListItemButton>
              </ListItem>
            );
          })

          }
        </Grid>
      </List>
    );

  };

  const updatePayments = async () => {

    console.log(cattlePayments);
    // cattlePayments = sortCattle(cattlePayments);

    setPayments(
      <List sx={{width: "100%", height:{xs: "90%", sm: "90%", md: "95%"}, marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}, marginBottom: {xs: "2rem", sm: "2rem", md: "2rm"}}}>
        <Grid container spacing={2}>
          {cattlePayments.map((payment) => {
            return(
              <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                <ListItemButton  key={generateRandomKey()} onClick={() => handlePayment(payment)}>
                  <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                          {`${payment.completeName}`}
                      </Typography>
                  </Grid>
                  <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                          {`${payment.idNumber}`}
                      </Typography>
                  </Grid>
                  <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                          {`$ ${new Intl.NumberFormat('es-CO').format(payment.totalPayment)}`}
                      </Typography>
                  </Grid>
                  <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                          {payment.isSigned ? "Firmado" : "No Firmado"}
                      </Typography>
                  </Grid>
                  <Grid kkey={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography key={generateRandomKey()} variant="body2" sx={{color: "black"}}>
                          {payment.isPaid ? "Pago" : "No Pago"}
                      </Typography>
                  </Grid>
                </ListItemButton>
              </ListItem>
            );
          })

          }
        </Grid>
      </List>
    );

  };

  const sortCattle = (cattleArray) => {
    cattleArray.sort((a,b) => {
        const statusValueA = sortingByIsPaid(a.isPaid);
        const statusValueB = sortingByIsPaid(b.isPaid);

        // First, compare based on "isPaid" property
        if (statusValueA !== statusValueB) {
            return statusValueA - statusValueB;
        }

        // If "isPaid" is the same, sort based on "isSigned" property
        const isSignedA = sortingByIsSigned(a.isSigned);
        const isSignedB = sortingByIsSigned(b.isSigned);

        return isSignedA - isSignedB;
        
    });

    return cattleArray;
}

const sortingByIsPaid = (condition) => {
    if(condition===false){
        return 1;
    }
    else{
        return 2;
    }
};

const sortingByIsSigned = (condition) => {
  if(condition===true){
      return 1;
  }
  else{
      return 2;
  }
};

  const [detailBox, setDetailBox] = React.useState(false);
  const [renderDetail, setRenderDetail] = React.useState();

  const handlePayment = (selectedPayment) => {

    currentSettlement = selectedPayment;
    console.log(currentSettlement);

    setRenderDetail(
      <Grid container spacing={1} sx={{alignItems: "center", marginTop:{xs: "0.2rem", sm: "0.2rem", md: "0.2rem"}, maxWidth: "32rem"}}>
        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.2rem", marginBottom: "0.2rem"}}>
            <Typography variant="h6" sx={{fontWeight: "bold", width: "100%"}}> {`$ ${new Intl.NumberFormat('es-CO').format(selectedPayment.totalPayment)}`}</Typography>
        </Grid>
        
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Nombre:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.completeName}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Identificación:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.idNumber}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Cuenta Número:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.bankAccount.bankAccount}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Tipo de Cuenta:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.bankAccount.bankAccountType}`}</Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ fontWeight: "bold", width: "100%"}}>Banco:</Typography>
        </Grid>
        <Grid item xs={6} sm={6} md={6} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.2rem"}}>
            <Typography variant="body2" sx={{ width: "100%"}}>{`${selectedPayment.bankAccount.bankCode}`}</Typography>
        </Grid>
      </Grid>
    );

    setDetailBox(true);

  };

  const closeDetailBox = () => {
    setDetailBox(false);
  };

  const handleSettlement = async (selectedPayment) => {
    
    setLoading(true);
    const updatedSoldCattle = await settlementPaymentUpdate(selectedPayment, loginUser.jwtToken);
    setLoading(false);
    console.log(updatedSoldCattle);
    
    if(updatedSoldCattle){
      cattlePayments = updatedSoldCattle;
      updatePayments();
    }
    

    // renderPayments();
  };

  const getMemo = async (settlement) => {
    console.log(settlement)
    const docReques = {
      id: settlement.id,
    };

    setLoading(true);
    await getSettlementMemo(docReques, loginUser.jwtToken);
    setLoading(false);
  };

  const generateRandomKey = () => {
    const randomString = Math.random().toString(36).slice(2);
    const timestamp = Date.now().toString(36);
    return randomString + '_' + timestamp;
  };

  return (
    <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", width: "100%"}}>
      {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
      <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
        >
          <Box>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container sx={{marginTop: "1.2rem"}}>
                  <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Nombre</Typography>
                  </Grid>
                  <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Identificación</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Valor</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Firma</Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Pago</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {payments}
              </Grid>
            </Grid>
          </Box>
        
        <Dialog open={detailBox} onClose={closeDetailBox}>
          <DialogTitle sx={{display: "flex", justifyContent: "center"}}>
            <Typography variant="h4">Detalle Pago</Typography> 
          </DialogTitle> 
          <DialogContent>
            {renderDetail}
          </DialogContent>
          <DialogActions sx={{display: "flex", justifyContent: "center"}}>
            <Grid container spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
               <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>   
                  <Button onClick={closeDetailBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                      ATRÁS
                  </Button>
              </Grid> 
              <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <Button 
                      onClick={() => handleSettlement(currentSettlement)} variant="contained" sx={{backgroundColor: Colors.primary}}>
                      PAGO
                  </Button>
                </Grid> 
                <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                  <Button 
                      onClick={() => getMemo(currentSettlement)} variant="contained" sx={{backgroundColor: Colors.primary}}>
                      DOC
                  </Button>
                </Grid>
              </Grid>
          </DialogActions>
        </Dialog>
        </Box>
        }
    </Box>
  )
}
