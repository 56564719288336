import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import{getEduUsers, blockEduUser, unblockEduUser} from '../../../utilityFunctions/EduMarketUtil';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import swal from "sweetalert";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

export default function EduUsersList(props) {

    const{loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        renderUsersReport();
    }, []);

    const initialValues = async () => {
        setLoading(true);
        const response = await getEduUsers(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            
            swal({
                title: "No users found",
                icon: "info",
                button: "OK",
            });
        }
        
        return response;
    
    };

    const [usersReport, setUsersReport] = React.useState(null);

    const renderUsersReport = async () => {

        const eduUsers = await initialValues();

        if(!eduUsers){
            return;
        }

        setUsersReport(
            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                {eduUsers.map((eduUser) => {
                    return(
                        <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                            <ListItemButton key={generateRandomKey()} onClick={() => handleEduUser(eduUser)}>
                                <Grid key={generateRandomKey()}  container spacing={1}>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{eduUser.name}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{eduUser.lastname}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{eduUser.email}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{eduUser.enabled ? "Enabled" : "Disabled"}</Typography>
                                    </Grid>
                                    <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography key={generateRandomKey()} variant="body2">{new Intl.DateTimeFormat('en-GB', dateOptions).format(new Date(eduUser.createdOn))}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        );

    };

    const[selectedUser, setSelectedUser] = React.useState(null);
    const[open, setOpen] = React.useState(false);

    const handleEduUser = (eduUser) => {

        setSelectedUser(eduUser);

    };

    React.useEffect(() => {
        if(selectedUser){
            setOpen(true);
        }
    }, [selectedUser]);

    const handleClose = () => {
        setSelectedUser(null);
        setOpen(false);
    };

    const handleBlockUser = async () => {
        console.log(selectedUser);

        handleClose();

        setLoading(true);
        const blockResponse = await blockEduUser({username: selectedUser.username}, loginUser.jwtToken);
        setLoading(false);


        if(!blockResponse){
            swal({
                title: "User not blocked",
                icon: "error",
                button: "OK",
            });
            return;
        };

        swal({
            title: "User blocked",
            icon: "success",
            button: "OK",
        });

    };

    const handleUnblockUser = async () => {
        console.log(selectedUser);

        handleClose();

        setLoading(true);
        const blockResponse = await unblockEduUser({username: selectedUser.username}, loginUser.jwtToken);
        setLoading(false);


        if(!blockResponse){
            swal({
                title: "User not unblocked",
                icon: "error",
                button: "OK",
            });
            return;
        };

        swal({
            title: "User unblocked",
            icon: "success",
            button: "OK",
        });

    };

    const handleProvided = () => {
    };

    const handleEnrolled = () => {
    };
    
    const generateRandomKey = () => {
        const randomString = Math.random().toString(36).slice(2);
        const timestamp = Date.now().toString(36);
        return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{marginTop: "1rem"}}>
                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Name</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Surname</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Email</Typography>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Status</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Created on</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        {usersReport}
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleClose} 
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "60%", md: "40%"},
                                height: "60%"
                            },
                        },
                    }}
                >
                    <Grid container direction="row" spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                        <Grid item xs={12} sm={12} md={12} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <ListItemButton onClick={() => handleBlockUser()}>
                                        <Typography variant="body2">Block User</Typography>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <ListItemButton onClick={() => handleUnblockUser()}>
                                        <Typography variant="body2">Unblock User</Typography>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <ListItemButton onClick={() => handleProvided()}>
                                        <Typography variant="body2">Provided Services</Typography>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <ListItemButton onClick={() => handleEnrolled()}>
                                        <Typography variant="body2">Enrolled Services</Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <Button variant="contained" onClick={handleClose}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
                </>
            }
            </Box>
        </Box>
    )
}
