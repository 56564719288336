import React, { useState, useEffect, useRef } from "react";
import "../../css/accountStyle.css";
import Input from "../../components/Input";
import AssetSelect from "../../components/AssetSelect";
import swal from "sweetalert";
import { RingLoader } from "react-spinners";
import { css } from "@emotion/react";
import { xrpPayment } from "../../utilityFunctions/Paymentutil";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function WupoPayment(props) {
  const { loginUser, dataForParent, fundingValue, ledgerAccounts, wupoServerUri } = props;

  //label variables
  const labelsArray = useRef(["DESTINO", "ACTIVO"]);
  let labelIndex = useRef(0);

  //Value From Input/Select
  const [inputFromChild, setInputFromChild] = useState();

  const WUPOILPACCOUNT = "wupoHotWallet"; //operational ilp AccountId used by Wupo
  const WUPOILPSECRET = "wupoAccountToken";

  const txInput = useRef({
    senderAccount: WUPOILPACCOUNT,
    paymentValue: fundingValue.current,
    receiverAccount: "", //Input
    senderKey: WUPOILPSECRET,
  });

  const payment = useRef({
    source: {
      address: "",
      maxAmount: {
        value: "",
        currency: "",
        counterparty: "",
      },
    },
    destination: {
      address: "",
      amount: {
        value: "",
        currency: "",
        counterparty: "",
      },
    },
    //TODO: include an automatic path or an input path
    //"paths": '[[{"currency":"COP","issuer":"rJAf4zqDKXGchRw3yG7f8rNh4GMEEvSMQf"},{"account":"rJAf4zqDKXGchRw3yG7f8rNh4GMEEvSMQf"}]]'
  });
  const wupoXrplSecret = useRef();
  const wupoXrplAddress = useRef();

  const [eraseLabel, setEraseLabel] = useState(false);
  const fetchConcluded = useRef(false);
  const [cardRender, setCardRender] = useState(false);

  useEffect(() => {
    swal({
      title: "¿QUÉ HAGO?",
      text: `\n Ingresa la información de destino`,
      icon: "success",
      buttons: {
        cancel: "Entiendo",
        admin: "Admin",
      },
      //["Entiendo", "Más Info"]
    }).then((value) => {
      switch (value) {
        case "info":
          swal({
            title: "¿QUÉ ES ESTO?",
            text: `\n Con el valor ingresado fondeamos tu cuenta en wupo.NET \n \n`,
            icon: "success",
            button: "Súper",
          });
          break;
        case "admin":
          swal({
            title: "PARA ADMINS E INVERSIONISTAS",
            text: `\n Esta funcionalidad usa el valor fondeado para hacer un pago.\n
                                El pago es realizado desde un conector que integra una infraestructura de pagos a un conector que integra otra infraestructura de pagos diferente. \n\n
                                Para probar la funcionalidad  tenemos creada la cuenta testReceiver con activo USD. O si prefieres puedes transferir a cualquier cuenta que hayas creado (todas se crean en un conector diferente a donde está la cuenta de WUPO que es usada para esta funcionalidad)\n \n
                                En ambiente de desarrollo se ingresa la cuenta, pero en producción se selecciona de cuentas pre-ingresadas \n\n
                                Te invitamos a revisar la consola (En Chrome: click derecho -> inspeccionar -> console) para ver lo que pasa por detrás en tiempo real!`,
            icon: "success",
            button: "Súper",
          });
          break;
        default:
      }
    });
  }, []);

  useEffect(() => {
    setEraseLabel(false);
  }, [eraseLabel]);

  //Post the payemnt
  function postPayment() {
    console.log("Payment JSON: " + JSON.stringify(txInput.current));
    fetch(`${wupoServerUri.devnet}/payment`, {
      method: "POST",
      body: JSON.stringify(txInput.current),
      headers: {
        'Authorization': `Basic ${loginUser.jwtToken}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (body) {
        fetchConcluded.current = true;
        console.log("Fetch text: " + JSON.stringify(body));
        if (
          body.receiverAmount !== undefined &&
          body.wupoAmount !== undefined
        ) {
          //The XRP payment value corresponds to the receiven value paid to the client's ilp account
          payment.current.source.maxAmount.value = String(body.receiverAmount);
          payment.current.destination.amount.value = String(
            body.receiverAmount
          );
          xrplPayment();
        } else {
          swal({
            title: "NO PUDIMOS HACER TU PAGO",
            text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 

                            Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                            Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                            Jorge: XXXXXXXXXX
                            Federico: XXXXXXXXXX`,
            icon: "error",
            button: "Entiendo",
          }).then(() => {
            dataForParent("tx-a");
          });
        }
      })
      .catch(function (error) {
        fetchConcluded.current = true;
        console.log("Error msg: " + error);
        swal({
          title: "NO PUDIMOS HACER TU PAGO",
          text: `\n Puede que hayas seleccionado una cuenta o activo inválido. 

                        Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                        Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                        Jorge: XXXXXXXXXX
                        Federico: XXXXXXXXXX`,
          icon: "error",
          button: "Entiendo",
        }).then(() => {
          dataForParent("tx-a");
        });
      });
  }

  function xrplPayment() {
    /*Use the integrator's fetch to define the payments variables */
    ledgerAccounts.forEach((element) => {
      if (element.accountId === WUPOILPACCOUNT) {
        wupoXrplSecret.current = element.xrplAddressSecret;
        wupoXrplAddress.current = element.xrplAddress; //not very useful, but to track when changing to a hash password
        payment.current.source.address = element.xrplAddress;
        payment.current.source.maxAmount.counterparty = element.xrplAddress;
        payment.current.destination.amount.counterparty = element.xrplAddress;
        // payment.current.source.maxAmount.value=inputFromChild;
        // payment.current.destination.amount.value=String(inputFromChild - (inputFromChild * 0.03));//Fix me: for test purposes slightly less;
      }
      if (element.accountId === txInput.current.receiverAccount) {
        payment.current.destination.address = element.xrplAddress;
      }
    });

    /* Payment from Wupo to the client using the defined asset */
    xrpPayment(
      payment.current,
      String(payment.current.source.maxAmount.value),
      payment.current.destination.address,
      wupoXrplSecret.current
    )
      .then(() => {
        swal({
          title: "PAGO EXITOSO",
          icon: "success",
          button: "Súper",
        }).then(() => {
          dataForParent("tx-a");
        });
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: "NO PUDIMOS HACER TU PAGO",
          text: `\n Por favor vuelve a intentarlo. 
    
                            Es nuestra obligación cumplirte y lo sabemos. Si existen fallas, estamos trabajando sin descanso para solucionarlas.\n \n 
                            Mientras te dejamos los teléfonos de nuestros fundadores para que conozcas avances de la solución\n\n
                            Jorge: XXXXXXXXXX
                            Federico: XXXXXXXXXX`,
          icon: "error",
          button: "Entiendo",
        }).then(() => {
          dataForParent("tx-a");
        });
        //PENDING REVERSION OF THE ACCOUNT CREATION IN THE ILP CONNECTOR
      });
  }

  function handleInput() {
    if (labelIndex.current < labelsArray.current.length) {
      if (labelIndex.current === 0) {
        txInput.current.receiverAccount = inputFromChild;
        console.log("Payment input: " + inputFromChild);
        labelIndex.current += 1;
        setEraseLabel(true);
      } else if (labelIndex.current === 1) {
        txInput.current.receiverAccount =
          txInput.current.receiverAccount + inputFromChild;
        payment.current.source.maxAmount.currency = inputFromChild;
        payment.current.destination.amount.currency = inputFromChild;
        console.log("Payment asset: " + inputFromChild);
        labelIndex.current = 0;
        setEraseLabel(true);
        fetchConcluded.current = true;
        setCardRender(
          <RingLoader size={150} color={"#63FF7E"} css={cssLoader} />
        );
        postPayment();
      }
    }
  }

  useEffect(() => {
    if (fetchConcluded.current === false) {
      if (labelsArray.current[labelIndex.current] === "DESTINO") {
        setCardRender(
          <Input
            onChangeInfo={(e) => setInputFromChild(e.target.value)}
            eraseInput={eraseLabel}
          />
        );
      } else if (labelsArray.current[labelIndex.current] === "ACTIVO") {
        setCardRender(
          <AssetSelect
            onChangeInfo={(e) => setInputFromChild(e.target.value)}
          />
        );
      }
    }
  }, [eraseLabel]);

  return (
    <div className="magicAccount">
      <div className="accountCreation" id="accountCard-id">
        <div className="accountContainer">
          <h2 className="accountTittle" id="tittle">
            {labelsArray.current[labelIndex.current]}
          </h2>
          {cardRender}
          <button
            type="button"
            className="accountBtn"
            id="accountBtn"
            onClick={handleInput}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
