import React from "react"
import { useHistory } from "react-router-dom"
import styled from "@emotion/styled"
import { FaRegAddressCard, FaWallet, FaCodepen } from "react-icons/fa"
import Colors from "../components/ui/Colors"
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import { AiOutlineShoppingCart } from "react-icons/ai";
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CategoryIcon from '@mui/icons-material/Category';
import CardTravelIcon from '@mui/icons-material/CardTravel';



const navHeight = 60

const navMenus = [
  // {
  //   id: "tx-a",
  //   name: "ALIADOS",
  //   to: "/storesmap", 
  //   icon: <IoStorefront />
  // },
  {
    id: "products",
    name: "SERVICES",
    // to: "/account/create_account",
    to: "/account/handle_account",
    icon: <CategoryIcon style={{fontSize: "1.4rem"}}/>
  },
  {
    id: "admin-a",
    name: "",
    to: "/handle_profile",
    icon: <Avatar alt="Profile" >
      <PersonIcon style={{fontSize: "2rem"}} />
    </Avatar>
  },
  {
    id: "initialLogin",
    name: "EXIT",
    to: "/",
    icon: <ExitToAppIcon style={{fontSize: "1.4rem"}}/>
  },
]

const NavBarStyle = styled.div(({
  display: "flex",
  flexDirection: "row",
  gap: "10%",
  height: "5rem",
  width: "100%",
  // maxWidth: "620px",
  // padding: "0.25rem 1.25rem",
  justifyContent: "center",
  alignSelf: "center",
  // backgroundColor: Colors.primary
  // backgroundColor: "#50C878"
  backgroundColor: "#0EB69C"
  // backgroundImage: "linear-gradient(to right, #56ccf2, #2f80ed)"
  // backgroundImage: "linear-gradient(to right, #009fff, #ec2f4b)"
  // backgroundImage: "linear-gradient(to right, #396afc, #2948ff)"
  // backgroundImage: "linear-gradient(to right, #0575E6, #00F260)"
  
}))

const NavItem = styled.div(({
  width: "20%",
  display: "flex",
  alignItems: "flex-end",
  color: "white",
  cursor: "pointer",
}))

const NavBar = ({ userdata }) => {

  const history = useHistory()
  const handleClickMenu = (event, to, id) => {
    
    if(!to) return;
    if (to === "/") {
      localStorage.clear();
      userdata(null);
      history.push(to);
    }
    console.log("after to null")
    console.log(id)
    if(id==="products"){
      console.log(event)
      setAnchorEl(event.currentTarget);

    }
    else{
      history.push(to);
    }
    
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toAvailableEdus = () => {
    history.push("/edu_programs/active_programs")
    handleClose();
  };

  const toMyEdus = () => {
    history.push("/edu_programs/enrolled_programs")
    handleClose();
  };
  

  return (
    <NavBarStyle>
      <Grid container>
           <Grid item xs={3} sm={6} md={8} lg={8} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
            <Box 
                component="img"
                sx={{
                  width: {xs:"140%", sm:"40%", md: "30%"},
                  height: {xs:"auto", sm:"auto", md: "auto"},
                  // minHeight: "410px",
                  paddingLeft: {xs: "0.9rem", sm: "1rem", md: "1.5rem"}
                  // position:"absolute", top: 10, left: 10 
                }}
                alt="Logo"
                src="/images/socialblox-whitelogo.png"

            />
           </Grid>
          {/* <Grid item display={{ xs: "none", sm: "flex", md: "flex", lg: "flex"}} xs={1} sm={6} md={8} lg={8} style={{justifyContent: "start", alignItems: "center", marginLeft: "2rem", flex: 1}}>
            <Typography variant="h4" style={{color: "white"}}>Hola</Typography>
          </Grid> */}
        <Grid item xs={9} sm={6} md={4} lg={4} style={{display: "flex", justifyContent: {xs: "end", sm: "center", md: "center"}, alignItems: "center", marginRight: {xs: "0.2rem", sm: "0.5rem", md: "0.5rem"}, marginLeft: "0.5rem", flex: 1}}>
          {navMenus.map(menu => (
            <Grid container key={menu.id} sx={{justifyContent: "end", marginRight: {xs: "1.5rem", sm: "0"}}}>
              <Grid item xs={3} sm={3} md={3}>
                <NavItem id={menu.id} key={menu.id} onClick={(e) => handleClickMenu(e, menu.to, menu.id)}>
                  <div style={{ textAlign: "center", marginTop: "1rem", marginBottom: "1rem" }}>
                    {menu.icon}
                    <Typography
                      sx={{
                        margin: 0,
                        fontSize: {xs: "0.5rem", sm: "0.5rem", md: "0.5rem"},
                        fontWeight: {xs: "bold", sm: "400", md: "400"},
                        overflowWrap: "break-word",
                        color: "white",
                      }}
                    >
                      {menu.name}
                    </Typography>
                  </div>
                </NavItem>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                <>
                  <MenuItem onClick={toAvailableEdus}>
                    <ListItemIcon>
                      <AiOutlineShoppingCart style={{fontSize: "1.4rem", color: Colors.primary}}/>
                    </ListItemIcon>
                    Available Programs
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={toMyEdus}>
                    <ListItemIcon>
                      <RocketLaunchOutlinedIcon style={{fontSize: "1.4rem", color: Colors.primary}}/>
                    </ListItemIcon>
                    My Programs
                  </MenuItem>
                </>
                </Menu>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </NavBarStyle>
  )
}

export default NavBar
