import React from 'react';
import { SvgIcon } from "@mui/material";

export default function CowIcon(props) {
  return (
    <SvgIcon
        {...props}
        version="1.0" 
        xmlns="http://www.w3.org/2000/svg"
        width="512.000000pt" 
        height="512.000000pt" 
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#25d366" stroke="#25d366" stroke-width="180">
            <path d="M1017 5006 c-100 -36 -173 -132 -232 -306 -42 -123 -59 -256 -52
            -414 7 -164 31 -279 82 -404 20 -48 39 -94 41 -102 4 -12 -15 -24 -74 -48 -43
            -18 -117 -56 -163 -83 -245 -146 -404 -321 -531 -585 -140 -290 -128 -312 212
            -376 90 -16 414 -14 518 4 l82 15 0 -123 c0 -243 33 -515 91 -743 l28 -113
            -50 -62 c-103 -127 -165 -297 -176 -481 -26 -453 226 -785 720 -950 529 -177
            1538 -178 2085 -4 200 64 409 191 520 315 150 167 223 391 209 639 -11 184
            -73 354 -176 481 l-50 62 28 113 c58 228 91 500 91 743 l0 123 83 -15 c107
            -18 407 -21 512 -3 141 23 248 53 272 77 50 50 41 100 -55 298 -151 314 -367
            522 -684 663 -46 20 -85 38 -87 39 -2 2 13 40 32 86 129 300 127 693 -5 963
            -60 125 -141 192 -242 202 -134 14 -229 -68 -262 -225 -26 -122 -102 -239
            -253 -395 -105 -108 -290 -269 -316 -275 -8 -2 -50 8 -95 22 -111 36 -151 34
            -184 -5 -18 -21 -26 -42 -26 -68 0 -53 36 -82 141 -114 81 -25 87 -29 93 -58
            9 -47 7 -129 -4 -190 -49 -259 -336 -399 -645 -314 -308 85 -560 -45 -687
            -357 -42 -103 -91 -158 -175 -200 -59 -29 -78 -33 -168 -36 -119 -5 -188 7
            -273 48 -72 34 -73 36 -42 150 85 315 253 566 500 749 104 78 272 162 403 202
            74 23 110 39 130 60 36 39 36 86 1 128 -33 39 -73 41 -184 5 -45 -14 -87 -24
            -95 -22 -26 6 -211 167 -316 275 -151 156 -227 273 -253 395 -15 72 -44 133
            -78 165 -60 56 -162 77 -241 49z m100 -194 c6 -4 18 -37 27 -73 21 -90 56
            -167 121 -263 86 -129 242 -294 397 -422 l38 -30 -110 -72 c-110 -73 -278
            -223 -335 -301 -16 -22 -33 -41 -37 -41 -4 0 -34 35 -68 78 -77 98 -153 253
            -187 379 -23 86 -26 118 -27 263 -1 128 3 179 17 230 51 188 113 284 164 252z
            m2952 -21 c29 -31 70 -127 98 -231 14 -51 18 -102 17 -230 -1 -144 -4 -177
            -26 -260 -42 -155 -123 -310 -226 -430 l-30 -35 -22 30 c-63 83 -242 246 -348
            315 l-112 74 38 30 c153 126 313 295 398 422 64 96 99 173 120 263 21 90 45
            103 93 52z m-536 -1089 c429 -349 584 -973 426 -1717 -12 -55 -24 -103 -28
            -107 -4 -4 -41 10 -82 32 -301 157 -744 240 -1289 240 -546 0 -999 -85 -1289
            -241 -41 -22 -77 -36 -81 -32 -11 12 -48 202 -65 328 -8 61 -18 190 -22 287
            l-6 177 53 -20 c90 -34 177 -50 279 -50 180 0 315 48 427 154 58 54 73 77 130
            194 38 79 80 151 103 176 90 97 207 119 385 73 230 -61 482 -12 658 128 118
            94 200 255 214 423 l7 82 56 -36 c31 -19 87 -60 124 -91z m-2529 -152 c16 -21
            46 -55 67 -76 l38 -37 -39 -77 c-48 -96 -103 -252 -126 -359 -19 -90 -12 -85
            -131 -105 -128 -23 -278 -29 -401 -17 -170 17 -175 19 -167 50 11 48 107 209
            170 288 109 136 254 244 442 329 54 24 103 44 108 44 6 0 23 -18 39 -40z
            m3283 -15 c188 -88 352 -220 456 -366 48 -68 137 -233 137 -255 0 -14 -25 -19
            -166 -35 -147 -16 -358 -2 -485 31 -35 10 -36 11 -53 91 -23 107 -78 263 -126
            359 l-39 77 38 37 c21 21 51 55 67 77 35 47 36 47 171 -16z m-1237 -1610 c473
            -56 796 -189 946 -388 98 -130 129 -230 129 -412 0 -182 -31 -282 -129 -412
            -201 -267 -708 -413 -1436 -413 -834 0 -1351 183 -1510 535 -43 95 -55 159
            -55 295 0 177 33 278 129 407 166 221 553 362 1104 403 174 13 663 4 822 -15z"/>
            <path d="M1890 2482 c-40 -21 -80 -90 -80 -137 0 -43 39 -105 80 -126 74 -40
            159 -13 198 61 27 54 27 82 3 137 -34 73 -127 103 -201 65z"/>
            <path d="M3090 2482 c-40 -21 -80 -90 -80 -137 0 -43 39 -105 80 -126 74 -40
            159 -13 198 61 27 54 27 82 3 137 -34 73 -127 103 -201 65z"/>
            <path d="M2223 1535 c-99 -43 -71 -185 36 -185 96 0 138 124 59 173 -37 24
            -61 27 -95 12z"/>
            <path d="M2823 1535 c-99 -43 -71 -185 36 -185 96 0 138 124 59 173 -37 24
            -61 27 -95 12z"/>
            <path d="M2511 4216 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83
            124 -149 87z"/>
        </g>

    </SvgIcon>
  )
}
