import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useHistory } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import AppButton from "../../components/ui/AppButton";
import MenuList from '@mui/material/MenuList';
import Colors from "../../components/ui/Colors"; 
import Divider from '@mui/material/Divider';
import { getApiCredentials, createApiCredentials, disableApiCredentials } from "../../utilityFunctions/RootUtil";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let fetchedCredentials;

const useStyles = makeStyles((theme) => ({

    gridInputs:{
        // maxWidth: "600px",
        justifyContent: "center",
        alignItems: "center",
        marginTop:"0.2em",
        marginBottom: "0.2em"
    },
    menuList: {
        overflowY: "scroll",
    },
    divider:{
        background: Colors.primary
    },
    button:{
        // background: "none",
        // textTransform: "none",
        // color: "blue",
        fontWeight: "bold",
    },
    buttonText:{
        background: "none",
        textTransform: "none",
        // fontWeight: "bold",
    },
    actionArea: {
        // borderRadius: 16,
        // transition: '0.2s',

        // '&:hover': {
        //     transform: 'scale(1.1)',
        //     backgroundColor: Colors.primary,
        // },
        overflow: "auto",
    },
}));

const dateOptions = {
    // weekday: "short",
    day: "2-digit",
    month: "numeric",
    year: "numeric",
    // hour: "2-digit",
    // minute: "2-digit",
};

export default function RootApi(props) {
    const{loginUser} = props

    const classes = useStyles();
    const history = useHistory();

    const[loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        setLoading(true);
        fetchCredentials();
    }, []);

    const[credentialsList, setCredentialsList] = React.useState('');
    const[apiKey, setApiKey] = React.useState('');
    const[apiSecret, setApiSecret] = React.useState('');
    const[renderDetail, setRenderDetail] = React.useState('');

    const fetchCredentials = async () => {
        fetchedCredentials = await getApiCredentials(loginUser.accountId, loginUser.jwtToken);
        console.log(fetchedCredentials);
        renderCredentials();
    };

    const credentialsDetail = (apiKey, apiSecret) => {
        setApiKey(apiKey);
        setApiSecret(apiSecret);
        setRenderDetail(
            <>
            <DialogTitle style={{color: "blue"}}>CREDENCIALES API</DialogTitle>
            <DialogContent className={classes.actionArea}>
                {/* <Card sx={{ minWidth: 150 }}>
                    <CardContent> */}
                        <Grid container direction="row" className={classes.gridInputs}>
                            <Grid item xs={3} sm={3} md={3}>
                                <Typography gutterBottom variant="body1" component="div">
                                    Api Key:
                                </Typography>   
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                                <Typography gutterBottom variant="body2" component="div">
                                    {apiKey}
                                </Typography> 
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}>
                                <Typography gutterBottom variant="body1" component="div">
                                    Api Secret:
                                </Typography>   
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                                <Typography gutterBottom variant="body2" component="div">
                                    {apiSecret}
                                </Typography> 
                            </Grid>
                        </Grid>
                    {/* </CardContent> */}
                    <Button className={classes.button} onClick={closeCredentialsDialog}>
                        Atrás
                    </Button>
                    <Button className={classes.button} onClick={() => credentialsDisable(apiKey)}>
                        Deshabilitar
                    </Button>
                {/* </Card> */}
            </DialogContent>
            </>
        );
        credentialsDialog();
        setLoading(false);
    };

    const[openCredentials, setOpenCredentials] = React.useState(false);
    const credentialsDialog = () =>{
        setOpenCredentials(true);
        setLoading(true);
    };

    const closeCredentialsDialog = () => {
        setOpenCredentials(false);
        setLoading(false);
    };

    const renderCredentials = () => {
        setCredentialsList(
            <>  
                {fetchedCredentials === "Error" ? 
                    <>
                        <Typography gutterBottom variant="h5" component="div">
                            No tienes credenciales API
                        </Typography>
                    </> 
                    :
                    <>
                        <Typography gutterBottom variant="h5" component="div">
                            Credenciales por fecha de creación:
                        </Typography>
                        <MenuList className={classes.menuList}>
                            {fetchedCredentials.map((credential) => {
                                return(
                                <>
                                    <Divider key={credential.id} className={classes.divider}/>
                                    <Grid key={credential.id} container direction="row" className={classes.gridInputs}>
                                        <Grid key={credential.id + 1} item xs={8} sm={8} md={8}>
                                            <Button key={credential.id} className={classes.buttonText} onClick={() => credentialsDetail(credential.username, credential.password)}>
                                                {`${new Intl.DateTimeFormat("es-CO", 
                                                                dateOptions).format(new Date(credential.createdOn))}`} 
                                            </Button>
                                        </Grid>
                                        <Grid key={credential.id + 2} item xs={4} sm={4} md={4}>
                                            <Typography gutterBottom variant="body2" component="div" style={{color: "blue", fontWeight:"bold"}}>
                                                {credential.enabled ? "Activa" : "Inactiva"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </>
                                )
                            })}
                        </MenuList>
                    </>
                }
            </>
        );
        setLoading(false);
    };

    const handleBack = () => {
        history.push({pathname: "/root-profile"});
    };

    const handleNewCredentials = async () => {
        setLoading(true);
        const receivedCredntials = await createApiCredentials(loginUser.accountId, loginUser.jwtToken);
        newCredentialsBox(receivedCredntials);
        
    };

    const [newCredentialsDialog, setNewCredentialsDialog] = React.useState(false);
    const [renderNewCredentials, setRenderNewCredentials] = React.useState();
        
    const newCredentialsBox = (apiCredntials) =>{
        setRenderNewCredentials(
            <>
            <DialogTitle style={{color: "blue"}}>CREDENCIALES API</DialogTitle>
            <DialogContent className={classes.actionArea}>
                {/* <Card sx={{ minWidth: 150 }}>
                    <CardContent> */}
                        <Grid container direction="row" className={classes.gridInputs}>
                            <Grid item xs={3} sm={3} md={3}>
                                <Typography gutterBottom variant="body1" component="div">
                                    Api Key:
                                </Typography>   
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                                <Typography gutterBottom variant="body2" component="div">
                                    {apiCredntials.apiKey}
                                </Typography> 
                            </Grid>

                            <Grid item xs={3} sm={3} md={3}>
                                <Typography gutterBottom variant="body1" component="div">
                                    Api Secret:
                                </Typography>   
                            </Grid>
                            <Grid item xs={8} sm={8} md={8}>
                                <Typography gutterBottom variant="body2" component="div">
                                    {apiCredntials.apiSecret}
                                </Typography> 
                            </Grid>
                        </Grid>
                    {/* </CardContent> */}
                    <Button className={classes.button} onClick={closeNewCredentials}>
                        Las tengo
                    </Button>
                    
                {/* </Card> */}
            </DialogContent>
            </>
        );
        setLoading(false);
        setNewCredentialsDialog(true);
    }
    
    const closeNewCredentials = () => {
        setNewCredentialsDialog(false);
    };



    const credentialsDisable = async (apiKey) => {
        setLoading(true);
        await disableApiCredentials(loginUser.accountId, apiKey, loginUser.jwtToken);
        setLoading(false);
        closeCredentialsDialog();
    };

    return (
        <div
            style={{
                // display: "flex",
                maxWidth: "600px",
                justifyContent: "center",
                width: "100%",
                height: "100%",
                textAlign: "center",
                marginTop: "1.2em",
                // overflow: "scroll"
            }}
        >
            {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader} /> :
                credentialsList 
            }
            <Grid container direction="row" className={classes.gridInputs} spacing="2">
                <Grid item xs={6} sm={6} md={6}>
                    <AppButton 
                        primary
                        width={"100%"}
                        onClick={handleBack}
                    >
                        Atrás
                    </AppButton>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <AppButton 
                        primary
                        width={"100%"}
                        onClick={handleNewCredentials}
                    >
                        Nueva API
                    </AppButton>
                </Grid>
            </Grid>
            <Dialog open={openCredentials} onClose={closeCredentialsDialog}>
                {renderDetail}
            </Dialog>
            <Dialog open={newCredentialsDialog} onClose={closeNewCredentials}>
                {renderNewCredentials}
            </Dialog>
        </div>
    )
}
