import React from 'react';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import swal from "sweetalert";
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import { createDelivery } from "../../utilityFunctions/DeliveryUtil";
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let newDelivery={
    status: "created",
    address: "",
    clientId: "",
    clientPhone: "",
    clientName: "",
    clientEmail: "",
};

export default function NewDelivery(props) {

    const {loginUser} = props 

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        newDelivery={
            status: "created",
            address: "",
            clientId: "",
            clientPhone: "",
            clientName: "",
            clientEmail: "",
        };

    }, []);

    const [address, setAddress] = React.useState("");
    const [clientId, setClientId] = React.useState("");
    const [clientPhone, setClientPhone] = React.useState("");
    const [clientName, setClientName] = React.useState("");
    const [clientEmail, setClientEmail] = React.useState("");

    const handleChange = (event, definition) => {
        switch(definition){
            case "address":
                newDelivery.address = event.target.value;
                setAddress(event.target.value);
                break;

            case "clientId":
                newDelivery.clientId = event.target.value;
                setClientId(event.target.value);
                break;

            case "clientPhone":
                newDelivery.clientPhone = event.target.value;
                setClientPhone(event.target.value);
                break;

            case "clientName":
                newDelivery.clientName = event.target.value;
                setClientName(event.target.value);
                break;

            case "clientEmail":
                newDelivery.clientEmail = event.target.value;
                setClientEmail(event.target.value);
                break;  

            default:
                break;
        }
    };

    const submitModifications = async () =>{
        setLoading(true);
        const result = await createDelivery(newDelivery, loginUser.jwtToken);
        if(result !== "Not found"){

            swal({
                title: "Creación Exitosa",
                icon: "success",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/lastmile/board"});
            });
        }else{
            swal({
                title: "Creación No Exitosa",
                icon: "error",
                button: "Entiendo",
            });
        }
        setLoading(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="clientId"
                            name="clientId"
                            label={<Typography variant="body2">Identificación</Typography>}
                            value={clientId}
                            onChange={(e)=>handleChange(e, "clientId")}
                            autoComplete="id"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="clientName"
                            name="clientName"
                            label={<Typography variant="body2">Nombre completo</Typography>}
                            value={clientName}
                            onChange={(e)=>handleChange(e, "clientName")}
                            autoComplete="name"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="address"
                            name="address"
                            label={<Typography variant="body2">Dirección de entrega</Typography>}
                            value={address}
                            onChange={(e)=>handleChange(e, "address")}
                            autoComplete="address"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="clientEmail"
                            name="clientEmail"
                            label={<Typography variant="body2">Correo electrónico</Typography>}
                            value={clientEmail}
                            onChange={(e)=>handleChange(e, "clientEmail")}
                            autoComplete="email"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="clientPhone"
                            name="clientPhone"
                            label={<Typography variant="body2">Número celular</Typography>}
                            value={clientPhone}
                            onChange={(e)=>handleChange(e, "clientPhone")}
                            autoComplete="phone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "3.5rem"}}>
                        <Button onClick={submitModifications} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                            CREAR
                        </Button>
                    </Grid>
                </Grid>
                
                }
            </Box>
        </Box>
    )
}
