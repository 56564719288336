import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { b2bClient, municipalities, ukLocations} from "../../Api";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      marginRight:0
    },
    dialogStyle:{
      whiteSpace: "pre-line"
    }, 
    link: {
      color: "blue",
      cursor: "pointer",
    }
}));

const clientTypes = [
  {
    type: "person",
    label: "Individual"
  },
  {
    type: "company",
    label: "Company"
  },
];

const clientIdTypes = [
  {
    type: "nationalId",
    label: "National Identity Card"
  },
  {
    type: "passport",
    label: "Passport"
  },
  {
    type: "foreignId",
    label: "Other"
  },
];

const representativeIdTypes =[
  {
    type: "nationalId",
    label: "National Identity Card"
  },
  {
    type: "passport",
    label: "Passport"
  },
  {
    type: "foreignId",
    label: "Other"
  },
];

export default function IDInformation(props) {
    const{
        dataForParent,
        setMunicipality,
        setGeoLocation
    }=props

    const classes = useStyles();

    const ukterritories = ["England", "Scotland", "Wales", "Northern Ireland"];


    const [id, setID] = React.useState('');
  
    const handleChange = (event) => {
        dataForParent(event);
    }; 

    const [idType, setIdType] = React.useState("");

    const idTypeChange = (e) => {
      setIdType(e.target.value);
      dataForParent(e);
    };

    const[clientType, setClientType] = React.useState("");

    const clientTypeChange = (e) => {
      setClientType(e.target.value);
      dataForParent(e);
    };

    const[representativeIdType, setRepresentativeIdType] = React.useState("");

    const representativeIdTypeChange = (e) => {
      setRepresentativeIdType(e.target.value);
      dataForParent(e);
    };

    const[territory, setTerritory] = React.useState("");
    const[county, setCounty] = React.useState("");
    const[city, setCity] = React.useState("");

    const territoryChange = (event, value) => {
      if(!value){
          return;
      }

      setTerritory(value);
      setCounty("");
      setCity("");
  };
  
  const countyChange = (event, value) => {
      setCounty(value);
      setCity("");
  };

  const cityChange = (event, value) => {
      setCity(value);
  };

  React.useEffect(() => {
    
    if(territory && county && city){
      setGeoLocation({territory: territory, county: county, city: city});
    }
  }, [territory, county, city]);

    return (
      <Box
        sx={{
          display: "flex",
          // flexGrow: "1",
          width: "100%",
          // height: "80%",
          justifyContent: "center",
          alignContent:"center",
          // marginLeft: "1.5rem",
        }}
      >
        
        <Grid container spacing={3} sx={{justifyContent: "center", width: "95%"}}>
          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
              <TextField
                  sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                  variant="standard"
                  fullWidth={true}
                  id="clientType"
                  name="clientType"
                  select
                  label={<Typography variant="body2">Person Type</Typography>}
                  value={clientType}
                  onChange={(e)=>clientTypeChange(e)}
                  autoComplete="off"
              >
                  {clientTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>

          {clientType && clientType==="person" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="firstName"
                name="firstName"
                label="Name"
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="lastName"
                name="lastName"
                label="Surname"
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  sx={{textAlign: "start"}}
                  variant="standard"
                  fullWidth={true}
                  id="idType"
                  name="idType"
                  select
                  label={<Typography variant="body2">Identification Type</Typography>}
                  value={idType}
                  onChange={(e)=>idTypeChange(e)}
                  autoComplete="off"
              >
                  {clientIdTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="idNumber"
                name="idNumber"
                label="Identification Number"
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="Address"
                name="Address"
                label="Address"
                fullWidth
                autoComplete="given-address"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                  options={ukterritories}
                  getOptionLabel={(option) => option}
                  
                  id="territory"
                  name="territory"
                  clearOnEscape
                  autoComplete = {true}
                  noOptionsText = {"Territory not found"}
                  fullWidth
                  onChange={territoryChange}
                  // onInputChange={onChange}
                  renderInput={(params) => (
                      <TextField  {...params} label="Territory" variant="standard" />
                  )}
              />
              {territory && 
                <>
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete sx={{ width: "100%"}}
                        options={
                            [...new Set(
                                ukLocations
                                    .filter(location => location.Territory === territory)
                                    .map(location => location.County)
                            )]
                        }
                        getOptionLabel={(option) => option}
                        
                        id="county"
                        name="county"
                        clearOnEscape
                        autoComplete = {true}
                        noOptionsText = {"County not found"}
                        fullWidth
                        onChange={countyChange}
                        // onInputChange={onChange}
                        renderInput={(params) => (
                            <TextField  {...params} label="County" variant="standard" />
                        )}
                    />
                </Grid>
                {county &&
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete sx={{ width: "100%"}}
                            options={[
                                ...new Set(
                                    ukLocations
                                        .filter(location => location.Territory === territory && location.County === county)
                                        .map(location => location.Name)
                                )
                            ]}
                            getOptionLabel={(option) => option}
                            
                            id="city"
                            name="city"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"City or town not found"}
                            fullWidth
                            onChange={cityChange}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField  {...params} label="City or Town" variant="standard" />
                            )}
                        />
                    </Grid>
                }
                </>
            }
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    variant="standard"
                    // required
                    id="phone"
                    type="tel"
                    name="phone"
                    label="Phone Number"
                    fullWidth
                    // value={value}
                    autoComplete="given-phone"
                    onChange={handleChange}
                    // inputProps={{ maxLength: 10, minLength: 10}}
                  />
            </Grid>
            </>
          }
          {clientType && clientType==="company" &&
            <>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="firstName"
                name="firstName"
                label="Company Name"
                fullWidth
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="idNumber"
                name="idNumber"
                label="Identification Number"
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="Address"
                name="Address"
                label="Address"
                fullWidth
                autoComplete="given-address"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete sx={{ width: "100%"}}
                  options={ukterritories}
                  getOptionLabel={(option) => option}
                  
                  id="territory"
                  name="territory"
                  clearOnEscape
                  autoComplete = {true}
                  noOptionsText = {"Territory not found"}
                  fullWidth
                  onChange={territoryChange}
                  // onInputChange={onChange}
                  renderInput={(params) => (
                      <TextField  {...params} label="Territory" variant="standard" />
                  )}
              />
            </Grid>
              {territory && 
                  <>
                  <Grid item xs={12} sm={6} md={6}>
                      <Autocomplete sx={{ width: "100%"}}
                          options={
                              [...new Set(
                                  ukLocations
                                      .filter(location => location.Territory === territory)
                                      .map(location => location.County)
                              )]
                          }
                          getOptionLabel={(option) => option}
                          
                          id="county"
                          name="county"
                          clearOnEscape
                          autoComplete = {true}
                          noOptionsText = {"County not found"}
                          fullWidth
                          onChange={countyChange}
                          // onInputChange={onChange}
                          renderInput={(params) => (
                              <TextField  {...params} label="County" variant="standard" />
                          )}
                      />
                  </Grid>
                  {county &&
                      <>
                      <Grid item xs={12} sm={6} md={6}>
                          <Autocomplete sx={{ width: "100%"}}
                              options={[
                                  ...new Set(
                                      ukLocations
                                          .filter(location => location.Territory === territory && location.County === county)
                                          .map(location => location.Name)
                                  )
                              ]}
                              getOptionLabel={(option) => option}
                              
                              id="city"
                              name="city"
                              clearOnEscape
                              autoComplete = {true}
                              noOptionsText = {"City or town not found"}
                              fullWidth
                              onChange={cityChange}
                              // onInputChange={onChange}
                              renderInput={(params) => (
                                  <TextField  {...params} label="City or Town" variant="standard" />
                              )}
                          />
                      </Grid>
                      </>
                  }
                  </>
              }
            <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    variant="standard"
                    // required
                    id="phone"
                    type="tel"
                    name="phone"
                    label="Phone Number"
                    fullWidth
                    // value={value}
                    autoComplete="given-phone"
                    onChange={handleChange}
                    // inputProps={{ maxLength: 10, minLength: 10}}
                  />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ marginTop: "1rem", marginBottom: "0.1rem"}}>
                  <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                  <Typography variant="body1" sx={{color: "black", fontWeight: "bold"}}>Legal Representative</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeName"
                name="representativeName"
                label="Name"
                fullWidth
                autoComplete="given-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeLastName"
                name="representativeLastName"
                label="Surname"
                fullWidth
                autoComplete="family-name"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                  sx={{textAlign: "start"}}
                  variant="standard"
                  fullWidth={true}
                  id="representativeIdType"
                  name="representativeIdType"
                  select
                  label={<Typography variant="body2">Identification Type</Typography>}
                  value={representativeIdType}
                  onChange={(e)=>representativeIdTypeChange(e)}
                  autoComplete="off"
              >
                  {representativeIdTypes.map((typeEl) => (
                      <MenuItem key={typeEl.type} value={typeEl.type}>
                          {typeEl.label}
                      </MenuItem>
                  ))}
              </TextField> 
          </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                variant="standard"
                id="representativeIdNumber"
                name="representativeIdNumber"
                label="Identification Number"
                fullWidth
                autoComplete="idNumber"
                onChange={handleChange}
              />
            </Grid>
            </>
          }
        </Grid>
      </Box>
    );
}
