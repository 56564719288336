import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography, DialogContent } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CardImg from "../img/creditcards/card2.png";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../components/ui/Colors";
import {createCardPayment} from "../utilityFunctions/OpenCardUtil";
import swal from "sweetalert";
import Bancolombia from "../img/creditcards/logo-bancolombia.png";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

const paymentObject = {
    username: "",
    internalCardCode: "",
    operationAmount: "",
};

export default function HandleCreditCard(props) {

    const {loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(()=> {
        paymentObject.username = loginUser.accountId;
        setLoading(true);
        renderPorducts();
    }, []);
    
    const[products, setProducts] = React.useState("");

    const renderPorducts = () => {
        
        if(!props.location.state || !props.location.state.products ||!props.location.state.products.listGeneralProduct){
            return;
        };

        const productsInfo = props.location.state.products.listGeneralProduct;

        console.log(productsInfo);

        setProducts(
            <Grid container spacing={2} sx={{justifyContent: {xs: "center", sm: "center"}}}>
                {productsInfo.map((creditCard) => {
                    return(
                        <Grid key={creditCard.internalCode} item xs={12} sm={6} md={6} lg={6} sx={{ display: "flex", justifyContent: "center", marginTop: {xs: "3rem", sm: "2rem", md: "0.5rem"}, marginBottom: "0.5rem"}}>
                            <Card key={creditCard.internalCode} sx={{maxWidth: "400px", minWidth: {xs: "80%", sm: "90%", md: "300px", borderRadius: "5%"}}}>
                                <CardActions key={creditCard.internalCode} onClick={() => toPayment(creditCard)} sx={{cursor: "pointer"}}>
                                    <Grid key={creditCard.internalCode * 1} container>
                                        <Grid key={creditCard.internalCode * 2} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center"}}>
                                            <CardMedia
                                                component="img"
                                                image={CardImg}
                                                alt="Card"
                                            />
                                        </Grid>
                                        <Grid key={creditCard.internalCode * 3} item xs={12} sm={12} md={12} lg={12} sx={{width: "90%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "2.5rem", marginBottom: "2.5rem"}}>
                                            <Typography variant="h4" sx={{color: "black"}}>{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.availableCrLImit)}`}</Typography>
                                        </Grid>
                                        <Grid key={creditCard.internalCode * 10} item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
                                            <Grid key={creditCard.internalCode * 11} container sx={{width: {xs: "90%", sm:"90%", md: "80%"}}}>
                                                <Grid key={creditCard.internalCode * 4} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    <Typography variant="body2">Fecha proximo pago:</Typography>
                                                </Grid>
                                                <Grid key={creditCard.internalCode * 5} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                                    <Typography variant="body2">{`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(creditCard.importantDate.instantPaymentLimitDate))}`}</Typography>
                                                </Grid>
                                                <Grid key={creditCard.internalCode * 6} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    <Typography variant="body2">Pago mínimo:</Typography>
                                                </Grid>
                                                <Grid key={creditCard.internalCode * 7} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                                    <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.lowestpayment)}`}</Typography>
                                                </Grid>
                                                <Grid key={creditCard.internalCode * 8} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                                    <Typography variant="body2">Pago Total:</Typography>
                                                </Grid>
                                                <Grid key={creditCard.internalCod * 9} item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                                    <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(creditCard.totalPayment)}`}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Grid key={creditCard.internalCode * 12} container>
                                    <Grid item xs={12} sm={12} md={12} key={creditCard.internalCod * 13} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem"}}>
                                        <Button onClick={()=>toStatement(creditCard)} sx={{fontSize: "1rem", cursor: "pointer", color: "#03a9f4", fontWeight: "bold"}}>
                                            Detalle
                                        </Button>
                                    </Grid> 
                                </Grid>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        );

        setLoading(false);
    }

    const[paymentDialog, setPaymentDialog] = React.useState(false);

    const closePaymentDialog = () => {
        setPaymentDialog(false);
    };

    const[paymentAction, setPaymentAction] = React.useState("");

    const toPayment = (product) => {
        
        setPaymentAction(
            <List sx={{width: "100%",}}>
                <Grid container spacing={2}>
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                        <ListItemButton  onClick={() => handleMinimumPayment(product)}>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <Typography variant="body2">Pago mínimo:</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(product.lowestpayment)}`}</Typography>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                        <ListItemButton  onClick={() => handleTotalPayment(product.totalPayment)}>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "start", textAlign: "start"}}>
                                <Typography variant="body2">Pago Total:</Typography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} lg={6} sx={{display: "flex", justifyContent: "end", textAlign: "end"}}>
                                <Typography variant="body2">{`$ ${new Intl.NumberFormat('es-CO').format(product.totalPayment)}`}</Typography>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                </Grid>
            </List>
        );
        
        setPaymentDialog(true);

    };

    const handleMinimumPayment = async (product) => {
        console.log(product);
        paymentObject.operationAmount = product.lowestpayment;
        paymentObject.internalCardCode = product.internalCode;

        const paymentResponse = await createCardPayment(paymentObject, loginUser.jwtToken);

        if(!paymentResponse){
            swal({
                title: "Pago No Exitoso",
                text: "El pago no fue exitoso. Vuelve a intentarlo más tarde",
                icon: "error",
                button: "ok",
            }).then(() => {
                setPaymentDialog(false);
            });
            
            return;
        }

        swal({
            title: "¡Pago Exitoso!",
            icon: "success",
            button: "Súper",
        }).then(() => {
            setPaymentDialog(false);
        });
            
    };

    const handleTotalPayment = async (product) => {
        paymentObject.operationAmount = product.totalPayment;
        paymentObject.internalCardCode = product.internalCode;
        
        const paymentResponse = await createCardPayment(paymentObject, loginUser.jwtToken);

        if(!paymentResponse){
            swal({
                title: "Pago No Exitoso",
                text: "El pago no fue exitoso. Vuelve a intentarlo más tarde",
                icon: "error",
                button: "ok",
            }).then(() => {
                setPaymentDialog(false);
            });
            
            return;
        }

        swal({
            title: "¡Pago Exitoso!",
            icon: "success",
            button: "Súper",
        }).then(() => {
            setPaymentDialog(false);
        });
    };

    const toStatement = (product) => {
        console.log("to statement...")

        history.push({
            pathname: "/creditCard/statement",
            state:{
                product: product,
            }
        });
    };


    return (
        <Box sx={{display: "flex", justifyContent: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: {xs: "start", sm: "center", md: "center"},
                    width: "90%",
                    height: "100%",
                    textAlign: "center",
                    overflowY: "scroll",
                    
                }}
            >
                {products}
                <Dialog open={paymentDialog} onClose={closePaymentDialog}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", alignItems: "center", justifyContent: "start", marginTop: "1rem", marginLeft: "3rem"}}>
                            <Box
                                component="img"
                                sx={{
                                    height: 60,
                                    width: 210,
                                }}
                                alt="Logo"
                                src={Bancolombia}
                            />
                        </Grid>
                    </Grid>
                    <DialogContent>
                        {paymentAction}                 
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closePaymentDialog} variant="contained" sx={{backgroundColor: Colors.primary,}}>
                            ATRÁS
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            }
        </Box>
    )
}
