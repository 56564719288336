import React from 'react';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import swal from "sweetalert";
import { createDeal, getTrustClients } from "../../utilityFunctions/DealsUtil";
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';


const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dealsTypes = [
    {
        label: "Cliente fiducia a fondo",
        type: "PRF"
    },
    {
        label: "Adquirente a fondo",
        type: "ADF"
    },
    {
        label: "Cliente VIP a fondo",
        type: "VFO"
    },
    {
        label: "Cliente interesante para fiducia",
        type: "IPR"
    },
    {
        label: "Cliente VIP a Promotor",
        type: "VPR"
    },
    {
        label: "Promotor a promotor",
        type: "PRP"
    },
    {
        label: "Cliente fondo a fondo",
        type: "FOF"
    },
];

const levels = [
    {
        level: "high",
        label: "Alta",
    },
    {
        level: "medium",
        label: "Media",
    },
    {
        level: "low",
        label: "Baja",
    },
];

let newDeal = {
    clientName: "",
    definitionsName: "",
    priority: "",
    type: "",

};

export default function CreateDeal(props) {

    const {loginUser} = props 

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        newDeal = {
            clientName: "",
            definitionsName: "",
            priority: "",
            type: "",
            value: "",
        
        };

        loadClients();
    }, []);

    const [clientNameArray, setClientNameArray] = React.useState("");
    const [type, setType] = React.useState("");
    const [priority, setPriority] = React.useState("");
    const [dealValue, setDealValue] = React.useState("");

    const loadClients = async () => {
       const allClients = await getTrustClients(loginUser.jwtToken);

       setClientNameArray(
            {
                options: allClients,
                getOptionLabel: (option) => option.name,
            }
       );

    };

    const handleChange = (event, definition) => {
        switch(definition){
            case "type":
                newDeal.type=event.target.value;
                newDeal.definitionsName = dealsTypes.find((el) => el.type === event.target.value).label
                setType(event.target.value);
                break;
            case "priority":
                newDeal.priority=event.target.value;
                setPriority(event.target.value);
                break;
            case "dealValue":
                newDeal.value=event.floatValue; 
                setDealValue(event.floatValue);
                break; 

            default:
                break;
        }
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        newDeal.clientName=value.name;
    };

    const onChange = () => {

    };

    const submitModifications = async () =>{
        setLoading(true);
        const result = await createDeal(newDeal, loginUser.jwtToken);
        if(result !== "Not found"){

            swal({
                title: "Creación Exitosa",
                icon: "success",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/commercial/deals"});
            });
        }else{
            swal({
                title: "Creación No Exitosa",
                icon: "error",
                button: "Entiendo",
            });
        }
        setLoading(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "50%",
                height: "100%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
        >

        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        {...clientNameArray}
                        id="clientsNames"
                        clearOnEscape
                        autoComplete = {true}
                        noOptionsText = {"Cliente no existe"}
                        // sx={{ width: 600 }}
                        onChange={onChangeAutocomplete}
                        onInputChange={onChange}
                        renderInput={(params) => (
                            <TextField {...params} label="Nombre cliente" variant="standard" />
                        )}
                    /> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        fullWidth={true}
                        id="type"
                        name="type"
                        select
                        label={<Typography variant="body2">Tipo oportunidad</Typography>}
                        value={type}
                        onChange={(e)=>handleChange(e, "type")}
                        autoComplete="off"
                    >
                        {dealsTypes.map((typeEl) => (
                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                {typeEl.label}
                            </MenuItem>
                        ))}
                    </TextField> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        autoComplete="off"
                        fullWidth={true}
                        id="priority"
                        name="priority"
                        select
                        label={<Typography variant="body2">Prioridad oportunidad</Typography>}
                        value={priority}
                        onChange={(e)=>handleChange(e, "priority")}
                    >
                        {levels.map((levelEl) => (
                            <MenuItem key={levelEl.level} value={levelEl.level}>
                                {levelEl.label}
                            </MenuItem>
                        ))}
                    </TextField>  
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "100%", height: "50%"}}
                        name="dealValue"
                        customInput={TextField}
                        prefix={'$ '}
                        type="text"
                        label={<Typography variant="body2">Valor oportunidad</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={dealValue}
                        onValueChange={(values) => {
                            // const {formattedValue, value, floatValue} = values;
                            handleChange(values, "dealValue");
                        }}
                    />
                </Grid>            
                <Grid item xs={12} sm={12} md={12} sx={{marginTop: "3.5rem"}}>
                    <Button onClick={submitModifications} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                        CREAR
                    </Button>
                </Grid>
            </Grid>
        }
        </Box>
    </Box>
    )
}
