import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography, Button, Dialog } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import{getAllEduPrograms, inactivateEduProgram, activateEduProgram, deleteEduProgram} from '../../../utilityFunctions/EduMarketUtil';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import swal from "sweetalert";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};



export default function EduProgramsList(props) {

  const{loginUser} = props;

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
      renderProgramsReport();
  }, []);

  const initialValues = async () => {
    setLoading(true);
    const response = await getAllEduPrograms(loginUser.jwtToken);
    setLoading(false);

    if(!response){
        
        swal({
            title: "No users found",
            icon: "info",
            button: "OK",
        });
    }
    
    return response;

  };

  const [programsReport, setProgramsReport] = React.useState(null);

  const renderProgramsReport = async () => {
      
      const eduPrograms = await initialValues();
  
      if(!eduPrograms){
          return;
      }
  
      setProgramsReport(
        <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
            {eduPrograms.map((eduProgram) => {
                return(
                    <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                        <ListItemButton key={generateRandomKey()} onClick={() => handleEduProgram(eduProgram)}>
                            <Grid key={generateRandomKey()}  container spacing={1}>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{new Intl.DateTimeFormat('en-GB', dateOptions).format(new Date(eduProgram.creationDate))}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{eduProgram.serviceType}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{eduProgram.title}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{`£ ${new Intl.NumberFormat('en-GB').format(eduProgram.price)}`}</Typography>
                                </Grid>
                                <Grid key={generateRandomKey()} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                                    <Typography key={generateRandomKey()} variant="body2">{!eduProgram.usersEnrolled ? "0" : eduProgram.usersEnrolled.length}</Typography>
                                </Grid>
                            </Grid>
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
      );
    }

  

    const[selectedProgram, setSelectedProgram] = React.useState(null);
    const[open, setOpen] = React.useState(false);

    const handleEduProgram = (eduProgram) => {

      setSelectedProgram(eduProgram);

    };

    React.useEffect(() => {
        if(selectedProgram){
            setOpen(true);
        }
    }, [selectedProgram]);

    const handleClose = () => {
        setSelectedProgram(null);
        setOpen(false);
    };

    const handleInactivate = async () => {

        handleClose();

      setLoading(true);
      const blockResponse = await inactivateEduProgram({id: selectedProgram.id}, loginUser.jwtToken);
      setLoading(false);


      if(!blockResponse){
          swal({
              title: "Program not inactivated",
              icon: "error",
              button: "OK",
          });
          return;
      };

      swal({
          title: "Program inactivated",
          icon: "success",
          button: "OK",
      });

    };

    const handleActivate = async () => {

        handleClose();

        setLoading(true);
        const blockResponse = await activateEduProgram({id: selectedProgram.id}, loginUser.jwtToken);
        setLoading(false);


        if(!blockResponse){
            swal({
                title: "Program not activated",
                icon: "error",
                button: "OK",
            });
            return;
        };

        swal({
            title: "Program activated",
            icon: "success",
            button: "OK",
        });

    };

    const handleDelete = async () => {

        handleClose();

        setLoading(true);
        const blockResponse = await deleteEduProgram({id: selectedProgram.id}, loginUser.jwtToken);
        setLoading(false);


        if(!blockResponse){
            swal({
                title: "Program not deleted",
                icon: "error",
                button: "OK",
            });
            return;
        };

        swal({
            title: "Program deleted",
            icon: "success",
            button: "OK",
        });

    };

    const generateRandomKey = () => {
      const randomString = Math.random().toString(36).slice(2);
      const timestamp = Date.now().toString(36);
      return randomString + '_' + timestamp;
    };  

  
  return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
      <Box
          sx={{
              display: "flex",
              // flex: 1,
              justifyContent: "center",
              alignItems: {xs: "start", sm: "center"},
              width: "90%",
              height: "100%",
              textAlign: "center",
              overflowY: "scroll",
          }}
      >
      {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
          <>
          <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                  <Grid container sx={{marginTop: "1rem"}}>
                      <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                          <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Created on</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                          <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Service Type</Typography>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center"}}>
                          <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Title</Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                          <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Price</Typography>
                      </Grid>
                      <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center"}}>
                          <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Enrolled</Typography>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                  {programsReport}
              </Grid>
          </Grid>
          <Dialog open={open} onClose={handleClose} 
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: {xs: "80%", sm: "60%", md: "40%"},
                                height: "60%"
                            },
                        },
                    }}
                >
                    <Grid container direction="row" spacing={2} sx={{display: "flex", justifyContent: "center"}}>
                        <Grid item xs={12} sm={12} md={12} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <ListItemButton onClick={() => handleInactivate()}>
                                        <Typography variant="body2">Inactivate Service</Typography>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <ListItemButton onClick={() => handleActivate()}>
                                        <Typography variant="body2">Activate Service</Typography>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                    <ListItemButton onClick={() => handleDelete()}>
                                        <Typography variant="body2">Delete Service</Typography>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx= {{display: "flex", justifyContent: "center", marginBottom: "1.5rem"}}>
                            <Button variant="contained" onClick={handleClose}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
          </>
      }
      </Box>
    </Box>
  )
}
