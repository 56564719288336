import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { useHistory } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import Colors from "../../components/ui/Colors";


const useStyles = makeStyles((theme) => ({
  
    dialogStyle:{
      whiteSpace: "pre-line",
      
    },
  
    dialog:{
      backgroundColor: "#DCDCDC",
    },
    
    linkStyle:{
      cursor: "pointer",
      color: "inherit",
      textAlign: "center"
    },
  
    actionArea: {
      borderRadius: 16,
      transition: '0.2s',
      '&:hover': {
        transform: 'scale(1.1)',
        backgroundColor: Colors.primary,
      },
    },
  
    cardImage:{
      width: "20%",
      justifySelf: "center"
    }
  
  }));

export default function RootProfile() {

    let history = useHistory();

    const classes = useStyles();

    React.useEffect(() =>{
        chooseAdminRequest();
    }, []);

    const rootCreate = () =>{
        history.push({pathname: "/root-create"});
    };

    const rootChange = () =>{
        history.push({pathname: "/root-change"});
    };

    const handleApiCredentials = () => {
      history.push({pathname: "/root-api"});  
    };

    const[adminChoose, setAdminChoose] = React.useState(false);

    const handleChooseAdminClose = () =>{
        setAdminChoose(false)
    };

    const chooseAdminRequest = () =>{
        setAdminChoose(true);
    };

  return (
    <div>
         <Dialog open={adminChoose} onClose={handleChooseAdminClose}>
            <DialogTitle style={{color: "blue"}}>TIPO DE OPERACIÓN</DialogTitle>
            <DialogContent className={classes.actionArea}>
                <Card sx={{ minWidth: 150 }}>
                    <CardActionArea onClick={rootCreate}>
                        <FaSearch size={20} />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                CREAR
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </DialogContent>

            <DialogContent className={classes.actionArea}>
                <Card sx={{ minWidth: 150 }}>
                    <CardActionArea onClick={rootChange}>
                        <IoSettings size={20} />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                MODIFICAR
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </DialogContent>

            <DialogContent className={classes.actionArea}>
                <Card sx={{ minWidth: 150 }}>
                    <CardActionArea onClick={handleApiCredentials}>
                        <IoSettings size={20} />
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Credenciales API
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </DialogContent>
          </Dialog>
          
    </div>
  )
}
