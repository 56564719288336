import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography } from "@mui/material";
import Colors from "../../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import {approvePayment} from "../../../utilityFunctions/MarketPlaceUtil";
import swal from "sweetalert";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

const approveObject = {
  id: "",
}
let cattleSelected;

let currentStatus = "active";

export default function CattleDetail(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // initialValues(); //Testing
        currentStatus = "active";
        initialValues();
      }, []);
      

    const [purchases, setPurchases] = React.useState();

    const initialValues = () => {
        if(props.location.state && props.location.state.selectedCattle){
          
            if(currentStatus === "pending"){
              cattleSelected = props.location.state.selectedCattle.filter((el) => el.status === currentStatus);
            }
            else{
              cattleSelected = props.location.state.selectedCattle.filter((el) => el.status !== "pending");
            }

          cattleSelected.sort((a, b) => new Date(b.investmentDate) - new Date(a.investmentDate));

          renderPurchases();

        }
    };

    const renderPurchases = async () => {

        if(!cattleSelected){
            return;
        }

        console.log(cattleSelected);
        setPurchases(
          <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
            <Grid container spacing={2}>
              {cattleSelected.map((purchase) => {
                return(
                  <ListItem key={generateRandomKey()} disablePadding divider sx={{marginBottom: "1.5rem"}}>
                    <ListItemButton  key={generateRandomKey()} onClick={() => openPaymentBox(purchase)}>
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                              {`${purchase.name} ${purchase.lastName}`}
                          </Typography>
                      </Grid>
                      {/* <Grid key={purchase.id} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                          <Typography key={purchase.id} variant="body1" sx={{color: "black"}}>
                              {`${purchase.username}`}
                          </Typography>
                      </Grid> */}
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                              {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(purchase.investmentDate))}`}
                          </Typography>
                      </Grid>
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: { xs: "none", sm: "flex", md: "flex"}, justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                              {`$ ${new Intl.NumberFormat('es-CO').format(purchase.initialBalance)}`}
                          </Typography>
                      </Grid>
                      <Grid key={generateRandomKey()} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <Typography key={generateRandomKey()} variant="body1" sx={{color: "black"}}>
                            {`${new Intl.NumberFormat('es-CO').format(purchase.investmentShare * 100)} %`}
                          </Typography>
                      </Grid>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </Grid>
          </List>
        );
    
    };

    const handleDetail = async () => {
      closePaymentBox();

      setLoading(true);
      const changedInvestment = await approvePayment(approveObject, loginUser.jwtToken);
      setLoading(false);

      if(!changedInvestment){

        swal({
          title: "Ocurrio un error",
          icon: "error",
          button: "ok",
        });

        return;
      }

      swal({
        title: "Pago aprobado",
        icon: "success",
        button: "súper",
      });

      approveObject.id="";

      

    };

    const [paymentBox, setPaymentBox] = React.useState(false);

    const closePaymentBox = () => {
      setPaymentBox(false);
    };

    const openPaymentBox = (purchase) => {
      approveObject.id = purchase.id

      setPaymentBox(true);

    };

    const changeStatus = () => {

      if(currentStatus === "active"){
        currentStatus="pending";
      }
      else{
        currentStatus="active"
      }

      initialValues();
      
    };

    

    const generateRandomKey = () => {
      const randomString = Math.random().toString(36).slice(2);
      const timestamp = Date.now().toString(36);
      return randomString + '_' + timestamp;
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
          
            <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: {xs: "start", sm: "center"},
                width: "90%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Grid container sx={{marginTop: "1rem"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "1.5rem"}}>
                      <Button onClick={changeStatus} variant="contained" sx={{backgroundColor: Colors.primary}}>
                          {currentStatus === "active" ? "Pendientes" : "Activos"}
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Nombre</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Fecha</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Valor</Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                      <Typography variant="body1" sx={{color: "black", fontWeight: "bold", textDecoration: 'underline',}}>Participación</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                  {purchases}
                </Grid>
              </Grid>
            }
              <Dialog open={paymentBox} onClose={closePaymentBox} fullWidth maxWidth="sm">
                  <Box sx={{width: "100%", display: "flex", justifyContent: "center", marginTop: "1rem", marginBottom: "2.5rem"}}>
                    <Typography variant="h4">Aprobación Pago</Typography>
                  </Box>
                  <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                      <Button onClick={closePaymentBox} variant="contained" sx={{backgroundColor: Colors.primary, marginRight: "1rem"}}>
                          ATRÁS
                      </Button>
                      <Button onClick={handleDetail} variant="contained" sx={{backgroundColor: Colors.primary, marginRight: "1rem"}}>
                          LISTO
                      </Button>
                  </DialogActions>
              </Dialog>
            
            </Box>
          
        </Box>
    )
}
