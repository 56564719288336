import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Yoga from "../../img/employees-rewards/yoga.jpg";
import Marshall from "../../img/employees-rewards/marshall.jpg";
import Dinner from "../../img/employees-rewards/dinner.jpg";
import Travel from "../../img/employees-rewards/travel.jpg";
import Study from "../../img/employees-rewards/study.jpg";
import Gym from "../../img/employees-rewards/gym.jpg";
import Clothes from "../../img/employees-rewards/clothes.jpg";

const rewards = [
    {
        pic: Yoga,
        title: "YOGA",
        description: "5 clases gratis",
        cost: "1.000 puntos"
    },
    {
        pic: Clothes,
        title: "ROPA",
        description: "-25% en marcas aliadas",
        cost: "1.100 puntos"
    },
    {
        pic: Dinner,
        title: "CENA",
        description: "Cena para dos",
        cost: "1.500 puntos"
    },
    {
        pic: Travel,
        title: "TIQUETES",
        description: "-10% Tiquetes",
        cost: "2.000 puntos"
    },
    {
        pic: Study,
        title: "ESTUDIO",
        description: "2 cursos gratis",
        cost: "2.500 puntos"
    },
    {
        pic: Gym,
        title: "GIMNASIO",
        description: "Año gratis",
        cost: "3.500 puntos"
    },
];

export default function LoyaltyRewards() {

    const [products, setProducts] = React.useState('');

    const availableProducts = () => {

        setProducts(
            
        );

    };

    return (
    <Box
        sx={{
            display: "flex",
            flexGrow: "1",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            backgroundColor: "#ededed",
            // backgroundColor: "#A8E6CE",
            // backgroundImage: "linear-gradient(to bottom right, #0CCDA3, #C1FCD3)"
            // height: "85%",
        }}
    >
        <Grid container spacing={3} sx={{alignItems: "center", marginTop:{xs: "1.5rem", sm: "1.5rem", md: 0}, maxWidth: "900px"}}>
            {rewards.map((reward) => {
                return(
                <>
                    <Grid key={reward.title} item xs={12} sm={6} md={4} lg={4} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <Card key={reward.title} sx={{width: "80%", height: "100%"}}>
                            <Grid key={reward.title+"a"} container direction = "column">
                                <Grid key={reward.title+"b"} item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Box 
                                        component="img"
                                        sx={{
                                        height: "90%",
                                        width: "100%",
                                        justifyContent: "center",
                                        }}
                                        alt="Premio"
                                        src={reward.pic} 

                                    >

                                    </Box>
                                </Grid>
                                <Grid key={reward.title+"c"} item xs={12} sm={12} md={12} sx={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "0,5rem", marginBottom: "1rem"}}>
                                    <Typography key={reward.title+"a"}  variant="body2" sx={{fontWeight: "bold", width: "100%"}}>{reward.title}</Typography>
                                    <Typography key={reward.title+"b"} variant="body2" sx={{ width: "100%"}}>{reward.description}</Typography>
                                    <Typography key={reward.title+"c"} variant="body2" sx={{ width: "100%"}}>{reward.cost}</Typography>
                                </Grid>
                            </Grid>
                        </Card>   
                    </Grid>
                </>
                )
            })}
        </Grid>
    </Box>
    )
}
