import React from 'react';
import {getFullFrontPurchases, getCodes} from "../../../utilityFunctions/MarketPlaceUtil";
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Colors from "../../../components/ui/Colors";
import TextField from '@mui/material/TextField';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { useHistory} from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


let selectedCattle = "";

export default function CattlesSearch(props) {
    const{loginUser} = props;

    const history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        // getAllCattles();
        getProductsCodes();
    },[])

    const [codes, setCodes] = React.useState("");

    const getProductsCodes = async () => {
        setLoading(true);
        const codes = await getCodes(loginUser.jwtToken);

        codes.sort((a, b) => {

            const codeA = a.code.toUpperCase();
            const codeB = b.code.toUpperCase();

            if (codeA < codeB) {
                return -1;
            } else if (codeA > codeB) {
                return 1;
            } else {
                return 0;
            }
        });

        setLoading(false);
        setCodes(
         {
             options: codes,
             getOptionLabel: (option) => option.code,
         }
        );
    };

    const onChangeAutocomplete = (event, value) => {
        if(!value){
            return;
        }
        selectedCattle = value;
    };

    const goToDetail = async () => {

        setLoading(true);
        const purchasesResponse = await getFullFrontPurchases(selectedCattle.code, loginUser.jwtToken);
        setLoading(false);

        if(!purchasesResponse){
            return;
        }
        
        history.push({ 
            pathname: "/info/purchase-detail",
            state:{
                selectedCattle: purchasesResponse,
            }
        });

    }
    
    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
             <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2} sx={{justifyContent: "center"}}>
                    <Grid item xs={12} sm={12} md={12} sx={{width: {xs: "90%", sm: "80%", md: "50%"}, maxWidth: {xs: "90%", sm: "80%", md: "50%"}}}>
                        <Autocomplete
                            {...codes}
                            id="cattles"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Lote no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeAutocomplete}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label={<Typography variant="body2">Código del lote</Typography>} variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={goToDetail} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}

