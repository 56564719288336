import React from 'react';
import {getCodes, changeProductsStatus} from "../../../utilityFunctions/MarketPlaceUtil";
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../../components/ui/Colors";
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import swal from "sweetalert";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const changeObject = {
    code: "",
    status: "",
}

const statusOptions = ["Operando", "Pagado"]

export default function ChangeCattleStatus(props) {

    const {loginUser} = props

    const [loading, setLoading] = React.useState(false);
    const [cattleStatus, setCattleStatus] = React.useState("");

    React.useEffect(() => {
        
        getProductsCodes();

    }, []);

    const [codes, setCodes] = React.useState("");

    const getProductsCodes = async () => {
        const codes = await getCodes(loginUser.jwtToken); 
 
        setCodes(
         {
             options: codes,
             getOptionLabel: (option) => option.code,
         }
        );
     };

    const onChangeAutocomplete = (event, value) => {
        if(!value){
            return;
        }
        changeObject.code = value.code;
    };

    const changeSelected = (e) => {
        setCattleStatus(e.target.value);
        changeObject.status = e.target.value;
    };

    const handelChange = async () => {
        setLoading(true);
        const changingResult = await changeProductsStatus(changeObject, loginUser.jwtToken);
        setLoading(false);

        if(!changingResult){
            swal({
                title: "Error",
                text: "Ocurrio un error al cambiar el estado del lote",
                icon: "error",
                button: "Ok",
            });
            return; 
        }

        swal({
            title: "Cambio Exitoso",
            icon: "success",
            button: "Súper",
        });

    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                    <Grid container direction="row" spacing={2} sx={{width: {xs: "90%", sm: "60%", md: "45%"}}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Autocomplete
                                {...codes}
                                sx={{width: "100%"}}
                                id="codes"
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"Lote no existe"}
                                // sx={{ width: 600 }}
                                onChange={onChangeAutocomplete}
                                // onInputChange={onChange}
                                renderInput={(params) => (
                                    <TextField {...params} label={<Typography variant="body2">Código del lote</Typography>} variant="standard" />
                                )}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                sx={{textAlign: "start"}}
                                variant="standard"
                                fullWidth={true}
                                id="cattleStatus"
                                name="cattleStatus"
                                select
                                label={<Typography variant="body2">Nuevo Estado</Typography>}
                                value={cattleStatus}
                                onChange={(e)=>changeSelected(e)}
                                autoComplete="off"
                            >
                                {statusOptions.map((typeEl) => (
                                    <MenuItem key={typeEl} value={typeEl}>
                                        {typeEl}
                                    </MenuItem>
                                ))}
                            </TextField> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={handelChange} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cambiar
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}
