import React from "react"
import { useHistory } from "react-router-dom"
import ContentForm from "../../layout/ContentForm"
import PageLabel from "../../components/ui/PageLabel"
import AppButton from "../../components/ui/AppButton"
import Colors from "../../components/ui/Colors"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Appstore from "../../img/download-stores/Appstore.png";
import Googleplaystore from "../../img/download-stores/Googleplaystore.png";

export default function QRRegister(props) {
  const history = useHistory()

    /**
   * Dialog box to download Google Authenticator
   */
     const [download, setDownload] = React.useState(true);

     function handleDownload(){
       setDownload(true);
     };
     
     function handleClose(){
       setDownload(false);
     };

  return (
    <div
    style={{
      display: "flex",
      // flexGrow: "1",
      width: "100%",
      height: "80%",
      justifyContent: "center",
      // alignContent:"center",
    }}
  >
    <ContentForm style={{ overflowX: "hidden" }}>
      <PageLabel
        style={{
          color: Colors.primary
        }}
      >
        ESCANEA
      </PageLabel>
      {
        props.location.state.qrImg &&
        <img
          src={props.location.state.qrImg}
          alt="NOT RENDERED"
        />
      }
      <AppButton 
        primary 
        width={"80%"}
        onClick={() => history.push({pathname: "/login"})}
      >
        Lo Tengo
      </AppButton>
        <React.Fragment>
          <Dialog open={download} onClose={handleDownload} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">GOOGLE AUTH. PARA MAYOR SEGURIDAD</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Da click en la imagen que corresponda a tu dispositivo y descarga la aplicación <span color={"blue"}>GoogleAuthenticator.</span>
              </DialogContentText>
              <DialogContentText>
                Esta aplicación te permite escanear el código QR presentado a continuación y te dará un código
                que cambia con el tiempo para que tu experiencia en WUPO sea muy segura!
              </DialogContentText>
              <DialogContentText>
                Cuando hayas descargado la aplicación oprime el botón "Listo" para continuar.
              </DialogContentText>
              <DialogContentText>
               Cuando quieras ingresar al mundo WUPO solo tienes que poner tu usuario, clave y el token dinámico
              </DialogContentText>
              <div
                style={{
                  display: "grid",
                  // gridRow: "50% 50%",
                  justifyContent: "center",
                  marginTop: 30
                }}
              >
                <img 
                  style={{
                    display: "flex",
                    height: 61,
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 9,
                    cursor: "pointer",
                  }}
                  src={Appstore} alt="QR not rendered" 
                  onClick={() => {
                    window.open("https://apps.apple.com/es/app/google-authenticator/id388497605", "_blank")
                  }}
                />
                <img 
                  style={{
                    display: "flex",
                    height: 90,
                    marginTop: 0,
                    marginLeft: -20,
                    marginRight: 9,
                    cursor: "pointer",
                  }}
                  src={Googleplaystore} alt="QR not rendered"
                  onClick={() => {
                    window.open("https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=es_CO&gl=US", "_blank")
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                  Listo
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
    </ContentForm>
    </div>
  )
}