import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, Typography } from "@mui/material";
import Colors from "../../components/ui/Colors";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { useHistory} from "react-router-dom";

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
};

export default function SectorsList(props) {

    const history = useHistory();

    React.useEffect(()=> {
        renderSectors();
    }, []);

    const [listSectors, setListSectors] = React.useState("");

    const renderSectors = () => {
        if(props.location.state && props.location.state.sectors){
            const sectorsArray = props.location.state.sectors;
            setListSectors(
                <List sx={{width: "100%", marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                <Grid container spacing={2}>
                    {sectorsArray.map((client) => {
                        return(
                        <>
                            <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                <ListItemButton onClick={() => clientDetail(client)}>
                                    <Grid key={client.id} item xs={4} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Box key={client.id}
                                            sx={{
                                                borderRadius: "25%",
                                                width: "3rem",
                                                height: "3rem",
                                                textAlign: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: "1.5rem",
                                                backgroundColor: Colors.secondary,
                                                color: "white",
                                            
                                            }} 
                                        >
                                            CL
                                        </Box>
                                    </Grid>
                                    <Grid key={client.id + 1} item xs={5} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={client.id + 1} variant="body1" sx={{color: "black"}}>
                                            {`${client.name}`}
                                        </Typography>
                                    </Grid>
                                    <Grid key={client.id + 2} item xs={2} sm={2} md={2} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={client.id + 2} variant="body2" sx={{}}>
                                            {client.clientType === "fund" ? "Cliente Fondo" : "Cliente Fidecomiso"}
                                        </Typography>
                                    </Grid>
                                    <Grid key={client.id + 3} item xs={3} sm={3} md={3} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={client.id + 3} variant="body2" sx={{fontWeight: "bold"}}>
                                            {`$ ${new Intl.NumberFormat('es-CO').format(client.trustProducts[0].value)}`}
                                        </Typography>
                                    </Grid>
                                    <Grid key={client.id + 4} item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography key={client.id + 4} variant="body2" sx={{fontWeight: "bold"}}>
                                            {`${new Intl.DateTimeFormat('es-CO', dateOptions).format(new Date(client.trustProducts[0].date))}`}
                                        </Typography>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                        </>
                        )
                    })}
                </Grid>
            </List>
            );

        }
    };

    const clientDetail = (client) =>{
        console.log(client)

        history.push({ 
            pathname: "/commercial/clientDetail",
            state:{
                client: client
            }
        });
    };

    return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "75%",
                height: "100%",
                textAlign: "center",
                overflowY: "scroll",
            }}
        >
            {listSectors}
        </Box>
    </Box>
    )
}
