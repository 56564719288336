import {wupoServerUri} from '../Api';

const createEduProgram = async (formData, jwtToken) => {
  
    console.log(formData.get("selectedImage"));
    console.log(formData.get("newProduct"));

    const response = await  fetch(`${wupoServerUri.devnet}/api/secure/newEduProgram`, {
          method: "POST",
          body: formData,
        //   credentials: 'include',
          headers: {
            "Authorization": `Bearer ${jwtToken}`,
            // "Content-Type": "application/json; charset=UTF-8",
          },
    }).catch((error) => {
        console.log("Error: " + error);
    });

    console.log(response.status);
    if(response.status === 200){
        return response.status;
    }
    else{
        return null;
    }
}

const modifyEduProgram = async (formData, jwtToken) => {
  
  console.log(formData.get("selectedImage"));
  console.log(formData.get("newProduct"));

  const response = await  fetch(`${wupoServerUri.devnet}/api/secure/modifyEduProgram`, {
        method: "POST",
        body: formData,
      //   credentials: 'include',
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          // "Content-Type": "application/json; charset=UTF-8",
        },
  }).catch((error) => {
      console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200){
      return response.status;
  }
  else{
      return null;
  }
}

const getActiveProducts = async(jwtToken) => {
  
  
    const response = await fetch(`${wupoServerUri.devnet}/api/secure/getEduPrograms`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${jwtToken}`,
          // "Content-Type": "application/json; charset=UTF-8",
        },
    })
    .catch((error) => {
      console.log("Error: " + error);
    });

    console.log(response.status);
    if(response.status === 200 ){
      const responseData = await response.json();
      console.log(responseData);

      const dataAndImages = responseData.map((data) => {
        const byteImage = Buffer.from(data.edProgramPic.picture, 'base64');
        data.edProgramPic.picture = new Blob([byteImage], {type: "application/octet-stream"});
        return data;
      });

      console.log(dataAndImages);

      return dataAndImages;
    }

    else {
      return null;
    }
}

const getClientProducts = async(jwtToken) => {
  
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getProgramsByClient`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const responseData = await response.json();
    console.log(responseData);

    const dataAndImages = responseData.map((data) => {
      const byteImage = Buffer.from(data.edProgramPic.picture, 'base64');
      data.edProgramPic.picture = new Blob([byteImage], {type: "application/octet-stream"});
      return data;
    });

    console.log(dataAndImages);

    return dataAndImages;
  }

  else {
    return null;
  }
}

const enrollUser = async( objectRequest ,jwtToken) => {

  console.log(objectRequest)
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/enrollUser`, {
      method: "POST",
      body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const investments = await response.json();
    console.log(investments);

    return investments;
  }

  else {
    return null;
  }
}

const getProviderPrograms = async( jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/secure/getProgramsByProvider`, {
      method: "POST",
      // body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const investments = await response.json();
    console.log(investments);

    return investments;
  }

  else {
    return null;
  }
}

const getEduUsers = async( jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/getEduUsers`, {
      method: "POST",
      // body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const investments = await response.json();
    console.log(investments);

    return investments;
  }

  else {
    return null;
  }
}

const getAllEduPrograms = async( jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/getAllEduPrograms`, {
      method: "POST",
      // body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const investments = await response.json();
    console.log(investments);

    return investments;
  }

  else {
    return null;
  }
}

const getEduEnrollments = async( jwtToken) => {
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/getEduEnrollments`, {
      method: "POST",
      // body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        // "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const investments = await response.json();
    console.log(investments);

    return investments;
  }

  else {
    return null;
  }
}

const blockEduUser = async( objectRequest ,jwtToken) => {

  console.log(objectRequest)
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/blockEduUser`, {
      method: "POST",
      body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const data = await response.json();
    console.log(data);

    return data;
  }

  else {
    return null;
  }
}

const unblockEduUser = async( objectRequest ,jwtToken) => {

  console.log(objectRequest)
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/unblockEduUser`, {
      method: "POST",
      body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const investments = await response.json();
    console.log(investments);

    return investments;
  }

  else {
    return null;
  }
}

const inactivateEduProgram = async( objectRequest ,jwtToken) => {

  console.log(objectRequest)
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/inactivateEduProgram`, {
      method: "POST",
      body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const data = await response.json();
    console.log(data);

    return data;
  }

  else {
    return null;
  }
}

const activateEduProgram = async( objectRequest ,jwtToken) => {

  console.log(objectRequest)
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/activateEduProgram`, {
      method: "POST",
      body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const data = await response.json();
    console.log(data);

    return data;
  }

  else {
    return null;
  }
}

const deleteEduProgram = async( objectRequest ,jwtToken) => {

  console.log(objectRequest)
  
  const response = await fetch(`${wupoServerUri.devnet}/api/admin/deleteEduProgram`, {
      method: "POST",
      body: JSON.stringify(objectRequest),
      credentials: 'include',
      headers: {
        "Authorization": `Bearer ${jwtToken}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
  })
  .catch((error) => {
    console.log("Error: " + error);
  });

  console.log(response.status);
  if(response.status === 200 ){
    const data = await response.json();
    console.log(data);

    return data;
  }

  else {
    return null;
  }
}

export {createEduProgram, getActiveProducts, enrollUser, getProviderPrograms, getClientProducts, getEduUsers, 
  getAllEduPrograms, getEduEnrollments, blockEduUser, unblockEduUser, inactivateEduProgram, activateEduProgram, 
  deleteEduProgram, modifyEduProgram};