import React from 'react';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import swal from "sweetalert";
import { createDealsDefinitions } from "../../utilityFunctions/DealsUtil";
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";



const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const dealsTypes = [
    {
        label: "Cliente fiducia a fondo",
        type: "PRF"
    },
    {
        label: "Adquirente a fondo",
        type: "ADF"
    },
    {
        label: "Cliente VIP a fondo",
        type: "VFO"
    },
    {
        label: "Cliente interesante para fiducia",
        type: "IPR"
    },
    {
        label: "Cliente VIP a Promotor",
        type: "VPR"
    },
    {
        label: "Promotor a promotor",
        type: "PRP"
    },
    {
        label: "Cliente fondo a fondo",
        type: "FOF"
    },
];

const levels = [
    {
        level: "high",
        label: "Alto",
    },
    {
        level: "medium",
        label: "Medio",
    },
    {
        level: "low",
        label: "Bajo",
    },
];

const jobs = [
    {
        job: "directorObra",
        label: "Director obra",
    },
    {
        job: "ejecutivoConstructora",
        label: "Ejecutivo constructora",
    },
    {
        job: "cfoConstructor",
        label: "Financiero en constructora",
    },
];

const actions = [
    {
        action: "comercialEmailResponse",
        label: "Respuesta correo comercial",
    },
    {
        action: "calllResponse",
        label: "Respuesta llamada",
    },
    {
        action: "surveyResponse",
        label: "Respuesta encuesta",
    },
];

const potentialValues = [
    {
        potentialValue: "valueFraction",
        label: "Porcentaje productos"
    },
    {
        potentialValue: "byIncome",
        label: "Porcentaje ingresos"
    },
];

let dealsDefinitions = {
    name: "",
    description: "",
    type: "",
    potentialValue: "",
    level: "",
    realStateNumber: "",
    realStateValue: "",
    realStateDate: "",
    averageInvestment: "",
    daysWithdraw: "",
    job: "",
    vip: "",
    action: "",
    income: "",
};

export default function NewDealsDefinitions(props) {
    const {loginUser} = props 

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        dealsDefinitions = {
            name: "",
            description: "",
            type: "",
            potentialValue: "",
            level: "",
            realStateNumber: "",
            realStateValue: "",
            realStateDate: "",
            averageInvestment: "",
            daysWithdraw: "",
            job: "",
            vip: "",
            action: "",
            income: "",
        };
    }, [])

    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [type, setType] = React.useState("");
    const [potentialValue, setPotentialValue] = React.useState("");
    const [level, setLevel] = React.useState("");
    const [realStateNumber, setRealStateNumber] = React.useState("");
    const [realStateValue, setRealStateValue] = React.useState("");
    const [realStateDate, setRealStateDate] = React.useState("");
    const [averageInvestment, setAverageInvestment] = React.useState("");
    const [daysWithdraw, setDaysWithdraw] = React.useState("");
    const [job, setJob] = React.useState("");
    const [vip, setVip] = React.useState("");
    const [action, setAction] = React.useState("");
    const [income, setIncome] = React.useState("");

    const handleChange = (event, definition) => {
        switch(definition){
            case "name":
                dealsDefinitions.name=event.target.value;
                setName(event.target.value);
                break;
            case "description":
                dealsDefinitions.description=event.target.value;
                setDescription(event.target.value);
                break;
            case "type":
                dealsDefinitions.type=event.target.value;
                setType(event.target.value);
                break;
            case "potentialValue":
                dealsDefinitions.potentialValue=event.target.value;
                setPotentialValue(event.target.value);
                break;
            case "level":
                dealsDefinitions.level=event.target.value;
                setLevel(event.target.value);
                break;
            case "realStateNumber":
                dealsDefinitions.realStateNumber=event.floatValue; 
                setRealStateNumber(event.floatValue);
                break; 
            case "realStateValue":
                dealsDefinitions.realStateValue=event.floatValue; 
                setRealStateValue(event.floatValue);
                break; 
            case "realStateDate":
                dealsDefinitions.realStateDate=event.floatValue;
                setRealStateDate(event.floatValue);
                break;
            case "averageInvestment":
                dealsDefinitions.averageInvestment=event.floatValue;
                setAverageInvestment(event.floatValue);
                break;
            case "daysWithdraw":
                dealsDefinitions.daysWithdraw=event.floatValue;
                setDaysWithdraw(event.floatValue);
                break;
            case "job":
                dealsDefinitions.job=event.target.value;
                setJob(event.target.value);
                break;
            case "vip":
                dealsDefinitions.vip=event.target.value;
                setVip(event.target.value);
                break;
            case "action":
                dealsDefinitions.action=event.target.value;
                setAction(event.target.value);
                break;
            case "income":
                dealsDefinitions.income=event.floatValue;
                setIncome(event.floatValue);
                break;

            default:
                break;
        }
    };

    const submitModifications = async () =>{
        setLoading(true);
        const result = await createDealsDefinitions(dealsDefinitions, loginUser.jwtToken);
        if(result){

            swal({
                title: "Creación Exitosa",
                icon: "success",
                button: "Entiendo",
            }).then(() => {
                history.push({pathname: "/commercial/deals"});
            });
        }else{
            swal({
                title: "Creación No Exitosa",
                icon: "error",
                button: "Entiendo",
            });
        }
        setLoading(false);
    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >

            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="dealName"
                            name="dealName"
                            label={<Typography variant="body2">Nombre oportunidad</Typography>}
                            value={name}
                            onChange={(e)=>handleChange(e, "name")}
                            autoComplete="off"
                        />    
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            multiline
                            autoComplete="off"
                            fullWidth={true}
                            id="description"
                            name="description"
                            label={<Typography variant="body2">Descripción oportunidad</Typography>}
                            InputProps={{ style: { fontSize: 12 } }}
                            value={description}
                            onChange={(e)=>handleChange(e, "description")}
                        />    
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="type"
                            name="type"
                            select
                            label={<Typography variant="body2">Tipo oportunidad</Typography>}
                            value={type}
                            onChange={(e)=>handleChange(e, "type")}
                            autoComplete="off"
                        >
                            {dealsTypes.map((typeEl) => (
                                <MenuItem key={typeEl.type} value={typeEl.type}>
                                    {typeEl.label}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="potentialValue"
                            name="potentialValue"
                            select
                            label={<Typography variant="body2">Valor potencial</Typography>}
                            value={potentialValue}
                            onChange={(e)=>handleChange(e, "potentialValue")}
                            autoComplete="off"
                        >
                            {potentialValues.map((valueEl) => (
                                <MenuItem key={valueEl.potentialValue} value={valueEl.potentialValue}>
                                    {valueEl.label}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            autoComplete="off"
                            fullWidth={true}
                            id="level"
                            name="level"
                            select
                            label={<Typography variant="body2">Nivel oportunidad</Typography>}
                            value={level}
                            onChange={(e)=>handleChange(e, "level")}
                        >
                            {levels.map((levelEl) => (
                                <MenuItem key={levelEl.level} value={levelEl.level}>
                                    {levelEl.label}
                                </MenuItem>
                            ))}
                        </TextField>  
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "50%"}}
                            name="realStateNumber"
                            customInput={TextField}
                            // prefix={'$ '}
                            type="text"
                            label={<Typography variant="body2">Cantidad negocios inmobiliarios</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={realStateNumber}
                            onValueChange={(values) => {
                                // const {formattedValue, value, floatValue} = values;
                                handleChange(values, "realStateNumber");
                            }}
                        />
                    </Grid>  
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "50%"}}
                            name="realStateValue"
                            customInput={TextField}
                            prefix={'$ '}
                            type="text"
                            label={<Typography variant="body2">Valor negocios inmobiliarios</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={realStateValue}
                            onValueChange={(values) => {
                                // const {formattedValue, value, floatValue} = values;
                                handleChange(values, "realStateValue");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "50%"}}
                            name="realStateDate"
                            customInput={TextField}
                            // prefix={'$ '}
                            type="text"
                            label={<Typography variant="body2">Días desde último negocio</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={realStateDate}
                            onValueChange={(values) => {
                                // const {formattedValue, value, floatValue} = values;
                                handleChange(values, "realStateDate");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "50%"}}
                            name="averageInvestment"
                            customInput={TextField}
                            prefix={'$ '}
                            type="text"
                            label={<Typography variant="body2">Inversión promedio fondo</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={averageInvestment}
                            onValueChange={(values) => {
                                // const {formattedValue, value, floatValue} = values;
                                handleChange(values, "averageInvestment");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "50%"}}
                            name="daysWithdraw"
                            customInput={TextField}
                            // prefix={'$ '}
                            type="text"
                            label={<Typography variant="body2">Días desde retiro fondo</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={daysWithdraw}
                            onValueChange={(values) => {
                                // const {formattedValue, value, floatValue} = values;
                                handleChange(values, "daysWithdraw");
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            autoComplete="off"
                            fullWidth={true}
                            id="job"
                            name="job"
                            select
                            label={<Typography variant="body2">Sector económico</Typography>}
                            value={job}
                            onChange={(e)=>handleChange(e, "job")}
                        >
                            {jobs.map((jobEl) => (
                                <MenuItem key={jobEl.job} value={jobEl.job}>
                                    {jobEl.label}
                                </MenuItem>
                            ))}
                        </TextField>  
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            autoComplete="off"
                            fullWidth={true}
                            id="vip"
                            name="vip"
                            select
                            label={<Typography variant="body2">Cliente VIP</Typography>}
                            value={vip}
                            onChange={(e)=>handleChange(e, "vip")}
                        >
                            <MenuItem value={false}>No</MenuItem>
                            <MenuItem value={true}>Sí</MenuItem>
                        </TextField>  
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <TextField
                            variant="standard"
                            autoComplete="off"
                            fullWidth={true}
                            id="action"
                            name="action"
                            select
                            label={<Typography variant="body2">Acción cliente</Typography>}
                            value={action}
                            onChange={(e)=>handleChange(e, "action")}
                        >
                            {actions.map((actionEl) => (
                                <MenuItem key={actionEl.action} value={actionEl.action}>
                                    {actionEl.label}
                                </MenuItem>
                            ))}
                        </TextField>  
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <NumberFormat
                            variant="standard"
                            style={{width: "100%", height: "50%"}}
                            name="income"
                            customInput={TextField}
                            prefix={'$ '}
                            type="text"
                            label={<Typography variant="body2">Ingresos cliente</Typography>}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            autoComplete="off"
                            value={income}
                            onValueChange={(values) => {
                                // const {formattedValue, value, floatValue} = values;
                                handleChange(values, "income");
                            }}
                        />
                    </Grid>             
                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1.5rem"}}>
                        <Button onClick={submitModifications} sx={{backgroundColor: Colors.primary, color: "white", '&.MuiButton-root:hover':{bgcolor: Colors.hover}}}>
                            CREAR
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
        </Box>
    )
}
