import React from "react";
import styled from "@emotion/styled";
import Auth from "./auth/Auth";
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import "./App.css";

const ViewPortStyle = styled.div(() => ({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "100%",
  width: "100%",
  backgroundColor: "white",
  // alignItems: "center"
}));

/*Stylesheet*/
// const THEME = createTheme(adaptV4Theme({
//   typography: {
//     fontFamily: "Montserrat",
    
//   },
// }));

const THEME = createTheme({
  typography: {
    fontFamily: "Montserrat",
    
  },
});

const myTheme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    
  },
});

const theme2 = createTheme();

theme2.typography.h5 = {
  fontSize: '10rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme2.breakpoints.up('md')]: {
    fontSize: '10rem',
  },
};

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={THEME}>
        <ViewPortStyle>
          <Auth />
        </ViewPortStyle>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
