import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import Colors from "../../../components/ui/Colors";
import { createBatchInvestments } from "../../../utilityFunctions/MarketPlaceUtil";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import * as XLSX from 'xlsx';

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

let investmentsObject=[];

export default function DataMigration(props) {

    const{loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const [excelData, setExcelData] = React.useState([]);
    const [excelFile, setExcelFile] = React.useState(null);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setExcelFile(e.target.files[0]);

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      
            // Convert the array of arrays (jsonData) to an array of objects
            const headerRow = jsonData[0];
            const dataRows = jsonData.slice(1);
      
            const arrayOfObjects = dataRows
                .filter((row) => row.some((cell) => cell !== undefined && cell !== ''))
                .map((row) =>
                    Object.fromEntries(
                        row.map((cell, index) => {

                        // if (headerRow[index] === 'fecha') {
                        //     // Convert the Excel date number to a Date object
                        //     const excelDateNumber = parseFloat(cell);
                        //     const excelDate = new Date((excelDateNumber - 1) * 24 * 60 * 60 * 1000 + 1); // Adjust for Excel's base date

                        //     // Extract day, month, and year components
                        //     const day = excelDate.getUTCDate().toString().padStart(2, '0');
                        //     const month = (excelDate.getUTCMonth() + 1).toString().padStart(2, '0');
                        //     let year = excelDate.getUTCFullYear() % 100; // Extract the last two digits of the year

                        //     // Ensure year is two digits with leading zero
                        //     if (year < 10) {
                        //     year = `0${year}`;
                        //     }

                        //     // Format the Date components as 'dd/mm/yy'
                        //     const formattedDate = `${day}/${month}/${year}`;

                        //     return [headerRow[index], formattedDate];
                        // }

                        return [headerRow[index], cell];
                        })
                    )
            );

            // Set the array of objects in state
            setExcelData(arrayOfObjects);
            investmentsObject=arrayOfObjects;

            console.log(arrayOfObjects);
        };

        reader.readAsBinaryString(file);
    };

    const deleteExcelFile = () => {
        setExcelFile(null);
    }

    const uploadBatchPurchases = async () => {
        console.log("uploading...");

        setLoading(true);
        const investmentsResult = await createBatchInvestments(investmentsObject, loginUser.jwtToken);
        setLoading(false);

        if(!investmentsResult){
            swal({
                title: "Error en el proceso",
                text: "Vuelve a intentarlo",
                icon: "error",
                button: "ok",
            });
            deleteExcelFile();
            investmentsObject=[];
            return;
        }

        swal({
            title: "Proceso exitoso",
            icon: "success",
            button: "ok",
        });
        deleteExcelFile();
        investmentsObject=[];

    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                    {!excelFile &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button
                                sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                component="label"
                                >
                                Compras Clientes
                                <input
                                    type="file"
                                    hidden
                                    onChange={(event) => {
                                        console.log(event.target.files[0]);
                                        handleFileUpload(event);
                                    }}
                                />
                            </Button>
                        </Grid>
                    }
                    {excelFile &&               
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                    <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{excelFile.name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Button onClick={deleteExcelFile}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                        Borrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {excelData &&
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={uploadBatchPurchases} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Cargar
                            </Button>
                        </Grid>

                    }
                </Grid>
            }
            </Box>
        </Box>
    )
}
