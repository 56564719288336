import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Colors from "../../components/ui/Colors";
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import NumberFormat from "react-number-format";
import TextField from '@mui/material/TextField';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import { GiCow } from "react-icons/gi";
import { GiWeight } from "react-icons/gi";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PercentIcon from '@mui/icons-material/Percent';
import CowIcon from './CowIcon';

import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import { createInvestment, bankAccountExist } from "../../utilityFunctions/MarketPlaceUtil";
import swal from "sweetalert";
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";

const inlineSvg =  `<svg xmlns='http://www.w3.org/2000/svg'  width='120' height='120' viewBox='0 0 120 120'><rect fill='#ddffaa' width='120' height='120'/><polygon  fill='#AE9' fill-opacity='1' points='120 120 60 120 90 90 120 60 120 0 120 0 60 60 0 0 0 60 30 90 60 120 120 120 '/></svg>`;

const useStyles = makeStyles({
    svgBackground: {
      background: `url("data:image/svg+xml;utf8,${encodeURIComponent(inlineSvg)}") center center/cover no-repeat`,
    },
});

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const investmentObject = {
    username: "",
    initialBalance: "",
    code: "",
};

let enableChangeShare = true;
let enableChangeValue = true;

let paymentWindow="";

const purchaseMinValue = 2000000; 
// const purchaseMinValue = 0; 

const checkoutInfo = {
    currency: 'COP',
    amountInCents: null,
    reference: "",
    publicKey: "",
    // signature: "",
};

export default function ProductDetail(props) {

  const {loginUser} = props;

  let history = useHistory();

  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);

  const wompiCheckout = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://checkout.wompi.co/widget.js';
        script.async = true;
        script.onload = () => {
            // The script has been loaded and is ready to use
            // You can access elements or functionality from the script here
            // For example, you might initialize a widget or perform other actions
            
            try{
                console.log(checkoutInfo);

                var  checkout = new window.WidgetCheckout({
                    currency: 'COP',
                    amountInCents: checkoutInfo.amountInCents,
                    reference: checkoutInfo.reference.toString(),
                    publicKey: checkoutInfo.publicKey,
                    // signature: "",
                });

                console.log(checkout)

                checkout.open(( result ) => {
                    var transaction = result.transaction
                    console.log('Transaction ID: ', transaction.id)
                    console.log('Transaction object: ', transaction)
                });

                resolve(checkout);
            }catch(error){
                console.log(error);
                setLoading(false);
            }

        };
        document.head.appendChild(script);
    });
    
  };

  const [investment, setInvestment] = React.useState("");
  const [share, setShare] = React.useState("");


  const handleBuy = async (code) => {

    setLoading(true);

    console.log("buy click");

    if(investmentObject.initialBalance < purchaseMinValue){
        swal({
            title: "Monto inferior al permitido",
            text: `El valor mínimo de compra es de 
            $ ${new Intl.NumberFormat('es-CO').format(purchaseMinValue)}`,
            icon: "info",
            button: "Entiendo",
        });
        setLoading(false);
        return;
    }

    if(investmentObject.initialBalance + props.location.state.product.valueCollected > props.location.state.product.totalValue){
        swal({
            title: "Monto superior al permitido",
            text: `En este lote solo está pendiente una participación que corresponde a $ ${props.location.state.product.totalValue - props.location.state.product.valueCollected}`,
            icon: "info",
            button: "Entiendo",
        });
        setLoading(false);
        return;
    }
    

    const checkAccount = await isBankAccount();
    console.log(checkAccount);

    if(checkAccount === "pending"){
        swal({
            title: "Pendiente Aprobación",
            text: "Tu cuenta todavía no está activa. Estamos revisando la documentación para activarla",
            icon: "info",
            button: "Entiendo",
        });
        setLoading(false);
        return;
    }

    if(!checkAccount){
        swal({
            title: "Pendiente cuenta bancaria",
            text: `Para poder comprar una participación, primero debes crear una cuenta bancaria con la documentación solicitada.
            Oprime el botón Crear para crear tu cuenta bancaria.`,
            icon: "info",
            button: "Entiendo",
        }).then(()=>{
            history.push({pathname: "/info/bank-account"});
            setLoading(false);
        });

        return;
    }

    investmentObject.username = loginUser.accountId;
    investmentObject.code = code;

    openPaymenLoading(); // Open payment window in a new tab

    const createdResponse = await createInvestment(investmentObject, loginUser.jwtToken);

    if(!createdResponse){
        return;
    }

    console.log(createdResponse);


    checkoutInfo.amountInCents = createdResponse.wompiInvoice.value * 100;
    checkoutInfo.publicKey = createdResponse.publicKey;
    checkoutInfo.reference = createdResponse.wompiInvoice.id;

    // var checkoutResult = await wompiCheckout(checkoutInfo); // Open payment window in the same tab

    openPaymentLink(createdResponse); // Open payment window in a new tab

    swal({
        title: "Compra en proceso",
        text: "Estamos procesando tu operación, cuando sea confirmada podrás ver reflejada tu compra",
        icon: "info",
        button: "Entiendo",
    }).then(()=> {
        history.push("/selling/marketplace");
    });


    setLoading(false);
  };

  const openPaymenLoading = () => {
    //  paymentWindow = window.open('https://wupotest.ngrok.io/withdrawmethod');
    // paymentWindow = window.open('https://vanking.d1vz57fbwgmh8h.amplifyapp.com/withdrawmethod');
    paymentWindow = window.open('https://webapp.vankingapp.com//withdrawmethod');
  };

  const openPaymentLink = (investmentResponse) => {
    if(!investmentResponse){
        paymentWindow.close();
        return;
    }

    try{
        const formData  = new FormData();
        formData.append("public-key", checkoutInfo.publicKey);
        formData.append("currency", "COP");
        formData.append("amount-in-cents",checkoutInfo.amountInCents);
        formData.append("reference", checkoutInfo.reference.toString());

        // Create the URL with the form data
        const url = [];
        for (const [key, value] of formData.entries()) {
            url.push(`${key}=${value}`);
        }

        // Set the URL of the new window
        paymentWindow.document.location.href = `https://checkout.wompi.co/p/?${url.join('&')}`;
        
        // paymentWindow.document.location.href = "https://checkout.wompi.co/p/";
    
    }catch(error){
        console.log(error);
        // paymentWindow.close();
    }


  };

  const isBankAccount = async () => {

    const checkAccount = await bankAccountExist(loginUser.accountId, loginUser.jwtToken);

    return checkAccount;

  };

  const valueChange = async (e) => {
    console.log(e.floatValue);
    await asyncValueChange(e);

    enableChangeShare = true;

  };

  const asyncValueChange = async (e) => {
    console.log(enableChangeValue);

    if(enableChangeValue === true){
        enableChangeShare = false;
        const tempValue = e.floatValue;
        setInvestment(tempValue);
        investmentObject.initialBalance = tempValue;
        
        console.log(tempValue / props.location.state.product.totalValue);
        console.log(tempValue);
        
        setShare((tempValue / props.location.state.product.totalValue)*100);
    }

    return true;
  };

  const shareChange = async (e) => {
    
    await asyncShareChange(e);

    enableChangeValue = true;

  };

  const asyncShareChange = async (e) => {
    enableChangeValue = false;
    if(enableChangeShare === true){
        enableChangeValue = false;
        console.log(e.floatValue)
        const shareValue = e.floatValue / 100;
        setShare(e.floatValue);

        const investmentCalculation = props.location.state.product.totalValue * (shareValue);
        console.log(investmentCalculation);
        setInvestment(investmentCalculation);
        investmentObject.initialBalance = investmentCalculation;
    }
    return true;
  };

  return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%", backgroundColor: "#f6f6f6",}}>
        <Box
            sx={{
                display: "flex",
                flexGrow: "1",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
            }}
        >

        {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
        
        <Grid container spacing={3} sx={{width:{xs: "100%", sm: "90%", md: "50%"}, alignItems: "center", marginTop:{xs: "0.5rem", sm: "0.5rem", md: 0}, marginBottom: "3rem", maxWidth: "680px"}}>
        <>
                <Grid  item xs={12} sm={12} md={12} lg={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Card sx={{width: {xs: "95%", sm: "95%", md: "76%"}, borderRadius: "15px"}}>
                        <Grid container direction = "column">
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <Box 
                                    component="img"
                                    sx={{
                                    height: "90%",
                                    width: "100%",
                                    justifyContent: "center",
                                    maxHeight: "300px",
                                    }}
                                    alt="Foto"
                                    src={URL.createObjectURL(props.location.state.product.cattleLotPic.cattlePic)}

                                >
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "1rem"}}>
                                <Grid container sx={{width: "90%"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop:"0.5rem", marginBottom: "0.2rem"}}>
                                        <Typography variant="h6" sx={{fontWeight: "bold", width: "100%", color: "#25d366"}}>{props.location.state.product.code}</Typography>
                                    </Grid>

                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <TrendingUpOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+1} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Rentabilidad Esperada*:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography variant="body2" sx={{ width: "100%"}}>{`${props.location.state.product.expectedRevenue*100}% E.A.`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <AttachMoneyOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+11} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Valor total:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+12} variant="body2" sx={{ width: "100%", fontSize: "0.79rem"}}>{`$ ${new Intl.NumberFormat('es-CO').format(props.location.state.product.totalValue)}`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <AttachMoneyOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+11} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Valor recaudado:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+12} variant="body2" sx={{ width: "100%", fontSize: "0.79rem"}}>{`$ ${new Intl.NumberFormat('es-CO').format(props.location.state.product.valueCollected)}`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <PercentIcon fontSize="small" sx={{color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+11} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Porcentaje vendido:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography variant="body2" sx={{ width: "100%"}}>{`${(new Intl.NumberFormat('es-CO').format((props.location.state.product.valueCollected/props.location.state.product.totalValue)*100))}%`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <LocalOfferOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+11} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Valor por Kilo:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+12} variant="body2" sx={{ width: "100%"}}>{`$ ${new Intl.NumberFormat('es-CO').format(Math.ceil(props.location.state.product.valuePerWeight))}`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <CalendarMonthIcon fontSize="small" sx={{color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+3} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Duración:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+4} variant="body2" sx={{ width: "100%"}}>{`${props.location.state.product.duration} meses`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <CowIcon style={{fontSize: "18px"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+7} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Cabezas de Ganado:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+8} variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(props.location.state.product.animalsAmount)}`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <GiWeight style={{fontSize: "18px", color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+9} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Peso Total:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+10} variant="body2" sx={{ width: "100%"}}>{`${new Intl.NumberFormat('es-CO').format(props.location.state.product.totalWeight)} Kg`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <CowIcon style={{fontSize: "18px"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+5} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Raza:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+6} variant="body2" sx={{ width: "100%"}}>{`${props.location.state.product.breed}`}</Typography>
                                    </Grid>
                                    <Grid item xs={1} sm={1} md={1} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <LocationOnOutlinedIcon fontSize="small" sx={{color: "#25d366"}}/>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+11} variant="body2" sx={{ width: "100%", paddingLeft: {xs: "0.7rem"}}}>Ubicación:</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4} md={4} sx={{display: "flex", justifyContent: "start", alignItems: "center", textAlign: "start", marginTop:"0.5rem"}}>
                                        <Typography key={props.location.state.product.id+12} variant="body2" sx={{ width: "100%"}}>{`${props.location.state.product.location}`}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.05rem", marginBottom: "0.2rem"}}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "90%", height: "90%"}}
                                    name="investment"
                                    customInput={TextField}
                                    prefix={'$ '}
                                    // suffix={' meses'}
                                    type="investment"
                                    label={<Typography variant="body2">Valor Compra</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={investment}
                                    onValueChange={(values) => {
                                        // const {formattedValue, value, floatValue} = values;
                                        valueChange(values);
                                    }}
                                    // onValueChange={(values) => {
                                    //     // const {formattedValue, value, floatValue} = values;
                                    //     valueChange(values);
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.3rem", marginBottom: "0.5rem"}}>
                                <NumberFormat
                                    variant="standard"
                                    style={{width: "90%", height: "90%"}}
                                    name="share"
                                    customInput={TextField}
                                    prefix={'$ '}
                                    suffix={' %'}
                                    // type="share"
                                    label={<Typography variant="body2">Participación lote</Typography>}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    autoComplete="off"
                                    value={share}
                                    onValueChange={(values) => {
                                        shareChange(values);
                                    }}
                                    // onValueChange={(values) => {
                                    //     // const {formattedValue, value, floatValue} = values;
                                    //     shareChange(values);
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Button onClick={() => handleBuy(props.location.state.product.code)} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "0.8rem", marginBottom: "0.8rem"}}>
                                    Comprar
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>   
                </Grid>
                </>   
        </Grid>
        }
        </Box>
    </Box>
  )
}
