import React from "react";

const AdminItem = ({ id, btnColor, title, btnName, handleClick }) => {
  return (
    <div>
      <p
        style={{
          alignSelf: "end",
          margin: "5px",
          fontSize: "12px",
        }}
      >
        {title}
      </p>
      <button
        style={{
          width: "90px",
          height: "50px",
          backgroundColor: btnColor,
          boxShadow:
            "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
          textAlign: "center",
          cursor: "pointer",
          color: "#293250",
          transitionDuration: "0.4s",
          textDecoration: "none",
          overflow: "hidden",
          border: "none",
          outline: "none",
          borderRadius: "8px",
        }}
        onClick={() => handleClick(id)}
        id={id}
      >
        {btnName}
      </button>
    </div>
  );
};

export default AdminItem;
