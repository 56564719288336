import React from 'react';
import { persistBankAccount, persistCompanyBankAccount, getClientPii } from '../../utilityFunctions/MarketPlaceUtil';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button, LinearProgress} from '@mui/material';
import TextField from '@mui/material/TextField';
import Colors from "../../components/ui/Colors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import {bankCodes} from "../../Api";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;


const bankAcountObject = {
    username: "",
    bankAccount: "",
    bankCode: "",
    bankAccountTypeString: "",
    bankAccountType: 1,
};

const acounTypesArray = ["Ahorros", "Corriente"];

let clientPii;

const maxSize = 1024 * 1024; // 1 MB

export default function CreateBankAccount(props) {

    const{loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    let history = useHistory();

    React.useEffect(() => {
        getPii();
        bankArray();
    }, []);

    const getPii = async () => {
        setLoading(true);
        clientPii = await getClientPii(loginUser.accountId, loginUser.jwtToken);
        setLoading(false);
    };

    const [bankAccount, setBankAccount] = React.useState("");
    const [bankCode, setBankCode] = React.useState("");
    const [bankAccountType, setBankAccountType]= React.useState("");
    const [accountCertification, setAccountCertification] = React.useState("");
    const [idCopy, setIdCopy] = React.useState("");

    //Docs for companies
    const[companyCert, setCompanyCert] = React.useState("");
    const[taxesCert, setTaxesCert] = React.useState("");

    //Rendering states
    const [progress, setProgress] = React.useState(0);
    const [fileLoading, setFileLoading] = React.useState(false);

    const [bank, setBank] = React.useState();

    const bankArray = () => {
        setBank(
            {
                options: bankCodes,
                getOptionLabel: (option) => option.bank,
            }
        );
    };
    
    const bankAccountChange = (e) => {
        setBankAccount(e.target.value);
        bankAcountObject.bankAccount = e.target.value;
    };

    const bankCodeChange = (e) => {
        setBankCode(e.target.value);
        bankAcountObject.bankCode = e.target.value;
    };

    const bankAccountTypeChange = (e) => {
        setBankAccountType(e.target.value);
        bankAcountObject.bankAccountTypeString = e.target.value;
    };

    const accountCertificationChange = (e) => {

        if(e.target.files[0].size > maxSize){
            swal({
                title: "Tamaño excedido",
                text: "El documento cargado debe ser de máximo 1 Megabyte.",
                icon: "error",
                button: "Ok",
            });
            return;
        }
        
        setAccountCertification(e.target.files[0]);
        setCertificationBox(true);
    }

    const deleteAccountCertification = () => {
        setAccountCertification(null);
    }

    const idCopyChange = (e) => {

        if(e.target.files[0].size > maxSize){
            swal({
                title: "Tamaño excedido",
                text: "El documento cargado debe ser de máximo 1 Megabyte.",
                icon: "error",
                button: "Ok",
            });
            return;
        }

        setIdCopy(e.target.files[0]);
        setIdBox(true);
    }

    const deleteIdCopy = () => {
        setIdCopy(null);
    }

    const companyCertChange = (e) => {

        if(e.target.files[0].size > maxSize){
            swal({
                title: "Tamaño excedido",
                text: "El documento cargado debe ser de máximo 1 Megabyte.",
                icon: "error",
                button: "Ok",
            });
            return;
        }

        setCompanyCert(e.target.files[0]);
        
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
              if (prevProgress < 100) {
                return prevProgress + 10;
              } else {
                clearInterval(interval);
                setFileLoading(false);
                return 0;
              }
            });
        }, 100);
    };

    const deleteCompanyCert = () => {
        setCompanyCert(null);
    };

    const taxesCertChange = (e) => {

        if(e.target.files[0].size > maxSize){
            swal({
                title: "Tamaño excedido",
                text: "El documento cargado debe ser de máximo 1 Megabyte.",
                icon: "error",
                button: "Ok",
            });
            return;
        }

        setTaxesCert(e.target.files[0]);

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
              if (prevProgress < 100) {
                return prevProgress + 10;
              } else {
                clearInterval(interval);
                setFileLoading(false);
                return 0;
              }
            });
        }, 100);
        
    };

    const deleteTaxesCert = () => {
        setTaxesCert(null);
    };

    const onChangeAutocomplete = (event, value) => {
        console.log(value);
        bankAcountObject.bankCode = value.bank

    };

    const determineClientType = () => {
        setLoading(true);

        if(clientPii.clientType && clientPii.clientType==="company"){
            newCompanyBankaccount();
            return;
        }

        newBankAccount();
    };
    
    const newBankAccount = async () => {

        if(!accountCertification || !idCopy || !bankAcountObject.bankAccount || !bankAcountObject.bankAccountTypeString || !bankAcountObject.bankCode){
            
            swal({
                title: "Datos Incompletos",
                text: "Debes cargar todos los datos, incuyendo los documentos",
                icon: "info",
                button: "Entiendo",
            });
            setLoading(false);
            return; 
        }

        bankAcountObject.username = loginUser.accountId;

        console.log(bankAcountObject)

        const formData  = new FormData();

        const json = JSON.stringify(bankAcountObject);

        const accountObject = new Blob([json], {
            type: 'application/json'
            // type: 'application/json'
        });

        formData.append("accountObject", accountObject);

        formData.append("accountCertification", accountCertification);
        formData.append("idCopy", idCopy);

        const bankAccountResult = await persistBankAccount(formData, loginUser.jwtToken);
        setLoading(false);

        if(!bankAccountResult){
            swal({
                title: "No Creada",
                text: "No se creó la cuenta bancaria, vuelve a intentarlo",
                icon: "error",
                button: "Entiendo",
            });
            return; 
        }

        swal({
            title: "Cuenta Creada",
            text: "Cuenta creada. Vamos a revisar los documentos que cargaste para aprobarla",
            icon: "success",
            button: "Súper",
        }).then(()=> {
            history.push("/selling/marketplace");
        });

    };

    const newCompanyBankaccount = async () => {
        if(!accountCertification || !idCopy || !companyCert || !taxesCert
            || !bankAcountObject.bankAccount || !bankAcountObject.bankAccountTypeString || !bankAcountObject.bankCode){
            
            swal({
                title: "Datos Incompletos",
                text: "Debes cargar todos los datos, incuyendo los documentos",
                icon: "info",
                button: "Entiendo",
            });
            setLoading(false);
            return; 
        }

        bankAcountObject.username = loginUser.accountId;

        console.log(bankAcountObject)

        const formData  = new FormData();

        const json = JSON.stringify(bankAcountObject);

        const accountObject = new Blob([json], {
            type: 'application/json'
            // type: 'application/json'
        });

        formData.append("accountObject", accountObject);

        formData.append("accountCertification", accountCertification);
        formData.append("idCopy", idCopy);
        formData.append("companyCert", companyCert);
        formData.append("taxesCert", taxesCert);

        const bankAccountResult = await persistCompanyBankAccount(formData, loginUser.jwtToken);
        setLoading(false);

        if(!bankAccountResult){
            swal({
                title: "No Creada",
                text: "No se creó la cuenta bancaria, vuelve a intentarlo",
                icon: "error",
                button: "Entiendo",
            });
            return; 
        }

        swal({
            title: "Cuenta Creada",
            text: "Cuenta creada. Vamos a revisar los documentos que cargaste para aprobarla",
            icon: "success",
            button: "Súper",
        });
    };

    const [certificationBox, setCertificationBox] = React.useState(false);
    const [cidBox, setIdBox] = React.useState(false);


    const closeCertBox = () => {
        setCertificationBox(false);
    };

    const closeIdBox = () => {
        setIdBox(false);
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    // flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "100%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Autocomplete
                            sx={{width: "100%"}}
                            {...bank}
                            fullWidth={true}
                            id="bank"
                            clearOnEscape
                            autoComplete = {true}
                            noOptionsText = {"Banco no existe"}
                            // sx={{ width: 600 }}
                            onChange={onChangeAutocomplete}
                            // onInputChange={onChange}
                            renderInput={(params) => (
                                <TextField {...params} label={<Typography variant="body2">Banco</Typography>} variant="standard" />
                            )}
                        /> 
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            variant="standard"
                            fullWidth={true}
                            id="bankAccount"
                            name="bankAccount"
                            label={<Typography variant="body2">Número de cuenta</Typography>}
                            value={bankAccount}
                            onChange={(e)=> bankAccountChange(e)}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextField
                            sx={{textAlign: "start"}}
                            variant="standard"
                            fullWidth={true}
                            id="bankAccountType"
                            name="bankAccountType"
                            select
                            label={<Typography variant="body2">Tipo de cuenta</Typography>}
                            value={bankAccountType}
                            onChange={(e)=>bankAccountTypeChange(e)}
                            autoComplete="off"
                        >
                            {acounTypesArray.map((typeEl) => (
                                <MenuItem key={typeEl} value={typeEl}>
                                    {typeEl}
                                </MenuItem>
                            ))}
                        </TextField> 
                    </Grid>
                    {clientPii && clientPii.clientType && clientPii.clientType==="person" &&
                        <>
                        {!accountCertification &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Certificación
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            accountCertificationChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {fileLoading &&
                            <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                            <LinearProgress variant="determinate" value={progress} sx={{width: "70%"}}/>
                            </Grid>
                        }
                        {accountCertification && fileLoading === false &&
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{accountCertification.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteAccountCertification}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                        }
                        {!idCopy &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Identificación
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            idCopyChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {fileLoading &&
                            <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                            <LinearProgress variant="determinate" value={progress} sx={{width: "70%"}}/>
                            </Grid>
                        }
                        {idCopy && fileLoading === false &&
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{idCopy.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteIdCopy}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                        }
                        </> 
                    }
                    {clientPii && clientPii.clientType && clientPii.clientType==="company" &&
                        <>
                        {!accountCertification &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Certificación Bancaria
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            accountCertificationChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {fileLoading &&
                            <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                            <LinearProgress variant="determinate" value={progress} sx={{width: "70%"}}/>
                            </Grid>
                        }
                        {accountCertification && fileLoading === false &&
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{accountCertification.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteAccountCertification}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                        }
                        {!companyCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Cámara de Comercio
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            companyCertChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {fileLoading &&
                            <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                            <LinearProgress variant="determinate" value={progress} sx={{width: "70%"}}/>
                            </Grid>
                        }
                        {companyCert && fileLoading === false &&
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{companyCert.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteCompanyCert}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                        }
                        {!taxesCert &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Registro tributario (RUT)
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            taxesCertChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {fileLoading &&
                            <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                            <LinearProgress variant="determinate" value={progress} sx={{width: "70%"}}/>
                            </Grid>
                        }
                        {taxesCert && fileLoading === false &&
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{taxesCert.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteTaxesCert}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                        }
                        {!idCopy &&
                            <Grid item xs={12} sm={6} md={6}>
                                <Button
                                    sx={{marginTop: "2rem", backgroundColor: "none", color: "gray", fontSize: "0.88rem", fontWeight: "bold"}}
                                    // variant="contained"
                                    component="label"
                                    >
                                    Identificación Representante
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(event) => {
                                            console.log(event.target.files[0]);
                                            idCopyChange(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        }
                        {fileLoading &&
                            <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                            <LinearProgress variant="determinate" value={progress} sx={{width: "70%"}}/>
                            </Grid>
                        }
                        {idCopy && fileLoading === false &&
                            <>
                            <Grid item xs={12} sm={6} md={6}>
                                <Grid container spacing={0} sx={{justifyContent: "center"}}>
                                    <Grid item xs={12} sm={12} md={12} sx={{textAlign: "center"}}>
                                        <Typography variant="body2" sx={{color: "black", fontWeight: "bold"}}>{idCopy.name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Button onClick={deleteIdCopy}  component="label" sx={{marginTop: "0rem", backgroundColor: "none", color: "gray", fontSize: "0.8rem"}}>
                                            Borrar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                        }
                        </> 
                    }          
                    <Grid item xs={12} sm={12} md={12}>
                        <Button onClick={determineClientType} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                            Crear
                        </Button>
                    </Grid>
                </Grid>
            }
            </Box>
            <Dialog open={certificationBox} onClose={closeCertBox}>
                <DialogContent>
                {accountCertification ? 
                <Box 
                    component="img"
                    sx={{
                    height: "90%",
                    width: "100%",
                    justifyContent: "center",
                    }}
                    alt="Foto"
                    src={URL.createObjectURL(accountCertification)}
                >
                </Box>
                :
                <></>
                }
                </DialogContent>
                <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={closeCertBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={cidBox} onClose={closeIdBox}>
                <DialogContent>
                {idCopy ? 
                <Box 
                    component="img"
                    sx={{
                    height: "90%",
                    width: "100%",
                    justifyContent: "center",
                    }}
                    alt="Foto"
                    src={URL.createObjectURL(idCopy)}
                >
                </Box>
                :
                <></>
                }
                </DialogContent>
                <DialogActions sx={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={closeIdBox} variant="contained" sx={{backgroundColor: Colors.primary}}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
