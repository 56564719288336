import React from 'react'
import Colors from './Colors'

const StepProgress = (props) => {
  const { stepNumber, steps } = props
  return (
    <div style={{ display: 'flex', width: '80%', justifyContent: 'center' }}>
      {
        steps.map((step, index) => {
          return (
            <div
              key={index}
              style={{
                width: `${100/steps.length}%`,
                height: '5px',
                padding: '0 0.2rem'
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '8px',
                  backgroundColor: index > stepNumber-1 ? '#F3F3F3' : Colors.primary,
                }}
              />
            </div>
          )
        })
      }
    </div>
  )
}

export default StepProgress