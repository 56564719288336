
const disbursedText = `Es el monto  que desembolsaremos en tu cuenta o que reclamarás en efectivo en alguno de los puntos autorizados.`;

const rateText = `Son los intereses que te cobraremos sobre  el capital adeudado, que es más bajo que  el establecido por la Superintendencia Financiera.`;

const warrantyText = `Para tener acceso a tu préstamo, necesitas un fiador que nosotros te brindamos a través del  Fondo de Garantías CONFÉ, quien sirve como garantía y respaldo de tu préstamo. Este servicio es obligatorio.`;

const platformText = `Es el costo que pagas por el servicio de nuestra Plataforma PlaticApp  que  incluye la evaluación de tu crédito , servicio de firma electrónica y el procesamiento y desembolso del mismo. Este es obligatorio para acceder a tu préstamo. `;

const platformDiscountText = `Es un descuento que te brindamos para promover el uso de nuestra plataforma PlaticApp, el cual varía dependiendo del plazo.`;

const vatText = `Aliquam eget finibus ante, non facilisis lectus. Sed vitae dignissim est, vel aliquam tellus.
Praesent non nunc mollis, fermentum neque at, semper arcu.
Nullam eget est sed sem iaculis gravida eget vitae justo.`;

const totalPaymentText = `Es el monto total que deberás pagar en el momento del vencimiento de tu préstamo.`;

export {disbursedText, rateText, warrantyText, platformText, platformDiscountText, vatText, totalPaymentText}