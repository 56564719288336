import React from 'react';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import {Typography, Button} from '@mui/material';
import TextField from '@mui/material/TextField';
import Colors from "../../../components/ui/Colors";
import MenuItem from '@mui/material/MenuItem';
import { qryAllPurchases, qryAllSettlements, qryAllCattles, getCodes, 
    qryPurchasesByCattle, qrySettlementsByCattle} from "../../../utilityFunctions/MarketPlaceUtil";
import swal from "sweetalert";
import Autocomplete from '@mui/material/Autocomplete';
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const RequestObject = {
    qryType: "",
};

const scopeTypes = ["Total", "Por Lote"]
const requestTypes = ["Lotes", "Compras", "Liquidaciones"];
const byCattleRequestTypes = ["Compras", "Liquidaciones"];

const byCattleRequest = {
    code: "",
};

export default function CSVQuerys(props) {

    const {loginUser} = props;

    const [loading, setLoading] = React.useState(false);

    const [codes, setCodes] = React.useState("");

    const getProductsCodes = async () => {
        setLoading(true);
        const codes = await getCodes(loginUser.jwtToken);

        codes.sort((a, b) => {

            const codeA = a.code.toUpperCase();
            const codeB = b.code.toUpperCase();

            if (codeA < codeB) {
                return -1;
            } else if (codeA > codeB) {
                return 1;
            } else {
                return 0;
            }
        });

        setLoading(false);
        setCodes(
         {
             options: codes,
             getOptionLabel: (option) => option.code,
         }
        );
    };

    const onChangeAutocomplete = (event, value) => {
        if(!value){
            return;
        }
        byCattleRequest.code = value.code;
    };

    const[qryType, setQryType] = React.useState("");
    const[scopeQry, setScopeQry] = React.useState("");

    const scopeSelection = (e) =>{

        RequestObject.qryType=null;
        setQryType("");

        setScopeQry(e.target.value);

        if(e.target.value === "Por Lote"){
            getProductsCodes();
        }
    };

    const typeSelection = (e) => {
        setQryType(e.target.value);
        RequestObject.qryType=e.target.value;
    };

    const handleRequest = () => {
        setLoading(true);
        if(RequestObject.qryType === "Lotes"){
            cattleRequest();
            return;
        }
        if(RequestObject.qryType === "Compras"){
            portfolioRequest();
            return;
        }
        if(RequestObject.qryType === "Liquidaciones"){
            settlementsRequest();
            return;
        }
    };

    const handleByCattleRequest = () => {
        setLoading(true);
        if(RequestObject.qryType === "Compras"){
            portfolioByCattleRequest();
            return;
        }
        if(RequestObject.qryType === "Liquidaciones"){
            settlementByCattleRequest();
            return;
        }
    };
    

    const cattleRequest = async () => {
        const cattles = await qryAllCattles(loginUser.jwtToken);
        setLoading(false);

        if(!cattles){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
        
    };

    const portfolioRequest = async () => {
        const portfolio = await qryAllPurchases(loginUser.jwtToken);
        setLoading(false);

        if(!portfolio){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
    };

    const settlementsRequest = async () => {
        const settlements = await qryAllSettlements(loginUser.jwtToken);
        setLoading(false);

        if(!settlements){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
    };

    const settlementByCattleRequest = async () => {
        const portfolio = await qrySettlementsByCattle(byCattleRequest, loginUser.jwtToken);
        setLoading(false);

        if(!portfolio){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
    };

    const portfolioByCattleRequest = async () => {
        const portfolio = await qryPurchasesByCattle(byCattleRequest, loginUser.jwtToken);
        setLoading(false);

        if(!portfolio){
            swal({
                title: "Error",
                text: "Error de descarga. Vuelve a intentarlo",
                icon: "info",
                button: "ok",
            });
            return; 
        }

        swal({
            title: "Descarga Exitosa",
            icon: "success",
            button: "ok",
        });
        return; 
    };

    return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "95%",
                height: "100%",
                textAlign: "center",
                marginTop: {xs: "1.3rem", sm: 0},
            }}
        >
            {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> :
                <Grid container direction="row" spacing={2} sx={{width: { xs: "95%", sm: "80%", md: "40%"}}}>
                    <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                sx={{textAlign: "start"}}
                                variant="standard"
                                fullWidth={true}
                                id="scopeQry"
                                name="scopeQry"
                                select
                                label={<Typography variant="body2">Consulta</Typography>}
                                value={scopeQry}
                                onChange={(e)=>scopeSelection(e)}
                                autoComplete="off"
                            >
                                {scopeTypes.map((typeEl) => (
                                    <MenuItem key={typeEl} value={typeEl}>
                                        {typeEl}
                                    </MenuItem>
                                ))}
                            </TextField> 
                        </Grid>
                    {scopeQry && scopeQry==="Total" &&
                        <>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                sx={{textAlign: "start"}}
                                variant="standard"
                                fullWidth={true}
                                id="bankAccountType"
                                name="bankAccountType"
                                select
                                label={<Typography variant="body2">Información</Typography>}
                                value={qryType}
                                onChange={(e)=>typeSelection(e)}
                                autoComplete="off"
                            >
                                {requestTypes.map((typeEl) => (
                                    <MenuItem key={typeEl} value={typeEl}>
                                        {typeEl}
                                    </MenuItem>
                                ))}
                            </TextField> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={handleRequest} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Descargar
                            </Button>
                        </Grid>
                        </>
                    }
                    {scopeQry && scopeQry==="Por Lote" &&
                        <>
                        <Grid item xs={12} sm={12} md={12}>
                            <Autocomplete
                                {...codes}
                                id="codes"
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"Lote no existe"}
                                // sx={{ width: 600 }}
                                onChange={onChangeAutocomplete}
                                // onInputChange={onChange}
                                renderInput={(params) => (
                                    <TextField {...params} label={<Typography variant="body2">Código del lote</Typography>} variant="standard" />
                                )}
                            /> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                sx={{textAlign: "start"}}
                                variant="standard"
                                fullWidth={true}
                                id="bankAccountType"
                                name="bankAccountType"
                                select
                                label={<Typography variant="body2">Información</Typography>}
                                value={qryType}
                                onChange={(e)=>typeSelection(e)}
                                autoComplete="off"
                            >
                                {byCattleRequestTypes.map((typeEl) => (
                                    <MenuItem key={typeEl} value={typeEl}>
                                        {typeEl}
                                    </MenuItem>
                                ))}
                            </TextField> 
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={handleByCattleRequest} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Descargar
                            </Button>
                        </Grid>
                        </>
                    }
                    
                </Grid>
            }
        </Box>
    </Box>
    )
}
