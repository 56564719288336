const AuthSecondFactorText = 
`
En WUPO tenemos varios controles de verificación, para que solo tú puedas acceder a tu cuenta.

Sin embargo, si quieres mayor seguridad, puedes habilitar la opción de clave dinámica que cambia cada minuto y brinda una capa adicional de seguridad. Es opcional.

Para habilitarlo necesitas descargar el Autenticador de Google y usarlo cada vez que entras a WUPO. 
`

export {AuthSecondFactorText}

export default AuthSecondFactorText;