import React from "react"
import { useHistory } from "react-router-dom"
import contractImg from "../../img/contract.svg"
import Content from "../../layout/Content"
import PageLabel from "../../components/ui/PageLabel";
import DefaultSection from "../../components/ui/DefaultSection";
import AppButton from "../../components/ui/AppButton";

export default function ChooseEscrowAction(props) {
  const history = useHistory()
  const assetFromHandler = props.match?.params?.asset;

  return (
    <Content>
      <PageLabel>ESCOGE</PageLabel>

      <DefaultSection backImgage={contractImg} bgColor={"transparent"} />
      <Content>
        <AppButton
          primary
          style={{ marginBottom: "1rem" }}
          onClick={() =>
            history.push(`/account/handle_account/escrow/handle/${assetFromHandler}`)
          }
        >
          Créalo
        </AppButton>
        <AppButton
          primary
          onClick={() =>
            history.push(`/account/handle_account/escrow/excution/${assetFromHandler}`)
          }
        >
          Liquídalo
        </AppButton>
      </Content>
    </Content>
  )
}
