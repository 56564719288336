import React, {useEffect} from 'react'
import { useHistory } from "react-router-dom"
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Activity from './Activity';
import ContactLocation from './ContactLocation';
import IDInformation from './IDInformation';
import swal from "sweetalert";
import { Box, useMediaQuery } from "@mui/material";
import { phoneValidation, phoneCode } from "../../../src/utilityFunctions/FetchUtil";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import { xrpPayment, createTrustline, createXrplAccount, persistAccount } from "../../utilityFunctions/Paymentutil";
import { loadXrpAddressCreds } from "../../utilityFunctions/FetchUtil";
import { trustlineLimitValue, creditAsset, xrplEnabled, isUsernamePhone, b2bClient, isPhoneVerifyEnabled} from "../../Api";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'PlaticApp Powered by '}
      <Link color="inherit">
        WUPO
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

  const onboardingInformation={
    name:"",
    lastName:"",
    idType:"",
    idNumber:"",
    nationality:"",
    birthCountry:"",
    homeCountry:"",
    address:"",
    municipality:"",
    phone:"",
    email:"",
    occupation:"",
    income:"",
    taxes:"",
    amlRisk:"low",
    privacyConsent:"",
    date:"",
    accountId:"",
    clientType: "",
    representativeName: null,
    representativeLastName: null,
    representativeIdType: null,
    representativeIdNumber: null,
  }

  const verificationObject = {
    code: "",
    phone: "",
    account: "",
    idType:"",
    idNumber:"",
  }

  // const steps = ['Identificación', 'Contacto', 'Finanzas'];
  const steps = ['Identification & Contact'];


  const setMunicipality = (value) => {
    console.log(value)
    onboardingInformation.municipality=value.municipality;
      
  }



const accountObject = {
  accountId: "",
  xrplAddress: "",
  accountIdToken: "",
  xrplAddressSecret: "",
  account: "",
  asset: creditAsset,
  stockAsset: creditAsset,
  retailCashBackCode: ""
};

const openCard=false;

export default function AMLForm(props) {
  const{ loginUser, wupoServerUri, enableAccountAsset, enablePaymentValue, 
    updateNavBarEnabled, loadProductsAndSetting } = props
  const history = useHistory()
  
  const [activeStep, setActiveStep] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const isSmallStepper = useMediaQuery(theme => theme.breakpoints.down('lg'));
  
  const stepperProps = {
    alternativeLabel: isSmallStepper ? true : false,
  }

  useEffect(()=>{    

  },[]);

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <IDInformation 
          dataForParent={setDataFromChilds}
          setMunicipality={setMunicipality}
          setGeoLocation={setGeoLocation}
        />;
      case 1:
        return <ContactLocation 
          dataForParent={setDataFromChilds}
        />;
      case 2:
        return <Activity 
          dataForParent={setDataFromChilds}
        />; 
      default:
        throw new Error('Unknown step');
    }
  }

  function setDataFromChilds(event){
    switch (event.target.name){
      case "clientType":
        onboardingInformation.clientType=event.target.value;

        // Reset all other properties to null
        Object.keys(onboardingInformation).forEach((key) => {
          if (key !== "clientType") {
            onboardingInformation[key] = null;
          }
        });

        if(event.target.value==="company"){
          onboardingInformation.idType="nit";
          onboardingInformation.lastName=" ";
        }

        enableNextButton();
      return;
      case "firstName":
        onboardingInformation.name=event.target.value;
        enableNextButton();
      return;
      case "lastName":
        onboardingInformation.lastName=event.target.value;
        enableNextButton();
      return;
      case "idType":
        onboardingInformation.idType=event.target.value;
        enableNextButton();
      return;
      case "idNumber":
        onboardingInformation.idNumber=event.target.value.replace(/[\s+.+,+]/g, '');
        enableNextButton();
      return;
      case "privacyConsent":
        onboardingInformation.privacyConsent=event.target.checked;
        onboardingInformation.date=new Date();
        enableNextButton();
      return;
      case "nationality":
        onboardingInformation.nationality=event.target.value;
        enableNextButton();
      return;
      case "birthCountry":
        onboardingInformation.birthCountry=event.target.value;
        enableNextButton();
      return;
      case "homeCountry":
        onboardingInformation.homeCountry=event.target.value;
        enableNextButton();
      return;
      case "Address":
        onboardingInformation.address=event.target.value;
        enableNextButton();
      return;
      case "phone":
        onboardingInformation.phone=event.target.value;
        enableNextButton();
      return;
      case "email":
        onboardingInformation.email=event.target.value;
        enableNextButton();
      return;
      case "occupation":
        onboardingInformation.occupation=event.target.value;
        if(event.target.value!=="lowRisk1" && event.target.value!=="lowRisk2"){
          onboardingInformation.amlRisk="high";
        }
        enableNextButton();
      return;
      case "income":
        onboardingInformation.income=event.target.value;
        enableNextButton();
      return;
      case "tax":
        onboardingInformation.taxes=event.target.value;
        enableNextButton();
      return;
      case "merchantCode":
        accountObject.retailCashBackCode=event.target.value;
        enableNextButton();
      return;
      case "representativeName":
        onboardingInformation.representativeName=event.target.value;
        enableNextButton();
      return;
      case "representativeLastName":
        onboardingInformation.representativeLastName=event.target.value;
        enableNextButton();
      return;
      case "representativeIdType":
        onboardingInformation.representativeIdType=event.target.value;
        enableNextButton();
      return;
      case "representativeIdNumber":
        onboardingInformation.representativeIdNumber=event.target.value;
        enableNextButton();
      return;

      default:
        console.log("unknown name :" + event.target.name);
    }

    
  }

  const setGeoLocation = (geoLocation) => {
    console.log(geoLocation);
    onboardingInformation.country = geoLocation.territory;
    onboardingInformation.state = geoLocation.county;
    onboardingInformation.city = geoLocation.city;
  }

  const enableNextButton = () => {
    console.log(onboardingInformation);
    if(onboardingInformation.clientType === "company"){
      console.log("entering company")
      if(onboardingInformation.name && onboardingInformation.lastName && 
        onboardingInformation.idType && onboardingInformation.idNumber && onboardingInformation.address
        && onboardingInformation.country && onboardingInformation.city && onboardingInformation.state && onboardingInformation.representativeName && 
        onboardingInformation.representativeLastName && onboardingInformation.representativeIdType &&
        onboardingInformation.representativeIdNumber && onboardingInformation.phone){
          console.log("complete company")
          setEnableNext(false);
          return;
      }
    }

    else if(onboardingInformation.clientType === "person"){
      console.log("entering person")
      if(onboardingInformation.name && onboardingInformation.lastName && 
        onboardingInformation.idType && onboardingInformation.idNumber && onboardingInformation.address
        && onboardingInformation.country && onboardingInformation.city && onboardingInformation.state && onboardingInformation.phone){
          console.log("complete person")
          setEnableNext(false);
          return;
      }
    }
    
      console.log("incomplete")
      console.log(onboardingInformation);
      setEnableNext(true);
      return;
    
    
  }

  const[enableNext, setEnableNext] = React.useState(true);

  const handleNext = () => {

    if(b2bClient === "IMS"){
     
        onboardingInformation.accountId=loginUser.accountId;
        // onboardingInformation.phone=loginUser.accountId;

        //Determine if an OTP will be send to the user's phone and verify it against external databases
        console.log(isPhoneVerifyEnabled);
        if(isPhoneVerifyEnabled === true){
          console.log("Verification phone enabledç++++++")
          phoneDialogOpen(); //UNCOMMENT
        }
        
        else{
          handleFinish();
        }
    }
    else{

        onboardingInformation.accountId=loginUser.accountId;
        // console.log("onboarding info: " + JSON.stringify(onboardingInformation))
        setActiveStep(activeStep + 1);;
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleFinish = () => {
    // console.log("client PII: " + JSON.stringify(onboardingInformation))
    console.log(onboardingInformation)
    fetch(`${wupoServerUri.devnet}/api/secure/clientspost`, {      
      method: 'POST',
      body: JSON.stringify(onboardingInformation),
      headers: {
        'Authorization': `Bearer ${loginUser.jwtToken}`, 
        'Content-Type': 'application/json; charset=utf-8'                     
      }
      
    }).then((response)=> { 
      console.log(response.status);
      return response.json();
  
    }).then((body)=>{
        console.log(body);
        setLoading(false);

        updateNavBarEnabled(true)
          setLoading(true);
        
          history.push("/edu_programs/active_programs");
            // history.push({pathname: "/account/handle_account"});
          setLoading(false);

    }).catch((error)=>{
        console.log("Error msg: "+ error);
    });
  };

  const mobileVerification = () => {
 
    setLoading(true);

    if(isUsernamePhone){
      verificationObject.phone = loginUser.accountId;
    }else{
      verificationObject.phone = onboardingInformation.phone;
    }
    verificationObject.code = verificationCode;
    verificationObject.account = loginUser.accountId;
    verificationObject.idType = onboardingInformation.idType;
    verificationObject.idNumber = onboardingInformation.idNumber;


    phoneValidation(verificationObject, loginUser.jwtToken).then((verificationRes) =>{
      
      if(!verificationRes.result || verificationRes.result === "denied" || verificationRes.result !== "approved"){

        if(verificationRes.reason === "WRONGCODE"){
          swal({
            title: "CÓDIGO INVÁLIDO",
            text: `\n El código que ingresaste no es correcto. Por favor vuelve a revisar tus mensajes de texto y busca un código con 4 números.
            `,
            icon: "error",
            button: "Entiendo"
          });
          
          //TODO: under review, the idea is to leave the text to include the sms
          // phoneDialogClose();
          setLoading(false);
          return;
        }
      
        else if(verificationRes.reason === "WRONGIDVSPHONE"){
          swal({
            title: "DATOS NO COINCIDEN",
            text: `\n Tus datos no coinciden con los consultados. Por favor revisa la información que ingresaste.
            `,
            icon: "error",
            button: "Entiendo"
          });
          
          phoneDialogClose();
          setLoading(false);
          return;
        }

        else if(verificationRes.reason === "RETRYBLOCKED"){
          swal({
            title: "Límite de intentos superado",
            text: `\n Intentaste más del número de veces aceptado. Por tu seguridad, no permitimos que hagas más intentos. Por favor comunícate con nostros para que podamos resolverlo.
            `,
            icon: "error",
            button: "Entiendo"
          }).then(() => {
            history.push({pathname: "/login"});
          });
          return;  
        }

        else{
          swal({
            title: "Autenticación inválida",
            icon: "error",
            button: "Entiendo"
          });

          phoneDialogClose();
          setLoading(false);
          return; 
        }
        
      }else{
        handleFinish();
      }
    });
 
  };

  const phoneDialogClose = () => {
    setPhoneVerification(false);

  };

  const phoneDialogOpen = () => {
    console.log("Entering the phonedialog open")
    verificationObject.account = loginUser.accountId;
    
    if(isUsernamePhone){
      verificationObject.phone = loginUser.accountId;
    }else{
      verificationObject.phone = onboardingInformation.phone;
    }
    setPhoneVerification(true);

    phoneCode(verificationObject, loginUser.jwtToken).then((verificationResult) => {
      if(verificationResult.idVerificationBlocked){
        swal({
          title: "Límite de intentos superado",
          text: `\n Intentaste más del número de veces aceptado. Por tu seguridad, no permitimos que hagas más intentos. Por favor comunícate con nostros para que podamos resolverlo
          `,
          icon: "error",
          button: "Entiendo"
        }).then(() => {
            history.push({pathname: "/"});
        });
        return;
      }
    });

  }

  const [phoneVerification, setPhoneVerification] = React.useState(false);
  const [verificationCode, setverificationCode] = React.useState("");

    return (
    <React.Fragment>
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",  width: "100%", height: "100%", backgroundColor: "#ededed",}}>
      {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> 
      : 
      <>
        <CssBaseline />
        <Box
            sx={{
                display: "flex",
                // flex: 1,
                justifyContent: "center",
                alignItems: "center",
                width: "90%",
                height: "100%",
                textAlign: "center",
            }}
          >
          <Paper sx={{width: {xs: "90%", sm: "95%", md: "60%"}, marginTop: "5%", marginBottom: "5%"}}>
            <Typography variant="h4" align="center" sx={{marginTop: "5%", marginBottom: "2rem"}}>
              Your Information
            </Typography>
            <Stepper {...stepperProps} activeStep={activeStep} sx={{marginBottom: "2rem"}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Gracias! 
                  </Typography>
                  {isPhoneVerifyEnabled ? 
                    <Button variant="contained" color="primary" onClick={phoneDialogOpen}>
                      Súper
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleFinish}
                    >
                      Súper
                    </Button>
                  }
                  
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Box sx={{display: "flex", justifyContent: "center"}}>
                  {/* <ThemeProvider theme={theme}> */}
                    {activeStep !== 0 && (
                      <Button onClick={handleBack}>
                        Atrás
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      disabled={enableNext}
                      sx={{marginTop: "3rem", marginBottom: "2rem"}}
                    >
                      {activeStep === steps.length - 1 ? 'Submit' : 'Submit'}
                    </Button>
                    {/* </ThemeProvider> */}
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
           
          </Paper>
          {/* <Copyright /> */}
        </Box>
        
        <React.Fragment>
        <Dialog open={phoneVerification} onClose={phoneDialogClose} aria-labelledby="form-dialog-tokens">
          <DialogTitle id="form-dialog-title3">VERIFICACIÓN CELULAR</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{maxWidth: "350px", whiteSpace: "pre-line"}}>
                <Typography align="center">
                  Ingresa el código de 4 números que te enviamos como mensaje de texto al celular:
                </Typography>
                <Typography align="center" setEnableNext={{fontWeight: "bolder"}}>
                  {onboardingInformation.phone}
                </Typography>
            </DialogContentText>
            <TextField
                autoFocus
                autoComplete="one-time-code"
                margin="dense"
                id="name"
                label="Ingresa el código"
                type="text"
                fullWidth
                inputProps={{ maxLength: 4, minLength: 4, inputMode: "numeric"}}
                onChange={e=>setverificationCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
          <Button onClick={phoneDialogClose} color="primary">
                Atrás
            </Button>
            <Button onClick={mobileVerification} color="primary">
                Listo
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      </>
      }
      </Box>
    </React.Fragment>
      
    )
}